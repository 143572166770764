import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import React from "react";
import { useCounterStyles } from "components/SlideGroups/utilities/styles";
import classNames from "classnames";

export const Counter = ({ count, containerClass = null }) => {
  const { classes } = useCounterStyles();

  return (
    <Box className={classNames(classes.counterContainer, containerClass)}>
      <Typography className={classes.counterTypography}>{count}</Typography>
    </Box>
  );
};
