import React from "react";

import { FormProvider } from "react-hook-form";
import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useOrderFormContainer } from "components/OrderForm/Info/OrderFormContainer/hooks/useOrderFormContainer";
import { ProjectSelectionFreeText } from "components/OrderForm/components/ProjectSelectionDropdown";
import {
  // ContributorAndTwoDayConflict,
  InfoOrderFormHeader,
} from "components/OrderForm/Info/InfoPageComponents";
import {
  CuttingInstructionsSelection,
  IHCOptimizationTypeSelection,
  MaterialsAtHistoWizSelection,
  OrderInfoFixativeSelection,
  OrderInfoProjectTeamsSelection,
  OrderInfoSpeciesSelection,
  // PathologyMapContributorSelection,
  SpecialInstructionsAttachmentSelection,
  TurnaroundTimeSelection,
} from "components/OrderForm/components/InfoSelectDropdowns";
import {
  AdditionalStepsPerSampleInput,
  CellTargetInput,
  ExpectedPhenotypeInput,
  ExpectedStainingPatternInput,
  ExperimentalTreatmentInput,
  FixationTimeHoursInput,
  PurposeOfStudyInput,
  RegionOfInterestInput,
  SectionsPerSlideInput,
  SectionThicknessMicronsInput,
  SpecialInstructionsFileUpload,
  SpecialInstructionsInput,
} from "components/OrderForm/components/InfoFormInputs";
import {
  CELL_TARGET,
  EXPECTED_PHENOTYPE,
  EXPECTED_STAINING_PATTERN,
  EXPERIMENTAL_TREATMENT,
  SHOW_CUTTING_INSTRUCTIONS_MODAL,
} from "components/OrderForm/Info/constants";
import { SavedInfoModal } from "components/OrderForm/Info/SavedInfoModal/SavedInfoModal";
import { GlpProtocol } from "components/GlpForm/GlpProtocol";
import { useGlpProtocol } from "components/GlpForm/helpers/hooks";
import { useOrderInfoFormStyles } from "./styles";

export const OrderInfoForm = ({
  team,
  orderDetails,
  selectableSpecies,
  selectableOrderFixativeOptions,
  selectableProjectOptions,
  selectableSpecialInstructionsAttachments,
  selectableTeams,
  handleModalChange,
  updateOrderFixatives,
}) => {
  const { is_glp_order: initialGlp, glp_order_protocol_file: initialFiles } =
    orderDetails;

  const { classes } = useOrderInfoFormStyles();
  const {
    protocolFiles,
    setProtocolFiles,
    isGLP,
    setIsGLP,
    hasError,
    handleGlp,
  } = useGlpProtocol(initialGlp);
  const {
    methods,
    onSubmit,
    renderTeamSelection,
    noTeamsAvailable,
    turnaroundTimeOptions,
    // pathologyMapContributorSelection,
    // pathContributorAndTATConflict,
    materialsAtHistowiz,
    renderOptionalIHC,
    getToggleProps,
    toggleOptionals,
    isSaveButtonDisabled,
    isOptionalVisible,
    getCollapseProps,
    openSavedModal,
    handleDecline,
    handleAccept,
    project,
    isSavedModalOpen,
  } = useOrderFormContainer({
    team,
    orderDetails,
    selectableTeams,
    isGLP,
  });

  return (
    <Box className={classes.container}>
      <SavedInfoModal
        project={project}
        isOpen={isSavedModalOpen}
        onDecline={handleDecline}
        onAccept={handleAccept}
      />
      <Box className={classes.formItem}>
        <InfoOrderFormHeader />
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((values) =>
              onSubmit(values, protocolFiles)
            )}
            className={classes.formContainer}
          >
            <ProjectSelectionFreeText
              selectableProjectOptions={selectableProjectOptions}
              name="project"
              openSavedModal={openSavedModal}
              checkmark
            />
            {renderTeamSelection && !noTeamsAvailable && (
              <OrderInfoProjectTeamsSelection
                name="team"
                selectableTeams={selectableTeams}
                team={team}
              />
            )}
            <GlpProtocol
              isOpen={isGLP}
              setIsOpen={setIsGLP}
              handleProtocolFiles={setProtocolFiles}
              hasError={hasError}
              initialGlp={initialGlp}
              initialFiles={initialFiles}
            />
            {!isGLP && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs>
                    <TurnaroundTimeSelection
                      name="turnaroundTime"
                      options={turnaroundTimeOptions}
                    />
                  </Grid>
                  {/* hidden as per 1834*/}
                  {/*<Grid item xs>*/}
                  {/*<PathologyMapContributorSelection*/}
                  {/*  isRequired={!isGLP}*/}
                  {/*  name="pathologyMapContributorSelection"*/}
                  {/*/>*/}
                  {/*{pathologyMapContributorSelection?.value && (*/}
                  {/*  <Box className={classes.InfoModalLink}>*/}
                  {/*    <a*/}
                  {/*      href="https://home.histowiz.com/pathology_map_terms/"*/}
                  {/*      target="_blank"*/}
                  {/*      rel="noopener noreferrer"*/}
                  {/*    >*/}
                  {/*      More Information*/}
                  {/*    </a>*/}
                  {/*  </Box>*/}
                  {/*)}*/}
                  {/*{pathContributorAndTATConflict && (*/}
                  {/*  <ContributorAndTwoDayConflict />*/}
                  {/*)}*/}
                  {/*</Grid>*/}
                  <Grid item xs>
                    <FixationTimeHoursInput name="fixationTimeHours" />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs>
                    <OrderInfoFixativeSelection
                      name="orderFixative"
                      updateOrderFixatives={updateOrderFixatives}
                      team={team}
                      selectableOrderFixativeOptions={
                        selectableOrderFixativeOptions
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs>
                    <CuttingInstructionsSelection name="cutting_instructions" />
                    <Box
                      onClick={handleModalChange(
                        SHOW_CUTTING_INSTRUCTIONS_MODAL
                      )}
                      className={classes.InfoModalLink}
                    >
                      Grossing Orientation
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <SectionsPerSlideInput name="sectionsPerSlide" />
                  </Grid>
                  <Grid item xs>
                    <AdditionalStepsPerSampleInput name="additionalStepsPerSample" />{" "}
                  </Grid>
                  <Grid item xs>
                    <SectionThicknessMicronsInput name="sectionThicknessMicrons" />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={4} className={classes.itemsContainer}>
              <Grid item xs={isGLP ? 12 : 6} className={classes.item}>
                <OrderInfoSpeciesSelection
                  name="species"
                  selectableSpecies={selectableSpecies}
                  materialsAtHistowiz={materialsAtHistowiz}
                />
              </Grid>
              {!isGLP && (
                <Grid item xs={6} className={classes.item}>
                  <MaterialsAtHistoWizSelection name="materials_at_histowiz" />
                </Grid>
              )}
            </Grid>
            {!isGLP && (
              <IHCOptimizationTypeSelection name="ihc_optimization_type" />
            )}
            {renderOptionalIHC && !isGLP && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  title="Toggle"
                  className={classes.additionalStudyDetailsButton}
                  {...getToggleProps({ onClick: toggleOptionals })}
                >
                  {isOptionalVisible ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                  Additional Study Details
                </Button>
                <Box {...getCollapseProps()}>
                  <>
                    <ExpectedPhenotypeInput name={EXPECTED_PHENOTYPE} />
                    <ExperimentalTreatmentInput name={EXPERIMENTAL_TREATMENT} />
                    <ExpectedStainingPatternInput
                      name={EXPECTED_STAINING_PATTERN}
                    />
                    <CellTargetInput name={CELL_TARGET} />
                  </>
                </Box>
              </Box>
            )}
            <PurposeOfStudyInput isRequired={isGLP} />
            <RegionOfInterestInput />
            {!isGLP && (
              <>
                <SpecialInstructionsAttachmentSelection
                  name="specialInstructionsAttachment"
                  selectableSpecialInstructionsAttachments={
                    selectableSpecialInstructionsAttachments
                  }
                />
                <SpecialInstructionsFileUpload
                  name="specialInstructionsAttachment"
                  team={team}
                />
                <SpecialInstructionsInput name="specialInstructions" />
              </>
            )}
            {!initialGlp && (
              <Box className={classes.nextButton}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      {...methods.register("save_project_defaults")}
                    />
                  }
                  label="Save form data for future use in this project"
                  labelPlacement="start"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSaveButtonDisabled}
                  onClick={handleGlp}
                >
                  {isGLP ? "Submit GLP Order" : "Save & Continue"}
                </Button>
              </Box>
            )}
            {initialGlp && (
              <Box className={classes.nextButton}>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSaveButtonDisabled}
                    onClick={handleGlp}
                  >
                    Save & Continue
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};
