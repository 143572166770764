import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { SlideCommentComponentV2 } from "components/AllSlidesComments/SlideCommentComponentV2";
import { useSelector } from "react-redux";
import { isStaffSelector } from "store/slices/userDetailsSlice";
import { useStyles } from "components/SlideComments/styles";

export const SlideCommentList = React.memo(
  ({ comments, handleDeleteComment }) => {
    const [isNewFirst, setIsNewFirst] = useState(true);
    const isStaff = useSelector(isStaffSelector);
    const [isInternalComments, setIsInternalComments] = useState(isStaff);
    const { classes } = useStyles();
    if (!comments || !comments.length) {
      return null;
    }

    const internalComments = comments.filter((comment) => comment.is_internal);
    const externalComments = comments.filter((comment) => !comment.is_internal);
    const commentsToDisplay = isInternalComments
      ? internalComments
      : externalComments;

    const sortedComments = isNewFirst
      ? commentsToDisplay
      : commentsToDisplay.reverse();

    return (
      <Fragment>
        {isStaff && (
          <Box mt={2} className={classes.container}>
            <Typography
              className={
                isInternalComments
                  ? classes.filterButtonActive
                  : classes.filterButton
              }
              onClick={() => setIsInternalComments(true)}
            >
              Internal{" "}
              <span className={classes.filterCount}>
                {internalComments.length}
              </span>
            </Typography>
            <Typography
              className={
                !isInternalComments
                  ? classes.filterButtonActive
                  : classes.filterButton
              }
              onClick={() => setIsInternalComments(false)}
            >
              External{" "}
              <span className={classes.filterCount}>
                {externalComments.length}
              </span>
            </Typography>
          </Box>
        )}
        <Typography
          className={classes.sort}
          onClick={() => setIsNewFirst((prev) => !prev)}
        >
          Comment date{" "}
          {isNewFirst ? (
            <KeyboardArrowDown fontSize="small" />
          ) : (
            <KeyboardArrowUp fontSize="small" />
          )}
        </Typography>
        {sortedComments.map((comment) => (
          <SlideCommentComponentV2
            comment={comment}
            key={comment.uuid}
            handleDeleteComment={handleDeleteComment}
          />
        ))}
      </Fragment>
    );
  }
);
