import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, Grid, Tab, Tabs } from "@mui/material";
import {
  getAPIUserDetails,
  updateAPIUserDetails,
} from "services/resources/commonResources";
import { EditUserTeamAddresses } from "components/EditUserDetails/EditUserTeamAddresses";
import { TabPanel } from "components/Shared/TabPanel";
import {
  FETCH_USER_DETAILS_MESSAGE,
  UPDATE_USER_DETAILS_MESSAGE,
} from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { UserEditFormField } from "components/EditUserDetails/UserEditFormField";

const useStyles = makeStyles()({
  root: {},
  wrapper: {
    maxWidth: "100%",
  },
});

export const EditUserPage = () => {
  const { wrapper } = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const [isLoaded, setIsLoaded] = useState(false);

  const [updateButtonText, setUpdateButtonText] = useState("Update");
  const [updateDisabled, setUpdatedDisabled] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);
  const { showError } = useSnackbar();

  const onSubmit = (data) => {
    setUpdatedDisabled(true);
    setUpdateButtonText("Updating");

    const { first_name, last_name, phone_number, secondary_phone_number } =
      data;

    const postParams = {
      first_name,
      last_name,
      phone_number,
      secondary_phone_number,
    };

    // the default for password is not filled, so only send this data to be changed if the password is populated
    // and isn't blank
    if (data.newPassword) {
      postParams.password = data.newPassword;
    }

    updateAPIUserDetails(postParams)
      .then(() => setUpdateButtonText("Updated!"))
      .catch(() => showError(UPDATE_USER_DETAILS_MESSAGE));
  };

  useEffect(() => {
    let isMounted = true;

    getAPIUserDetails()
      .then((user) => {
        const {
          first_name,
          last_name,
          phone_number,
          secondary_phone_number,
          credit_amount,
        } = user;

        reset({
          first_name,
          last_name,
          phone_number,
          secondary_phone_number,
          credit_amount,
        });

        isMounted && setIsLoaded(true);
      })
      .catch(() => showError(FETCH_USER_DETAILS_MESSAGE));

    return () => {
      isMounted = false;
    };
  }, []);

  const handleTabsChange = (e, value) => {
    setCurrentTab(value);
  };

  if (!isLoaded) {
    return <MiniDrawerWithContext header={"Change User Details"} />;
  }

  return (
    <MiniDrawerWithContext header={"Change User Details"}>
      <Grid container spacing={8} className={wrapper}>
        <Grid item xs={12} md={7} lg={5}>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabsChange}
          >
            <Tab label="Profile" />
            <Tab label="Team Addresses" />
          </Tabs>

          <TabPanel value={currentTab} index={0}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <UserEditFormField fieldLabel={"First Name"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register("first_name", { required: true })}
                  size="small"
                />
              </UserEditFormField>
              <UserEditFormField fieldLabel={"Last Name"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register("last_name", { required: true })}
                  size="small"
                />
              </UserEditFormField>

              <UserEditFormField fieldLabel={"Primary Phone #"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register("phone_number", { required: false })}
                  size="small"
                />
              </UserEditFormField>

              <UserEditFormField fieldLabel={"Secondary Phone #"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register("secondary_phone_number", { required: false })}
                  size="small"
                />
              </UserEditFormField>

              <UserEditFormField fieldLabel={"Change Password"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register("newPassword", {
                    required: false,
                    minLength: 6,
                  })}
                  type={"password"}
                  size="small"
                  defaultValue={""}
                  helperText={
                    errors.newPassword && "Must Be At Least 6 Characters"
                  }
                  error={Boolean(
                    errors.newPassword && "Must Be At Least 6 Characters"
                  )}
                />
              </UserEditFormField>

              <UserEditFormField fieldLabel={"Confirm New Password"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register("passwordMatch", {
                    required: false,
                    validate: (value) => {
                      const newPassword = getValues("newPassword");

                      return (
                        value === newPassword || "The Passwords Do Not Match"
                      );
                    },
                  })}
                  type={"password"}
                  size="small"
                  defaultValue={""}
                  helperText={
                    errors.passwordMatch && errors.passwordMatch.message
                  }
                  error={Boolean(
                    errors.passwordMatch && errors.passwordMatch.message
                  )}
                />
              </UserEditFormField>

              <Box display="flex" justifyContent="flex-end">
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    type={"submit"}
                    disabled={updateDisabled}
                  >
                    {updateButtonText}
                  </Button>
                </Box>
              </Box>
            </form>
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <EditUserTeamAddresses />
          </TabPanel>
        </Grid>
      </Grid>
    </MiniDrawerWithContext>
  );
};
