import { ViewTypes } from "components/SlideGroups/utilities/constants";
import { PathologyMapSlideCardView } from "components/PathologyMap/PathologyMapSlideCards/Views/CardView/PathologyMapSlideCardView";
import { PathologyMapSlideListView } from "components/PathologyMap/PathologyMapSlideCards/Views/ListView/PathologyMapSlideListView";

export const getPathologyMapItemsView = (view) =>
  ({
    [ViewTypes.CARD_VIEW]: PathologyMapSlideCardView,
    [ViewTypes.LIST_VIEW]: PathologyMapSlideListView,
  }[view]);

export const SEARCH_PAGE_URL_REGEX_PATTERN = /^\/search\/*/;
export const IS_PUBLIC_REFINEMENT_TYPE = "is_public";
export const FAVORITE_CATEGORIES_PREFERENCES_FIELD = "favoriteCategories";
export const FAVORITE_CATEGORIES_TAB_INDEX = "favorite";
