import { makeStyles } from "tss-react/mui";
import { PRIMARY_WHITE, FORM_TEXT_COLOR } from "utilities/colors";

export const ModalStyles = {
  maxWidth: "54rem",
  width: "100%",
  backgroundColor: PRIMARY_WHITE,
  borderRadius: "0.2rem",
};

export const useSuggestFeatureModalStyles = makeStyles()(() => ({
  title: {
    fontSize: "1.87rem",
    fontWeight: 700,
    textShadow: "0px 4px 4px #00000014",
    opacity: 0.5,
    color: "black",
    marginBottom: "2rem",
  },
  form: {
    color: FORM_TEXT_COLOR,
  },
  input: {
    marginBottom: "1.5rem",
  },
  dropzone: {
    marginTop: "1.5rem",
  },
  cancelButton: {
    cursor: "pointer",
  },
  submitButton: {
    marginTop: "1.5rem",
    width: "100%",
    textTransform: "uppercase",
  },
  contacts: {
    textAlign: "center",
    maxWidth: "28rem",
    margin: "1rem auto 0",
    color: FORM_TEXT_COLOR,
  },
  description: {
    maxHeight: "15rem",
    fontFamily: "unset",
  },
}));
