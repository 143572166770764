import { makeStyles } from "tss-react/mui";

export const useUpdatePOModalStyles = makeStyles()({
  input: {
    marginTop: "0.5rem",
    fontSize: "1rem",
    width: "50%",
    display: "inline-block",
    marginBottom: "1rem",
    position: "relative",
  },
  inputInvalid: {
    border: "1px solid red",
  },
  attachment: {
    marginTop: "1rem",
    marginBottom: "1rem",
    border: "2px dashed #ccc",
    padding: "1rem",
    borderRadius: "4px",
  },
  submitButton: {
    float: "right",
  },
  checkmark: {
    position: "absolute",
    top: "5.5rem",
    marginLeft: "0.5rem",
  },
  spinnerContainer: {
    height: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
