import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  CREATED_DATE_SORT,
  MODIFIED_DATE_SORT,
  NAME_SORT,
  SLIDES_COUNT_SORT,
} from "components/SlideGroups/utilities/constants";
import { Counter } from "components/SlideGroups/components/Counter";
import { AddSlideGroupButton } from "components/SlideGroups/components/AddSlideGroupButton";
import { ViewsSelectionButtons } from "components/SlideGroups/Modals/components/ViewsSelectionButtons";

export const LayoutHeaderSection = ({
  classes,
  sortBy,
  setSortBy,
  slideGroups,
  setSlideGroupModalOpen,
  view,
  setView,
}) => {
  return (
    <Grid container display={"flex"} justifyContent={"space-between"} mb={2}>
      <Grid item xs={9} className={classes.card}>
        <Box className={classes.row}>
          <Typography className={classes.sortByLabel} variant={"subtitle2"}>
            SORT BY:
          </Typography>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortBy}
              label="Age"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value={CREATED_DATE_SORT}>Date Created</MenuItem>
              <MenuItem value={MODIFIED_DATE_SORT}>Last Modified</MenuItem>
              <MenuItem value={SLIDES_COUNT_SORT}>Slide Count</MenuItem>
              <MenuItem value={NAME_SORT}>Name</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <ViewsSelectionButtons setView={setView} view={view} />
        <Box className={classes.row}>
          <Typography variant="h6" gutterBottom className={classes.slidesLabel}>
            GROUPS
          </Typography>
          <Counter count={slideGroups?.length || 0} />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <AddSlideGroupButton
          setSlideGroupModalOpen={setSlideGroupModalOpen}
          classes={classes}
        />
      </Grid>
    </Grid>
  );
};
