import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import OpenSeadragon from "openseadragon";
import * as Annotorious from "@recogito/annotorious-openseadragon";
import {
  MULTI_SLIDE_VIEWER_PARAMS_URL,
  PATHOLOGY_MAP_SEARCH,
} from "constants/urls";
import {
  DefaultQCScoresNames,
  ML_QC_BLURRY_DATA,
} from "components/QCViewer/constants";
import {
  ANNOTATION_COMMENT_AUTHOR_FONT,
  ANNOTATION_COMMENT_AUTHOR_OFFSET_Y,
  ANNOTATION_COMMENT_CONTENT_FONT,
  ANNOTATION_COMMENT_LINE_OFFSET_Y,
  ANNOTATION_COMMENT_NEW_COMMENT_OFFSET_Y,
  ANNOTATION_COMMENTS_INITIAL_OFFSET_Y,
  ANNOTATION_OBJECT_LINE_COLOR,
  ANNOTATION_OBJECT_LINE_WIDTH,
  CANCEL_CURRENT_MEASURE_KEY_CODE,
  DEFAULT_OVERLAYS_NUMBER,
  DIMENSIONS_MENU,
  MAX_COMMENT_ONE_LINE_LENGTH,
  ML_QC_OVERLAY_PADDING_COEFFICIENT,
  SLIDE_VIEWER_PIXELS_PER_METER,
} from "components/ImageViewer/constants";
import { makeStyles, withStyles } from "tss-react/mui";
import { MEDIUM_GREY, SECONDARY_VIEWER } from "utilities/colors";
import { getCurrentDateFormatted } from "utilities/dates_and_times";
import { debounce, range } from "lodash";
import { createFabricPoint } from "components/ImageViewer/fabric/objectFactories";
import { fabric } from "fabric";
import {
  DEFAULT_FABRIC_FONT_COLOR,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_COLOR,
  DEFAULT_LINE_WIDTH,
  DEFAULT_SHADOW,
} from "components/ImageViewer/fabric/constants";
import FileSaver from "file-saver";
import {
  MAX_SCORE_QC_PASSED,
  MIN_SCORE_QC_FAILED,
} from "components/Modals/constants";
import { getSvgRectElementCoordinates } from "utilities/drawing";
import { MAX_ZOOM_LEVEL_FOR_BASE_VIEWER } from "components/ImageViewer/viewerBaseConfig";
import {
  convertToRGBA,
  getViewerContainerPositionFromImagePosition,
} from "components/ImageViewer/extAnnoUtils";
import { appURL } from "services/backendAPI";
import { FormControlLabel, Checkbox } from "@mui/material";

const iconStyles = (theme) => ({
  icon: {
    svg: {
      width: 30,
      height: 30,
      padding: 2,
      boxSizing: "border-box",

      [theme.breakpoints.down("lg")]: {
        width: 25,
        height: 25,
      },
    },
  },
  highlightedIcon: {
    backgroundColor: `${SECONDARY_VIEWER} !important`,
  },
  separate: {
    paddingTop: "5rem",

    [theme.breakpoints.down("xl")]: {
      paddingTop: "1.5rem",
    },
  },
  hidden: {
    width: "1px",
    height: "1px",
  },
});

const useStyles = makeStyles()((theme) => iconStyles(theme));

export const ToolbarIcon = ({
  id,
  icon,
  className,
  isFullScreen,
  isSeparate,
  isHighlighted,
  ...params
}) => {
  const ref = useRef();
  const { classes } = useStyles();
  const { onMouseDown, onMouseUp } = params;

  useEffect(() => {
    if (!isFullScreen || !ref.current) {
      return;
    }

    onMouseDown && ref.current.addEventListener("mousedown", onMouseDown);
    onMouseUp && ref.current.addEventListener("mouseup", onMouseUp);

    return () => {
      if (!ref.current) return;

      onMouseDown && ref.current.removeEventListener("mousedown", onMouseDown);
      onMouseUp && ref.current.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseDown, onMouseUp, isFullScreen]);

  return (
    <li className={isSeparate ? classes.separate : ""}>
      <a
        id={id}
        {...params}
        ref={ref}
        className={`rounded-border ${classes.icon} ${
          isHighlighted ? classes.highlightedIcon : ""
        }`}
      >
        {icon || <i className={className} />}
      </a>
    </li>
  );
};

export const openMultiSlideParamsNewWindow = ({ rows }) => {
  const slideUUIDs = rows.map((row) => row.uuid);
  const slideUUIDsString = slideUUIDs.toString();
  const url = MULTI_SLIDE_VIEWER_PARAMS_URL.replace(
    ":slideUUIDs",
    slideUUIDsString
  );

  window.open(url, "_blank");
};

export const qcScoreOverlaysExist = (slide, scoreToDisplay) => {
  if (!slide?.additional_data) {
    return false;
  }

  const hasSlideQCRegions = DefaultQCScoresNames.some(
    (scoreName) => slide.additional_data[scoreName]?.regions
  );

  if (!hasSlideQCRegions) {
    return false;
  }

  return !(
    scoreToDisplay !== "all" && !slide.additional_data[scoreToDisplay]?.regions
  );
};

export const getAllSlideQCScoreRegions = (
  slide,
  qcScoreNames = DefaultQCScoresNames
) => {
  const qcScoreRegions = qcScoreNames
    .map((scoreName) => slide.additional_data[scoreName].regions)
    .flat();
  return qcScoreRegions;
};

export const filterValidQCScoreRegions = (regions) => {
  const validRegions = regions.filter(
    ({ valid }) => valid === undefined || valid
  );
  return validRegions;
};

export const getTopQCScoreRegions = (
  regions,
  limitTo = DEFAULT_OVERLAYS_NUMBER
) => {
  const sortedRegions = regions.sort(
    (currRegion, nextRegion) =>
      nextRegion.patch_score_converted - currRegion.patch_score_converted
  );
  const regionsWithHighestScores = sortedRegions.slice(0, limitTo);

  return regionsWithHighestScores;
};

export const getFailedScoreRegionsByEachScore = (
  slide,
  limitTo = DEFAULT_OVERLAYS_NUMBER
) => {
  const existingScoreNames = DefaultQCScoresNames.filter(
    (scoreName) => slide.additional_data[scoreName]?.regions
  );

  const allFailedRegions = existingScoreNames
    .map((scoreName) => {
      const failedRegions = getFailedQCRegionsByScoreName(
        slide,
        scoreName,
        limitTo
      );
      return failedRegions;
    })
    .flat();

  return allFailedRegions;
};

export const createViewerOverlaysByQCRegions = (regions, slideUUID, viewer) => {
  const overlays = regions.map((region, idx) => {
    const { patch_bbox, patch_score_converted } = region;
    // [x1, y1, x2, y2] is the format for the patch_bbox but
    // we need to make some transformations according to OSD slide rotation
    const boxSideLength = patch_bbox[2] - patch_bbox[0];
    const borderClass = isQCScoreFailed(patch_score_converted)
      ? "red-qc-ml-highlighted-box"
      : isQCScorePassed(patch_score_converted)
      ? "green-qc-ml-highlighted-box"
      : "yellow-qc-ml-highlighted-box";

    // need some padding to prevent boxes overlapping
    const pad = boxSideLength * ML_QC_OVERLAY_PADDING_COEFFICIENT;

    const qcScoreAreaOverlay = {
      id: `qc-ml-overlay-${slideUUID}-${idx}-${viewer.id}`,
      // these transformation are based on the OSD slide rotation
      // cause originally slides are scanned horizontally but rotated in OSD by 270 degrees
      px: region.patch_bbox[1] + pad,
      py: viewer.source.height - region.patch_bbox[0] - boxSideLength - pad,
      width: boxSideLength - pad * 2,
      height: boxSideLength - pad * 2,
      className: `osd-qc-ml-highlighted-box ${borderClass}`,
    };

    return qcScoreAreaOverlay;
  });

  return overlays;
};

export const getTopQCRegionsByScoreName = (
  slide,
  scoreName,
  limitTo = DEFAULT_OVERLAYS_NUMBER
) => {
  const validRegions = filterValidQCScoreRegions(
    slide.additional_data[scoreName].regions
  );
  const topRegions = getTopQCScoreRegions(validRegions, limitTo);

  return topRegions;
};

export const getFailedQCRegionsByScoreName = (
  slide,
  scoreName,
  limitTo = DEFAULT_OVERLAYS_NUMBER
) => {
  const validRegions = filterValidQCScoreRegions(
    slide.additional_data[scoreName].regions
  );
  const failedRegions = validRegions.filter(({ patch_score_converted }) =>
    isQCScoreFailed(patch_score_converted)
  );
  const topFailedRegions = getTopQCScoreRegions(failedRegions, limitTo);

  return topFailedRegions;
};

export const getQCOverlays = (
  viewer,
  slide,
  scoreToDisplay = ML_QC_BLURRY_DATA,
  limitTo = DEFAULT_OVERLAYS_NUMBER
) => {
  const overlaysRegions =
    scoreToDisplay === "all"
      ? getFailedScoreRegionsByEachScore(slide, limitTo)
      : getTopQCRegionsByScoreName(slide, scoreToDisplay, limitTo);

  const viewerQCOverlays = createViewerOverlaysByQCRegions(
    overlaysRegions,
    slide.uuid,
    viewer
  );

  return viewerQCOverlays;
};

export const initAnnotorious = (args) => {
  const {
    viewer,
    annotationsEnabled,
    annotationDrawTool,
    annotationDrawModeEnabled,
    handleAnnotationUpdate,
    handleAnnotationCreate,
    handleAnnotationRemove,
  } = args;

  const anno = Annotorious(viewer, {
    widgets: [
      {
        widget: "COMMENT",
      },
    ],
  });

  anno.setVisible(annotationsEnabled);
  anno.setDrawingTool(annotationDrawTool);
  anno.setDrawingEnabled(annotationDrawModeEnabled);

  anno.on("updateAnnotation", handleAnnotationUpdate);
  anno.on("createAnnotation", handleAnnotationCreate);
  anno.on("deleteAnnotation", handleAnnotationRemove);

  //It's needed cause there is Annotorious bug causes switching draw mode after drawing canceled
  anno.on("cancelSelected", () => {
    setTimeout(() => {
      anno.setDrawingEnabled(annotationDrawModeEnabled);
    }, 100);
  });

  return anno;
};

export const addOverlaysToViewer = (viewer, overlays) => {
  overlays.forEach((overlay) => viewer.addOverlay(overlay));
};

export const getAnnotationByInnerID = (rawAnnotations, innerID) => {
  return rawAnnotations.find(
    (rawAnnotation) => rawAnnotation.data.id === innerID
  );
};

export const getAnnotationCommentCreatorByUser = (user) => ({
  id: "#",
  name: user.first_name,
});

export const buildAnnotationCommentByResponseData = (comment) => ({
  ...comment,
  creator: getAnnotationCommentCreatorByUser(comment.user),
});

export const buildAnnotationByResponseData = (annotation) => ({
  ...annotation.data,
  body: annotation.comments.map(buildAnnotationCommentByResponseData),
});

export const getUpdatedRawAnnotations = (
  rawAnnotations,
  updatedAnnotations,
  annotationToUpdate
) => {
  const updatedAnnotation = updatedAnnotations.find(
    ({ id }) => id === annotationToUpdate.id
  );

  if (updatedAnnotation) {
    const rawAnnotationToUpdateIdx = rawAnnotations.findIndex(
      ({ data }) => data.id === annotationToUpdate.id
    );

    const updatedRawAnnotation = {
      ...rawAnnotations[rawAnnotationToUpdateIdx],
      comments: [...updatedAnnotation.body],
    };

    const updatedRawAnnotations = [...rawAnnotations];
    updatedRawAnnotations.splice(
      rawAnnotationToUpdateIdx,
      1,
      updatedRawAnnotation
    );

    return updatedRawAnnotations;
  } else {
    return rawAnnotations.filter(
      ({ data }) => data.id !== annotationToUpdate.id
    );
  }
};

export const updateAnnotationComments = (annotation, comment) => ({
  ...annotation,
  comments: [...annotation.comments, comment],
});

export const getCreatedComment = (annotation, prevAnnotation) => {
  const prevAnnotationCommentUUIDs = prevAnnotation.body.map(
    (comment) => comment.uuid
  );

  const createdComment = annotation.body.find(
    (comment) => !prevAnnotationCommentUUIDs.includes(comment.uuid)
  );

  return createdComment;
};

export const getRemovedComments = (annotation, prevAnnotation) => {
  const newAnnotationCommentUUIDs = annotation.body.map(
    (comment) => comment.uuid
  );

  const removedComments = prevAnnotation.body.filter(
    (comment) => !newAnnotationCommentUUIDs.includes(comment.uuid)
  );

  return removedComments;
};

export const getEditedComments = (annotation, prevAnnotation) => {
  const prevAnnotationCommentValues = Object.fromEntries(
    prevAnnotation.body.map(({ uuid, value }) => [uuid, value])
  );

  const editedComments = annotation.body.filter((comment) => {
    const prevCommentValue = prevAnnotationCommentValues[comment.uuid];
    return prevCommentValue && comment.value !== prevCommentValue;
  });

  return editedComments;
};

export const createFullScreenButtonElement = (
  id,
  toggleFullScreen,
  isFullScreen,
  classes
) => {
  const container = document.createElement("div");
  container.classList.add("osd-zoom-button-group", classes.fullScreenButton);
  const button = document.createElement("div");
  button.classList.add("osd-zoom-button", "osd-rounded-border");
  button.id = `full-page-${id}`;
  button.title = "Toggle full page";
  button.classList.add("rounded-border");
  const icon = document.createElement("i");
  icon.classList.add("fa", isFullScreen ? "fa-compress-alt" : "fa-expand-alt");
  button.appendChild(icon);
  container.appendChild(button);
  container.onclick = toggleFullScreen;

  return container;
};

export const createColorsLegendElement = (
  colors,
  colorLegendFilter,
  setColorLegendFilter
) => {
  if (!colors.length) {
    return null;
  }

  const handleColorClick = (color) => {
    const index = colorLegendFilter.indexOf(color);
    index > -1
      ? colorLegendFilter.splice(index, 1)
      : colorLegendFilter.push(color);

    setColorLegendFilter([...colorLegendFilter]);
  };

  const legend = document.createElement("div");
  legend.classList.add("osd-colors-legend");

  colors.forEach(({ color, label, isSelected }) => {
    const colorContainer = document.createElement("label");
    colorContainer.classList.add("color-container");

    const colorIndicator = document.createElement("span");
    colorIndicator.classList.add("color-indicator");
    colorIndicator.style.backgroundColor = isSelected ? color : MEDIUM_GREY;
    colorIndicator.onclick = () => {
      handleColorClick(color);
    };

    const colorLabel = document.createElement("span");
    colorLabel.classList.add("color-label");
    colorLabel.innerText = label;
    colorContainer.appendChild(colorLabel);
    colorContainer.appendChild(colorIndicator);

    legend.appendChild(colorContainer);
  });

  return legend;
};

export const createTextToggleElement = (
  isAnnotationTextAvailable,
  showAnnotationText,
  setShowAnnotationText
) => {
  const annotationTextContainer = document.createElement("div");
  annotationTextContainer.classList.add("annotation-text-container");

  const CustomCheckbox = withStyles(Checkbox, {
    root: {
      padding: 0,
    },
  });

  const MuiCheckbox = (
    <FormControlLabel
      control={
        <CustomCheckbox
          checked={showAnnotationText}
          onChange={() => setShowAnnotationText(!showAnnotationText)}
        />
      }
      sx={{
        width: "100%",
        justifyContent: "space-between",
        margin: "0",
        padding: "0 0.1rem 0.5rem 0",
      }}
      label="Annotations text"
      labelPlacement="start"
    />
  );
  const root = createRoot(annotationTextContainer);
  root.render(MuiCheckbox);

  return annotationTextContainer;
};

export const createButtonGroupElement = () => {
  const buttonGroup = document.createElement("div");
  buttonGroup.classList.add("osd-zoom-button-group-container");
  return buttonGroup;
};

const createZoomButtonElement = (zoomAmount, isSelected) => {
  const zoomButton = document.createElement("div");
  zoomButton.className = "osd-zoom-button osd-rounded-border";
  if (isSelected) {
    zoomButton.classList.add("osd-zoom-btn-selected");
  }
  zoomButton.innerText = `${zoomAmount}x`;

  return zoomButton;
};

//calculate dimensions based on current width and height using multiply param
export const calculateDimension = (width, height, multiply) => {
  const newWidth = width * multiply;
  const newHeight = height * multiply;

  return {
    width: multiply ? newWidth : width,
    height: multiply ? newHeight : height,
  };
};

export const createDimensionsMenu = (
  screenshotWithResizing,
  classes,
  isOpen
) => {
  const canvas = document.getElementsByClassName("upper-canvas");
  const menuWrapper = document.createElement("ul");
  menuWrapper.className = classes.dimensionMenuWrapper;
  menuWrapper.style = isOpen ? "display: block" : "display: none";

  const originWidth = canvas[0]?.width;
  const originHeight = canvas[0]?.height;

  const menuItems = DIMENSIONS_MENU.map((dimension) => {
    const newWidth = dimension?.width;
    const newHeight = dimension?.height;

    const item = document.createElement("li");
    item.textContent = `${dimension.title} (${newWidth || originWidth} x ${
      newHeight || originHeight
    })`;

    item.setAttribute("key", dimension.title);
    item.className = classes.dimensionMenuItem;
    item.onclick = () =>
      screenshotWithResizing({
        width: newWidth || originWidth,
        height: newHeight || originHeight,
      });
    return item;
  });

  menuWrapper.append(...menuItems);
  return menuWrapper;
};

export const createScreenshotButtonElement = (
  onClickScreen,
  openDimensionsHandler,
  classes,
  isOpenDimensions
) => {
  const screenshotWithResizing = (resizeValues) => {
    onClickScreen(null, resizeValues);
  };

  const menu = createDimensionsMenu(
    screenshotWithResizing,
    classes,
    isOpenDimensions
  );
  const screenButtonWrapper = document.createElement("div");
  screenButtonWrapper.className = classes.screenshotWrapper;
  const screenButton = document.createElement("div");
  const dimensionsButton = document.createElement("div");

  screenButton.className = classes.downloadButton;
  screenButton.onclick = onClickScreen;

  dimensionsButton.className = classes.dimensionArrow;
  dimensionsButton.onclick = () => openDimensionsHandler(!isOpenDimensions);

  const icon = document.createElement("i");
  icon.className = "fa fa-camera";
  screenButton.appendChild(icon);

  const arrowIcon = document.createElement("i");
  arrowIcon.className = "fa fa-chevron-down";
  dimensionsButton.appendChild(arrowIcon);

  screenButtonWrapper.appendChild(screenButton);
  screenButtonWrapper.appendChild(dimensionsButton);
  screenButtonWrapper.appendChild(menu);

  return screenButtonWrapper;
};

export const createNavSlideButtonElement = (
  text,
  onClick,
  isFullScreen,
  toggleFullScreen
) => {
  const navButton = document.createElement("div");
  navButton.className = isFullScreen
    ? "osd-zoom-button osd-rounded-border"
    : "osd-button-hidden";
  navButton.innerText = text;
  navButton.onclick = () => {
    toggleFullScreen();
    !!onClick && typeof onClick === "function" && onClick(true);
  };

  return navButton;
};

export const createZoomIndicatorElement = (zoomValue, actualZoomRatio) => {
  const zoomIndicator = document.createElement("div");
  zoomIndicator.className = "osd-zoom-indicator";
  zoomIndicator.style.background = SECONDARY_VIEWER;
  const zoomLabelValue =
    actualZoomRatio > 0 ? zoomValue / actualZoomRatio : zoomValue;
  zoomIndicator.innerText = `Zoom: ${zoomLabelValue.toFixed(1)}x`;

  return zoomIndicator;
};

export const createFixedZoomButton = (
  viewer,
  zoomAmount,
  actualZoomRatio,
  isSelected
) => {
  return new OpenSeadragon.Button({
    element: createZoomButtonElement(zoomAmount, isSelected),
    tooltip: `Zoom to ${zoomAmount}x`,
    onClick: () => {
      const actualZoomValue =
        actualZoomRatio > 0 ? actualZoomRatio * zoomAmount : zoomAmount;
      const zoomVal = parseFloat(actualZoomValue);
      viewer.viewport.zoomTo(zoomVal, null, false);
    },
  });
};

export const setMaximumZoomToViewerAndViewport = (
  viewer,
  viewerHelper,
  setActualZoomRatio
) => {
  viewerHelper.setMaxZoom(1);
  const actualMaxZoomLevel =
    viewerHelper.getMaxZoomLevel() || MAX_ZOOM_LEVEL_FOR_BASE_VIEWER;
  viewer.viewport.maxZoomLevel = actualMaxZoomLevel;

  setActualZoomRatio(calculateActualZoomRatio(actualMaxZoomLevel));
};

export const calculateActualZoomRatio = (actualMaxZoomValue) => {
  return actualMaxZoomValue / MAX_ZOOM_LEVEL_FOR_BASE_VIEWER;
};

export const wrapViewerZoomButtonsInExceptionHandler = (...zoomingButtons) => {
  /*
    This workaround used to handle TypeError exceptions caused by OSD <v3.0.0 bug.
    See: https://github.com/openseadragon/openseadragon/pull/1884
  */
  zoomingButtons.forEach((button) => {
    button.removeAllHandlers();
    button.addHandler("press", handleViewerButtonZoom(button.onPress));
    button.addHandler("release", handleViewerButtonZoom(button.onRelease));
    button.addHandler("click", handleViewerButtonZoom(button.onClick));
    button.addHandler("enter", handleViewerButtonZoom(button.onEnter));
    button.addHandler("exit", handleViewerButtonZoom(button.onExit));
    button.addHandler("focus", handleViewerButtonZoom(button.onFocus));
    button.addHandler("blur", handleViewerButtonZoom(button.onBlur));
  });
};

const handleViewerButtonZoom = (handler) => {
  return (...args) => {
    try {
      handler(...args);
    } catch (e) {
      if (e instanceof TypeError) {
        console.info(
          "You see this message cause OSD <v3.0.0 has inner bug causing spam Sentry service." +
            "Please don't forget to update OSD once OSD Annotorious plugin SHIFT drawing will be fixed."
        );
      } else {
        throw e;
      }
    }
  };
};

export const isQCScoreFailed = (qcScore) => {
  return qcScore >= MIN_SCORE_QC_FAILED;
};

export const isQCScorePassed = (qcScore) => {
  return qcScore <= MAX_SCORE_QC_PASSED;
};

export const getImagePositionFromViewportPosition = (viewer, position) => {
  const osdPoint = new OpenSeadragon.Point(position.x, position.y);
  const viewportPoint = viewer.viewport.pointFromPixel(osdPoint);
  const imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint);

  return imagePoint;
};

export const getDistanceBetweenTwoSlidePoints = (
  position1,
  position2,
  viewer
) => {
  const imagePoint1 = getImagePositionFromViewportPosition(viewer, position1);
  const imagePoint2 = getImagePositionFromViewportPosition(viewer, position2);
  const pixelsPerMeter = viewer.pixelsPerMeter;

  const distancePx = Math.hypot(
    imagePoint2.x - imagePoint1.x,
    imagePoint2.y - imagePoint1.y
  );

  const distanceInMeters = distancePx / pixelsPerMeter;
  const distanceInMm = distanceInMeters * 1000;
  const distanceInMicrometers = distanceInMm * 1000;

  return {
    m: distanceInMeters,
    mm: distanceInMm,
    micrometers: distanceInMicrometers,
  };
};

export const getDistanceMessage = (distance, precision = 3) => {
  const units = [
    { unit: "km", factor: 1 / 1000000 },
    { unit: "m", factor: 1 / 1000 },
    { unit: "cm", factor: 1 / 10 },
    { unit: "mm", factor: 1 },
    { unit: "μm", factor: 1000 },
    { unit: "nm", factor: 1000000 },
  ];

  const defaultUnit = units[3];

  const valueInMM = distance.mm;

  const { unit, factor } =
    units.find(({ factor }) => {
      const valueInCurrentUnit = valueInMM * factor;
      // make sure the value is between 1 and 1000
      return valueInCurrentUnit >= 1 && valueInCurrentUnit < 1000;
    }) || defaultUnit;

  const value = valueInMM * factor;

  return `${value.toPrecision(precision)} ${unit}`;
};

const getResizedCanvas = (canvas, newWidth, newHeight) => {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
};

export const handleViewerSlideScreenshot = (
  slide,
  viewer,
  distanceMeasureManager,
  resizeValues,
  extAnnotationsManager,
  isExtAnnotationsEnabled
) => {
  if (!viewer || !slide) {
    return;
  }

  const currentDateFormatted = getCurrentDateFormatted();
  const serializedSlideName = slide.name.replace(".svs", "").replace(/ /g, "_");
  const fileName = `${currentDateFormatted}_${serializedSlideName}.png`;
  const scalebarInstance = viewer.scalebarInstance;
  const scalebarCanvas = scalebarInstance.getImageWithScalebarAsCanvas();

  const mainCanvas = getViewerImageWithAnnotationsAsCanvas(
    scalebarCanvas,
    viewer
  );

  if (distanceMeasureManager) {
    const distanceMeasureCanvas = distanceMeasureManager.canvas.getElement();
    const mainCanvasContext = mainCanvas.getContext("2d");
    mainCanvasContext.drawImage(distanceMeasureCanvas, 0, 0);
  }

  if (isExtAnnotationsEnabled) {
    extAnnotationsManager.currentModels.forEach((model) => {
      const canvas = model.canvas.upperCanvasEl;
      const ctx = mainCanvas.getContext("2d");
      ctx.drawImage(canvas, 0, 0);
    });
  }

  const resizedCanvas = resizeValues
    ? getResizedCanvas(mainCanvas, resizeValues.width, resizeValues.height)
    : mainCanvas;

  resizedCanvas.toBlob((blob) => {
    FileSaver.saveAs(blob, fileName);
  });
};

export const micronsPerPixelToPixelsPerMeter = (micronsPerPixel) => {
  if (!micronsPerPixel) {
    return SLIDE_VIEWER_PIXELS_PER_METER;
  }

  const metersPerPixel = micronsPerPixel * 1e-6;
  return 1 / metersPerPixel;
};

export class DistanceMeasure {
  constructor(viewer, overlay, canvas) {
    this.imageMeasurePoints = [];
    this.viewer = viewer;
    this.overlay = overlay;
    this.canvas = canvas;
    this.measuredDistance = undefined;
    this.lastDrawnObjects = [];
    this.startPoint = undefined;
    this.isMeasureFinished = false;
  }

  startDraw(position) {
    this.imageMeasurePoints = [];

    const point = createFabricPoint(position.x, position.y);
    this.canvas.add(point);
    const imagePosition = getImagePositionFromViewportPosition(
      this.viewer,
      position
    );
    this.imageMeasurePoints.push(imagePosition);

    this.startPoint = point;
  }

  drawMeasureLine(position2) {
    const position1 = getViewerContainerPositionFromImagePosition(
      this.viewer,
      this.imageMeasurePoints[0]
    );

    this.canvas.remove(...this.lastDrawnObjects);

    const distance = this.isMeasureFinished
      ? this.measuredDistance
      : getDistanceBetweenTwoSlidePoints(position1, position2, this.viewer);

    this.measuredDistance = distance;

    const distanceMessage = getDistanceMessage(distance);

    const line = new fabric.Line(
      [position1.x, position1.y, position2.x, position2.y],
      {
        strokeWidth: DEFAULT_LINE_WIDTH,
        stroke: DEFAULT_LINE_COLOR,
        lockMovementX: true,
        lockMovementY: true,
        originX: "center",
        originY: "center",
        shadow: DEFAULT_SHADOW,
      }
    );

    const text = new fabric.Text(distanceMessage, {
      fontSize: DEFAULT_FONT_SIZE,
      fontWeight: "bold",
      fontFamily: "Helvetica",
      left: (position2.x + position1.x) / 2,
      top: (position2.y + position1.y) / 2,
      fill: DEFAULT_FABRIC_FONT_COLOR,
      textAlign: "center",
      shadow: DEFAULT_SHADOW,
    });

    this.canvas.add(line, text);

    this.canvas.sendToBack(line);
    this.canvas.bringToFront(text);

    this.lastDrawnObjects = [line, text];
  }

  endDraw(position) {
    // fallback for cases when user clicks on the same point twice
    this.drawMeasureLine(position);

    const point = createFabricPoint(position.x, position.y);

    this.canvas.add(point);

    const imagePosition = getImagePositionFromViewportPosition(
      this.viewer,
      position
    );
    this.imageMeasurePoints.push(imagePosition);

    this.lastDrawnObjects.push(point);
    this.isMeasureFinished = true;
  }

  resize() {
    if (this.isMeasureFinished) {
      this.clear();

      const imagePoints = [...this.imageMeasurePoints];

      const point1 = getViewerContainerPositionFromImagePosition(
        this.viewer,
        imagePoints[0]
      );
      const point2 = getViewerContainerPositionFromImagePosition(
        this.viewer,
        imagePoints[1]
      );

      this.startDraw(point1);
      this.drawMeasureLine(point2);
      this.endDraw(point2);
    } else {
      this.canvas.remove(this.startPoint);
      const point = getViewerContainerPositionFromImagePosition(
        this.viewer,
        this.imageMeasurePoints[0]
      );
      this.startDraw(point);
    }
  }

  clear() {
    this.canvas.remove(...this.lastDrawnObjects, this.startPoint);
  }

  show() {
    const objectsToShow = [this.startPoint, ...this.lastDrawnObjects];

    if (!objectsToShow.length) {
      return;
    }
    this.canvas.add(...objectsToShow);
    this.resize();
  }
}

export class DistanceMeasureManager {
  constructor(viewer, isMeasureEnabled) {
    this.viewer = viewer;
    this.isMeasureEnabled = isMeasureEnabled;
    this.measurments = [];
    this.isMeasuring = false;
    this.currentMeasure = undefined;
    this.overlay = undefined;
    this.canvas = undefined;
    this.drawLineMouseTracker = undefined;
  }

  init() {
    this.overlay = this.viewer.fabricjsOverlay({ scale: 1000 });
    this.canvas = this.overlay.fabricCanvas();
    this.addListeners();
  }

  addListeners() {
    const eventsToPreventZooming = [
      "canvas-click",
      // it seems like for some users canvas-drag fires before canvas-click causing measuring to fail
      // "canvas-drag"
    ];

    eventsToPreventZooming.forEach((eventName) => {
      this.viewer.addHandler(eventName, (e) => {
        if (!this.isMeasureEnabled) {
          return;
        }
        e.preventDefaultAction = true;
      });
    });

    // handling dragging to display current measure line properly
    this.viewer.addHandler("canvas-drag", (e) => {
      if (!(this.isMeasureEnabled && this.isMeasuring)) {
        return;
      }

      this.currentMeasure.drawMeasureLine(e.position);
    });

    this.viewer.addHandler("viewport-change", () => {
      this.measurments.length && this.resize();
    });

    this.viewer.addHandler("canvas-click", (e) => {
      // e.quick tells that click wasn't fired on canvas-drag
      if (!(this.isMeasureEnabled && e.quick)) {
        return;
      }

      if (!this.isMeasuring) {
        this.currentMeasure = new DistanceMeasure(
          this.viewer,
          this.overlay,
          this.canvas
        );
        this.measurments.push(this.currentMeasure);

        this.currentMeasure.startDraw(e.position);
        this.isMeasuring = true;
      } else {
        this.currentMeasure.endDraw(e.position);
        this.isMeasuring = false;
      }
    });

    this.drawLineMouseTracker = new OpenSeadragon.MouseTracker({
      element: this.viewer.container,
      moveHandler: debounce((e) => {
        if (!this.isMeasuring) {
          return;
        }

        this.currentMeasure.drawMeasureLine(e.position);
      }, 5),
      keyUpHandler: (e) => {
        if (e.keyCode === CANCEL_CURRENT_MEASURE_KEY_CODE && this.isMeasuring) {
          e.preventDefault = true;
          this.cancelMeasuring();
        }
      },
    });
  }

  cancelMeasuring() {
    this.currentMeasure.clear();
    this.isMeasuring = false;
    this.currentMeasure = undefined;
    this.measurments.pop();
  }

  reset() {
    this.measurments.forEach((measurement) => measurement.clear());
    this.measurments = [];
    this.isMeasuring = false;
    this.currentMeasure = undefined;
  }

  resize() {
    this.measurments.forEach((measurement) => measurement.resize());
  }

  hide() {
    this.isMeasuring && this.cancelMeasuring();
    this.measurments.forEach((measurement) => measurement.clear());
  }

  show() {
    this.measurments.forEach((measurement) => measurement.show());
  }

  destroy() {
    this.canvas?.dispose();
    this.drawLineMouseTracker?.destroy();
  }
}

export const splitAnnotationCommentIntoLines = (comment) => {
  let lineSliceStartIndex = 0;
  let lineSliceEndIndex = MAX_COMMENT_ONE_LINE_LENGTH;

  const commentLinesCount = Math.ceil(
    comment.length / MAX_COMMENT_ONE_LINE_LENGTH
  );
  const commentLines = range(commentLinesCount).map(() => {
    const commentLine = comment.slice(lineSliceStartIndex, lineSliceEndIndex);

    lineSliceStartIndex = lineSliceEndIndex;
    lineSliceEndIndex += MAX_COMMENT_ONE_LINE_LENGTH;

    return commentLine;
  });

  return commentLines;
};

export const drawAnnotationCommentLines = (
  ctx,
  commentLines,
  startPosition
) => {
  const commentPosition = { ...startPosition };

  commentLines.forEach((commentLine) => {
    ctx.font = ANNOTATION_COMMENT_CONTENT_FONT;
    ctx.fillText(commentLine, commentPosition.x, commentPosition.y);
    commentPosition.y += ANNOTATION_COMMENT_LINE_OFFSET_Y;
  });
};

export const drawCanvasAnnotationComments = (ctx, comments, startPosition) => {
  const commentPosition = {
    x: startPosition.x,
    y: startPosition.y + ANNOTATION_COMMENTS_INITIAL_OFFSET_Y,
  };

  comments.forEach((comment) => {
    const commentLines = splitAnnotationCommentIntoLines(comment.value);
    drawAnnotationCommentLines(ctx, commentLines, commentPosition);

    const commentsOffsetY =
      commentLines.length * ANNOTATION_COMMENT_LINE_OFFSET_Y;

    ctx.font = ANNOTATION_COMMENT_AUTHOR_FONT;
    const commentAuthor = `${comment.user.first_name} ${comment.user.last_name}`;

    const authorPositionY =
      commentPosition.y + commentsOffsetY - ANNOTATION_COMMENT_AUTHOR_OFFSET_Y;
    ctx.fillText(commentAuthor, commentPosition.x, authorPositionY);
    commentPosition.y =
      authorPositionY + ANNOTATION_COMMENT_NEW_COMMENT_OFFSET_Y;
  });
};

export const getViewerImageWithAnnotationsAsCanvas = (viewerCanvas, viewer) => {
  const { widthScaleFactor, heightScaleFactor } = getViewerScaleFactor(viewer);
  const allAnnotations = [
    ...viewer.container.getElementsByClassName("a9s-annotation"),
  ];

  const objectsToDraw = allAnnotations.map((annotationEl) => {
    const svgEl = annotationEl.firstChild;

    if (!svgEl || svgEl?.tagName === "g") return [];

    const rawPoints =
      svgEl.tagName === "rect"
        ? getSvgRectElementCoordinates(svgEl)
        : [...svgEl.animatedPoints];

    const viewportPoints = rawPoints.map((point) => {
      const osdPoint = new OpenSeadragon.Point(point.x, point.y);
      const viewportPoint =
        viewer.viewport.imageToViewerElementCoordinates(osdPoint);
      return viewportPoint;
    });

    return viewportPoints;
  });

  const commentsToDisplay = allAnnotations.map((annotationEl) => {
    const recentComments = [...annotationEl.annotation.underlying.body].slice(
      -3
    );
    return recentComments;
  });

  const ctx = viewerCanvas.getContext("2d");

  ctx.lineWidth = ANNOTATION_OBJECT_LINE_WIDTH;
  ctx.strokeStyle = ANNOTATION_OBJECT_LINE_COLOR;

  objectsToDraw.forEach((instancePoints, index) => {
    ctx.beginPath();

    instancePoints.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point.x * widthScaleFactor, point.y * heightScaleFactor);
      } else {
        ctx.lineTo(point.x * widthScaleFactor, point.y * heightScaleFactor);
      }
    });

    ctx.closePath();
    ctx.stroke();

    const maxY =
      Math.max(...instancePoints.map((point) => point.y)) * heightScaleFactor;
    const minX =
      Math.min(...instancePoints.map((point) => point.x)) * widthScaleFactor;

    drawCanvasAnnotationComments(ctx, commentsToDisplay[index], {
      x: minX,
      y: maxY,
    });
  });

  return viewerCanvas;
};

// in some cases when viewer is resized image canvas width differs from container width
// leading to wrong objects location (actually its width) on the resulting image
export const getViewerScaleFactor = (viewer) => {
  const imgCanvas = viewer.drawer.canvas;

  const widthScaleFactor = imgCanvas.width / viewer.container.offsetWidth;
  const heightScaleFactor = imgCanvas.height / viewer.container.offsetHeight;

  return {
    widthScaleFactor,
    heightScaleFactor,
  };
};

export const addDefaultViewerEventListeners = (
  viewer,
  { setSlideOpened, setZoom, actualZoomRatio, setActualZoomRatio, viewerHelper }
) => {
  const handleZoomEvent = (event) => {
    if (!event.eventSource?.source) return;

    const actualZoom = event.zoom;
    const maxActualZoom =
      actualZoomRatio > 0
        ? actualZoomRatio * MAX_ZOOM_LEVEL_FOR_BASE_VIEWER
        : event.zoom;

    if (actualZoom > maxActualZoom) {
      const zoomVal = parseFloat(maxActualZoom);
      viewer.viewport.zoomTo(zoomVal, null, false);
    }

    setZoom(actualZoom > maxActualZoom ? maxActualZoom : actualZoom);
  };

  const handleResizeEvent = (event) => {
    if (!event.eventSource?.source) return;

    setMaximumZoomToViewerAndViewport(viewer, viewerHelper, setActualZoomRatio);
  };

  const handleOpenEvent = () => {
    const actualZoom = viewer?.viewport?.getZoom() || 0;
    viewerHelper.setMaxZoom(1);
    const maxZoom = viewerHelper.getMaxZoomLevel();

    if (actualZoom > maxZoom) {
      viewer.viewport.zoomTo(maxZoom, null, false);
      setZoom(maxZoom);
    }

    setSlideOpened(true);

    setMaximumZoomToViewerAndViewport(viewer, viewerHelper, setActualZoomRatio);
    if (!window.location.hash) {
      viewer.viewport.fitVertically(true);
    }
  };

  viewer.addHandler("zoom", (e) => setTimeout(() => handleZoomEvent(e), 0));
  viewer.addHandler("resize", (e) => setTimeout(() => handleResizeEvent(e), 0));

  viewer.addHandler("open", handleOpenEvent);
  viewer.addHandler("close", () => setSlideOpened(false));

  viewer.addHandler("canvas-drag", () => {
    document.body.style.cursor = "grabbing";
  });
  viewer.addHandler("canvas-drag-end", () => {
    document.body.style.cursor = "default";
  });
};

export const getOpenOSDViewers = () => {
  const openViewers = document.querySelectorAll(".openseadragon");
  return openViewers;
};

export const isFullScreenViewerExist = () =>
  getOpenOSDViewers()?.[0]?.classList.contains("fullpage");

export const setRowSelectionVisible = (gridApi) => {
  const selectedRowIndex = gridApi.getSelectedNodes()?.[0]?.rowIndex;

  if (!selectedRowIndex) return;

  const allRowsCount = gridApi.getDisplayedRowCount();

  const indexToShow =
    selectedRowIndex === allRowsCount - 1
      ? selectedRowIndex
      : selectedRowIndex + 1;

  gridApi.ensureIndexVisible(indexToShow);
};

const processJSON = (
  annotationAttachment,
  colorLegendFilter,
  setColorLegend,
  extAnnoDrawingRef
) => {
  let colors = [];
  annotationAttachment.features.forEach((feature, i) => {
    const { stroke } = convertToRGBA(
      feature.properties?.classification?.colorRGB
    );

    colors.push({
      label: feature.properties?.classification?.name,
      color: stroke,
      isSelected: !colorLegendFilter.includes(stroke),
    });

    // Set unique colors
    setColorLegend(
      colors.filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            ({ color, name }) => color === obj.color && name === obj.name
          )
      )
    );

    const modelName = feature.properties?.classification?.name + i;

    const model = extAnnoDrawingRef.current.getOrCreateModel(
      modelName,
      feature
    );
    if (colorLegendFilter.includes(stroke)) {
      model.hide();
    } else {
      model.drawFeature(feature);
      model.isShown = true;
    }
  });
};

const processXML = (
  xmlData,
  colorLegendFilter,
  setColorLegend,
  extAnnoDrawingRef,
  setIsAnnotationTextAvailable,
  showAnnotationText,
  setAnnotationsLegend
) => {
  let colors = [];
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlData, "text/xml");
  const annotations = xmlDoc.getElementsByTagName("Annotation");

  if (showAnnotationText) {
    const annotationsLegend = Array.from(annotations).map((annotation) => {
      const { stroke: color } = convertToRGBA(
        parseInt(annotation.getAttribute("LineColor"))
      );
      return {
        name: annotation.getAttribute("Name"),
        id: annotation.getAttribute("Id"),
        color,
        regions: Array.from(annotation.getElementsByTagName("Region")).map(
          (region) => ({
            id: region.getAttribute("Id"),
            area: region.getAttribute("Area"),
            length: region.getAttribute("Length"),
            text: region.getAttribute("Text"),
          })
        ),
      };
    });
    setAnnotationsLegend(annotationsLegend);
  }

  Array.from(annotations).forEach((annotation, i) => {
    const name = annotation.getAttribute("Name");
    const colorRGB = parseInt(annotation.getAttribute("LineColor"));
    const { stroke } = convertToRGBA(colorRGB);

    colors.push({
      label: name,
      color: stroke,
      isSelected: !colorLegendFilter.includes(stroke),
    });

    // Set unique colors
    setColorLegend(
      colors.filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            ({ color, name }) => color === obj.color && name === obj.name
          )
      )
    );

    const modelName = name + i;
    const model = extAnnoDrawingRef.current.getOrCreateModel(
      modelName,
      annotation
    );

    if (colorLegendFilter.includes(stroke)) {
      model.hide();
    } else {
      model.showAnnotationText = showAnnotationText;
      model.drawFeatureFromXML(annotation, setIsAnnotationTextAvailable);
      model.isShown = true;
    }
  });
};

export const handleInitExternalAnnotations = (
  isExternalAnnotationsEnabled,
  colorLegendFilter,
  extAnnoDrawingRef,
  annotationAttachment,
  setColorLegend,
  setIsAnnotationTextAvailable,
  showAnnotationText,
  setAnnotationsLegend
) => {
  if (!extAnnoDrawingRef.current || !annotationAttachment) {
    return;
  }

  extAnnoDrawingRef.current.currentModels.forEach((model) => {
    model.hide();
  });
  if (!isExternalAnnotationsEnabled) {
    return;
  }
  if (annotationAttachment.type === "FeatureCollection") {
    processJSON(
      annotationAttachment,
      colorLegendFilter,
      setColorLegend,
      extAnnoDrawingRef
    );
  } else {
    processXML(
      annotationAttachment,
      colorLegendFilter,
      setColorLegend,
      extAnnoDrawingRef,
      setIsAnnotationTextAvailable,
      showAnnotationText,
      setAnnotationsLegend
    );
  }
};

export const getPathologyMapSearchParamsURL = ({ slide }) => {
  const searchParams = [];
  const { sample } = slide;

  let pathologyMapSearchURL = `${appURL}${PATHOLOGY_MAP_SEARCH}`;

  if (!sample) {
    return pathologyMapSearchURL;
  }

  if (sample.organ) {
    searchParams.push(`organ_name=${sample.organ.name}`);
  }

  if (sample.species) {
    searchParams.push(`species_name=${sample.species.name}`);
  }

  if (searchParams.length === 0) {
    return pathologyMapSearchURL;
  }

  const serializedParams = searchParams.join("&");

  pathologyMapSearchURL = pathologyMapSearchURL + `?${serializedParams}`;
  return pathologyMapSearchURL;
};
