import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { uploadSpecialInstructionsAttachments } from "services/resources/commonResources";
import { Tooltip, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { FormInputInfoTooltip } from "components/OrderForm/components/FormInputInfoTooltip";
import { UPLOAD_SPECIAL_INSTRUCTIONS_ERROR_MESSAGE } from "constants/errorMessages";
import { CheckmarkProvider } from "components/OrderForm/components/CheckmarkProvider";
import { inputStyles } from "components/OrderForm/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { HELP_TOOLTIP_TEXT } from "components/OrderForm/components/constants";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

const useStyles = makeStyles()(inputStyles);

const dropZoneStyles = () => ({
  dropZoneContainer: {
    marginTop: "1rem",
    border: "1px",
    borderStyle: "dashed",
    borderColor: "black",
    padding: "5px",
  },
  dropZoneLabel: {
    margin: "1rem",
  },
  dropZone: {
    width: "100%",
  },
});

export const ErrorMessage = ({ error }) => {
  const { classes } = useStyles();

  return error ? (
    <div className={classes.errorFeedback}>{error.message}</div>
  ) : null;
};

const useDropzoneStyles = makeStyles()(dropZoneStyles);

export const NumberFormInput = ({
  formLabel,
  formPlaceholder,
  name,
  infoText,
  checkmark,
  className,
  disabled,
  required,
  icon = null,
}) => {
  const {
    control,
    register,
    formState: { submitCount },
  } = useFormContext();

  const {
    fieldState: { error, isTouched },
  } = useController({ name, control });

  const { classes } = useStyles();

  const isValidated = isTouched || submitCount !== 0;
  const isDisplayValidation = isValidated && checkmark && !disabled;

  const borderStyles = error ? classes.error : classes.validated;
  const baseInputStyles = isDisplayValidation
    ? `${classes.formInput} ${borderStyles}`
    : classes.formInput;
  const inputStyles = className
    ? `${baseInputStyles} ${className}`
    : baseInputStyles;

  return (
    <div>
      <Typography>
        <label className={classes.formLabel}>
          {formLabel}
          {required && <span className={classes.requiredAsterisk}>*</span>}
          {infoText && <FormInputInfoTooltip infoText={infoText} />}
          {icon}
        </label>
      </Typography>
      <CheckmarkProvider
        isValid={!error}
        validated={isValidated}
        visible={checkmark}
      >
        <input
          placeholder={formPlaceholder}
          type="number"
          min={0}
          disabled={disabled}
          name={name}
          {...register(name)}
          className={inputStyles}
        />
      </CheckmarkProvider>
      {isDisplayValidation && <ErrorMessage error={error} />}
    </div>
  );
};

export const FixationTimeHoursInput = ({ name }) => {
  const { classes } = useStyles();

  const HelpIcon = () => (
    <Tooltip title={HELP_TOOLTIP_TEXT}>
      <HelpOutlineIcon className={classes.helpIcon} />
    </Tooltip>
  );

  return (
    <>
      <NumberFormInput
        name={name}
        formLabel={"Fixation Time (Hours)"}
        formPlaceholder={"Hours"}
        checkmark
        required
        icon={<HelpIcon />}
      />
    </>
  );
};

export const SectionsPerSlideInput = ({ name }) => {
  return (
    <NumberFormInput
      name={name}
      formLabel={"Sections Per Slide"}
      formPlaceholder={"per slide .."}
      checkmark
    />
  );
};

export const AdditionalStepsPerSampleInput = ({ name }) => {
  return (
    <NumberFormInput
      name={name}
      formLabel={"Additional Steps/Levels Per Sample"}
      formPlaceholder={"per sample .."}
      checkmark
      //infoText={"This is the number of additional steps/levels per sample."}
    />
  );
};

export const SectionThicknessMicronsInput = ({ name }) => {
  return (
    <NumberFormInput
      name={name}
      formLabel={"Section Thickness (Microns)"}
      formPlaceholder={"per sample .."}
      checkmark
    />
  );
};

export const SpecialInstructionsInput = ({ name }) => {
  return (
    <TextFormInput
      name={name}
      formLabel={"Special Instructions"}
      formPlaceholder={
        "Eg. Need Necropsy, Cut Consecutive Sections, Only Stain Sections 2 and 5, Embed Samples Sagittally, etc ..."
      }
      checkmark
    />
  );
};

export const PurposeOfStudyInput = ({ isRequired }) => (
  <TextFormInput
    name="purpose_of_study"
    formLabel={`Purpose Of Study`}
    formPlaceholder="Eg. Research Liver Tissue"
    checkmark
    isRequired={isRequired}
  />
);

export const RegionOfInterestInput = () => (
  <TextFormInput
    name="region_of_interest"
    formLabel={`Region Of Interest`}
    formPlaceholder="Not required. If your study has a specific region of interest, please enter it here..."
    checkmark
    isRequired={false}
  />
);

export const ExpectedPhenotypeInput = ({ name }) => {
  return (
    <TextFormInput
      name={name}
      formLabel={"Expected Phenotype (Optional)"}
      formPlaceholder={""}
    />
  );
};

export const ExperimentalTreatmentInput = ({ name }) => {
  return (
    <TextFormInput
      name={name}
      formLabel={"Expected Treatment (Optional)"}
      formPlaceholder={""}
    />
  );
};

export const ExpectedStainingPatternInput = ({ name }) => {
  return (
    <TextFormInput
      name={name}
      formLabel={"Expected Staining Pattern (Optional)"}
      formPlaceholder={""}
    />
  );
};

export const CellTargetInput = ({ name }) => {
  return (
    <TextFormInput
      name={name}
      formLabel={"Cell Target (Optional)"}
      formPlaceholder={""}
    />
  );
};

export const SpecialInstructionsFileUpload = ({ name, team }) => {
  const { classes, cx } = useDropzoneStyles();
  const { showError } = useSnackbar();
  const { control } = useFormContext();
  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = (value) => {
    onChange(value);
  };

  const onDrop = (acceptedFiles) => {
    const team_uuid = team.value;

    let formData = new FormData();
    const file = acceptedFiles[0];

    if (file) {
      const file_name = file.name;

      // we only accept one file so it doesn't really matter
      formData.append("file", file);
      formData.append("file_name", file_name);
      formData.append("team_uuid", team_uuid);

      uploadSpecialInstructionsAttachments(formData)
        .then((response) => {
          const specialInstructionsAttachment = {
            label: response.data.name,
            value: response.data.uuid,
            url: response.data.url,
          };

          handleChange(specialInstructionsAttachment);
        })
        .catch(() => showError(UPLOAD_SPECIAL_INSTRUCTIONS_ERROR_MESSAGE));
    }
  };

  return (
    <div className={classes.dropZoneContainer}>
      <Dropzone
        className={classes.dropZone}
        onDrop={onDrop}
        multiple={false}
        accept={"application/pdf,.xls,.xlsx,.csv,.jpg,.jpeg,.png"}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={cx("dropzone", {
                "dropzone--isActive": isDragActive,
              })}
            >
              <input {...getInputProps()} />
              <Typography>
                <label className={classes.dropZoneLabel}>
                  {
                    "Click here to upload Special Instructions instead. Only PDF / Excel / JPG / PNG files are accepted."
                  }
                </label>
              </Typography>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

const TextFormInput = ({
  formLabel,
  formPlaceholder,
  rows,
  name,
  infoText,
  checkmark,
  className,
  disabled,
  isRequired,
}) => {
  const { classes } = useStyles();

  const {
    control,
    register,
    formState: { submitCount },
  } = useFormContext();

  const {
    fieldState: { isTouched, error },
  } = useController({ name, control });

  const rowLength = rows || 5;

  const isValidated = isTouched || submitCount !== 0;
  const isDisplayValidation = isValidated && checkmark && !disabled;

  const borderStyles = error ? classes.error : classes.validated;
  const baseInputStyles = isDisplayValidation
    ? `${classes.formInput} ${borderStyles}`
    : classes.formInput;
  const inputStyles = className
    ? `${baseInputStyles} ${className}`
    : baseInputStyles;

  return (
    <div>
      <Typography>
        <label className={classes.formLabel}>{formLabel}</label>
        {isRequired && <span className={classes.requiredAsterisk}>*</span>}
        {infoText && <FormInputInfoTooltip infoText={infoText} />}
      </Typography>
      <CheckmarkProvider
        isValid={!error}
        validated={isValidated}
        visible={isDisplayValidation}
        className={classes.alignTop}
      >
        <textarea
          placeholder={formPlaceholder}
          name={name}
          rows={rowLength}
          {...register(name)}
          className={inputStyles}
          disabled={disabled}
        />
      </CheckmarkProvider>
      {isDisplayValidation && <ErrorMessage error={error} />}
    </div>
  );
};

TextFormInput.propTypes = {
  formLabel: PropTypes.string.isRequired,
  formPlaceholder: PropTypes.string.isRequired,
};

const FormInput = (props) => {
  const { handleChange, handleBlur } = props;
  const {
    formLabel,
    formId,
    formPlaceholder,
    formInput,
    formValue,
    formErrors,
    formTouched,
    infoText,
  } = props;
  const { classes } = useStyles();

  return (
    <div>
      <Typography>
        <label className={classes.formLabel}>{formLabel}</label>
        {infoText && <FormInputInfoTooltip infoText={infoText} />}
      </Typography>
      <input
        // id is necessary for react formik to handle changes
        id={formId}
        placeholder={formPlaceholder}
        type={formInput}
        value={formValue}
        onChange={handleChange}
        onBlur={handleBlur}
        className={
          formErrors && formTouched
            ? classes.formInput + " " + classes.error
            : classes.formInput
        }
      />
      {formErrors && formTouched && (
        <div className={classes.errorFeedback}>{formErrors}</div>
      )}
    </div>
  );
};

FormInput.propTypes = {
  formLabel: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formPlaceholder: PropTypes.string.isRequired,
  formInput: PropTypes.string.isRequired,
  formValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const FormInputV2 = ({
  formLabel,
  formPlaceholder,
  formInput,
  name,
  disabled = false,
  infoText,
  checkmark,
  validated,
  className,
  onKeyUp,
}) => {
  const { classes } = useStyles();

  const {
    control,
    register,
    formState: { submitCount },
  } = useFormContext();

  const {
    fieldState: { isTouched, error },
  } = useController({ name, control });

  const isValidated = isTouched || validated || submitCount !== 0;
  const isDisplayValidation = isValidated && checkmark && !disabled;

  const borderStyles = error ? classes.error : classes.validated;
  const baseInputStyles = isDisplayValidation
    ? `${classes.formInput} ${borderStyles}`
    : classes.formInput;
  const inputStyles = className
    ? `${baseInputStyles} ${className}`
    : baseInputStyles;

  return (
    <div className={classes.inputWrapper}>
      <Typography>
        <label className={classes.formLabel}>{formLabel}</label>
        {infoText && <FormInputInfoTooltip infoText={infoText} />}
      </Typography>
      <CheckmarkProvider
        isValid={!error}
        validated={isValidated}
        visible={isDisplayValidation}
      >
        <input
          placeholder={formPlaceholder}
          type={formInput}
          {...register(name)}
          disabled={disabled}
          className={inputStyles}
          onKeyUp={onKeyUp}
        />
      </CheckmarkProvider>
      {isDisplayValidation && <ErrorMessage error={error} />}
    </div>
  );
};

export { TextFormInput, FormInput };
