import { TeamSelector } from "components/common/TeamSelector/TeamSelector";
import { ErrorOutline } from "@mui/icons-material";
import React, { memo, useMemo } from "react";
import { Card } from "components/common/Card/Card";
import { TeamOverviewContent } from "components/UserProfile/TeamOverviewCard/TeamOverviewContent";
import { Skeleton } from "@mui/material";
import { CardHeader } from "components/UserProfile/components/CardHeader";
import { buildTeamInfo } from "components/UserProfile/utilities";
import { useDispatch } from "react-redux";
import { setForceOpenTeamOverviewModal } from "store/slices/userDetailsSlice";

export const TeamOverviewCard = memo(
  ({ selectedTeam, setSelectedTeam, teams }) => {
    const teamInfo = useMemo(() => buildTeamInfo(selectedTeam), [selectedTeam]);
    const dispatch = useDispatch();

    const subtitle = selectedTeam ? (
      teamInfo
    ) : (
      <Skeleton variant="text" width={200} />
    );

    const rightSection = (
      <ErrorOutline
        color="error"
        fontSize="large"
        onClick={() => dispatch(setForceOpenTeamOverviewModal(true))}
      />
    );

    return (
      <Card
        headerContent={
          <CardHeader
            title={
              <TeamSelector
                teams={teams}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
              />
            }
            subtitle={subtitle}
            RightSection={rightSection}
          />
        }
        bodyContent={<TeamOverviewContent team={selectedTeam} />}
      />
    );
  }
);
