import CloseIcon from "@mui/icons-material/Close";
import React, { useContext } from "react";
import { AppContext } from "services/context";

import { IconButton, Snackbar } from "@mui/material";

export const SnackbarWithContext = ({ message, snackbarString }) => {
  const context = useContext(AppContext);
  const contextModalChange = context.handleContextModalChange;

  const onClose = () => {
    // if it's open, then close it!
    if (context[snackbarString]) {
      contextModalChange(snackbarString)();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={context[snackbarString]}
      autoHideDuration={6000}
      onClose={onClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">{message}</span>}
      action={[
        <IconButton
          key="close"
          size="large"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};
