import { useState } from "react";
import { SamplesPageV2 } from "components/OrderForm/SamplesFormV2/SamplesPageV2";
import { SamplesPageV1 } from "components/OrderForm/SamplesFormV1/SamplesPageV1";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { BetaIcon } from "components/Shared/Icons/BetaIcon";
import { useStyles } from "components/OrderForm/SamplesPageWrapper/styles";
import { getFromLS, saveToLS } from "components/OrderSlidesList/utilities";
import { SAMPLES_PAGE_V2_VIEW } from "components/OrderForm/constants";

export const SamplesPageWrapper = () => {
  const { classes } = useStyles();
  const pageViewFromLS =
    typeof getFromLS(SAMPLES_PAGE_V2_VIEW) === "object"
      ? false
      : getFromLS(SAMPLES_PAGE_V2_VIEW);
  const [isV2, setIsV2] = useState(pageViewFromLS);

  const onChangeSwitch = (event) => {
    saveToLS(SAMPLES_PAGE_V2_VIEW, event.target.checked);
    setIsV2(event.target.checked);
  };
  return (
    <Box className={classes.root}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        className={classes.switchWrapper}
      >
        <Typography>Legacy Order Form</Typography>
        <Switch size="small" checked={isV2} onChange={onChangeSwitch} />
        <Typography className={classes.betaWrapper}>
          <Box>New Order Form </Box> <BetaIcon />
        </Typography>
      </Stack>

      {isV2 ? <SamplesPageV2 /> : <SamplesPageV1 />}
    </Box>
  );
};
