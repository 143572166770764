import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { IHC_ANTIBODIES_EXTERNAL_URL, LOGIN_URL } from "constants/urls";
import { LoginPage } from "components/Login/LoginPage";
import {
  AuthenticatedRouteDetails,
  AuthenticatedRouteDetailsKeys,
  BasicRouteDetails,
  BasicRouteDetailsKeys,
} from "constants/routes";
import { getLocalToken } from "utilities/auth";
import {
  useGetTeamDetailsQuery,
  useGetUserDetailsQuery,
} from "store/apis/userDetailsApi";
import { FETCH_TEAMS_ERROR_MESSAGE } from "constants/errorMessages";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import { AntibodiesPage } from "components/AntibodiesList/AntibodiesPage";

const hist = createBrowserHistory();

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getLocalToken() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: LOGIN_URL,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export const RouterRoutes = () => {
  const isToken = getLocalToken();
  useGetUserDetailsQuery(undefined, { skip: !isToken });
  const { error } = useGetTeamDetailsQuery(undefined, { skip: !isToken });
  useQueryError(error, FETCH_TEAMS_ERROR_MESSAGE);

  return (
    <Router history={hist}>
      <Switch>
        {BasicRouteDetailsKeys.map((path) => {
          const component = BasicRouteDetails[path];
          return <Route exact path={path} component={component} key={path} />;
        })}
        {AuthenticatedRouteDetailsKeys.map((path) => {
          const component = AuthenticatedRouteDetails[path];
          return (
            <AuthenticatedRoute
              exact
              path={path}
              component={component}
              key={path}
            />
          );
        })}
        <Route path={IHC_ANTIBODIES_EXTERNAL_URL} component={AntibodiesPage} />
        <Route path="/" component={LoginPage} />
      </Switch>
    </Router>
  );
};
