import { MonthDateFormatString } from "utilities/dates_and_times";
import { startableConsultationStates } from "components/OrdersList/constants";

const checkConsultationCanStart = (consultation) => {
  if (consultation?.order) {
    if (consultation.order?.ready_for_pathology_consultation_datetime) {
      return true;
    } else {
      return false;
    }
  }

  // if there's no histology order from the lab, then the consultation can start
  // if it's in the right state
  if (startableConsultationStates.includes(consultation.state)) {
    return true;
  }
};

export const checkPathologistCanStartConsultation = (consultation) => {
  // ignore if there already is a turnaround_start_datetime on the consultation
  // this gets set by the backend when the order gets moved from the lab to the
  // pathology queued
  const consultationCanStart = checkConsultationCanStart(consultation);
  const consultationAlreadyAccepted =
    consultation?.pathologists_accepted_datetime;
  const consultationFinished = !!consultation.turnaround_ended_datetime;

  return (
    consultationCanStart && consultationAlreadyAccepted && !consultationFinished
  );
};

export const consultationTurnaroundStartDateTimeFormatter = ({ data }) => {
  return MonthDateFormatString(data?.turnaround_start_datetime);
};
export const consultationTurnaroundDueDateTimeFormatter = ({ data }) => {
  return MonthDateFormatString(data?.turnaround_due_datetime);
};
export const consultationLabOrderTurnaroundStartDateTimeFormatter = ({
  data,
}) => {
  return MonthDateFormatString(data?.order?.turnaround_start_datetime);
};
export const consultationPathologistAcceptedDatetimeFormatter = ({ data }) => {
  return MonthDateFormatString(data?.pathologists_accepted_datetime);
};

export const consultationLabOrderTurnaroundDueDatetimeFormatter = ({
  data,
}) => {
  return MonthDateFormatString(data?.order?.turnaround_due_datetime);
};

export const consultationReadyForPathologyConsultationsDatetimeFormatter = ({
  data,
}) => {
  return MonthDateFormatString(
    data?.order?.ready_for_pathology_consultation_datetime
  );
};

export const OrderNameFromConsultationFormatter = ({ data }) => {
  if (data && data.order && data.order.name) {
    const serializedOrderDisplay = `${data.order.name} (${data.order.state_label})`;
    return serializedOrderDisplay;
  }
};

export const OrganizationNameFromConsultationFormatter = ({ data }) => {
  if (data?.organization?.name) {
    return data?.organization?.name;
  }
};
