import React from "react";
import { Grid } from "@mui/material";

export const SampleScienceInfoSaveButtons = ({
  renderSaveButton,
  renderSaveAndContinueButton,
}) => (
  <>
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      direction="row-reverse"
    >
      <Grid item>
        {renderSaveButton && renderSaveButton()}
        {renderSaveAndContinueButton()}
      </Grid>
    </Grid>
  </>
);
