import { PLACE_ORDER_REVIEW_FEEDBACK_URL } from "constants/urls";

export const getGlpProtocolUrl = (fileUrl) => {
  const root = process.env.REACT_APP_API_URL;

  if (!fileUrl) {
    return "#";
  }

  if (fileUrl.includes("http")) {
    return fileUrl;
  }

  return `${root}${fileUrl}`;
};

export const getGlpFormFields = (order) => ({
  test_system: order?.test_system ?? "",
  test_article: order?.test_article ?? null,
  purpose_of_study: order?.purpose_of_study ?? "",
  nature_of_study: order?.nature_of_study ?? "",
  current_status_of_each_study: order?.current_status_of_each_study ?? "",
  name_of_study_director: order?.name_of_study_director ?? "",
  identity_of_sponsor: order?.identity_of_sponsor ?? null,
  principal_investigator: order?.principal_investigator?.email ?? "",
  histowiz_phase: order?.histowiz_phase ?? "",
  date_of_1st_dosing: getGlpDate(order?.date_of_1st_dosing ?? Date.now()),
  study_initiation_date: getGlpDate(order?.study_initiation_date ?? Date.now()),
  shipping_date: getGlpDate(order?.shipping_date ?? Date.now()),
});

export const getSubmitGlpFields = (order) => ({
  ...getGlpFormFields(order),
  principal_investigator: order?.principal_investigator,
  identity_of_sponsor: order?.identity_of_sponsor?.uuid,
});

export const getGlpDate = (timestamp) =>
  `${new Date(timestamp).toLocaleDateString("en-CA")}T00:00:00Z`;

export const getCreatedGlpOrderUrl = (glpId, name, isStaff) =>
  isStaff
    ? `/glp/${glpId}`
    : PLACE_ORDER_REVIEW_FEEDBACK_URL.replace(
        ":orderID",
        name.replace("Order ", "")
      );
