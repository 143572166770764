//export const PASSED = "passed";
//export const FAILED_REASONS = [FOLDS, BLURRY, BLADES, OTHER];
export const FOLDS = "folds";
export const BLURRY = "blurry";
export const STAINS = "stains";
export const FLOATERS = "floaters";
export const BLADES = "blades";
export const TISSUE_SEPARATION = "tissue_separation";
export const TISSUE_CRACKING = "tissue_cracking";
export const MICROVIBRATIONS = "microvibrations";
export const OTHER = "other";
export const COVERSLIP = "coverslip";
export const CLEAN_SLIDE = "clean_slide";
export const CUT_DEEPER = "cut_deeper";
export const SCANNER_ARTIFACTS = "scanner_artifacts";

export const RESTAIN = "restain";
export const RECUT = "recut";
export const RESCAN = "rescan";

export const ML_QC_BLURRY_DATA = "ml_qc_blurry_data";
export const ML_QC_FOLDS_TEARS_DATA = "ml_qc_folds_data";
export const ML_QC_BLADE_MARKS_DATA = "ml_qc_blade_marks_data";
export const ML_QC_DIRTY_DATA = "ml_qc_dirty_data";
export const ML_QC_STAIN_DATA = "ml_qc_stain_data";
export const ML_QC_COVERSLIP_DATA = "ml_qc_coverslip_data";
export const ML_QC_TISSUE_SEPARATION_DATA = "ml_qc_tissue_separation_data";
export const ML_QC_MICROVIBRATIONS_DATA = "ml_qc_microvibrations_data";
export const ML_QC_TISSUE_CRACKING_DATA = "ml_qc_tissue_cracking_data";
export const ML_QC_FLOATERS_DATA = "ml_qc_floaters_data";

export const QC_OTHER_CHECK_CONSTANT = "qc_other_check";
export const ML_QC_BLURRY_CHECK_CONSTANT = "ml_qc_blurry_check";
export const ML_QC_FOLDS_CHECK_CONSTANT = "ml_qc_folds_check";
export const ML_QC_BLADE_MARKS_CHECK_CONSTANT = "ml_qc_blade_marks_check";
export const ML_QC_DIRTY_CHECK_CONSTANT = "ml_qc_dirty_check";
export const ML_QC_STAIN_CHECK_CONSTANT = "ml_qc_stain_check";
export const ML_QC_COVERSLIP_CHECK_CONSTANT = "ml_qc_coverslip_check";
//
export const ML_QC_CUT_DEEPER_ISSUE_CHECK_CONSTANT =
  "ml_qc_cut_deeper_issue_check";
export const ML_QC_FLOATERS_CHECK_CONSTANT = "ml_qc_floaters_check";
export const ML_QC_SCANNER_ARTIFACTS_CHECK_CONSTANT =
  "ml_qc_scanner_artifacts_check";
export const ML_QC_MICROVIBRATIONS_CHECK_CONSTANT =
  "ml_qc_microvibrations_check";
export const ML_TISSUE_CRACKING_CHECK_CONSTANT = "ml_qc_tissue_cracking_check";
export const ML_TISSUE_SEPARATION_CHECK_CONSTANT =
  "ml_qc_tissue_separation_check";

export const QC_PASSED_STATE_CONSTANT = "passed";
export const QC_FAILED_STATE_CONSTANT = "failed";

export const ML_QC_BLURRY_STATE_CONSTANT = "ml_qc_blurry_state";
export const ML_QC_FOLDS_STATE_CONSTANT = "ml_qc_folds_state";
export const ML_QC_BLADE_MARKS_STATE_CONSTANT = "ml_qc_blade_marks_state";
export const ML_QC_DIRTY_STATE_CONSTANT = "ml_qc_dirty_state";
export const ML_QC_STAIN_STATE_CONSTANT = "ml_qc_stain_state";
export const ML_QC_COVERSLIP_STATE_CONSTANT = "ml_qc_coverslip_state";
export const ML_QC_CUT_DEEPER_STATE_CONSTANT = "ml_qc_cut_deeper_state";
export const ML_QC_FLOATERS_STATE_CONSTANT = "ml_qc_floaters_state";
export const ML_QC_SCANNER_ARTIFACTS_STATE_CONSTANT =
  "ml_qc_scanner_artifacts_state";
export const ML_QC_MICROVIBRATIONS_STATE_CONSTANT =
  "ml_qc_microvibrations_state";
export const ML_TISSUE_CRACKING_STATE_CONSTANT = "ml_qc_tissue_cracking_state";
export const ML_TISSUE_SEPARATION_STATE_CONSTANT =
  "ml_qc_tissue_separation_state";

export const ML_QC_BLURRY_SCORE_CONSTANT = "ml_qc_blurry_score";
export const ML_QC_FOLDS_TEARS_SCORE_CONSTANT = "ml_qc_folds_score";
export const ML_QC_BLADE_MARKS_SCORE_CONSTANT = "ml_qc_blade_marks_score";
export const ML_QC_DIRTY_SCORE_CONSTANT = "ml_qc_dirty_score";
export const ML_QC_STAIN_SCORE_CONSTANT = "ml_qc_stain_score";
export const ML_QC_COVERSLIP_SCORE_CONSTANT = "ml_qc_coverslip_score";
export const ML_QC_CUT_DEEPER_SCORE_CONSTANT = "ml_qc_cut_deeper_score";
export const ML_QC_FLOATERS_SCORE_CONSTANT = "ml_qc_floaters_score";
export const ML_QC_SCANNER_ARTIFACTS_SCORE_CONSTANT =
  "ml_qc_scanner_artifacts_score";
export const ML_QC_MICROVIBRATIONS_SCORE_CONSTANT =
  "ml_qc_microvibrations_score";
export const ML_TISSUE_CRACKING_SCORE_CONSTANT = "ml_qc_tissue_cracking_score";
export const ML_TISSUE_SEPARATION_SCORE_CONSTANT =
  "ml_qc_tissue_separation_score";

export const QC_OTHER_LABEL = "Other";
export const ML_QC_BLURRY_LABEL = "Blurry";
export const ML_QC_FOLDS_LABEL = "Folds";
export const ML_QC_BLADE_MARKS_LABEL = "Blade Marks";
export const ML_QC_DIRTY_LABEL = "Dirty";
export const ML_QC_STAIN_LABEL = "Stains";
export const ML_QC_COVERSLIP_LABEL = "Coverslip";
export const ML_QC_CUT_DEEPER_LABEL = "Cut Deeper";
export const ML_QC_FLOATERS_LABEL = "Floaters";
export const ML_QC_SCANNER_ARTIFACTS_LABEL = "Scanner Artifacts";
export const ML_QC_TISSUE_CRACKING_LABEL = "Tissue Cracking";
export const ML_QC_TISSUE_SEPARATION_LABEL = "Tissue Separation";
export const ML_QC_MICROVIBRATIONS_LABEL = "Microvibrations";

export const ML_QC_OPTIONS_RECORDS = [
  {
    attribute: ML_QC_BLURRY_CHECK_CONSTANT,
    failConditionReasonName: BLURRY,
    reasonPriority: 20,
    stateAttribute: ML_QC_BLURRY_STATE_CONSTANT,
    label: ML_QC_BLURRY_LABEL,
    redoAction: RESCAN,
    dataAttribute: ML_QC_BLURRY_DATA,
    scoreAttribute: ML_QC_BLURRY_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_FOLDS_CHECK_CONSTANT,
    failConditionReasonName: FOLDS,
    reasonPriority: 19,
    stateAttribute: ML_QC_FOLDS_STATE_CONSTANT,
    label: ML_QC_FOLDS_LABEL,
    redoAction: RECUT,
    dataAttribute: ML_QC_FOLDS_TEARS_DATA,
    scoreAttribute: ML_QC_FOLDS_TEARS_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_DIRTY_CHECK_CONSTANT,
    failConditionReasonName: CLEAN_SLIDE,
    reasonPriority: 18,
    stateAttribute: ML_QC_DIRTY_STATE_CONSTANT,
    label: ML_QC_DIRTY_LABEL,
    redoAction: RESCAN,
    dataAttribute: ML_QC_DIRTY_DATA,
    scoreAttribute: ML_QC_DIRTY_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_COVERSLIP_CHECK_CONSTANT,
    failConditionReasonName: COVERSLIP,
    reasonPriority: 17,
    stateAttribute: ML_QC_COVERSLIP_STATE_CONSTANT,
    label: ML_QC_COVERSLIP_LABEL,
    redoAction: RESCAN,
    dataAttribute: ML_QC_COVERSLIP_DATA,
    scoreAttribute: ML_QC_COVERSLIP_SCORE_CONSTANT,
  },
  {
    attribute: ML_TISSUE_CRACKING_CHECK_CONSTANT,
    failConditionReasonName: TISSUE_CRACKING,
    reasonPriority: 16,
    stateAttribute: ML_TISSUE_CRACKING_STATE_CONSTANT,
    label: ML_QC_TISSUE_CRACKING_LABEL,
    redoAction: RECUT,
    dataAttribute: ML_QC_TISSUE_CRACKING_DATA,
    scoreAttribute: ML_TISSUE_CRACKING_SCORE_CONSTANT,
  },
  {
    attribute: ML_TISSUE_SEPARATION_CHECK_CONSTANT,
    failConditionReasonName: TISSUE_SEPARATION,
    reasonPriority: 15,
    stateAttribute: ML_TISSUE_SEPARATION_STATE_CONSTANT,
    label: ML_QC_TISSUE_SEPARATION_LABEL,
    redoAction: RECUT,
    dataAttribute: ML_QC_TISSUE_SEPARATION_DATA,
    scoreAttribute: ML_TISSUE_SEPARATION_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_MICROVIBRATIONS_CHECK_CONSTANT,
    failConditionReasonName: MICROVIBRATIONS,
    reasonPriority: 14,
    stateAttribute: ML_QC_MICROVIBRATIONS_STATE_CONSTANT,
    label: ML_QC_MICROVIBRATIONS_LABEL,
    redoAction: RECUT,
    dataAttribute: ML_QC_MICROVIBRATIONS_DATA,
    scoreAttribute: ML_QC_MICROVIBRATIONS_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_STAIN_CHECK_CONSTANT,
    failConditionReasonName: STAINS,
    reasonPriority: 13,
    stateAttribute: ML_QC_STAIN_STATE_CONSTANT,
    label: ML_QC_STAIN_LABEL,
    redoAction: RECUT,
    // dataAttribute: ML_QC_STAIN_DATA,
    scoreAttribute: ML_QC_STAIN_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_SCANNER_ARTIFACTS_CHECK_CONSTANT,
    failConditionReasonName: SCANNER_ARTIFACTS,
    reasonPriority: 12,
    stateAttribute: ML_QC_SCANNER_ARTIFACTS_STATE_CONSTANT,
    label: ML_QC_SCANNER_ARTIFACTS_LABEL,
    redoAction: RESCAN,
    scoreAttribute: ML_QC_SCANNER_ARTIFACTS_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_CUT_DEEPER_ISSUE_CHECK_CONSTANT,
    failConditionReasonName: CUT_DEEPER,
    reasonPriority: 11,
    stateAttribute: ML_QC_CUT_DEEPER_STATE_CONSTANT,
    label: ML_QC_CUT_DEEPER_LABEL,
    redoAction: RECUT,
    scoreAttribute: ML_QC_CUT_DEEPER_SCORE_CONSTANT,
  },
  {
    attribute: ML_QC_BLADE_MARKS_CHECK_CONSTANT,
    failConditionReasonName: BLADES,
    reasonPriority: 10,
    stateAttribute: ML_QC_BLADE_MARKS_STATE_CONSTANT,
    label: ML_QC_BLADE_MARKS_LABEL,
    redoAction: RECUT,
    // dataAttribute: ML_QC_BLADE_MARKS_DATA,
    scoreAttribute: ML_QC_BLADE_MARKS_SCORE_CONSTANT,
  },
  {
    attribute: QC_OTHER_CHECK_CONSTANT,
    failConditionReasonName: OTHER,
    reasonPriority: 21,
    label: QC_OTHER_LABEL,
    redoAction: RECUT,
  },
  {
    attribute: ML_QC_FLOATERS_CHECK_CONSTANT,
    failConditionReasonName: FLOATERS,
    reasonPriority: 9,
    stateAttribute: ML_QC_FLOATERS_STATE_CONSTANT,
    label: ML_QC_FLOATERS_LABEL,
    redoAction: RECUT,
    // dataAttribute: ML_QC_FLOATERS_DATA,
    scoreAttribute: ML_QC_FLOATERS_SCORE_CONSTANT,
  },
];

export const QCFailReasonOptions = ML_QC_OPTIONS_RECORDS.map((option) => ({
  value: option.failConditionReasonName,
  label: option.label,
}));

export const DefaultQCScoresOptions = ML_QC_OPTIONS_RECORDS.reduce(
  (prevValue, currValue) => {
    return [
      ...prevValue,
      ...(currValue.dataAttribute
        ? [{ value: currValue.dataAttribute, label: currValue.label }]
        : []),
    ];
  },
  []
);

export const DefaultQCScoresNames = DefaultQCScoresOptions.map(
  (option) => option.value
);

export const QCScoresOptions = [
  ...DefaultQCScoresOptions,
  { value: "all", label: "All" },
];

export const DEFAULT_QC_SCORE = QCScoresOptions.find(
  (option) => option.value === "all"
);

export const DEFAULT_QC_FAILED_CHECKS = ML_QC_OPTIONS_RECORDS.reduce(
  (prevValue, currValue) => ({
    ...prevValue,
    [currValue.attribute]: false,
  }),
  {}
);

export const FETCHING_RECENTLY_CHANGED_SLIDES_ERROR_MESSAGE =
  "Error Occurred Getting Recently Changed Slides!";
export const FETCHING_QC_SLIDES_ERROR_MESSAGE =
  "Error Occurred Fetching QC Slides!";
export const SUBMITTING_ERROR_MESSAGE = "An Error Occurred When Submitting";

export const QC_BULK_REVIEW_GLP_SLIDE_SELECTED_ERROR_MESSAGE =
  "Bulk Review QC Is Not Allowed for GLP Slides!";
