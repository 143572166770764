import OpenSeadragon from "openseadragon";
import { ANNOTATION_TYPES } from "components/ImageViewer/constants";

function subtractBothFromWidthAndHeight(coords, width, height) {
  return coords.map(([x, y]) => [height - x, y]);
}

export const drawPolygonFlat = (ctx, path) => {
  ctx.beginPath();
  ctx.moveTo(path[0], path[1]);
  for (let i = 2; i < path.length; i += 2) {
    ctx.lineTo(path[i], path[i + 1]);
  }
  ctx.closePath();
};

const drawEllipse = (ctx, centerX, centerY, radiusX, radiusY) => {
  ctx.beginPath();
  ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctx.closePath();
};

export const getViewerContainerPositionFromImagePosition = (
  viewer,
  position
) => {
  const osdPoint = new OpenSeadragon.Point(position.x, position.y);
  const viewportPoint = viewer.viewport.imageToViewportCoordinates(osdPoint);
  const containerPosition = viewer.viewport.pixelFromPoint(viewportPoint);

  return containerPosition;
};

export const convertToRGBA = (colorRGB) => {
  // Convert the signed integer to an unsigned integer
  const unsignedColor = colorRGB >>> 0;

  // Extract the RGB components
  const red = (unsignedColor >> 16) & 0xff;
  const green = (unsignedColor >> 8) & 0xff;
  const blue = unsignedColor & 0xff;

  const alpha = 0.3;

  return {
    fill: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    stroke: `rgba(${red}, ${green}, ${blue})`,
  };
};

export class ExtAnnoDrawingManager {
  constructor(viewer, slide, initialModels = []) {
    this.viewer = viewer;
    this.slide = slide;
    this.currentModels = [];

    this.createInitialModels(initialModels);
  }

  createInitialModels(initialModels) {
    initialModels.forEach((modelName) => {
      const model = this.getOrCreateModel(modelName);
      model.draw();
    });
  }

  updateSlide(slide) {
    this.slide = slide;

    this.currentModels.forEach((model) => {
      model.slide = slide;
    });
  }

  getModelByName(modelName) {
    return this.currentModels.find((model) => model.modelName === modelName);
  }

  createModel(modelName, feature) {
    const model = new ExtAnnoModel(modelName, this.viewer, this.slide, feature);
    this.currentModels.push(model);

    return model;
  }

  getOrCreateModel(modelName, feature) {
    let model = this.getModelByName(modelName);

    if (!model) {
      model = this.createModel(modelName, feature);
    }

    return model;
  }

  destroy() {
    this.currentModels.forEach((model) => {
      model.destroy();
    });
  }
}

export class ExtAnnoModel {
  constructor(modelName, viewer, slide, feature) {
    this.modelName = modelName;
    this.viewer = viewer;
    this.slide = slide;
    this.feature = feature;
    this.isShown = false;
    this.showAnnotationText = true;

    this.overlay = this.viewer.fabricjsOverlay({ scale: 1000 });
    this.canvas = this.overlay.fabricCanvas();
    this.ctx = this.canvas.upperCanvasEl.getContext("2d");

    this.update = this.update.bind(this);

    this.addEventListeners();
  }

  draw() {
    if (!this.viewer.source || !this.feature || !this.isShown) {
      return;
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.feature.type
      ? this.drawFeature(this.feature)
      : this.drawFeatureFromXML(this.feature);
  }

  update() {
    if (!this.isShown) {
      return;
    }
    this.draw();
  }

  hide() {
    this.isShown = false;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  addEventListeners() {
    this.viewer.addHandler("fabricjs-resize-finished", this.update);
  }

  removeEventListeners() {
    this.viewer.removeHandler("fabricjs-resize-finished", this.update);
  }

  destroy() {
    this.hide();
    this.removeEventListeners();
  }

  drawPolygons(colorRGB, revertedCoordinates, xmlProps = {}) {
    const { regionType, annotationId, setIsAnnotationTextAvailable } = xmlProps;

    const polygonPath = revertedCoordinates.flat();
    const { stroke } = convertToRGBA(colorRGB);
    this.ctx.strokeStyle = stroke;
    this.ctx.lineWidth = 2;
    if (regionType === ANNOTATION_TYPES.ARROW) {
      // Draw arrow
      this.ctx.beginPath();
      this.ctx.moveTo(polygonPath[2], polygonPath[3]);
      this.ctx.lineTo(polygonPath[0], polygonPath[1]);

      const angle = Math.atan2(
        polygonPath[1] - polygonPath[3],
        polygonPath[0] - polygonPath[2]
      );
      const headLength = 15;

      this.ctx.lineTo(
        polygonPath[0] + headLength * Math.cos(angle - Math.PI / 6 + Math.PI),
        polygonPath[1] + headLength * Math.sin(angle - Math.PI / 6 + Math.PI)
      );

      this.ctx.moveTo(polygonPath[0], polygonPath[1]);
      this.ctx.lineTo(
        polygonPath[0] + headLength * Math.cos(angle + Math.PI / 6 + Math.PI),
        polygonPath[1] + headLength * Math.sin(angle + Math.PI / 6 + Math.PI)
      );

      this.ctx.stroke();
    } else if (regionType === ANNOTATION_TYPES.ELLIPSE) {
      //Draw ellipse (circle)
      const vertex1 = polygonPath.slice(0, 2);
      const vertex2 = polygonPath.slice(2, 4);
      const center = {
        x: (vertex1[0] + vertex2[0]) / 2,
        y: (vertex1[1] + vertex2[1]) / 2,
      };

      const radiusX = Math.abs(vertex1[0] - vertex2[0]) / 2;
      const radiusY = Math.abs(vertex1[1] - vertex2[1]) / 2;
      drawEllipse(this.ctx, center.x, center.y, radiusX, radiusY);
    } else {
      drawPolygonFlat(this.ctx, polygonPath);
    }

    this.ctx.stroke();

    if (annotationId) {
      setIsAnnotationTextAvailable?.(true);
      if (!this.showAnnotationText) return;

      // Calculate the center and radius for the circle
      const bbox = calculatePolygonBoundingBox(revertedCoordinates);
      const centerX = (bbox.left + bbox.right) / 2;
      const centerY = (bbox.top + bbox.bottom) / 2;

      const baseFontSize = 12;
      const defaultZoom = 0.5;
      const padding = 2;
      const currentZoom = this.viewer.viewport.getZoom();
      const fontSize = baseFontSize * (currentZoom / defaultZoom);
      this.ctx.font = `${fontSize}px Arial`;

      const textMetrics = this.ctx.measureText(annotationId);
      const textWidth = textMetrics.width;
      const textHeight = fontSize;

      const textX = centerX - textWidth / 2;
      const textY = centerY + textHeight / 2 - fontSize / 10;
      const radius = Math.max(textWidth, textHeight) / 2 + padding;
      this.ctx.fillStyle = "rgba(128, 128, 128, 0.5)";

      this.ctx.beginPath();
      this.ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
      this.ctx.fill();

      this.ctx.fillStyle = stroke;
      this.ctx.fillText(annotationId, textX, textY);
    }

    function calculatePolygonBoundingBox(coordinates) {
      let minX = Infinity,
        maxX = -Infinity,
        minY = Infinity,
        maxY = -Infinity;

      coordinates.forEach(([x, y]) => {
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
      });

      return {
        left: minX,
        right: maxX,
        top: minY,
        bottom: maxY,
      };
    }
  }

  drawFeature(feature) {
    this.feature = feature;
    const { coordinates } = feature.geometry;
    const adjustedCoordinates = subtractBothFromWidthAndHeight(
      coordinates,
      this.viewer.source.width,
      this.viewer.source.height
    );
    const revertedCoordinates = adjustedCoordinates.map((coord) => {
      const updatedPoint = {
        x: coord[1],
        y: coord[0],
      };

      const viewportPoint = getViewerContainerPositionFromImagePosition(
        this.viewer,
        updatedPoint
      );
      return [viewportPoint.x, viewportPoint.y];
    });

    this.drawPolygons(
      feature.properties?.classification?.colorRGB,
      revertedCoordinates
    );
  }

  drawFeatureFromXML(annotation, setIsAnnotationTextAvailable) {
    this.feature = annotation;
    const regions = annotation.getElementsByTagName("Region");
    Array.from(regions).forEach((region) => {
      let vertices = region.getElementsByTagName("V");
      if (!vertices.length) {
        vertices = region.getElementsByTagName("Vertex");
      }
      const coordinates = Array.from(vertices).map((vertex) => {
        return [
          parseInt(vertex.getAttribute("X")),
          parseInt(vertex.getAttribute("Y")),
        ];
      });

      const adjustedCoordinates = subtractBothFromWidthAndHeight(
        coordinates,
        this.viewer.source.width,
        this.viewer.source.height
      );

      const revertedCoordinates = adjustedCoordinates.map(([y, x]) => {
        const viewportPoint = getViewerContainerPositionFromImagePosition(
          this.viewer,
          { x, y }
        );
        return [viewportPoint.x, viewportPoint.y];
      });

      const colorRGB = parseInt(annotation.getAttribute("LineColor"));
      const annotationId = region.getAttribute("Id");
      const regionType = region.getAttribute("Type");
      this.drawPolygons(colorRGB, revertedCoordinates, {
        regionType,
        annotationId,
        setIsAnnotationTextAvailable,
      });
    });
  }
}
