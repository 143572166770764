import { makeStyles } from "tss-react/mui";
import { ERROR_ICON } from "utilities/colors";

export const useTeamComponentStyles = makeStyles()({
  intro: { marginBottom: "1rem" },
  inviteTable: { marginTop: "1rem" },
  inputForm: { width: 300 },
  buttonStyle: { marginTop: "1rem" },
  emailInput: {
    display: "flex",
    flexDirection: "column",
  },
  errorMessage: {
    marginTop: "0.5rem",
    color: ERROR_ICON,
  },
});
