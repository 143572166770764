export const getSvgRectElementCoordinates = (element) => {
  const x = parseFloat(element.getAttribute("x"));
  const y = parseFloat(element.getAttribute("y"));
  const xRight = x + parseFloat(element.getAttribute("width"));
  const yBottom = y + parseFloat(element.getAttribute("height"));

  return [
    { x, y },
    { x: xRight, y },
    { x: xRight, y: yBottom },
    { x, y: yBottom },
  ];
};
