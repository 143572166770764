import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  shipBackButtonStyle: {
    marginBottom: "1rem",
    display: "block",
  },
}));

export const ShipBackButton = ({ text, onClick, isDisabled }) => {
  const {
    classes: { shipBackButtonStyle },
  } = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={shipBackButtonStyle}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
};
