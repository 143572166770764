import { makeStyles } from "tss-react/mui";

export const usePathConsultationFormStyles = makeStyles()(() => ({
  formWrap: { maxWidth: "1024px", margin: "0 auto", width: "100%" },
}));

export const usePathologyConsultationFormContentStyles = makeStyles()(() => ({
  disabledCursor: {
    cursor: "not-allowed",
  },
}));

export const useRequestPathologistModalLegacyStyles = makeStyles()((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
}));
