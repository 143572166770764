import { DefaultTabWrapper } from "components/OrderDetails/Tabs/utilities";
import { Grid } from "@mui/material";
import { AdditionalServicesTable } from "components/OrderForm/components/OrderDetailsTables";
import React from "react";

export const ImageAnalysisAndPathologyConsultationTab = ({ order }) => {
  return (
    <DefaultTabWrapper>
      <Grid item xs>
        <AdditionalServicesTable order={order} alternative={true} />
      </Grid>
    </DefaultTabWrapper>
  );
};
