import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useShipStyles } from "components/OrderDetails/Tabs/OrderProgressTab/styles";

export const Chip = ({ chipName, color }) => {
  const { classes } = useShipStyles({ color });

  return (
    <Box className={classes.chip}>
      <Typography className={classes.chipText}>{chipName}</Typography>
    </Box>
  );
};
