import React from "react";
import {
  Typography,
  TableCell,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
} from "@mui/material";
import { withStyles } from "tss-react/mui";
import { toTitleCase } from "utilities/general";

export const CustomTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

export const OrderTableRow = ({ classes, row }) => {
  const { name, service_type, description, quantity, quickbooks_sku } = row;

  let service_type_display = service_type;
  // tweak things like wet_tissue and a_la_carte
  service_type_display = service_type.replaceAll("_", " ");

  service_type_display = toTitleCase(service_type_display);
  service_type_display = service_type_display.replace("Oct", "OCT");
  service_type_display = service_type_display.replace("If", "IF");

  let quickbooks_sku_display = quickbooks_sku;
  if (!quickbooks_sku) {
    quickbooks_sku_display = "";
  }

  return (
    <TableRow className={classes.row}>
      <CustomTableCell component="th" scope="row">
        {name}
      </CustomTableCell>
      <CustomTableCell>{quickbooks_sku_display}</CustomTableCell>
      <CustomTableCell>{service_type_display}</CustomTableCell>
      <CustomTableCell>{description}</CustomTableCell>
      <CustomTableCell>{quantity}</CustomTableCell>
    </TableRow>
  );
};

export const OrderTableHeader = () => (
  <TableHead>
    <TableRow>
      <CustomTableCell>Service Name</CustomTableCell>
      <CustomTableCell>SKU</CustomTableCell>
      <CustomTableCell>Type</CustomTableCell>
      <CustomTableCell>Description</CustomTableCell>
      <CustomTableCell>Quantity</CustomTableCell>
    </TableRow>
  </TableHead>
);

export const OrderTable = ({ classes, orders }) => (
  <Paper className={classes.paper}>
    <Table className={classes.table}>
      <OrderTableHeader />
      <TableBody>
        {orders.map((row) => {
          return <OrderTableRow row={row} classes={classes} key={row.name} />;
        })}
      </TableBody>
    </Table>
  </Paper>
);

export const ServiceSlidesTable = ({ classes, orders, title }) => {
  if (!orders.length) return;

  return (
    <>
      <Typography variant="h6" className={classes.tableTitle}>
        {title}
      </Typography>
      <OrderTable classes={classes} orders={orders} />
    </>
  );
};
