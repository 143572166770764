import { MiniDrawerWithContext } from "components/Layout/drawer";
import { Tab, Tabs } from "@mui/material";
import {
  FINALIZED_ORDER_LABEL,
  ignoreOrderStates,
  IN_PROGRESS_ORDER_LABEL,
  UPCOMING_ORDER_LABEL,
} from "components/OrdersList/constants";
import React, { useEffect, useState } from "react";
import { TabContainer } from "components/OrderDetails/Tabs/utilities";
import { getPathologistWorklistOrders } from "services/resources/pathologyConsultations";
import { BaseConsultationsAgGrid } from "components/PathologyConsultations/WorklistGrid";
import { errorLogger } from "utilities/loggers";
import {
  finishedConsultationStates,
  unsubmittedConsultationStates,
} from "components/PathologyConsultations/constants";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { useSelector } from "react-redux";

export const PathologistWorklist = () => {
  const [tabValueInfo, setTabValueInfo] = useState({
    tabValue: IN_PROGRESS_ORDER_LABEL,
  });

  const { tabValue } = tabValueInfo;
  const [consultations, setConsultations] = useState({
    finishedConsultations: [],
    inProgressConsultations: [],
    upcomingConsultations: [],
  });
  const userDetails = useSelector(userDetailsSelector);

  const handleTabChange = (event, tabValue) => {
    setTabValueInfo((prevState) => {
      return {
        ...prevState,
        tabValue,
      };
    });
  };

  useEffect(() => {
    getPathologistWorklistOrders()
      .then((data) => {
        const inProgressConsultations = data.filter((consultation) => {
          return (
            !finishedConsultationStates.includes(consultation.state) &&
            !unsubmittedConsultationStates.includes(consultation.state) &&
            !ignoreOrderStates.includes(consultation.state)
          );
        });

        const finishedConsultations = data.filter((consultation) => {
          return finishedConsultationStates.includes(consultation.state);
        });

        const upcomingConsultations = data.filter((consultation) => {
          // this only includes from the histology lab
          if (!consultation.order) {
            return false;
          }

          // only show if the order is not ready for pathology consultation
          // that would mean it's still being worked in the lab
          return !consultation.order.ready_for_pathology_consultation_datetime;
        });

        setConsultations({
          finishedConsultations: finishedConsultations,
          inProgressConsultations: inProgressConsultations,
          upcomingConsultations: upcomingConsultations,
        });
      })

      .catch((error) => {
        errorLogger(error, "getPathologistWorklistOrders");
      });
  }, []);

  const renderTabContents = () => {
    return (
      <>
        {tabValue === UPCOMING_ORDER_LABEL && (
          <TabContainer>
            <BaseConsultationsAgGrid
              consultations={consultations.upcomingConsultations}
              title={"Upcoming Pathology Consultations (Currently In Lab)"}
              userDetails={userDetails}
            />
          </TabContainer>
        )}
        {tabValue === IN_PROGRESS_ORDER_LABEL && (
          <TabContainer>
            <BaseConsultationsAgGrid
              consultations={consultations.inProgressConsultations}
              title={"Active Pathology Consultations (In Progress)"}
              userDetails={userDetails}
            />
          </TabContainer>
        )}
        {tabValue === FINALIZED_ORDER_LABEL && (
          <TabContainer>
            <BaseConsultationsAgGrid
              consultations={consultations.finishedConsultations}
              title={"Finished Pathology Consultations"}
              userDetails={userDetails}
            />
          </TabContainer>
        )}
      </>
    );
  };

  const upcomingLabel = `Upcoming (${consultations.upcomingConsultations.length})`;
  const inProgressLabel = `In Progress (${consultations.inProgressConsultations.length})`;
  const finalizedLabel = `Finished (${consultations.finishedConsultations.length})`;

  return (
    <MiniDrawerWithContext header={"Pathologist Worklist"}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor={"primary"}
      >
        <Tab value={UPCOMING_ORDER_LABEL} label={upcomingLabel}></Tab>
        <Tab value={IN_PROGRESS_ORDER_LABEL} label={inProgressLabel}></Tab>
        <Tab value={FINALIZED_ORDER_LABEL} label={finalizedLabel} />
      </Tabs>
      {renderTabContents()}
    </MiniDrawerWithContext>
  );
};
