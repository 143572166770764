import { createSlice } from "@reduxjs/toolkit";
import { defaultShowPreferences } from "components/ImageViewer/constants";

const initialState = {
  email: "",
  isStaff: false,
  firstName: "",
  lastName: "",
  isPathologist: false,
  isBetaFeaturesEnabled: false,
  isProjectManager: false,
  showDrawer: false,
  preferences: { ...defaultShowPreferences },
  algolia_search_api_key: "",
  algolia_user_slides_search_api_key: "",
  algolia_access_groups: {},
  phoneNumber: "",
  secondaryPhoneNumber: "",
  credit_amount: null,
  primaryTeam: null,
  permissions: [],
  forceOpenSlidesSearchTutorial: false,
  forceOpenTeamOverviewModal: false,
  forceOpenDemoChart: true,
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    handleDrawer: (state) => {
      state.showDrawer = !state.showDrawer;
    },
    minimizeDrawer: (state) => {
      state.showDrawer = false;
    },
    setIsDrawerOpen: (state, { payload }) => {
      state.showDrawer = payload;
    },
    updateIsStaff: (state, { payload }) => {
      state.isStaff = payload;
    },
    updatePreferences: (state, { payload }) => {
      state.preferences = { ...state.preferences, ...payload };
    },
    updateTeamDetails: (state, { payload }) => {
      if (payload?.[0]) {
        state.primaryTeam = payload[0];

        const permissions = payload.map((team) => team.app_permissions).flat();
        state.permissions = permissions;
      }
    },
    updateUserDetails: (state, { payload }) => {
      const {
        email,
        is_staff,
        is_pathologist,
        preferences,
        algolia_search_api_key,
        algolia_user_slides_search_api_key,
        algolia_access_groups,
        credit_amount,
        enable_beta_features,
        first_name,
        last_name,
        phone_number,
        secondary_phone_number,
        is_project_manager,
      } = payload;

      if ("show_drawer" in preferences) {
        state.showDrawer = preferences["show_drawer"];
      }
      state.email = email;
      state.firstName = first_name;
      state.lastName = last_name;
      state.isStaff = is_staff;
      state.isBetaFeaturesEnabled = enable_beta_features;
      state.preferences = !Object.keys(preferences).length
        ? defaultShowPreferences
        : { ...defaultShowPreferences, ...preferences };
      state.phoneNumber = phone_number;
      state.firstName = first_name;
      state.lastName = last_name;
      state.secondaryPhoneNumber = secondary_phone_number;
      state.algolia_search_api_key = algolia_search_api_key;
      state.algolia_user_slides_search_api_key =
        algolia_user_slides_search_api_key;
      state.algolia_access_groups = algolia_access_groups;
      state.credit_amount = credit_amount;
      state.isPathologist = is_pathologist;
      state.isProjectManager = is_project_manager;
    },
    setForceOpenSlidesSearchTutorial: (state, { payload }) => {
      state.forceOpenSlidesSearchTutorial = payload;
    },
    setForceOpenTeamOverviewModal: (state, { payload }) => {
      state.forceOpenTeamOverviewModal = payload;
    },
    setForceOpenDemoChart: (state, { payload }) => {
      state.forceOpenDemoChart = payload;
    },
  },
});

export const {
  updateUserDetails,
  handleDrawer,
  setIsDrawerOpen,
  updateIsStaff,
  updatePreferences,
  minimizeDrawer,
  updateTeamDetails,
  setForceOpenSlidesSearchTutorial,
  setForceOpenTeamOverviewModal,
} = userDetailsSlice.actions;

export const userDetailsReducer = userDetailsSlice.reducer;

export const primaryTeamSelector = (state) => state.userDetails.primaryTeam;
export const permissionsSelector = (state) => state.userDetails.permissions;
export const isStaffSelector = (state) => state.userDetails.isStaff;
export const isPathologistSelector = (state) => state.userDetails.isPathologist;
export const isProjectManagerSelector = (state) =>
  state.userDetails.isProjectManager;
export const isBetaFeaturesEnabledSelector = (state) =>
  state.userDetails.isBetaFeaturesEnabled;
export const currentUserEmailSelector = (state) => state.userDetails.email;
export const currentUserPreferencesSelector = (state) =>
  state.userDetails.preferences;
export const algoliaSearchApiKeySelector = (state) =>
  state.userDetails.algolia_search_api_key;
export const algoliaUserSlidesSearchApiKeySelector = (state) =>
  state.userDetails.algolia_user_slides_search_api_key;
export const algoliaAccessGroupsSelector = (state) =>
  state.userDetails.algolia_access_groups;
export const currentUserCreditAmountSelector = (state) =>
  state.userDetails.credit_amount;
export const userDetailsSelector = (state) => state.userDetails;
export const phoneNumberSelector = (state) => state.userDetails.phoneNumber;
export const showDrawerSelector = (state) => state.userDetails.showDrawer;
export const secondaryPhoneNumberSelector = (state) =>
  state.userDetails.secondaryPhoneNumber;
export const selectForceOpenSlidesSearchTutorial = (state) =>
  state.userDetails.forceOpenSlidesSearchTutorial;
export const selectForceOpenTeamOverviewModal = (state) =>
  state.userDetails.forceOpenTeamOverviewModal;
export const selectForceOpenDemoChart = (state) =>
  state.userDetails.forceOpenDemoChart;
