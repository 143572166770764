import { useEffect } from "react";

import { GENERAL_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useQueryError = (error, message) => {
  const { showError } = useSnackbar();

  useEffect(() => {
    if (!error) return;

    showError(message ?? GENERAL_ERROR_MESSAGE);
  }, [error, message, showError]);
};
