import { useCallback, useEffect, useRef, useState } from "react";
import { FETCH_ORDER_FIXATIVES_ERROR_MESSAGE } from "constants/errorMessages";
import { getOrderFixatives } from "services/resources/orders";
import { serializeNameAndUUID } from "utilities/api";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useOrderFixatives = () => {
  const { showError } = useSnackbar();
  const mountedUpdateOrderFixatives = useRef(false);

  const [selectableOrderFixativeOptions, setSelectableOrderFixativeOptions] =
    useState([]);

  const updateOrderFixatives = useCallback(() => {
    getOrderFixatives()
      .then((response) => {
        const selectableOrderFixativeOptions =
          response.data.map(serializeNameAndUUID);
        if (!mountedUpdateOrderFixatives.current) {
          setSelectableOrderFixativeOptions(selectableOrderFixativeOptions);
        }
      })
      .catch(() => showError(FETCH_ORDER_FIXATIVES_ERROR_MESSAGE));
  }, []);

  useEffect(() => {
    updateOrderFixatives();
    return () => {
      mountedUpdateOrderFixatives.current = true;
    };
  }, [updateOrderFixatives]);

  return {
    selectableOrderFixativeOptions,
    updateOrderFixatives,
  };
};
