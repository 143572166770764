import { makeStyles } from "tss-react/mui";

const styles = () => ({
  centeredComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const useStyles = makeStyles()(styles);

export const CenteredComponent = ({ children, ...props }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.centeredComponent} {...props}>
      {children}
    </div>
  );
};
