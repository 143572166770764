import { backendAPI } from "services/backendAPI";
import {
  GLOBAL_ORDERS_SEARCH_RESOURCE_NAME,
  GLOBAL_SAMPLES_SEARCH_RESOURCE_NAME,
  GLOBAL_SLIDES_SEARCH_RESOURCE_NAME,
} from "services/resources/apiConstants";

export const getGlobalSearchResults = (query, signal) =>
  Promise.all([
    getOrdersSearchResults(query, signal),
    getSlidesSearchResults(query, signal),
  ]);

export const getOrdersSearchResults = (query, signal) => {
  const params = {
    search: query,
  };

  return backendAPI
    .get(GLOBAL_ORDERS_SEARCH_RESOURCE_NAME, { params, signal })
    .then((response) => {
      return response.data;
    });
};

export const getSlidesSearchResults = (query, signal) => {
  const params = {
    search: query,
  };

  return backendAPI
    .get(GLOBAL_SLIDES_SEARCH_RESOURCE_NAME, { params, signal })
    .then(({ data }) => {
      const { count, results } = data;
      return {
        count,
        results,
      };
    });
};

export const getSamplesSearchResults = (query, signal) => {
  const params = {
    search: query,
  };

  return backendAPI
    .get(GLOBAL_SAMPLES_SEARCH_RESOURCE_NAME, { params, signal })
    .then((response) => {
      return response.data;
    });
};
