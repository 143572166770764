import React, { memo } from "react";
import Box from "@mui/material/Box";
import { useChannelsStore, useViewerStore } from "components/IFViewer/state";
import shallow from "zustand/shallow";
import { toRgb } from "components/IFViewer/utils";
import { LegendRow } from "components/IFViewer/components/legendRow";
import { useChannelsLegendStyles } from "components/IFViewer/styles";

export const ChannelsLegend = memo(() => {
  const [channelOptions] = useViewerStore(
    (store) => [store.computed.channelOptions],
    shallow
  );

  const [channelsVisible, colors, selections, ids, toggleIsOnSetter] =
    useChannelsStore(
      (store) => [
        store.channelsVisible,
        store.colors,
        store.selections,
        store.ids,
        store.toggleIsOn,
      ],
      shallow
    );
  const legendRows = ids.map((id, i) => {
    return {
      id,
      name: channelOptions[selections[i].c],
      color: toRgb(false, colors[i]),
      visibility: channelsVisible[i],
      toggleIsOn: () => toggleIsOnSetter(i),
    };
  });

  const { classes } = useChannelsLegendStyles();

  return (
    <Box className={classes.container}>
      {legendRows.map((rowData) => (
        <LegendRow key={rowData.id} classes={classes} {...rowData} />
      ))}
    </Box>
  );
});
