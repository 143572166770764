import { TextFormInput } from "components/OrderForm/components/InfoFormInputs";
import React, { Fragment } from "react";
import { BaseOrderDropdownSelections } from "./BaseSelectDropdowns";
import {
  ImageAnalysisAndPathologyConsultationTurnaroundTimeOptions,
  trueFalseOptions,
} from "../constants";
import { Typography } from "@mui/material";

export const BoneDecalcificationInput = ({ name }) => {
  return (
    <BaseOrderDropdownSelections
      name={name}
      title={"Requires Bone Decalcification"}
      options={trueFalseOptions}
      checkmark
    />
  );
};

export const ImageAnalysisAndPathologyConsultationTimeInput = ({ name }) => {
  return (
    <BaseOrderDropdownSelections
      name={name}
      title={"Image Analysis and Pathology Consultation Turnaround Time"}
      options={ImageAnalysisAndPathologyConsultationTurnaroundTimeOptions}
    />
  );
};

export const ShipBackStainedSlidesInput = (props) => {
  return (
    <BaseOrderDropdownSelections
      orderSelectionType={"ship_back_stained_slides"}
      value={props.values.ship_back_stained_slides}
      onChange={props.setFieldValue}
      onBlur={props.setFieldTouched}
      error={props.errors.ship_back_stained_slides}
      touched={props.touched.ship_back_stained_slides}
      title={"Ship Back Stained Slides"}
      options={trueFalseOptions}
      {...props}
    />
  );
};

export const ShipBackUnstainedSlidesInput = ({ name }) => {
  return (
    <BaseOrderDropdownSelections
      name={name}
      title={"Ship Back Unstained Slides(*)"}
      options={trueFalseOptions}
      checkmark
    />
  );
};

export const ShipBackSamplesInput = (props) => {
  return (
    <BaseOrderDropdownSelections
      orderSelectionType={"ship_back_samples"}
      value={props.values.ship_back_samples}
      onChange={props.setFieldValue}
      onBlur={props.setFieldTouched}
      error={props.errors.ship_back_samples}
      touched={props.touched.ship_back_samples}
      title={"Ship Back Blocks"}
      options={trueFalseOptions}
      {...props}
    />
  );
};

export const ClientFedexAccountInput = ({ name }) => {
  return (
    <Fragment>
      <TextFormInput
        name={name}
        formLabel={"Client Fedex Account To Use When Shipping"}
        formPlaceholder={"Client Fedex Number"}
        rows={1}
        checkmark
      />
      <Typography>
        * HistoWiz provides free inbound shipping on orders over $500.
      </Typography>
    </Fragment>
  );
};
