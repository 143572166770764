import {
  PO_SUBMITTED_MESSAGE,
  PO_UPDATE_ERROR_MESSAGE,
  PO_UPLOAD_REQUIRED_MESSAGE,
} from "components/Modals/constants";
import { postBillingContact } from "services/resources/commonResources";
import { POST_BILLING_CONTACTS_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useState } from "react";
import { useUpdateOrderMutation } from "store/apis/orderApi";

export const usePOActions = (order, updatedPO, setIsPOUploaded) => {
  const { showError, showSuccess, showWarning } = useSnackbar();
  const [updateOrderMutation] = useUpdateOrderMutation();
  const [billingContact, setBillingContact] = useState(
    order?.billing_contact || {}
  );

  const onSubmitPO = async (billingContact) => {
    if (!updatedPO) {
      showWarning(PO_UPLOAD_REQUIRED_MESSAGE);
      return;
    }

    const postParams = {
      po_number: updatedPO,
      email: billingContact.email || "",
      name: billingContact.name || "",
      phone_number: billingContact.phone_number || "",
      team_uuid: order.team?.uuid || "",
    };

    try {
      const updatedBillingContact = await postBillingContact(postParams);
      setBillingContact(updatedBillingContact);
      setIsPOUploaded(true);
    } catch (error) {
      showError(POST_BILLING_CONTACTS_ERROR_MESSAGE);
    }
  };

  const onSave = async (onClose) => {
    const result = await updateOrderMutation({
      orderUUID: order.uuid,
      postParams: {
        billing_contact_uuid: billingContact.uuid,
      },
    });

    if (result.error) {
      showError(PO_UPDATE_ERROR_MESSAGE);
    } else {
      showSuccess(PO_SUBMITTED_MESSAGE);
      !!onClose && typeof onClose === "function" && onClose();
    }
    setIsPOUploaded(false);
  };

  return {
    onSubmitPO,
    onSave,
  };
};
