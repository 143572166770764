export const KEYS = {
  ENTER: "Enter",
  RIGHT: "ArrowRight",
  LEFT: "ArrowLeft",
  ESC: "Escape",
  P: "p",
  F: "f",
  Q: "q",
  E: "e",
  CONTROL: "ctrl",
};

export const KEYS_CODES = {
  ENTER: 13,
  ARROW_RIGHT: 39,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ESC: 27,
  P: 80,
  F: 70,
  Q: 81,
  E: 69,
  BACKSPACE: 8,
  DELETE: 46,
};
