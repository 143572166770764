import { baseCreateApi } from "store/apis/baseCreateApi";

export const projectsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => ({ url: "projects/", method: "GET" }),
    }),
    updateProject: builder.mutation({
      query: ({ uuid, body }) => ({
        url: `projects/${uuid}/`,
        method: "PUT",
        body,
      }),
      async onQueryStarted({ uuid, body }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          baseCreateApi.util.updateQueryData(
            "getProjects",
            undefined,
            (draft) => {
              const index = draft.findIndex((project) => project.uuid === uuid);
              if (index !== -1) draft[index].name = body.name;
            }
          )
        );
      },
    }),
  }),
});

export const { useGetProjectsQuery, useUpdateProjectMutation } = projectsApi;
