import React from "react";
import { Link } from "@mui/material";

import { useGlpFormStyles } from "components/GlpForm/styles";
import { getGlpProtocolUrl } from "components/GlpForm/helpers/functions";

export const GlpHeader = ({ glpOrder }) => {
  const { classes } = useGlpFormStyles();
  const { header, link, links } = classes;
  const { order, id } = glpOrder ?? {};
  const { id: orderId, protocol_file_attachments: files } = order ?? {};

  const apiBase = process.env.REACT_APP_API_URL;

  return (
    <header className={header}>
      <i>
        For Internal Use Only. To be filled in by Principal Investigator using
        client uploaded protocol. All fields are required.
      </i>
      <div className={links}>
        {files?.map(({ name, uuid, file }) => (
          <Link
            className={link}
            href={getGlpProtocolUrl(file)}
            target="blank"
            key={uuid}
          >
            {name}
          </Link>
        ))}
        {orderId && (
          <Link
            className={link}
            href={`${apiBase}/lisa/core/order/${orderId}/change/`}
            target="blank"
          >
            This order in LIMS
          </Link>
        )}
        {id && (
          <Link
            className={link}
            href={`${apiBase}/lisa/core/glporder/${id}/change/`}
            target="blank"
          >
            This GLP order in LIMS
          </Link>
        )}
      </div>
    </header>
  );
};
