import { Grid, Typography } from "@mui/material";

import { useSlideNameStyles } from "components/SlideName/styles";

export const SlideName = ({ name, children }) => {
  const { classes } = useSlideNameStyles();
  const { slideName, slideNameWrap } = classes;

  return (
    <Grid item className={slideNameWrap}>
      <Typography variant="h5" color="inherit" className={slideName}>
        {name}
      </Typography>
      {children}
    </Grid>
  );
};
