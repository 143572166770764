import React, { useCallback, useEffect, useState } from "react";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { makeStyles } from "tss-react/mui";
import { useTitle } from "components/utilities/hooks/useTitle";
import {
  primaryTeamSelector,
  userDetailsSelector,
} from "store/slices/userDetailsSlice";
import { useSelector } from "react-redux";
import { TEAM_BLOCKS_URL } from "constants/urls";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import {
  getBlocksColumnsDef,
  SamplesInBlocksColumnsDef,
} from "components/utilities/AgGridCols/BlocksColumns";
import { Button, Stack, Typography } from "@mui/material";
import { gridThemeStyle } from "components/OrderDetails/Tabs/constants";
import {
  agGridDefaultColDef,
  getAgGridSelectedChildsUUIDs,
} from "components/utilities/grid";
import { backendURL } from "services/backendAPI";
import { DefaultModal } from "components/Modals/DefaultModal";
import Box from "@mui/material/Box";
import { FormInput } from "components/FormInputs/FormInput";
import { BlockSampleColumnsDefs } from "components/utilities/AgGridCols/AgGridColumns";
import {
  FETCH_TEAM_BLOCKS_ERROR_MESSAGE,
  GET_SAMPLES_ERROR,
  MERGE_TEAM_BLOCKS_ERROR_MESSAGE,
} from "constants/errorMessages";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import {
  useLazyGetTeamSamplesQuery,
  useMergeBlocksActionMutation,
} from "store/apis/samplesApi";
import {
  useLazyGetTeamBlocksQuery,
  useUnmergeBlockSamplesMutation,
  useUpdateBlockMutation,
} from "store/apis/teamsApi";
import { DEFAULT_SUCCESS_MESSAGE } from "utilities/hooks/useSnackbar/constants";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import {
  getMultiBlocksMenu,
  getSingeSamplesMenu,
  getSingleBlocksMenu,
} from "./utilities";

const useStyles = makeStyles()(() => ({
  newRowClass: {
    backgroundColor: "lightblue",
  },
}));

export const TeamBlocksPage = () => {
  useTitle("HistoWiz - Blocks List");

  const { classes } = useStyles();

  const userDetails = useSelector(userDetailsSelector);
  const { showSuccess, showError } = useSnackbar();
  const match = useRouteMatch();
  const { teamUUID } = match.params;
  const [isStaff, setIsStaff] = useState(false);
  const [confirmBlock, setConfirmBlock] = useState(null);

  const [team, setTeam] = useState(null);
  const [teamName, setTeamName] = useState("");

  const [blocksSelected, setBlocksSelected] = useState([]);

  const [confirmMergeModalOpen, setConfirmMergeModalOpen] = useState(false);
  const [blocksGridAPI, setBlocksGridApi] = useState(null);
  const [samplesGridAPI, setSamplesGridApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState("");

  const [getTeamSamples, { data: samplesData }] = useLazyGetTeamSamplesQuery();

  const [getTeamBlocks, { data: blocksData }] = useLazyGetTeamBlocksQuery();

  const [unmergeSamples] = useUnmergeBlockSamplesMutation();
  const [updateBlock] = useUpdateBlockMutation();

  const [mergeBlocksAction, { error: mergeTeamsError }] =
    useMergeBlocksActionMutation();
  useQueryError(mergeTeamsError, MERGE_TEAM_BLOCKS_ERROR_MESSAGE);

  const onBlocksGridReady = useCallback(
    (params) => {
      setBlocksGridApi(params.api);
    },
    [setBlocksGridApi]
  );

  const onSamplesGridReady = useCallback(
    (params) => {
      setSamplesGridApi(params.api);
    },
    [setSamplesGridApi]
  );

  const toggleModal = () => setConfirmMergeModalOpen((prevState) => !prevState);

  const onMergeSelection = () => {
    mergeBlocks({ rows: blocksSelected });
    toggleModal();
  };

  const BlockConfirmMergeModal = () => {
    if (!blocksSelected) {
      return null;
    }

    const blockNames = blocksSelected.map((block) => block.name).join(", ");

    return (
      <DefaultModal open={confirmMergeModalOpen} onClose={toggleModal}>
        <Typography
          variant="h6"
          id="modal-title"
          className={classes.typographyMarginBottom}
        >
          Merge Blocks: {blockNames}?
        </Typography>
        <Box mt={1} mb={2}>
          <Typography variant="subtitle1" id="simple-modal-description">
            Warning : This will permanently merge and delete the other selected
            blocks.
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="end">
          <span className={classes.deleteButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={onMergeSelection}
            >
              Merge Blocks
            </Button>
          </span>
        </Stack>
      </DefaultModal>
    );
  };

  useEffect(() => {
    // ag-grid caches a some functions, which causes an issue because by default for context we say is not a staff
    if (userDetails.isStaff) {
      setIsStaff(true);
    }
  }, [userDetails]);

  const handleGetTeamBlocks = () => {
    getTeamBlocks({ teamUUID, internal: true })
      .unwrap()
      .then((response) => {
        const firstInstance = response[0];
        if (firstInstance) {
          const team = firstInstance.team;

          setTeam(team);
          setTeamName(team.name);
        }
      })
      .catch(() => showError(FETCH_TEAM_BLOCKS_ERROR_MESSAGE));
  };

  useEffect(() => {
    if (!teamUUID) {
      return;
    }

    handleGetTeamBlocks();

    getTeamSamples({ teamUUID, excludeMatchedBlockSamples: true }).catch(() =>
      showError(GET_SAMPLES_ERROR)
    );
  }, [teamUUID]);

  const mergeBlocks = ({ rows }) => {
    const rowUUIDs = rows.map((row) => row.uuid);
    mergeBlocksAction({ block_uuids: rowUUIDs })
      .unwrap()
      .then((response) => {
        // Get the selected rows from the grid
        const selectedNodes = blocksGridAPI.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        blocksGridAPI.applyTransaction({ remove: selectedData });

        const updatedRowData = response;
        // update the row data after the merge to have a isNew: true flag
        updatedRowData.forEach((row) => {
          row.isNew = true;
        });

        blocksGridAPI.applyTransaction({ add: updatedRowData, addIndex: 0 });
      })
      .catch(() => showError(MERGE_TEAM_BLOCKS_ERROR_MESSAGE));
  };

  const getBlocksMenu = (params) => {
    // this function gets cached by ag grid, which is a problem when we actually use
    // react context for is staff and that changes, so here, we pass it into ag-grid as a constantly
    // updating context
    const gridContext = params.context;
    const { isStaff } = gridContext;
    const row = params.node?.data;

    if (!row || !isStaff) return;

    const selectedRows = params.api.getSelectedRows();
    const mergeBlocks = () => {
      setBlocksSelected(selectedRows);
      toggleModal();
    };

    const menu =
      selectedRows.length > 1
        ? getMultiBlocksMenu({ mergeBlocks })
        : getSingleBlocksMenu({ row, samplesGridAPI });

    return [...params.defaultItems, ...menu];
  };

  const getSamplesMenu = (params) => {
    const gridContext = params.context;
    const { isStaff } = gridContext;
    const row = params.node?.data;

    if (!row || !isStaff) return;

    const selectedRows = params.api.getSelectedRows();

    const menu = selectedRows.length > 1 ? [] : getSingeSamplesMenu({ row });

    return [...params.defaultItems, ...menu];
  };

  const handleUnmergeSlides = (sample_uuids) => {
    unmergeSamples({ sample_uuids })
      .unwrap()
      .then(() => {
        showSuccess(DEFAULT_SUCCESS_MESSAGE);
      })
      .catch(showError());
  };

  const getSamplesContextMenuItems = (params) => {
    const gridContext = params.context;
    const { isStaff } = gridContext;
    const rows = params?.node?.data;

    // if not a staff member, don't show anything
    if (!rows || !isStaff) {
      return;
    }

    const samplesSelected = getAgGridSelectedChildsUUIDs({
      gridAPI: blocksGridAPI,
    });

    if (!samplesSelected.length) return;

    return [
      {
        name: "Unmerge Samples from Block",
        action: () => handleUnmergeSlides(samplesSelected),
      },
    ];
  };

  const onQuickFilterText = (event) => {
    setQuickFilterText(event.target.value);
  };

  const detailCellRendererParams = (params) => {
    // provide the Grid Options to use on the Detail Grid
    return {
      detailGridOptions: {
        columnDefs: SamplesInBlocksColumnsDef,
        getContextMenuItems: () => getSamplesContextMenuItems(params),
      },
      // get the rows for each Detail Grid
      getDetailRowData: (params) => {
        params.successCallback(params.data.samples);
      },
    };
  };

  const lisLink = `${backendURL}/lisa/core/team/${team?.id}/`;
  const groupDisplayType = "multipleColumns";

  const handleVoidBlock = () => {
    updateBlock({
      teamUUID: confirmBlock.team.uuid,
      blockUUID: confirmBlock.uuid,
      body: {
        state: "voided",
      },
    })
      .unwrap()
      .then(() => {
        setConfirmBlock(null);
        showSuccess(DEFAULT_SUCCESS_MESSAGE);
      })
      .catch(() => showError());
  };

  return (
    <MiniDrawerWithContext header="Blocks List">
      <Typography variant="h4" gutterBottom>
        <a href={lisLink} target="_blank" rel="noreferrer">
          {teamName}
        </a>
      </Typography>
      <Typography variant="h6">
        <a href={lisLink} target="_blank" rel="noreferrer">
          Blocks
        </a>
      </Typography>
      <Box mb={3}>
        <DefaultModal
          open={!!confirmBlock}
          onClose={() => setConfirmBlock(null)}
        >
          <Typography
            variant="h6"
            id="modal-title"
            className={classes.typographyMarginBottom}
            mb={2}
          >
            Void Block {confirmBlock?.name}?
          </Typography>
          <Stack direction="row" justifyContent="end">
            <span className={classes.deleteButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleVoidBlock}
              >
                Void Block
              </Button>
            </span>
          </Stack>
        </DefaultModal>
        <div className="ag-theme-balham" style={gridThemeStyle}>
          <FormInput onChange={onQuickFilterText} />
          <AgGridReact
            masterDetail
            rowGroupPanelShow="always"
            detailCellRendererParams={detailCellRendererParams}
            onGridReady={onBlocksGridReady}
            groupDisplayType={groupDisplayType}
            enableSorting
            enableFilter
            enableColResize
            quickFilterText={quickFilterText}
            defaultColDef={agGridDefaultColDef}
            columnDefs={getBlocksColumnsDef({
              classes,
              onBlockUpdate: setConfirmBlock,
            })}
            detailRowHeight={250}
            rowData={blocksData}
            rowSelection="multiple"
            context={{ isStaff }}
            getContextMenuItems={getBlocksMenu}
            rowClassRules={{
              "antibody-has-children": (data) => !data.data,
              "antibody-has-parent": (data) => data.node.parent.rowIndex,
            }}
            overlayNoRowsTemplate={"<span>No data / Loading</span>"}
          />
        </div>
      </Box>
      {/*not sure why ag-grid takes too much space*/}
      <Box mt={8}>
        <Typography variant="h6">
          <a href={lisLink} target="_blank" rel="noreferrer">
            Samples (No Block Assigned)
          </a>
        </Typography>
        <div className="ag-theme-balham" style={gridThemeStyle}>
          <FormInput onChange={onQuickFilterText} />
          <AgGridReact
            onGridReady={onSamplesGridReady}
            columnDefs={BlockSampleColumnsDefs}
            rowData={samplesData}
            //onFirstDataRendered={handleFirstDataRendered}
            enableColResize
            rowSelection="multiple"
            //enableFillHandle
            // want the grid to stop editing when focus leaves the cell or the grid
            //stopEditingWhenCellsLoseFocus
            fillHandleDirection="y"
            rowDragManaged
            context={{ isStaff }}
            getContextMenuItems={getSamplesMenu}
            rowDrag
            enableRangeSelection
            editable={false}
            suppressClearOnFillReduction
            defaultColDef={agGridDefaultColDef}
            //onCellValueChanged={onCellValueChanged}
            //tooltipShowDelay={0}
            //components={frameworkComponents}
            sideBar
            //onPasteEnd={onPasteEnd}
            //onRowDataUpdated={handleGridRowDataUpdated}
            //onCellDoubleClicked={handleFillHandleDoubleClicked}
            overlayNoRowsTemplate={"<span>No data</span>"}
            // if we don't suppressRowClick, copy and paste keeps on trying to copy
            // the entire row and it really sucks for the entire experience
            suppressRowClickSelection
            suppressCopyRowsToClipboard
            //suppressNoRowsOverlay={isLoading}
          />
        </div>
      </Box>
      <BlockConfirmMergeModal />
    </MiniDrawerWithContext>
  );
};

export const BlocksPage = () => {
  const primaryTeam = useSelector(primaryTeamSelector);
  const history = useHistory();
  useEffect(() => {
    if (!primaryTeam) {
      return;
    }
    const url = TEAM_BLOCKS_URL.replace(":teamUUID", primaryTeam.uuid);
    // found primary URL, redirecting
    history.push(url);
  }, [primaryTeam]);

  return <></>;
};
