import { Paper, Tab, Tabs } from "@mui/material";
import { SelectedShippingMethodTab } from "components/OrderForm/BillingShipping/ShippingMethod/SelectedShippingMethodTab";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { HISTOWIZ_FEDEX } from "components/OrderForm/constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUSA } from "utilities/forms";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import {
  billingShippingSelectors,
  setShippingMethodSelected,
} from "store/slices/billingShippingSlice/billingShippingSlice";

export const ShippingMethod = ({ handleModalChange, shippingLabels }) => {
  const { classes } = useBillingShippingStyles();
  const { showWarning } = useSnackbar();
  const shippingMethodSelected = useSelector(
    billingShippingSelectors.shippingMethodSelected
  );
  const shippingAddressSelected = useSelector(
    billingShippingSelectors.shippingAddressSelected
  );
  const dispatch = useDispatch();

  const handleShippingMethodChange = (shippingMethod) => {
    dispatch(setShippingMethodSelected(shippingMethod));
    if (
      shippingMethod.value === HISTOWIZ_FEDEX &&
      isUSA(shippingAddressSelected?.country)
    ) {
      handleModalChange("shippingDimensionsModal")();
    }
  };

  useEffect(() => {
    if (
      shippingMethodSelected?.value === HISTOWIZ_FEDEX &&
      !shippingLabels?.length &&
      shippingAddressSelected &&
      !isUSA(shippingAddressSelected.country)
    ) {
      showWarning(
        "Due to international customs, we cannot create an international shipping label.",
        `Please contact sales at 1-833-728-4522 or email at sales@histowiz.com`,
        { autoHideDuration: 10000 }
      );
    }
  }, [shippingMethodSelected, shippingAddressSelected]);

  return (
    <Paper className={classes.paper}>
      <Tabs value={0} indicatorColor="secondary" textColor="secondary">
        <Tab label="Select Shipping Method" />
      </Tabs>
      <SelectedShippingMethodTab
        onChange={handleShippingMethodChange}
        shippingMethodSelected={shippingMethodSelected}
      />
    </Paper>
  );
};
