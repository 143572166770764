import { FormInputV2 } from "components/OrderForm/components/InfoFormInputs";
import React from "react";

export const DefaultTeamAddressField = ({ label, name, placeholder }) => {
  return (
    <FormInputV2
      name={name}
      formLabel={label}
      formPlaceholder={placeholder}
      formInput="text"
      checkmark
    />
  );
};
