import { useNewCardStyles } from "components/PathologyMap/Modals/FullSlideInfoModal/styles";
import Box from "@mui/material/Box";

export const NewCard = ({ header, content }) => {
  const { classes } = useNewCardStyles();

  return (
    <Box className={classes.slideCard}>
      <Box className={classes.header}>{header}</Box>
      {content}
    </Box>
  );
};
