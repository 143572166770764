import React from "react";
import moment from "moment/moment";
import { MONTH_DATE_FORMAT } from "utilities/dates_and_times";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Stack, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { HISTOWIZ_SECONDARY_COLOR } from "utilities/colors";

import { Archive as ArchiveIcon } from "@mui/icons-material";

export const ConsultationsTeamFileAttachmentsTable = ({ attachments }) => {
  let rows = [];
  if (attachments && attachments.length > 0) {
    rows = attachments.map((attachment) => ({
      name: attachment.name,
      uuid: attachment.uuid,
      created: moment(attachment.created).format(MONTH_DATE_FORMAT),
      value: "Download",
      url: attachment.file,
    }));
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "70%" }}>
                <Typography variant={"h6"}>
                  Uploaded Reference Attachments
                </Typography>
                <Typography variant={"body2"}>
                  {" "}
                  Optional Reference Documents (Papers, Previous Reports, etc.).
                </Typography>
              </TableCell>
              <TableCell align="right">Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <a
                    href={row.url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.name}
                  </a>
                </TableCell>
                <TableCell align="right">{row.created}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const FinalReportTeamFileAttachmentsTable = ({ attachments }) => {
  let rows = [];
  if (attachments && attachments.length > 0) {
    rows = attachments.map((attachment) => ({
      name: attachment.name,
      uuid: attachment.uuid,
      created: moment(attachment.created).format(MONTH_DATE_FORMAT),
      value: "Download",
      url: attachment.file,
    }));
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: HISTOWIZ_SECONDARY_COLOR }}
      >
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "70%" }}>
                <Typography variant={"h4"} color={"white"}>
                  Upload Final Report
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant={"body2"} color={"white"}>
                  Upload Date
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <a
                    href={row.url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography variant={"body2"} color={"white"}>
                        {row.name}
                      </Typography>
                      <ArchiveIcon color={"white"} sx={{ color: "white" }} />
                    </Stack>
                  </a>
                </TableCell>
                <TableCell align="right">
                  <Typography variant={"body2"} color={"white"}>
                    {row.created}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const ProtocolTeamFileAttachmentsTable = ({ attachments }) => {
  let rows = [];
  if (attachments && attachments.length > 0) {
    rows = attachments.map((attachment) => ({
      name: attachment.name,
      uuid: attachment.uuid,
      created: moment(attachment.created).format(MONTH_DATE_FORMAT),
      value: "Download",
      url: attachment.file,
    }));
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "70%" }}>
                <Typography variant={"h6"}>
                  Uploaded Protocol Attachments
                </Typography>
                <Typography variant={"body2"}>
                  {" "}
                  Required Protocol Documents
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant={"body2"} color={"white"}>
                  Upload Date
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <a
                    href={row.url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.name}
                  </a>
                </TableCell>
                <TableCell align="right">{row.created}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
