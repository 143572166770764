export const HOW_TO_USE_ANNOTATIONS_OPTIONS = [
  "Use SHIFT to start an annotation",
  "Double-Click to end a selection when using Polygon",
  "If Draw Mode is enabled, SHIFT is not required to start an annotation",
  "Use the Show/Hide Menu to hide or show annotations",
];

export const HOW_TO_USE_MEASUREMENT_OPTIONS = [
  "Click a point to start a measurement",
  "Click a point to end the measurement.",
  "To cancel a measurement while in progress, press Z",
  "Click on the measurement tool to hide or show measurements",
];

export const HOW_TO_USE_USER_SLIDES_SEARCH_OPTIONS = [
  "We are delighted to introduce you to the exclusive beta launch of Search 2.0, where all your slides are now accessible through one simple interface. You are among the select group of users chosen to experience this tool before its official release.\n" +
    "With lightning-fast search algorithms and a user-friendly interface, Search 2.0 is designed to revolutionize access to your slides. Your insights and feedback are incredibly valuable to us as we fine-tune this tool to meet your unique needs.",
];

export const USER_SLIDES_SEARCH_TUTORIAL_VIDEO_URL =
  "https://player.vimeo.com/video/861406668?h=8139ed3cd9&autoplay=1&quality=1080p";
