import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Stack, Typography, TableCell, TableRow } from "@mui/material";
import {
  FAIL_FEATURE_REQUEST,
  SUCCESS_FEATURE_REQUEST,
  suggestFeatureSchema,
} from "components/Modals/SuggestFeatureModal/constants";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { Dropzone } from "components/utilities/components/Dropzone/Dropzone";
import {
  FormInputV2,
  TextFormInput,
} from "components/OrderForm/components/InfoFormInputs";
import { DefaultModal } from "components/Modals/DefaultModal";
import {
  ModalStyles,
  useSuggestFeatureModalStyles,
} from "components/Modals/SuggestFeatureModal/styles";
import { requestFeature } from "services/resources/requestFeature";

export const SuggestFeatureModal = ({ open, onClose }) => {
  const { classes } = useSuggestFeatureModalStyles();
  const [image, setImage] = useState(null);
  const { showError, showSuccess } = useSnackbar();

  const handleSubmit = useCallback(
    (data) => {
      const formData = new FormData();

      if (image) formData.append("file", image);
      if (data.title) formData.append("title", data.title);

      formData.append("description", data.description);

      requestFeature(formData)
        .then(() => {
          showSuccess(SUCCESS_FEATURE_REQUEST);
          onClose();
        })
        .catch(() => {
          showError(FAIL_FEATURE_REQUEST);
        });
    },
    [image]
  );

  const methods = useForm({
    resolver: yupResolver(suggestFeatureSchema),
  });

  const {
    register,
    formState: { errors, touchedFields, submitCount },
  } = methods;

  const defaultFormProps = {
    register,
    errors,
    checkmark: true,
    touched: touchedFields,
    submitCount,
  };

  const handleDrop = (files) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  useEffect(() => {
    if (!open) {
      setImage(null);
      methods.reset();
    }
  }, [open]);

  return (
    <DefaultModal open={open} onClose={onClose} styles={ModalStyles}>
      <Typography className={classes.title}>
        Submit A Feature Request
      </Typography>
      <FormProvider {...methods}>
        <form
          className={classes.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <FormInputV2
            {...defaultFormProps}
            formLabel="Title"
            formPlaceholder="Title (Optional)"
            name="title"
            className={classes.input}
          />
          <TextFormInput
            {...defaultFormProps}
            formLabel="Goal / Brief Description"
            formPlaceholder="Describe your request here (in 500 words or less)..."
            name="description"
            className={classes.description}
          />
          <Dropzone
            className={classes.dropzone}
            onDrop={handleDrop}
            attachments={image ? [image] : []}
            disabled={false}
            title="Uploaded Attachments"
            description="Optional"
          >
            {image && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography>{image.name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <CloseIcon
                    className={classes.cancelButton}
                    onClick={() => setImage(null)}
                  />
                </TableCell>
              </TableRow>
            )}
          </Dropzone>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submitButton}
          >
            Submit feature request
          </Button>
          <Typography className={classes.contacts}>
            Questions? Please feel free to call us at{" "}
            <a href="tel:3472211020" className={classes.contacts}>
              347-221-1020
            </a>{" "}
            or email{" "}
            <a
              href="mailto:getsupport@histowiz.com"
              className={classes.contacts}
            >
              getsupport@histowiz.com
            </a>
          </Typography>
        </form>
      </FormProvider>
    </DefaultModal>
  );
};

SuggestFeatureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
