import { makeStyles } from "tss-react/mui";
import { SECONDARY_BLUE } from "utilities/colors";

export const PATHOLOGY_MAP_DEFAULT_FONT_COLOR = "black";
export const PATHOLOGY_MAP_DEFAULT_FONT_WEIGHT = 400;
export const PATHOLOGY_MAP_DEFAULT_FONT_FAMILY = "Roboto";
export const PATHOLOGY_MAP_BOLD_FONT_WEIGHT = 700;

export const PATHOLOGY_MAP_DEFAULT_BORDER_COLOR = "rgba(0, 0, 0, 0.05)";
export const PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR = "rgba(0, 0, 0, 0.05)";
export const PATHOLOGY_MAP_ACTIVE_FILTER_ITEM_BG_COLOR =
  "rgba(255, 255, 255, 0.5)";
export const PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR = "#DC727C";
export const PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR_HOVER = "#B85A64";
export const PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR_DISABLED = "#9b9b9b";
export const PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_TYPOGRAPHY_COLOR = "white";

export const DEFAULT_TYPOGRAPHY_PROPS = {
  fontWeight: PATHOLOGY_MAP_DEFAULT_FONT_WEIGHT,
  fontSize: "0.8rem",
  color: PATHOLOGY_MAP_DEFAULT_FONT_COLOR,
  fontFamily: PATHOLOGY_MAP_DEFAULT_FONT_FAMILY,
};

export const HEADER_TYPOGRAPHY_PROPS = {
  fontSize: "1rem",
  letterSpacing: "0.2rem",
  textTransform: "uppercase",
};

export const PATHOLOGY_MAP_CATEGORY_ITEM_BG_COLOR = "transparent";
export const PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR = "#879BFF";

export const PATHOLOGY_MAP_SELECTED_ITEM_BG_COLOR = "rgba(66, 148, 213, 0.20)";
export const PATHOLOGY_MAP_DISABLED_ITEM_BG_COLOR = "rgba(217,217,217,0.7)";

export const TEAM_OVERVIEW_CARD_HEADER_TYPOGRAPHY_PROPS = {
  color: "rgba(0,0,0,0.5)",
  fontSize: "0.75rem",
  fontStyle: "normal",
  lineHeight: "normal",
};

export const PATHOLOGY_MAP_SLIDE_CARD_HEADER_TYPOGRAPHY_PROPS = {
  color: "rgba(0,0,0,0.5)",
  fontSize: "0.5rem",
  fontStyle: "normal",
  lineHeight: "normal",
};

export const PATHOLOGY_MAP_SLIDE_CARD_CONTENT_TYPOGRAPHY_PROPS = {
  fontSize: "0.85rem",
  letterSpacing: "2.8px",
  fontStyle: "normal",
  lineHeight: "normal",
};

export const PATHOLOGY_MAP_SLIDE_CARD_INFO_TITLE_TYPOGRAPHY_PROPS = {
  fontSize: "0.6rem",
  letterSpacing: "1px",
  textTransform: "uppercase",
};

export const PATHOLOGY_MAP_SLIDE_CARD_INFO_CONTENT_TYPOGRAPHY_PROPS = {
  fontSize: "0.7rem",
  textAlign: "end",
};

export const PATHOLOGY_MAP_RESULTS_COUNTER_TYPOGRAPHY_PROPS = {
  color: "white",
  fontSize: "0.6rem",
  fontWeight: PATHOLOGY_MAP_BOLD_FONT_WEIGHT,
  textAlign: "center",
};

export const PATHOLOGY_MAP_SEARCH_BUTTON_TYPOGRAPHY_PROPS = {
  fontWeight: PATHOLOGY_MAP_BOLD_FONT_WEIGHT,
  fontSize: "1rem",
  color: "white",
};

export const PATHOLOGY_MAP_COUNTER_CONTAINER_BG_COLOR = "rgba(0,0,0,0.4)";
export const PATHOLOGY_MAP_FILTERS_SEARCH_BG_COLOR =
  "rgba(255, 255, 255, 0.80)";

export const PATHOLOGY_MAP_SELECTED_SLIDE_DATA_BG_COLOR =
  "rgba(242,245,108,0.8)";
export const PATHOLOGY_MAP_EVEN_SLIDE_DATA_BG_COLOR = "#F2F7F9";

export const PATHOLOGY_MAP_SECTION_HEADER_BG_COLOR =
  "linear-gradient(180deg, rgba(108, 162, 187, 0.45), rgba(108, 162, 187, 0.10))";

export const PATHOLOGY_MAP_SECTION_BG_COLOR = "#ffffff4c";

export const PATHOLOGY_MAP_SEARCH_BAR_BG_COLOR = "rgba(255, 255, 255, 0.80)";
export const PATHOLOGY_MAP_SEARCH_BUTTON_BG_COLOR = "#DC727C";
export const PATHOLOGY_MAP_SEARCH_BUTTON_HOVER_BG_COLOR = "#B85A64";

export const PATHOLOGY_MAP_TOOLBAR_BG_COLOR = "rgba(255, 255, 255, 0.80)";
export const PATHOLOGY_MAP_TRANSPARENT_BLACK = "#0000000d";

export const LOADER_WRAPPER_STYLE = { marginTop: "1rem" };

export const SLIDE_SEARCH_TAB_STYLES = {
  textTransform: "none",
  height: "48px",
  minHeight: "48px",
  fontWeight: 400,
  padding: "6px 2px",
};

export const USER_PROFILE_ORDERS_TYPES_TABS_STYLES = {
  textTransform: "uppercase",
  height: "40px",
  minHeight: "40px",
  fontWeight: 400,
  minWidth: "min-content",
  padding: "0.25rem 0.5rem",
};

export const useSlideInfoForPathologyMapStyles = makeStyles()({
  slideDataContainer: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
    padding: "0.2rem 0.5rem",
    background: "transparent",
    "&:nth-of-type(even)": {
      backgroundColor: PATHOLOGY_MAP_EVEN_SLIDE_DATA_BG_COLOR,
    },
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedSlideData: {
    background: `${PATHOLOGY_MAP_SELECTED_SLIDE_DATA_BG_COLOR} !important`,
    "& p": {
      fontWeight: PATHOLOGY_MAP_BOLD_FONT_WEIGHT,
    },
  },
  cellWithAction: {
    color: SECONDARY_BLUE,
    "&:hover": {
      cursor: "context-menu",
    },
  },
});
