import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateAddresses } from "components/OrderForm/BillingShipping/hooks/useUpdateAddresses";
import {
  buildUpdateOrderParams,
  serializeTeamAddress,
} from "components/OrderForm/BillingShipping/utilities/serializers";
import {
  additionalReviewValidationSchema,
  getAdditionalQuestionsFormDefaultValues,
} from "components/OrderForm/BillingShipping/utilities/utilities";
import { UPDATE_ORDER_ERROR_MESSAGE } from "constants/errorMessages";
import { PLACE_ORDER_REVIEW_STEP_URL } from "constants/urls";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateOrder } from "services/resources/orders";
import {
  setValidated,
  setSubmitting,
  billingShippingSelectors,
} from "store/slices/billingShippingSlice/billingShippingSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { isIAOrPCOrder } from "utilities/orders";

export const useAdditionalQuestionsForm = () => {
  const {
    teamUUID,
    order,
    shippingAddressSelected,
    shippingMethodSelected,
    billingContactSelected,
    selectedBlanketPurchaseOrder,
    dropboxSelected,
    shippingAddresses,
    submitting,
  } = useSelector(billingShippingSelectors.additionalQuestionsForm);
  const dispatch = useDispatch();

  const [shippingTabSelected, setShippingTabSelected] = useState(1);
  const [shippingBackAddressSelected, setShippingBackAddressSelected] =
    useState(order.address_to);
  const portalRef = useRef(null);
  const history = useHistory();
  const { showError } = useSnackbar();
  const updateTeamAddresses = useUpdateAddresses(setShippingTabSelected);

  const {
    uuid: orderUUID,
    requested_unstained_slides: unstainedSlidesRequested,
  } = order;

  const methods = useForm({
    defaultValues: getAdditionalQuestionsFormDefaultValues(order),
    resolver: yupResolver(
      additionalReviewValidationSchema(unstainedSlidesRequested)
    ),
  });

  const [
    ship_back_samples,
    ship_back_unstained_slides,
    ship_back_stained_slides,
    client_fedex_account,
  ] = methods.watch([
    "ship_back_samples",
    "ship_back_unstained_slides",
    "ship_back_stained_slides",
    "client_fedex_account",
  ]);

  const isFedexVisible =
    ship_back_samples.value ||
    ship_back_unstained_slides.value ||
    ship_back_stained_slides.value;

  const { setValue } = methods;

  const onSubmit = async (values) => {
    dispatch(setSubmitting(true));

    const isNotValid =
      !(shippingAddressSelected && shippingMethodSelected) ||
      (isFedexVisible && !client_fedex_account) ||
      (unstainedSlidesRequested && !ship_back_unstained_slides);

    if (isNotValid) {
      dispatch(setSubmitting(false));
      dispatch(setValidated(true));
      return;
    }

    // fallback for cases when input components not mounted
    const defaultValues = getAdditionalQuestionsFormDefaultValues(order);
    const modifiedValues = {
      ...defaultValues,
      ...values,
    };

    const postParams = buildUpdateOrderParams(modifiedValues, {
      shippingAddressSelected,
      shippingMethodSelected,
      selectedBlanketPurchaseOrder,
      billingContactSelected,
      dropboxSelected,
    });

    postParams.address_to_uuid = ship_back_unstained_slides.value
      ? shippingBackAddressSelected.uuid
      : null;

    await updateOrder(orderUUID, postParams)
      .then(() => {
        dispatch(setSubmitting(false));
        const url = PLACE_ORDER_REVIEW_STEP_URL.replace(
          ":orderUUID",
          orderUUID
        );
        history.push(url);
      })
      .catch(() => {
        dispatch(setSubmitting(false));
        showError(UPDATE_ORDER_ERROR_MESSAGE);
      });
  };

  const orderRequestedIAOrPC = isIAOrPCOrder(order);

  const handleAddTeamAddressSuccess = (address) => {
    const shippingBackAddressSelected = serializeTeamAddress({
      address,
    });

    updateTeamAddresses();
    setShippingTabSelected(1);

    setShippingBackAddressSelected(shippingBackAddressSelected);
  };

  const handleShippingTabChange = (event, value) => {
    setShippingTabSelected(value);
  };

  useEffect(() => {
    if (order.address_to) {
      const address = serializeTeamAddress({
        address: order.address_to,
      });
      setShippingBackAddressSelected(address);
    } else {
      setShippingBackAddressSelected(shippingAddressSelected);
    }
  }, [shippingAddressSelected, order.address_to]);

  return {
    methods,
    onSubmit,
    orderRequestedIAOrPC,
    unstainedSlidesRequested,
    handleShippingTabChange,
    shippingTabSelected,
    shippingBackAddressSelected,
    setShippingBackAddressSelected,
    shippingAddresses,
    isFedexVisible,
    setValue,
    client_fedex_account,
    handleAddTeamAddressSuccess,
    shippingAddressSelected,
    teamUUID,
    submitting,
    ship_back_unstained_slides,
    portalRef,
  };
};
