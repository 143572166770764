import { DARK_GREY } from "utilities/colors";
import { SAMPLE_EDITOR_URL } from "constants/urls";

export const BREADCRUMBS = [
  {
    name: `Samples Editor`,
    color: DARK_GREY,
    link: SAMPLE_EDITOR_URL,
    fontSize: "1rem",
  },
  {
    name: `Changelog`,
    color: "black",
    bold: true,
    fontSize: "1.25rem",
  },
];

export const TABLE_HEADER = {
  name: "Sample Name",
  id: "Sample ID",
  project_name: "Project Name",
  order_id: "Order",
  field: "Field Name",
  old: "Changed From",
  new: "Changed To",
  author: "Author",
};

export const SCIENCE_INFO = "science_info";
export const DISPLAY_NAME = "display_name";

export const DATE_FORMAT = "DD MMM YYYY";
