export const USER_ADDED_TO_TEAM_SUCCESS_MESSAGE =
  "You've successfully been added to team!";
export const QUOTE_SUBMITTING_SUCCESS_MESSAGE =
  "Successfully Submitted Email Request";

export const SHIP_BACK_SUCCESS_MESSAGE =
  "Your request to ship back project materials for the order has been received!";

export const CONTENT_DO_NOT_SHOW_AGAIN_SUCCESS_MESSAGE =
  "Content will not be shown again";

export const DELETE_SLIDE_COMMENT_SUCCESS_MESSAGE = "Comment was deleted";

export const ALGOLIA_MORE_SLIDES_FOUND_SUCCESS_MESSAGE =
  "More Matching Slides Found!";

export const UPDATE_SAMPLES_SUCCESS_MESSAGE = "Samples successfully updated!";
export const IMPORT_SAMPLES_FILE_SUCCESS_MESSAGE =
  "Samples were successfully uploaded!";
export const SUCCESS_MAKE_VALID_POS_CONTROL_MESSAGE =
  "Slide Link Has Been Copied!";
