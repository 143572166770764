import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  DefaultTabWrapper,
  getIFViewerURLFromSlide,
  getMultiContextMenuItemsIFViewer,
  RenderIFSlideMacro,
  useGetSingleContextMenuItemsIFGrid,
} from "components/OrderDetails/Tabs/utilities";
import { AgGridReact } from "ag-grid-react";
import { gridThemeStyle } from "components/OrderDetails/Tabs/constants";
import {
  IFSlideAdminColumnsDefs,
  IFSlideColumnsDefs,
} from "components/utilities/AgGridCols/AgGridColumns";
import {
  agGridDefaultColDef,
  exportToExcelAction,
} from "components/utilities/grid";
import {
  marginTopStyle,
  useOrderDetailsSlidesStyles,
} from "components/OrderDetails/orderDetailsSlidesStyles";
import { useHistory } from "react-router-dom";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { useSelector } from "react-redux";
import { ExportToExcelButton } from "components/OrderForm/Buttons/ExportToExcelButton";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";

const IFSlidesDetailsTable = ({ order, ifSlides, isIFSlidesLoading }) => {
  const userDetails = useSelector(userDetailsSelector);
  const [isStaff, setIsStaff] = useState(false);
  const [gridApi, setGridApi] = React.useState(null);
  const { classes } = useOrderDetailsSlidesStyles();
  const history = useHistory();

  const getSingleContextMenuItemsIFGrid = useGetSingleContextMenuItemsIFGrid();
  useAGGridOverlayControl(isIFSlidesLoading, ifSlides, gridApi);

  useEffect(() => {
    // ag-grid caches a some functions, which causes an issue because by default for context we say is not a staff
    if (userDetails.isStaff) {
      setIsStaff(true);
    }
  }, [userDetails]);

  const openInIFViewer = ({ ifSlide }) => {
    const url = getIFViewerURLFromSlide({ ifSlide });
    history.push(url);
  };

  const onGridReady = ({ api }) => {
    setGridApi(api);
  };

  const renderTable = () => {
    return (
      <div className="ag-theme-balham" style={gridThemeStyle}>
        <AgGridReact
          onGridReady={onGridReady}
          rowSelection={"multiple"}
          columnDefs={isStaff ? IFSlideAdminColumnsDefs : IFSlideColumnsDefs}
          defaultColDef={agGridDefaultColDef}
          rowData={ifSlides}
          enableColResize={true}
          sideBar={false}
          rowGroupPanelShow={"always"}
          enableSorting={true}
          enableFilter={true}
          context={{ isStaff }}
          getContextMenuItems={getContextMenuItems({ order })}
          onRowDoubleClicked={(row) => openInIFViewer({ ifSlide: row.data })}
          overlayNoRowsTemplate={"<span>No data</span>"}
          suppressNoRowsOverlay={isIFSlidesLoading}
        />
      </div>
    );
  };

  const renderSlideMacroImages = () => {
    return (
      <>
        <div style={marginTopStyle}>
          <Typography variant={"h5"}>Slide Thumbnails</Typography>
        </div>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          {ifSlides.map((ifSlide) => {
            return <RenderIFSlideMacro ifSlide={ifSlide} key={ifSlide.uuid} />;
          })}
        </Grid>
      </>
    );
  };
  const getContextMenuItems = () => (params) => {
    // ag-grid caches some stuff which is why this gets a little harder
    // const gridContext = params.context;
    // const { isStaff } = gridContext;

    if (!params.node?.data) {
      return [];
    }

    const row = params.node.data;
    const rowsSelected = params.api.getSelectedRows();

    if (rowsSelected.length > 1) {
      return getMultiContextMenuItemsIFViewer({
        rows: rowsSelected,
      });
    } else {
      return getSingleContextMenuItemsIFGrid({ row, isStaff });
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <div className={classes.slideView}>
          <Typography variant={"h5"}>IF Slides</Typography>
          <ExportToExcelButton
            color={"primary"}
            onClick={() =>
              exportToExcelAction(gridApi, "HistoWiz-OrderDetails-Slides")
            }
          />
        </div>
        {renderTable()}
      </Grid>
      <Grid item xs={12}>
        {renderSlideMacroImages()}
      </Grid>
    </Fragment>
  );
};

export const IFSlidesTab = ({ order, ifSlides, isIFSlidesLoading }) => {
  return (
    <DefaultTabWrapper>
      <IFSlidesDetailsTable
        order={order}
        ifSlides={ifSlides}
        isIFSlidesLoading={isIFSlidesLoading}
      />
    </DefaultTabWrapper>
  );
};
