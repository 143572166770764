import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSlideInfoForPathologyMapStyles } from "../../PathologyMap/constants/styles";
import { OrderInfo } from "./OrderInfo";
import {
  COMPLETED_ORDERS_TAB_VALUE,
  ORDER_TABS_IDS,
} from "components/PathologyMap/constants/common";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { useOrdersTabContentStyles } from "components/UserProfile/styles";
import { Card } from "components/common/Card/Card";
import {
  ORDERS_CARD_TABS_LIST,
  SlideTypes,
} from "components/UserProfile/constants";
import { HeaderTabs } from "components/UserProfile/components/HeaderTabs";
import { CardHeader } from "components/UserProfile/components/CardHeader";
import { OrdersSortTeamPage } from "components/UserProfile/components/OrdersSortTeamPage";
import { usePrepareOrderForSelectedTab } from "components/UserProfile/hooks";
import { sortOrder } from "utilities/constants";
import { useHistory } from "react-router";
import { onDoubleClick, onSlidesClick } from "components/UserProfile/utilities";
import { ORDER_NAME_SORT_FIELD } from "components/SlideGroups/utilities/constants";

export const OrdersCard = ({
  setSelectedOrder,
  selectedOrder,
  selectedTeam,
}) => {
  const { classes: classesFromPathologyMap } =
    useSlideInfoForPathologyMapStyles();
  const { classes: orderTabStyles } = useOrdersTabContentStyles();
  const classes = { ...classesFromPathologyMap, ...orderTabStyles };
  const history = useHistory();
  const [currentTabValue, setCurrentTabValue] = useState(
    COMPLETED_ORDERS_TAB_VALUE
  );

  const [sort, setSort] = useState({
    field: ORDER_NAME_SORT_FIELD,
    order: sortOrder.DESC,
  });

  const { selectedTabSortedOrders, isOrdersLoading } =
    usePrepareOrderForSelectedTab(
      selectedTeam,
      ORDER_TABS_IDS[currentTabValue],
      sort
    );

  const handleTabChange = useCallback((_event, tabValue) => {
    setCurrentTabValue(tabValue);
  }, []);

  const selectFirstOrder = () => setSelectedOrder(selectedTabSortedOrders[0]);

  useEffect(() => {
    if (!selectedTabSortedOrders?.length) return;

    selectFirstOrder();
  }, [currentTabValue, selectedTeam]);

  useEffect(() => {
    if (selectedOrder || !selectedTabSortedOrders?.length) return;

    selectFirstOrder();
  }, [selectedTabSortedOrders]);

  const isDefaultTab = currentTabValue === COMPLETED_ORDERS_TAB_VALUE;

  return (
    <Card
      headerContent={
        <CardHeader
          title="PROJECTS / ORDERS"
          subtitle=""
          RightSection={
            <HeaderTabs
              currentTabValue={currentTabValue}
              handleTabChange={handleTabChange}
              tabsList={ORDERS_CARD_TABS_LIST}
            />
          }
        />
      }
      bodyContent={
        <Box className={classes.bodyColumn}>
          <OrdersSortTeamPage
            sort={sort}
            setSort={setSort}
            isDefaultTab={isDefaultTab}
          />
          <Box className={classes.ordersList} sx={{ minWidth: 600 }}>
            {isOrdersLoading ? (
              <Box className={classes.spinnerContainer}>
                <Spinner />
              </Box>
            ) : selectedTabSortedOrders.length ? (
              selectedTabSortedOrders.map((order) => (
                <OrderInfo
                  onIFSlidesClick={() =>
                    onSlidesClick(order, history, SlideTypes.IF)
                  }
                  onDefaultSlidesClick={() =>
                    onSlidesClick(order, history, SlideTypes.IHC)
                  }
                  onDoubleClick={() => onDoubleClick(order, history)}
                  isSelected={selectedOrder?.uuid === order.uuid}
                  onRowClick={() => setSelectedOrder(order)}
                  key={order.uuid}
                  order={order}
                  classes={classes}
                  isDefaultTab={isDefaultTab}
                />
              ))
            ) : (
              <Box height="100%" display="flex" alignItems="center">
                <Typography>No such orders</Typography>
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};
