export const SAMPLES_NAME_COLUMN_MAX_LIMIT = 12;
export const BONE_DECALCIFICATION = "bone_decalcification";

export const BONE_ORGAN = "Bone";

export const NON_VALID_SUBMISSIONS_FOR_BONE_ORGAN = [
  "unstained",
  "paraffin_block",
  "oct_block",
];

export const NOTE_EXCEL_SAMPLES = [
  { cells: [] },
  {
    cells: [
      {
        data: { value: null },
        styleId: "emptyWarning",
      },
      {
        data: {
          value: `- If you enter non-existent entity names in these cells, they won't be updated during the import.`,
          type: "String",
        },
      },
    ],
  },
  { cells: [] },
];

export const EXPORT_EXCEL_STYLES = [
  {
    id: "cell",
    alignment: {
      wrapText: true,
      horizontal: "Left",
    },
  },
  {
    id: "header",
    interior: {
      color: "#d9d9d9",
      pattern: "Solid",
    },
    font: {
      bold: true,
    },
  },
  {
    id: "selectCell",
    interior: {
      color: "#ffcc00",
      pattern: "Gray75",
    },
    borders: {
      borderBottom: {
        weight: 1,
      },
      borderTop: {
        weight: 1,
      },
      borderRight: {
        weight: 1,
      },
      borderLeft: {
        weight: 1,
      },
    },
  },
  {
    id: "emptyWarning",
    interior: {
      color: "#ffcc00",
      pattern: "Gray75",
    },
  },
];
