import { makeStyles } from "tss-react/mui";
import { BLUE_BG_STYLES } from "components/Shared/styles";

export const useDrawingModeButtonStyles = makeStyles()(() => ({
  buttonWrapper: {
    textTransform: "none",
    "& p": {
      fontFamily: "Roboto",
      fontSize: "0.8rem",
      fontWeight: 400,
      letterSpacing: "0.1em",
      textAlign: "center",
      paddingInline: "1.2rem",
    },
  },
}));

export const useDrawingToolButtonStyles = makeStyles()(() => ({
  buttonWrapper: {
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&.Mui-selected": {
      ...BLUE_BG_STYLES,
      "&:hover": {
        ...BLUE_BG_STYLES,
      },
    },
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      background: "#bdbdbd",
    },
  },
}));
