import React from "react";
import { TextField } from "@mui/material";

import { useGlpFormStyles } from "components/GlpForm/styles";

export const GlpText = ({
  value,
  onChange,
  title,
  placeholder,
  isMultiline,
  disabled,
}) => {
  const { classes } = useGlpFormStyles();
  const { field } = classes;

  return (
    <TextField
      disabled={disabled}
      label={title}
      autoComplete="off"
      placeholder={placeholder}
      multiline={isMultiline}
      className={field}
      rows={isMultiline ? 4 : 1}
      onChange={({ target: { value } }) => onChange(value)}
      value={value}
      required
    />
  );
};
