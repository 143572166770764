import { Box, Button, TextField, Typography } from "@mui/material";
import { DefaultModal } from "components/Modals/DefaultModal";
import { useStyles } from "components/Modals/ShareSlideModal/styles";
import { ModalSize } from "components/Modals/constants";
import {
  useShareClipboard,
  useSlideLinkClipboard,
} from "components/Share/helpers/hooks";
import {
  SLIDE_SOCIAL_NAME_FOR_SHARE_IF_VIEWER,
  SLIDE_SOCIAL_NAME_FOR_SHARE_VIEWER,
} from "components/Share/helpers/constants";
import { getItemSharedUrl } from "components/Share/helpers/functions";
import { useRouteMatch } from "react-router-dom";
import SocialIcons from "components/Modals/ShareSlideModal/SocialIcons/SocialIcons";
import { getBodyPreview, getIframe } from "./helpers";

const ShareSlideModal = ({ isOpen, onClose, item }) => {
  const match = useRouteMatch();
  const { share_uuid, medium_thumbnail_url, gcp_macro_medium, name, order } =
    item;
  const isIFViewer = match.path.includes("if_viewer");

  const { classes } = useStyles({ isIFViewer: isIFViewer });
  const generatedSlideUrl = getItemSharedUrl(
    isIFViewer
      ? SLIDE_SOCIAL_NAME_FOR_SHARE_IF_VIEWER
      : SLIDE_SOCIAL_NAME_FOR_SHARE_VIEWER,
    share_uuid
  );

  const fullUrl = `${generatedSlideUrl}${window.location.hash}`;
  const imgSrc = isIFViewer ? gcp_macro_medium : medium_thumbnail_url;

  const bodyPreview = getBodyPreview(
    isIFViewer,
    imgSrc,
    fullUrl,
    order?.project?.name ?? "",
    name
  );

  const iframePreview = getIframe(bodyPreview);

  const handleCopyIframe = useSlideLinkClipboard(iframePreview);
  const handleCopyLink = useShareClipboard(
    item,
    isIFViewer
      ? SLIDE_SOCIAL_NAME_FOR_SHARE_IF_VIEWER
      : SLIDE_SOCIAL_NAME_FOR_SHARE_VIEWER
  );

  return (
    <DefaultModal
      open={isOpen}
      onClose={onClose}
      size={ModalSize.LARGE}
      styles={{ padding: "20px" }}
    >
      <Typography className={classes.title}>
        Share slide in social media:
      </Typography>

      <SocialIcons
        fullUrl={fullUrl}
        handleCopyLink={handleCopyLink}
        isIFViewer={isIFViewer}
      />

      {isIFViewer && (
        <>
          <Box className={classes.flexWrapper}>
            <Typography className={classes.inputLabel}>
              Copy the embed code:
            </Typography>
            <TextField
              value={iframePreview}
              className={classes.inputWrapper}
              inputProps={{
                className: classes.input,
              }}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.copyButton}
              onClick={handleCopyIframe}
            >
              Copy
            </Button>
          </Box>
          <Box>
            <iframe
              className={classes.iframe}
              title="preview"
              srcDoc={bodyPreview}
            ></iframe>
          </Box>
        </>
      )}
    </DefaultModal>
  );
};

export default ShareSlideModal;
