import { makeStyles } from "tss-react/mui";
import { TABS_BLUE } from "utilities/colors";

export const useStyles = makeStyles()({
  root: {
    display: "grid",
  },
  centered: {
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tabs: {
    ".Mui-selected": {
      color: TABS_BLUE,
    },
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    alignItems: "center",
  },
  button: {
    margin: "5px 0",
    marginLeft: "auto",
    fontSize: "0.75rem",
    padding: "3px 10px",
  },
});
