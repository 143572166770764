import React from "react";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import Box from "@mui/material/Box";

import { AddAntibodyForm } from "components/OrderForm/components/AddAntibodyForm";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const CreateAntibodyModal = ({
  open,
  onClose,
  teamUUID,
  onAfterSuccessCreate,
}) => {
  const { showSuccess } = useSnackbar();

  const handleAntibodyCreated = (name, uuid, antibody) => {
    onAfterSuccessCreate(antibody);
  };

  return (
    <DefaultDialog open={open} onClose={onClose} title="Create New Antibody">
      <Box width={700}>
        <AddAntibodyForm
          teamUUID={teamUUID}
          handleAddAntibodyModal={onClose}
          addAntibodyModalName=""
          updateOptionsWithAdditionalAntibodyCallback={handleAntibodyCreated}
          handleSnackbarChange={() =>
            showSuccess("Created Antibody is now available in Antibodies list")
          }
        />
      </Box>
    </DefaultDialog>
  );
};
