import { BORDER_COLOR } from "utilities/colors";

export const styles = {
  dropzone: {
    padding: "0.75rem",
    border: `1px dashed ${BORDER_COLOR}`,
    width: "100%",
  },
  disabledCursor: {
    cursor: "not-allowed",
  },
};
