import {
  getOrderOrGroup,
  getSlidesListError,
} from "components/OrderSlidesList/utilities";
import { slideDataSerializer } from "components/utilities/gridDataSerializers";
import { GET_SLIDE_GROUP_ERROR_MESSAGE } from "constants/errorMessages";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  useLazyGetSlideGroupQuery,
  useLazyGetSlideGroupsQuery,
} from "store/apis/slideGroupsApi";
import {
  useLazyGetSlidesByOrderQuery,
  useLazyGetSlidesByProjectQuery,
  useLazyGetSlidesBySharedOrderQuery,
} from "store/apis/slidesApi";
import {
  clearSlidesList,
  setGroup,
  setIsSharedPage,
  setIsSlidesListLoading,
  setProjectUUID,
  setSlidesListOrder,
} from "store/slices/slidesListSlice/slidesListSlice";
import { setSlides } from "store/slices/slidesSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useSlidesListData = () => {
  const match = useRouteMatch();
  const [getSlideGroups, { data: slideGroups }] = useLazyGetSlideGroupsQuery();
  const [getSlideGroup] = useLazyGetSlideGroupQuery();
  const [getSlidesByOrder] = useLazyGetSlidesByOrderQuery();
  const [getSlidesBySharedOrder] = useLazyGetSlidesBySharedOrderQuery();
  const [getSlidesByProject] = useLazyGetSlidesByProjectQuery();

  const history = useHistory();
  const [slidesGroupName, setSlidesGroupName] = useState("");
  const isSharedGroup = history.location.pathname.includes("shared_groups");

  const { showError } = useSnackbar();

  const dispatch = useDispatch();

  const { orderUUID, projectUUID, shareUUID, slideGroupUUID } = match.params;

  useEffect(() => {
    dispatch(setProjectUUID(projectUUID));
  }, [projectUUID]);

  useEffect(() => {
    dispatch(setIsSharedPage(history.location.pathname.includes("shared")));
  }, [history.location.pathname]);

  useEffect(() => {
    if (!orderUUID && !shareUUID) {
      return;
    }

    getOrderOrGroup(orderUUID, shareUUID, isSharedGroup)
      .then((orderDetails) => {
        if (isSharedGroup) {
          setSlideGroupData(orderDetails);

          return;
        }

        dispatch(setSlidesListOrder(orderDetails));
        document.title = `${orderDetails.name} Slides List - HistoWiz`;
      })
      .catch(() => showError(getSlidesListError(orderUUID, isSharedGroup)));

    return () => dispatch(clearSlidesList());
  }, [orderUUID, shareUUID]);

  useEffect(() => {
    const isSharedOrder = shareUUID && !isSharedGroup;
    if (!orderUUID && !isSharedOrder && !projectUUID) {
      return;
    }

    const getOrderSlides = orderUUID
      ? () => getSlidesByOrder({ orderUUID }).unwrap()
      : () => getSlidesBySharedOrder({ shareUUID }).unwrap();

    const getSlides = projectUUID
      ? getSlidesByProject({ projectUUID }).unwrap()
      : getOrderSlides();

    dispatch(setIsSlidesListLoading(true));

    getSlides
      .then((response) => {
        if (response) {
          const rows = response.map(slideDataSerializer);
          dispatch(setSlides(rows));
          dispatch(setIsSlidesListLoading(false));
        }
      })
      .catch((error) => {
        dispatch(setIsSlidesListLoading(false));
        console.log(`Error Getting slides!`);
        console.log(error.response);
      });

    return () => {
      dispatch(clearSlidesList());
    };
  }, [orderUUID, shareUUID, projectUUID]);

  useEffect(() => {
    if (!slideGroupUUID) {
      return;
    }
    // TODO: uncomment in Slide groups V2
    // const getSlideGroupsFunction =
    //   slideGroupUUID === FAVORITE_SLIDES_URL_KEY
    //     ? getSlideGroups
    //     : getSlideGroups;
    getSlideGroups();

    return () => dispatch(clearSlidesList());
  }, [slideGroupUUID]);

  const setSlideGroupData = (slideGroup) => {
    setSlidesGroupName(`"${slideGroup.name}" `);
    const rows = slideGroup.slides.map(slideDataSerializer);
    dispatch(setGroup(slideGroup));
    dispatch(setSlides(rows));
    dispatch(setIsSlidesListLoading(false));
  };

  useEffect(() => {
    if (!slideGroups) {
      return;
    }

    const currentSlideGroup = slideGroups.find(
      (slideGroup) => slideGroup.uuid === slideGroupUUID
    );

    if (currentSlideGroup) {
      setSlideGroupData(currentSlideGroup);
    } else {
      getSlideGroup({ slideGroupUUID })
        .unwrap()
        .then((slideGroup) => setSlideGroupData(slideGroup))
        .catch(() => showError(GET_SLIDE_GROUP_ERROR_MESSAGE));
    }
  }, [slideGroups]);

  useEffect(() => {}, []);

  return {
    projectUUID,
    slidesGroupName,
    slideGroupUUID,
  };
};
