import { Button } from "@mui/material";
import {
  CreatedYearMonthDateFormatter,
  ModifiedDateFormatter,
  OrganNameFormatter,
  SpeciesNameFormatter,
} from "components/utilities/gridDataSerializers";

const orderIDsGetter = (params) => {
  const orderIDs = params?.data?.samples?.map((s) => s.order_id);
  const uniqueOrderIDs = [...new Set(orderIDs)];

  return uniqueOrderIDs.join(", ");
};

export const getBlocksColumnsDef = ({ classes, onBlockUpdate }) => {
  return [
    {
      headerName: "Name",
      field: "name",
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      checkboxSelection: true,
      floatingFilter: true,
      cellClassRules: {
        [classes.newRowClass]: function (params) {
          return params?.data?.isNew === true;
        },
      },
    },
    {
      headerName: "",
      width: 90,
      cellRenderer: ({ node }) => (
        <Button onClick={() => onBlockUpdate(node?.data)}>Void</Button>
      ),
    },
    {
      headerName: "ID",
      field: "id",
      width: 100,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Order IDs",
      valueGetter: orderIDsGetter,
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Samples Count",
      field: "samples.length",
      width: 120,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Original Sample",
      field: "original_sample.name",
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Submission",
      field: "submission.name",
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Submission Type",
      field: "submission.service_type",
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Organ",
      field: "organ",
      valueGetter: OrganNameFormatter,
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Species",
      field: "species",
      valueGetter: SpeciesNameFormatter,
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    { headerName: "Team", field: "team.name" },
    {
      headerName: "UUID",
      field: "uuid",
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Created",
      field: "created",
      //suppressSizeToFit: true,
      width: 100,
      valueGetter: CreatedYearMonthDateFormatter,
      sortable: true,
    },
    {
      headerName: "Modified",
      field: "modified",
      sortable: true,
      unSortIcon: true,
      sort: "desc",
      width: 100,
      valueGetter: ModifiedDateFormatter,
    },
  ];
};
export const SamplesInBlocksColumnsDef = [
  { headerName: "Sample Name", field: "name", filter: "agTextColumnFilter" },
  { field: "id", headerName: "ID", checkboxSelection: true },
  { headerName: "Order ID", field: "order_id" },
  { field: "uuid", headerName: "UUID" },
  {
    headerName: "Created",
    field: "created",
    valueGetter: CreatedYearMonthDateFormatter,
    //suppressSizeToFit: true,
    width: 100,
    sortable: true,
  },
  {
    field: "modified",
    sortable: true,
    unSortIcon: true,
    sort: "desc",
    valueGetter: ModifiedDateFormatter,
  },
];
