import { AgGridReact } from "ag-grid-react";
import { EXPORT_EXCEL_STYLES } from "components/OrderForm/SamplesFormV2/constants";
import { RESPONSIVE_GRID } from "components/Projects/helpers/constants";
import { Paper } from "@mui/material";
import { useSamplesEditorStyles } from "components/SampleEditor/styles";

export const ExportAllGrid = ({ setExportGridApi, fullColumns }) => {
  const { classes } = useSamplesEditorStyles();

  return (
    <Paper
      id="samples-list"
      className={`ag-theme-balham ${classes.agGridWrapper} ${classes.hideGrid}`}
    >
      <AgGridReact
        defaultColDef={RESPONSIVE_GRID}
        columnDefs={fullColumns}
        onGridReady={({ api }) => setExportGridApi(api)}
        excelStyles={EXPORT_EXCEL_STYLES}
      />
    </Paper>
  );
};
