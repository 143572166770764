import { CreateSlideGroupModal } from "components/SlideGroups/Modals/CreateSlideGroupModal";
import { HeaderWithSorts } from "./HeaderWithSorts";
import { Box, Typography, Button } from "@mui/material";
import { SlideGroupItem } from "./SlideGroupItem";
import React, { useMemo, useState } from "react";
import { useGetSlideGroupsQuery } from "store/apis/slideGroupsApi";
import {
  filterSlideGroupsThatContainSelectedSlides,
  slideGroupsSorter,
} from "components/SlideGroups/utilities/utilities";
import { useSlideGroupsVerticalContainer } from "components/SlideGroups/utilities/styles";
import { sortOrder } from "utilities/constants";
import { sortArray } from "utilities/general";

export const SlideGroupsVerticalContainer = ({
  selectedSlides,
  isDeleteMode = false,
  customClasses = {},
  showCreateButton = false,
}) => {
  const { classes } = useSlideGroupsVerticalContainer();
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

  const [sort, setSort] = useState({ field: "name", order: sortOrder.DESC });
  const { data: slideGroups } = useGetSlideGroupsQuery();

  const sortedSlideGroups = useMemo(
    () => sortArray(slideGroups, slideGroupsSorter, sort),
    [sort, slideGroups]
  );

  const preparedSlideGroups = isDeleteMode
    ? filterSlideGroupsThatContainSelectedSlides(
        sortedSlideGroups,
        selectedSlides
      )
    : sortedSlideGroups;

  const combinedClasses = { ...classes, ...customClasses };

  return (
    <>
      <HeaderWithSorts
        setSort={setSort}
        sort={sort}
        classes={combinedClasses}
      />
      <Box className={combinedClasses.slideGroupVerticalContainer}>
        {preparedSlideGroups.length ? (
          preparedSlideGroups.map((slideGroup) => (
            <SlideGroupItem
              classes={{
                container: combinedClasses.groupsRowContainer,
              }}
              key={slideGroup.uuid}
              slideGroup={slideGroup}
              selectedSlides={selectedSlides}
            />
          ))
        ) : (
          <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
            <Typography variant="h5">
              {isDeleteMode
                ? "No groups contain selected slides"
                : "You don't have slide groups"}
            </Typography>
          </Box>
        )}
        {showCreateButton && (
          <>
            <Button
              onClick={() => setIsCreateGroupModalOpen(true)}
              className={classes.addButton}
              color="primary"
              variant="contained"
            >
              Create Slide Group
            </Button>
            <CreateSlideGroupModal
              open={isCreateGroupModalOpen}
              onClose={() => setIsCreateGroupModalOpen(false)}
            />
          </>
        )}
      </Box>
    </>
  );
};
