import { Grid } from "@mui/material";
import { PathologyMapCategoryItem } from "components/PathologyMap/PathologyMapCategories/PathologyMapCategoryItem";
import {
  useCurrentPathologyMapManager,
  useFavoriteCategories,
} from "components/PathologyMap/utilities/hooks";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";
import { usePathologyMapCategoriesItemsStyles } from "./styles";
import {
  findFavoriteCategory,
  hideInvisibleCategories,
  hideNonFavoriteCategoriesAndSort,
} from "components/PathologyMap/utilities/common";
import { memo, useMemo } from "react";
import { FAVORITE_CATEGORIES_TAB_INDEX } from "components/PathologyMap/utilities/constants";

export const PathologyMapCategoriesItems = memo(({ presetIndex }) => {
  const { categoriesList, presets } = useCurrentPathologyMapManager();
  const { classes } = usePathologyMapCategoriesItemsStyles();
  const { items } = useCurrentRefinements();
  const { favoriteCategories, markAsFavorite, markAsUnfavorite } =
    useFavoriteCategories();

  const categoriesFromPreset = presets[presetIndex] || [];

  const preparedCategories = useMemo(
    () =>
      presetIndex === FAVORITE_CATEGORIES_TAB_INDEX
        ? hideNonFavoriteCategoriesAndSort(categoriesList, favoriteCategories)
        : hideInvisibleCategories(categoriesList, categoriesFromPreset),
    [categoriesList, categoriesFromPreset, favoriteCategories, presetIndex]
  );

  const handleStarClick = (isFavorite, refinementType) =>
    isFavorite
      ? markAsUnfavorite(refinementType)
      : markAsFavorite(refinementType);

  return (
    <Grid
      container
      item
      xs
      wrap="nowrap"
      direction="column"
      className={classes.itemsWrapper}
    >
      {preparedCategories.map(
        ({ title, refinementType, isBold, isGroupEnd, isHidden }) => (
          <PathologyMapCategoryItem
            isHidden={isHidden}
            key={refinementType}
            refinementType={refinementType}
            text={title}
            isBold={isBold}
            isGroupEnd={isGroupEnd}
            selectedFilters={items}
            isFavorite={findFavoriteCategory(
              refinementType,
              favoriteCategories
            )}
            handleStarClick={handleStarClick}
          />
        )
      )}
    </Grid>
  );
});
