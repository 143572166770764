import { makeStyles } from "tss-react/mui";

export const useCreatePanelModalStyles = makeStyles()(() => ({
  errorFeedback: {
    color: "red",
    marginTop: "0.25rem",
  },
}));

export const useDefaultDialogStyles = makeStyles()((theme) => ({
  modalPaper: {
    zIndex: 4501, //header has z-index: 4500. It's so big because of the z-index for the Viewer.
  },
  dialogActions: {
    paddingRight: theme.spacing(3),
  },
}));

export const useDeleteModalStyles = makeStyles()(() => ({
  container: {
    height: "100%",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 16px 0 32px",
  },
}));

export const useQCBulkPassFailModalStyles = makeStyles()((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  failedQCButton: {
    padding: theme.spacing(2),
    backgroundColor: "#CC0000",
    color: "white",
  },
  passedQCButton: {
    padding: theme.spacing(2),
    backgroundColor: "#109B10",
    color: "white",
  },
  cancelButton: {
    marginRight: "1rem",
  },
  externalTextField: {
    marginTop: "1rem",
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  qcReviewedText: {
    marginBottom: "1rem",
  },
  passedQCText: {
    display: "inline-block",
  },
  grid: { height: "100%" },
  buttonTypography: { marginTop: "1rem" },
  slideThumbnailImage: {
    verticalAlign: "top",
    marginRight: "1rem",
  },
  thumbnailsWrapper: {
    display: "flex",
    width: "95%",
    height: "95%",
  },
  warningIcon: {
    position: "relative",
    top: "2.5rem",
    left: "0.2rem",
    width: "0",
  },
}));

export const useQCBulkResetModalStyles = makeStyles()((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  failedQCButton: {
    padding: theme.spacing(2),
    backgroundColor: "#cc0000",
    color: "white",
  },
  passedQCButton: {
    padding: theme.spacing(2),
    backgroundColor: "#109B10",
    color: "white",
  },
  cancelButton: {
    marginRight: "1rem",
  },
  externalTextField: {
    marginTop: "1rem",
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  qcReviewedText: {
    marginBottom: "1rem",
  },
  passedQCText: {
    display: "inline-block",
  },
  grid: { height: "100%" },
  buttonTypography: { marginTop: "1rem" },
  slideThumbnailImage: {
    verticalAlign: "top",
    marginRight: "1rem",
    marginBottom: "1rem",
  },
}));

export const useQCPassFailModalHookStyles = makeStyles()((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  accordion: {
    marginBottom: "0.5rem",
  },
  specialInstructionText: {
    margin: "0.25rem 0",
  },
  dialog: {
    paddingBottom: "8px !important",
  },
  failedQCButton: {
    padding: theme.spacing(2),
    backgroundColor: "#CC0000",
    color: "white",
  },
  passedQCButton: {
    padding: theme.spacing(2),
    backgroundColor: "#109B10",
    color: "white",
  },
  qcCheckFailContainer: {
    //"background-image": "linear-gradient(red, green)"
    //backgroundColor: "#109B10",
    //background: "linear-gradient(red, green)",
    //color: "black",
  },
  certainlyPassedContainer: {
    backgroundColor: "#109B10",
  },
  certainlyFailedContainer: {
    backgroundColor: "red",
  },
  dialogActionsWrapper: {
    paddingLeft: theme.spacing(2),
  },
  dialogContentWrapper: {
    height: "100%",
  },
  qcReviewSidebarWrapper: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "100%",
  },
  checkAllButton: {
    width: "15rem",
  },
}));

export const useViewSlideModalStyles = makeStyles()(() => ({
  container: {
    height: "100%",
  },
}));

export const useSlideImageComponentStyles = makeStyles()(() => ({
  container: {
    height: "100%",
  },
  slideImageContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
  },
  slideImage: {
    height: "270px",
    maxWidth: "320px",
  },
}));
