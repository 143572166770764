import { MenuActionItem } from "components/ImageViewer/SlidesViewerHeader";
import { Grain } from "@mui/icons-material";
import React, { useState } from "react";
import { Help } from "components/Shared/Help";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { makeStyles } from "tss-react/mui";
import { useRunSlideAIProcessingMutation } from "store/apis/slidesApi";

export const useStyles = makeStyles()(() => ({
  runAIProcessingActionWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export const RunAIProcessingAction = ({ slides, menuItemStyle = {} }) => {
  const { classes } = useStyles();
  const notRequestedProcessingSlides = slides.filter(
    (slide) => !slide?.ml_processing_requested_datetime
  );
  const isAllSlidesProcessingRequested =
    notRequestedProcessingSlides.length === 0;
  const [isDisabled, setIsDisabled] = useState(isAllSlidesProcessingRequested);
  const { showSuccess, showError } = useSnackbar();

  const [runSlideAIProcessing] = useRunSlideAIProcessingMutation();

  const handleRunSlideAIProcessing = () => {
    setIsDisabled(true);

    Promise.all(
      notRequestedProcessingSlides.map((slide) =>
        runSlideAIProcessing({ slideUUID: slide.uuid })
          .unwrap()
          .catch(() => {
            showError(`Failed to request processing for slide ${slide.name}`);
            setIsDisabled(false);
          })
      )
    ).then((results) => {
      const successCount = results.filter(
        (result) => result.status === 200
      ).length;
      showSuccess(`Processing started for ${successCount} slides`);
    });
  };

  return (
    <div className={classes.runAIProcessingActionWrapper}>
      <MenuActionItem
        icon={<Grain fontSize="small" />}
        onClick={handleRunSlideAIProcessing}
        label="Run Slide AI Processing"
        disabled={isDisabled}
        style={menuItemStyle}
      />
      {isDisabled && (
        <div>
          <Help text="Processing already requested for all selected slides" />
        </div>
      )}
    </div>
  );
};
