import { useEffect, useState } from "react";
import { FETCH_SPECIAL_INSTRUCTIONS_ERROR_MESSAGE } from "constants/errorMessages";
import { getSpecialInstructionsAttachments } from "services/resources/commonResources";
import { serializeNameWithUrl } from "utilities/api";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useSerializedSpecialInstructions = () => {
  const unmountIgnore = useUnmountIgnore();
  const { showError } = useSnackbar();
  const [
    selectableSpecialInstructionsAttachments,
    setSelectableSpecialInstructionsAttachments,
  ] = useState([]);

  useEffect(() => {
    getSpecialInstructionsAttachments()
      .then((response) => {
        const selectableSpecialInstructionsAttachments =
          response.map(serializeNameWithUrl);
        if (!unmountIgnore.current) {
          setSelectableSpecialInstructionsAttachments(
            selectableSpecialInstructionsAttachments
          );
        }
      })
      .catch(() => showError(FETCH_SPECIAL_INSTRUCTIONS_ERROR_MESSAGE));
  }, [unmountIgnore]);

  return {
    selectableSpecialInstructionsAttachments,
  };
};
