import { SnackbarWithContext } from "components/Modals/DefaultSnackbar";
import React from "react";

export const RequestShipBackMaterialsSnackbar = () => {
  return (
    <SnackbarWithContext
      snackbarString={"snackbarRequestBackMaterials"}
      message={
        "Your request to ship back project materials for the order has been received!"
      }
    />
  );
};
