import compose from "recompose/compose";
import React from "react";
import { Button, Checkbox, Grid, Paper } from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import { SubmittingIcon } from "components/icons/LoadingIcon";
import {
  OrderSummaryTable,
  ServicesRequestedTable,
  ShippingTable,
} from "components/OrderForm/components/ReviewTables";
import { getSamples } from "services/resources/samples";
import {
  PLACE_ORDER_REVIEW_FEEDBACK_URL,
  PLACE_ORDER_REVIEW_STEP_URL_V2,
  PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL,
} from "constants/urls";
import {
  getOrderByUUID,
  getOrderShipments,
  submitOrder,
  updateOrder,
} from "services/resources/orders";
import { withContextAsProps } from "react-context-consumer-hoc";
import { AppContext } from "services/context";
import { LAST_ORDER_FORM_PAGE } from "components/OrderForm/constants";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import Typography from "@mui/material/Typography";
import { withSnackbar } from "notistack";
import {
  FETCH_ORDER_ERROR_MESSAGE,
  FETCH_ORDER_SHIPMENT_ERROR_MESSAGE,
  SUBMIT_ORDER_ERROR_MESSAGE,
  UPDATE_ORDER_ERROR_MESSAGE,
} from "constants/errorMessages";
import { connect } from "react-redux";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import { withStyles } from "tss-react/mui";
import { ShipSpecifications } from "components/OrderForm/BillingShipping/BillingShippingComponents";
import { DEFAULT_EXTRA_MESSAGE } from "utilities/hooks/useSnackbar/constants";
import { getSnackbarOptions } from "utilities/hooks/useSnackbar/useSnackbar";

const styles = (theme) => ({
  saveButton: {
    textAlign: "right",
    textTransform: "uppercase",
    marginTop: "2rem",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  confirmPaper: {
    width: "100%",
    marginTop: "2rem",
    padding: theme.spacing(2),
  },
});

class _ReviewView extends React.Component {
  constructor(props) {
    super(props);

    const orderUUID = props.match.params["orderUUID"];

    this.state = {
      isConfirmModalVisible: false,
      submitting: false,
      orderUUID: orderUUID,
      confirmDropbox: true,
      confirmMinimumOrderTotal: false,
      confirmHumanSamples: false,
      samples: [],
      shipments: [],
    };
  }

  componentDidMount() {
    document.title = "Place Order - Review";

    this.updateOrder();
    this.updateSamples();
    this.updateShipments();
  }

  updateShipments = () => {
    getOrderShipments(this.state.orderUUID)
      .then((shipments) => {
        this.setState({ shipments });
      })
      .catch(() => {
        this.props.enqueueSnackbar(FETCH_ORDER_SHIPMENT_ERROR_MESSAGE, {
          variant: "error",
        });
      });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  updateSamples = () => {
    return getSamples(this.state.orderUUID).then((samples) => {
      this.setState({ samples: samples.data });
    });
  };

  updateOrder = () => {
    getOrderByUUID(this.state.orderUUID)
      .then((order) => {
        this.setState({ order });

        if (order.dropbox) {
          this.setState({ confirmDropbox: false });
        }
      })
      .catch(() => {
        this.props.enqueueSnackbar(
          `${FETCH_ORDER_ERROR_MESSAGE} \n ${DEFAULT_EXTRA_MESSAGE}`,
          getSnackbarOptions("error")
        );
      });
  };

  makeAPIRequests = async () => {
    return await submitOrder({
      orderUUID: this.state.orderUUID,
    }).catch(() => {
      this.props.enqueueSnackbar(
        `${SUBMIT_ORDER_ERROR_MESSAGE} \n ${DEFAULT_EXTRA_MESSAGE}`,
        getSnackbarOptions("error")
      );
    });
  };

  modalActions = () => {
    return (
      <>
        <Button onClick={this.onModalClose}>Cancel</Button>
        <Button onClick={this.handleSaveAndContinue} autoFocus>
          <SubmittingIcon submitting={this.state.submitting} />
          Submit
        </Button>
      </>
    );
  };

  onModalClose = () => {
    this.setState({ isConfirmModalVisible: false });
  };

  handleSubmit = () => {
    if (!this.state.samples.length) {
      this.setState({ isConfirmModalVisible: true });
    } else {
      this.handleSaveAndContinue();
    }
  };

  handleSaveAndContinue = () => {
    this.setState({ submitting: true });

    this.makeAPIRequests().then((res) => {
      if (!res) {
        return;
      }
      const { pathology_consultations } = res;
      const pathologyConsultationOrderUUID = pathology_consultations?.[0]?.uuid;

      const orderID = this.state.order.name.replace("Order ", "");

      if (
        this.state.order.requested_pathology_consultation &&
        !!pathologyConsultationOrderUUID
      ) {
        const pcFormUrl =
          PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL.replace(
            ":consultUUID",
            pathologyConsultationOrderUUID
          );

        this.props.history.push(pcFormUrl, {
          from: this.props.history.location.pathname,
        });
      } else {
        this.props.history.push(
          PLACE_ORDER_REVIEW_FEEDBACK_URL.replace(":orderID", orderID)
        );
      }
    });

    updateOrder(this.state.orderUUID, {
      last_order_form_page: LAST_ORDER_FORM_PAGE.REVIEW,
    }).catch(() => {
      this.props.enqueueSnackbar(
        `${UPDATE_ORDER_ERROR_MESSAGE} \n ${DEFAULT_EXTRA_MESSAGE}`,
        getSnackbarOptions("error")
      );
    });
  };

  renderDropboxConfirmation = () => {
    const { classes } = this.props;

    if (!this.state.order.dropbox) {
      return null;
    }

    return (
      <Paper className={classes.confirmPaper}>
        <Checkbox
          checked={this.state.confirmDropbox}
          onChange={this.handleChange("confirmDropbox")}
          value="confirmDropbox"
        />
        <span>
          I will place my samples in the Dropbox with the order number by the
          HistoWiz pickup time. I understand I am responsible for additional
          storage fee if I do not pick up my completed orders from the Dropbox
          within 30 days.
        </span>
      </Paper>
    );
  };

  renderHumanSamplesConfirmation = () => {
    const { classes } = this.props;

    return (
      <Paper className={classes.confirmPaper}>
        <Grid container direction="row" wrap="nowrap" alignItems="baseline">
          <Grid item>
            <Checkbox
              checked={this.state.confirmHumanSamples}
              onChange={this.handleChange("confirmHumanSamples")}
              value="confirmHumanSamples"
            />
          </Grid>
          <Grid item>
            <span>
              I understand and agree that the work done by HistoWiz is expected
              to be for research purposes only and will not be used in
              diagnostic/clinical decision making.
            </span>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderMinimumOrderSizeConfirmation = () => {
    const { classes } = this.props;

    return (
      <Paper className={classes.confirmPaper}>
        <Grid container direction="row" wrap="nowrap" alignItems="baseline">
          <Grid item>
            <Checkbox
              checked={this.state.confirmMinimumOrderTotal}
              onChange={this.handleChange("confirmMinimumOrderTotal")}
              value="confirmMinimumOrderTotal"
            />
          </Grid>
          <Grid item>
            <span>
              I confirm that this order is either a first time free trial OR
              that it will be charged a total of $250 minimum if it does not
              meet the $250 minimum order size (I understand there is a 4 slide
              per marker minimum requirement for IHC and special stain services.
              I will be charged the 4 slides for each marker that does not meet
              this requirement).
            </span>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  render() {
    if (!this.state.order) {
      return null;
    }
    // eslint-disable-next-line no-unused-vars
    const newUrl = PLACE_ORDER_REVIEW_STEP_URL_V2.replace(
      ":orderUUID",
      this.state.orderUUID
    );

    const { classes } = this.props;
    const isDemoUser = this.props.email === "demouser@histowiz.com";

    return (
      <div>
        <ShowStaffUserOnly>
          <Typography variant={"body2"} align={"center"}>
            Click <Link to={newUrl}>here</Link> to use the new order form.
          </Typography>
        </ShowStaffUserOnly>
        <OrderSummaryTable order={this.state.order} />
        <ServicesRequestedTable
          order={this.state.order}
          samples={this.state.samples}
        />
        <ShippingTable order={this.state.order} />
        {this.renderDropboxConfirmation()}
        {this.renderMinimumOrderSizeConfirmation()}
        {this.renderHumanSamplesConfirmation()}

        <Paper className={classes.confirmPaper}>
          <ShipSpecifications />
        </Paper>

        <div className={classes.saveButton}>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color={"primary"}
            disabled={
              isDemoUser ||
              this.state.submitting ||
              !(
                this.state.confirmMinimumOrderTotal &&
                this.state.confirmDropbox &&
                this.state.confirmHumanSamples
              )
            }
          >
            <SubmittingIcon submitting={this.state.submitting} />
            {isDemoUser ? "This is A Demo Account!" : "Submit Order"}
          </Button>
        </div>
        <DefaultDialog
          open={this.state.isConfirmModalVisible}
          onClose={this.onModalClose}
          actions={this.modalActions}
        >
          No samples were added. Do you want to submit the order anyway?
        </DefaultDialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.userDetails.email,
});

const _ReviewViewWithStyles = withStyles(_ReviewView, styles);

export const ReviewView = compose(
  connect(mapStateToProps),
  withRouter,
  withSnackbar,
  withContextAsProps(AppContext)
)(_ReviewViewWithStyles);
