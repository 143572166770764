import { ChangesModal } from "components/UserProfile/components/ChangesModal";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { useTitle } from "components/utilities/hooks/useTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  selectForceOpenTeamOverviewModal,
  setForceOpenTeamOverviewModal,
} from "store/slices/userDetailsSlice";
import { OrdersCard } from "./OrdersCard/OrdersCard";
import { LatestCommentsCard } from "components/UserProfile/LatestCommentsCard/LatestCommentsCard";
import { TeamOverviewCard } from "components/UserProfile/TeamOverviewCard/TeamOverviewCard";
import { OrderTrackingCard } from "components/UserProfile/OrderTrackingCard/OrderTrackingCard";
import { useGetTeamsQuery } from "store/apis/teamsApi";
import { useTeamNewsFeed } from "components/UserProfile/hooks";
import { useTeamOverviewPageStyles } from "components/UserProfile/styles";

export const TeamOverviewPage = () => {
  useTitle("HistoWiz - Team Overview");

  const { classes } = useTeamOverviewPageStyles();

  const { data: teams } = useGetTeamsQuery();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [changesModalOpen, setChangesModalOpen] = useState(true);
  const showChangesModal = useSelector(selectForceOpenTeamOverviewModal);
  const dispatch = useDispatch();

  const { newsfeeds } = useTeamNewsFeed(selectedTeam);

  useEffect(() => {
    if (selectedTeam || !teams?.length) return;

    setSelectedTeam(teams[0]);
  }, [teams]);

  const headerLabel = `${selectedTeam?.name || "Team"} Overview`;

  const handleCloseChangesModal = () => {
    setChangesModalOpen(false);
    dispatch(setForceOpenTeamOverviewModal(false));
  };

  const dataUsage = +(
    selectedTeam?.total_storage_used / Math.pow(1000, 2)
  ).toFixed(2);
  const price = dataUsage < 0.05 ? "0" : Math.ceil(dataUsage) * 49;

  return (
    <MiniDrawerWithContext header={headerLabel}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TeamOverviewCard
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            teams={teams}
          />
        </Grid>
        <Grid item container xs={12} direction="row">
          <Grid item xs={12} lg={8}>
            <OrdersCard
              setSelectedOrder={setSelectedOrder}
              selectedOrder={selectedOrder}
              selectedTeam={selectedTeam}
            />
          </Grid>
          <Grid item xs={12} className={classes.orderTrackingGridCell} lg={4}>
            {!!selectedOrder && <OrderTrackingCard order={selectedOrder} />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LatestCommentsCard newsFeed={newsfeeds?.get(selectedTeam)} />
        </Grid>
        {selectedTeam && (
          <ChangesModal
            forceOpen={showChangesModal}
            open={changesModalOpen}
            onClose={handleCloseChangesModal}
            dataUsage={dataUsage}
            price={price}
            teamName={selectedTeam?.name}
          />
        )}
      </Grid>
    </MiniDrawerWithContext>
  );
};
