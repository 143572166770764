import { useController, useFormContext } from "react-hook-form";
import { Input } from "@mui/material";
import { setAddress } from "components/SignupForm/helpers";
import { makeStyles } from "tss-react/mui";
import { ERROR_PROCESSING_ZIP_CODE } from "components/SignupForm/constants";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

const useStyles = makeStyles()(() => ({
  hint: {
    "&:hover::before": {
      content: '"Enter zip code to autofill city and state"',
      position: "absolute",
      top: -25,
      left: 0,
      height: 30,
      width: 300,
    },
    position: "relative",
  },
}));

export const AutofillAddressInput = ({ placeholder, name }) => {
  const { control, setValue, resetField } = useFormContext();
  const { showError } = useSnackbar();

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const onBlur = () => {
    setAddress(value, setValue).catch(() =>
      showError(ERROR_PROCESSING_ZIP_CODE)
    );
    if (errors["city"]) resetField("city");
    if (errors["state"]) resetField("state");
  };

  const { classes } = useStyles();

  return (
    <div className={classes.hint}>
      <Input
        onBlur={onBlur}
        placeholder={placeholder}
        inputProps={{
          "aria-label": "Description",
        }}
        fullWidth={true}
        value={value || ""}
        onChange={onChange}
      />
    </div>
  );
};
