import { DefaultModal } from "components/Modals/DefaultModal";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { sendFeedbackToChris } from "components/PathologyMap/utilities/services";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

// needs to be removed after new search is fully released
export const ChrisFeedbackModal = ({ open, onClose }) => {
  const [feedbackContent, setFeedbackContent] = useState("");
  const { showError } = useSnackbar();
  const { showSuccess } = useSnackbar();

  const handleSubmit = () => {
    if (!feedbackContent.length) {
      showError("Feedback content cannot be empty");
      return;
    }

    const data = {
      content: feedbackContent,
    };

    sendFeedbackToChris(data)
      .then(() => {
        showSuccess("Feedback sent successfully");
        onClose();
      })
      .catch(() => {
        showError("Failed to send feedback. Please try again.");
      });
  };

  const isSubmitButtonDisabled = !feedbackContent.length;

  return (
    <DefaultModal open={open} onClose={onClose}>
      <Grid container direction="column" spacing={3} minWidth="50rem">
        <Grid item>
          <Typography variant="h6">
            Leave Your Feedback On Search 2.0
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            multiline
            onChange={(e) => setFeedbackContent(e.target.value)}
            rows={4}
            fullWidth
            value={feedbackContent}
            placeholder="Enter your feedback here"
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
          >
            Send Feedback
          </Button>
        </Grid>
      </Grid>
    </DefaultModal>
  );
};
