export const GLP_REQUIRED_TEXT =
  "A GLP Protocols Document is required for GLP orders.";

export const STUDY_STATUSES = [
  { label: "Draft Protocol", value: "draft_protocol" },
  { label: "Protocol Approved", value: "protocol_approved" },
  { label: "Study Initiated / In-Life", value: "study_initiated_in_life" },
  { label: "Histopathology", value: "histopathology" },
  { label: "Report", value: "report" },
  { label: "Completed", value: "completed" },
];

export const HISTOWIZ_PHAZES = [
  { label: "Waiting for Samples", value: "waiting_samples" },
  { label: "Histology Processing", value: "histology_processing" },
  { label: "Pathology Processing", value: "pathology_processing" },
  { label: "Order Complete / Slides Public", value: "slide_complete" },
];

export const INVESTIGATORS = [
  {
    label: "Alan Barusevicius | IHC Manager",
    value: "alan.barusevicius@histowiz.com",
  },
  {
    label: "Jack Casey | Research Associate",
    value: "jack.casey@histowiz.com",
  },
  {
    label: "Adrien Cesaire | Director of Laboratory Operations",
    value: "adrien@histowiz.com",
  },
  { label: "Xing Chen | Project Manager", value: "xing@histowiz.com" },
  { label: "Julie Feldstein | Chief Pathologist", value: "julie@histowiz.com" },
  {
    label: "Andrzej Cholewinski | Image Analysis Lead",
    value: "andrzej@histowiz.com",
  },
  {
    label: "Rachel Clary | Senior Scientist",
    value: "rachel.clary@histowiz.com",
  },
  {
    label: "Abora Ettela | Project Manager",
    value: "abora.ettela@histowiz.com",
  },
  { label: "Fabiola Louis | Project Manager", value: "fabiola@histowiz.com" },
  {
    label: "Sharwari Phanse | Senior Research Associate",
    value: "sharwari@histowiz.com",
  },
  {
    label: "Patrick Savickas | Advanced Immunostains and Research Supervisor",
    value: "patrick@histowiz.com",
  },
  { label: "Suhagi Shah | Project Manager", value: "suhagi.shah@histowiz.com" },
];
