import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ViewLabels } from "components/OrderForm/BillingShipping/AdditionalQuestions/ViewLabels";
import {
  DropboxShipComponent,
  SelfServiceShipComponent,
} from "components/OrderForm/BillingShipping/BillingShippingComponents";
import {
  OvernightShippingConstant,
  OvernightShippingValue,
  TwoDayShippingConstant,
} from "components/OrderForm/BillingShipping/utilities/constants";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { FedExComponent } from "components/OrderForm/components/FedexLabel";
import { SHIPPING_OPTIONS_VALUES } from "components/OrderForm/constants";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isStaffSelector } from "store/slices/userDetailsSlice";
import { isUSA } from "utilities/forms";

export const SelectedShippingDetailsTab = ({
  shippingAddressSelected,
  dropboxOptions,
  shippingOption,
  shippingMethodSelected,
  shippingLabels,
  dropboxSelected,
  handleDropboxChange,
  handleModalChange,
  width,
  height,
  length,
  weight,
  submitFedExComponent,
  submitting,
  handleTabsChange,
}) => {
  const { classes } = useBillingShippingStyles();
  // if no address has been selected, dont render anything
  const isValidCountry = useMemo(
    () => isUSA(shippingAddressSelected?.country),
    [shippingAddressSelected]
  );

  const isStaff = useSelector(isStaffSelector);

  if (!shippingAddressSelected) {
    return (
      <Box mt={2}>
        <Typography>
          A Shipping / Return Address needs to be selected.
        </Typography>
      </Box>
    );
  }

  const shippingOptionLabel =
    shippingOption === OvernightShippingValue
      ? OvernightShippingConstant
      : TwoDayShippingConstant;

  const isNonStaffUserWithLabels = !isStaff && !!shippingLabels?.length;

  if (!shippingMethodSelected) {
    return null;
  }

  switch (shippingMethodSelected.value) {
    case SHIPPING_OPTIONS_VALUES.MYSELF:
      return (
        <div className={classes.selectForm}>
          <SelfServiceShipComponent />
        </div>
      );
    case SHIPPING_OPTIONS_VALUES.HISTOWIZ_FEDEX:
      return isNonStaffUserWithLabels ? (
        <div className={classes.selectForm}>
          <ViewLabels handleTabsChange={handleTabsChange} />
        </div>
      ) : (
        <div className={classes.selectForm}>
          <FedExComponent
            isValidCountry={isValidCountry}
            classes={classes}
            shippingLabels={shippingLabels}
            submitFedExComponent={submitFedExComponent}
            submitting={submitting}
            width={width}
            height={height}
            length={length}
            weight={weight}
            shippingOption={shippingOptionLabel}
            handleModal={handleModalChange}
          />
        </div>
      );
    case SHIPPING_OPTIONS_VALUES.DROPBOX:
      return (
        <div className={classes.selectForm}>
          <DropboxShipComponent
            classes={classes}
            options={dropboxOptions}
            dropboxSelected={dropboxSelected}
            handleDropboxChange={handleDropboxChange}
          />
        </div>
      );
    default:
      return null;
  }
};
