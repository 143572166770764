import { makeStyles } from "tss-react/mui";
import {
  PATHOLOGY_MAP_DEFAULT_BORDER_COLOR,
  PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR,
  PATHOLOGY_MAP_SEARCH_BAR_BG_COLOR,
  PATHOLOGY_MAP_SEARCH_BUTTON_BG_COLOR,
  PATHOLOGY_MAP_SEARCH_BUTTON_HOVER_BG_COLOR,
  PATHOLOGY_MAP_TOOLBAR_BG_COLOR,
} from "components/PathologyMap/constants/styles";

export const usePathologyMapLayoutStyles = makeStyles()(() => ({
  boxSearch: {
    top: 1,
    position: "sticky",
    zIndex: 1,
  },
}));

export const usePathologyMapSearchBarStyles = makeStyles()(() => ({
  searchBarContainer: {
    borderRadius: "3px",
    background: PATHOLOGY_MAP_SEARCH_BAR_BG_COLOR,
    border: `1px solid ${PATHOLOGY_MAP_DEFAULT_BORDER_COLOR}`,
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR}`,
    paddingLeft: "1rem",
    marginBottom: 0,
    "&:focus-within": {
      background: "white",
    },
  },
  searchButton: {
    borderRadius: "3px",
    background: PATHOLOGY_MAP_SEARCH_BUTTON_BG_COLOR,
    height: "100%",
    padding: "0.5rem 5rem",
    marginLeft: "1rem",
    "&:hover": {
      background: PATHOLOGY_MAP_SEARCH_BUTTON_HOVER_BG_COLOR,
    },
  },
  searchIcon: {
    marginRight: "0.5rem",
  },
}));

export const usePathologyMapToolbarStyles = makeStyles()(() => ({
  changeViewButton: {
    "&:hover": {
      backgroundColor: "rgba(66, 148, 213, 0.2)",
    },
  },
  selectedButton: {
    background:
      "linear-gradient(180deg, rgba(66, 148, 213, 0.35), rgba(66, 148, 213, 0.20))",
    color: "black",
  },
  toolbar: {
    background: PATHOLOGY_MAP_TOOLBAR_BG_COLOR,
    borderRadius: "3px",
    border: `1px solid ${PATHOLOGY_MAP_DEFAULT_BORDER_COLOR}`,
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR}`,
    padding: "0.5rem 1rem 0.5rem 3rem",
    gap: "10px 0",
  },
  counterWrapper: {
    marginInline: "0.5rem",
  },
  switch: {
    paddingTop: "0.2rem",
    height: "2rem",
    marginRight: "2rem",
  },
  buttonsSection: {
    width: "100% !important",
  },
}));
