import { difference } from "lodash";
import {
  GridArrayValueSelector,
  IsTumorGetter,
  IsTumorValueSetter,
  OrganValueSetter,
  RequiredCellStyler,
  SampleNameRequiredCellStyler,
  SamplesGridSimpleFormatter,
  SimpleValueNameGetterV3,
  SubmittedInValueSetter,
} from "components/utilities/gridDataSerializers";
import {
  EXPERIMENT_TYPES_VALUES,
  IF_SERVICE,
  REQUESTED_ANTIBODIES_FIELD_VALUE,
  REQUESTED_PANELS_FIELD_VALUE,
  REQUESTED_SERVICES_FIELD_NAME,
  REQUESTED_SERVICES_OPTIONS,
  SPECIALIZED_BLOCK_SERVICE,
  SERVICE_NAME_TO_REQUESTED_SERVICE_MAP,
  REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
  serializedTrueFalseOptionsToSelectInput,
} from "components/OrderForm/constants";
import {
  getOrderAntibodies,
  getOrgans,
  getSampleSubmissions,
  getSimplePanels,
  getSpecialStains,
} from "services/resources/commonResources";
import {
  ALL_HUMAN_AND_NON_HUMAN_COLUMNS,
  COLUMN_TOOLTIPS,
  COLUMNS_LIST_VALIDATION_CRITERIA,
  NONHUMAN_SPECIFIC_COLUMNS,
} from "components/OrderFormV2/SampleScienceInfoV2/ScienceInfoConstants";
import { setColumnVisibleIfValueExists } from "components/utilities/grid";

export const getNameFromData = (data) => {
  const results = data.map((result) => {
    return result.name;
  });

  return results;
};
export const getDisplayNameFromData = (data) => {
  const results = data.map((result) => {
    return result.display_name;
  });

  return results;
};
export const getOrganColumnsData = async () => {
  const _organData = await getOrgans();
  const organData = _organData.filter((result) => {
    return result.is_in_order_form;
  });

  return organData;
};

export const getAntibodiesColumnData = async (order) => {
  return await getOrderAntibodies(order.uuid);
};

export const getSampleSubmissionsColumnData = async (order, isStaff) => {
  const _apiResponse = await getSampleSubmissions(order);

  const validData = isStaff
    ? _apiResponse
    : _apiResponse.filter((result) => {
        return result.is_in_order_form;
      });

  return validData;
};

const getSamplesSpecialStainsColumnData = async () => {
  const _apiResponse = await getSpecialStains();
  return _apiResponse;
};

const getSamplesPanelsColumnData = async () => {
  const _apiResponse = await getSimplePanels();
  return _apiResponse;
};

const toolTipValueGetter = (params) => {
  const fieldName = params?.colDef?.field;
  const helpText = COLUMN_TOOLTIPS[fieldName] ? COLUMN_TOOLTIPS[fieldName] : "";
  return helpText;
};

export const getSampleScienceInfoColumns = ({ fieldName, hide }) => {
  const columnDetails = {
    headerName: fieldName,
    tooltipComponent: "customTooltip",
    tooltipValueGetter: toolTipValueGetter,
    field: fieldName,
    filter: "agTextColumnFilter",
    editable: true,
    hide: hide,
    width: 150,
    // some columns in sample science info have long names
    // force a min to prevent auto-adjusting that makes it hard to read
    minWidth: 125,
  };

  if (COLUMNS_LIST_VALIDATION_CRITERIA[fieldName]) {
    columnDetails["cellEditor"] = "agRichSelectCellEditor";
    columnDetails["cellEditorParams"] = {
      values: COLUMNS_LIST_VALIDATION_CRITERIA[fieldName],
    };
    columnDetails["cellEditorPopup"] = true;
  }

  return columnDetails;
};

export const suppressKeyboardEvent = (params) => {
  // ideally, but im not sure how a backspace or delete should also close the popup editor ...
  if (!params.editing) {
    const isBackspaceKey = params.event.keyCode === 8;
    const isDeleteKey = params.event.keyCode === 46;

    if (isBackspaceKey || isDeleteKey) {
      const columnName = params.column.colId;
      const currentData = params.data[columnName];

      // if the current data is an array type, fill an empty array when backspace/delete,
      // otherwise, make an empty string
      const emptyResult = Array.isArray(currentData) ? [] : "";
      params.data[columnName] = emptyResult;

      // clears the dropdown options
      const { api } = params;
      api.refreshCells();
      return true;
    }
  }
};

//specialized block should be the only service selected
export const handleSettingSpecializedBlockService = (e) => {
  const isSpecializedBlockSelected = e.newValue?.includes(
    SPECIALIZED_BLOCK_SERVICE
  );

  if (!isSpecializedBlockSelected) {
    return;
  }

  // it's needed to prevent loop of setting value
  if (isSpecializedBlockSelected && e.newValue.length === 1) {
    return;
  }

  e.node.setDataValue(
    REQUESTED_SERVICES_FIELD_NAME,
    [SPECIALIZED_BLOCK_SERVICE],
    "resetting services"
  );
};

export const getRequestedServicesOptions = ({ isStaff }) => {
  const requestedServicesOptions = [
    ...REQUESTED_SERVICES_OPTIONS,
    ...(isStaff ? [SPECIALIZED_BLOCK_SERVICE] : []),
  ];

  return requestedServicesOptions;
};

export const handleServicesColumnValueChange = (e) => {
  setIFPanelsColumnVisible(e);
  handleSettingSpecializedBlockService(e);
};

export const setIFPanelsColumnVisible = (e) => {
  if (e.columnApi.columnModel) {
    setColumnVisibleIfValueExists(e, REQUESTED_PANELS_FIELD_VALUE, IF_SERVICE);
  }
};

export const getScienceInfoSamplesColumns = ({ order }) => {
  // check on the order if the species is for human, if so include additional columns
  // because on legacy orders, we didn't store species on the order, this will not always be available
  const isHumanSpeciesOrder = !!order.species?.name
    ?.toLowerCase()
    .includes("human");

  const additionalScienceInfoColumns = isHumanSpeciesOrder
    ? ALL_HUMAN_AND_NON_HUMAN_COLUMNS
    : NONHUMAN_SPECIFIC_COLUMNS;

  // by default, all grid columns should have sample name
  const scienceInfoGridColumns = [
    {
      headerName: "Sample Name",
      field: "name",
      filter: "agTextColumnFilter",
      enableRowGroup: false,
      checkboxSelection: true,
      suppressSizeToFit: true,
      editable: false,
      pinned: "left",
      minWidth: 125,
    },
  ];

  additionalScienceInfoColumns.forEach((fieldName) => {
    const fieldNameColumn = getSampleScienceInfoColumns({
      fieldName: fieldName,
      hide: false,
    });
    scienceInfoGridColumns.push(fieldNameColumn);
  });

  return scienceInfoGridColumns;
};

export const handleDynamicColumnsVisibility = (
  e,
  needGetValuesFromApi = false
) => {
  if (e.api.columnModel) {
    for (let serviceName in SERVICE_NAME_TO_REQUESTED_SERVICE_MAP) {
      const serviceDetailsFieldName =
        SERVICE_NAME_TO_REQUESTED_SERVICE_MAP[serviceName];

      if (needGetValuesFromApi) {
        e.api.forEachNode(({ data }) => {
          const newValue = data[serviceDetailsFieldName];
          setColumnVisibleIfValueExists(
            {
              api: e.api,
              columnApi: e.columnApi,
              newValue,
            },
            serviceDetailsFieldName,
            serviceName
          );
        });
      } else {
        setColumnVisibleIfValueExists(e, serviceDetailsFieldName, serviceName);
      }
    }
  }
};

export const handleClearColumnValues = (e) => {
  if (e.newValue?.length < e.oldValue?.length) {
    const removedServices = difference(e.oldValue, e.newValue);
    const columnsToClear = removedServices.map(
      (serviceName) => SERVICE_NAME_TO_REQUESTED_SERVICE_MAP[serviceName]
    );
    columnsToClear.forEach(
      (column) => column && e.node.setDataValue(column, [])
    );
  }
};

export const handleSampleServicesValueChanged = (e) => {
  handleDynamicColumnsVisibility(e);
  handleClearColumnValues(e);
  handleSettingSpecializedBlockService(e);
};

const getPanelsTooltips = (panelsData) => {
  return panelsData.map((panel) => {
    const antibodiesNames = getDisplayNameFromData(panel.antibodies).join(", ");
    return {
      value: panel.display_name,
      title: antibodiesNames,
    };
  });
};

const getDefaultSampleColumns = ({
  organData,
  antibodiesData,
  sampleSubmissionData,
  specialStainsData,
  panelsData,
  isStaff,
}) => {
  const sampleColumns = [
    {
      headerName: "Sample Name",
      field: "name",
      filter: "agTextColumnFilter",
      enableRowGroup: false,
      checkboxSelection: true,
      suppressSizeToFit: true,
      cellStyle: SampleNameRequiredCellStyler,
      editable: (params) => !params.node.data.previous_sample,
      pinned: "left",
      minWidth: 125,
    },
    {
      headerName: "Submitted In",
      filter: "agTextColumnFilter",
      field: "submission",
      valueGetter: SimpleValueNameGetterV3,
      valueSetter: SubmittedInValueSetter(sampleSubmissionData),
      cellStyle: RequiredCellStyler,
      enableRowGroup: true,
      editable: true,
      width: 125,
      minWidth: 125,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: getNameFromData(sampleSubmissionData),
      },
      cellEditorPopup: true,
    },
    {
      headerName: "Services (Double Click)",
      field: REQUESTED_SERVICES_FIELD_NAME,
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      cellEditor: "reactSelectEditor",
      cellEditorParams: {
        values: getRequestedServicesOptions({ isStaff }),
      },
      cellEditorPopup: true,
      cellStyle: RequiredCellStyler,
      minWidth: 150,
      width: 225,
      editable: true,
      suppressKeyboardEvent: suppressKeyboardEvent,
      onCellValueChanged: handleSampleServicesValueChanged,
    },
    {
      headerName: "IF Panels",
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      field: REQUESTED_PANELS_FIELD_VALUE,
      filter: "agTextColumnFilter",
      editable: true,
      cellEditor: "reactSelectEditor",
      cellEditorParams: {
        values: getDisplayNameFromData(panelsData),
        tooltips: getPanelsTooltips(panelsData),
      },
      cellEditorPopup: true,
      width: 150,
      minWidth: 150,
      suppressKeyboardEvent: suppressKeyboardEvent,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Special Stains",
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      field: REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
      filter: "agTextColumnFilter",
      width: 150,
      minWidth: 150,
      editable: true,
      cellEditor: "reactSelectEditor",
      cellEditorParams: {
        values: getNameFromData(specialStainsData),
      },
      cellEditorPopup: true,
      suppressKeyboardEvent: suppressKeyboardEvent,
      hide: true,
    },
    {
      headerName: "IHC Antibodies",
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      field: REQUESTED_ANTIBODIES_FIELD_VALUE,
      filter: "agTextColumnFilter",
      editable: true,
      cellEditor: "reactSelectEditor",
      cellEditorParams: {
        values: getDisplayNameFromData(antibodiesData),
      },
      cellEditorPopup: true,
      width: 150,
      minWidth: 150,
      suppressKeyboardEvent: suppressKeyboardEvent,
      hide: true,
    },
    {
      headerName: "Organ",
      field: "organ",
      valueGetter: SimpleValueNameGetterV3,
      valueSetter: OrganValueSetter(organData),
      cellStyle: RequiredCellStyler,
      enableRowGroup: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: getNameFromData(organData),
      },
      cellEditorPopup: true,
      width: 100,
      minWidth: 100,
    },
    {
      headerName: "Is Tumor",
      field: "is_tumor",
      valueGetter: IsTumorGetter,
      valueSetter: IsTumorValueSetter,
      cellStyle: RequiredCellStyler,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["Unknown", "True", "False"],
      },
      cellEditorPopup: true,
      enableRowGroup: true,
      editable: true,
      width: 100,
      minWidth: 100,
    },
    {
      headerName: "Control Type",
      field: "control_type",
      filter: "agTextColumnFilter",
      cellStyle: RequiredCellStyler,
      enableRowGroup: true,
      editable: true,
      width: 125,
      minWidth: 125,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: EXPERIMENT_TYPES_VALUES,
      },
      cellEditorPopup: true,
    },
  ];

  return sampleColumns;
};

export const getSamplesColumnsData = async ({ order, isStaff }) => {
  // TODO - Some antibodies may not show up in the order form if no longer public ...
  // For now, we're going to keep the previous method where a staff member
  // will create the IHC Antibody and the user will be able to select it later

  return Promise.all([
    getOrganColumnsData(),
    getAntibodiesColumnData(order),
    getSampleSubmissionsColumnData(order, isStaff),
    getSamplesSpecialStainsColumnData(),
    getSamplesPanelsColumnData(),
  ]).then((response) => {
    const [
      organData,
      antibodiesData,
      sampleSubmissionData,
      specialStainsData,
      panelsData,
    ] = response;

    const sampleColumns = getDefaultSampleColumns({
      organData,
      antibodiesData,
      sampleSubmissionData,
      specialStainsData,
      panelsData,
      isStaff,
    });

    // check on the order if the species is for human, if so include additional columns
    // because on legacy orders, we didn't store species on the order, this will not always be available
    const isHumanSpeciesOrder = !!order.species?.name
      ?.toLowerCase()
      .includes("human");

    const additionalScienceInfoColumns = isHumanSpeciesOrder
      ? ALL_HUMAN_AND_NON_HUMAN_COLUMNS
      : NONHUMAN_SPECIFIC_COLUMNS;

    const additionalPathologyMapColumns = [];
    additionalScienceInfoColumns.forEach((fieldName) => {
      const fieldNameColumn = getSampleScienceInfoColumns({
        fieldName: fieldName,
        hide: true,
      });
      additionalPathologyMapColumns.push(fieldNameColumn);
    });

    const details = {
      antibodies: antibodiesData,
      submissions: sampleSubmissionData,
      specialStains: specialStainsData,
      organs: organData,
      panels: panelsData,
      columns: sampleColumns.concat(additionalPathologyMapColumns),
    };

    return details;
  });
};

export const handleAddNewColumnOption = ({
  gridAPI,
  setLookupCache,
  setColumns,
  createdOption,
  columnName,
  columnId,
}) => {
  setLookupCache((prevState) => ({
    ...prevState,
    [columnName]: [...prevState[columnName], createdOption],
  }));

  const columns = gridAPI.getColumnDefs();

  columns.forEach((column) => {
    if (column.colId === columnId) {
      column.cellEditorParams.values.push(createdOption.display_name);
    }
  });

  setColumns(columns);
};

export const getAllGridData = (gridAPI) => {
  const allData = [];
  if (gridAPI) {
    gridAPI.forEachNode((node) => {
      allData.push(node.data);
    });
  }

  return allData;
};

export const getBoneDecalcificationFormDefaultValues = () => ({
  bone_decalcification: serializedTrueFalseOptionsToSelectInput[false],
});

export const cloneSamples = (samples) =>
  samples?.map((sample) => ({ ...sample })) ?? [];
