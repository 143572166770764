import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { useSelector } from "react-redux";
import Select from "react-select";
import { billingShippingSelectors } from "store/slices/billingShippingSlice/billingShippingSlice";

export const PurchaseOrderForm = ({ onChange }) => {
  const { classes } = useBillingShippingStyles();
  const selectedBlanketPurchase = useSelector(
    billingShippingSelectors.selectedBlanketPurchase
  );
  const blanketPurchaseOrders = useSelector(
    billingShippingSelectors.blanketPurchaseOrders
  );

  const options = blanketPurchaseOrders.map((option) => ({
    ...option,
    label: option.number,
  }));

  return (
    <Select
      options={options}
      isMulti={false}
      onChange={onChange}
      value={selectedBlanketPurchase}
      clearable={false}
      className={classes.selectForm}
    />
  );
};
