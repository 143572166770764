import { makeStyles } from "tss-react/mui";
import { SECONDARY_BLUE, WHITE } from "utilities/colors";
import {
  HORIZONTAL_SCROLL_STYLE,
  VERTICAL_SCROLL_STYLE,
} from "themes/defaultMuiTheme";

const _OrderTypesTabsStyles = () => ({
  tabs: {
    height: "40px",
    minHeight: "40px",
    margin: "0 auto",
  },
  indicator: {
    display: "none",
  },
  selectedTab: {
    backgroundColor: `${WHITE}99`,
    border: "2px solid #8897F3",
    borderRadius: "4px",
  },
});

export const useOrderTypesTabsStyles = makeStyles()(_OrderTypesTabsStyles);

const _OrdersTabContentStyles = (theme) => ({
  ordersList: {
    height: "645px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    ...VERTICAL_SCROLL_STYLE,

    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },
  withPointer: {
    cursor: "pointer",
  },
  slideInfoRow: {
    padding: "0.5rem 1rem 0.5rem 2rem",
  },
  spinnerContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  commentText: {
    width: "100%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    whiteSpace: "normal",
  },
  cellWithLink: {
    color: SECONDARY_BLUE,
  },
  commentContainer: {
    maxWidth: "100000px",
  },
  selectedRow: {
    backgroundColor: "rgba(66, 148, 213, 0.2) !important",
    border: "2px solid rgba(84, 112, 255, 1) !important",
  },
  teamMembersContainer: {
    maxHeight: "74vh",
    overflowY: "scroll",
    ...VERTICAL_SCROLL_STYLE,
  },
  bodyColumn: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    ...HORIZONTAL_SCROLL_STYLE,
  },
});

export const useOrdersTabContentStyles = makeStyles()(_OrdersTabContentStyles);

const _TeamOverviewStyles = () => ({
  link: {
    cursor: "pointer",
    fontWeight: 500,
    textDecoration: "underline",
    textUnderlineOffset: "2px",
  },
  linkIcon: {
    marginLeft: "2px",
  },
  row: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
  },
});

export const useTeamOverviewStyles = makeStyles()(_TeamOverviewStyles);

const _TeamOverviewPageStyles = () => ({
  orderTrackingGridCell: {
    paddingLeft: "1rem",
    "@media (max-width: 1200px)": {
      paddingLeft: 0,
      paddingTop: "1rem",
    },
  },
  changesText: {
    fontSize: "1.25rem",
    paddingBottom: "1.5rem",
  },
});

export const useTeamOverviewPageStyles = makeStyles()(_TeamOverviewPageStyles);

export const TEAM_OVERVIEW_HEADER_SUBTITLE_TYPOGRAPHY_PROPS = {
  color: "rgba(0,0,0,0.5)",
  fontSize: "0.8rem",
  fontStyle: "normal",
  lineHeight: "normal",
};

export const TEAM_OVERVIEW_HEADER_TITLE_TYPOGRAPHY_PROPS = {
  fontSize: "1.125rem",
  letterSpacing: "2.4px",
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 600,
};

const teamOverviewCommentsStyles = {
  image: {
    width: "100%",
    maxHeight: 150,
    paddingRight: "2rem",
  },
};

export const useTeamOverviewCommentsStyles = makeStyles()(
  teamOverviewCommentsStyles
);
