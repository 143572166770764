import { Box, Button, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { DefaultModal } from "components/Modals/DefaultModal";
import { useSaveChangesModalStyles } from "./styles";
import { useDataForModal } from "./helpers/hooks";
import { usePostSamplesMutation } from "store/apis/samplesApi";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { UPDATE_SAMPLES_ERROR_MESSAGE } from "constants/errorMessages";
import { UPDATE_SAMPLES_SUCCESS_MESSAGE } from "constants/successMessages";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { useScrollStyles } from "components/OrderSlidesList/SlideActionTabs/styles";

export const SaveChangesModal = ({
  isOpenModal,
  closeModal,
  allSamples,
  samplesToUpdate,
  updatedSamplesHandler,
  refetchSamples,
  nextPage,
  onChangePage,
}) => {
  const { showError, showSuccess } = useSnackbar();
  const { classes } = useSaveChangesModalStyles();
  const { classes: scrollClasses } = useScrollStyles();

  const { compareData } = useDataForModal(allSamples, samplesToUpdate);
  const [postSamples, { isLoading: isUpdating }] = usePostSamplesMutation();

  const onSaveHandler = () => {
    postSamples(samplesToUpdate)
      .unwrap()
      .then(() => {
        showSuccess(UPDATE_SAMPLES_SUCCESS_MESSAGE);
        updatedSamplesHandler({});
        refetchSamples();
      })
      .catch(() => {
        showError(UPDATE_SAMPLES_ERROR_MESSAGE);
      })
      .finally(() => {
        closeModal();
      });
  };

  const onDiscard = () => {
    if (nextPage) {
      onChangePage(null, nextPage, true);
      closeModal();
      return;
    }
    updatedSamplesHandler({});
    closeModal();
  };

  return (
    <DefaultModal
      open={isOpenModal}
      onClose={closeModal}
      className={classes.modal}
      width="40%"
    >
      <DialogTitle>
        {nextPage ? "These Changes will be Lost" : "Applied Changes"}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          disabled={isUpdating}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <div className={`${classes.listWrapper} ${scrollClasses.root}`}>
        {Object.values(compareData).map((sample) => {
          const { uuid, fullSampleName, ...newDataToShow } = sample.new;

          return (
            <div key={uuid} className={classes.sampleWrapper}>
              <div>
                <b>Sample:</b> {fullSampleName}
              </div>
              {Object.keys(newDataToShow).map((field, index) => {
                return (
                  <div
                    key={`${field}_${index}`}
                    className={classes.fieldsWrapper}
                  >
                    <span className={classes.sampleField}>{field}:</span>{" "}
                    <span className={classes.oldValue}>
                      {sample.old[field]}
                    </span>{" "}
                    <span className={classes.newValue}>
                      {newDataToShow[field]}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <Box className={classes.buttonsWrapper}>
        <Button onClick={onDiscard} variant="outlined" disabled={isUpdating}>
          {nextPage ? "Go Anyways" : "Discard"}
        </Button>
        {!nextPage && (
          <Button
            onClick={onSaveHandler}
            variant="outlined"
            disabled={isUpdating}
          >
            {isUpdating ? <Spinner size={20} color="inherit" /> : "Save"}
          </Button>
        )}
      </Box>
    </DefaultModal>
  );
};
