import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DefaultModal } from "components/Modals/DefaultModal";
import React from "react";
import { ModalSize } from "components/Modals/constants";
import { PleaseCallHistoWiz } from "components/Shared/PleaseCallHistoWiz";

export const SamplesSubmitSaveErrorsModal = ({ open, onClose, children }) => {
  return (
    <DefaultModal open={open} onClose={onClose} size={ModalSize.LARGE}>
      <Container>
        <Typography
          align={"center"}
          variant={"h5"}
          gutterBottom={true}
          color={"primary"}
        >
          There's a slight issue with your samples!
        </Typography>
        <Box my={2}>{children}</Box>
        <Box mt={3}>
          <PleaseCallHistoWiz />
        </Box>
      </Container>
    </DefaultModal>
  );
};
