import { memo } from "react";
import { Box, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { SamplesHistoryTable } from "components/SampleEditor/components/SamplesChangelog/TableComponents";
import { TABLE_HEADER } from "components/SampleEditor/components/SamplesChangelog/constants";

export const TimelineTables = ({
  tables,
  classes,
  rowLoading,
  assignHistoryDetails,
  expandedRows,
}) => {
  const TimeLineComponent = memo(({ table }) => (
    <Timeline className={classes.timeline}>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Box>
            <Typography
              variant="h6"
              component="div"
              display="flex"
              alignItems="center"
              mb={1}
            >
              {table.title}
            </Typography>
            <SamplesHistoryTable
              table={table}
              tableHeader={TABLE_HEADER}
              classes={classes}
              rowLoading={rowLoading}
              assignHistoryDetails={assignHistoryDetails}
              expandedRows={expandedRows}
            />
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  ));

  return tables.length ? (
    <Box mt={2}>
      {tables.map((table, index) => (
        <Box key={index}>
          <TimeLineComponent table={table} />
        </Box>
      ))}
    </Box>
  ) : (
    <Box className={classes.noDataLabel}>No data</Box>
  );
};
