export class StepperBuilder {
  #isConditionProvided = false;
  #condition = null;
  #builder = null;
  #builderProps = null;

  setCondition(condition) {
    this.#isConditionProvided = true;
    this.#condition = condition;

    return this;
  }

  setBuilder(builder) {
    this.#builder = builder;

    return this;
  }

  setBuilderProps(...builderProps) {
    this.#builderProps = builderProps;

    return this;
  }

  build() {
    const isBuilderProvided =
      this.#builder && this.#builder instanceof Function;
    const isConditionPassed =
      (this.#isConditionProvided && this.#condition === true) ||
      !this.#isConditionProvided;

    return isConditionPassed && isBuilderProvided
      ? this.#builder(...this.#builderProps)
      : null;
  }
}
