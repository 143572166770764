export const SERVICE_TYPES = {
  wet_tissue: "wetTissueSlides",
  paraffin_block: "paraffinBlockSlides",
  oct_block: "octBlockSlides",
  unstained: "unstainedServiceSlides",
  slide_scanning: "dataManagementServices",
  a_la_carte: "aLaCarteServices",
  automated_image_analysis: "automatedImageAnalysis",
  expert_pathologist_diagnosis: "expertPathologistDiagnosis",
  additional_billing_items: "additionalBillingItems",
  if_slide_scanning: "dataManagementServices",
};

export const PRICE_REVIEW_PAGE_TITLE = "Place Order - Item Review";

export const PRICE_REVIEW_DESCRIPTION_INVALID =
  "We were unable to calculate the total sum because we could not price a custom item. Please contact sales for a personalized accurate quote.";

export const SLIDES_TABLE_TITLE = "Quote Estimate (Total)";

export const BUTTON_TEXT_CONTACT_SALES = "Contact Sales for Custom Quote";

export const BUTTON_TEXT_SUBMITTED_REQUEST =
  "Submitted Request for Custom Quote";

export const WET_TISSUE_SLIDES = "Wet Tissue to Digital Slide Packages";

export const OCT_BLOCK_SLIDES = "OCT Block to Digital Slide Packages";

export const PARAFFIN_BLOCK_SLIDES = "Paraffin Block to Digital Slide Package";

export const UNSTAINED_SLIDES = "Unstained Slides to Digital Slide Package";

export const DATA_MANAGEMENT_SERVICES = "Data Management Services";

export const AUTOMATED_IMAGE_ANALYSIS = "Automated Image Analysis";

export const EXPERT_DIAGNOSIS = "Expert Pathologist Diagnosis";

export const IHC_OPTIMIZATION = "IHC Optimization";

export const A_LA_CARTE = "A La Carte Services";

export const ADDITIONAL_BILLING = "Additional Billing Items";

export const initialState = {
  wetTissueSlides: [],
  paraffinBlockSlides: [],
  octBlockSlides: [],
  unstainedServiceSlides: [],
  dataManagementServices: [],
  aLaCarteServices: [],
  automatedImageAnalysis: [],
  expertPathologistDiagnosis: [],
  additionalBillingItems: [],
  ihcOptimization: [],
};
