import { baseCreateApi } from "store/apis/baseCreateApi";
import {
  SLIDE_ANNOTATIONS_RESOURCE_NAME,
  SLIDE_ANNOTATION_COMMENTS_RESOURCE_NAME,
  SLIDE_ANNOTATION_COMMENT_RESOURCE_NAME,
  SLIDE_ANNOTATION_RESOURCE_NAME,
} from "services/resources/apiConstants";
import { defaultActionAsyncHandler } from "store/utilities";
import {
  createAnnotation,
  createAnnotationComment,
  deleteAnnotation,
  setAnnotations,
} from "store/slices/annotationsSlice";

export const annotationsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    createSlideAnnotation: builder.mutation({
      query: ({ slideUUID, postParams }) => {
        const url = SLIDE_ANNOTATIONS_RESOURCE_NAME.replace(
          ":slideUUID",
          slideUUID
        );
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          createAnnotation
        );
      },
    }),
    createSlideAnnotationComment: builder.mutation({
      query: ({ annotationUUID, postParams }) => {
        const url = SLIDE_ANNOTATION_COMMENTS_RESOURCE_NAME.replace(
          ":annotationUUID",
          annotationUUID
        );
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          createAnnotationComment
        );
      },
    }),
    deleteSlideAnnotationComment: builder.mutation({
      query: ({ commentUUID }) => {
        const url = SLIDE_ANNOTATION_COMMENT_RESOURCE_NAME.replace(
          ":commentUUID",
          commentUUID
        );
        return {
          url,
          method: "DELETE",
        };
      },
    }),
    updateSlideAnnotationComment: builder.mutation({
      query: ({ commentUUID, data }) => {
        const url = SLIDE_ANNOTATION_COMMENT_RESOURCE_NAME.replace(
          ":commentUUID",
          commentUUID
        );
        return {
          url,
          method: "PUT",
          body: data,
        };
      },
    }),
    updateSlideAnnotation: builder.mutation({
      query: ({ annotationUUID, postParams }) => {
        const url = SLIDE_ANNOTATION_RESOURCE_NAME.replace(
          ":annotationUUID",
          annotationUUID
        );
        return {
          url,
          method: "PUT",
          body: postParams,
        };
      },
    }),
    deleteSlideAnnotation: builder.mutation({
      query: ({ annotationUUID }) => {
        const url = SLIDE_ANNOTATION_RESOURCE_NAME.replace(
          ":annotationUUID",
          annotationUUID
        );
        return {
          url,
          method: "DELETE",
        };
      },
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          deleteAnnotation
        );
      },
    }),
    getSlideAnnotations: builder.query({
      query: ({ slideUUID }) => {
        const url = SLIDE_ANNOTATIONS_RESOURCE_NAME.replace(
          ":slideUUID",
          slideUUID
        );
        return {
          url,
          method: "GET",
        };
      },
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          setAnnotations
        );
      },
    }),
  }),
});

export const {
  useCreateSlideAnnotationMutation,
  useCreateSlideAnnotationCommentMutation,
  useDeleteSlideAnnotationCommentMutation,
  useUpdateSlideAnnotationCommentMutation,
  useUpdateSlideAnnotationMutation,
  useDeleteSlideAnnotationMutation,
  useLazyGetSlideAnnotationsQuery,
  useGetSlideAnnotationsQuery,
} = annotationsApi;
