export const PATHOLOGY_ERROR_MESSAGE = "Error Creating Pathology Consultation!";

export const pathologyFields = [
  {
    name: "desired_pathology_expertise",
    label: "Desired Pathology Expertise",
    description: "Skill in certain organ/tissues/stains, etc.",
  },
  {
    name: "requested_pathologist",
    label: "Requested Pathologist (Optional)",
    description: "",
    checkmark: false,
  },
  {
    name: "species_text",
    label: "Species",
    description: "",
  },
  {
    name: "control_animal",
    label: "Control Animal (If Applicable)",
    description: "",
    checkmark: false,
  },
  {
    name: "type_of_analysis_scoring_required",
    label: "Type of Analysis / Scoring Required",
    description: "",
  },
  {
    name: "anticipated_findings",
    label: "Anticipated Findings",
    description: "",
  },
];

export const GLPError = {
  message: "Is Required!",
};

export const PLACE_ORDER_REVIEW_STEP_URL_REGEX_PATTERN =
  /\/forms\/order\/.+\/review\//;
