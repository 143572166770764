export const WhiteboardIcon = ({ width = 19, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <rect
        opacity="0.6"
        x="0.774902"
        width="18"
        height="18"
        fill="url(#whiteboardIcon)"
      />
      <defs>
        <pattern
          id="whiteboardIcon"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_757_5" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_757_5"
          width="96"
          height="96"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD00lEQVR4nO2dT4hNURzHP8MkRP4slJiEDPlb2Egpyr+yk0j+pIQNwkampFhQLFFmIVGMTFPyt7CSlYlINlYoFpjxJ4yYpzOdV9P0Xu/ee+65Z+5930/9djP3nPv93Hfe3HPOvQNCCCGEEIOQBmAn8Ah4DlwBloTuVD2FfxUoVah7EuGf7VXCl4gMGAm8iyCgXHeB5iw6Vi+0xAi/XJ+BGaE7XgQmAF8TCDDVHrrzReBswvBNdYfufN6ZCfyRgHB0OIRfsr8vErLMMfxeYHHSxuudBuCJowBzlywSsskx/B5gWtLG651hwBtHAadCn0SeOeQY/hdgfOiTyCvjgE+OAvb76FgTcAP45tg5FX0Zdth7jMjhm3kNhUeqGXyx2dbEXPkKHy8ZXI8iQMMO3i5AM8lXE139eM1AAgh7kUkAElDXw1xNnA8wSBkFfHAM73CEdiSgCiccw39vF+trIQEVmAT8cBSwlWhIQAUuOoZvdsUNIRoSMID5wF9HASuJjgQM4L5j+LeJhwT0Y4Vj+OaTM5d4SIDFjNmdjgIuEB8JsOxwDP8nMJn4SAAwAnjrKOBYgvAHjYDRwFrgIHASOAMcBTYD0/HPEcfwP9g759wJWAPcirC97yWwBxjO4NpcW65dDu0HEdBsH+OJe6If7aL2UNLjtGP4r4DGPAmYDXQ5nnQnsIh0iPNgRaVa59h+5gLuOJ5wqd/usn240+vQB/MpJk8CGhy3c5cq1DX7Je4rgGr1L6VPYaYCGu2VW0q5XgNzPAXge3NtpgIMDz0IKNnp420eAqhUv4ApCdpK0n7qB1hYZa7dzKNcAtYDG4Bz9hGeuOG02hurtALwvbk2cwGGBfbhZfN98N0+rDavws+NAY4nGLaexxiSkgx3Lt85cdv3f4AImD9dn8UMqscuK46tMuffnmBTmblpnEi65EIAdlhpTXDFmmHsPLDRDm2X7V8wcY5h1giW4ofcCCizJYX12qj1GFiOX3InADu+v/YUepcdnrJ6y0kuBWBnH6u9nSRp/U55nqnQAsrsTfHmLu56bhrkXkD53uJpCg9EzCJ7CiEAO3TsTbid8EHA18cURkAZs2izG3hRo19m2LoJrCIshRPQn6n2oeoWu9Rp6gCw2mEJMW0KLSAPSEBgJCAwEhAYCQiMBARGAgIjAYGRgMBIQGAkIDASEBgJCIwEBEYCAiMBgZGAwEhA0QWocMpAAgh7EUkAElDXw1hN9OJWvIXfncX/UVFRNYO2KAJm2n2VCpJUM/gU5+0rTfZF067vXFDRl2FbwlffCCGEEEIIIYQQQgiKw38e/dbGZc+Q/AAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};
