import { makeStyles } from "tss-react/mui";
import { HISTOWIZ_SECONDARY_COLOR, WHITE } from "utilities/colors";

export const useChangeModalStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    width: "70vw",
    maxHeight: "80vh",
  },
  storage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: WHITE,
    background: "linear-gradient(160.52deg, #EC6A7A 6.76%, #F696A2 103.29%)",
    padding: "2.4rem 1.5rem 1rem",
    position: "relative",
  },
  storageTitle: {
    fontSize: "1.125rem",
    fontWeight: 500,
    marginBottom: "1rem",
  },
  storageImage: {
    padding: "0 1.25rem",
  },
  storageUsage: {
    display: "grid",
    gap: "0.875rem 2.25rem",
    marginTop: "0.75rem",
    padding: "0 1rem 1.25rem",
    gridTemplateColumns: "1fr 1fr",
    borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
  },
  storageUsageLabel: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  storageUsageValue: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 500,
  },
  advantagesContainer: {
    margin: "1rem 0 0.75rem",
  },
  advantage: {
    display: "flex",
    alignItems: "center",
    gap: "0.8rem",
    marginBottom: "0.75rem",
  },
  advantageText: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  learnMoreButton: {
    width: "100%",
    border: `1px solid ${WHITE}`,
    opacity: 0.8,
  },
  changes: {
    padding: "2.5rem 2rem 1.5rem",
  },
  changesTitle: {
    fontSize: "1.125rem",
    fontWeight: 500,
    marginBottom: "1.5rem",
    textAlign: "center",
    color: HISTOWIZ_SECONDARY_COLOR,
  },
  changesText: {
    fontSize: "0.875rem",
    lineHeight: "1.3rem",
    marginBottom: "1.1rem",
  },
  newMark: {
    position: "absolute",
    padding: "0.25rem 0.5rem",
    borderRadius: "0.25rem",
    background: "#6D6AF5",
    fontSize: "0.5rem",
    top: "1.25rem",
    right: "3.5rem",
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
  },
  checkboxLabel: {
    fontSize: "0.75rem",
    marginTop: "0.25rem",
  },
  changesList: {
    fontSize: "0.875rem",
    lineHeight: "1.3rem",
  },
}));
