import { Box } from "@mui/material";
import { SlideInfoCard } from "components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideInfoCard";
import { CommentsCard } from "components/PathologyMap/Modals/FullSlideInfoModal/SlideCommentsSection/CommentsCard";
import { DefaultModal } from "components/Modals/DefaultModal";
import { SlideViewerCard } from "components/PathologyMap/Modals/FullSlideInfoModal/SlideViewerSection/SlideViewerCard";
import { useAddSlidesToSlideGroupModalStyles } from "components/PathologyMap/Modals/FullSlideInfoModal/styles";
import { MODAL_WITHOUT_BACKGROUND } from "components/SlideGroups/utilities/styles";

export const FullSlideInfoModal = ({ slide, fullSlide, isOpen, onClose }) => {
  const { classes } = useAddSlidesToSlideGroupModalStyles();

  return (
    <DefaultModal
      open={isOpen}
      onClose={onClose}
      width="90%"
      BackdropProps={{
        classes: {
          root: classes.modalRoot,
        },
      }}
      styles={{
        ...MODAL_WITHOUT_BACKGROUND,
        height: "90vh",
        minWidth: "1000px",
        width: "80%",
      }}
    >
      <Box mr="3vh" width="60%" className={classes.modalBackground}>
        <SlideViewerCard slide={fullSlide} />
      </Box>
      <Box width="40%" display="flex" flexDirection="column">
        <Box height="45%" className={classes.modalBackground}>
          <SlideInfoCard slide={slide} />
        </Box>
        <Box
          height={"calc(55% - 1rem)"}
          mt="3vh"
          className={classes.modalBackground}
        >
          <CommentsCard slide={slide} />
        </Box>
      </Box>
    </DefaultModal>
  );
};
