import {
  FormInputV2,
  TextFormInput,
} from "components/OrderForm/components/InfoFormInputs";

export const BaseTextInput = ({ label, description, ...props }) => {
  return (
    <FormInputV2
      {...props}
      formInput="text"
      formLabel={label}
      formPlaceholder={description}
    />
  );
};

export const GoalBriefDescriptionInput = ({ name }) => {
  return (
    <TextFormInput
      name={name}
      formLabel="Goal / Brief Description"
      formPlaceholder="A brief description of your project will help us match you with a pathologist."
      checkmark
    />
  );
};

export const AdditionalDetailsDescriptionInput = ({ name, isDisabled }) => {
  return (
    <TextFormInput
      name={name}
      formLabel="Additional Details (If Applicable)"
      formPlaceholder="Ex: Mice / Animal Age, Gender, Genotype, Age at Sacrifice, Negative Control (non-infected?)"
      disabled={isDisabled}
    />
  );
};
