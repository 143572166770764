import { makeStyles } from "tss-react/mui";
import { HISTOWIZ_SECONDARY_COLOR } from "utilities/colors";

export const useOrderSlidesViewStyles = makeStyles()((theme) => ({
  grid: {
    height: "450px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "transparent",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  info: {
    fontSize: "medium",
    float: "left",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    paddingBottom: "1rem",
  },
  button: {
    margin: 3,
    textTransform: "uppercase",
  },
  buttonsContainer: {
    display: "flex",
    marginBottom: "1rem",
    marginLeft: "auto",
    float: "right",
  },
  topButton: {
    textTransform: "uppercase",
    marginLeft: "1rem",
    marginTop: "1rem",
  },
  downloadButton: {
    textTransform: "uppercase",
    marginLeft: "0rem",
    marginTop: "1rem",
  },
  progress: {
    margin: ".5rem",
  },
  rightAlign: {
    float: "right",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  leftAlign: {
    marginTop: "1rem",
    marginBottom: ".25rem",
    float: "left",
  },
  gridTable: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "4rem",
  },
  toolbar: {
    float: "left",
  },
  link: {
    color: theme.primary,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  headerTextStyle: {
    fontSize: "medium",
    marginBottom: "1rem",
  },
  editableTextStyle: {
    color: HISTOWIZ_SECONDARY_COLOR,
    fontColor: HISTOWIZ_SECONDARY_COLOR,
    fontSize: "medium",
    float: "left",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    marginLeft: "0.25rem",
  },
}));
