import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CopiedSnackbar } from "components/Modals/CopiedSnackbar";
import {
  viewAllSlides,
  viewSelectedSlides,
} from "components/OrderDetails/Tabs/utilities";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "services/context";
import { ORDERS_SLIDE_LIST_URL } from "constants/urls";
import { downloadMultiSlideSource } from "services/resources/slides";
import { backendURL } from "services/backendAPI";
import {
  getSlideAdminColumnDefs,
  getSlideColumnDefs,
} from "components/utilities/AgGridCols/AgGridColumns";

import { openMultiSlideParamsNewWindow } from "components/ImageViewer/utilities";
import {
  addHideColumnsOnGroupEvent,
  agGridDefaultColDefFloatingFilter,
} from "components/utilities/grid";
import { getExportParams } from "components/OrderSlidesList/BaseOrderSlidesListView";
import { gridThemeStyle } from "components/OrderDetails/Tabs/constants";
import { useSlidesListViewStyles } from "components/OrderDetails/styles";
import { isStaffSelector } from "store/slices/userDetailsSlice";
import { FavoriteCell } from "components/utilities/FavoriteSlide/FavoriteCell";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";
import { useDispatch, useSelector } from "react-redux";
import { setSlides, slidesSelector } from "store/slices/slidesSlice";
import { ExportToExcelButton } from "components/OrderForm/Buttons/ExportToExcelButton";
import { useLazyDownloadSlideSourceQuery } from "store/apis/downloadSlideApi";

export const OrderDetailsSlidesListView = ({
  quickFilterText,
  onQuickFilterText,
  rowData,
  isSlidesLoading,
}) => {
  const history = useHistory();
  const { handleContextModalChange } = useContext(AppContext);
  const isStaff = useSelector(isStaffSelector);
  const [gridApi, setGridApi] = useState(null);
  const [displayedRowCount, setDisplayedRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const slides = useSelector(slidesSelector);
  const dispatch = useDispatch();

  useAGGridOverlayControl(isSlidesLoading, rowData, gridApi);

  const { classes } = useSlidesListViewStyles();

  const [downloadSlideSource] = useLazyDownloadSlideSourceQuery();

  const updateDisplayedRowCount = useCallback(() => {
    if (gridApi) {
      const gridDisplayedRowCount = gridApi.getDisplayedRowCount();
      if (displayedRowCount !== gridDisplayedRowCount) {
        setDisplayedRowCount(gridDisplayedRowCount);
      }
    }
  }, [gridApi, displayedRowCount]);

  useEffect(() => {
    // storing slides separately from actual rowData cause updating rowData
    // leads to resetting ag-grid filters, grouping etc.
    dispatch(setSlides([...rowData]));
  }, [rowData]);

  useEffect(() => {
    // updating by transaction cause using setRowData leads to resetting ag-grid filters, grouping etc.
    if (gridApi) {
      gridApi.applyTransaction({
        update: [...slides],
      });
    }
  }, [slides]);

  const getSingleContextMenuItems = ({ row }) => {
    let context = [
      {
        name: "Copy Slide Name",
        action: () => {
          if (row) {
            navigator.clipboard.writeText(row.uploaded_name);
            handleContextModalChange("snackbarCopied")();
          }
        },
      },
      {
        name: "View Slide",
        action: () => {
          if (row) {
            const url = `${ORDERS_SLIDE_LIST_URL.replace(
              ":orderUUID",
              row.order?.uuid
            )}#slideId=${row.uuid}`;
            history.push(url);
          }
        },
      },
      {
        name: "Download Slide",
        action: () => {
          if (row) {
            downloadSlideSource({ slide: row });
          }
        },
      },
    ];

    if (isStaff) {
      const lisaSlideLink = `${backendURL}/lisa/core/slide/${row.id}/change/`;

      const additionalAdmin = [
        {
          name: "View Slide (LISA)",
          action: () => {
            if (row) {
              window.open(lisaSlideLink, "_blank");
            }
          },
        },
      ];

      context = [...context, ...additionalAdmin];
    }
    return context;
  };

  const handleViewAllSlides = () => viewAllSlides({ slides, selectedRows });

  const handleViewSelectedSlides = () => viewSelectedSlides({ selectedRows });

  const getMultipleContextMenuItems = (rows) => {
    return [
      {
        name: "Download Selected Slides",
        action: () => {
          if (rows) {
            downloadMultiSlideSource({ slides: rows });
          }
        },
      },
      {
        name: `View All ${slides.length} Slides`,
        action: handleViewAllSlides,
      },
      {
        name: `View ${rows?.length} Selected Slides`,
        action: handleViewSelectedSlides,
      },
    ];
  };

  const getContextMenuItems = (params) => {
    if (!params.node?.data) {
      return;
    }

    const row = params.node.data;

    if (selectedRows.length > 1) {
      return getMultipleContextMenuItems(selectedRows);
    } else {
      return getSingleContextMenuItems({ row });
    }
  };

  const navigateToSlideViewer = (params) => {
    const { data } = params.node;
    // if you group by something, you aren't able to navigate to the slide viewer
    if (data && data.uuid) {
      const url = `${ORDERS_SLIDE_LIST_URL.replace(
        ":orderUUID",
        data.order?.uuid
      )}#slideId=${data.uuid}`;
      history.push(url);
    }
  };

  const onGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    addHideColumnsOnGroupEvent(columnApi);
  };

  const onRowSelected = () => {
    updateDisplayedRowCount();
    setSelectedRows(gridApi.getSelectedRows());
  };

  const handleCompareSlides = () => {
    openMultiSlideParamsNewWindow({ rows: selectedRows });
  };

  const exportToExcelAction = () => {
    if (gridApi) {
      const fileName = `HistoWiz-OrderDetails-Slides`;
      const exportParams = getExportParams(fileName);
      gridApi.exportDataAsExcel(exportParams);
    }
  };

  const slideAdminFrameworkComponents = {
    favoriteCell: FavoriteCell,
  };

  const getGridColumns = isStaff ? getSlideAdminColumnDefs : getSlideColumnDefs;

  const gridColumns = getGridColumns();
  const getRowId = (row) => row.data.uuid;

  return (
    <div className="ag-theme-balham" style={gridThemeStyle}>
      <Box>
        <Typography
          variant="text"
          onClick={handleViewAllSlides}
          className={classes.viewButton}
        >
          View <span className={classes.underline}>All</span> ({slides?.length})
          Slides <OpenInNew fontSize="small" />
        </Typography>
        {!!selectedRows.length && (
          <Typography
            variant="text"
            onClick={handleViewSelectedSlides}
            className={classes.viewButton}
          >
            View (
            <span className={classes.primaryColor}>{selectedRows?.length}</span>
            ) Selected Slides <OpenInNew fontSize="small" />
          </Typography>
        )}
      </Box>
      <input
        className={classes.formInput}
        value={quickFilterText}
        type="text"
        onChange={onQuickFilterText}
        placeholder="Type text to filter..."
      />
      <ExportToExcelButton
        styles={classes.exportToExcelButton}
        color={"primary"}
        onClick={() =>
          exportToExcelAction(gridApi, "HistoWiz-OrderDetails-Slides")
        }
      />
      <Typography className={classes.count}>
        Showing {displayedRowCount} of {rowData.length} Slides
      </Typography>
      <Button
        variant="contained"
        color={"primary"}
        onClick={handleCompareSlides}
        className={classes.compareSlidesBtn}
      >
        Compare
      </Button>
      <AgGridReact
        onModelUpdated={updateDisplayedRowCount}
        enableSorting={true}
        enableFilter={true}
        quickFilterText={quickFilterText}
        enableColResize={true}
        components={slideAdminFrameworkComponents}
        rowDragManaged={true}
        rowGroupPanelShow={"always"}
        columnDefs={gridColumns}
        rowData={rowData}
        sideBar={true}
        defaultColDef={agGridDefaultColDefFloatingFilter}
        rowSelection={"multiple"}
        onGridReady={onGridReady}
        onCellDoubleClicked={navigateToSlideViewer}
        onRowSelected={onRowSelected}
        getContextMenuItems={getContextMenuItems}
        getRowId={getRowId}
        overlayNoRowsTemplate={"<span>No data</span>"}
        suppressNoRowsOverlay={isSlidesLoading}
      />
      <CopiedSnackbar />
    </div>
  );
};

OrderDetailsSlidesListView.propTypes = {
  onQuickFilterText: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  quickFilterText: PropTypes.string,
};
