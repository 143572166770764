import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { SHIPPING_LABELS_TAB_INDEX } from "components/OrderForm/constants";

export const ViewLabels = ({ handleTabsChange }) => {
  const { classes } = useBillingShippingStyles();

  return (
    <Box>
      <Typography>Shipping label(s) submitted</Typography>
      <Button
        className={classes.buttonTypography}
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => handleTabsChange(null, SHIPPING_LABELS_TAB_INDEX)}
      >
        View Shipping Labels
      </Button>
    </Box>
  );
};
