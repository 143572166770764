import React, { Fragment } from "react";

export const TabPanel = (props) => {
  const { children, value, index } = props;

  if (value !== index) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};
