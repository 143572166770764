import {
  IMAGE_ANALYSIS_SERVICE,
  PATHOLOGY_CONSULTATION_SERVICE,
} from "components/OrderForm/constants";
import {
  requestedServicesForOrder,
  requestedServicesForSamples,
  STEPPER_COMPLETE_INDEX,
  STEPPER_NOT_STARTED_INDEX,
} from "components/OrderDetails/Tabs/constants";
import {
  ORDER_COMPLETE_TRACKER_STATE,
  ORDER_IA_TRACKER_STATE,
  ORDER_PREPARE_TO_SHIP_TRACKER_STATE,
  SHIPPING_SHIPPED_VALUE,
} from "components/OrdersList/constants";
import { StepperBuilder } from "components/OrderDetails/Tabs/OrderProgressTab/StepperBuilder";
import {
  HISTOLOGY_STEPPER,
  IA_AND_PC_STEPPER,
  IA_STEPPER_LABEL,
  PC_STEPPER_LABEL,
  POST_PROCESSING_STEPPER,
  PRE_PROCESSING_STEPPER,
  SHIPPING_STEPPER,
  SHORTENED_ORDER_STATES_LIST,
  STEPS_INDEXES_IN_CHRONOLOGICAL_ORDER,
} from "components/OrderDetails/Tabs/OrderProgressTab/constants";
import { putDateIntoOrderHistoryDescription } from "utilities/dates_and_times";
import { v4 as uuid } from "uuid";

export const getSteppersBuilders = (
  samples,
  order,
  orderHistory,
  latestActiveStepIDX
) => {
  const requestedServices = prepareRequestServices(samples, order);

  const isNonIAOrPCServicesRequested = !!requestedServices.find(
    (service) =>
      service.name !== IMAGE_ANALYSIS_SERVICE &&
      service.name !== PATHOLOGY_CONSULTATION_SERVICE
  );

  const isIAAndPCStepperVisible =
    order.requested_image_analysis || order.requested_pathology_consultation;
  const isShipBackStepperVisible =
    order.ship_back_samples ||
    order.ship_back_stained_slides ||
    order.ship_back_unstained_slides;

  return [
    new StepperBuilder()
      .setCondition(true)
      .setBuilder(buildStepperFromFrame)
      .setBuilderProps(
        PRE_PROCESSING_STEPPER,
        orderHistory,
        latestActiveStepIDX
      ),
    new StepperBuilder()
      .setCondition(isNonIAOrPCServicesRequested)
      .setBuilder(buildStepperFromFrame)
      .setBuilderProps(HISTOLOGY_STEPPER, orderHistory, latestActiveStepIDX),
    new StepperBuilder()
      .setCondition(isIAAndPCStepperVisible)
      .setBuilder(prepareIAAndPCStepper)
      .setBuilderProps(order),
    new StepperBuilder()
      .setCondition(true)
      .setBuilder(buildStepperFromFrame)
      .setBuilderProps(
        POST_PROCESSING_STEPPER,
        orderHistory,
        latestActiveStepIDX
      ),
    new StepperBuilder()
      .setCondition(isShipBackStepperVisible)
      .setBuilder(prepareShippingStepper)
      .setBuilderProps(order, latestActiveStepIDX),
  ];
};

export const findStepIDX = (stepName) =>
  STEPS_INDEXES_IN_CHRONOLOGICAL_ORDER.findIndex(
    (stepIDX) => stepIDX === stepName
  );

export const checkIfStepExistsInHistory = (history, stepState) => {
  return !!history.find(
    (historyObject) => historyObject.tracker_state === stepState
  );
};

export const findLatestStepIDXFromHistory = (history) => {
  let latestStepIDX = STEPPER_NOT_STARTED_INDEX;
  history.forEach((historyObject) => {
    const stepIndex = findStepIDX(historyObject.tracker_state);
    if (stepIndex > latestStepIDX) {
      latestStepIDX = stepIndex;
    }
  });
  return latestStepIDX;
};

export const prepareIAAndPCStepper = (order) => {
  const iaAndPcStepper = IA_AND_PC_STEPPER;

  if (
    order.requested_image_analysis &&
    order.requested_pathology_consultation
  ) {
    iaAndPcStepper.name = `${IA_STEPPER_LABEL}/${PC_STEPPER_LABEL}`;
  } else if (order.requested_image_analysis) {
    iaAndPcStepper.name = IA_STEPPER_LABEL;
  } else if (order.requested_pathology_consultation) {
    iaAndPcStepper.name = PC_STEPPER_LABEL;
  }

  if (order.turnaround_ia_pc_ended_datetime) {
    iaAndPcStepper.activeStepIDX = STEPPER_COMPLETE_INDEX;
  } else if (order.tracker_state === ORDER_IA_TRACKER_STATE) {
    iaAndPcStepper.activeStepIDX = 0;
  } else {
    iaAndPcStepper.activeStepIDX = STEPPER_NOT_STARTED_INDEX;
  }

  return iaAndPcStepper;
};

export const prepareShippingStepper = (order, latestActiveStepIDX) => {
  const shippingStepper = SHIPPING_STEPPER;

  const isShipBackSamplesShippingComplete = order.ship_back_samples
    ? order.ship_back_samples_state === SHIPPING_SHIPPED_VALUE
    : true;
  const isShipBackStainedSlidesShippingComplete = order.ship_back_stained_slides
    ? order.ship_back_stained_slides_state === SHIPPING_SHIPPED_VALUE
    : true;
  const isShipBackUnstainedSlidesShippingComplete =
    order.ship_back_unstained_slides
      ? order.ship_back_unstained_slides_state === SHIPPING_SHIPPED_VALUE
      : true;

  const prepareShippingStepIDX = findStepIDX(
    ORDER_PREPARE_TO_SHIP_TRACKER_STATE
  );

  const isShippedCompletely =
    isShipBackSamplesShippingComplete &&
    isShipBackStainedSlidesShippingComplete &&
    isShipBackUnstainedSlidesShippingComplete;

  const isShippedPartially =
    (order.ship_back_samples &&
      order.ship_back_samples_state === SHIPPING_SHIPPED_VALUE) ||
    (order.ship_back_stained_slides &&
      order.ship_back_stained_slides_state === SHIPPING_SHIPPED_VALUE) ||
    (order.ship_back_unstained_slides &&
      order.ship_back_unstained_slides_state === SHIPPING_SHIPPED_VALUE);

  if (isShippedCompletely) {
    shippingStepper.activeStepIDX = STEPPER_COMPLETE_INDEX;
  } else if (isShippedPartially) {
    shippingStepper.activeStepIDX = 1;
  } else if (latestActiveStepIDX === prepareShippingStepIDX) {
    shippingStepper.activeStepIDX = 0;
  } else if (latestActiveStepIDX < prepareShippingStepIDX) {
    shippingStepper.activeStepIDX = STEPPER_NOT_STARTED_INDEX;
  }

  return shippingStepper;
};

export const buildStepperFromFrame = (
  stepper,
  orderHistory,
  latestActiveStepIDX
) => {
  const steps = stepper.steps;
  const stepsKeys = Object.keys(steps);
  const lastStep = stepsKeys[stepsKeys.length - 1];

  const isOrderComplete = checkIfStepExistsInHistory(
    orderHistory,
    ORDER_COMPLETE_TRACKER_STATE
  );

  const latestPassedStep =
    STEPS_INDEXES_IN_CHRONOLOGICAL_ORDER[latestActiveStepIDX];

  if (steps[latestPassedStep]) {
    stepper.activeStepIDX = isOrderComplete
      ? STEPPER_COMPLETE_INDEX
      : stepsKeys.findIndex((stepKey) => stepKey === latestPassedStep);
  } else {
    const lastStepIndex = findStepIDX(lastStep);
    if (latestActiveStepIDX > lastStepIndex) {
      stepper.activeStepIDX = STEPPER_COMPLETE_INDEX;
    } else {
      stepper.activeStepIDX = STEPPER_NOT_STARTED_INDEX;
    }
  }

  return stepper;
};

export const prepareSteppers = (order, orderHistory, samples) => {
  const latestPassedStepFromHistoryIDX =
    findLatestStepIDXFromHistory(orderHistory);
  const currentStepFromOrderIDX = findStepIDX(order.tracker_state);
  const latestActiveStepIDX = Math.max(
    currentStepFromOrderIDX,
    latestPassedStepFromHistoryIDX
  ); //check max in case if order history isn't provided

  const stepperBuilders = getSteppersBuilders(
    samples,
    order,
    orderHistory,
    latestActiveStepIDX
  );

  return stepperBuilders.reduce((accumulator, builder) => {
    const stepper = builder.build();
    if (stepper) {
      accumulator.push(stepper);
    }

    return accumulator;
  }, []);
};

export const prepareSteppersForCancelledOrder = (
  order,
  orderHistory,
  samples
) => {
  const steppers = prepareSteppers(order, orderHistory, samples);

  return steppers.map((stepper) => {
    return {
      ...stepper,
      activeStepIDX: STEPPER_NOT_STARTED_INDEX,
    };
  });
};

export const prepareRequestServices = (samples, order) => {
  const orderServices = requestedServicesForOrder.filter((requestedService) => {
    return requestedService.fields.find((field) => !!order[field]);
  });

  const samplesServices = requestedServicesForSamples.filter(
    (requestedService) => {
      return !!samples.find(
        (sample) => !!requestedService.fields.find((field) => !!sample[field])
      );
    }
  );

  return [...orderServices, ...samplesServices];
};

export const prepareShortenedOrderHistory = (rawHistory) => {
  let prevState = "";
  return rawHistory.reverse().reduce((result, historyObject) => {
    const currentState = historyObject.tracker_state;
    if (currentState && currentState !== prevState) {
      prevState = currentState;

      const orderProgressState = SHORTENED_ORDER_STATES_LIST.find(
        ({ state }) => state === currentState
      );

      if (orderProgressState) {
        result.unshift({
          nameToDisplay: orderProgressState.label,
          description: orderProgressState.isDateRequired
            ? putDateIntoOrderHistoryDescription(
                orderProgressState.description,
                historyObject.history_date
              )
            : orderProgressState.description,
          ids: uuid(),
          ...historyObject,
        });
      }
    }
    return result;
  }, []);
};

export const prettifyTrackerState = (stateName) => {
  if (!stateName?.length) return "";

  const capitalizedName =
    stateName.charAt(0).toUpperCase() + stateName.slice(1);
  return capitalizedName.replaceAll("_", " ");
};
