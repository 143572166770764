export const COLUMNS = [
  {
    headerName: "Sample Name",
    field: "name",
    sortable: true,
    flex: 2,
  },
  {
    headerName: "Count",
    field: "count",
    editable: true,
    cellEditor: "countEditor",
    flex: 1,
  },
  {
    headerName: "Service Type",
    field: "serviceType",
    sortable: true,
    flex: 2,
  },
  {
    headerName: "Type",
    field: "type",
    sortable: true,
    flex: 2,
  },
  {
    headerName: "Path. Consult #",
    field: "pathology_consultation_count",
    editable: true,
    cellEditor: "countEditor",
    flex: 2,
  },
];

export const tumorTypes = [
  { id: "true", value: "true", text: "True", title: "True" },
  { id: "false", value: "false", text: "False", title: "False" },
  { id: "unknown", value: "unknown", text: "Unknown", title: "Unknown" },
];

export const controlStatusTypes = [
  {
    id: "Experimental",
    value: "Experimental",
    text: "Experimental",
    title: "Experimental",
  },
  { id: "Positive", value: "Positive", text: "Positive", title: "Positive" },
  { id: "Negative", value: "Negative", text: "Negative", title: "Negative" },
  // If it's N/A we just don't send anything and consider it a null response
  { id: "N/A", value: "N/A", text: "N/A", title: "N/A" },
];

export const trueFalseOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const CuttingInstructionsOptions = [
  { value: "coronal", label: "Coronal Plane" },
  { value: "horizontal", label: "Horizontal Plane" },
  { value: "sagittal", label: "Sagittal Plane" },
  { value: "n/a", label: "N/A" },
];

export const CuttingInstructionsOptionsMapping =
  CuttingInstructionsOptions.reduce(function (map, obj) {
    map[obj.value] = obj.label;
    return map;
  }, {});

export const OrderTurnaroundTimeOptions = [
  { value: 2, label: "Two Days (Premium)" },
  { value: 3, label: "Three Days (Default)" },
  { value: 10, label: "Ten Days" },
];

const IHCOptimizationBasic = "basic";
const IHCOptimizationBasicLabel = "Basic IHC Optimization";

const IHCOptimizationExpert = "expert";
const IHCOptimizationExpertLabel = "Expert IHC Optimization";

const CLIENT_LED_BASIC_OPTIMIZATION = "client_led_basic_optimization";
const CLIENT_LED_BASIC_OPTIMIZATION_LABEL = "Client Led (Basic Optimization)";

const HISTOWIZ_LED_BASIC_OPTIMIZATION = "histowiz_led_basic_optimization";
const HISTOWIZ_LED_BASIC_OPTIMIZATION_LABEL =
  "HistoWiz Led (Basic Optimization)";

const HISTOWIZ_LED_PREMIUM_OPTIMIZATION = "histowiz_led_premium_optimization";
const HISTOWIZ_LED_PREMIUM_OPTIMIZATION_LABEL =
  "HistoWiz Led (Premium Optimization)";

export const ORDER_IHC_OPTIMIZATION_DEFAULT_TYPE_VALUE = "";

export const OrderIHCOptimizationTypes = [
  { value: ORDER_IHC_OPTIMIZATION_DEFAULT_TYPE_VALUE, label: "N/A" },
  //{ value: IHCOptimizationBasic, label: IHCOptimizationBasicLabel },
  //{ value: IHCOptimizationExpert, label: IHCOptimizationExpertLabel },
  {
    value: CLIENT_LED_BASIC_OPTIMIZATION,
    label: CLIENT_LED_BASIC_OPTIMIZATION_LABEL,
  },
  {
    value: HISTOWIZ_LED_BASIC_OPTIMIZATION,
    label: HISTOWIZ_LED_BASIC_OPTIMIZATION_LABEL,
  },
  {
    value: HISTOWIZ_LED_PREMIUM_OPTIMIZATION,
    label: HISTOWIZ_LED_PREMIUM_OPTIMIZATION_LABEL,
  },
];

export const OrderIHCOptimizationLabelDisplay = {
  "": "N/A",
  [IHCOptimizationBasic]: IHCOptimizationBasicLabel,
  [IHCOptimizationExpert]: IHCOptimizationExpertLabel,
  [CLIENT_LED_BASIC_OPTIMIZATION]: CLIENT_LED_BASIC_OPTIMIZATION_LABEL,
  [HISTOWIZ_LED_BASIC_OPTIMIZATION]: HISTOWIZ_LED_BASIC_OPTIMIZATION_LABEL,
  [HISTOWIZ_LED_PREMIUM_OPTIMIZATION]: HISTOWIZ_LED_PREMIUM_OPTIMIZATION_LABEL,
};

// filter out the blank "", but show all valid IHC Optimization Types
export const AllIHCOptimizationTypes = Object.keys(
  OrderIHCOptimizationLabelDisplay
).filter((result) => {
  return result;
});

// how we store service_types for ihc_optimizations
export const backendServiceTypesIHCOptimizations = [
  "basic_ihc_optimization", // legacy
  "expert_ihc_optimization", // legacy
  "ihc_optimization",
];

export const ImageAnalysisAndPathologyConsultationTurnaroundTimeOptions = [
  { value: 3, label: "Three Days" },
  { value: 10, label: "Ten Days" },
];

export const PathologyExpertiseOptions = [
  { label: "Bone and Soft Tissue", value: "bone_and_soft_tissue" },
  { label: "Breast", value: "breast" },
  { label: "Cardiac", value: "cardiac" },
  { label: "Dermatopathology", value: "dermatopathology" },
  { label: "Gastrointestinal", value: "gastrointestinal" },
  { label: "General Anatomic", value: "general_anatomic" },
  { label: "General Surgical", value: "general_surgical" },
  { label: "Genitourinary", value: "genitourinary" },
  { label: "Gynecology", value: "gynecology" },
  { label: "HistoWiz Recommended", value: "histowiz_recommended" },
  { label: "Hematopathology", value: "hematopathology" },
  { label: "Hepatic", value: "hepatic" },
  { label: "Head Neck and Endocrine", value: "head_neck_endocrine" },
  {
    label: "Infectious/Immune Disorders",
    value: "infectious_immune_disorders",
  },
  { label: "Neuropathology", value: "neuropathology" },
  { label: "Neuropathology", value: "neuropathology" },
  { label: "Oncology", value: "oncology" },
  { label: "Pulmonary", value: "pulmonary" },
  { label: "Renal", value: "renal" },
];

export const getFormSerializedValueForPathologyExpertise = (specialities) => {
  if (!specialities) {
    return [];
  }

  const serializedArray = PathologyExpertiseOptions.filter((option) =>
    specialities.includes(option.value)
  );
  return serializedArray;
};

export const H_AND_E_SERVICE = "H&E";
export const IHC_SERVICE = "IHC";
export const IF_SERVICE = "Immunofluorescence";
export const UNSTAINED_SERVICE = "Unstained";
export const PATHOLOGY_CONSULTATION_SERVICE = "Pathology Consultation";
export const SPECIAL_STAIN_SERVICE = "Special Stain";
export const IMAGE_ANALYSIS_SERVICE = "Image Analysis";
export const GROSS_PROCESS_EMBED_ONLY_SERVICE = "Gross Process Embed (Only)";
export const SCAN_ONLY_SERVICE = "Scan Only";
export const IF_SCAN_ONLY_SERVICE = "IF Scan Only";

// TODO - rename specialized block service to be called Combination Block on all frontend / backend
export const SPECIALIZED_BLOCK_SERVICE = "Combination Block";

export const IHC_SERVICE_DETAILS = { value: "ihc", label: IHC_SERVICE };
export const IF_SERVICE_DETAILS = { value: "if", label: IF_SERVICE };
export const H_AND_E_SERVICE_DETAILS = {
  value: "h_and_e",
  label: H_AND_E_SERVICE,
};
export const SPECIAL_STAINS_SERVICE_DETAILS = {
  value: "special_stain",
  label: SPECIAL_STAIN_SERVICE,
};
export const UNSTAINED_SERVICE_DETAILS = {
  value: "unstained",
  label: UNSTAINED_SERVICE,
};

export const SLIDE_NAVIGATOR_SLIDE_TYPES = [
  H_AND_E_SERVICE_DETAILS,
  UNSTAINED_SERVICE_DETAILS,
  IHC_SERVICE_DETAILS,
  SPECIAL_STAINS_SERVICE_DETAILS,
];

export const PATHOLOGY_CONSULTATION_SERVICE_DETAILS = {
  value: "pathology_consultation",
  label: "Pathology Consultation",
};

export const IMAGE_ANALYSIS_SERVICE_DETAILS = {
  value: "image_analysis",
  label: "Image Analysis",
};

export const GROSS_PROCESS_EMBED_ONLY_DETAILS = {
  value: "gross_process_embed_only",
  label: "Gross Process Embed (Only)",
};

export const SCAN_ONLY_DETAILS = {
  value: "scan_only",
  label: "Scan Only",
};

export const IF_SCAN_ONLY_DETAILS = {
  value: "if_scan_only",
  label: "IF Scan Only",
};

export const servicesOptions = [
  H_AND_E_SERVICE_DETAILS,
  UNSTAINED_SERVICE_DETAILS,
  IF_SERVICE_DETAILS,
  IHC_SERVICE_DETAILS,
  SPECIAL_STAINS_SERVICE_DETAILS,
  PATHOLOGY_CONSULTATION_SERVICE_DETAILS,
  IMAGE_ANALYSIS_SERVICE_DETAILS,
  GROSS_PROCESS_EMBED_ONLY_DETAILS,
  SCAN_ONLY_DETAILS,
  IF_SCAN_ONLY_DETAILS,
];
export const adminServiceOptions = [...servicesOptions];

const _servicesOptionsMapping = () => {
  // Do this to make sure it's only called one time
  let mapping = {};
  servicesOptions.forEach((detail) => {
    mapping[detail.value] = detail.label;
  });
  return mapping;
};

export const servicesOptionsMapping = _servicesOptionsMapping();

// use this because react-select expects an object
export const serializedTrueFalseOptionsToSelectInput = {
  true: { label: "Yes", value: true },
  false: { label: "No", value: false },
  null: false,
};

// use this because react-select expects an object
export const serializedTurnaroundTime = {
  2: { label: "Two Days (Premium)", value: 2 },
  3: { label: "Three Days", value: 3 },
  5: { label: "Five Days", value: 5 },
  10: { label: "10 Days", value: 10 },
};

export const MYSELF = "myself";
export const HISTOWIZ_FEDEX = "histowiz_fedex";
export const DROPBOX = "dropbox";

export const SHIPPING_OPTIONS_VALUES = {
  MYSELF,
  HISTOWIZ_FEDEX,
  DROPBOX,
};

export const SHIPPING_OPTIONS_DETAILS = {
  [MYSELF]: "Ship It Myself",
  [HISTOWIZ_FEDEX]: "HistoWiz Generated FedEx Label (Preferred)",
  [DROPBOX]: "Dropoff At A Dropbox",
};

export const SHIPPING_OPTIONS = [
  { value: MYSELF, label: SHIPPING_OPTIONS_DETAILS[MYSELF] },
  {
    value: HISTOWIZ_FEDEX,
    label: SHIPPING_OPTIONS_DETAILS[HISTOWIZ_FEDEX],
  },
  { value: DROPBOX, label: SHIPPING_OPTIONS_DETAILS[DROPBOX] },
];

export const IS_TUMOR_FIELD_NAME = "is_tumor";
export const REQUESTED_SERVICES_FIELD_NAME = "requested_services";

// full list of services is generated by getRequestedServicesOptions
export const REQUESTED_SERVICES_OPTIONS = [
  H_AND_E_SERVICE,
  SPECIAL_STAIN_SERVICE,
  IHC_SERVICE,
  UNSTAINED_SERVICE,
  GROSS_PROCESS_EMBED_ONLY_SERVICE,
  SCAN_ONLY_SERVICE,
  PATHOLOGY_CONSULTATION_SERVICE,
  IMAGE_ANALYSIS_SERVICE,
  IF_SERVICE,
  IF_SCAN_ONLY_SERVICE,
];

export const REQUESTED_ANTIBODIES_FIELD_VALUE = "requested_antibodies";
export const REQUESTED_PANELS_FIELD_VALUE = "requested_panels";
export const REQUESTED_SPECIAL_STAINS_FIELD_VALUE = "requested_special_stains";

export const EXPERIMENT_TYPES_VALUES = [
  "Experimental",
  "Positive",
  "Negative",
  "N/A",
];

export const TumorDetails = {
  null: "Unknown",
  true: "True",
  false: "False",
};

// when using something that flips dictionary k/v values, True/False values in Javascript end up
// being strings ... so here, just do it by hand of setting true/false
export const TumorDetailsInverted = {
  Unknown: null,
  True: true,
  False: false,
};

export const ORDER_INFO = "order_info";
export const SAMPLES = "samples";
export const SCIENCE_INFO = "science_info";
export const SELECT_SLIDES = "select_slides";
export const PRICE_PREVIEW = "price_review";
export const BILLING = "billing";
export const REVIEW = "review";

export const LAST_ORDER_FORM_PAGE = {
  ORDER_INFO,
  SAMPLES,
  SCIENCE_INFO,
  SELECT_SLIDES,
  PRICE_PREVIEW,
  BILLING,
  REVIEW,
};
export const NO_STATE = "no state";
export const USA_VALUE = "usa";
export const CANADA_VALUE = "canada";
export const OTHER_VALUE = "other";

export const COUNTRIES = [
  { value: USA_VALUE, label: "USA" },
  { value: CANADA_VALUE, label: "Canada" },
  { value: OTHER_VALUE, label: "other" },
];

export const CANADA_PROVINCES = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland And Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "NU", label: "Nunavut" },
  { value: "NT", label: "Northwest Territories" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "YT", label: "Yukon" },
];

export const USA_STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export const REVIEW_PAGE_TITLE = "Place Order - Review";

export const DEMO_USER_EMAIL = "demouser@histowiz.com";

export const DEMO_ACCOUNT_MESSAGE = "This is A Demo Account!";

export const SUBMIT_ORDER = "Submit Order";

export const SPECIALIZED_ORDER = "specialized";
export const ROUTINE_ORDER = "routine";

export const SERVICE_NAME_TO_REQUESTED_SERVICE_MAP = {
  [IF_SERVICE]: REQUESTED_PANELS_FIELD_VALUE,
  [IHC_SERVICE]: REQUESTED_ANTIBODIES_FIELD_VALUE,
  [SPECIAL_STAIN_SERVICE]: REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
};

export const ORDER_FETCHING_ERROR_MESSAGE =
  "An error occurred during order fetching!";
export const FILE_UPLOADING_ERROR_MESSAGE =
  "An error occurred during file uploading!";
export const SUBMITTING_ERROR_MESSAGE = "An Error occurred When Submitting!";

export const POST_ANTIBODY_ERROR_MESSAGE =
  "An Error occurred during posting antibody!";
export const GRID_IS_NOT_INITIALIZED_ERROR_MESSAGE =
  "Grid is not initialized. Try to refresh page!";

export const BILLING_TYPE_PO_NUMBER = "po_number";
export const BILLING_TYPE_BLANKET_PURCHASE_ORDER = "blanket_purchase_order";
export const BILLING_TYPE_CREDIT_CARD = "credit_card";

export const SHIPPING_DETAILS_TAB_INDEX = 0;
export const SHIPPING_LABELS_TAB_INDEX = 1;

export const PARAFFIN_BLOCKS = "Paraffin Blocks";

export const SAMPLES_PAGE_V2_VIEW = "isSamplesPageV2";
