export const MAX_CHANNELS = 6;
export const DEFAULT_OVERVIEW = {
  margin: 35,
  scale: 0.15,
  position: "bottom-left",
};
export const COLORMAP_OPTIONS = [
  "viridis",
  "greys",
  "magma",
  "jet",
  "hot",
  "bone",
  "copper",
  "summer",
  "density",
  "inferno",
];

export const DAPI = "DAPI";
export const SAMPLE_AF = "Sample AF";
export const OPAL_480 = "Opal 480";
export const OPAL_520 = "Opal 520";
export const OPAL_570 = "Opal 570";
export const OPAL_620 = "Opal 620";
export const OPAL_690 = "Opal 690";
export const OPAL_780 = "Opal 780";
export const CY_5 = "Cy5 MSI";
export const CY_3 = "Cy3";
export const FITC = "FITC";
export const RED_TEXAS = "Texas Red";

export const OPTIONS = "options";

export const DEFAULT_PRESETS = {
  [DAPI]: DAPI,
  [OPAL_480]: OPAL_480,
  [OPAL_520]: OPAL_520,
  [OPAL_570]: OPAL_570,
  [OPAL_620]: OPAL_620,
  [OPAL_690]: OPAL_690,
  [OPAL_780]: OPAL_780,
  [SAMPLE_AF]: SAMPLE_AF,
  [OPTIONS]: {},
};

export const COLORS_MAP = {
  [DAPI]: [47, 0, 169],
  [OPAL_480]: [63, 247, 233],
  [OPAL_520]: [22, 239, 57],
  [OPAL_570]: [254, 255, 0],
  [OPAL_620]: [220, 143, 33],
  [OPAL_690]: [214, 44, 29],
  [OPAL_780]: [243, 254, 247],
  [CY_5]: [211, 110, 112],
  [CY_3]: [194, 176, 120],
  [FITC]: [73, 103, 141],
  [RED_TEXAS]: [144, 72, 132],
  [SAMPLE_AF]: [0, 0, 0],
};

export const COLOR_PALLETE = [
  [0, 0, 255],
  [0, 255, 0],
  [255, 0, 255],
  [255, 255, 0],
  [255, 128, 0],
  [0, 255, 255],
  [255, 255, 255],
  [255, 0, 0],
];

export const GLOBAL_SLIDER_DIMENSION_FIELDS = ["z", "t"];
export const FILL_PIXEL_VALUE = "----";

export const PATHOLOGY_CONSULTATION_PAGE_TITLE =
  "Submitted Pathology Consultation";

export const DEFAULT_ERROR_MESSAGE =
  "Something has gone wrong loading the IF image.";

export const IF_SLIDE_API_PREFIX = "https://api.histowiz.com/if_slides";
export const IF_SLIDE_NAME = "slide.ome.tiff";

export const COLORMAP_SLIDER_CHECKBOX_COLOR = [220, 220, 220];

export const SLIDER_DEBOUNCE_TIME = 50;

export const ZOOM_LEVELS = [1, 2, 4, 5, 10, 20, 40];

const ZOOM_OFFSET = 0.002;
export const DEFAULT_ZOOM_MIN = -6 + ZOOM_OFFSET;
export const DEFAULT_ZOOM_MAX = 0 - ZOOM_OFFSET;

export const ZOOM_STEP = 2;

export const MAIN_VIEW_LAYER = "detail";

export const OVERVIEW_LAYER = "overview";

export const IF_VIEWER_COLORS_INTENSITY = "IF_VIEWER_COLORS_INTENSITY";

export const DEFAULT_ZOOM_LEVEL = 1;

export const LEFT_VIEW_LAYER = "left";
export const RIGHT_VIEW_LAYER = "right";

export const MAX_ALLOWED_CHANNELS = 6;

export const HASH_PARAMS_DELIMITER = "&";

export const DEFAULT_CONTRAST = 40;
export const MIN_CONTRAST = 1;
export const MAX_CONTRAST = 100;
export const CONTRAST_STEP = 1;
export const BRIGHTNESS_CUTOFF_PERCENTILE_8_BIT = 5e-2;
export const BRIGHTNESS_CUTOFF_PERCENTILE_16_BIT = 5e-4;
export const NEGATIVE_MULTIPLIER = 4;
export const UINT8 = "Uint8";

export const NON_EXISTENT_INDEX = -1;

export const ERROR_MAKING_SCREENSHOT = "Error while making screenshot";
export const ERROR_PARSING_URL =
  "Failed to parse viewer params from URL, please check the link";
