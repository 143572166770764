import React, { useEffect, useMemo, useState } from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import { getOrders, updateOrder } from "services/resources/orders";
import { OrderFormWizardNavigator } from "components/OrderForm/WizardNavigator/WizardNavigatorView";
import { AgGridReact } from "ag-grid-react";
import { useHistory, useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import { getSamples } from "services/resources/samples";
import { FormProvider } from "react-hook-form";
import {
  LAST_ORDER_FORM_PAGE,
  REQUESTED_ANTIBODIES_FIELD_VALUE,
  REQUESTED_PANELS_FIELD_VALUE,
  SUBMITTING_ERROR_MESSAGE,
} from "components/OrderForm/constants";
import {
  getAllGridData,
  getSamplesColumnsData,
  getUpdatedArrWithEmptyLines,
  handleAddNewColumnOption,
  handleDynamicColumnsVisibility,
} from "components/OrderForm/SamplesFormV2/utilities";
import { SamplesStatusBar } from "components/OrderForm/SamplesFormV2/components/SamplesStatusBar/SamplesStatusBar";
import {
  gridThemeStyle,
  useOrderFormSamplesV2Styles,
} from "components/OrderForm/SamplesFormV2/styles/js/common";
import {
  samplesListValidation,
  yupSamplesValidation,
} from "components/OrderForm/SamplesFormV2/validators";
import { getDuplicatesFromArray } from "utilities/general";
import {
  PLACE_ORDER_PRICE_REVIEW_STEP_URL,
  PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL,
  PLACE_ORDER_SLIDES_STEP_URL,
} from "constants/urls";
import { handleFillHandleDoubleClicked } from "components/utilities/grid";
import {
  RenderBackendValidationErrors,
  RenderDuplicateSameNames,
  RenderNoRowsError,
  RenderNoSpeciesInOrder,
  RenderValidationErrors,
} from "components/OrderForm/SamplesFormV2/components/ValidationErrors";
import { useTitle } from "components/utilities/hooks/useTitle";
import { CreatePanelModal } from "components/Modals/CreatePanelModal";
import { CreateAntibodyModal } from "components/Modals/CreateAntibodyModal";
import { debounce } from "lodash";
import { SubmittingIcon } from "components/icons/LoadingIcon";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import {
  serializeRequestedAntibodiesOnSample,
  serializeRequestedPanelsOnSample,
  serializeRequestedServicesOnSample,
  serializeRequestedSpecialStainsOnSample,
  serializeSampleDetailsToBackendPostRequest,
  serializeScienceInfoOnSample,
} from "components/OrderForm/SamplesFormV2/serializers";
import { useGetOrderByUUIDQuery } from "store/apis/orderApi";
import { useGetProjectsQuery } from "store/apis/projectsApi";
import {
  FETCH_DATA_ERROR_MESSAGE,
  FETCH_ORDER_ERROR_MESSAGE,
  UPDATE_ORDER_ERROR_MESSAGE,
} from "constants/errorMessages";
import { AddPreviousSamplesModal } from "components/OrderForm/SamplesFormV2/components/SelectExistingSamplesModal/SelectExistingSamplesModal";
import { isStaffSelector } from "store/slices/userDetailsSlice";

import { PleaseCallHistoWiz } from "components/Shared/PleaseCallHistoWiz";
import { ReactSelectEditor } from "components/OrderForm/SamplesFormV2/components/ReactSelectEditor";
import { SamplesSubmitSaveErrorsModal } from "components/OrderForm/SamplesFormV2/components/SamplesSubmitSaveErrorsModal";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { IF_SERVICE_TYPE } from "components/OrderForm/SlideServicesForm/constants";
import { finishedOrderStates } from "components/OrdersList/constants";
import { BoneDecalcificationInput } from "components/OrderForm/components/AdditionalQuestionsFormInput";
import {
  BONE_DECALCIFICATION,
  BONE_ORGAN,
  EXPORT_EXCEL_STYLES,
  NON_VALID_SUBMISSIONS_FOR_BONE_ORGAN,
} from "components/OrderForm/SamplesFormV2/constants";
import { useBoneDecalcificationForm } from "components/OrderForm/SamplesFormV2/hooks";
import { ActionButtonsPanel } from "./components/ActionButtonsPanel";
import {
  useLazyGetSamplesFromOrdersQuery,
  usePostSamplesMutation,
} from "store/apis/samplesApi";
import { cloneSamples } from "components/OrderForm/SamplesFormV1/utilities";

const frameworkComponents = {
  reactSelectEditor: ReactSelectEditor,
};

export const SamplesPageV2 = () => {
  useTitle("Place Order - Samples");

  const { classes } = useOrderFormSamplesV2Styles();
  const history = useHistory();
  const match = useRouteMatch();
  const unmountIgnore = useUnmountIgnore();
  const matchOrderUUID = match.params.orderUUID;

  const [orderUUID, setOrderUUID] = useState(matchOrderUUID);
  const [rows, setRows] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [columns, setColumns] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isSamplesLoading, setIsSamplesLoading] = useState(false);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);
  const [gridAPI, setGridAPI] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [samplesFromOrders, setSamplesFromOrders] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [addPreviousSamplesModalOpen, setAddPreviousSamplesModalOpen] =
    useState(false);

  const [errorsListModalOpen, setErrorsListModalOpen] = useState(false);
  const [validationErrorsFound, setValidationErrorsFound] = useState(false);
  const [duplicatedSampleNames, setDuplicatedSampleNames] = useState([]);
  const [backendIssueErrors, setBackendIssueErrors] = useState("");
  const [ordersAndSamples, setOrdersAndSamples] = useState([]);
  const [sampleSubmissions, setSampleSubmissions] = useState([]);

  const [previousOrders, setPreviousOrders] = useState([]);

  const { showError } = useSnackbar();

  const isStaff = useSelector(isStaffSelector);
  const { data: order = null, error } = useGetOrderByUUIDQuery(orderUUID);
  const { data: projects, isLoading: isProjectsLoading } =
    useGetProjectsQuery();
  const [postSamples] = usePostSamplesMutation();
  const [getSamplesFromOrders] = useLazyGetSamplesFromOrdersQuery();

  useQueryError(error, FETCH_ORDER_ERROR_MESSAGE);

  const { materials_at_histowiz: materialsAtHistowiz } = order || {};

  const fetchOrdersAndSamples = async () => {
    setIsOrdersLoading(true);

    try {
      const orderIDs = previousOrders.map(({ uuid }) => uuid).join(",");
      const samplesResponse = await getSamplesFromOrders({
        orderUUIDs: orderIDs,
      });

      setSamplesFromOrders(samplesResponse?.data ?? []);

      const ordersResponse = await getOrders();
      if (!unmountIgnore.current) {
        const orders = ordersResponse.data.filter(
          ({ uuid, contains_samples, ship_back_samples, state }) => {
            const differentOrder = uuid !== order.uuid;
            const samplesStillAtHistoWiz = !ship_back_samples;
            const orderInValidState = finishedOrderStates.includes(state);

            return (
              orderInValidState &&
              differentOrder &&
              contains_samples &&
              samplesStillAtHistoWiz
            );
          }
        );

        setPreviousOrders(orders.map(({ name, uuid }) => ({ name, uuid })));
      }
    } catch (error) {
      showError(error.message);
    } finally {
      if (!unmountIgnore.current) {
        setIsOrdersLoading(false);
      }
    }
  };

  useEffect(() => {
    setAddPreviousSamplesModalOpen(!!materialsAtHistowiz);
  }, [materialsAtHistowiz]);

  useEffect(() => {
    if (!materialsAtHistowiz) return;

    fetchOrdersAndSamples();
  }, [unmountIgnore, materialsAtHistowiz]);

  const [isCreatePanelModalVisible, setIsCreatePanelModalVisible] =
    useState(false);

  const { bone_decalcification, methods } = useBoneDecalcificationForm(order);

  // A bit of a hack, but have a cache to know what value ag-Grid is referring to,
  // for UI and Ag-Grid Reference, we store based on names of Antibodies / Special Stains
  // so now we need a way to look back the original thing for the UUID
  // use of react-context / redux would be useful here
  const [lookupCache, setLookupCache] = useState({});

  const is3DaysTAT = order?.turnaround_days === 3;

  const isIFServiceRequested = rows.some((row) =>
    row.requested_services?.includes(IF_SERVICE_TYPE)
  );

  const [isCreateAntibodyModalVisible, setIsCreateAntibodyModalVisible] =
    useState(false);

  const [isChangesSaved, setIsChangesSaved] = useState(true);

  const allData = getAllGridData(gridAPI);
  const isValidBoneSampleExists = useMemo(
    () =>
      allData.find(
        (sample) =>
          sample.organ?.name === BONE_ORGAN &&
          !NON_VALID_SUBMISSIONS_FOR_BONE_ORGAN.includes(
            sample.submission?.service_type
          )
      ),
    [allData]
  );

  const handleGridRowDataUpdated = () => {
    setIsChangesSaved(false);
  };

  const checkboxHandler = (node) => {
    if (!gridAPI) return;
    const { selected, rowIndex } = node;

    gridAPI.getDisplayedRowAtIndex(rowIndex).selectThisNode(!selected);
    gridAPI.redrawRows({ rowNodes: [node] });

    setSelectedRows(gridAPI.getSelectedRows());
  };

  useEffect(() => {
    if (
      !previousOrders?.length ||
      ordersAndSamples?.length ||
      !projects?.length ||
      !samplesFromOrders?.length
    )
      return;

    const ordersWithSamples = previousOrders.map((order) => {
      const samples = samplesFromOrders.filter(
        (sample) => sample.order.uuid === order.uuid
      );

      return {
        ...order,
        projectName: projects?.find((project) =>
          project.orders?.find((o) => o.uuid === order.uuid)
        )?.name,
        checked: false,
        samples: samples.map((sample) => ({ ...sample, checked: false })),
      };
    });

    setOrdersAndSamples(
      ordersWithSamples.filter((order) => !!order.samples.length)
    );
  }, [previousOrders, projects, samplesFromOrders]);

  const handleWizardNavigatorBeforeNavigate = () => {
    if (isChangesSaved) return false;

    const promptText = "Changes you made may not be saved. Leave page?";
    return !window.confirm(promptText);
  };

  const toggleCreateAntibodyModal = () =>
    setIsCreateAntibodyModalVisible((prevState) => !prevState);

  // in the slim chance that the order UUID is changed from react, the orderUUID needs to be updated
  useEffect(() => {
    setOrderUUID(matchOrderUUID);
  }, [matchOrderUUID]);

  useEffect(() => {
    if (!order || columns.length) {
      return;
    }

    // a bit critical but for ag-grid to fill out the columns, we need to get all the possible options
    // in the dropdowns, this assembles that and then only should ag-grid appear
    setIsColumnsLoading(true);
    if (!gridAPI) return;
    getSamplesColumnsData({ order, isStaff, checkboxHandler })
      .then((response) => {
        if (!unmountIgnore.current) {
          setColumns(response.columns);
          setSampleSubmissions(response.submissions);
          setLookupCache(response);
        }
      })
      .catch(() => showError(FETCH_DATA_ERROR_MESSAGE))
      .finally(() => {
        setIsColumnsLoading(false);
      });
  }, [order, unmountIgnore, gridAPI]);

  const getSamplesData = () => {
    setIsSamplesLoading(true);
    if (!gridAPI) return;

    getSamples(orderUUID)
      .then((response) => {
        if (unmountIgnore.current) return;

        const samplesData = cloneSamples(response?.data);

        const emptyRows = [...new Array(20)].map((_, i) => {
          return {
            order_uuid: orderUUID,
            id: new Date().getTime() + i,
          };
        });

        if (!unmountIgnore.current) {
          samplesData.forEach((sample) => {
            serializeRequestedServicesOnSample(sample);
            serializeRequestedAntibodiesOnSample(sample);
            serializeRequestedSpecialStainsOnSample(sample);
            serializeRequestedPanelsOnSample(sample);
            serializeScienceInfoOnSample(sample);
          });

          setRows([...samplesData, ...emptyRows]);
          checkAllRowsAreValid();
        }
      })
      .catch(() => showError(FETCH_DATA_ERROR_MESSAGE))
      .finally(() => setIsSamplesLoading(false));
  };

  useEffect(() => {
    getSamplesData();
  }, [orderUUID, unmountIgnore, gridAPI]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isChangesSaved) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history, isChangesSaved]);

  const toggleCreatePanelModal = () =>
    setIsCreatePanelModalVisible((prevState) => !prevState);

  const handleSaveAndContinue = () => {
    if (validationErrorsFound) {
      setErrorsListModalOpen(true);
      return;
    }

    // if we get this far, disable the save and continue so people will not click
    // it multiple times - for a large amount of samples, this can create a lock
    // on the samples table, causing app issues ... will refactor backend in future
    setIsSubmitting(true);

    const allData = getAllGridData(gridAPI);
    const postParams = allData.map((result) => {
      return serializeSampleDetailsToBackendPostRequest(
        result,
        order,
        lookupCache
      );
    });

    postSamples(postParams)
      .then(() => {
        // pathology map consent orders have to fill out sample science
        // route an order to either fill out sample science info
        let url;
        if (order.pathology_map_consent) {
          url = PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL.replace(
            ":orderUUID",
            orderUUID
          );
        } else {
          url = PLACE_ORDER_SLIDES_STEP_URL.replace(":orderUUID", orderUUID);
        }

        const isGPE = !postParams.filter(
          (sample) => !sample.gross_process_embed_only
        ).length;

        if (isGPE) {
          url = PLACE_ORDER_PRICE_REVIEW_STEP_URL.replace(
            ":orderUUID",
            orderUUID
          );
        }

        setIsChangesSaved(true);

        history.push(url);
      })
      .catch((error) => {
        const errorMessage = error.response
          ? JSON.stringify(error.response.data)
          : SUBMITTING_ERROR_MESSAGE;

        showError(errorMessage);

        setBackendIssueErrors(errorMessage);
        setErrorsListModalOpen(true);
        setIsSubmitting(false);
      });

    const previousOrdersUUIDs = [
      ...new Set(
        allData
          .filter((sample) => sample.previous_sample)
          .map((sample) => sample.order.uuid)
      ),
    ];

    updateOrder(orderUUID, {
      bone_decalcification: isValidBoneSampleExists
        ? bone_decalcification.value
        : false,
      last_order_form_page: LAST_ORDER_FORM_PAGE.SAMPLES,
      materials_at_histowiz_previous_orders_v2_uuids: previousOrdersUUIDs,
    }).catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));
  };

  const handleSetAddPreviousSamplesModal = () => {
    setAddPreviousSamplesModalOpen(!addPreviousSamplesModalOpen);
  };

  const onGridReady = (params) => {
    setGridAPI(params.api);
    setGridColumnApi(params.columnApi);
  };

  const checkAllRowsAreValid = debounce(() => {
    const allData = getAllGridData(gridAPI);

    // get sample names trimmed and then check for duplicates
    const sampleNames = allData.map(({ name }) =>
      name ? name.trim().toUpperCase() : ""
    );
    // get all samples that are just not just empty spaces
    const sampleNamesNoSpaces = sampleNames.filter((name) => !!name);

    // if we find duplicates, store it so that we can reference it later
    const duplicatedSampleNames = getDuplicatesFromArray(sampleNamesNoSpaces);
    setDuplicatedSampleNames(duplicatedSampleNames);

    if (duplicatedSampleNames.length > 0) {
      console.log("Duplicated Sample Names Found");
      setValidationErrorsFound(true);
    }

    if (allData.length === 0) {
      console.log("No Data Found");
      setValidationErrorsFound(true);
    }

    // if the order is missing species, dont let the user go to the next page
    const missingSpeciesError = !order?.species;

    samplesListValidation
      .validate(allData)
      .then(() => {
        // the additional if statement here is a check because
        // we should only let validation pass if
        // there are no duplicate samples names
        // and there is at least some data
        if (duplicatedSampleNames.length > 0) {
        } else if (allData.length === 0) {
        } else if (missingSpeciesError) {
        } else {
          setValidationErrorsFound(false);
        }
      })
      .catch(() => {
        setValidationErrorsFound(true);
      });
  }, 300);

  const onProcessDataFromClipboard = (e) => {
    const data = e?.data;

    const pastedData = data.map((sample, i) => {
      return {
        name: sample.toString(),
        order_uuid: orderUUID,
        id: new Date().getTime() + i,
      };
    });

    setRows((prev) => {
      return getUpdatedArrWithEmptyLines(prev, pastedData);
    });

    checkAllRowsAreValid();
    gridAPI.redrawRows();
  };

  const onCellValueChanged = (e) => {
    if (!gridAPI) return;
    setIsChangesSaved(false);

    const fieldName = e.colDef.field;
    const prevValue = e.oldValue;
    if (fieldName === "name" && !prevValue) {
      const emptyRow = {
        order_uuid: orderUUID,
        id: new Date().getTime(),
      };
      gridAPI.applyTransaction({
        add: [emptyRow],
      });
    }

    checkAllRowsAreValid();
    gridAPI.redrawRows();
  };

  const handleSetErrorsListModalOpen = () => {
    setErrorsListModalOpen(!errorsListModalOpen);
  };

  const renderErrorModal = () => {
    if (!gridAPI) {
      return;
    }

    const allData = getAllGridData(gridAPI);
    const issueMessages = [];
    allData.forEach((row, index) => {
      try {
        yupSamplesValidation.validateSync(row, { abortEarly: false });
      } catch (validationError) {
        const errors = validationError.errors;
        const sampleName = row.name;

        // humans dont count by 0
        const humanRowIndex = index + 1;

        // create an array of dicts describing the fail reason
        errors.forEach((errorMessage) => {
          const errorDetail = {
            rowIndex: humanRowIndex,
            name: sampleName,
          };

          errorDetail["message"] = errorMessage;
          issueMessages.push(errorDetail);
        });
      }
    });

    return (
      <SamplesSubmitSaveErrorsModal
        open={errorsListModalOpen}
        onClose={handleSetErrorsListModalOpen}
      >
        <RenderValidationErrors issueMessages={issueMessages} />
        <RenderDuplicateSameNames
          duplicatedSampleNames={duplicatedSampleNames}
        />
        <RenderNoRowsError rows={allData} />
        <RenderBackendValidationErrors
          backendIssueErrors={backendIssueErrors}
        />
      </SamplesSubmitSaveErrorsModal>
    );
  };

  const handleFirstDataRendered = (e) => {
    handleDynamicColumnsVisibility(e, true);
  };

  const isColumnsLoaded = !!gridColumnApi?.getColumns()?.length;

  const isLoading =
    columns.length === 0 ||
    isProjectsLoading ||
    isSamplesLoading ||
    isColumnsLoading ||
    isOrdersLoading;

  useEffect(() => {
    if (!columns.length && !isColumnsLoaded) return;

    handleFirstDataRendered({ api: gridAPI, columnApi: gridColumnApi });
  }, [columns.length, isColumnsLoading, isColumnsLoaded]);

  const handleSuccessOptionCreate =
    (columnName, columnId) => (createdOption) => {
      handleAddNewColumnOption({
        gridAPI,
        setColumns,
        setLookupCache,
        createdOption,
        columnName,
        columnId,
      });
    };

  useAGGridOverlayControl(isLoading, rows, gridAPI);

  const renderGrid = () => {
    return (
      <div
        className={`ag-theme-balham ${classes.agGridWrapper}`}
        style={gridThemeStyle}
      >
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columns}
          rowData={rows}
          enableColResize={true}
          rowSelection={"multiple"}
          enableFillHandle={true}
          // want the grid to stop editing when focus leaves the cell or the grid
          stopEditingWhenCellsLoseFocus={true}
          fillHandleDirection={"y"}
          enableRangeSelection={true}
          editable={true}
          suppressClearOnFillReduction={true}
          onCellValueChanged={onCellValueChanged}
          components={frameworkComponents}
          processDataFromClipboard={onProcessDataFromClipboard}
          onRowDataUpdated={handleGridRowDataUpdated}
          onCellDoubleClicked={handleFillHandleDoubleClicked}
          overlayNoRowsTemplate={"<span>No data</span>"}
          // if we don't suppressRowClick, copy and paste keeps on trying to copy
          // the entire row and it really sucks for the entire experience
          suppressRowClickSelection={true}
          suppressCopyRowsToClipboard={true}
          suppressNoRowsOverlay={isLoading}
          rowHeight={40}
          excelStyles={EXPORT_EXCEL_STYLES}
        />
      </div>
    );
  };

  return (
    <>
      <div className={classes.page}>
        <div className={classes.navigator}>
          <OrderFormWizardNavigator
            onBeforeNavigate={handleWizardNavigatorBeforeNavigate}
          />
        </div>
        <Box mt={2} px={2}>
          {/*This render no species is from a while back before we used to mandate */}
          {/*species be filled in the order form.*/}
          <RenderNoSpeciesInOrder order={order} />
          <Box mb={1}>
            <ActionButtonsPanel
              classes={classes}
              showSelectedServiceWarning={is3DaysTAT && isIFServiceRequested}
              toggleCreateAntibodyModal={toggleCreateAntibodyModal}
              disableLeftButtons={!selectedRows.length}
              disableRightButtons={isLoading}
              toggleCreatePanelModal={toggleCreatePanelModal}
              handleSetAddPreviousSamplesModal={
                handleSetAddPreviousSamplesModal
              }
              materialsAtHistowiz={materialsAtHistowiz}
              gridAPI={gridAPI}
              setSelectedRows={setSelectedRows}
              checkAllRowsAreValid={checkAllRowsAreValid}
              orderUUID={orderUUID}
              getSamplesData={getSamplesData}
              setRows={setRows}
              orderName={order?.name}
            />
          </Box>
          {renderGrid()}
          {columns.length !== 0 && gridAPI && (
            <SamplesStatusBar count={getAllGridData(gridAPI).length} />
          )}
          {isValidBoneSampleExists && (
            <FormProvider {...methods}>
              <BoneDecalcificationInput name={BONE_DECALCIFICATION} />
            </FormProvider>
          )}
          {!isLoading && (
            <AddPreviousSamplesModal
              ordersAndSamples={ordersAndSamples}
              setAddPreviousSamplesModalOpen={setAddPreviousSamplesModalOpen}
              addPreviousSamplesModalOpen={addPreviousSamplesModalOpen}
              handleSetAddPreviousSamplesModal={
                handleSetAddPreviousSamplesModal
              }
              checkAllRowsAreValid={checkAllRowsAreValid}
              sampleSubmissions={sampleSubmissions}
              setRows={setRows}
            />
          )}
          {renderErrorModal()}
          <Box mt={1} mb={2} display={"flex"} justifyContent={"flex-end"}>
            <Button
              className={
                validationErrorsFound
                  ? classes.saveAndContinueButtonError
                  : classes.saveAndContinueButton
              }
              variant="contained"
              color={"primary"}
              disabled={isSubmitting || !order?.species || isLoading}
              onClick={handleSaveAndContinue}
            >
              <SubmittingIcon submitting={isSubmitting} />
              {validationErrorsFound
                ? "Save & Continue (!)"
                : "Save & Continue"}
            </Button>
          </Box>

          <PleaseCallHistoWiz />
          <Box mt={1}>
            <Stack direction="row" justifyContent="center">
              <Typography variant={"body2"}>{order?.name}</Typography>
            </Stack>
          </Box>
        </Box>
      </div>
      <CreatePanelModal
        antibodies={lookupCache.antibodies}
        open={isCreatePanelModalVisible}
        onClose={toggleCreatePanelModal}
        onAfterSuccessCreate={handleSuccessOptionCreate(
          "panels",
          REQUESTED_PANELS_FIELD_VALUE
        )}
        teamUUID={order?.team.uuid}
      />
      <CreateAntibodyModal
        open={isCreateAntibodyModalVisible}
        onClose={toggleCreateAntibodyModal}
        onAfterSuccessCreate={handleSuccessOptionCreate(
          "antibodies",
          REQUESTED_ANTIBODIES_FIELD_VALUE
        )}
        teamUUID={order?.team.uuid}
      />
    </>
  );
};
