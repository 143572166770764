import { getFormattedDate } from "components/Projects/helpers/functions";
import { IS_TUMOR_FIELD_NAME } from "components/OrderForm/constants";
import { backendURL } from "services/backendAPI";

const getTumorType = (block) => {
  const displayedTumorType = {
    true: "Positive",
    false: "Negative",
    null: "Unknown",
  };
  const tumorType = block.samples[0]?.[IS_TUMOR_FIELD_NAME];
  return displayedTumorType[tumorType];
};

const orderIDsGetter = (samples) => {
  const orderIDs = samples?.map((s) => s.order_id);
  const uniqueOrderIDs = [...new Set(orderIDs)];

  return uniqueOrderIDs.join(", ");
};

export const getRowData = (blocksData, projects) => {
  return blocksData
    .filter((block) => {
      const order = projects
        ? projects
            .flatMap((project) => project.orders)
            .find((order) =>
              order.name.includes(block.original_sample?.order_id)
            )
        : null;
      return order && order.state !== "cancelled";
    })
    .map((block) => {
      const projectName = projects
        ? projects.find((project) =>
            project.orders.find((order) =>
              order.name.includes(block.original_sample?.order_id)
            )
          )?.name
        : "";
      const orderName = orderIDsGetter(block.samples);
      const slidesCount = block.samples.reduce((acc, sample) => {
        return acc + sample.slides?.length;
      }, 0);

      return {
        order: [projectName, block.name],
        name: block.name,
        uuid: block.uuid,
        sampleUUID: block.original_sample?.uuid,
        atHistowiz: !block.samples[0]?.order.ship_back_samples,
        modified: getFormattedDate(block.modified),
        created: getFormattedDate(block.created),
        species: block.species?.name || "N/A",
        submission: block.submission?.name || "N/A",
        organ: block.organ?.name,
        tumorType: getTumorType(block),
        controlType: block.samples[0]?.control_type || "N/A",
        slidesCount,
        block: { ...block, orderName, projectName },
      };
    });
};

export const getDataPath = (data) => {
  return data.order || null;
};

export const getSelectedSlides = (data, currentSelectedRows) => {
  const blocksWithSlides = data.filter((block) =>
    currentSelectedRows.some((row) =>
      block.samples.some((s) => s.uuid === row.data.sampleUUID)
    )
  );

  const samples = blocksWithSlides.map((block) => block.samples);

  return samples
    .flat()
    .map((sample) => sample.slides)
    .flat()
    .filter((slide) => slide)
    .map((slide) => {
      return {
        ...slide,
        orderName: currentSelectedRows[0]?.data?.order?.[1],
        projectName: currentSelectedRows[0]?.data?.order?.[0],
      };
    });
};

export const getSingleBlocksMenu = ({ row, samplesGridAPI }) => {
  const lisaLink = `${backendURL}/lisa/core/block/${row.id}/change/`;
  const selectedSamplesUUIDs = samplesGridAPI
    .getSelectedRows()
    ?.map((sample) => sample.uuid);

  return [
    {
      name: "Edit Block in LISA",
      action: () => window.open(lisaLink, "_blank"),
    },
    {
      name: "Add selected samples to this block",
      disabled: !selectedSamplesUUIDs.length,
      action: () => {
        console.log(selectedSamplesUUIDs, "merged to block", row.id);
      },
    },
  ];
};

export const getMultiBlocksMenu = ({ mergeBlocks }) => [
  {
    name: "Merge Blocks",
    action: mergeBlocks,
  },
];

export const getSingeSamplesMenu = ({ row }) => {
  const sampleUUID = row.uuid;

  return [
    {
      name: "Create Block from Sample",
      action: () => console.log("Create Block from Sample", sampleUUID),
    },
  ];
};
