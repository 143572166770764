import { makeStyles } from "tss-react/mui";
import { WHITE_SMOKE } from "utilities/colors";

export const textDecorationStyle = { textDecoration: "none", fontSize: "14px" };

export const useConsultationTableRowStyles = makeStyles()((theme) => ({
  progressLink: {
    fontSize: "0.7em",
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: WHITE_SMOKE,
    },
  },
  rowCell: {
    fontSize: "14px",
  },
}));

export const useRenderServicesRequestedStyles = makeStyles()(() => ({
  grid: {
    height: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "transparent",
  },
}));

export const useBaseConsultationsAgGridStyles = makeStyles()(() => ({
  worklistStyle: {
    width: "100%",
  },
}));
