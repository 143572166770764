import { RESET_STORE } from "store/constants";

export const forceStateUpdateOnQueryStarted = async (
  dispatch,
  action,
  data
) => {
  dispatch(action(data));
};

export const defaultActionAsyncHandler = async (
  { dispatch, queryFulfilled, queryArgs },
  action
) => {
  try {
    const res = await queryFulfilled;

    if (res) {
      const actionProps = queryArgs
        ? {
            data: res.data,
            queryArgs,
          }
        : res.data;

      dispatch(action(actionProps));
    }
  } catch (error) {
    console.error(error);
  }
};

export const resetDataMiddleware =
  ({ getState }) =>
  (next) => {
    const initialAppState = getState();

    return (action) => {
      if (action.type === RESET_STORE) {
        const actionWithInitialAppState = {
          ...action,
          payload: initialAppState,
        };

        return next(actionWithInitialAppState);
      }

      return next(action);
    };
  };
