import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import favicon from "images/favicon.png";
import { withStyles } from "tss-react/mui";
import InputBase from "@mui/material/InputBase";
import { ClearRefinements, Stats } from "react-instantsearch-dom";
import { usePathologyMapHeroContainerStyles } from "./styles";

export const PathologyMapHeroContainer = () => {
  const { classes } = usePathologyMapHeroContainerStyles();

  return (
    <Fragment>
      <Box className={classes.heroContainer}>
        <Box mt={0} mb={-1}>
          <Typography align={"center"} variant={"h4"} gutterBottom={false}>
            <img
              className={classes.histowizIcon}
              src={favicon}
              alt={"HistoWiz Icon"}
            />
            PathologyMap
          </Typography>
        </Box>
      </Box>
      <Box className={classes.heroContainer}>
        <Typography align={"center"} variant={"h5"} color={"inherit"}>
          Stop looking for a slide — find it.
        </Typography>
      </Box>
    </Fragment>
  );
};

export const ResultsPerPageInputStyle = withStyles(InputBase, (theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const HistoWizStatsPerSearch = () => {
  return (
    <Fragment>
      <Box display="flex" justifyContent="flex-end" mt={1} mb={0} p={1}>
        <Box>
          <ClearRefinements
            translations={{
              reset: "Clear all filters",
            }}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" p={1}>
        <Box>
          <Stats />
        </Box>
      </Box>
    </Fragment>
  );
};
