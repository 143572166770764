import React from "react";
import { Grid, Typography } from "@mui/material";
import { TextWithLimitedWidth } from "components/SlideGroups/components/TextWithLimitedWidth";
import { useSlideInfoRowStyles } from "components/SlideGroups/utilities/styles";

export const SlideInfoRow = ({ slide, index }) => {
  const { classes } = useSlideInfoRowStyles();

  const formatString = (row) => row || "-";

  return (
    <Grid container className={classes.row}>
      <Grid item width={"3rem"}>
        <Typography fontSize="0.75em" lineHeight={2}>
          {index + 1}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography fontSize="0.75em" lineHeight={2}>
          {`${slide.order.id} / ${slide.name}`}
        </Typography>
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <Typography fontSize="0.75em" lineHeight={2}>
          {formatString(slide.stain_type)}
        </Typography>
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <Typography fontSize="0.75em" lineHeight={2}>
          {formatString(slide.organ?.name)}
        </Typography>
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <Typography fontSize="0.75em" lineHeight={2}>
          {formatString(slide.species?.name)}
        </Typography>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="center">
        <Typography fontSize="0.75em" lineHeight={2}>
          {formatString(slide.antibody?.name)}
        </Typography>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="center">
        <TextWithLimitedWidth
          value={formatString(slide.sample?.science_info?.xenografts?.[0])}
          containerClass={classes.longTextContainer}
          tooltipPlacement={"top"}
        />
      </Grid>
    </Grid>
  );
};
