import React, { forwardRef, useImperativeHandle } from "react";
import { Typography } from "@mui/material";
import "components/OrderForm/SamplesFormV2/styles/css/customTooltip.css";

export const SamplesCustomTooltip = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ["custom-tooltip"];
      },
    };
  });

  if (!props.value) {
    return null;
  }

  const fieldName = props.colDef?.field;

  return (
    <div className="custom-tooltip">
      <Typography variant={"body2"}>
        {fieldName} - {props.value}
      </Typography>
    </div>
  );
});
