import { Button, Grid, Tooltip } from "@mui/material";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";
import { PathologyMapResultsCounter } from "components/PathologyMap/Shared/PathologyMapResultsCounter";
import { PathologyMapSectionHeaderWrapper } from "components/PathologyMap/Shared/PathologyMapSectionHeaderWrapper";
import { HeaderPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/HeaderPathologyMapTypography";
import { usePathologyMapCategoriesHeaderStyles } from "components/PathologyMap/utilities/styles";
import StarIcon from "@mui/icons-material/StarRounded";
import { YELLOW_MARKED } from "components/utilities/constants";
import React from "react";
import { FAVORITE_CATEGORIES_TAB_INDEX } from "components/PathologyMap/utilities/constants";

export const PathologyMapCategoriesHeader = ({
  presetIndex,
  setPresetIndex,
}) => {
  const { items } = useCurrentRefinements();
  const { classes } = usePathologyMapCategoriesHeaderStyles();
  const selectedFiltersCount = items.length;

  return (
    <PathologyMapSectionHeaderWrapper>
      <Grid item>
        <HeaderPathologyMapTypography>CATEGORIES</HeaderPathologyMapTypography>
      </Grid>
      <Grid item>
        <Tooltip title="Favorite categories">
          <Button
            variant="contained"
            color="inherit"
            className={`${classes.categoriesPresetButton} ${
              presetIndex === FAVORITE_CATEGORIES_TAB_INDEX &&
              classes.selectedButton
            }`}
            onClick={() => setPresetIndex(FAVORITE_CATEGORIES_TAB_INDEX)}
          >
            <StarIcon htmlColor={YELLOW_MARKED} />
          </Button>
        </Tooltip>
        <Tooltip title="Standard Metadata">
          <Button
            variant="contained"
            color="inherit"
            className={`${classes.categoriesPresetButton} ${
              presetIndex === 0 && classes.selectedButton
            }`}
            onClick={() => setPresetIndex(0)}
          >
            1
          </Button>
        </Tooltip>
        <Tooltip title="Science Information Metadata">
          <Button
            variant="contained"
            color="inherit"
            className={`${classes.categoriesPresetButton} ${
              presetIndex === 1 && classes.selectedButton
            }`}
            onClick={() => setPresetIndex(1)}
          >
            2
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <PathologyMapResultsCounter count={selectedFiltersCount} />
      </Grid>
    </PathologyMapSectionHeaderWrapper>
  );
};
