import React from "react";
import PolicyIcon from "@mui/icons-material/Policy";
import EditIcon from "@mui/icons-material/Edit";
import BlocksIcon from "@mui/icons-material/AbcRounded";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

import {
  PATHOLOGY_MAP_SEARCH,
  LISA_URL,
  INTERNAL_QC_VIEWER_URL,
  TESTING_IHC_OPTIMIZATION_URL,
  PATHOLOGY_MAP_SEARCH_2,
  BLOCKS_URL,
  BLOCKS_V2_URL,
} from "constants/urls";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import { SidebarListItem } from "components/Layout/Sidebar/SidebarListItem";
import { SidebarList } from "components/Layout/Sidebar/SidebarList";
import { PATHOLOGY_MAP_V2_NAME } from "components/PathologyMap/constants/common";
import { PathologyMapSearchIcon } from "components/PathologyMap/Icons/PathologyMapSearchIcon";

export const AdminList = () => (
  <SidebarList>
    <SidebarListItem
      Icon={ViewInArOutlinedIcon}
      name="Blocks"
      path={BLOCKS_V2_URL}
    />
    <ShowStaffUserOnly>
      <SidebarListItem
        Icon={PathologyMapSearchIcon}
        name={PATHOLOGY_MAP_V2_NAME}
        path={PATHOLOGY_MAP_SEARCH_2}
      />
      <SidebarListItem
        Icon={PolicyIcon}
        name="Pathology Map"
        path={PATHOLOGY_MAP_SEARCH}
      />
      <SidebarListItem
        Icon={EditIcon}
        name="LIS Admin"
        path={LISA_URL}
        isExternal
      />
      <SidebarListItem
        Icon={PlaylistAddCheck}
        name="QC Viewer"
        path={INTERNAL_QC_VIEWER_URL}
      />
      <SidebarListItem
        Icon={FingerprintIcon}
        name="IHC Optimizations"
        path={TESTING_IHC_OPTIMIZATION_URL}
      />
      <SidebarListItem Icon={BlocksIcon} name="Blocks List" path={BLOCKS_URL} />
    </ShowStaffUserOnly>
  </SidebarList>
);
