import React, { useEffect, useState } from "react";
import moment from "moment";

import "ag-grid-enterprise";

import {
  getAntibodies,
  getMultiplexAntibodies,
  getPanels,
  getSpecialStainsIHC,
} from "services/resources/commonResources";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { appURL, backendURL } from "services/backendAPI";
import { Button, Collapse, Stack } from "@mui/material";
import {
  AntibodyColumnDefs,
  ihcAdminColumnDefs,
} from "components/utilities/AgGridCols/AgGridColumns";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PATHOLOGY_MAP_SEARCH_2 } from "constants/urls";
import Grid from "@mui/material/Grid";
import { TabPanel } from "components/Shared/TabPanel";
import { FormInput } from "components/FormInputs/FormInput";
import { SingleAntibodyTable } from "components/AntibodiesList/SingleAntibodyTable";
import { MultiplexAntibodyTable } from "components/AntibodiesList/MultiplexAntibodyTable";
import { IFPanelTable } from "components/AntibodiesList/IFPanelTable";
import {
  MULTIPLEX_ANTIBODY_TABLE_IDX,
  PANELS_TABLE_IDX,
  SINGLE_ANTIBODY_TABLE_IDX,
  SPECIAL_STAINS_TABLE_IDX,
} from "components/AntibodiesList/constants";
import {
  FETCH_ANTIBODIES_ERROR_MESSAGE,
  FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE,
  FETCH_SPECIAL_STAINS_ERROR_MESSAGE,
} from "constants/errorMessages";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { useSelector } from "react-redux";
import { SpecialStainsTable } from "components/AntibodiesList/SpecialStainsTable";
import { DATE_REQUEST_FORMAT } from "utilities/dates_and_times";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useTitle } from "components/utilities/hooks/useTitle";
import { AntibodySummary } from "components/AntibodiesList/AntibodySummary";
import { DARK_GREY } from "utilities/colors";

import { DetailsHeader } from "components/AntibodiesList/DetailsTabHeader";
import { SimpleSlideView } from "components/ImageViewer/SimpleSlideImageView";
import { useRouteMatch } from "react-router-dom";
import { useAntibodiesListStyles } from "./antibodiesListStyles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export const AntibodiesListView = () => {
  const unmountIgnore = useUnmountIgnore();
  const userDetails = useSelector(userDetailsSelector);
  const { showError } = useSnackbar();
  const match = useRouteMatch();
  const [columnDefs, setColumnDefs] = useState(AntibodyColumnDefs);
  /* eslint-disable-next-line no-unused-vars */
  const [currentDataLength, setCurrentDataLength] = useState(0);

  const [antibodiesRowData, setAntibodiesRowData] = useState([]);
  const [isAntibodiesLoading, setIsAntibodiesLoading] = useState(true);
  const [multiplexRowData, setMultiplexRowData] = useState([]);
  const [specialStains, setSpecialStains] = useState([]);
  const [isMultiplexAntibodiesLoading, setIsMultiplexAntibodiesLoading] =
    useState(true);
  const [isSpecialStainsLoading, setIsSpecialStainsLoading] = useState(true);
  const [panelsRowData, setPanelsRowData] = useState([]);
  const [isPanelsLoading, setIsPanelsLoading] = useState(true);
  const [isDetailsCollapsed, setIsDetailsCollapsed] = useState(false);
  const [isDetailsView, setIsDetailsView] = useState(true);

  const [currentTab, setCurrentTab] = useState(SINGLE_ANTIBODY_TABLE_IDX);

  const [selectedAntibody, setSelectedAntibody] = useState(null);

  const [slide, setSlide] = useState();

  const isMainTab = currentTab === SINGLE_ANTIBODY_TABLE_IDX;

  const { classes } = useAntibodiesListStyles({
    isFullTable: !slide && isMainTab,
    isDetailsCollapsed,
  });

  const [quickFilterText, setQuickFilterText] = useState("");

  const [isStaff, setIsStaff] = useState(false);
  const [singleAntibodyGridApi, setSingleAntibodyGridApi] = useState(null);
  const [singleAntibodyDetailGridApi, setSingleAntibodyDetailGridApi] =
    useState(null);

  const title = selectedAntibody
    ? `${selectedAntibody.name} - ${selectedAntibody.catalog_number}`
    : "Antibodies Catalog";

  useTitle(`${title} - HistoWiz`);

  const antibodyUUIDFromUrl = match?.params["antibodyUUID"];

  useEffect(() => {
    if (!antibodiesRowData) return;
    if (antibodyUUIDFromUrl) {
      const antibody = antibodiesRowData.find(
        (antibody) => antibody.uuid === antibodyUUIDFromUrl
      );
      setSelectedAntibody(antibody);
      setSlide(antibody?.validated_slides[0]);
    } else {
      setSelectedAntibody(antibodiesRowData[0]);
      setSlide(antibodiesRowData[0]?.validated_slides[0]);
    }
  }, [antibodyUUIDFromUrl, antibodiesRowData]);

  useEffect(() => {
    if (singleAntibodyGridApi) {
      singleAntibodyGridApi.sizeColumnsToFit();
    }
  }, [singleAntibodyGridApi, isDetailsCollapsed]);

  useEffect(() => {
    getAntibodies()
      .then((response) => {
        if (!unmountIgnore.current) {
          setAntibodiesRowData(response);
        }
      })
      .catch(() => showError(FETCH_ANTIBODIES_ERROR_MESSAGE))
      .finally(() => setIsAntibodiesLoading(false));

    getPanels()
      .then((response) => {
        if (!unmountIgnore.current) {
          setPanelsRowData(response);
        }
      })
      .catch(() => showError(FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE))
      .finally(() => setIsPanelsLoading(false));

    getMultiplexAntibodies()
      .then((response) => {
        if (!unmountIgnore.current) {
          setMultiplexRowData(response);
        }
      })
      .catch(() => showError(FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE))
      .finally(() => setIsMultiplexAntibodiesLoading(false));

    getSpecialStainsIHC()
      .then((response) => {
        if (!unmountIgnore.current) {
          setSpecialStains(response);
        }
      })
      .catch(() => showError(FETCH_SPECIAL_STAINS_ERROR_MESSAGE))
      .finally(() => setIsSpecialStainsLoading(false));
  }, [unmountIgnore]);

  const handleExportToExcel = () => {
    const now = moment().format(DATE_REQUEST_FORMAT);
    const fileName = `Single-Antibodies-Catalog-${now}`;

    const exportParams = {
      columnGroups: true,
      fileName: fileName,
    };
    singleAntibodyGridApi.exportDataAsExcel(exportParams);
  };

  useEffect(() => {
    // ag-grid caches a some functions, which causes an issue because by default for context we say is not a staff
    if (userDetails.isStaff) {
      setColumnDefs(ihcAdminColumnDefs);
      setIsStaff(true);
    }
  }, [userDetails]);

  const getContextMenuItems = (params) => {
    // this function gets cached by ag grid, which is a problem when we actually use
    // react context for is staff and that changes, so here, we pass it into ag-grid as a constantly
    // updating context
    const gridContext = params.context;
    const { isStaff } = gridContext;

    if (!params.node?.data) {
      return;
    }

    const row = params.node.data;

    // if not a staff member, don't show anything
    if (!isStaff) {
      return;
    }

    return getSingleContextMenuItems({ row });
  };

  const getSingleContextMenuItems = ({ row }) => {
    const lisaLink = `${backendURL}/lisa/core/antibody/${row.id}/change/`;

    const antibodyName = row.name.toUpperCase();

    const pathologyMapSearchURL = `${appURL}${PATHOLOGY_MAP_SEARCH_2}?production_pathology_map[refinementList][antibody_name][0]=${antibodyName}`;

    return [
      {
        name: "Edit Antibody in LISA",
        action: () => {
          if (row) {
            window.open(lisaLink, "_blank");
          }
        },
      },
      {
        name: "Find Slides in PathologyMap",
        action: () => {
          if (row) {
            window.open(pathologyMapSearchURL, "_blank");
          }
        },
      },
    ];
  };

  const onQuickFilterText = (event) => {
    setQuickFilterText(event.target.value);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    setSlide(undefined);
    setSingleAntibodyGridApi(undefined);
    setSingleAntibodyGridApi(undefined);
  };

  const setDisplayRowLengthHandler = (event) => {
    setCurrentDataLength(
      event.api.rowModel.rowsToDisplay.filter((element) => !element.detail)
        .length
    );
  };

  const getTabStyle = (tabIndex) => {
    if (tabIndex === currentTab)
      return {
        borderRadius: "6px",
        border: `8px solid #8897F3`,
        borderWidth: "4px",
        color: `${DARK_GREY} !important`,
        fontWeight: "bold",
        backgroundColor: "#EFF4F7FF",
        minHeight: "unset",
        marginLeft: "0.25rem",
        padding: "0.625rem 1rem",
        boxShadow: "0px 4px 4px 0px #00000040",
      };
    return {
      padding: "0.625rem 1.25rem",
      minHeight: "unset",
      marginLeft: "0.25rem",
    };
  };

  const thumbnail = slide?.large_thumbnail_url || "";
  const isFullWidth = (isDetailsCollapsed && isMainTab) || !isMainTab;

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid
          item
          xs={isFullWidth ? 11.8 : 5.8}
          className={classes.gridWrapper}
        >
          <Box mb={1} className={classes.tabs}>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabsChange}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                label="IHC"
                id="ihc-antibodies-tab"
                sx={getTabStyle(SINGLE_ANTIBODY_TABLE_IDX)}
              />
              <Tab
                label="Multiplex"
                id="multiplex-antibodies-tab"
                sx={getTabStyle(MULTIPLEX_ANTIBODY_TABLE_IDX)}
              />
              <Tab
                label="IF Panel"
                id="if-panel-antibodies-tab"
                sx={getTabStyle(PANELS_TABLE_IDX)}
              />
              <Tab
                label="Special Stains"
                id="special-stains-antibodies-tab"
                sx={getTabStyle(SPECIAL_STAINS_TABLE_IDX)}
              />
            </Tabs>
          </Box>
          <Grid item>
            <FormInput onChange={onQuickFilterText} />
            <Box
              data-nosnippet="true"
              className={`ag-theme-balham ${classes.agGrid}`}
            >
              <TabPanel value={currentTab} index={SINGLE_ANTIBODY_TABLE_IDX}>
                <SingleAntibodyTable
                  setDisplayRowData={setDisplayRowLengthHandler}
                  rowData={antibodiesRowData}
                  isLoading={isAntibodiesLoading}
                  quickFilterText={quickFilterText}
                  columnDefs={columnDefs}
                  getContextMenuItems={getContextMenuItems}
                  isStaff={isStaff}
                  setSlide={setSlide}
                  setGridApi={setSingleAntibodyGridApi}
                  gridApi={singleAntibodyGridApi}
                  setDetailGridApi={setSingleAntibodyDetailGridApi}
                  selectedAntibody={selectedAntibody}
                />
                {isStaff && (
                  <Stack direction="row" justifyContent="end">
                    <Button onClick={handleExportToExcel}>
                      Export to Excel
                    </Button>
                  </Stack>
                )}
              </TabPanel>
              <TabPanel value={currentTab} index={MULTIPLEX_ANTIBODY_TABLE_IDX}>
                <MultiplexAntibodyTable
                  setDisplayRowData={setDisplayRowLengthHandler}
                  rowData={multiplexRowData}
                  isLoading={isMultiplexAntibodiesLoading}
                  quickFilterText={quickFilterText}
                  getContextMenuItems={getContextMenuItems}
                  isStaff={isStaff}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={PANELS_TABLE_IDX}>
                <IFPanelTable
                  rowData={panelsRowData}
                  isLoading={isPanelsLoading}
                  quickFilterText={quickFilterText}
                  isStaff={isStaff}
                  setDisplayRowData={setDisplayRowLengthHandler}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={SPECIAL_STAINS_TABLE_IDX}>
                <SpecialStainsTable
                  rowData={specialStains}
                  isLoading={isSpecialStainsLoading}
                  quickFilterText={quickFilterText}
                  setDisplayRowData={setDisplayRowLengthHandler}
                />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
        {isMainTab && (
          <Grid item xs={0.2}>
            <Button
              className={classes.collapseButton}
              onClick={() => setIsDetailsCollapsed(!isDetailsCollapsed)}
            >
              {isDetailsCollapsed ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </Grid>
        )}
        <Grid item xs={6} className={classes.detailsWrapper}>
          {isMainTab && (
            <>
              <Collapse orientation="horizontal" in={!isDetailsCollapsed}>
                <Box className={classes.detailsCollapsibleContainer}>
                  {!isDetailsCollapsed && (
                    <DetailsHeader
                      classes={classes}
                      selectedAntibody={selectedAntibody}
                      setIsDetailsView={setIsDetailsView}
                      isDetailsView={isDetailsView}
                      thumbnail={thumbnail}
                    />
                  )}
                  <Grid
                    item
                    className={`${!isDetailsView && classes.viewerContainer}`}
                  >
                    {selectedAntibody &&
                      (isDetailsView ? (
                        <AntibodySummary
                          selectedAntibody={selectedAntibody}
                          thumbnail={thumbnail}
                          classes={classes}
                        />
                      ) : (
                        <SimpleSlideView
                          slide={slide}
                          id={slide?.uuid}
                          showNavigator={false}
                        />
                      ))}
                  </Grid>
                </Box>
              </Collapse>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
