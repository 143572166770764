import { makeStyles } from "tss-react/mui";

export const useOrderTeamsSelectorStyles = makeStyles()(() => ({
  menuItem: {
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible":
      {
        backgroundColor: "transparent",
      },
    ":hover > p": {
      display: "block",
    },
  },
  onlyText: {
    display: "none",
    fontWeight: "500",
    borderRadius: "16px",
    cursor: "pointer",
    padding: "3px 15px",
    ":hover": {
      backgroundColor: "rgba(178, 81, 176, 0.1)",
    },
  },
}));
