import { BillingContactDisplay } from "components/OrderForm/BillingShipping/BillingShippingComponents";
import { POAttachmentSection } from "components/OrderForm/BillingShipping/BillingShippingInputs";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  billingShippingSelectors,
  setBillingContactSelected,
} from "store/slices/billingShippingSlice/billingShippingSlice";

export const ExistingContactForm = ({ billingContacts }) => {
  const { classes } = useBillingShippingStyles();
  const order = useSelector(billingShippingSelectors.order);
  const billingContactSelected = useSelector(
    billingShippingSelectors.billingContactSelected
  );
  const dispatch = useDispatch();

  return (
    <div className={classes.selectForm}>
      <Select
        options={billingContacts}
        isMulti={false}
        onChange={(value) => dispatch(setBillingContactSelected(value))}
        value={billingContactSelected}
        clearable={false}
        className={classes.selectForm}
      />
      <BillingContactDisplay contact={billingContactSelected} />
      <POAttachmentSection orderUUID={order.uuid} />
    </div>
  );
};
