import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getTeamAddresses } from "services/resources/commonResources";
import { FETCH_TEAM_ADDRESSES_ERROR_MESSAGE } from "constants/errorMessages";
import { setShippingAddresses } from "store/slices/billingShippingSlice/billingShippingSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useUpdateAddresses = (setCurrentTab) => {
  const { showError } = useSnackbar();
  const dispatch = useDispatch();

  const updateAddresses = useCallback(() => {
    getTeamAddresses()
      .then((response) => {
        const responseSerialized = response.map((data) => {
          data["label"] = `${data["name"]} - ${data["street_1"]}`;
          data["value"] = data["uuid"];
          return data;
        });

        if (responseSerialized.length) {
          dispatch(setShippingAddresses(responseSerialized));
          setCurrentTab(1);
        } else {
          dispatch(setShippingAddresses(responseSerialized));
        }
      })
      .catch(() => showError(FETCH_TEAM_ADDRESSES_ERROR_MESSAGE));
  }, []);

  return updateAddresses;
};
