import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styles } from "components/utilities/components/Dropzone/AttachedFiles/styles";

export const AttachedFiles = ({
  title,
  description,
  adornmentRight,
  children,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={styles.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.cell}>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2"> {description}</Typography>
            </TableCell>
            {adornmentRight && (
              <TableCell align="right">{adornmentRight}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
