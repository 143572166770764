import { CustomSlidesListView } from "components/OrderSlidesList/CustomSlidesListView/CustomSlidesListView";
import { SuccessSignupPage } from "components/pages/SuccessSignupPage";
import {
  BLOCKS_URL,
  BLOCKS_V2_URL,
  COMPLETED_ORDER_REVIEW_FEEDBACK_URL,
  CONTACT_URL,
  DEMO_LOGIN_URL,
  DEMO_LOGIN_URL_2,
  DROPBOX_REQUEST_URL,
  DROPBOXES_LIST_URL,
  EMPLOYEE_NOMINATION_URL,
  GLP_FORM_URL,
  IF_VIEWER_URL,
  INTERNAL_QC_VIEWER_URL,
  LOGIN_TOKEN,
  LOGIN_URL,
  LOGOUT_URL,
  MULTI_SLIDE_VIEWER_PARAMS_URL,
  MULTI_SLIDE_VIEWER_URL,
  ORDER_CHART_URL,
  ORDER_DETAILS_URL,
  ORDER_SLIDE_COMMENTS_URL,
  ORDERS_IHC_OPTIMIZATIONS_URL,
  ORDERS_LIST_URL,
  ORDERS_SLIDE_LIST_URL,
  PATHOLOGIST_WORKLIST_URL,
  PATHOLOGY_CONSULTATION_INFO_VIEW_URL,
  PATHOLOGY_MAP_SEARCH,
  PATHOLOGY_MAP_SEARCH_2,
  PLACE_ORDER_BILLING_STEP_URL,
  PLACE_ORDER_INFO_URL,
  PLACE_ORDER_INITIAL_URL,
  PLACE_ORDER_INITIAL_URL_V2,
  PLACE_ORDER_PRICE_REVIEW_STEP_URL,
  PLACE_ORDER_REVIEW_FEEDBACK_URL,
  PLACE_ORDER_REVIEW_STEP_URL,
  PLACE_ORDER_REVIEW_STEP_URL_V2,
  PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL,
  PLACE_ORDER_SAMPLES_STEP_URL,
  PLACE_ORDER_SLIDES_STEP_URL,
  PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM,
  PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL,
  PROJECTS_URL,
  PROJECT_SLIDES_URL,
  REFERRALS_URL,
  RESET_PASSWORD_CONFIRM_URL,
  RESET_PASSWORD_URL,
  SHARE_SLIDE_URL,
  SHARED_IF_VIEWER_URL,
  SHARED_ORDER_SLIDE_LIST_URL,
  SHARED_SOCIAL_IF_ORDER_URL,
  SHARED_SOCIAL_ORDER_URL,
  SIGNUP_URL,
  SLIDE_GROUPS_ROUTE,
  SLIDE_GROUPS_URL,
  SLIDE_NAVIGATOR_URL,
  SLIDE_VIEWER_DEBUG_URL,
  SLIDE_VIEWER_URL,
  SLIDES_LIST_URL,
  SLIDES_LIST_URL_2,
  SUCCESS_SIGNUP_URL,
  TEAM_BLOCKS_URL,
  TEAM_USER_INVITE_URL,
  USER_DETAILS_URL,
  TEAM_OVERVIEW_URL,
  CUSTOM_SLIDE_LIST_ROUTE,
  ORDER_BARCODES,
  SAMPLE_EDITOR_URL,
  SHARED_GROUP_SLIDE_LIST_URL,
  SAMPLES_CHANGELOG_URL,
} from "constants/urls";
import { SignupPage } from "components/pages/SignupPage";
import { LoginPage } from "components/Login/LoginPage";
import { LogoutPage } from "components/pages/LogoutPage";
import { MultiSlidePage } from "components/pages/DemoSlideViewerPage";
import { LoginTokenPage } from "components/Login/LoginTokenPage";
import { ResetPasswordPage } from "components/ResetPassword/ResetPasswordPage";
import { ResetPasswordConfirmPage } from "components/ResetPassword/ResetPasswordConfirmPage";
import { EmployeeNominationForm } from "components/TypeForms/Nomination2020";
import { DropboxTypeform } from "components/TypeForms/DropboxTypeform";

import { OrderDetailsPage } from "components/OrderDetails/OrderDetailsPage";
import { ContactPage } from "components/Contact/ContactPage";
import { OrderSlidesListView2 } from "components/OrderSlidesList/OrderSlidesListViewV2";
import { DropboxPage } from "components/Dropboxes/DropboxPage";
import { PlaceOrderInfoView } from "components/OrderForm/Info/InfoView";
import {
  PlaceOrderBillingView,
  PlaceOrderPriceReviewView,
  PlaceOrderReviewView,
  PlaceOrderReviewViewV2,
} from "components/pages/PlaceNewOrderPage";
import { SlidesListPage } from "components/SlidesList/SlidesListPage";
import { OrderFormFeedbackPage } from "components/OrderSlidesList/OrderFormFeedbackPage";
import { QCViewer } from "components/QCViewer/QCViewer";
import { SampleScienceInfoV2 } from "components/OrderFormV2/SampleScienceInfoV2/SampleScienceInfoV2";
import { SlideNavigatorPage } from "components/SlideNavigator/SlideNavigator";
import { ReferralPage } from "components/Referrals/referrals";
import { MultiSlideParamsView } from "components/ImageViewer/MultiSlideParamsView";
import { TeamUserInvitePage } from "components/TeamUserInvites/TeamUserInviteView";
import { IHCOptimizationView } from "components/IHCOptimizationViewer/IHCOptimizationView";
import { AllSlidesCommentsPage } from "components/AllSlidesComments/AllSlidesCommentsPage";
import { PlaceOrderSlidesView } from "components/OrderForm/SlideServicesForm/SlideServicesPage";
import { IFViewer } from "components/IFViewer/IFViewer";
import { CompletedOrderFeedbackPage } from "components/OrderSlidesList/CompletedOrderFeedbackPage";
import { SlideImagePage } from "components/pages/SlideImagePage";
import { OrderSlidesChartPage } from "components/OrderSlidesChart/OrderSlidesChartPage";
import { SlideImageDebugPage } from "components/pages/SlideImageDebugPage";
import { OrdersListPage } from "components/OrdersList/OrdersListPage";
import { EditUserPage } from "components/EditUserDetails/EditUserDetailsPage";
import { PathologyConsultationOrderFormPage } from "components/PathologyConsultations/OrderForm/PathologyConsultationOrderFormPage";
import { PathologistWorklist } from "components/PathologyConsultations/PathologistWorklist";
import { PathologyConsultationInfoPage } from "components/PathologyConsultations/PathologyConsultationInfoPage";
import { ProjectsOverview } from "components/Projects/ProjectsOverview";
import { GlobalPathologyMapPage } from "components/PathologyMap/pages/GlobalPathologyMapPage";
import { UserSlidesPathologyMapPage } from "components/PathologyMap/pages/UserSlidesPathologyMapPage";
import { PathologyMapSearch } from "components/PathologyMap/PathologyMapDeprecated/PathologyMapSearch";
import { GlpForm } from "components/GlpForm/GlpForm";
import {
  BlocksPage,
  TeamBlocksPage,
} from "components/BlocksList/TeamBlocksPage";
import { SlideGroupsListPage } from "components/SlideGroups/SlideGroupsListPage";
import { TeamOverviewPage } from "components/UserProfile/TeamOverviewPage";
import { SharedSocialSlide } from "components/pages/SharedSocialSlide";
import { BlocksHomePage } from "components/pages/BlocksHomePage";
import { OrderBarcodesView } from "components/OrderBarcodes/OrderBarcodesView";
import { SampleEditor } from "components/SampleEditor/SampleEditor";
import { SampleChangeLog } from "components/SampleEditor/components/SamplesChangelog/SampleChangeLog";
import { SamplesPageWrapper } from "components/OrderForm/SamplesPageWrapper/SamplesPageWrapper";

export const BasicRouteDetails = {
  [SIGNUP_URL]: SignupPage,
  [SUCCESS_SIGNUP_URL]: SuccessSignupPage,
  [LOGIN_URL]: LoginPage,
  [DEMO_LOGIN_URL]: (params) => LoginPage({ ...params, isDemo: true }),
  [DEMO_LOGIN_URL_2]: (params) => LoginPage({ ...params, isDemo: true }),
  [LOGOUT_URL]: LogoutPage,
  [MULTI_SLIDE_VIEWER_URL]: MultiSlidePage,
  [PATHOLOGY_MAP_SEARCH]: PathologyMapSearch,
  [PATHOLOGY_MAP_SEARCH_2]: GlobalPathologyMapPage,
  [LOGIN_TOKEN]: LoginTokenPage,
  [RESET_PASSWORD_URL]: ResetPasswordPage,
  [RESET_PASSWORD_CONFIRM_URL]: ResetPasswordConfirmPage,
  [EMPLOYEE_NOMINATION_URL]: EmployeeNominationForm,
  [DROPBOX_REQUEST_URL]: DropboxTypeform,
  [SHARED_SOCIAL_ORDER_URL]: SharedSocialSlide,
  [SHARED_SOCIAL_IF_ORDER_URL]: SharedSocialSlide,
};

export const AuthenticatedRouteDetails = {
  [USER_DETAILS_URL]: EditUserPage,
  [ORDERS_LIST_URL]: OrdersListPage,
  [ORDER_BARCODES]: OrderBarcodesView,
  [PROJECTS_URL]: ProjectsOverview,
  [TEAM_OVERVIEW_URL]: TeamOverviewPage,
  [PROJECT_SLIDES_URL]: OrderSlidesListView2,
  [ORDER_DETAILS_URL]: OrderDetailsPage,
  [CONTACT_URL]: ContactPage,
  [ORDERS_SLIDE_LIST_URL]: OrderSlidesListView2,
  [SLIDE_GROUPS_ROUTE]: OrderSlidesListView2,
  [SHARED_ORDER_SLIDE_LIST_URL]: OrderSlidesListView2,
  [SHARED_GROUP_SLIDE_LIST_URL]: OrderSlidesListView2,
  [DROPBOXES_LIST_URL]: DropboxPage,
  [PLACE_ORDER_INITIAL_URL]: PlaceOrderInfoView,
  [PLACE_ORDER_INITIAL_URL_V2]: PlaceOrderInfoView,
  [PLACE_ORDER_INFO_URL]: PlaceOrderInfoView,
  [PLACE_ORDER_SAMPLES_STEP_URL]: SamplesPageWrapper,
  [ORDERS_IHC_OPTIMIZATIONS_URL]: IHCOptimizationView,
  [PLACE_ORDER_SLIDES_STEP_URL]: PlaceOrderSlidesView,
  [SLIDE_GROUPS_URL]: SlideGroupsListPage,
  [PLACE_ORDER_PRICE_REVIEW_STEP_URL]: PlaceOrderPriceReviewView,
  [SLIDES_LIST_URL]: SlidesListPage,
  [SLIDES_LIST_URL_2]: UserSlidesPathologyMapPage,
  [ORDER_SLIDE_COMMENTS_URL]: AllSlidesCommentsPage,
  [PLACE_ORDER_BILLING_STEP_URL]: PlaceOrderBillingView,
  [PLACE_ORDER_REVIEW_STEP_URL_V2]: PlaceOrderReviewViewV2,
  [PLACE_ORDER_REVIEW_STEP_URL]: PlaceOrderReviewView,
  [PLACE_ORDER_REVIEW_FEEDBACK_URL]: OrderFormFeedbackPage,
  [COMPLETED_ORDER_REVIEW_FEEDBACK_URL]: CompletedOrderFeedbackPage,
  [INTERNAL_QC_VIEWER_URL]: QCViewer,
  [SLIDE_VIEWER_URL]: SlideImagePage,
  [SLIDE_VIEWER_DEBUG_URL]: SlideImageDebugPage,
  [SHARE_SLIDE_URL]: SlideImagePage,
  [PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL]: SampleScienceInfoV2,
  [SLIDE_NAVIGATOR_URL]: SlideNavigatorPage,
  [REFERRALS_URL]: ReferralPage,
  [MULTI_SLIDE_VIEWER_PARAMS_URL]: MultiSlideParamsView,
  [TEAM_USER_INVITE_URL]: TeamUserInvitePage,
  [IF_VIEWER_URL]: IFViewer,
  [SHARED_IF_VIEWER_URL]: IFViewer,
  [ORDER_CHART_URL]: OrderSlidesChartPage,
  [PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM]: PathologyConsultationOrderFormPage,
  [PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL]:
    PathologyConsultationOrderFormPage,
  [PATHOLOGY_CONSULTATION_INFO_VIEW_URL]: PathologyConsultationInfoPage,
  [PATHOLOGIST_WORKLIST_URL]: PathologistWorklist,
  [BLOCKS_V2_URL]: BlocksHomePage,
  [BLOCKS_URL]: BlocksPage,
  [TEAM_BLOCKS_URL]: TeamBlocksPage,
  [GLP_FORM_URL]: GlpForm,
  [CUSTOM_SLIDE_LIST_ROUTE]: CustomSlidesListView,
  [SAMPLE_EDITOR_URL]: SampleEditor,
  [SAMPLES_CHANGELOG_URL]: SampleChangeLog,
  "/default": OrdersListPage,
};

export const AuthenticatedRouteDetailsKeys = Object.keys(
  AuthenticatedRouteDetails
);
export const BasicRouteDetailsKeys = Object.keys(BasicRouteDetails);
