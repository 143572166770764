import React, { useState } from "react";
import { DemoUserWarning } from "components/OrderForm/components/DemoUserOrderWarning";
import {
  CuttingInstructionsModal,
  PathologyMapHelperModal,
} from "components/OrderForm/Info/InfoPageComponents";
import { PlaceOrderBaseView } from "components/OrderForm/WizardNavigator/WizardNavigatorPage";
import { useTitle } from "components/utilities/hooks/useTitle";
import { OrderInfoForm } from "components/OrderForm/Info/OrderFormContainer/OrderFormContainer";
import { useBuiltOrderDetails } from "components/OrderForm/Info/hooks/useBuiltOrderDetails";
import { useOrderFixatives } from "components/OrderForm/Info/hooks/useOrderFixatives";
import { useSerializedProjects } from "components/OrderForm/Info/hooks/useSerializedProjects";
import { useSerializedSpecialInstructions } from "components/OrderForm/Info/hooks/useSerializedSpecialInstructions";
import { useSerializedSpecies } from "components/OrderForm/Info/hooks/useSerializedSpecies";
import { useSerializedTeams } from "components/OrderForm/Info/hooks/useSerializedTeams";
import { useIsDemoUser } from "components/utilities/hooks/useIsDemoUser";
import {
  SHOW_CUTTING_INSTRUCTIONS_MODAL,
  SHOW_PATHOLOGY_MAP_MODAL,
} from "components/OrderForm/Info/constants";

export const InfoView = () => {
  const isDemoUser = useIsDemoUser();
  const { team, selectableTeams } = useSerializedTeams();
  const { selectableSpecies } = useSerializedSpecies();
  const { selectableProjectOptions } = useSerializedProjects();
  const { selectableOrderFixativeOptions, updateOrderFixatives } =
    useOrderFixatives();
  const { selectableSpecialInstructionsAttachments } =
    useSerializedSpecialInstructions();
  const { ready, orderDetails } = useBuiltOrderDetails();

  const [showCuttingInstructionsModal, setShowCuttingInstructionsModal] =
    useState(false);

  const [showPathologyMapModal, setShowPathologyMapModal] = useState(false);

  const handleModalChange = (name) => () => {
    const mapModalToSetter = {
      [SHOW_CUTTING_INSTRUCTIONS_MODAL]: setShowCuttingInstructionsModal,
      [SHOW_PATHOLOGY_MAP_MODAL]: setShowPathologyMapModal,
    };

    const setter = mapModalToSetter[name];
    setter((prevValue) => !prevValue);
  };

  return (
    ready &&
    !!team && (
      <>
        {isDemoUser && <DemoUserWarning />}
        <OrderInfoForm
          team={team}
          // pass in options that are used for select
          orderDetails={orderDetails}
          selectableSpecies={selectableSpecies}
          selectableOrderFixativeOptions={selectableOrderFixativeOptions}
          selectableProjectOptions={selectableProjectOptions}
          selectableSpecialInstructionsAttachments={
            selectableSpecialInstructionsAttachments
          }
          selectableTeams={selectableTeams}
          handleModalChange={handleModalChange}
          updateOrderFixatives={updateOrderFixatives}
        />
        <CuttingInstructionsModal
          open={showCuttingInstructionsModal}
          onClose={handleModalChange(SHOW_CUTTING_INSTRUCTIONS_MODAL)}
        />
        <PathologyMapHelperModal
          open={showPathologyMapModal}
          onClose={handleModalChange(SHOW_PATHOLOGY_MAP_MODAL)}
        />
      </>
    )
  );
};

export const PlaceOrderInfoView = () => {
  useTitle("Place Order - Info");

  return (
    <PlaceOrderBaseView label="Place An Order">
      <InfoView />
    </PlaceOrderBaseView>
  );
};
