import * as Yup from "yup";
import { REQUESTED_SERVICES_FIELD_NAME } from "components/OrderForm/constants";
import { SAMPLES_NAME_COLUMN_MAX_LIMIT } from "components/OrderForm/SamplesFormV2/constants";

export const yupSamplesValidation = Yup.object({
  name: Yup.string()
    .required("Sample Name Is Required.")
    .max(
      SAMPLES_NAME_COLUMN_MAX_LIMIT,
      `Sample Name Cannot Be More Than ${SAMPLES_NAME_COLUMN_MAX_LIMIT} Characters.`
    ),
  submission: Yup.string().required("Submitted In Types Are Required."),
  [REQUESTED_SERVICES_FIELD_NAME]: Yup.string().required(
    "Required Services Are Required."
  ),
  organ: Yup.string().required("A Sample Organ Is Required."),
  control_type: Yup.string().required("A Control Type Selection Is Required."),
});

export const samplesListValidation = Yup.array().of(yupSamplesValidation);
