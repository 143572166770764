import { SortLabel } from "components/SlideGroups/Modals/components/SortLabel";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSlideInfoRowStyles } from "components/SlideGroups/utilities/styles";
import { handleSortClick } from "components/SlideGroups/utilities/utilities";
import {
  ANTIBODIES_FIELD,
  ORDER_NAME_SORT_FIELD,
  ORGAN_SORT_FIELD,
  SPECIES_FIELD,
  STAIN_TYPE_SORT_FIELD,
  XENOGRAFTS_FIELD,
} from "components/SlideGroups/utilities/constants";

export const SlidesSort = ({ sort, setSort }) => {
  const { classes } = useSlideInfoRowStyles();

  return (
    <Grid container className={classes.row}>
      <Grid item width={"3rem"}>
        <Typography fontSize="0.75em" lineHeight={2}>
          #
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <SortLabel
          isActive={sort.field === ORDER_NAME_SORT_FIELD}
          label={"ORDER / SLIDE NAME"}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, ORDER_NAME_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <SortLabel
          isActive={sort.field === STAIN_TYPE_SORT_FIELD}
          label={"STAIN TYPE"}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, STAIN_TYPE_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <SortLabel
          isActive={sort.field === ORGAN_SORT_FIELD}
          label={"ORGAN"}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, ORGAN_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <SortLabel
          isActive={sort.field === SPECIES_FIELD}
          label={"SPECIES"}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, SPECIES_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="center">
        <SortLabel
          isActive={sort.field === ANTIBODIES_FIELD}
          label={"ANTIBODIES"}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, ANTIBODIES_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="center">
        <SortLabel
          isActive={sort.field === XENOGRAFTS_FIELD}
          label={"XENOGRAFTS"}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, XENOGRAFTS_FIELD)}
          classes={classes}
        />
      </Grid>
    </Grid>
  );
};
