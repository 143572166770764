import { userDetailsReducer } from "store/slices/userDetailsSlice";
import { slidesReducer } from "store/slices/slidesSlice";
import { ifSlidesReducer } from "store/slices/ifSlidesSlice";
import { slideGroupsReducer } from "store/slices/slideGroupsSlice";
import { pathologyMapReducer } from "store/slices/pathologyMapSlice";
import { billingShippingReducer } from "store/slices/billingShippingSlice/billingShippingSlice";
import { slideCommentsReducer } from "./slices/slideCommentsSlice";
import { teamsReducer } from "store/slices/teamsSlice";
import { slidesListReducer } from "store/slices/slidesListSlice/slidesListSlice";
import { aiToolsReducer } from "store/slices/aiToolsSlice";
import { annotationsReducer } from "store/slices/annotationsSlice";

export const rootReducer = {
  annotations: annotationsReducer,
  userDetails: userDetailsReducer,
  slideGroups: slideGroupsReducer,
  slides: slidesReducer,
  ifSlides: ifSlidesReducer,
  pathologyMap: pathologyMapReducer,
  billingShipping: billingShippingReducer,
  slideComments: slideCommentsReducer,
  teams: teamsReducer,
  slidesList: slidesListReducer,
  aiTools: aiToolsReducer,
};
