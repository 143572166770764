import { Button } from "@mui/material";
import { useClearAllAlgoliaFilters } from "components/PathologyMap/utilities/hooks";
import {
  PATHOLOGY_MAP_BOLD_FONT_WEIGHT,
  PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_TYPOGRAPHY_COLOR,
} from "components/PathologyMap/constants/styles";
import { DefaultPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography";
import { usePathologyMapClearActiveFiltersButtonStyles } from "./styles";

export const PathologyMapClearActiveFiltersButton = ({ disabled }) => {
  const clearAllFilters = useClearAllAlgoliaFilters();
  const { classes } = usePathologyMapClearActiveFiltersButtonStyles();

  const handleClearRefinements = () => {
    clearAllFilters();
  };

  return (
    <Button
      className={classes.clearRefinementsButton}
      onClick={handleClearRefinements}
      disabled={disabled}
    >
      <DefaultPathologyMapTypography
        fontWeight={PATHOLOGY_MAP_BOLD_FONT_WEIGHT}
        color={PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_TYPOGRAPHY_COLOR}
        textTransform="uppercase"
      >
        Clear All Filters
      </DefaultPathologyMapTypography>
    </Button>
  );
};
