import { memo, useMemo } from "react";
import { Button } from "@mui/material";

import { RIGHT_VIEW_LAYER } from "components/IFViewer/constants";
import {
  FilterCenterFocus,
  Fullscreen,
  FullscreenExit,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";

export const ControlButtons = memo(
  ({
    useLinkedView,
    pan,
    isFullscreen,
    classes,
    handleZoomIn,
    handleZoomOut,
    setCurrentZoomLevel,
    fullScreenHandle,
    viewerRef,
  }) => {
    const CONTROLS = useMemo(
      () => [
        {
          id: "ifViewerZoomIn",
          name: "Zoom in",
          icon: <ZoomIn />,
          onClick: (pan) => handleZoomIn(pan),
        },
        {
          id: "ifViewerZoomOut",
          name: "Zoom out",
          icon: <ZoomOut />,
          onClick: (pan) => handleZoomOut(pan),
        },
        isFullscreen
          ? {
              id: "toggleIfFullscreen",
              name: "Exit full screen",
              icon: <FullscreenExit />,
              onClick: () => fullScreenHandle.exit(),
            }
          : {
              id: "toggleIfFullscreen",
              name: "Full screen",
              icon: <Fullscreen />,
              onClick: () => fullScreenHandle.enter(),
            },
        {
          id: "resetIfViewer",
          name: "Set to default",
          icon: <FilterCenterFocus />,
          onClick: (pan) => {
            setCurrentZoomLevel(null);
            viewerRef.current.resetSlide(pan);
          },
        },
      ],
      [viewerRef, isFullscreen, handleZoomIn, handleZoomOut, fullScreenHandle]
    );

    let className = classes.controlButtonsContainer;
    if (pan === RIGHT_VIEW_LAYER) {
      if (isFullscreen) {
        className += ` ${classes.controlButtonsContainerRightFullscreen}`;
      } else if (useLinkedView) {
        className += ` ${classes.controlButtonsContainerRight}`;
      }
    }

    return (
      <div className={className}>
        {CONTROLS.map((control) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a title={control.name} key={control.name}>
            <Button
              id={control.id}
              key={control.name}
              variant="outlined"
              color="primary"
              className={classes.controlButton}
              onClick={() => control.onClick(useLinkedView && pan)}
            >
              {control.icon}
            </Button>
          </a>
        ))}
      </div>
    );
  }
);
