import { useNotifyOrderCommentsModalStyles } from "components/Modals/IHCOptimization/styles";
import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const OptimizationDilutionOrderCommentModule = ({ orderComment }) => {
  const { classes } = useNotifyOrderCommentsModalStyles();

  return (
    <Fragment>
      <Box mb={2}>
        <Typography
          variant="h6"
          className={classes.reviewText}
          gutterBottom={false}
          color={"primary"}
        >
          Optimization / Dilution:{" "}
        </Typography>
        <Typography>{orderComment.slides[0]?.optimization_dilution}</Typography>

        <Box height={"10px"} />

        <Typography
          color={"primary"}
          variant="h6"
          className={classes.reviewText}
          gutterBottom={false}
        >
          Comment:
        </Typography>
        <Typography>{orderComment.text}</Typography>
      </Box>
    </Fragment>
  );
};
