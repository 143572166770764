import { array, object, string } from "yup";

export const stringRequirements = string().trim();
export const pathologyConsultationOrderSchema = object().shape({
  goal_description: stringRequirements.required("A description is required."),
  turnaround_days: object().required("Please select a turnaround time."),
  specialities: array().required("Required!"),
  desired_pathology_expertise: string()
    .trim()
    .min(1, "Required")
    .required("Required!"),
  species_text: string().trim().min(1, "Required").required("Required!"),
  type_of_analysis_scoring_required: string()
    .trim()
    .min(1, "Required")
    .required("Required!"),
  anticipated_findings: string()
    .trim()
    .min(1, "Required")
    .required("Required!"),
  control_animal: string().trim(),
  reference_papers: string().trim(),
  requested_pathologist: string().trim(),
  special_instructions: string().trim(),
});

export const pathologyConsultationGLPSchema = object().shape({
  goal_description: stringRequirements.required("A description is required."),
});
