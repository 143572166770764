import Typography from "@mui/material/Typography";
import React from "react";
import { useOrderSlidesViewStyles } from "components/OrderForm/SlideServicesForm/SlideServicesViewStyles";
import Box from "@mui/material/Box";
import { exportToExcelAction } from "components/utilities/grid";
import { ExportToExcelButton } from "components/OrderForm/Buttons/ExportToExcelButton";

export const AdditionalSlideServicesFootnote = () => {
  const { classes } = useOrderSlidesViewStyles();

  return (
    <Typography className={classes.info}>
      <span>
        Note: There is a 4 slide per stain minimum requirement for IHC and
        special stain services.
      </span>
      <span>
        If Image Analysis and Pathology Consultation services were requested,
        additional columns will appear on this page.
      </span>
    </Typography>
  );
};
export const SlideServicesExportToExcel = ({ gridApi, isLoading, order }) => {
  const { classes } = useOrderSlidesViewStyles();
  const exportFileName = `${order?.name} - Slide Services`;

  return (
    <Box mb={1}>
      <div className={classes.downloadButton}>
        <ExportToExcelButton
          isLoading={isLoading}
          onClick={() => exportToExcelAction(gridApi, exportFileName)}
        />
      </div>
    </Box>
  );
};
