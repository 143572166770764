import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const UserEditFormField = (props) => {
  const { fieldLabel, children, mr } = props;

  return (
    <Fragment>
      <Box mt={3} mr={mr}>
        <Typography variant="subtitle1" color="textPrimary">
          {fieldLabel}
        </Typography>
        <Box mt={1}>{children}</Box>
      </Box>
    </Fragment>
  );
};
