import { Grid, Input } from "@mui/material";
import { useAlgoliaIndex } from "components/PathologyMap/utilities/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  currentRefinementTypeSelector,
  selectCurrentFiltersSearchQuery,
  setCurrentFacetSearchFilters,
  setCurrentFiltersSearchQuery,
} from "store/slices/pathologyMapSlice";
import { useEffect } from "react";
import { SEARCH_FILTERS_PLACEHOLDER } from "components/PathologyMap/constants/common";
import { ALGOLIA_FACETS_SEARCH_ERROR_MESSAGE } from "constants/errorMessages";
import { searchForAlgoliaFacets } from "components/PathologyMap/utilities/services";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";

import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { usePathologyMapFilterPropertiesSearchStyles } from "./styles";

export const PathologyMapFilterPropertiesSearch = () => {
  const { classes } = usePathologyMapFilterPropertiesSearchStyles();
  const dispatch = useDispatch();
  const searchValue = useSelector(selectCurrentFiltersSearchQuery);
  const { showError } = useSnackbar();
  const algoliaIndex = useAlgoliaIndex();
  const currentRefinement = useSelector(currentRefinementTypeSelector);
  const { items: currentRefinements } = useCurrentRefinements();

  useEffect(() => {
    dispatch(setCurrentFacetSearchFilters([]));
  }, [currentRefinement]);

  const handleInputChange = (e) => {
    dispatch(setCurrentFiltersSearchQuery(e.target.value));
  };

  useEffect(() => {
    searchForAlgoliaFacets({
      algoliaIndex,
      searchValue,
      currentRefinement,
      currentRefinements,
    })
      ?.then((res) => {
        res && dispatch(setCurrentFacetSearchFilters(res.facetHits));
      })
      .catch(() => showError(ALGOLIA_FACETS_SEARCH_ERROR_MESSAGE));
  }, [searchValue, searchForAlgoliaFacets]);

  return (
    <Grid item>
      <Input
        fullWidth
        value={searchValue}
        disableUnderline
        className={classes.searchInput}
        placeholder={SEARCH_FILTERS_PLACEHOLDER}
        onChange={handleInputChange}
      />
    </Grid>
  );
};
