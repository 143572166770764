import { ColumnHeader } from "components/SampleEditor/components/ColumnHeader";
import { SAMPLE_FIELDS } from "./constants";

export const buildSamplesState = (samples) => {
  const sortedByProjects = Object.values(
    samples.reduce((acc, item) => {
      const projectId = item.project_uuid;
      const isFirstInProject = !acc[projectId]?.length;

      return {
        ...acc,
        [projectId]: [...(acc[projectId] ?? []), { ...item, isFirstInProject }],
      };
    }, {})
  );

  const sortedByOrders = sortedByProjects.map((projectSamples) =>
    Object.values(
      projectSamples.reduce((acc, item) => {
        const orderId = item.order_id;
        const isFirstInOrder = !acc[orderId]?.length;

        return {
          ...acc,
          [orderId]: [...(acc[orderId] ?? []), { ...item, isFirstInOrder }],
        };
      }, {})
    ).flat()
  );

  return sortedByOrders.flat();
};

export const convertSampleObjectToUpdate = (initialItem, updatedSample) => {
  const updatedScienceInfo = {
    ...initialItem.science_info,
    [`${SAMPLE_FIELDS.sex}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.sex]
    ),
    [`${SAMPLE_FIELDS.speciesStrain}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.speciesStrain]
    ),
    [`${SAMPLE_FIELDS.genotype}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.genotype]
    ),
    [`${SAMPLE_FIELDS.originHost}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.originHost]
    ),
    [`${SAMPLE_FIELDS.age}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.age]
    ),
    [`${SAMPLE_FIELDS.engineeredModel}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.engineeredModel]
    ),
    [`${SAMPLE_FIELDS.technique}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.technique]
    ),
    [`${SAMPLE_FIELDS.typeOfImplantation}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.typeOfImplantation]
    ),
    [`${SAMPLE_FIELDS.expression}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.expression]
    ),
    [`${SAMPLE_FIELDS.drugStudyName}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.drugStudyName]
    ),
    [`${SAMPLE_FIELDS.drugDosingSchedule}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.drugDosingSchedule]
    ),
    [`${SAMPLE_FIELDS.drugDuration}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.drugDuration]
    ),
    [`${SAMPLE_FIELDS.drugDosage}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.drugDosage]
    ),
    [`${SAMPLE_FIELDS.drugAdministration}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.drugAdministration]
    ),
    [`${SAMPLE_FIELDS.outcomeExamination}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.outcomeExamination]
    ),
    [`${SAMPLE_FIELDS.survivalStudy}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.survivalStudy]
    ),
    [`${SAMPLE_FIELDS.instanceId}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.instanceId]
    ),
    [`${SAMPLE_FIELDS.accessioningId}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.accessioningId]
    ),
    [`${SAMPLE_FIELDS.experimentId}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.experimentId]
    ),
    [`${SAMPLE_FIELDS.passageId}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.passageId]
    ),
    [`${SAMPLE_FIELDS.animalId}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.animalId]
    ),
    [`${SAMPLE_FIELDS.surgeryType}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.surgeryType]
    ),
    [`${SAMPLE_FIELDS.notes}`]: formatEmptyValue(
      updatedSample.science_info?.[SAMPLE_FIELDS.notes]
    ),
  };

  return {
    ...initialItem,
    associated_slides: updatedSample["associated_slies"],
    alias: updatedSample.alias,
    organ: updatedSample.organ,
    species: updatedSample.species,
    science_info: {
      ...initialItem.science_info,
      ...updatedScienceInfo,
    },
  };
};

export const formatEmptyValue = (value) => {
  return value || "";
};

export const formatDisplayEmptyValue = (value, customSign = "-") => {
  return value || customSign;
};

export const formatHeaderTitle = (value) =>
  value
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

export const getKeyByValue = (value) =>
  Object.keys(SAMPLE_FIELDS).find((k) => SAMPLE_FIELDS[k] === value);

export const getScienceInfoColumn = (column, gridApi, setColumns) => ({
  headerName: column,
  field: `science_info.${column}`,
  headerComponent: () => (
    <ColumnHeader
      removable
      column={column}
      gridApi={gridApi}
      setColumns={setColumns}
      name={formatHeaderTitle(column)}
    />
  ),
  minWidth: 200,
  resizable: true,
  editable: true,
  cellRenderer: ({ data }) => (
    <span>{formatDisplayEmptyValue(data.science_info?.[column])}</span>
  ),
});
