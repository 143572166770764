import { useSlideImagePageStyles } from "components/pages/styles";
import React, { useEffect } from "react";
import { SlideImageView } from "components/ImageViewer/SlideImageView";
import {
  clearSlidesList,
  setIsSharedPage,
  setSlidesListOrder,
} from "store/slices/slidesListSlice/slidesListSlice";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { useRouteMatch } from "react-router-dom";
import { setSlides, slidesSelector } from "store/slices/slidesSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSlideViewerPageTitle } from "components/OrderSlidesList/utilities";
import { useTitle } from "components/utilities/hooks/useTitle";
import { FETCH_SLIDE_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import {
  useLazyGetSharedSlideQuery,
  useLazyGetSlideQuery,
} from "store/apis/slidesApi";

export const SlideImagePage = () => {
  const match = useRouteMatch();
  const slides = useSelector(slidesSelector);
  const dispatch = useDispatch();
  const { classes } = useSlideImagePageStyles();
  const slide = slides[0];
  const { showError } = useSnackbar();

  let { slideUUID, shareSlideUUID } = match.params;
  slideUUID = slideUUID ?? shareSlideUUID;

  const [getSlide] = useLazyGetSlideQuery();
  const [getSharedSlide] = useLazyGetSharedSlideQuery();

  const pageTitle = getSlideViewerPageTitle(slide);
  useTitle(pageTitle);

  useEffect(() => {
    let isMounted = true;

    if (!slideUUID) {
      return;
    }

    dispatch(setIsSharedPage(!!shareSlideUUID));

    const slideGetter = shareSlideUUID
      ? getSharedSlide({ shareUUID: slideUUID }).unwrap()
      : getSlide({ slideUUID }).unwrap();

    slideGetter
      .then((fetchedSlide) => {
        if (!isMounted) {
          return;
        }

        dispatch(setSlides([fetchedSlide]));
        dispatch(setSlidesListOrder(fetchedSlide.order));
      })
      .catch(() => showError(FETCH_SLIDE_ERROR_MESSAGE));

    return () => {
      dispatch(clearSlidesList());
      isMounted = false;
    };
  }, [slideUUID]);

  return (
    <MiniDrawerWithContext header={"Slide Viewer"} withoutBackground>
      <div className={classes.viewer}>
        {slide && <SlideImageView slide={slide} />}
      </div>
    </MiniDrawerWithContext>
  );
};
