import { makeStyles } from "tss-react/mui";

export const useSlideNameStyles = makeStyles()(() => ({
  slideName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  slideNameWrap: {
    display: "flex",
    padding: "0.5rem 0",
    width: "100%",
  },
}));
