import React, { Fragment } from "react";
import { Button, Typography } from "@mui/material";
import { DefaultModal } from "components/Modals/DefaultModal";
import { serializeSpecialInstructions } from "components/utilities/gridDataSerializers";
import { useAttachmentStyles } from "./styles";

export const Attachment = ({ file }) => {
  const { classes } = useAttachmentStyles();
  if (!file) return null;

  return (
    <Fragment>
      <Typography variant="body1" className={classes.attachedFileName}>
        File: {file.file_name}
      </Typography>
      <a
        href={file.url}
        download={file.file_name}
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="contained" color="primary">
          View Attached File
        </Button>
      </a>
    </Fragment>
  );
};

export const ViewAttachmentsModal = ({ open, onClose, order }) => {
  const { classes } = useAttachmentStyles();

  if (!order?.special_instructions_attachment && !order?.special_instructions)
    return null;

  const specialInstructions = serializeSpecialInstructions(order);

  const instructionText = order.special_instructions;
  const attachmentFile = order.special_instructions_attachment;

  return (
    <DefaultModal open={open} onClose={onClose}>
      <div className={classes.modalContainer}>
        <Typography variant="h5">
          Special Instructions for {order?.name}
        </Typography>
        {instructionText || attachmentFile ? (
          <>
            {specialInstructions.map((specialInstruction) => (
              <div key={specialInstruction.field}>
                <b className={classes.specialInstructionLabel}>
                  {specialInstruction.label}:
                </b>
                {specialInstruction.field ===
                "special_instructions_attachment" ? (
                  <Attachment file={attachmentFile} />
                ) : (
                  <Typography
                    variant="body1"
                    className={classes.attachedFileName}
                  >
                    {specialInstruction.text}
                  </Typography>
                )}
              </div>
            ))}
          </>
        ) : (
          <p>No attachments</p>
        )}
      </div>
    </DefaultModal>
  );
};
