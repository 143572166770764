import { SnackbarActionCloseButton } from "components/Shared/Buttons/SnackbarActionCloseButton";

export const DEFAULT_WARNING_MESSAGE = "Warning!";

export const DEFAULT_SUCCESS_MESSAGE = "Request was executed successfully";

export const DEFAULT_ERROR_MESSAGE =
  "An error occurred during request executing";

export const DEFAULT_EXTRA_MESSAGE =
  "Please contact us at 1-833-PATHLAB or email us at getsupport@histowiz.com";

export const DEFAULT_SNACKBAR_OPTIONS = {
  action: (key) => <SnackbarActionCloseButton snackbarKey={key} />,
  style: { whiteSpace: "pre-line" },
  autoHideDuration: 3000,
};
