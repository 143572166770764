import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
} from "@mui/material";

export const AntibodySummary = ({ selectedAntibody, thumbnail, classes }) => {
  const {
    clone_number,
    clonality,
    host_species,
    histowiz_validated_species,
    reactive_species,
    applications,
    display_name,
    description,
    alternate_catalog_names: altNames,
  } = selectedAntibody;

  const {
    rowStyle,
    cellStyle,
    thumbnailStyle,
    ABSummaryStyle,
    centered,
    ABDescription,
    ABDescriptionText,
  } = classes;

  const stringifyNames = (arr) => arr.map(({ name }) => name).join(", ");

  return (
    <>
      <Box className={ABSummaryStyle}>
        <Table>
          <TableBody>
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>Clone Number:</TableCell>
              <TableCell className={cellStyle}>
                {clone_number || "N/A"}
              </TableCell>
            </TableRow>
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>Clonality:</TableCell>
              <TableCell className={cellStyle}>{clonality}</TableCell>
            </TableRow>
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>Host Species:</TableCell>
              <TableCell className={cellStyle}>{host_species?.name}</TableCell>
            </TableRow>
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>
                Histowiz Validated Species:
              </TableCell>
              <TableCell className={cellStyle}>
                {stringifyNames(histowiz_validated_species)}
              </TableCell>
            </TableRow>
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>
                Vendor Reactive Species:
              </TableCell>
              <TableCell className={cellStyle}>
                {stringifyNames(reactive_species)}
              </TableCell>
            </TableRow>
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>Application:</TableCell>
              <TableCell className={cellStyle}>
                {stringifyNames(applications)}
              </TableCell>
            </TableRow>
            {!!altNames?.length && (
              <TableRow className={rowStyle}>
                <TableCell className={cellStyle}>Alternate Names:</TableCell>
                <TableCell className={cellStyle}>
                  {altNames.join(", ")}
                </TableCell>
              </TableRow>
            )}
            {description && (
              <TableRow className={rowStyle}>
                <TableCell className={`${cellStyle} ${ABDescription}`}>
                  Description:
                </TableCell>
                <TableCell className={`${cellStyle} ${ABDescriptionText}`}>
                  {description}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {thumbnail && (
          <div className={centered}>
            <Typography mt={1}>{display_name}</Typography>
            <img
              className={thumbnailStyle}
              src={thumbnail}
              alt={display_name}
            />
          </div>
        )}
      </Box>
    </>
  );
};
