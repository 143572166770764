import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import {
  actionTabs,
  META_TAB,
  TABS_EXCLUDED_FOR_MULTISELECT,
} from "components/OrderSlidesList/SlideActionTabs/constants";
import { SlideActionTabs } from "components/OrderSlidesList/SlideActionTabs/SlideActionTabs";
import { useStyles } from "components/OrderSlidesList/SlideActionTabs/ActionPanelsContainer/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isExpandedSlideListSelector,
  selectedTabBottomSelector,
  selectedTabTopSelector,
  setSelectedTabBottom,
  setSelectedTabTop,
} from "store/slices/slidesListSlice/slidesListSlice";

export const ActionPanelsContainer = ({
  slidesViewerProps,
  slidesTableProps,
  slideCommentsProps,
  slidesGroupProps,
  isSingleViewer,
  currentSlide,
  annotationsLegend,
  isMultipleSelected,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const selectedTabTop = useSelector(selectedTabTopSelector);
  const selectedTabBottom = useSelector(selectedTabBottomSelector);
  const isExpanded = useSelector(isExpandedSlideListSelector);
  const [isActionsHidden, setIsActionsHidden] = useState(false);

  const isExpandedSlideList =
    isExpanded &&
    (selectedTabTop === actionTabs.slidesList ||
      selectedTabBottom === actionTabs.slidesList);
  const hideTop = isExpanded && selectedTabBottom === actionTabs.slidesList;
  const hideBottom = isExpanded && selectedTabTop === actionTabs.slidesList;

  useEffect(() => {
    if (slidesTableProps) {
      dispatch(setSelectedTabTop(actionTabs.slidesList));
    }
  }, []);

  useEffect(() => {
    if (!isMultipleSelected) return;

    if (TABS_EXCLUDED_FOR_MULTISELECT.includes(selectedTabTop)) {
      dispatch(setSelectedTabTop(actionTabs.comments));
    }
    if (TABS_EXCLUDED_FOR_MULTISELECT.includes(selectedTabBottom)) {
      dispatch(setSelectedTabBottom(actionTabs.comments));
    }
  }, [isMultipleSelected]);

  return (
    <>
      <Button
        className={classes.collapseButton}
        onClick={() => setIsActionsHidden(!isActionsHidden)}
      >
        {isActionsHidden ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </Button>
      <Collapse
        orientation="horizontal"
        in={!isActionsHidden}
        collapsedSize={30}
      >
        <Box
          className={`${classes.actionsContainer} ${
            isExpandedSlideList && "slideList"
          }`}
        >
          {!hideTop && (
            <SlideActionTabs
              slidesViewerProps={slidesViewerProps}
              slidesCommentsProps={slideCommentsProps}
              slidesTableProps={slidesTableProps}
              slidesGroupProps={slidesGroupProps}
              currentSlide={currentSlide}
              activeTab={selectedTabTop}
              setActiveTab={(tab) => dispatch(setSelectedTabTop(tab))}
              disabledTabs={[selectedTabBottom]}
              isSingleViewerMode={isSingleViewer}
              annotationsLegend={annotationsLegend}
              isMultipleSelected={isMultipleSelected}
            />
          )}
          {!hideBottom && (
            <SlideActionTabs
              slidesViewerProps={slidesViewerProps}
              slidesCommentsProps={slideCommentsProps}
              slidesTableProps={slidesTableProps}
              slidesGroupProps={slidesGroupProps}
              currentSlide={currentSlide}
              activeTab={selectedTabBottom}
              disabledTabs={[selectedTabTop]}
              setActiveTab={(tab) => dispatch(setSelectedTabBottom(tab))}
              isSingleViewerMode={isSingleViewer}
              annotationsLegend={annotationsLegend}
              isMultipleSelected={isMultipleSelected}
            />
          )}
        </Box>
      </Collapse>
    </>
  );
};
