import { showActionsValidStates } from "components/PathologyConsultations/constants";
import { checkPathologistCanStartConsultation } from "components/PathologyConsultations/AgGridColumnsUtilities";

export const canRenderReviewRequest = ({ consultation }) => {
  const notAccepted = !consultation?.pathologists_accepted_datetime;
  if (showActionsValidStates.includes(consultation.state) && notAccepted) {
    return true;
  } else {
    return false;
  }
};

export const canRenderStartRequest = ({ consultation }) => {
  const readyToStart = checkPathologistCanStartConsultation(consultation);
  if (consultation.state === "pathologist_accepted" && readyToStart) {
    return true;
  }
  return false;
};

export const canRenderPreviousRequest = ({ consultation }) => {
  if (!consultation.pathologists_accepted_datetime) {
    return false;
  }
  return true;
};

export const canRenderFinishRequest = ({ consultation }) => {
  if (consultation.state === "started") {
    return true;
  }
  return false;
};
