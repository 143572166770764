import React, { useEffect, useState } from "react";
import { getTeamAddresses } from "services/resources/commonResources";
import Select from "react-select";

import { TeamAddressForm } from "components/Shared/TeamAddress/TeamAddressFormV1";
import { FETCH_TEAM_ADDRESSES_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { UserEditFormField } from "components/EditUserDetails/UserEditFormField";

export const EditUserTeamAddresses = () => {
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  const { showError } = useSnackbar();

  useEffect(() => {
    getTeamAddresses()
      .then((addresses) => setShippingAddresses(addresses))
      .catch(() => showError(FETCH_TEAM_ADDRESSES_ERROR_MESSAGE));
  }, []);

  const handleSelectChange = (value) => {
    setSelectedAddress(value);
  };

  return (
    <>
      <UserEditFormField fieldLabel="Edit Address">
        <Select
          options={shippingAddresses}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          placeholder="Select team address..."
          onChange={handleSelectChange}
        />
      </UserEditFormField>
      {!!selectedAddress && (
        <TeamAddressForm
          address={selectedAddress}
          submitButtonText="Update Address"
        />
      )}
    </>
  );
};
