import {
  LEFT_VIEW_LAYER,
  RIGHT_VIEW_LAYER,
  ZOOM_LEVELS,
} from "components/IFViewer/constants";
import { Button } from "@mui/material";
import React, { memo } from "react";

export const ZoomButtons = memo(
  ({
    useLinkedView,
    pan,
    isFullscreen,
    classes,
    zoomLeft,
    zoomRight,
    currentZoomLevel,
    setPan,
    updateZoomLevel,
  }) => {
    const containerClassName = isFullscreen
      ? classes.zoomContainerRightFullscreen
      : useLinkedView && pan === RIGHT_VIEW_LAYER
      ? classes.zoomContainerRight
      : classes.zoomContainer;

    return (
      <div className={containerClassName}>
        {ZOOM_LEVELS.map((zoomLevel) => {
          const isSelected = useLinkedView
            ? (pan === LEFT_VIEW_LAYER && zoomLevel === zoomLeft) ||
              (pan === RIGHT_VIEW_LAYER && zoomLevel === zoomRight)
            : zoomLevel === currentZoomLevel;

          const className = isSelected
            ? `${classes.zoomButton} ${classes.zoomSelected}`
            : classes.zoomButton;

          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a title={`Zoom to ${zoomLevel}x`} key={zoomLevel}>
              <Button
                id={`zoomToFixed-${zoomLevel}`}
                variant="outlined"
                color="primary"
                className={className}
                onClick={() => {
                  setPan(pan);
                  updateZoomLevel(pan, zoomLevel);
                }}
              >
                {`${zoomLevel}x`}
              </Button>
            </a>
          );
        })}
      </div>
    );
  }
);
