export const styles = (theme) => ({
  button: {
    margin: 3,
    textTransform: "uppercase",
  },
  saveButton: {
    marginTop: "1rem",
    marginLeft: "0.5rem",
    float: "right",
    textTransform: "uppercase",
  },
  tutorialButton: {
    marginBottom: "1rem",
    float: "top",
    textAlign: "right",
    textTransform: "uppercase",
    // @TODO: Show tutorial button when demo will be ready
    visibility: "hidden",
  },
  progress: {
    margin: ".5rem",
  },
  rightAlign: {
    float: "right",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  leftAlign: {
    marginTop: "1rem",
    marginBottom: ".25rem",
    float: "left",
  },
  gridTable: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "4rem",
  },
  toolbar: {
    float: "left",
  },
  info: {
    fontSize: "small",
    float: "left",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    width: "100%",
    fontSize: "small",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    bottom: "2rem",
  },
  link: {
    color: theme.primary,
    "&:hover": {
      cursor: "pointer",
    },
  },
  grid: {
    height: "450px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "transparent",
  },
});

export const editedInputStyle = {
  width: "90%",
  height: "25px",
  border: "none",
  outline: "none",
};

export const centeredCellStyle = { textAlign: "center" };
