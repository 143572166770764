export const getNormalizedSlideFileName = ({ name, order: { id } }) =>
  name
    .replace(id, "")
    .replace(/\.(svs|tiff|scn)/g, "")
    .replaceAll("$", " ")
    .trim();

export const getSlideChangesDate = (slide) =>
  new Date(slide.failed_qc_datetime ?? slide.passed_qc_datetime);

export const getLastChangedSlide = (a, b) =>
  getSlideChangesDate(a) > getSlideChangesDate(b) ? a : b;

export const getClearedSlideName = (slide) => {
  const { name, order_id: orderID } = slide;

  return name
    .replace(/\$/g, "_")
    .replace(new RegExp(`^${orderID}_`), "")
    .replace(/\.(svs|tiff|scn)/g, "");
};

export const isGLPSlide = (slide) => slide?.order?.is_glp_order;

export const isAnySlideFromGLPOrder = (slides) => {
  return slides.some(isGLPSlide);
};
