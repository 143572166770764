import { makeStyles } from "tss-react/mui";
import {
  DARK_GREY_HOVER,
  HISTOWIZ_PRIMARY_COLOR,
  MEDIUM_GREY,
  WHITE,
} from "utilities/colors";

export const ModalStyles = {
  padding: 0,
  width: 626,
  outline: "none",
};

export const useSavedInfoModalStyles = makeStyles()(() => ({
  header: {
    background: HISTOWIZ_PRIMARY_COLOR,
    color: WHITE,
    fontSize: 24,
    lineHeight: "24px",
    padding: "1.25rem 0",
    textAlign: "center",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);",
    textTransform: "uppercase",
  },
  body: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  content: {
    padding: "1.8rem 0 0.6rem",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.25rem",
  },
  decline: {
    background: MEDIUM_GREY,
    color: WHITE,

    "&:hover": {
      background: DARK_GREY_HOVER,
    },
  },
}));
