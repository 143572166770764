import { SnackbarWithContext } from "components/Modals/DefaultSnackbar";
import React from "react";

export const CopiedSnackbar = () => {
  return (
    <SnackbarWithContext
      snackbarString={"snackbarCopied"}
      message={"Copied!"}
    />
  );
};
