import { makeStyles } from "tss-react/mui";
import {
  QC_AI_MODELS,
  TISSUE_AI_MODELS,
} from "components/AITools/constants/common";

const QC_CANVAS_COLORS = {
  PASSED: "rgba(0, 255, 0, 1)",
  FAILED: "rgba(255, 0, 0, 1)",
  INCONCLUSIVE: "rgba(255, 255, 0, 1)",
};

export const MAP_AI_MODEL_TO_DISPLAY_COLOR = {
  [TISSUE_AI_MODELS.ML_TISSUE_DATA]: "rgba(0, 0, 255, 0.35)",
  [TISSUE_AI_MODELS.ML_TUMOR_DATA]: "rgba(255, 0, 0, 0.35)",
  [TISSUE_AI_MODELS.ML_STROMA_DATA]: "rgba(152, 245, 90, 0.35)",
  [TISSUE_AI_MODELS.ML_INFLAMMATION_DATA]: "rgba(245, 90, 201, 0.35)",
  [TISSUE_AI_MODELS.ML_NECROSIS_DATA]: "rgba(255, 213, 0, 0.35)",
  [QC_AI_MODELS.ML_QC_BLURRY_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_TISSUE_SEPARATION_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_BLADE_MARKS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_COVERSLIP_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_CUT_DEEPER_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_DIRTY_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_FLOATERS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_SCANNER_ARTIFACTS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_FOLDS_TEARS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_STAIN_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_MICROVIBRATIONS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_TISSUE_CRACKING_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
};

export const useSelectableItemStyles = makeStyles()(() => ({
  selectableItem: {
    color: "#757575",
    fontFamily: "Courier",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}));

export const useContentStyles = makeStyles()(() => ({
  mlModelsHeader: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: "0.9rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "0.7rem",
  },
  percentageSliderWrapper: {
    maxWidth: "50% !important",
    paddingTop: "0.25rem !important",
  },
  aiModelsListWrapper: {
    paddingTop: 0,
  },
}));

export const AI_PROCESSING_ACTION_MENU_STYLE = {
  padding: 0,
};

export const getColorIndicatorStyle = (color) => ({
  width: "0.6rem",
  height: "0.6rem",
  marginRight: "0.5rem",
  backgroundColor: color,
  display: "inline-block",
});

export const SELECTED_AI_MODEL_ITEM_BG_COLOR = "rgba(66, 148, 213, 0.20)";

export const getSelectedAIModelItemContainerStyle = (isDisabled, isLoading) => {
  const isClickable = isDisabled || isLoading;
  return {
    cursor: isClickable ? "default" : "pointer",
  };
};

export const getSelectedAIModelItemContainerBGColor = (
  isSelected,
  isDisabled,
  isLoading
) => {
  const isSelectable = isSelected && !(isLoading || isDisabled);
  return isSelectable ? SELECTED_AI_MODEL_ITEM_BG_COLOR : "white";
};

export const getSelectedAIModelCheckIconStyle = (isSelected, isDisabled) => {
  const isSelectable = isSelected && !isDisabled;

  return {
    visibility: isSelectable ? "visible" : "hidden",
  };
};

export const DISABLED_AI_MODEL_ITEM_BG_COLOR = "rgba(117, 117, 117, 0.60)";

export const getSelectedAIModelTextStyle = (isDisabled) => {
  return isDisabled ? { color: DISABLED_AI_MODEL_ITEM_BG_COLOR } : {};
};
