import { Grid, Typography } from "@mui/material";
import { useTeamOverviewCommentsStyles } from "components/UserProfile/styles";
import { Link } from "react-router-dom";
import { TextWithLimitedWidth } from "components/SlideGroups/components/TextWithLimitedWidth";
import React from "react";
import {
  buildOrderCommentLabel,
  buildSlideCommentLabel,
  buildSubmittedUserLabel,
} from "components/UserProfile/utilities";
import Box from "@mui/material/Box";
import { SLIDE_COMMENT_TYPE } from "components/UserProfile/constants";
import { SLIDE_VIEWER_URL } from "constants/urls";

export const CommentInfo = ({ comment, classes }) => {
  const { classes: commentsClasses } = useTeamOverviewCommentsStyles();
  const submittedUserLabel = buildSubmittedUserLabel(
    comment.user,
    comment.created
  );

  const commentLabel =
    comment.instance_type === SLIDE_COMMENT_TYPE
      ? buildSlideCommentLabel(comment)
      : buildOrderCommentLabel(comment);

  const slide = comment.slide;

  const slideViewerURL = SLIDE_VIEWER_URL.replace(":slideUUID", slide.uuid);

  return (
    <Grid
      container
      className={`${classes.slideDataContainer} ${classes.slideInfoRow}`}
    >
      <Grid item xs={3} xl={2} display="flex" justifyContent="center">
        <Link to={slideViewerURL} target={"_blank"} className={classes.link}>
          <img
            className={commentsClasses.image}
            src={slide.medium_thumbnail_url}
            alt={"thumbnail"}
          />
        </Link>
      </Grid>
      <Grid item xs={9} xl={10}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography>{commentLabel}</Typography>
          <Typography textAlign="center">{submittedUserLabel}</Typography>
        </Box>
        <Grid item xs={12}>
          <TextWithLimitedWidth
            value={comment.text}
            containerClass={classes.commentContainer}
            textClass={classes.commentText}
            tooltipPlacement={"bottom"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
