import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDefaultDialogStyles } from "./styles";

export const DefaultDialog = ({
  open,
  onClose,
  children,
  actions,
  title,
  ...props
}) => {
  const { classes } = useDefaultDialogStyles();

  return (
    <Dialog
      className={classes.modalPaper}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      {...props}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!!actions && (
        <DialogActions className={classes.dialogActions}>
          {actions()}
        </DialogActions>
      )}
    </Dialog>
  );
};
