import { useSnackbar as useNotistackSnackbar } from "notistack";

import {
  DEFAULT_SNACKBAR_OPTIONS,
  DEFAULT_WARNING_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_EXTRA_MESSAGE,
} from "utilities/hooks/useSnackbar/constants";

export const getSnackbarOptions = (variant = "success", options = {}) => ({
  ...DEFAULT_SNACKBAR_OPTIONS,
  ...options,
  variant,
});

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const showWarning = (
    message = DEFAULT_WARNING_MESSAGE,
    extraMessage = "",
    options
  ) =>
    enqueueSnackbar(
      `${message}\n${extraMessage}`,
      getSnackbarOptions("warning", options)
    );

  const showInfo = (message = DEFAULT_WARNING_MESSAGE, options) =>
    enqueueSnackbar(message, getSnackbarOptions("info", options));

  const showSuccess = (message = DEFAULT_SUCCESS_MESSAGE, options) =>
    enqueueSnackbar(message, getSnackbarOptions("success", options));

  const showError = (
    message = DEFAULT_ERROR_MESSAGE,
    extraMessage = DEFAULT_EXTRA_MESSAGE,
    options
  ) =>
    enqueueSnackbar(
      `${message}\n${extraMessage}`,
      getSnackbarOptions("error", options)
    );

  return { showWarning, showSuccess, showError, showInfo, closeSnackbar };
};
