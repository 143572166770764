import React from "react";
import { AccordionWithComments } from "components/ImageViewer/components/AccordionWithComments";
import { Typography } from "@mui/material";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { useMultiSlidesComments } from "components/ImageViewer/hooks";
import { useMultiSlidesCommentsStyles } from "components/ImageViewer/styles";

export const MultiSlidesComments = React.memo(({ slides, isSharedPage }) => {
  const { classes } = useMultiSlidesCommentsStyles();

  const {
    slidesCommentsGroups,
    isCommentsLoading,
    handleCreateComment,
    handleDeleteComment,
  } = useMultiSlidesComments(slides, isSharedPage);

  return (
    <div className={classes.commentsContainer}>
      {slides.length ? (
        slides.map((slide) => {
          const commentsGroup = slidesCommentsGroups.get(slide);
          return (
            <AccordionWithComments
              key={slide.uuid}
              slide={slide}
              handleDeleteComment={handleDeleteComment}
              handleCreateComment={handleCreateComment}
              slideCommentsGroup={commentsGroup}
            />
          );
        })
      ) : !isCommentsLoading ? (
        <Typography variant="subtitle1" className={classes.noCommentsLabel}>
          No slide comments
        </Typography>
      ) : null}
      {isCommentsLoading ? (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      ) : null}
    </div>
  );
});
