import React from "react";
import { Box, Typography } from "@mui/material";
import { useSlideInfoForPathologyMapStyles } from "../../PathologyMap/constants/styles";
import { useOrdersTabContentStyles } from "components/UserProfile/styles";
import { CommentInfo } from "components/UserProfile/LatestCommentsCard/CommentInfo";
import { Card } from "components/common/Card/Card";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { CardHeader } from "components/UserProfile/components/CardHeader";

export const LatestCommentsCard = ({ newsFeed = {} }) => {
  const { classes: classesFromPathologyMap } =
    useSlideInfoForPathologyMapStyles();
  const { classes: orderTabStyles } = useOrdersTabContentStyles();
  const classes = { ...classesFromPathologyMap, ...orderTabStyles };

  //TODO: Uncomment in v2
  // const [currentTabValue, setCurrentTabValue] = useState(
  //   SLIDE_COMMENTS_TAB_VALUE
  // );
  // const comments = useMemo(
  //   () => newsFeed[currentTabValue],
  //   [newsFeed, currentTabValue]
  // );
  const comments = newsFeed.slide_comments;

  const renderComments = () =>
    comments?.length ? (
      comments.map((comment) => (
        <CommentInfo key={comment.uuid} comment={comment} classes={classes} />
      ))
    ) : (
      <Typography marginY="auto">No comments</Typography>
    );

  return (
    <Card
      headerContent={
        <CardHeader title="LATEST COMMENTS" subtitle="TEAM COMMUNICATION" />
      }
      bodyContent={
        <Box className={classes.ordersList}>
          {newsFeed ? (
            renderComments()
          ) : (
            <Box className={classes.spinnerContainer}>
              <Spinner />
            </Box>
          )}
        </Box>
      }
    />
  );
};
