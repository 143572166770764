import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import {
  extendArrayIfConditionOrValueExists,
  toTitleCase,
} from "utilities/general";
import { SHIPPING_OPTIONS_DETAILS } from "components/OrderForm/constants";
import { formatDatetimeStringToDates } from "utilities/dates_and_times";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { PLACE_ORDER_REVIEW_FEEDBACK_URL } from "constants/urls";
import { isIAOrPCOrder } from "utilities/orders";
import { UpdatePOModal } from "components/Modals/UpdatePOModal/UpdatePOModal";

const getNamesFromArray = (array) => {
  return array.map((data) => {
    return data.name;
  });
};

const getAntibodyDisplayNameFromAPIResults = (array) => {
  return array.map((data) => {
    return data.antibody?.display_name;
  });
};

export const CustomTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderColor: theme.palette.primary.light,
    border: "2px",
  },
  body: {
    fontSize: 14,
  },
}));

const AlternativeTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    borderColor: theme.palette.secondary.light,
  },
  body: {
    fontSize: 14,
  },
}));

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflowX: "auto",
    // use this to disable showing of scrollbar
    overflowY: "hidden",
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderColor: theme.palette.primary.light,
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
  },
  alternativeHeader: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    borderColor: theme.palette.secondary.light,
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  expansionDetails: {
    padding: 0,
  },
  editIcon: {
    marginLeft: ".5rem",
  },
  editButtonWrapper: {
    display: "inline-block",
  },
  tooltip: {
    zIndex: -1,
  },
  summaryTitle: {
    fontSize: "1.125rem",
  },
  summaryValue: {
    fontSize: "1rem",
  },
});

const _SummaryTable = ({
  classes,
  data,
  label,
  alternative,
  hidden,
  isEditable,
  order,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const TableCellRender = alternative ? CustomTableCell : AlternativeTableCell;
  const headerStyle = alternative ? classes.header : classes.alternativeHeader;

  const defaultExpanded = !hidden;

  const toggleEditModal = () => {
    setIsEditModalOpen((prev) => !prev);
  };

  const handleEditAction = () => {
    setIsEditModalOpen(true);
  };

  const handleWrapperClick = (e) => {
    e.stopPropagation();
    setIsTooltipOpen(false);
  };

  const renderEditBillingButton = (order) => (
    <Tooltip title="Update PO" arrow open={isTooltipOpen}>
      <div onClick={handleWrapperClick} className={classes.editButtonWrapper}>
        <EditIcon
          className={classes.editIcon}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          onClick={handleEditAction}
        />
        <UpdatePOModal
          open={isEditModalOpen}
          onClose={toggleEditModal}
          fullOrder={order}
        />
      </div>
    </Tooltip>
  );

  return (
    <Paper className={classes.root}>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          className={headerStyle}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            className={classes.summaryTitle}
            variant="h5"
            color={"inherit"}
          >
            {label}
            {isEditable && renderEditBillingButton(order)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          <Table className={classes.table}>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow className={classes.row} key={row.name}>
                    <TableCellRender component="th" scope="row">
                      <Typography
                        className={classes.summaryValue}
                        variant={"h5"}
                      >
                        {row.name}
                      </Typography>
                    </TableCellRender>
                    <TableCellRender align={"right"}>
                      {row.url ? (
                        <Typography
                          className={classes.summaryValue}
                          variant={"h5"}
                        >
                          <a
                            href={row.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.value}
                          </a>
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.summaryValue}
                          variant={"h5"}
                        >
                          {row.value}
                        </Typography>
                      )}
                    </TableCellRender>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export const SummaryTable = withStyles(_SummaryTable, styles);

export const OrderSummaryTable = ({ order, alternative }) => {
  const pathologyMapConsent = toTitleCase(
    order.pathology_map_consent.toString()
  );

  const teamMembers = order.team.members.map((member) => {
    return member.email;
  });
  const teamMembersString = teamMembers.join(", ");

  const teamMembersWithPhone = order.team.members.filter((member) => {
    return member.phone_number;
  });
  const phoneNumbers = teamMembersWithPhone.map((member) => {
    return `${member.phone_number} (${member.first_name})`;
  });
  const phoneNumbersString = phoneNumbers.join(", ");

  const isNewLabelled = toTitleCase(order.team.is_new.toString());

  let accountRep = "";
  if (order.team.account_rep) {
    accountRep = order.team.account_rep.email;
  }

  let submittedUser = "";
  if (order.submitted_user) {
    submittedUser = order.submitted_user.email;
  }

  const orderID = order.name.match(/\d+/)?.[0];
  const url = PLACE_ORDER_REVIEW_FEEDBACK_URL.replace(":orderID", orderID);

  const orderRequestedIAOrPC = isIAOrPCOrder(order);

  const linkField = {
    name: "Order Feedback",
    value: "Link",
    url: url,
  };

  const defaultFields = [
    { name: "Order Name", value: order.name },
    { name: "Project", value: order.project.name },
    { name: "Phone Number", value: phoneNumbersString },
    { name: "Team", value: order.team.name },
    { name: "Is New", value: isNewLabelled },
    { name: "Account Rep", value: accountRep },
    { name: "Team Members", value: teamMembersString },
    { name: "Organization", value: order.organization.name },
    { name: "Turnaround Time", value: order.turnaround_days },
    { name: "State", value: order.state_label },
    {
      name: "Order Contributes to Pathology Map",
      value: pathologyMapConsent,
    },
    {
      name: "Submitted User",
      value: submittedUser,
    },
    {
      name: "Notes",
      value: order.external_notes,
    },
    {
      name: "Turnaround Start Time",
      value: formatDatetimeStringToDates(order.turnaround_start_datetime),
    },
    {
      name: "Turnaround Due Time",
      value: formatDatetimeStringToDates(order.turnaround_due_datetime),
    },
    {
      name: "Turnaround Ended Time",
      value: formatDatetimeStringToDates(order.turnaround_ended_datetime),
    },
  ];

  if (orderID) {
    defaultFields.splice(1, 0, linkField);
  }

  const additionalFields = [
    {
      name: "Turnaround IA/PC Time",
      value: order.turnaround_image_analysis_pathology_consultation_days,
      options: { index: 10, condition: orderRequestedIAOrPC },
    },
    {
      name: "Turnaround IA/PC Start Time",
      value: formatDatetimeStringToDates(order.turnaround_ia_pc_start_datetime),
      options: { condition: orderRequestedIAOrPC },
    },
    {
      name: "Turnaround IA/PC Due Time",
      value: formatDatetimeStringToDates(order.turnaround_ia_pc_due_datetime),
      options: { condition: orderRequestedIAOrPC },
    },
    {
      name: "Turnaround IA/PC Ended Time",
      value: formatDatetimeStringToDates(order.turnaround_ia_pc_ended_datetime),
      options: { condition: orderRequestedIAOrPC },
    },
    {
      name: "Special Instructions",
      value: order.special_instructions,
    },
    {
      name: "Special Instructions Attachment",
      value: order.special_instructions_attachment?.file_name,
      url: order.special_instructions_attachment?.url,
    },
    {
      name: "Uploaded Samples Science Attachment",
      value: "Download",
      url: order.uploaded_samples_science_info,
      options: { condition: !!order.uploaded_samples_science_info },
    },
  ];

  const data = extendArrayIfConditionOrValueExists(
    defaultFields,
    additionalFields
  );

  return (
    <SummaryTable data={data} label={"Summary"} alternative={alternative} />
  );
};

export const OrderAttachmentsTable = ({ attachments, alternative }) => {
  const data = attachments.map((attachment) => ({
    name: attachment.name,
    value: "Download",
    url: attachment.file,
  }));

  return (
    <SummaryTable
      data={data}
      label={"Order Attachments"}
      alternative={alternative}
    />
  );
};

export const ServicesRequestedTable = ({ samples, alternative }) => {
  if (!samples) {
    return null;
  }

  const heCount = samples.reduce((count, sample) => {
    return sample.requested_h_and_e_stain_count + count;
  }, 0);
  const unstainedCount = samples.reduce((count, sample) => {
    return sample.requested_unstained_count + count;
  }, 0);
  const ihcCount = samples.reduce((count, sample) => {
    return sample.requested_antibodies_count + count;
  }, 0);
  const specialStainCount = samples.reduce((count, sample) => {
    return sample.requested_special_stains_count + count;
  }, 0);

  const ifCount = samples.reduce((count, sample) => {
    return sample.requested_if_count + count;
  }, 0);

  const totalSlidesCount = samples.reduce((count, sample) => {
    return sample.total_slides_requested + count;
  }, 0);

  let ihcAntibodiesNames = [];
  let channelFilterNames = [];
  let specialStainsNames = [];
  samples.forEach((sample) => {
    if (sample.requested_antibodies) {
      ihcAntibodiesNames.push(
        ...getAntibodyDisplayNameFromAPIResults(sample.requested_antibodies)
      );
    }

    if (sample.requested_special_stains) {
      specialStainsNames.push(
        ...getNamesFromArray(sample.requested_special_stains)
      );
    }

    if (sample.requested_channel_filters) {
      channelFilterNames.push(
        ...getNamesFromArray(sample.requested_channel_filters)
      );
    }

    if (sample.requested_panels) {
      channelFilterNames.push(...getNamesFromArray(sample.requested_panels));
    }
  });

  ihcAntibodiesNames = [...new Set(ihcAntibodiesNames)];
  specialStainsNames = [...new Set(specialStainsNames)];
  channelFilterNames = [...new Set(channelFilterNames)];

  const data = [
    { name: "H&E Slides Requested (#) ", value: heCount },
    { name: "Unstained Slides Requested (#) ", value: unstainedCount },
    { name: "IHC Slides Requested (#) ", value: ihcCount },
    { name: "Special Slides Requested (#)", value: specialStainCount },
    { name: "Immunofluorescence Requested (#)", value: ifCount },
    //ifCount
    { name: "Total Slides Requested (#)", value: totalSlidesCount },
    { name: "IHC Antibodies Requested", value: ihcAntibodiesNames.join(", ") },
    { name: "Special Stains Requested", value: specialStainsNames.join(", ") },
    {
      name: "Channel Filters / Panels Requested",
      value: channelFilterNames.join(", "),
    },
  ];
  return (
    <SummaryTable
      data={data}
      label={"Services Requested"}
      alternative={alternative}
    />
  );
};

export const ShippingTable = ({ order, shipments, alternative }) => {
  const ship_back_stained_slides = toTitleCase(
    (!!order.ship_back_stained_slides).toString()
  );
  const ship_back_unstained_slides = toTitleCase(
    (!!order.ship_back_unstained_slides).toString()
  );
  const ship_back_samples = toTitleCase(order.ship_back_samples.toString());

  const data = [
    { name: "Ship Back Stained Slides", value: ship_back_stained_slides },
    { name: "Ship Back Unstained Slides", value: ship_back_unstained_slides },
    { name: "Ship Back Blocks", value: ship_back_samples },
    {
      name: "Shipment Method",
      value: SHIPPING_OPTIONS_DETAILS[order.shipment_method],
    },
  ];

  if (order.dropbox) {
    data.push({
      name: "Dropbox Location",
      value: order.dropbox.name,
      url: order.dropbox.image,
    });
  }

  if (shipments) {
    const toHistoWizShipment = shipments.filter((shipment) => {
      return !!(
        shipment.address_to && shipment.address_to.street_1.includes("30-02")
      );
    })[0];

    const fromHistoWizShipment = shipments.filter((shipment) => {
      return !!(
        shipment.address_from &&
        shipment.address_from.street_1.includes("30-02")
      );
    })[0];

    if (toHistoWizShipment) {
      data.push({
        name: "FedEx - To HistoWiz Shipping Label",
        value: "Download",
        url: toHistoWizShipment.shipping_label,
      });
      //TODO : Make these functions
      const fedexURL = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${toHistoWizShipment.tracking_number}`;
      data.push({
        name: "FedEx - To HistoWiz",
        value: "Link",
        url: fedexURL,
      });
    }

    if (fromHistoWizShipment) {
      data.push({
        name: "FedEx - From HistoWiz Shipping Label",
        value: "Download",
        url: fromHistoWizShipment.shipping_label,
      });

      const fedexURL = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${fromHistoWizShipment.tracking_number}`;
      data.push({
        name: "FedEx - From HistoWiz",
        value: "Link",
        url: fedexURL,
      });
    }
  }

  return (
    <SummaryTable data={data} label={"Shipping"} alternative={alternative} />
  );
};

export const OrderBillingTable = ({ order, alternative, hidden }) => {
  const po_number = order.billing_contact
    ? order.billing_contact.po_number
    : "N/A";

  const data = [{ name: "PO Number", value: po_number }];

  if (order.quote_attachment) {
    data.push({
      name: "Attached Quote",
      value: "Link",
      url: order.quote_attachment,
    });
  }

  if (order.po_attachment) {
    data.push({
      name: "Attached PO",
      value: "Link",
      url: order.po_attachment,
    });
  }

  return (
    <SummaryTable
      data={data}
      label={"Billing"}
      alternative={alternative}
      hidden={hidden}
      isEditable
      order={order}
    />
  );
};
