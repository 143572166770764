import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const styles = () => {
  return {
    loadingProgress: {
      margin: ".5rem",
    },
  };
};

const useStyles = makeStyles()(styles);

export const SubmittingIcon = ({ submitting, ...props }) => {
  const { classes } = useStyles();

  return submitting ? (
    <CircularProgress
      className={classes.loadingProgress}
      color="secondary"
      variant="indeterminate"
      size={20}
      {...props}
    />
  ) : (
    <span />
  );
};
