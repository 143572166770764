import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { makeStyles } from "tss-react/mui";
import { checkMarkStyles } from "components/OrderForm/styles";

export const CheckmarkProvider = ({
  isValid,
  children,
  validated,
  visible,
  className,
}) => {
  const useStyles = makeStyles()(checkMarkStyles);
  const { classes } = useStyles();
  const iconStyles = className ? `${classes.icon} ${className}` : classes.icon;

  return (
    <div className={classes.container} data-testid="checkmark">
      {children}
      {visible &&
        validated &&
        (isValid ? (
          <CheckCircleIcon className={iconStyles} color="success" />
        ) : (
          <ErrorIcon className={iconStyles} color="error" />
        ))}
    </div>
  );
};
