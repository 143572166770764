import Grid from "@mui/material/Grid";
import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

export const QCToolbar = ({
  showOverlay,
  handleOverlayChange,
  handleDisplayFloatingThumbnailChange,
  showFloatingMacroImage,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={showOverlay}
              value="showOverlay"
              onChange={handleOverlayChange}
            />
          }
          label="Show Overlays"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={showFloatingMacroImage}
              value="displayThumbnail"
              onChange={handleDisplayFloatingThumbnailChange}
            />
          }
          label="Display Thumbnail"
        />
      </Grid>
    </Grid>
  );
};
