import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import React from "react";
import { useShortInfoBlockStyles } from "components/SlideGroups/utilities/styles";

export const ShortInfoBlock = ({ title, content }) => {
  const { classes } = useShortInfoBlockStyles();

  return (
    <Box className={classes.shortInfoBlock}>
      <Typography className={classes.shortInfoCaption}>{title}</Typography>
      <Typography className={classes.shortInfoText}>
        {content || "-"}
      </Typography>
    </Box>
  );
};
