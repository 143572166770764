import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { CustomTableCell } from "components/OrdersList/constants";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { withContextAsProps } from "react-context-consumer-hoc";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { AppContext } from "services/context";
import { getReferrals, postReferral } from "services/resources/referrals";
import { DATE_REQUEST_FORMAT } from "utilities/dates_and_times";
import { makeStyles } from "tss-react/mui";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { isEmailValid } from "utilities/auth";
import { EMAIL_VALIDATION_MESSAGE } from "constants/errorMessages";
import { ERROR_ICON } from "utilities/colors";

const useStyles = makeStyles()(() => ({
  formInput: {
    marginTop: "0.25rem",
    padding: "0.5rem",
    fontSize: "14px",
    width: "30%",
    display: "inline-block",
    borderRadius: "4px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    marginBottom: "1rem",
    marginRight: "1rem",
  },
  errorInput: {
    borderColor: ERROR_ICON,
  },
  text: {
    marginBottom: "1rem",
  },
  button: {
    fontSize: "0.875rem",
    padding: "0.313rem 1.25rem",
  },
}));

export const ReferralsTableHeader = (
  <TableHead>
    <TableRow>
      <CustomTableCell>Email</CustomTableCell>
      <CustomTableCell>Sent On</CustomTableCell>
      <CustomTableCell>Status</CustomTableCell>
    </TableRow>
  </TableHead>
);

const ReferralsTableRow = ({ row }) => {
  return (
    <TableRow>
      <CustomTableCell component="th" scope="row">
        {row.email}
      </CustomTableCell>
      <CustomTableCell component="th" scope="row">
        {moment(row.created).format(DATE_REQUEST_FORMAT)}
      </CustomTableCell>
      <CustomTableCell component="th" scope="row">
        Sent
      </CustomTableCell>
    </TableRow>
  );
};

export const ReferralsTable = ({ referrals }) => {
  return (
    <Table>
      {ReferralsTableHeader}
      <TableBody>
        {referrals.map((row) => {
          return <ReferralsTableRow row={row} key={row.uuid} />;
        })}
      </TableBody>
    </Table>
  );
};

const _ReferralView = () => {
  const { classes } = useStyles();
  const { showError } = useSnackbar();
  const [referralEmail, setReferralEmail] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    getReferrals().then((response) => {
      setReferrals(response);
    });
  }, []);

  const handleEmailChange = ({ target: { value } }) => {
    setEmailError(false);
    setReferralEmail(value);
  };

  const submitEmail = () => {
    const isValid = isEmailValid(referralEmail);

    if (!isValid) {
      setEmailError(true);
      showError(EMAIL_VALIDATION_MESSAGE);
      return;
    }

    postReferral({ email: referralEmail })
      .then(({ data }) => {
        const updatedReferrals = [data, ...referrals];
        setReferrals(updatedReferrals);
      })
      .catch(({ response, message }) => {
        showError(response?.data?.email || message);
      });
  };

  return (
    <>
      <Typography variant={"h4"} className={classes.text}>
        Introducing Our Referral Program ...
      </Typography>

      <Typography variant={"h5"} className={classes.text}>
        If you refer a friend to HistoWiz, we're offering a special offer! We're
        offering a $50 Amazon Gift or 5% discount off your next order. Your
        preference, just let us know. Any newly referred customers will also get
        $100 discount on their first order! Please note - Referral discounts
        apply to new teams only.
      </Typography>

      <input
        className={`${classes.formInput} ${emailError && classes.errorInput}`}
        type="text"
        placeholder={"Email ... "}
        value={referralEmail}
        onChange={handleEmailChange}
      />
      <Button
        variant="contained"
        color="secondary"
        size={"large"}
        className={classes.button}
        onClick={submitEmail}
        disabled={emailError || !referralEmail}
      >
        Refer A Friend!
      </Button>
      <Typography variant={"h5"} className={classes.text}>
        Referral Email History
      </Typography>
      <ReferralsTable referrals={referrals} />
    </>
  );
};

export const ReferralView = compose(
  withRouter,
  withContextAsProps(AppContext)
)(_ReferralView);

export const ReferralPage = () => (
  <MiniDrawerWithContext header={"Referrals"}>
    <ReferralView />
  </MiniDrawerWithContext>
);
