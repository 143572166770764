import highResolutionLogo from "images/logos/logoHighResolution.png";
import { FACEBOOK, LINKED_IN, TELEGRAM, TWITTER, WHATSAPP } from "./constants";

export const getSocialShareLink = (type, slideUrl) => {
  const encodedUrl = encodeURIComponent(slideUrl);

  switch (type) {
    case FACEBOOK:
      return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    case TWITTER:
      return `https://twitter.com/intent/tweet?url=${encodedUrl}`;
    case WHATSAPP:
      return `https://wa.me/?text=${encodedUrl}`;
    case TELEGRAM:
      return `https://telegram.me/share/url?url=${encodedUrl}`;
    case LINKED_IN:
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
    default:
      return slideUrl;
  }
};

export const getBodyPreview = (
  isIFViewer,
  imgSrc,
  generatedSlideUrl,
  projectName,
  slideName
) => {
  const imgPosition = isIFViewer
    ? "object-position: bottom;"
    : "object-position: 20% 25%;";

  return `<body style='margin: 0px; overflow: hidden; height: 250px; font-family: sans-serif;'>   
  <div style='position: relative; height: 100%;'>
    <div style='width: 100%; background-color: white;'>
      <img style='width: 200px' src=${window.location.origin}${highResolutionLogo}/>
    </div>
    <div style='background-color: black; position: absolute; width: 100%; height: 100%; opacity: 0.4;'></div>
      <img style='width: 100%; height: 100%; object-fit: cover; ${imgPosition}' src=${imgSrc} />
      <p style='font-size: 12px; width: 98%; position: absolute; top: 22%; left: 2%; color: white; margin: 0px;'>${projectName} / ${slideName}</p>
      <a href=${generatedSlideUrl} target='_blank' style='position: absolute; top: 55%; left: 37%; color: white; border: 1px solid white; padding: 8px 15px; cursor: pointer; text-decoration: none;'>Click to view more</a>
    </div>
</body>`;
};

export const getIframe = (body) => {
  return `<iframe style='max-width: 800px; width: 100%; margin-top: 1.25rem; border: 0px; height: 250px; overflow: hidden' srcDoc="${body}" title='preview'></iframe>`;
};
