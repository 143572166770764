import { AppBar, Tab, Tabs, Typography, CircularProgress } from "@mui/material";
import { ORDER_DETAILS_URL } from "constants/urls";
import moment from "moment";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  getOrderIDtoUUIDMapping,
  getOrderShipments,
} from "services/resources/orders";
import { MONTH_DAY_YEAR_FORMAT } from "utilities/dates_and_times";
import { useOrderDetailsSlidesStyles } from "components/OrderDetails/orderDetailsSlidesStyles";
import {
  ACTIVITY_TAB_VALUE,
  BILLING_AND_SHIPPING_TAB_VALUE,
  CONSOLIDATED_TAB_VALUE,
  EMBEDDING_AND_CUTTING_TAB_VALUE,
  IMAGE_ANALYSIS_PATHOLOGY_CONSULTATION_TAB_VALUE,
  FILE_ATTACHMENTS_FAILED_TO_UPLOAD,
  ORDER_PROGRESS_TAB_VALUE,
  PROCESSED_IF_SLIDES_TAB_VALUE,
  PROCESSED_SLIDES_TAB_VALUE,
  SAMPLES_DETAILS_TAB_VALUE,
  SUMMARY_TAB_VALUE,
  VALID_ORDER_DETAILS_TAB_VALUES,
} from "components/OrderDetails/constants";
import { useTitle } from "components/utilities/hooks/useTitle";
import {
  getOrderFileAttachments,
  uploadOrderFileAttachments,
} from "services/resources/commonResources";
import { circularProgressContainerStyles } from "components/OrderDetails/styles";
import { TabContents } from "components/OrderDetails/Tabs/TabContents";
import { ORDER_FETCHING_ERROR_MESSAGE } from "components/OrderForm/constants";
import { useGetOrderByUUIDQuery } from "store/apis/orderApi";
import {
  COMMON_ERROR_MESSAGE,
  FETCH_ORDER_SHIPMENT_ERROR_MESSAGE,
  FETCHING_SLIDES_ERROR_MESSAGE,
  GET_SAMPLES_ERROR,
} from "constants/errorMessages";
import { useSelector } from "react-redux";
import { isStaffSelector } from "store/slices/userDetailsSlice";
import { getTabValueByHash } from "utilities/general";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import { useTabsNavigation } from "utilities/hooks/useTabsNavigation/useTabsNavigation";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useGetIFSlidesByOrderQuery } from "store/apis/ifSlideApi";
import {
  useLazyGetNotUploadedSlidesByOrderQuery,
  useLazyGetSlidesByOrderQuery,
} from "store/apis/slidesApi";
import { useLazyGetSamplesByOrderIdQuery } from "store/apis/samplesApi";

export const OrderDetailsView = () => {
  useTitle("HistoWiz - Order Details");
  const match = useRouteMatch();

  const location = useLocation();
  const isStaff = useSelector(isStaffSelector);
  const { classes } = useOrderDetailsSlidesStyles();
  const [orderUUID, setOrderUUID] = useState(match?.params["orderUUID"]);
  const [shipments, setShipments] = useState([]);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [tabValue, setTabValue] = useState(
    getTabValueByHash(
      location.hash,
      VALID_ORDER_DETAILS_TAB_VALUES,
      PROCESSED_SLIDES_TAB_VALUE
    )
  );
  const [showFailedQCSlides, setShowFailedQCSlides] = useState(false);
  const [fileAttachments, setFileAttachments] = useState([]);

  const { data: ifSlides = [], isLoading: isIFSlidesLoading } =
    useGetIFSlidesByOrderQuery(orderUUID, {
      skip: !orderUUID,
    });
  const [
    getSlidesByOrder,
    { isLoading: isSlidesLoading, data: slides, error: slidesFetchingError },
  ] = useLazyGetSlidesByOrderQuery();
  const [getNotUploadedSlidesByOrder, { data: notUploadedSlides }] =
    useLazyGetNotUploadedSlidesByOrderQuery();
  const [
    getSamplesByOrderId,
    { data: samples, error: getSamplesByOrderIdError },
  ] = useLazyGetSamplesByOrderIdQuery();

  const { showSuccess, showError } = useSnackbar();

  useTabsNavigation(tabValue);

  useQueryError(slidesFetchingError, FETCHING_SLIDES_ERROR_MESSAGE);
  useQueryError(getSamplesByOrderIdError, GET_SAMPLES_ERROR);

  useEffect(() => {
    const locationHashTabValue = getTabValueByHash(
      location.hash,
      VALID_ORDER_DETAILS_TAB_VALUES,
      PROCESSED_SLIDES_TAB_VALUE
    );

    if (tabValue === locationHashTabValue) {
      return;
    }

    setTabValue(locationHashTabValue);
  }, [location]);

  const routeIfBasedOnOrderID = useCallback(({ orderID }) => {
    return getOrderIDtoUUIDMapping({ orderID })
      .then((updatedUUID) => {
        const url = ORDER_DETAILS_URL.replace(":orderUUID", updatedUUID);
        window.location.href = url;
      })
      .catch(() => showError(COMMON_ERROR_MESSAGE));
  }, []);

  const {
    data: order,
    error,
    isLoading: isOrderLoading,
  } = useGetOrderByUUIDQuery(orderUUID);
  useTitle(`${order?.name || "Order"} Details`);
  useQueryError(error, ORDER_FETCHING_ERROR_MESSAGE);

  const updateSamples = useCallback(async (orderUUID) => {
    await getSamplesByOrderId({ orderUUID });
  }, []);

  const updateShipments = useCallback(async (orderUUID) => {
    const shipments = await getOrderShipments(orderUUID).catch(() => {
      showError(FETCH_ORDER_SHIPMENT_ERROR_MESSAGE);

      return [];
    });

    setShipments(shipments);
  }, []);

  const updateFileAttachments = useCallback(async (orderUUID) => {
    const fileAttachments = await getOrderFileAttachments(orderUUID);

    setFileAttachments(fileAttachments);
  }, []);

  const handleTabChange = useCallback((_event, tabValue) => {
    setTabValue(tabValue);
  }, []);

  const handleToggleShowFailedQCSlides = useCallback(() => {
    setShowFailedQCSlides((prevShowFailedQCSlides) => !prevShowFailedQCSlides);
  }, []);

  const onQuickFilterText = useCallback((event) => {
    setQuickFilterText(event.target.value);
  }, []);

  // update orderUUID Effect
  useEffect(() => {
    setOrderUUID(match?.params["orderUUID"]);
  }, [match?.params]);

  // we do this for when using global search, we want to change the orderUUID dynamically
  // when this happens, we want this page to update and get the updated orderUUID
  useEffect(() => {
    if (orderUUID.length < 8) {
      routeIfBasedOnOrderID({ orderID: orderUUID });
    }

    getSlidesByOrder({ orderUUID });
    updateSamples(orderUUID);
    updateShipments(orderUUID);

    if (isStaff) {
      getNotUploadedSlidesByOrder({ orderUUID });
    }

    updateFileAttachments(orderUUID);
  }, [
    isStaff,
    orderUUID,
    updateSamples,
    updateShipments,
    routeIfBasedOnOrderID,
    updateFileAttachments,
  ]);

  if (!order) return null;

  const createDateFormatted = moment(order.created).format(
    MONTH_DAY_YEAR_FORMAT
  );

  const onDrop = (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("order_uuid", orderUUID);

    uploadOrderFileAttachments(formData)
      .then(() =>
        showSuccess("Files uploaded successfully! Please refresh this page.")
      )
      .catch(() => showError(FILE_ATTACHMENTS_FAILED_TO_UPLOAD));
  };

  return (
    <Fragment>
      {isOrderLoading && (
        <div style={circularProgressContainerStyles}>
          <CircularProgress size={70} />
        </div>
      )}
      <Typography variant="h5" noWrap>
        {order.name} - {createDateFormatted}
      </Typography>
      <div className={classes.tabStyle}>
        <AppBar position="static" className={classes.appBarStyle}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant={"scrollable"}
            indicatorColor={"primary"}
            scrollButtons
          >
            <Tab value={SUMMARY_TAB_VALUE} label="Summary" />
            <Tab value={ACTIVITY_TAB_VALUE} label="Activity" />
            <Tab value={EMBEDDING_AND_CUTTING_TAB_VALUE} label="Embed & Cut" />
            <Tab value={PROCESSED_SLIDES_TAB_VALUE} label="Slides" />
            <Tab value={PROCESSED_IF_SLIDES_TAB_VALUE} label="IF Slides" />
            <Tab
              value={IMAGE_ANALYSIS_PATHOLOGY_CONSULTATION_TAB_VALUE}
              label="Image Analysis & Path Consult"
            />
            <Tab
              value={BILLING_AND_SHIPPING_TAB_VALUE}
              label="Billing & Shipping"
            />
            <Tab value={CONSOLIDATED_TAB_VALUE} label="Consolidated" />
            {/*need to do this way to have appbar render correctly, cannot use component*/}
            {isStaff && (
              <Tab value={SAMPLES_DETAILS_TAB_VALUE} label="Samples" />
            )}
            <Tab
              id="order-details-progress-tab"
              value={ORDER_PROGRESS_TAB_VALUE}
              label="Progress"
            />
          </Tabs>
        </AppBar>
        <TabContents
          tabValue={tabValue}
          classes={classes}
          isStaff={isStaff}
          order={order}
          samples={samples ?? []}
          ifSlides={ifSlides}
          shipments={shipments}
          slides={slides}
          isSlidesLoading={isSlidesLoading}
          isIFSlidesLoading={isIFSlidesLoading}
          showFailedQCSlides={showFailedQCSlides}
          quickFilterText={quickFilterText}
          onQuickFilterText={onQuickFilterText}
          handleToggleShowFailedQCSlides={handleToggleShowFailedQCSlides}
          notUploadedSlides={notUploadedSlides ?? []}
          attachments={fileAttachments}
          onFilesDrop={onDrop}
        />
      </div>
    </Fragment>
  );
};
