import moment from "moment";
import { DATE_REQUEST_FORMAT } from "utilities/dates_and_times";

export const getExportParams = (orderName) => {
  const now = moment().format(DATE_REQUEST_FORMAT);

  const fileName = `${orderName}-${now}`;
  return {
    columnGroups: true,
    fileName: fileName,
  };
};

export const getExportPCAndIASlidesParams = (orderName) => {
  // going to keep this here for the time being ... but tbh i dont really
  // know what the original intent of this is to do either
  const defaultParams = getExportParams(orderName);

  return {
    ...defaultParams,
    shouldRowBeSkipped: (node) => {
      const rowData = node.node.data;
      return !(
        rowData.image_analysis_count || rowData.pathology_consultation_count
      );
    },
  };
};

export const overlayLoadingTemplate =
  '<span class="ag-overlay-loading-center">Please wait while your rows are loading!</span>';

export const OrderSlidesListViewOverLayTemplate =
  '<span class="ag-overlay-loading-center">No Data Found (No Slides or Enable Show Team Orders?)</span>';
