import { useLayoutEffect, useRef } from "react";

export const useResizeObserver = (callback) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    const element = ref?.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      // Wrapper to avoid ResizeObserver loop limit exceeded and related errors
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        callback(element, entries[0]);
      });
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);

  return ref;
};
