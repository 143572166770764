import { Box } from "@mui/material";
import { MultiSlidesComments } from "components/ImageViewer/components/MultiSLidesComments";
import { SlideComments } from "components/ImageViewer/SlidesViewerComments";
import { SlideViewerActions } from "components/ImageViewer/SlideViewerHeader/SlideViewerActions";
import { SlidesTable } from "components/OrderSlidesList/OrderSlidesTable";
import { ActionTabsHeader } from "components/OrderSlidesList/SlideActionTabs/ActionTabsHeader/ActionTabsHeader";
import { actionTabs } from "components/OrderSlidesList/SlideActionTabs/constants";
import { SlideMetadata } from "components/OrderSlidesList/SlideActionTabs/SlideMetadata/SlideMetadata";
import { SlideGroupsVerticalContainer } from "components/SlideGroups/Modals/components/SlideGroupsVerticalContainer";
import React from "react";
import {
  useScrollStyles,
  useStyles,
} from "components/OrderSlidesList/SlideActionTabs/styles";
import { ConditionalComponent } from "components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent";
import { SlideAIToolsContent } from "components/AITools/components/SlideAIToolsContent";
import { SlideViewerExtAnnotations } from "components/OrderSlidesList/ExtAnnotations/ExtAnnotationsTab";

export const SlideActionTabs = ({
  slidesViewerProps,
  slidesTableProps,
  slidesCommentsProps,
  isSingleViewerMode,
  slidesGroupProps,
  currentSlide,
  activeTab,
  setActiveTab,
  disabledTabs,
  annotationsLegend,
  isMultipleSelected,
}) => {
  const { classes } = useStyles();
  const { classes: scrollClasses } = useScrollStyles();
  const isSlidesList = activeTab === actionTabs.slidesList && slidesTableProps;
  const isHeaderDisabled = isSingleViewerMode
    ? false
    : !slidesTableProps.gridApi;

  return (
    <Box className={classes.root}>
      <ActionTabsHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showList={!!slidesTableProps}
        disabledTabs={disabledTabs}
        isDisabled={isHeaderDisabled}
        isMultipleSelected={isMultipleSelected}
      />
      <Box className={`${classes.tabContent} ${scrollClasses.root}`}>
        {activeTab === actionTabs.meta && (
          <SlideMetadata slide={currentSlide} />
        )}
        {activeTab === actionTabs.comments &&
          (slidesViewerProps.slides.length > 1 ? (
            <MultiSlidesComments {...slidesViewerProps} />
          ) : slidesViewerProps.slides.length ? (
            <SlideComments {...slidesCommentsProps} />
          ) : null)}
        {isSlidesList && <SlidesTable {...slidesTableProps} />}
        {activeTab === actionTabs.groups && (
          <SlideGroupsVerticalContainer
            {...slidesGroupProps}
            customClasses={{
              sortsContainer: classes.sortsContainer,
              label: classes.groupsSortLabel,
              groupsRowContainer: classes.groupsRowContainer,
              slideGroupVerticalContainer: classes.groupsRowsContainer,
            }}
            showCreateButton
          />
        )}
        <ConditionalComponent condition={activeTab === actionTabs.aiTools}>
          <Box padding={1} overflow={"auto"}>
            <SlideAIToolsContent
              slide={currentSlide}
              slidesTableProps={slidesTableProps}
            />
          </Box>
        </ConditionalComponent>
        <ConditionalComponent
          condition={activeTab === actionTabs.extAnnotations}
        >
          <Box padding={1} overflow={"auto"}>
            <SlideViewerExtAnnotations
              currentSlide={currentSlide}
              isSingleViewer={isSingleViewerMode}
              annotationsLegend={annotationsLegend}
            />
          </Box>
        </ConditionalComponent>
        {activeTab === actionTabs.actions && (
          <SlideViewerActions
            currentSlide={currentSlide}
            isSingleViewer={!isMultipleSelected}
          />
        )}
      </Box>
    </Box>
  );
};
