import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

export const FulfilledCellCheckbox = ({ value: canBeFulfilled }) => {
  return (
    <>
      {canBeFulfilled ? (
        <DoneIcon fontSize="medium" color="secondary" />
      ) : (
        <ClearIcon fontSize="medium" color="error" />
      )}
    </>
  );
};

export const sampleHyperlinkRenderer = (params) => {
  const { colDef, data } = params;
  const link = `${process.env.REACT_APP_API_URL}/lisa/core/sample/${data.id}/change/`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {data[colDef.field]}
    </a>
  );
};

export class PairedUploadedRequestedColumns {
  constructor(
    sampleTypeName,
    requestedFieldName,
    uploadedFieldName,
    missingFieldName,
    extraFieldName = ""
  ) {
    this.sampleTypeName = sampleTypeName;
    this.requestedFieldName = requestedFieldName;
    this.uploadedFieldName = uploadedFieldName;
    this.missingFieldName = missingFieldName;
    this.extraFieldName = extraFieldName;
  }

  getUploadedColumn = () => {
    return {
      headerName: "Uploaded",
      field: this.uploadedFieldName,
      columnGroupShow: "open",
    };
  };

  getRequestedColumn = () => {
    return {
      headerName: "Requested",
      field: this.requestedFieldName,
      columnGroupShow: "open",
    };
  };

  getMissingColumn = () => {
    return {
      headerName: "Missing",
      field: this.missingFieldName,
      columnGroupShow: "open",
    };
  };

  getFulfilledState = ({ data, colDef }) => {
    return this.extraFieldName
      ? data[this.extraFieldName].fulfilled
      : !data[colDef.field];
  };

  getFulfilledStateColumn = () => {
    return {
      headerName: "",
      field: this.missingFieldName,
      cellRendererSelector: ({ data, colDef }) => {
        const fulfilledCellCheckboxRenderer = {
          component: "fulfilledCellCheckbox",
          params: { value: this.getFulfilledState({ data, colDef }) },
        };
        const requestedNumber = data[this.requestedFieldName];

        return requestedNumber ? fulfilledCellCheckboxRenderer : null;
      },
    };
  };

  getColumns = () => {
    return {
      headerName: this.sampleTypeName,
      children: [
        this.getFulfilledStateColumn(),
        this.getRequestedColumn(),
        this.getUploadedColumn(),
        this.getMissingColumn(),
      ],
    };
  };
}

export const getPairedColumnDefs = (pairedColumns) => {
  return pairedColumns.map((pair) => {
    const pairedColumns = new PairedUploadedRequestedColumns(...pair);
    return pairedColumns.getColumns();
  });
};
