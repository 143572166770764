import { useHistory } from "react-router";
import { Box, Typography } from "@mui/material";
import { SwapVert, North, South } from "@mui/icons-material";

import { useGetUrlParam } from "./helpers/hooks";
import { ORDERING_PARAM, PAGE_PARAM } from "./helpers/constants";
import { useSortPanelStyles } from "./styles";

export const SamplesSortButton = ({ param }) => {
  const { classes } = useSortPanelStyles();
  const { sortItemWrapper, sortTitle, activeSort } = classes;

  const history = useHistory();
  const sortBy = useGetUrlParam(ORDERING_PARAM);
  const isActive = sortBy.includes(param) || (!param && !sortBy);

  const sortHandler = () => {
    let params = new URLSearchParams(history.location.search);

    if (param) {
      const existingTypeSymbol = sortBy.includes("-") ? "" : "-";
      const directionSymbol = sortBy.includes(param) ? existingTypeSymbol : "";

      params.set(ORDERING_PARAM, `${directionSymbol}${param}`);
    } else {
      params.delete(ORDERING_PARAM);
    }

    params.set(PAGE_PARAM, 1);
    history.replace({ search: params.toString() });
  };

  const activeSortArrows = sortBy.includes("-") ? (
    <South fontSize="small" />
  ) : (
    <North fontSize="small" />
  );

  return (
    <Box className={sortItemWrapper} onClick={() => sortHandler(param)}>
      <Typography className={`${sortTitle} ${isActive && activeSort}`}>
        {param ?? "PROJECT/ORDER"}
      </Typography>
      {param && (
        <>{isActive ? activeSortArrows : <SwapVert fontSize="small" />}</>
      )}
    </Box>
  );
};
