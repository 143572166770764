export const US_STATES = [
  {
    name: "Alabama",
    value: "AL",
  },
  {
    name: "Alaska",
    value: "AK",
  },
  {
    name: "Arizona",
    value: "AZ",
  },
  {
    name: "Arkansas",
    value: "AR",
  },
  {
    name: "California",
    value: "CA",
  },
  {
    name: "Colorado",
    value: "CO",
  },
  {
    name: "Connecticut",
    value: "CT",
  },
  {
    name: "Delaware",
    value: "DE",
  },
  {
    name: "District Of Columbia",
    value: "DC",
  },
  {
    name: "Florida",
    value: "FL",
  },
  {
    name: "Georgia",
    value: "GA",
  },
  {
    name: "Guam",
    value: "GU",
  },
  {
    name: "Hawaii",
    value: "HI",
  },
  {
    name: "Idaho",
    value: "ID",
  },
  {
    name: "Illinois",
    value: "IL",
  },
  {
    name: "Indiana",
    value: "IN",
  },
  {
    name: "Iowa",
    value: "IA",
  },
  {
    name: "Kansas",
    value: "KS",
  },
  {
    name: "Kentucky",
    value: "KY",
  },
  {
    name: "Louisiana",
    value: "LA",
  },
  {
    name: "Maine",
    value: "ME",
  },
  {
    name: "Maryland",
    value: "MD",
  },
  {
    name: "Massachusetts",
    value: "MA",
  },
  {
    name: "Michigan",
    value: "MI",
  },
  {
    name: "Minnesota",
    value: "MN",
  },
  {
    name: "Mississippi",
    value: "MS",
  },
  {
    name: "Missouri",
    value: "MO",
  },
  {
    name: "Montana",
    value: "MT",
  },
  {
    name: "Nebraska",
    value: "NE",
  },
  {
    name: "Nevada",
    value: "NV",
  },
  {
    name: "New Hampshire",
    value: "NH",
  },
  {
    name: "New Jersey",
    value: "NJ",
  },
  {
    name: "New Mexico",
    value: "NM",
  },
  {
    name: "New York",
    value: "NY",
  },
  {
    name: "North Carolina",
    value: "NC",
  },
  {
    name: "North Dakota",
    value: "ND",
  },
  {
    name: "Ohio",
    value: "OH",
  },
  {
    name: "Oklahoma",
    value: "OK",
  },
  {
    name: "Oregon",
    value: "OR",
  },
  {
    name: "Pennsylvania",
    value: "PA",
  },
  {
    name: "Puerto Rico",
    value: "PR",
  },
  {
    name: "Rhode Island",
    value: "RI",
  },
  {
    name: "South Carolina",
    value: "SC",
  },
  {
    name: "South Dakota",
    value: "SD",
  },
  {
    name: "Tennessee",
    value: "TN",
  },
  {
    name: "Texas",
    value: "TX",
  },
  {
    name: "Utah",
    value: "UT",
  },
  {
    name: "Vermont",
    value: "VT",
  },
  {
    name: "Virgin Islands",
    value: "VI",
  },
  {
    name: "Virginia",
    value: "VA",
  },
  {
    name: "Washington",
    value: "WA",
  },
  {
    name: "West Virginia",
    value: "WV",
  },
  {
    name: "INTL",
    value: "INTL",
  },
  {
    name: "Wisconsin",
    value: "WI",
  },
  {
    name: "Wyoming",
    value: "WY",
  },
];

export const defaultSignupValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  password_2: "",
  labName: "",
  city: "",
  phoneNumber: "",
  referral: "",
  referralDetails: "",
  organization_uuid: "",
  state: "",
};

export const REFERRAL_PROGRAM_VALUE_CONSTANT = "referral_program";
export const ranges = [
  { value: "word_of_mouth", label: "Word Of Mouth" },
  { value: "google_search", label: "Google Search" },
  { value: REFERRAL_PROGRAM_VALUE_CONSTANT, label: "Referral Program" },
  { value: "video_advertisement", label: "Video Advertisement" },
  { value: "company_rep_call", label: "Company Rep Call" },
  { value: "company_rep_visit", label: "Company Rep Visit" },
  { value: "company_rep_email", label: "Company Rep Email" },
  { value: "conference_vendor_show", label: "Conference / Vendor Show" },
  { value: "print_advertisement", label: "Print Advertisement" },
  // { value: "mailing_list", label: "Mailing List" },
  { value: "social_media", label: "Social Media / PR" },
  //{ value: "other", label: "Other" },
  { value: "affiliate", label: "HistoWiz Affiliate" },
  // { value: "digital_ad", label: "Digital Ad" }
];

export const ERROR_PROCESSING_SIGNUP_MESSAGE = "Error processing signup!";

export const ERROR_PROCESSING_ZIP_CODE = "Error getting address from zip code";
