import { Card } from "components/common/Card/Card";
import React, { memo, useEffect, useState } from "react";
import { useOrderHistory } from "components/OrderDetails/Tabs/OrderProgressTab/hooks";
import { ProgressStepper } from "components/OrderDetails/Tabs/OrderProgressTab/ProgressStepper";
import { ORDER_CANCELLED_TRACKER_STATE } from "components/OrdersList/constants";
import {
  prepareSteppers,
  prepareSteppersForCancelledOrder,
} from "components/OrderDetails/Tabs/OrderProgressTab/utilities";
import { CardHeader } from "components/UserProfile/components/CardHeader";
import { Typography } from "@mui/material";
import { useLazyGetSamplesByOrderIdQuery } from "store/apis/samplesApi";
import { GET_SAMPLES_ERROR } from "constants/errorMessages";
import { useSnackbar } from "notistack";

export const OrderTrackingCard = memo(({ order }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { orderHistory } = useOrderHistory(order);
  const { showError } = useSnackbar();

  const [getSamplesByOrderId, { data: samples }] =
    useLazyGetSamplesByOrderIdQuery();

  useEffect(() => {
    if (!order) return null;
    setIsLoading(true);

    getSamplesByOrderId({ orderUUID: order.uuid })
      .catch(() => showError(GET_SAMPLES_ERROR))
      .finally(() => {
        setIsLoading(false);
      });
  }, [order]);

  if (!order) return null;

  const isOrderCancelled =
    order.tracker_state === ORDER_CANCELLED_TRACKER_STATE;

  const steppers = isOrderCancelled
    ? prepareSteppersForCancelledOrder(order, orderHistory, samples ?? [])
    : prepareSteppers(order, orderHistory, samples ?? []);

  return (
    <Card
      sx={{ height: "100%" }}
      headerContent={
        <CardHeader
          title={order.state_label.toUpperCase()}
          subtitle={"ORDER TRACKING: " + order.id}
        />
      }
      bodyContent={
        <div>
          {isOrderCancelled ? (
            <Typography mt={4} mb={4} align="center" variant="h6">
              This order has been cancelled
            </Typography>
          ) : (
            steppers.map((stepper) => (
              <ProgressStepper
                key={stepper.name}
                stepper={stepper}
                isLoading={isLoading}
              />
            ))
          )}
        </div>
      }
    />
  );
});
