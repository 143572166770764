import { makeStyles } from "tss-react/mui";

export const useIFHeaderStyles = makeStyles()(() => ({
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: "1rem",
  },
  buttons: {
    display: "flex",
    gap: "0.5rem",
    maxWidth: "560px",
    width: "max-content",
  },
  breadcrumb: {
    alignItems: "center",
    display: "flex",
    gap: "0.5rem",
    width: "calc(100% - 565px)",
    whiteSpace: "nowrap",
  },
  icon: { position: "relative", zIndex: 10, height: "1.5rem" },
  thumbnail: {
    borderRadius: "1rem",
    position: "absolute",
    left: "100%",
    top: 0,
    width: "250px",
  },
}));
