import { makeStyles } from "tss-react/mui";
import { FORM_TEXT_COLOR, HISTOWIZ_SECONDARY_COLOR } from "utilities/colors";

export const useOrderInfoFormStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",

    "& .MuiGrid-root": {
      marginTop: "0 !important",
      paddingTop: "0 !important",
    },
  },
  additionalStudyDetailsButton: {
    marginTop: "0.5rem",
  },
  formItem: {
    marginTop: "-1.125rem",
    flexBasis: "95%",
  },
  nextButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "0.5rem",
    color: FORM_TEXT_COLOR,
  },
  checkbox: {
    color: FORM_TEXT_COLOR,
    "&.Mui-checked": {
      color: HISTOWIZ_SECONDARY_COLOR,
    },
  },
  InfoModalLink: {
    color: "blue",
    fontSize: "14px",
    marginTop: "0.25rem",
    cursor: "pointer",
    fontWeight: "500",
  },
  item: {
    padding: "1rem !important",
  },
  itemsContainer: {
    marginLeft: "-1rem",
    marginTop: 0,
  },
}));
