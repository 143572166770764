export const FETCH_DATA_ERROR_MESSAGE =
  "An error occurred during fetching data!";
export const GENERAL_ERROR_MESSAGE =
  "An error occurred during request processing!";
export const FETCH_ORGANIZATIONS_ERROR_MESSAGE =
  "An error occurred during fetching organizations!";
export const CREATE_ORGANIZATIONS_ERROR_MESSAGE =
  "An error occurred during creating organizations!";
export const FETCH_PROJECTS_ERROR_MESSAGE =
  "An error occurred during fetching projects!";
export const FETCH_TEAMS_ERROR_MESSAGE =
  "An error occurred during fetching teams!";
export const FETCH_NEWSFEED_ACTIVITY_ERROR =
  "An error occurred during fetching newsfeed!";
export const CREATE_PROJECT_ERROR_MESSAGE =
  "An error occurred during project creating!";
export const GET_SLIDE_GROUP_ERROR_MESSAGE =
  "An error occurred during getting slide group!";
export const CREATE_FIXATIVE_ERROR_MESSAGE =
  "An error occurred during fixative creating!";
export const CREATE_ANTIBODY_ERROR_MESSAGE =
  "An error occurred during antibody creating!";
export const FETCH_ORGANS_ERROR_MESSAGE =
  "An error occurred during organs fetching!";
export const FETCH_SPECIES_ERROR_MESSAGE =
  "An error occurred during species fetching!";
export const FETCH_SPECIAL_INSTRUCTIONS_ERROR_MESSAGE =
  "An error occurred during special instructions fetching!";
export const UPLOAD_SPECIAL_INSTRUCTIONS_ERROR_MESSAGE =
  "An error occurred during special instructions uploading!";
export const UPLOAD_ATTACHMENT_ERROR_MESSAGE =
  "An error occurred during attachment uploading!";
export const FETCH_SAMPLES_SUBMISSION_ERROR_MESSAGE =
  "An error occurred during samples submission fetching!";
export const FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE =
  "An error occurred during multiplex antibodies fetching!";
export const FETCH_ANTIBODIES_ERROR_MESSAGE =
  "An error occurred during antibodies fetching!";
export const FETCH_SPECIAL_STAINS_ERROR_MESSAGE =
  "An error occurred during special stains fetching!";
export const FETCH_PURCHASE_ORDERS_ERROR_MESSAGE =
  "An error occurred during blanket purchase orders fetching!";
export const FETCH_BILLING_CONTACTS_ERROR_MESSAGE =
  "An error occurred during billing contacts fetching!";
export const POST_BILLING_CONTACTS_ERROR_MESSAGE =
  "An error occurred during billing contacts posting!";
export const FETCH_TEAM_ADDRESSES_ERROR_MESSAGE =
  "An error occurred during fetching team addresses!";
export const FETCH_DROPBOXES_ERROR_MESSAGE =
  "An error occurred during fetching dropboxes!";
export const FETCH_IF_CHANNEL_FILTERS_ERROR_MESSAGE =
  "An error occurred during fetching IF channel filters!";
export const POST_UNSTAINED_SLIDES_ERROR_MESSAGE =
  "An error occurred during unstained slides posting!";
export const FETCH_SLIDE_ERROR_MESSAGE =
  "An error occurred during slide fetching!";
export const FETCH_TEAM_BLOCKS_ERROR_MESSAGE =
  "An error occurred during team blocks fetching!";
export const MERGE_TEAM_BLOCKS_ERROR_MESSAGE =
  "An error occurred during team blocks merging!";

export const UPDATE_USER_DETAILS_MESSAGE =
  "An error occurred when updating user details!";
export const FETCH_USER_DETAILS_MESSAGE =
  "An error occurred during fetching user details!";

export const COMMON_ERROR_MESSAGE = "Network error occurred!";
export const SUBMIT_ORDER_ERROR_MESSAGE =
  "An Error occurred during order submitting!";
export const UPDATE_ORDER_ERROR_MESSAGE =
  "An Error occurred during order updating!";
export const DUPLICATE_ORDER_ERROR_MESSAGE =
  "An Error occurred during order duplicating!";
export const FETCH_ORDER_ERROR_MESSAGE =
  "An error occurred when retrieving this order's data";
export const FETCH_ORDERS_ERROR_MESSAGE =
  "An error occurred when retrieving orders data";
export const FETCH_SHARED_ORDER_ERROR_MESSAGE =
  "An Error occurred during fetching shared order!";
export const FETCH_SHARED_GROUP_ERROR_MESSAGE =
  "An Error occurred during fetching shared group!";
export const FETCH_NEWSFEED_ERROR_MESSAGE =
  "An Error occurred during fetching news feed!";
export const FETCH_SAMPLES_SCIENCE_INFO_ERROR_MESSAGE =
  "An Error occurred during fetching samples science info!";
export const FETCH_ORDER_IDS_INFO_ERROR_MESSAGE =
  "An Error occurred during fetching order ids!";
export const FETCH_ORDER_FIXATIVES_ERROR_MESSAGE =
  "An Error occurred during fetching order fixatives!";
export const FETCH_ORDER_SHIPMENT_ERROR_MESSAGE =
  "An Error occurred during fetching order shipments!";

export const QUOTE_SUBMITTING_ERROR_MESSAGE =
  "An Error occurred during submitting Email request!";
export const DELETE_ORDER_ERROR_MESSAGE =
  "An Error occurred during order deleting!";
export const DELETE_COMMENT_ERROR_MESSAGE =
  "An Error occurred during comment deleting!";
export const FETCHING_COMMENTS_ERROR_MESSAGE =
  "An Error occurred during slide comments fetching!";
export const FETCHING_SLIDES_ERROR_MESSAGE =
  "An Error occurred during slides fetching!";
export const BULK_RESET_QC_ERROR_MESSAGE =
  "An error occurred during bulk reset QC slides!";
export const POST_STRIPE_CARD_ERROR_MESSAGE =
  "An Error occurred during posting stripe card!";
export const POST_ORDER_SHIPMENT_ERROR_MESSAGE =
  "An Error occurred during posting order shipment!";
export const USER_ADDED_TO_TEAM_ERROR_MESSAGE =
  "Your adding to a team failed. Please contact the support team!";
export const POST_PRESETS_ERROR_MESSAGE =
  "An Error occurred during posting channels presets!";
export const POST_ORDER_COMMENT_ERROR_MESSAGE = "Error posting order comment";

export const CONTENT_DO_NOT_SHOW_AGAIN_ERROR_MESSAGE =
  "An error occurred when trying to disable content";
export const ADD_SLIDE_COMMENT_ERROR_MESSAGE =
  "An error occurred during adding comment!";
export const DELETE_SLIDE_COMMENT_ERROR_MESSAGE =
  "An error occurred during comment deleting!";

export const ALGOLIA_FACETS_SEARCH_ERROR_MESSAGE =
  "An error occurred during filters search!";

export const UPDATE_SAMPLES_ERROR_MESSAGE =
  "An error occurred during updating samples!";
export const IMPORT_SAMPLES_FILE_ERROR_MESSAGE =
  "An error occurred during uploading file!";
export const EXPORT_SAMPLES_FILE_ERROR_MESSAGE =
  "An error occurred during exporting samples!";
export const GET_SAMPLES_ERROR = "An error occurred during getting samples!";
export const WRONG_FILE_TYPE_ERROR_MESSAGE = "Wrong file type!";
export const FAIL_MAKE_VALID_POS_CONTROL_MESSAGE =
  "Slide Couldn't Be Marked As Valid Pos Control. Try again";
export const HALO_ANNOTATIONS_DOWNLOADING_ERROR_MESSAGE =
  "An Error occurred during exporting Halo annotations!";

export const GETTING_DATA_ERROR = "An error occurred during getting data!";

//AUTH ERRORS
export const LOGIN_ERROR_MESSAGE =
  "Error Occurred When Submitting Login Details";
export const EMAIL_VALIDATION_MESSAGE = "Email is not valid";
export const TOKEN_IS_NOT_PROVIDED_MESSAGE = "Token is not provided";

export const FAIL_GET_SLIDE_ML_DATA =
  "An error occurred during getting slide ML Data!";
