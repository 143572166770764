import { FormInputV2 } from "components/OrderForm/components/InfoFormInputs";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { Typography } from "@mui/material";

import {
  getPlaceById,
  getPlacesBySubstring,
} from "services/resources/googleMaps";
import { useOutsideClick } from "components/utilities/hooks/useClickOutside";
import { debounce } from "lodash";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useAddressInputWithAutofillingStyles } from "./styles";

export const AddressInputWithAutofilling = ({
  label,
  name,
  placeholder,
  submitSelectedAddress,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsDropdownOpen, setIsSuggestionsDropdownOpen] =
    useState(false);
  const { classes } = useAddressInputWithAutofillingStyles();
  const { showWarning } = useSnackbar();

  const suggestionsRef = useRef(null);
  useOutsideClick(suggestionsRef, () => setIsSuggestionsDropdownOpen(false));

  const fetchSuggestions = useCallback((event) => {
    const substring = event.target.value;

    if (substring.length > 2) {
      setIsSuggestionsDropdownOpen(true);

      getPlacesBySubstring(substring)
        .then((response) => {
          setSuggestions(response.data.predictions);
        })
        .catch(() => showWarning("Error occurred during fetching suggestions"));
    }
  }, []);

  const getSuggestions = useMemo(() => {
    return debounce(fetchSuggestions, 300);
  }, [fetchSuggestions]);

  const fetchAddressByPlaceId = (placeId) => {
    setIsSuggestionsDropdownOpen(false);

    getPlaceById(placeId)
      .then((response) => {
        submitSelectedAddress(response.data?.result);
      })
      .catch(() => showWarning("Error occurred during fetching place"));
  };

  return (
    <>
      <FormInputV2
        name={name}
        formLabel={label}
        formPlaceholder={placeholder}
        formInput="text"
        checkmark
        onKeyUp={getSuggestions}
      />
      {isSuggestionsDropdownOpen && (
        <div ref={suggestionsRef} className={classes.suggestionsDropdown}>
          {suggestions?.length ? (
            suggestions.map((suggestion) => {
              return (
                <div
                  key={suggestion.place_id}
                  className={classes.suggestion}
                  onClick={() => fetchAddressByPlaceId(suggestion.place_id)}
                >
                  <Typography className={classes.address}>
                    {suggestion.description}
                  </Typography>
                </div>
              );
            })
          ) : (
            <div className={classes.suggestion}>
              <Typography className={classes.address}>
                We Were Unable to Locate This Address
              </Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
};
