import { FormInputV2 } from "components/OrderForm/components/InfoFormInputs";

export const SlideGroupNameInput = (props) => {
  const { name, register, errors, touched, submitCount, checkmark, validated } =
    props;

  return (
    <FormInputV2
      name={name}
      formLabel={""}
      formPlaceholder={"Name"}
      formInput={"text"}
      register={register}
      errors={errors}
      touched={touched}
      checkmark={checkmark}
      submitCount={submitCount}
      validated={validated}
    />
  );
};
