import { makeStyles } from "tss-react/mui";
import { PRIMARY_BLUE } from "utilities/colors";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    background: `${PRIMARY_BLUE}16`,
    padding: "0.25rem 2rem",
  },
  disabledTabHeader: {
    filter: "blur(4px)",
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  tab: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.75rem 0rem",
    cursor: "pointer",
    width: "6.4rem",
    fontSize: "0.875rem",
    textTransform: "lowercase",
    boxSizing: "border-box",

    [theme.breakpoints.down("xl")]: {
      width: "4rem",
    },

    [theme.breakpoints.down("lg")]: {
      width: "3rem",
    },
  },
  activeTab: {
    borderRadius: "0.375rem",
    border: "4px solid #8897F3",
    opacity: 0.6,
    background: "#FFF",
    padding: "0.5rem 0",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    fontWeight: 700,
  },
  disabledTab: {
    cursor: "not-allowed",
    opacity: 0.3,
  },
  label: {
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
}));
