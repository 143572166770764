import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React from "react";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  helpIcon: {
    marginLeft: "0.25rem",
    width: "1.25rem",
    height: "1.25rem",
    color: theme.palette.primary.main,
    verticalAlign: "middle",
  },
}));

export const Help = ({ text, iconClassName }) => {
  const { classes } = useStyles();
  const className = iconClassName || classes.helpIcon;

  return (
    <Tooltip title={text}>
      <HelpOutlineIcon className={className} />
    </Tooltip>
  );
};
