import { Grid, Typography } from "@mui/material";
import { SkeletonGrid } from "components/common/SkeletonGrid/SkeletonGrid";
import React, { useState } from "react";
import { transformDataUsage } from "components/UserProfile/utilities";
import { MB } from "components/UserProfile/constants";
import { TeamMembersModal } from "components/UserProfile/Modals/TeamMembersModal";
import { useHistory } from "react-router";
import { ORDERS_LIST_URL } from "constants/urls";
import { useTeamOverviewStyles } from "components/UserProfile/styles";
import { FINALIZED_ORDER_LABEL } from "components/OrdersList/constants";
import { HASH_PARAMS_DELIMITER } from "utilities/constants";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const TeamOverviewContent = ({ team }) => {
  const { classes } = useTeamOverviewStyles();

  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
  const history = useHistory();

  const dataUsageLabel = transformDataUsage(team?.total_storage_used, MB);

  const redirectToOrdersPage = () => {
    const ordersPageUrl = `${ORDERS_LIST_URL}#${FINALIZED_ORDER_LABEL}${HASH_PARAMS_DELIMITER}${team.uuid}`;
    history.push(ordersPageUrl);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        paddingX={3}
        paddingY={1}
        columns={12}
        className={classes.row}
      >
        <Grid xs={2} item>
          <Typography fontWeight={500}>TOTAL ORDERS:</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={500}>DATA USAGE:</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={500}>H&E SLIDE COUNT:</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={500}>IHC SLIDE COUNT:</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={500}>IF SLIDE COUNT:</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={500}>TEAM MEMBERS:</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" paddingX={3} paddingY={1} columns={12}>
        <SkeletonGrid
          isReady={team}
          xs={2}
          item
          display="flex"
          alignItems="center"
          flexDirection="row"
        >
          <Typography onClick={redirectToOrdersPage} className={classes.link}>
            {team?.total_orders_count}
          </Typography>
          <OpenInNewIcon
            fontSize="smaller"
            className={classes.linkIcon}
            opacity={0.6}
          />
        </SkeletonGrid>
        <SkeletonGrid isReady={team} xs={2} item>
          <Typography>{dataUsageLabel}</Typography>
        </SkeletonGrid>
        <SkeletonGrid isReady={team} xs={2} item>
          <Typography width={"max-content"}>
            {team?.total_he_slides_count}
          </Typography>
        </SkeletonGrid>
        <SkeletonGrid isReady={team} xs={2} item>
          <Typography width={"max-content"}>
            {team?.total_ihc_slides_count}
          </Typography>
        </SkeletonGrid>
        <SkeletonGrid isReady={team} xs={2} item>
          <Typography width={"max-content"}>
            {team?.total_if_slides_count}
          </Typography>
        </SkeletonGrid>
        <SkeletonGrid
          isReady={team}
          xs={2}
          item
          display="flex"
          alignItems="center"
          flexDirection="row"
        >
          <Typography
            className={classes.link}
            onClick={() => setIsMembersModalOpen(true)}
          >
            {team?.total_members_count}
          </Typography>
          <OpenInNewIcon
            fontSize="smaller"
            className={classes.linkIcon}
            opacity={0.6}
          />
        </SkeletonGrid>
      </Grid>
      {!!team && (
        <TeamMembersModal
          open={isMembersModalOpen}
          team={team}
          onClose={() => setIsMembersModalOpen(false)}
        />
      )}
    </>
  );
};
