import React, { forwardRef, useImperativeHandle, useState } from "react";
import Select, { components } from "react-select";

const Option = ({ innerProps, ...props }) => {
  const { selectProps, value } = props;
  const tooltip = selectProps.tooltips?.find(
    (tooltip) => tooltip.value === value
  );

  const extendedInnerProps = {
    ...innerProps,
    ...(!!tooltip && { title: tooltip.title }),
  };

  return <components.Option innerProps={extendedInnerProps} {...props} />;
};

export const ReactSelectEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => true,
  }));

  return (
    <div style={{ width: props.column.actualWidth }}>
      <Select
        isMulti
        value={value}
        onChange={setValue}
        getOptionValue={(option) => option}
        getOptionLabel={(option) => option}
        options={props.values}
        components={{ Option }}
        tooltips={props.tooltips}
      />
    </div>
  );
});
