import {
  DELETE_ANNOTATIONS_ERROR_MESSAGE,
  DELETE_ANNOTATIONS_SUCCESS_MESSAGE,
  EXTERNAL_ANNOTATIONS_SWITCH_FIELD,
  FILE_TYPE_ERROR_MESSAGE,
  GEOJSON_FILE_EXTENSION,
  HALO_ANNOTATION_FIELD,
  HALO_ANNOTATIONS_FILE_EXTENSION,
  IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION,
  SVS_FILE_EXTENSION,
  UPLOAD_ANNOTATIONS_ERROR_MESSAGE,
  UPLOAD_ANNOTATIONS_SUCCESS_MESSAGE,
} from "components/ImageViewer/constants";
import { updateSlide } from "services/resources/slides";
import { updateSlide as updateSlideState } from "store/slices/slidesSlice";
import { updateCurrentUserPreferences } from "services/resources/commonResources";
import { updatePreferences } from "store/slices/userDetailsSlice";

export const isMatchingFile = (slideName, fileName) =>
  slideName.replace(SVS_FILE_EXTENSION, "") ===
    fileName.replace(GEOJSON_FILE_EXTENSION, "") ||
  slideName.replace(SVS_FILE_EXTENSION, "") ===
    fileName.replace(HALO_ANNOTATIONS_FILE_EXTENSION, "") ||
  slideName.replace(SVS_FILE_EXTENSION, "") ===
    fileName.replace(IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION, "");

export const handleUploadAnnotationsAttachment = async (
  file,
  currentSlide,
  dispatch,
  preferences,
  showError,
  showSuccess
) => {
  if (!file) {
    return;
  }
  const fileExtension = file.name.split(".").pop();
  if (
    fileExtension !== "geojson" &&
    fileExtension !== "annotations" &&
    fileExtension !== "xml"
  ) {
    showError(FILE_TYPE_ERROR_MESSAGE);
    return;
  }
  const formData = new FormData();
  formData.append(HALO_ANNOTATION_FIELD, file);
  try {
    const { data } = await updateSlide(currentSlide.uuid, formData);

    if (data) {
      dispatch(updateSlideState(data));
      await updateCurrentUserPreferences({
        ...preferences,
        [EXTERNAL_ANNOTATIONS_SWITCH_FIELD]: true,
      });
      dispatch(
        updatePreferences({
          [EXTERNAL_ANNOTATIONS_SWITCH_FIELD]: true,
        })
      );
      showSuccess(UPLOAD_ANNOTATIONS_SUCCESS_MESSAGE);
    }
  } catch (error) {
    showError(UPLOAD_ANNOTATIONS_ERROR_MESSAGE);
  }
};

export const handleBatchUploadAnnotationsAttachment = async (
  files,
  dispatch,
  showError,
  showSuccess
) => {
  if (!files) {
    return;
  }

  let successCount = 0;
  let failedUploads = [];

  for (const file of files) {
    const formData = new FormData();
    formData.append(HALO_ANNOTATION_FIELD, file.data);

    try {
      const { data } = await updateSlide(file.slideUUID, formData);
      if (data) {
        dispatch(updateSlideState(data));
        successCount++;
      }
    } catch (error) {
      failedUploads.push(file.data.name);
      showError(`${UPLOAD_ANNOTATIONS_ERROR_MESSAGE}: ${file.data.name}`);
    }
  }
  if (successCount) {
    showSuccess(`${successCount} ${UPLOAD_ANNOTATIONS_SUCCESS_MESSAGE}`);
  }
  if (failedUploads.length) {
    showError(
      `Failed to upload the following annotations: ${failedUploads.join(", ")}`
    );
  }
};

export const handleDeleteAnnotationsAttachment = async (
  currentSlide,
  preferences,
  dispatch,
  setIsConfirmModalOpen,
  showError,
  showSuccess
) => {
  try {
    const { data } = await updateSlide(currentSlide.uuid, {
      [HALO_ANNOTATION_FIELD]: "",
    });
    if (data) {
      await updateCurrentUserPreferences({
        ...preferences,
        [EXTERNAL_ANNOTATIONS_SWITCH_FIELD]: false,
      });
      dispatch(updateSlideState(data));
      dispatch(
        updatePreferences({
          [EXTERNAL_ANNOTATIONS_SWITCH_FIELD]: false,
        })
      );
      setIsConfirmModalOpen(false);
      showSuccess(DELETE_ANNOTATIONS_SUCCESS_MESSAGE);
    }
  } catch (error) {
    showError(DELETE_ANNOTATIONS_ERROR_MESSAGE);
  }
};
