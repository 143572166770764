import { Button, Grid, Typography } from "@mui/material";
import { buildSubmittedUserLabel } from "components/UserProfile/utilities";
import React, { memo } from "react";
import { TextWithLimitedWidth } from "components/SlideGroups/components/TextWithLimitedWidth";
import {
  IA_ATTACHMENT,
  PATHOLOGY_CONSULTATION_ATTACHMENT,
} from "components/OrderForm/SlideServicesForm/constants";
import { useHistory } from "react-router";
import { ORDER_CHART_URL } from "constants/urls";

const compareProps = (prev, next) => {
  return prev.order === next.order && prev.isSelected === next.isSelected;
};

export const OrderInfo = memo(
  ({
    order,
    classes,
    onRowClick,
    onIFSlidesClick,
    onDefaultSlidesClick,
    onDoubleClick,
    isSelected,
    isDefaultTab,
  }) => {
    const submittedUserLabel = buildSubmittedUserLabel(
      order.submitted_user,
      order.created
    );

    const history = useHistory();
    const selectedRowClass = isSelected ? classes.selectedRow : "";
    const orderInfo = (
      <span>
        {order.project.name} / <b>{order.name}</b>
      </span>
    );

    const getAttachmentLink = (attachmentType, onClickAction) => {
      if (order[attachmentType]) {
        return (
          <Button size="small" onClick={onClickAction}>
            Link
          </Button>
        );
      }
      return "-";
    };

    const getPathologyLink = () => {
      return getAttachmentLink(PATHOLOGY_CONSULTATION_ATTACHMENT, () => {
        window.open(order.pathology_consultation_attachment, "_blank");
      });
    };

    const getIAReportLink = () => {
      return getAttachmentLink(IA_ATTACHMENT, () => {
        window.open(order.image_analysis_attachment, "_blank");
      });
    };

    const getIAChartLink = () => {
      return getAttachmentLink(IA_ATTACHMENT, () => {
        history.push(ORDER_CHART_URL.replace(":orderUUID", order.uuid));
      });
    };

    return (
      <Grid
        container
        onClick={onRowClick}
        onDoubleClick={onDoubleClick}
        className={`${classes.slideDataContainer} ${classes.slideInfoRow} ${selectedRowClass}`}
      >
        <Grid item xs={isDefaultTab ? 3 : 4}>
          <TextWithLimitedWidth
            value={orderInfo}
            containerClass={classes.commentText}
            tooltipPlacement={"bottom"}
          />
        </Grid>
        <Grid item xs={isDefaultTab ? 1 : 2}>
          <Typography textAlign="center">{order.state_label}</Typography>
        </Grid>
        <Grid item xs={isDefaultTab ? 3 : 4}>
          <Typography textAlign="center">{submittedUserLabel}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={order.slides_count ? classes.cellWithLink : ""}
          onClick={() => (order.slides_count ? onDefaultSlidesClick() : null)}
        >
          <Typography textAlign="center">{order.slides_count}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={order.if_slides_count ? classes.cellWithLink : ""}
          onClick={() => (order.if_slides_count ? onIFSlidesClick() : null)}
        >
          <Typography textAlign="center">{order.if_slides_count}</Typography>
        </Grid>
        {isDefaultTab && (
          <>
            {" "}
            <Grid item xs={1}>
              <Typography textAlign="center">{getPathologyLink()}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign="center">{getIAReportLink()}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign="center">{getIAChartLink()}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  },
  compareProps
);
