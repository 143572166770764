import { viewSelectedSlides } from "components/OrderDetails/Tabs/utilities";
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper, Switch, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { downloadMultiSlideSource } from "services/resources/slides";
import {
  MULTI_SLIDE_VIEWER_URL,
  ORDER_DETAILS_URL,
  ORDERS_SLIDE_LIST_URL,
  SLIDE_VIEWER_URL,
} from "constants/urls";
import { AppContext } from "services/context";
import { RequestPathologistModalLegacy } from "components/PathologyConsultations/OrderForm/RequestPathologistModalLegacy";
import { AllSlideListColumnDefs } from "components/utilities/AgGridCols/AgGridColumns";
import { openMultiSlideParamsNewWindow } from "components/ImageViewer/utilities";
import { slideDataSerializer } from "components/utilities/gridDataSerializers";
import {
  agGridDefaultColDefFloatingFilter,
  exportToExcelAction,
} from "components/utilities/grid";
import { useTitle } from "components/utilities/hooks/useTitle";
import { useHistory } from "react-router";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";
import {
  useLazyGetAllSlidesQuery,
  useLazyGetOnlySlidesSubmittedByUserQuery,
} from "store/apis/slidesApi";

import { useSlidesListViewStyles } from "components/SlidesList/styles";
import { FETCHING_SLIDES_ERROR_MESSAGE } from "constants/errorMessages";
import { useLazyDownloadSlideSourceQuery } from "store/apis/downloadSlideApi";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

const gridStyle = {
  height: "100%",
  width: "100%",
  backgroundColor: "transparent",
};

export const SlidesListView = () => {
  const { classes } = useSlidesListViewStyles();
  const { handleContextModalChange } = useContext(AppContext);

  const [getAllSlides] = useLazyGetAllSlidesQuery();

  const [getOnlySlidesSubmittedByUser] =
    useLazyGetOnlySlidesSubmittedByUserQuery();

  const { showError } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [gridApi, setGridApi] = useState();
  const [displayedRowCount, setDisplayedRowCount] = useState(0);
  const [showFloatingMacroImage, setShowFloatingMacroImage] = useState(false);
  // if you change this value to true don't forget to update isShowTeamOrdersAutoEnabledOnce
  // cause it probably won't be needed anymore
  const [showTeamOrders, setShowTeamOrders] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const [slidesSelected, setSlidesSelected] = useState([]);
  const [isShowTeamOrdersAutoEnabledOnce, setIsShowTeamOrdersAutoEnabledOnce] =
    useState(false);

  useAGGridOverlayControl(isLoading, rowData, gridApi);

  const [downloadSlideSource] = useLazyDownloadSlideSourceQuery();

  const unmountIgnore = useUnmountIgnore();
  const history = useHistory();
  useTitle("HistoWiz - Slides List");

  useEffect(() => {
    setIsLoading(true);
    const slidesGetter = showTeamOrders
      ? getAllSlides
      : getOnlySlidesSubmittedByUser;

    slidesGetter()
      .unwrap()
      .then((response) => {
        if (response && !unmountIgnore.current) {
          const rows = response.map(slideDataSerializer);
          setRowData(rows);
        }
      })
      .catch(() => showError(FETCHING_SLIDES_ERROR_MESSAGE))
      .finally(() => setIsLoading(false));
  }, [showTeamOrders]);

  // enabling show team orders in case if user has no personal slides
  useEffect(() => {
    if (
      isShowTeamOrdersAutoEnabledOnce ||
      showTeamOrders ||
      rowData.length ||
      isLoading
    ) {
      return;
    }

    setShowTeamOrders(true);
    setIsShowTeamOrdersAutoEnabledOnce(true);
  }, [isShowTeamOrdersAutoEnabledOnce, rowData, isLoading, showTeamOrders]);

  const onQuickFilterText = (e) => {
    setQuickFilterText(e.target.value);
  };

  const handleModelUpdated = (e) => {
    isLoading && e.api.hideOverlay();
    const rowCount = e.api.getDisplayedRowCount();
    setDisplayedRowCount(rowCount);
  };

  const handleCompareSlides = () => {
    if (gridApi) {
      const rowsSelected = gridApi.getSelectedRows();
      openMultiSlideParamsNewWindow({ rows: rowsSelected });
    }
  };

  const handleShowTeamSwitchChange = (event) => {
    !isLoading && setShowTeamOrders(event.target.checked);
  };

  const renderFloatingThumbnail = () => {
    if (!(thumbnail && showFloatingMacroImage)) {
      return null;
    }

    return (
      <div className={classes.floatingThumbnail}>
        <Paper className={classes.floatingPaper} elevation={1}>
          <img
            className={classes.floatingImage}
            src={thumbnail}
            alt={"Thumbnail"}
          />
        </Paper>
      </div>
    );
  };

  const getSingleContextMenuItems = ({ row }) => {
    const orderName = row.order.name;

    return [
      {
        name: "View Slide",
        action: () => {
          const url = SLIDE_VIEWER_URL.replace(":slideUUID", row.uuid);
          history.push(url);
        },
      },
      {
        name: `View ${orderName} Slides (All)`,
        action: () => {
          const url = ORDERS_SLIDE_LIST_URL.replace(
            ":orderUUID",
            row.order.uuid
          );
          history.push(url);
        },
      },
      {
        name: `View ${orderName} Details`,
        action: () => {
          const url = ORDER_DETAILS_URL.replace(":orderUUID", row.order.uuid);
          history.push(url);
        },
      },
      {
        name: "Download Slide",
        action: () => {
          downloadSlideSource({ slide: row });
        },
      },
    ];
  };

  const getMultiContextMenuItems = ({ rows }) => {
    return [
      {
        name: "Compare Slides",
        action: () => {
          const state = { slides: rows };
          history.push({
            pathname: MULTI_SLIDE_VIEWER_URL,
            state: state,
          });
        },
      },
      {
        name: `View ${rows?.length} Selected Slides`,
        action: () => viewSelectedSlides({ selectedRows: rows }),
      },
      {
        name: "Download Slides",
        action: () => {
          downloadMultiSlideSource({ slides: rows });
        },
      },
    ];
  };

  const getContextMenuItems = (params) => {
    if (!params.node?.data) {
      return;
    }

    const row = params.node.data;
    const rowsSelected = params.api.getSelectedRows();

    if (rowsSelected.length > 1) {
      return getMultiContextMenuItems({ rows: rowsSelected });
    } else {
      return getSingleContextMenuItems({ row });
    }
  };

  const onRowSelected = ({ api }) => {
    const rowsSelected = api.getSelectedRows();
    const thumbnail = rowsSelected[0]?.large_macro_url || "";

    setThumbnail(thumbnail);
    setSlidesSelected(rowsSelected);
  };

  const onGridReady = ({ api }) => {
    setGridApi(api);
    api.sizeColumnsToFit();
  };

  const navigateToSlideViewer = (params) => {
    const { data } = params.node;
    // if you group by something, you aren't able to navigate to the slide viewer
    if (data?.uuid) {
      const url = SLIDE_VIEWER_URL.replace(":slideUUID", data.uuid);
      history.push(url);
    }
  };

  return (
    <div className="ag-theme-balham" style={gridStyle}>
      <input
        className={classes.formInput}
        type="text"
        onChange={onQuickFilterText}
        placeholder="Filter text ..."
      />

      <Button
        variant="contained"
        color={"primary"}
        onClick={handleCompareSlides}
        className={classes.compareButton}
      >
        Compare
      </Button>

      <Button
        variant="contained"
        color={"secondary"}
        onClick={handleContextModalChange("modalPathologistConsultationOpen")}
        className={classes.compareButton}
      >
        Request Pathology Consultation
      </Button>
      <Button
        variant="contained"
        color="secondary"
        fullWidth={false}
        disabled={isLoading}
        onClick={() =>
          gridApi && exportToExcelAction(gridApi, "HistoWiz-Slides-Export")
        }
        className={classes.compareButton}
      >
        Download as Excel
      </Button>
      <span className={classes.buttonGrouping}>
        <Switch
          checked={showFloatingMacroImage}
          onChange={(e) => setShowFloatingMacroImage(e.target.checked)}
          value="showFloatingMacroImage"
        />
        Display Thumbnail{" "}
        <Switch
          checked={showTeamOrders}
          onChange={handleShowTeamSwitchChange}
        />{" "}
        Show Team Orders
      </span>
      <div>- Right Click to View A Slide</div>
      <div>
        - Hold (Shift or Cmd - Mac / Control - PC) + Right Click to Compare
        Multiple Slides
      </div>
      <Typography align={"right"}>
        Showing {displayedRowCount} of {rowData.length} Slides
      </Typography>
      {renderFloatingThumbnail()}
      <AgGridReact
        onModelUpdated={handleModelUpdated}
        enableSorting
        enableFilter
        quickFilterText={quickFilterText}
        enableColResize
        rowDragManaged
        rowGroupPanelShow="always"
        columnDefs={AllSlideListColumnDefs}
        rowData={rowData}
        // this looks bad on mobile
        //sideBar={true}
        defaultColDef={agGridDefaultColDefFloatingFilter}
        rowSelection="multiple"
        onGridReady={onGridReady}
        onCellDoubleClicked={navigateToSlideViewer}
        onRowSelected={onRowSelected}
        getContextMenuItems={getContextMenuItems}
        overlayNoRowsTemplate={"<span>No data</span>"}
        suppressNoRowsOverlay={isLoading}
      />
      <RequestPathologistModalLegacy slidesSelected={slidesSelected} />
    </div>
  );
};
