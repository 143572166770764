import {
  TEAM_BLOCK_RESOURCE_NAME,
  TEAM_BLOCKS_RESOURCE_NAME,
  TEAM_UNMERGE_SAMPLES_RESOURCE_NAME,
  TEAM_PREFERENCES,
  BLOCK_ORDER_RESOURCE_NAME,
  BLOCK_NEW_ORDER_RESOURCE_NAME,
} from "services/resources/apiConstants";
import { baseCreateApi } from "store/apis/baseCreateApi";

export const teamsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query({
      query: () => ({ url: "teams/", method: "GET" }),
    }),
    getTeamBlocks: builder.query({
      query: ({ teamUUID, internal = false }) => {
        let url = TEAM_BLOCKS_RESOURCE_NAME.replace(":teamUUID", teamUUID);

        if (internal) url += "?internal=1";

        return {
          url,
          method: "GET",
        };
      },
    }),
    unmergeBlockSamples: builder.mutation({
      query: ({ sample_uuids }) => ({
        url: TEAM_UNMERGE_SAMPLES_RESOURCE_NAME,
        method: "POST",
        body: { sample_uuids },
      }),
    }),
    updateBlock: builder.mutation({
      query: ({ teamUUID, blockUUID, body }) => ({
        url: TEAM_BLOCK_RESOURCE_NAME.replace(":teamUUID", teamUUID).replace(
          ":blockUUID",
          blockUUID
        ),
        method: "PATCH",
        body,
      }),
    }),
    createOrderWithBlocks: builder.mutation({
      query: ({ blocksUUIDs }) => ({
        url: BLOCK_NEW_ORDER_RESOURCE_NAME,
        method: "POST",
        body: {
          block_uuids: blocksUUIDs,
        },
      }),
    }),
    addBlocksToOrder: builder.mutation({
      query: ({ blocksUUIDs, orderUUID }) => ({
        url: BLOCK_ORDER_RESOURCE_NAME,
        method: "POST",
        body: {
          block_uuids: blocksUUIDs,
          order_uuid: orderUUID,
        },
      }),
    }),
    getTeamPreferences: builder.query({
      query: () => ({ url: TEAM_PREFERENCES, method: "GET" }),
    }),
    setTeamPreferences: builder.mutation({
      query: ({ body }) => ({ url: TEAM_PREFERENCES, method: "PATCH", body }),
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetTeamBlocksQuery,
  useLazyGetTeamBlocksQuery,
  useUnmergeBlockSamplesMutation,
  useUpdateBlockMutation,
  useGetTeamPreferencesQuery,
  useSetTeamPreferencesMutation,
  useCreateOrderWithBlocksMutation,
  useAddBlocksToOrderMutation,
} = teamsApi;
