import { makeStyles } from "tss-react/mui";

export const useOrderActivityViewStyles = makeStyles()(() => ({
  root: {
    width: "100%",
  },
  slideCard: {
    width: "100%",
    height: "min-content",
    padding: "1rem",
    marginTop: "0.5rem",
  },
  orderName: {
    fontSize: "20px",
    marginBottom: "8px",
    fontWeight: "500",
  },
  comments: {
    flexDirection: "column",
    width: "100%",
    marginLeft: "16px",
  },
  noCommentsLabel: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "max-content",
  },
  commentLabel: {
    fontSize: "16px",
    marginBottom: "8px",
    fontWeight: "500",
  },
  commentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
