import React from "react";
import { List } from "@mui/material";

import { useSidebarStyles } from "components/Layout/Sidebar/styles";

export const SidebarList = ({ children }) => {
  const { classes } = useSidebarStyles();
  const { list } = classes;

  return <List className={list}>{children}</List>;
};
