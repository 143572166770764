import { makeStyles } from "tss-react/mui";
import { PATHOLOGY_MAP_TRANSPARENT_BLACK } from "components/PathologyMap/constants/styles";

export const usePathologyMapSlideCardContentStyles = makeStyles()({
  imageWrapper: {
    "&:hover": {
      cursor: "pointer",
    },
    marginBottom: "-6px",
  },
  contentWrapper: {
    padding: "0 0.5rem 0.5rem",
  },
});

export const usePathologyMapSlideCardHeaderStyles = makeStyles()({
  header: {
    padding: "0.5rem 0.75rem 0.5rem 1rem",
  },
});

export const usePathologyMapSlideCardItemStyles = makeStyles()({
  slideCardsContainer: {
    backgroundColor: "white",
    borderColor: PATHOLOGY_MAP_TRANSPARENT_BLACK,
    borderRadius: "3px",
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_TRANSPARENT_BLACK}`,
    width: "100%",
  },
});

export const usePathologyMapSlideCardItemsStyles = makeStyles()({
  snackbar: {
    minWidth: "200px !important",
  },
});
