import { makeStyles } from "tss-react/mui";

export const useBillingShippingStyles = makeStyles()((theme) => ({
  selectForm: {
    marginBottom: "1rem",
    marginTop: "2rem",
    marginRight: "2rem",
    width: "100%",
  },
  dropboxSelectForm: {
    marginBottom: "1rem",
    paddingBottom: "2rem",
    marginTop: "1rem",
    marginRight: "2rem",
  },
  rightAlignButton: {
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  container: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formItem: {
    marginLeft: "auto",
    marginRight: "auto",
    flexBasis: "95%",
  },
  saveButton: {
    marginTop: "3rem",
    textAlign: "right",
    textTransform: "uppercase",
  },
  changeAddressButton: {
    marginTop: "0.5rem",
    height: "2.5rem",
  },
  legacyFormButton: {
    marginTop: "3rem",
    textTransform: "uppercase",
    height: "2.5rem",
  },
  page: {
    marginBottom: "1rem",
  },
  textField: {
    margin: ".5rem",
  },
  closeModalButton: {
    padding: theme.spacing(2),
    backgroundColor: "#109B10",
    color: "white",
  },
  buttonTypography: { marginTop: "1rem" },
  paper: {
    padding: theme.spacing(2),
    border: "2px solid #ccc",
    color: theme.palette.text.secondary,
    //backgroundColor: "gray"
  },
  modalPaper: {
    width: "600px",
  },
  addressContainer: {
    marginTop: "6px",
  },
}));

export const useShippingLabelsStyles = makeStyles()(() => ({
  labelsTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem",
  },
}));
