export const QC_AI_MODELS = {
  ML_QC_BLURRY_DATA: "ml_qc_blurry_data",
  ML_QC_FOLDS_TEARS_DATA: "ml_qc_folds_data",
  ML_QC_BLADE_MARKS_DATA: "ml_qc_blade_marks_data",
  ML_QC_DIRTY_DATA: "ml_qc_dirty_data",
  ML_QC_STAIN_DATA: "ml_qc_stain_data",
  ML_QC_COVERSLIP_DATA: "ml_qc_coverslip_data",
  ML_QC_TISSUE_SEPARATION_DATA: "ml_qc_tissue_separation_data",
  ML_QC_MICROVIBRATIONS_DATA: "ml_qc_microvibrations_data",
  ML_QC_TISSUE_CRACKING_DATA: "ml_qc_tissue_cracking_data",
  ML_QC_FLOATERS_DATA: "ml_qc_floaters_data",
  ML_QC_CUT_DEEPER_DATA: "ml_qc_cut_deeper_data",
  ML_QC_SCANNER_ARTIFACTS_DATA: "ml_qc_scanner_artifacts_data",
};

export const TISSUE_AI_MODELS = {
  ML_TUMOR_DATA: "ml_tumor_data",
  ML_STROMA_DATA: "ml_stroma_data",
  ML_INFLAMMATION_DATA: "ml_inflammation_data",
  ML_TISSUE_DATA: "ml_tissue_data",
  ML_NECROSIS_DATA: "ml_necrosis_data",
};

export const TISSUE_MODELS_ITEM_MENU_OPTIONS = [
  {
    name: "Tumor",
    color: "rgba(255, 0, 0, 0.35)",
    value: TISSUE_AI_MODELS.ML_TUMOR_DATA,
  },
  {
    name: "Stroma",
    color: "rgba(152, 245, 90, 0.35)",
    value: TISSUE_AI_MODELS.ML_STROMA_DATA,
  },
  {
    name: "Inflammation",
    color: "rgba(245, 90, 201, 0.35)",
    value: TISSUE_AI_MODELS.ML_INFLAMMATION_DATA,
  },
  {
    name: "Tissue",
    color: "rgba(0, 0, 255, 0.35)",
    value: TISSUE_AI_MODELS.ML_TISSUE_DATA,
  },
  {
    name: "Necrosis",
    color: "rgba(255, 213, 0, 0.35)",
    value: TISSUE_AI_MODELS.ML_NECROSIS_DATA,
  },
];

export const QC_MODELS_ITEM_MENU_OPTIONS = [
  { name: "Blurry", value: QC_AI_MODELS.ML_QC_BLURRY_DATA },
  {
    name: "Tissue Seperation",
    value: QC_AI_MODELS.ML_QC_TISSUE_SEPARATION_DATA,
  },
  { name: "Blade Marks", value: QC_AI_MODELS.ML_QC_BLADE_MARKS_DATA },
  { name: "Coverslip", value: QC_AI_MODELS.ML_QC_COVERSLIP_DATA },
  { name: "Cut Deeper", value: QC_AI_MODELS.ML_QC_CUT_DEEPER_DATA },
  { name: "Dirty", value: QC_AI_MODELS.ML_QC_DIRTY_DATA },
  { name: "Floaters", value: QC_AI_MODELS.ML_QC_FLOATERS_DATA },
  {
    name: "Scanner Artifacts",
    value: QC_AI_MODELS.ML_QC_SCANNER_ARTIFACTS_DATA,
  },
  { name: "Folds", value: QC_AI_MODELS.ML_QC_FOLDS_TEARS_DATA },
  { name: "Stain", value: QC_AI_MODELS.ML_QC_STAIN_DATA },
  { name: "Microvibrations", value: QC_AI_MODELS.ML_QC_MICROVIBRATIONS_DATA },
  { name: "Tissue Cracking", value: QC_AI_MODELS.ML_QC_TISSUE_CRACKING_DATA },
];

export const DEFAULT_QC_MODELS_OPACITY = 100;
export const DEFAULT_TISSUE_MODELS_OPACITY = 35;

export const AI_MODEL_CHANGE_ACTIONS = {
  ADD: "add",
  REMOVE: "remove",
};

export const AI_MODEL_EVENTS = {
  OPACITY_CHANGE: "modelOpacityChange",
};

export const SHAPE_DRAW_TYPES = {
  FILL: "fill",
};

export const QC_SCORE_COLORS = {
  DEFAULT: "default",
  FAILED: "failed",
  PASSED: "passed",
};

export const AI_MODELS_STATES = {
  NA: "n/a",
  REQUESTED: "requested",
  FAILED: "failed",
  NOT_FOUND: "not_found",
  EXISTS: "exists",
};
