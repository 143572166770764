import { Box, Typography } from "@mui/material";
import {
  blockActions,
  blockActionsIcons,
} from "components/BlocksList/constants";
import { useBlocksPageV2Styles } from "components/BlocksList/styles";

export const BlocksActionHeaderTab = ({
  tab,
  activeTab,
  setActiveTab,
  getTabCounter,
}) => {
  const { classes } = useBlocksPageV2Styles();
  const isTabDisabled = (tab) => tab === activeTab;

  const tabClassName = (tab) =>
    blockActions[tab] === activeTab
      ? `${classes.tab} ${classes.activeTab}`
      : classes.tab;

  const handleTabClick = (tab) => {
    if (!isTabDisabled(tab)) {
      setActiveTab(tab);
    }
  };

  const tabValue = blockActions[tab];

  return (
    <Box
      className={`${tabClassName(tab)} ${
        activeTab === tabValue ? classes.disabledTab : ""
      }`}
      key={tab}
      title={tabValue}
      onClick={() => handleTabClick(tabValue)}
      disabled={isTabDisabled(tabValue)}
    >
      {blockActionsIcons[tab]}
      <Box className={classes.tabCounter}>{getTabCounter(tabValue)}</Box>
      <Typography component="span" className={classes.label}>
        {tabValue}
      </Typography>
    </Box>
  );
};
