/** @jsxRuntime classic */
/** @jsx jsx */

import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { LOGIN_URL } from "constants/urls";
import { Fragment, useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { resetPassword } from "services/resources/auth";
import { jsx, css } from "@emotion/react";
import {
  RESET_PASSWORD_HELPER_TEXT,
  RESET_PASSWORD_PAGE_TITLE,
} from "components/ResetPassword/constants";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useResetPasswordPageStyles } from "./styles";
import { isEmailValid } from "utilities/auth";
import { EMAIL_VALIDATION_MESSAGE } from "constants/errorMessages";

const _ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    document.title = RESET_PASSWORD_PAGE_TITLE;
  }, []);

  const history = useHistory();

  const { showSuccess, showError } = useSnackbar();

  const { classes } = useResetPasswordPageStyles();

  const onChange = ({ target: { value } }) => {
    setEmailError(false);
    setEmail(value);
  };

  const resetAccount = () => {
    const isValid = isEmailValid(email);
    if (!isValid) {
      setEmailError(true);
      showError(EMAIL_VALIDATION_MESSAGE);
      return;
    }

    const postParams = {
      email,
    };

    resetPassword({ postParams })
      .then((response) => {
        if (response.status === 200) {
          showSuccess("Account has been reset, please check your email!");

          history.push(LOGIN_URL);
        }
      })
      .catch(({ response, message }) => {
        const errorMessage = response?.data?.email || message;
        showError(errorMessage);
      });
  };

  return (
    <Paper className={classes.paperContainer}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
        spacing={0}
      >
        <Grid item xs={12} sm={9} md={6} lg={3}>
          <Paper>
            <Paper className={classes.loginHeader}>
              <Typography align={"center"} variant={"h5"} color={"inherit"}>
                Reset Password
              </Typography>
            </Paper>
            <Grid container justifyContent="center" alignItems="center">
              <Fragment>
                <TextField
                  id="email"
                  label="Email"
                  type={"text"}
                  className={classes.input}
                  helperText={RESET_PASSWORD_HELPER_TEXT}
                  value={email}
                  error={emailError}
                  onChange={onChange}
                  margin="normal"
                />
                <div
                  css={css`
                    margin: 2rem;
                    width: 80%;
                  `}
                >
                  <Button
                    css={css`
                      float: right;
                    `}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={resetAccount}
                    disabled={!email || emailError}
                  >
                    Reset Account
                  </Button>
                </div>
              </Fragment>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const ResetPasswordPage = withRouter(_ResetPasswordPage);
