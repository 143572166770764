import "react-responsive-carousel/lib/styles/carousel.min.css";
import { splitOrders } from "components/OrdersList/utilities/helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useTitle } from "components/utilities/hooks/useTitle";
import { setUserToTeam } from "services/resources/orders";

import {
  FinishedOrdersTable,
  InProgressOrdersTable,
  UnsubmittedOrdersTable,
} from "components/OrdersList/OrderTables";
import {
  FINALIZED_ORDER_LABEL,
  ignoreOrderStates,
  IN_PROGRESS_ORDER_LABEL,
  UNSUBMITTED_ORDER_LABEL,
  VALID_ORDERS_LIST_TAB_VALUES,
} from "components/OrdersList/constants";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";

import { Box, Grid, Switch, Tab, Tabs, Typography } from "@mui/material";

import { GoToOrderComponent } from "components/OrdersList/GoToOrderComponent";
import { useLocation } from "react-router-dom";
import { useGetOrdersQuery } from "store/apis/orderApi";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import {
  FETCH_ORDERS_ERROR_MESSAGE,
  USER_ADDED_TO_TEAM_ERROR_MESSAGE,
} from "constants/errorMessages";
import { useSelector } from "react-redux";
import { currentUserEmailSelector } from "store/slices/userDetailsSlice";
import { getPathologyConsultationOrders } from "services/resources/pathologyConsultations";
import {
  defaultSpinnerThemeStyle,
  Spinner,
} from "components/Loading/Spinner/Spinner";

import {
  finishedConsultationStates,
  unsubmittedConsultationStates,
} from "components/PathologyConsultations/constants";

import { ConditionalComponent } from "components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent";
import { getTabValueByHash, getTeamUUIDFromHash } from "utilities/general";
import { USER_ADDED_TO_TEAM_SUCCESS_MESSAGE } from "constants/successMessages";
import { useTabsNavigation } from "utilities/hooks/useTabsNavigation/useTabsNavigation";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { OrderTeamsSelector } from "components/common/FilterOrdersByTeam/OrderTeamsSelector";
import { useGetTeamsQuery } from "store/apis/teamsApi";
import { filterOrdersByTeam } from "components/UserProfile/utilities";
import { useOrderListViewStyles } from "./styles";
import { InProgressPathologyConsultationsTable } from "components/PathologyConsultations/InProgressPathologyConsultationsTable";

export const OrdersListView = () => {
  useTitle("HistoWiz - Orders List");

  const { data: teams } = useGetTeamsQuery();
  const [selectedTeams, setSelectedTeams] = useState([]);

  const { classes } = useOrderListViewStyles();
  const { showSuccess, showError } = useSnackbar();
  const location = useLocation();
  const email = useSelector(currentUserEmailSelector);

  const getTeamInviteUUID = () => {
    const queryString = require("query-string");
    const params = queryString.parse(location.search);
    return params.team_user_invite_uuid;
  };

  const [state, setState] = useState({
    showTeamOrders: true,
    navigationOrderID: "",
    teamInviteUUID: getTeamInviteUUID(),
  });

  const [consultations, setConsultations] = useState({
    finishedConsultations: [],
    unsubmittedConsultations: [],
    inProgressConsultations: [],
  });

  const [tabValue, setTabValue] = useState(
    getTabValueByHash(
      location.hash,
      VALID_ORDERS_LIST_TAB_VALUES,
      FINALIZED_ORDER_LABEL
    )
  );

  useTabsNavigation(tabValue);

  const {
    data: ordersData,
    isLoading: isOrdersLoading,
    error,
  } = useGetOrdersQuery();
  useQueryError(error, FETCH_ORDERS_ERROR_MESSAGE);

  const filteredOrders = useMemo(
    () => filterOrdersByTeam(ordersData, selectedTeams),
    [ordersData, selectedTeams]
  );
  const orders = useMemo(() => splitOrders(filteredOrders), [filteredOrders]);
  useEffect(() => {
    const locationHashTabValue = getTabValueByHash(
      location.hash,
      VALID_ORDERS_LIST_TAB_VALUES,
      FINALIZED_ORDER_LABEL
    );

    if (tabValue === locationHashTabValue) {
      return;
    }

    setTabValue(locationHashTabValue);
  }, [location]);

  useEffect(() => {
    if (!teams) return;

    const teamUUIDFromHash = getTeamUUIDFromHash(location.hash);

    let teamFromHash = null;

    if (teamUUIDFromHash) {
      teamFromHash = teams.find((team) => team.uuid === teamUUIDFromHash);
    }

    setSelectedTeams(teamFromHash ? [teamFromHash] : teams);
  }, [teams]);

  useEffect(() => {
    getPathologyConsultationOrders()
      .then((data) => {
        const unsubmittedConsultations = data.filter((consultation) => {
          return finishedConsultationStates.includes(consultation.state);
        });

        const finishedConsultations = data.filter((consultations) => {
          return finishedConsultationStates.includes(consultations.state);
        });

        const inProgressConsultations = data.filter((consultations) => {
          return (
            !finishedConsultationStates.includes(consultations.state) &&
            !unsubmittedConsultationStates.includes(consultations.state) &&
            !ignoreOrderStates.includes(consultations.state)
          );
        });

        setConsultations({
          finishedConsultations: finishedConsultations,
          unsubmittedConsultations: unsubmittedConsultations,
          inProgressConsultations: inProgressConsultations,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (state.teamInviteUUID !== undefined) {
      setUserToTeam(state.teamInviteUUID)
        .then(() => showSuccess(USER_ADDED_TO_TEAM_SUCCESS_MESSAGE))
        .catch(() => showError(USER_ADDED_TO_TEAM_ERROR_MESSAGE));
    }
  }, [state.teamInviteUUID]);

  const TabContainer = (props) => {
    return (
      <Typography component="div" className={classes.tabContainerStylePadding}>
        {props.children}
      </Typography>
    );
  };

  const handleTabChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const handleSwitchChange = (name) => (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.target.checked,
      };
    });
  };

  let filteredInProgressOrders, filteredFinishedOrders;
  if (state.showTeamOrders) {
    filteredInProgressOrders = orders.inProgressOrders;
    filteredFinishedOrders = orders.finishedOrders;
  } else {
    filteredInProgressOrders = orders.inProgressOrders.filter((data) => {
      // if the user matches or the submitted_user was blank
      return !data.submitted_user || data.submitted_user.email === email;
    });
    filteredFinishedOrders = orders.finishedOrders.filter((data) => {
      // if the user matches or the submitted_user was blank
      return !data.submitted_user || data.submitted_user.email === email;
    });
  }

  const unsubmittedLabel = `Unsubmitted Orders (${orders.unsubmittedOrders.length})`;
  const inProgressLabel = `Orders in Progress (${filteredInProgressOrders.length})`;
  const finalizedLabel = `Finished Orders (${filteredFinishedOrders.length})`;

  const renderTabContents = () => {
    return (
      <>
        <ConditionalComponent condition={tabValue === UNSUBMITTED_ORDER_LABEL}>
          <TabContainer>
            <UnsubmittedOrdersTable orders={orders.unsubmittedOrders} />
          </TabContainer>
        </ConditionalComponent>
        <ConditionalComponent condition={tabValue === IN_PROGRESS_ORDER_LABEL}>
          <TabContainer>
            <Box mb={3}>
              <InProgressOrdersTable
                orders={filteredInProgressOrders}
                consultations={consultations.inProgressConsultations}
              />
            </Box>
            <Box mt={1} mb={3}>
              <InProgressPathologyConsultationsTable
                consultations={consultations.inProgressConsultations}
              />
            </Box>
          </TabContainer>
        </ConditionalComponent>
        <ConditionalComponent condition={tabValue === FINALIZED_ORDER_LABEL}>
          <TabContainer>
            <FinishedOrdersTable
              orders={filteredFinishedOrders}
              consultations={consultations.finishedConsultations}
            />
          </TabContainer>
        </ConditionalComponent>
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor={"primary"}
          >
            <Tab value={UNSUBMITTED_ORDER_LABEL} label={unsubmittedLabel} />
            <Tab value={IN_PROGRESS_ORDER_LABEL} label={inProgressLabel} />
            <Tab value={FINALIZED_ORDER_LABEL} label={finalizedLabel} />
          </Tabs>
          <Box display="flex" flexDirection="row">
            <Box mt={3} mr={2}>
              <Switch
                size="small"
                checked={state.showTeamOrders}
                onChange={handleSwitchChange("showTeamOrders")}
              />{" "}
              Show Team Orders
            </Box>
            {teams?.length > 1 && (
              <OrderTeamsSelector
                teams={teams}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
              />
            )}
          </Box>
        </Grid>
        <Grid item>
          <ShowStaffUserOnly>
            <GoToOrderComponent />
          </ShowStaffUserOnly>
        </Grid>
      </Grid>
      {/*{renderTabContents()}*/}
      {/*turn back off before launch, right now easier to see changes*/}
      {isOrdersLoading ? (
        <div style={defaultSpinnerThemeStyle}>
          <Spinner />
        </div>
      ) : (
        renderTabContents()
      )}
    </>
  );
};
