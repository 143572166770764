import React, { useRef } from "react";
import {
  ACTIVITY_TAB_VALUE,
  BILLING_AND_SHIPPING_TAB_VALUE,
  CONSOLIDATED_TAB_VALUE,
  EMBEDDING_AND_CUTTING_TAB_VALUE,
  IMAGE_ANALYSIS_PATHOLOGY_CONSULTATION_TAB_VALUE,
  ORDER_PROGRESS_TAB_VALUE,
  PROCESSED_IF_SLIDES_TAB_VALUE,
  PROCESSED_SLIDES_TAB_VALUE,
  SAMPLES_DETAILS_TAB_VALUE,
  SUMMARY_TAB_VALUE,
} from "components/OrderDetails/constants";
import { OrderActivityTab } from "components/OrderDetails/Tabs/OrderActivityTab";
import { IFSlidesTab } from "components/OrderDetails/Tabs/IFSlidesTab";
import ReactToPrint from "react-to-print";
import { Typography } from "@mui/material";
import { ConsolidatedTabInstructions } from "components/OrderDetails/Tabs/ConsolidatedTabInstructions";
import { SampleDetailsTab } from "components/OrderDetails/Tabs/SampleDetailsTab";
import { ProcessedSlidesTab } from "components/OrderDetails/Tabs/ProcessedSlidesTab";
import { EmbeddingAndCuttingInstructionsTab } from "components/OrderDetails/Tabs/EmbeddingAndCuttingInstructionsTab";
import { ImageAnalysisAndPathologyConsultationTab } from "components/OrderDetails/Tabs/ImageAnalysisAndPathologyConsultationTab";
import { BillingAndShippingTab } from "components/OrderDetails/Tabs/BillingAndShippingTab";
import { TabContainer } from "components/OrderDetails/Tabs/utilities";
import { OrderSummaryTab } from "components/OrderDetails/Tabs/OrderSummaryTab";
import { ConditionalComponent } from "components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import { OrderProgressTab } from "components/OrderDetails/Tabs/OrderProgressTab/OrderProgressTab";

export const TabContents = (props) => {
  const {
    tabValue,
    classes,
    isStaff,
    order,
    samples,
    ifSlides,
    shipments,
    slides,
    isSlidesLoading,
    isIFSlidesLoading,
    showFailedQCSlides,
    quickFilterText,
    onQuickFilterText,
    handleToggleShowFailedQCSlides,
    notUploadedSlides,
    attachments,
    onFilesDrop,
  } = props;
  const componentRef = useRef(null);

  return (
    <div>
      <ConditionalComponent condition={tabValue === SUMMARY_TAB_VALUE}>
        <TabContainer>
          <OrderSummaryTab
            order={order}
            samples={samples}
            attachments={attachments}
            onFilesDrop={onFilesDrop}
          />
        </TabContainer>
      </ConditionalComponent>

      <ConditionalComponent condition={tabValue === ACTIVITY_TAB_VALUE}>
        <TabContainer>
          <OrderActivityTab
            order={order}
            samples={samples}
            attachments={attachments}
            onFilesDrop={onFilesDrop}
          />
        </TabContainer>
      </ConditionalComponent>

      <ConditionalComponent condition={tabValue === PROCESSED_SLIDES_TAB_VALUE}>
        <TabContainer>
          <ProcessedSlidesTab
            classes={classes}
            slides={slides}
            isSlidesLoading={isSlidesLoading}
            showFailedQCSlides={showFailedQCSlides}
            isStaff={isStaff}
            order={order}
            quickFilterText={quickFilterText}
            onQuickFilterText={onQuickFilterText}
            handleToggleShowFailedQCSlides={handleToggleShowFailedQCSlides}
            notUploadedSlides={notUploadedSlides}
          />
        </TabContainer>
      </ConditionalComponent>
      <ConditionalComponent
        condition={tabValue === PROCESSED_IF_SLIDES_TAB_VALUE}
      >
        <TabContainer>
          <IFSlidesTab
            order={order}
            ifSlides={ifSlides}
            isIFSlidesLoading={isIFSlidesLoading}
          />
        </TabContainer>
      </ConditionalComponent>
      <ConditionalComponent
        condition={tabValue === BILLING_AND_SHIPPING_TAB_VALUE}
      >
        <TabContainer>
          <BillingAndShippingTab order={order} shipments={shipments} />
        </TabContainer>
      </ConditionalComponent>

      <ConditionalComponent
        condition={tabValue === IMAGE_ANALYSIS_PATHOLOGY_CONSULTATION_TAB_VALUE}
      >
        <TabContainer>
          <ImageAnalysisAndPathologyConsultationTab order={order} />
        </TabContainer>
      </ConditionalComponent>

      <ConditionalComponent
        condition={tabValue === EMBEDDING_AND_CUTTING_TAB_VALUE}
      >
        <TabContainer>
          <EmbeddingAndCuttingInstructionsTab order={order} />
        </TabContainer>
      </ConditionalComponent>

      <ConditionalComponent condition={tabValue === CONSOLIDATED_TAB_VALUE}>
        <TabContainer>
          <ReactToPrint
            trigger={() => (
              /* eslint-disable-next-line*/
              <a href="#" style={{ textAlign: "right" }}>
                <Typography variant={"subtitle1"}>Print this page!</Typography>
              </a>
            )}
            content={() => componentRef.current}
          />
          <ConsolidatedTabInstructions
            order={order}
            samples={samples}
            shipments={shipments}
            classes={classes}
            ref={componentRef}
          />
          <ProcessedSlidesTab
            classes={classes}
            slides={slides}
            isSlidesLoading={isSlidesLoading}
            showFailedQCSlides={showFailedQCSlides}
            isStaff={isStaff}
            order={order}
            quickFilterText={quickFilterText}
            onQuickFilterText={onQuickFilterText}
            handleToggleShowFailedQCSlides={handleToggleShowFailedQCSlides}
            notUploadedSlides={notUploadedSlides}
          />
        </TabContainer>
      </ConditionalComponent>

      <ShowStaffUserOnly>
        <ConditionalComponent
          condition={tabValue === SAMPLES_DETAILS_TAB_VALUE}
        >
          <TabContainer>
            <SampleDetailsTab samples={samples} />
          </TabContainer>
        </ConditionalComponent>
      </ShowStaffUserOnly>

      <ConditionalComponent condition={tabValue === ORDER_PROGRESS_TAB_VALUE}>
        <TabContainer>
          <OrderProgressTab order={order} samples={samples} />
        </TabContainer>
      </ConditionalComponent>
    </div>
  );
};
