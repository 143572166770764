import { CreditCardSnackbar } from "components/Modals/CreditCardSnackbar";
import { CreatedFedExSnackbar } from "components/Modals/FedexSnackbar";
import { BillingTabs } from "components/OrderForm/BillingShipping/BillingTabs/BillingTabs";
import { useBillingShippingView } from "components/OrderForm/BillingShipping/hooks/useBillingShippingView";
import { ShippingDetails } from "components/OrderForm/BillingShipping/ShippingDetails/ShippingDetails";
import { ShippingMethod } from "components/OrderForm/BillingShipping/ShippingMethod/ShippingMethod";
import { ShippingTabs } from "components/OrderForm/BillingShipping/ShippingTabs/ShippingTabs";
import { DemoUserWarnIfEmailMatchDemo } from "components/OrderForm/components/DemoUserOrderWarning";
import { Grid, Typography } from "@mui/material";
import {
  FedexDimensionsModal,
  QuoteAttachmentSection,
} from "components/OrderForm/BillingShipping/BillingShippingInputs";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { AdditionalQuestionsForm } from "components/OrderForm/BillingShipping/AdditionalQuestions/AdditionalQuestionsForm";

export const BillingShippingView = () => {
  const { classes } = useBillingShippingStyles();
  const {
    order,
    shippingLabels,
    email,
    handleModalChange,
    shippingOption,
    submitFedExComponent,
    setShippingOption,
    shippingDimensionsModal,
  } = useBillingShippingView();

  return (
    <>
      {" "}
      {order && (
        <div className={classes.page}>
          <DemoUserWarnIfEmailMatchDemo email={email} />
          <Typography variant={"h5"} gutterBottom>
            You can submit an order without a PO, and will receive a quote after
            order submission. However, orders can only be started with a valid
            Credit Card or PO number.
          </Typography>
          <Typography variant={"h5"} gutterBottom>
            Please review our{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://home.histowiz.com/terms/"
            >
              terms
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://storage.googleapis.com/production-histowiz-public/website/HistoWiz%20Sample%20Preparation%20and%20Shipping%20Instructions.pdf"
            >
              shipping instructions
            </a>
            . You can edit your order and attach the signed quote or PO anytime
            before the order is started.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QuoteAttachmentSection />
            </Grid>
            <Grid item xs={8}>
              <BillingTabs />
              <ShippingTabs />
            </Grid>
            <Grid item xs={8}>
              <ShippingMethod
                shippingLabels={shippingLabels}
                handleModalChange={handleModalChange}
              />
            </Grid>
            <Grid item xs={8}>
              <ShippingDetails
                shippingLabels={shippingLabels}
                shippingOption={shippingOption}
                submitFedExComponent={submitFedExComponent}
                handleModalChange={handleModalChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              <AdditionalQuestionsForm />
            </Grid>
          </Grid>
          <FedexDimensionsModal
            isOpen={shippingDimensionsModal}
            onClose={handleModalChange("shippingDimensionsModal")}
            shippingOption={shippingOption}
            setShippingOption={setShippingOption}
          />
          <CreatedFedExSnackbar />
          <CreditCardSnackbar />
        </div>
      )}
    </>
  );
};
