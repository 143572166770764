import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import React from "react";
import { TextWithLimitedWidth } from "components/SlideGroups/components/TextWithLimitedWidth";
import { useSlideActions } from "components/PathologyMap/Modals/FullSlideInfoModal/hooks";

export const FullSlideModalBreadcrumb = ({ slide, classes }) => {
  const { viewSlide } = useSlideActions(slide);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      paddingX={4}
      width={"100%"}
    >
      <Typography
        color={"textSecondary"}
        className={classes.breadcrumbPathTextClass}
      >
        order / name
      </Typography>
      <TextWithLimitedWidth
        textClass={classes.breadcrumbTextClass}
        containerClass={classes.breadcrumbContainerClass}
        value={
          <>
            {slide.order.id} /
            <b className={classes.slideName} onClick={viewSlide}>
              {` ${slide.name}`}
            </b>
          </>
        }
      />
    </Box>
  );
};
