import { viewSelectedSlides } from "components/OrderDetails/Tabs/utilities";
import { backendURL } from "services/backendAPI";
import { ORDER_DETAILS_URL, SLIDE_VIEWER_URL } from "constants/urls";
import { downloadMultiSlideSource } from "services/resources/slides";
import { openMultiSlideParamsNewWindow } from "components/ImageViewer/utilities";
import { useLazyDownloadSlideSourceQuery } from "store/apis/downloadSlideApi";

export const useGetSingleContextMenuItems = () => {
  const [downloadSlideSource] = useLazyDownloadSlideSourceQuery();

  const getMenuContext = ({
    row,
    isStaff,
    isBetaFeaturesEnabled,
    history,
    handleAddSlideToGroupClick,
    setIsExportHaloAnnotationsModalOpen,
    handleRemoveSlideFromGroupClick,
  }) => {
    const lisaSlideLink = `${backendURL}/lisa/core/slide/${row.id}/change/`;

    const menuContext = [
      {
        name: "View Slide Details",
        action: () => {
          if (row) {
            const url = SLIDE_VIEWER_URL.replace(":slideUUID", row.uuid);
            history.push(url);
          }
        },
      },
      {
        name: "View Slide Details (New Tab)",
        action: () => {
          if (row) {
            const url = SLIDE_VIEWER_URL.replace(":slideUUID", row.uuid);
            window.open(url);
          }
        },
      },
      {
        name: "View Order Details",
        action: () => {
          if (row) {
            const url = ORDER_DETAILS_URL.replace(":orderUUID", row.order.uuid);
            history.push(url);
          }
        },
      },
      {
        name: "Download Slide",
        action: () => {
          if (row) {
            downloadSlideSource({ slide: row });
          }
        },
      },
    ];

    if (isStaff) {
      menuContext.push(
        {
          name: `Export Annotations To Halo Format For Selected Slide`,
          action: () => {
            setIsExportHaloAnnotationsModalOpen(true);
          },
        },
        {
          name: "View Slide (LISA)",
          action: () => {
            if (row) {
              window.open(lisaSlideLink, "_blank");
            }
          },
        }
      );
    }

    if (isStaff || isBetaFeaturesEnabled) {
      menuContext.push(
        {
          name: "Add Slide to Group",
          action: () => {
            if (row && handleAddSlideToGroupClick) {
              handleAddSlideToGroupClick(row);
            }
          },
        },
        {
          name: "Remove Slide from Group",
          action: () => {
            if (row && handleRemoveSlideFromGroupClick) {
              handleRemoveSlideFromGroupClick(row);
            }
          },
        }
      );
    }

    return menuContext;
  };

  return getMenuContext;
};

export const getMultiContextMenuItems = ({
  rows,
  isStaff,
  setIsExportHaloAnnotationsModalOpen,
}) => {
  let menuContext = [
    {
      name: "Download Slides",
      action: () => {
        downloadMultiSlideSource({ slides: rows });
      },
    },
    {
      name: "View Slide Details (New Tab)",
      action: () => {
        if (rows) {
          rows.forEach((row) => {
            const url = SLIDE_VIEWER_URL.replace(":slideUUID", row.uuid);
            window.open(url, "_blank", "noopener");
          });
        }
      },
    },
    {
      name: "Compare Selected Slides (New Tab)",
      action: () => {
        if (rows) {
          openMultiSlideParamsNewWindow({ rows });
        }
      },
    },
    {
      name: `View ${rows?.length} Selected Slides (New Tab)`,
      action: () => viewSelectedSlides({ selectedRows: rows }),
    },
  ];

  if (isStaff) {
    menuContext.push({
      name: `Export Annotations To Halo Format For ${rows?.length} Slides`,
      action: () => {
        setIsExportHaloAnnotationsModalOpen(true);
      },
    });
  }
  return menuContext;
};
