import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { TEAM_OVERVIEW_URL } from "constants/urls";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { AppContext } from "services/context";
import { useLazyGetUserDetailsQuery } from "store/apis/userDetailsApi";
import { TOKEN_IS_NOT_PROVIDED_MESSAGE } from "constants/errorMessages";

export const LoginTokenPage = ({ match }) => {
  const { showError } = useSnackbar();
  const context = useContext(AppContext);
  const [getUserDetails] = useLazyGetUserDetailsQuery();

  useEffect(() => {
    const token = match.params.token;
    if (token) {
      localStorage.token = token;
      context.updateIsLoggedIn();
      getUserDetails();
    } else {
      showError(TOKEN_IS_NOT_PROVIDED_MESSAGE);
    }
  }, []);

  return <Redirect to={TEAM_OVERVIEW_URL} />;
};
