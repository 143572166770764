import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isStaffSelector } from "store/slices/userDetailsSlice";

export const useFilteredComments = (comments) => {
  const isStaff = useSelector(isStaffSelector);

  return useMemo(
    () =>
      isStaff ? comments : comments?.filter((comment) => !comment.is_internal),
    [comments, isStaff]
  );
};
