import {
  BLANKET_PURCHASE_ORDER_TAB_VALUE,
  EXISTING_BILLING_INFO_TAB_VALUE,
  PO_NUMBER_INFO_TAB_VALUE,
} from "components/OrderForm/BillingShipping/utilities/constants";
import {
  serializeBillingContact,
  serializeTeamAddress,
} from "components/OrderForm/BillingShipping/utilities/serializers";
import { getLatestBillingContact } from "components/OrderForm/BillingShipping/utilities/utilities";
import {
  BILLING_TYPE_BLANKET_PURCHASE_ORDER,
  SHIPPING_OPTIONS_DETAILS,
} from "components/OrderForm/constants";
import {
  FETCH_BILLING_CONTACTS_ERROR_MESSAGE,
  POST_BILLING_CONTACTS_ERROR_MESSAGE,
} from "constants/errorMessages";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getBillingContacts,
  postBillingContact,
} from "services/resources/commonResources";
import {
  setBillingContactSelected,
  setDropboxSelected,
  setSelectedBlanketPurchase,
  setShippingAddressSelected,
  setShippingMethodSelected,
  billingShippingSelectors,
} from "store/slices/billingShippingSlice/billingShippingSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useBillingTabs = () => {
  const { showError } = useSnackbar();
  const [billingContacts, setBillingContacts] = useState([]);
  const [billingTabSelected, setBillingTabSelected] = useState(
    PO_NUMBER_INFO_TAB_VALUE
  );

  const { order, teamUUID } = useSelector(
    billingShippingSelectors.billingTabs,
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleBillingTabChange = (event, value) => {
    setBillingTabSelected(value);
  };

  const updateBillingContacts = useCallback((useLatest = false) => {
    getBillingContacts()
      .then((response) => {
        const responseSerialized = response.map((data) => {
          const isCreditCard = data.credit_card;
          const billingLabel = isCreditCard ? data.credit_card.brand : "PO#";
          const billingDetails = isCreditCard
            ? data.credit_card.last4
            : data["po_number"];
          const billingLabelDetailed = `${billingLabel} ${billingDetails}`;

          data["label"] = `${data["name"]} - ${billingLabelDetailed}`;
          data["value"] = data["uuid"];
          return data;
        });

        setBillingContacts(responseSerialized);

        if (useLatest) {
          const lastValue = getLatestBillingContact(responseSerialized);

          dispatch(setBillingContactSelected(lastValue));
        }
      })
      .catch(() => showError(FETCH_BILLING_CONTACTS_ERROR_MESSAGE));
  }, []);

  const handleAddBillingSuccess = (billingContact) => {
    updateBillingContacts();

    setBillingTabSelected(EXISTING_BILLING_INFO_TAB_VALUE);
    dispatch(setBillingContactSelected(billingContact));
  };

  const onBlanketPurchaseOrderSelect = (purchaseOrder) => {
    dispatch(setSelectedBlanketPurchase(purchaseOrder));

    const billingContact = billingContacts.find(
      (contact) =>
        contact.billing_type === BILLING_TYPE_BLANKET_PURCHASE_ORDER &&
        contact.purchase_order?.uuid === purchaseOrder.uuid
    );

    const postParams = {
      team_uuid: teamUUID,
      name: purchaseOrder.number,
      email: "",
      phone_number: "",
      purchase_order_uuid: purchaseOrder.uuid,
      billing_type: BILLING_TYPE_BLANKET_PURCHASE_ORDER,
    };

    if (!!billingContact) {
      dispatch(setBillingContactSelected(billingContact));
    } else {
      postBillingContact(postParams)
        .then((response) => {
          const billingContactSelected = serializeBillingContact({
            billingContact: response,
          });

          dispatch(setBillingContactSelected(billingContactSelected));
        })
        .catch(() => showError(POST_BILLING_CONTACTS_ERROR_MESSAGE));
    }
  };

  const handleCreditCardUpdate = () => {
    setBillingTabSelected(EXISTING_BILLING_INFO_TAB_VALUE);
    updateBillingContacts(true);
  };

  useEffect(() => {
    updateBillingContacts();
  }, [updateBillingContacts]);

  useEffect(() => {
    if (order.dropbox) {
      const dropboxSelected = {
        label: order.dropbox.name,
        value: order.dropbox.uuid,
        uuid: order.dropbox.uuid,
      };
      dispatch(setDropboxSelected(dropboxSelected));
    }

    if (order.billing_contact) {
      const newBillingContact = serializeBillingContact({
        billingContact: order.billing_contact,
      });

      dispatch(setBillingContactSelected(newBillingContact));

      if (
        newBillingContact.billing_type === BILLING_TYPE_BLANKET_PURCHASE_ORDER
      ) {
        dispatch(setSelectedBlanketPurchase(newBillingContact));
      }

      setBillingTabSelected(
        newBillingContact.billing_type === BILLING_TYPE_BLANKET_PURCHASE_ORDER
          ? BLANKET_PURCHASE_ORDER_TAB_VALUE
          : EXISTING_BILLING_INFO_TAB_VALUE
      );
    }

    if (order.address_from) {
      const address = serializeTeamAddress({
        address: order.address_from,
      });
      dispatch(setShippingAddressSelected(address));
    }

    if (order.shipment_method) {
      const shippingMethodSelected = {
        label: SHIPPING_OPTIONS_DETAILS[order.shipment_method],
        value: order.shipment_method,
      };

      dispatch(setShippingMethodSelected(shippingMethodSelected));
    }
  }, []);

  return {
    billingTabSelected,
    handleBillingTabChange,
    teamUUID,
    handleAddBillingSuccess,
    onBlanketPurchaseOrderSelect,
    handleCreditCardUpdate,
    billingContacts,
  };
};
