import React from "react";
import { useParams } from "react-router";

import { useSerializedSpecies } from "components/OrderForm/Info/hooks/useSerializedSpecies";
import { useTitle } from "components/utilities/hooks/useTitle";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import {
  HISTOWIZ_PHAZES,
  INVESTIGATORS,
  STUDY_STATUSES,
} from "components/GlpForm/helpers/constants";
import { useGlpFormStyles } from "components/GlpForm/styles";
import {
  useGlpForm,
  useGlpFormSubmit,
  useGlpOrder,
  useGlpSearchData,
} from "components/GlpForm/helpers/hooks";
import { GlpSelect } from "components/GlpForm/GlpSelect";
import { GlpDate } from "components/GlpForm/GlpDate";
import { GlpFooter } from "components/GlpForm/GlpFooter";
import { GlpText } from "components/GlpForm/GlpText";
import { GlpSearch } from "components/GlpForm/GlpSearch";
import { GlpHeader } from "components/GlpForm/GlpHeader";

export const GlpForm = () => {
  const { orderUUID } = useParams();
  const { glpOrder } = useGlpOrder(orderUUID);
  const { fields, handleField } = useGlpForm(glpOrder);
  const { selectableSpecies } = useSerializedSpecies();
  const { articles, organizations } = useGlpSearchData();
  const { handleSubmit, isLoading } = useGlpFormSubmit(orderUUID, fields);
  const { classes } = useGlpFormStyles();
  const { form, section } = classes;
  const { order } = glpOrder ?? {};
  const { id } = order ?? {};

  const title = `GLP Order Form ${id ? ` for Order ${id}` : ""}`;

  useTitle(title);

  return (
    <MiniDrawerWithContext header={title}>
      <form onSubmit={handleSubmit} className={form}>
        <GlpHeader glpOrder={glpOrder} />
        <section className={section}>
          <GlpSelect
            title="Test System"
            value={fields.test_system}
            options={selectableSpecies.map(({ label }) => label)}
            onChange={(value) => handleField("test_system", value)}
            disabled
          />
          <GlpSearch
            title="Test Article"
            placeholder="Waiting BE for this field..."
            value={fields.test_article}
            onChange={(value) => handleField("test_article", value)}
            items={articles}
            freeInput
          />
          <GlpDate
            onChange={(value) => handleField("date_of_1st_dosing", value)}
            value={fields.date_of_1st_dosing}
            title="Date of 1st Dosing"
          />
          <GlpDate
            onChange={(value) => handleField("study_initiation_date", value)}
            value={fields.study_initiation_date}
            title="Date Study Initiated"
          />
          <GlpDate
            onChange={(value) => handleField("shipping_date", value)}
            value={fields.shipping_date}
            title="Shipping Date to HistoWiz"
          />
          <GlpSelect
            title="Current Status of Study"
            value={fields.current_status_of_each_study}
            options={STUDY_STATUSES}
            onChange={(value) =>
              handleField("current_status_of_each_study", value)
            }
          />
          <GlpSelect
            title="HistoWiz Phase"
            value={fields.histowiz_phase}
            options={HISTOWIZ_PHAZES}
            onChange={(value) => handleField("histowiz_phase", value)}
          />
          <GlpSelect
            title="Principal Investigator"
            value={fields.principal_investigator}
            options={INVESTIGATORS}
            onChange={(value) => handleField("principal_investigator", value)}
          />
          <GlpText
            title="Name of Study Director"
            placeholder="Waiting BE for this field..."
            value={fields.name_of_study_director}
            onChange={(value) => handleField("name_of_study_director", value)}
          />
          <GlpSearch
            title="Identity of Sponsor"
            placeholder="Organization behind the order…"
            value={fields.identity_of_sponsor}
            onChange={(value) => handleField("identity_of_sponsor", value)}
            items={organizations}
          />
          <GlpText
            title="Purpose of Study"
            placeholder="Enter purpose of study…"
            value={fields.purpose_of_study}
            isMultiline
            onChange={(value) => handleField("purpose_of_study", value)}
            disabled={!!order?.purpose_of_study}
          />
          <GlpText
            title="Nature of Study"
            placeholder="Enter nature of study ie. toxicology, 90-day inhalation, 2-year carcinogenicity, etc."
            value={fields.nature_of_study}
            isMultiline
            onChange={(value) => handleField("nature_of_study", value)}
          />
        </section>
        <GlpFooter disabled={isLoading} />
      </form>
    </MiniDrawerWithContext>
  );
};
