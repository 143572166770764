import { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  getClearedFiltersAttributes,
  getHighlightedWords,
} from "components/PathologyMap/utilities/common";
import {
  SLIDE_DATA_COPY_TEXT,
  SLIDE_DATA_COPY_TOGGLE_DELAY,
} from "components/PathologyMap/constants/common";
import { HighlightedText } from "components/Shared/HighlightedText";
import { DefaultPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography";
import {
  PATHOLOGY_MAP_SLIDE_CARD_INFO_CONTENT_TYPOGRAPHY_PROPS,
  PATHOLOGY_MAP_SLIDE_CARD_INFO_TITLE_TYPOGRAPHY_PROPS,
  useSlideInfoForPathologyMapStyles,
} from "components/PathologyMap/constants/styles";

export const PathologyMapSlideCardInfo = ({
  title,
  content,
  attribute,
  slide,
  selectedFilters,
}) => {
  const { classes } = useSlideInfoForPathologyMapStyles();

  const [isCopyStatusVisible, setIsCopyStatusVisible] = useState(false);

  const highlightedValues = useMemo(() => {
    return getHighlightedWords(slide, attribute);
  }, []);

  const clearedFiltersAttributes = getClearedFiltersAttributes(selectedFilters);
  const isSelected = clearedFiltersAttributes.includes(attribute);

  const handleCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      setIsCopyStatusVisible(true);
      setTimeout(
        () => setIsCopyStatusVisible(false),
        SLIDE_DATA_COPY_TOGGLE_DELAY
      );
    });
  };

  const isHighlightingAvailable = !isSelected && highlightedValues.length;
  const dataContent = isHighlightingAvailable ? (
    <HighlightedText text={content} highlightList={highlightedValues} />
  ) : (
    content
  );
  const shownContent = isCopyStatusVisible ? SLIDE_DATA_COPY_TEXT : dataContent;

  return (
    <Grid
      container
      direction="row"
      className={`${classes.slideDataContainer} ${
        isSelected && classes.selectedSlideData
      }`}
      justifyContent="space-between"
      alignItems="center"
      onClick={handleCopy}
    >
      <Grid item xs={3}>
        <DefaultPathologyMapTypography
          {...PATHOLOGY_MAP_SLIDE_CARD_INFO_TITLE_TYPOGRAPHY_PROPS}
        >
          {title}
        </DefaultPathologyMapTypography>
      </Grid>
      <Grid item xs>
        <DefaultPathologyMapTypography
          {...PATHOLOGY_MAP_SLIDE_CARD_INFO_CONTENT_TYPOGRAPHY_PROPS}
        >
          {shownContent}
        </DefaultPathologyMapTypography>
      </Grid>
    </Grid>
  );
};
