import { SLIDES_LIST_HEADER_HEIGHT } from "components/OrderSlidesList/OrderSlidesListViewV2Styles";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()({
  actionsContainer: {
    display: "grid",
    gridTemplateRows: "50% 50%",
    height: "100%",
    maxHeight: `calc(100vh - ${SLIDES_LIST_HEADER_HEIGHT})`,

    "&.slideList": {
      gridTemplateRows: "100%",
    },
  },
  collapseButton: {
    minWidth: "unset",
    padding: 0,
  },
});
