import { makeStyles } from "tss-react/mui";

export const useDropboxesViewStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: "wrap",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
