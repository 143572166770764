import React, { useState, Fragment } from "react";
import { Button } from "@mui/material";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { ADD_SLIDE_COMMENT_ERROR_MESSAGE } from "constants/errorMessages";
import { KEYS } from "constants/keyboard";
import { useCreateSlideComment } from "components/PathologyMap/Modals/FullSlideInfoModal/hooks";
import { useCommentInputStyles } from "components/PathologyMap/Modals/FullSlideInfoModal/styles";
import {
  COMMENT_INPUT_PLACEHOLDER_TEXT,
  EXTERNAL_COMMENT_INPUT_WARNING,
  INTERNAL_COMMENT_INPUT_WARNING,
} from "components/PathologyMap/Modals/FullSlideInfoModal/constants";

export const CommentInput = ({ slideUUID, isInternal, isStaff }) => {
  const { classes } = useCommentInputStyles();
  const [commentText, setCommentText] = useState("");
  const { createSlideComment } = useCreateSlideComment();
  const { showError } = useSnackbar();

  const prefix = isInternal
    ? INTERNAL_COMMENT_INPUT_WARNING
    : EXTERNAL_COMMENT_INPUT_WARNING;
  const placeholder = isStaff
    ? prefix + COMMENT_INPUT_PLACEHOLDER_TEXT
    : COMMENT_INPUT_PLACEHOLDER_TEXT;

  const createComment = () => {
    createSlideComment(slideUUID, commentText, isInternal && isStaff)
      .unwrap()
      .then(() => {
        setCommentText("");
      })
      .catch(() => showError(ADD_SLIDE_COMMENT_ERROR_MESSAGE));
  };

  const onTextareaKeyPress = (event) => {
    if (event.key === KEYS.ENTER) {
      createComment();
    }
  };

  return (
    <Fragment>
      <textarea
        placeholder={placeholder}
        value={commentText}
        name={"comment_text"}
        rows={3}
        className={classes.input}
        onKeyPress={onTextareaKeyPress}
        onChange={(e) => setCommentText(e.target.value)}
      />
      <Button
        disabled={!commentText.length}
        variant={"contained"}
        className={classes.submitButton}
        onClick={createComment}
      >
        Submit
      </Button>
    </Fragment>
  );
};
