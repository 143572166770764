import { Checkbox } from "@mui/material";

export const CheckboxSamples = ({ checked, handler, id }) => (
  <Checkbox
    size="small"
    checked={checked}
    value="check-sample"
    onChange={handler}
    style={{ padding: 0 }}
    id={id}
  />
);
