import { Box, Typography } from "@mui/material";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { Check } from "@mui/icons-material";
import React from "react";
import {
  getColorIndicatorStyle,
  getSelectedAIModelCheckIconStyle,
  getSelectedAIModelItemContainerBGColor,
  getSelectedAIModelItemContainerStyle,
  getSelectedAIModelTextStyle,
  useSelectableItemStyles,
} from "components/AITools/constants/styles";

export const SlideAIModelSelectableItem = ({
  name,
  color,
  handleChange,
  isSelected,
  isDisabled,
  isLoading,
}) => {
  const { classes } = useSelectableItemStyles();

  const handleClick = (e) => {
    !isDisabled && handleChange(e);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={1}
      p={1}
      bgcolor={getSelectedAIModelItemContainerBGColor(
        isSelected,
        isDisabled,
        isLoading
      )}
      style={getSelectedAIModelItemContainerStyle(isDisabled, isLoading)}
      onClick={handleClick}
    >
      <Typography
        className={classes.selectableItem}
        style={getSelectedAIModelTextStyle(isDisabled)}
      >
        {color && <span style={getColorIndicatorStyle(color)} />}
        {name}
      </Typography>
      {isLoading ? (
        <Spinner size={12} color="secondary" />
      ) : (
        <Check
          style={getSelectedAIModelCheckIconStyle(isSelected, isDisabled)}
          fontSize="1rem"
        />
      )}
    </Box>
  );
};
