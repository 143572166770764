import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";

import { InProgressConsultationOrderTableHeader } from "components/PathologyConsultations/PathologyConsultationTableHeaders";
import { PathologyConsultationTable } from "components/PathologyConsultations/PathologyConsultationTable";

export const InProgressPathologyConsultationsTable = ({ consultations }) => {
  return (
    <Fragment>
      <Box mt={1}>
        <Typography variant="h6" align={"left"} color={"primary"}>
          Pathology Consultations (In Progress)
        </Typography>
      </Box>
      <PathologyConsultationTable
        consultations={consultations}
        tableHeader={InProgressConsultationOrderTableHeader}
      />
    </Fragment>
  );
};
