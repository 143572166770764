import React from "react";
import {
  DEFAULT_MODAL_SIZE,
  LARGE_MODAL_SIZE,
  SIMPLE_MODAL_SIZE,
} from "components/Modals/constants";
import { Modal } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const overflowStyle = {
  overflow: "auto",
};
const getBaseStyles = (theme) => {
  return {
    position: "absolute",
    "z-index": 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  };
};

const defaultModalStyles = (theme) => ({
  modalPaper: {
    ...getBaseStyles(theme),
  },
  limitedHeight: {
    maxHeight: "93vh",
    overflowY: "auto",
    padding: "0.5rem",
  },
});

const largeModalStyles = (theme) => ({
  modalPaper: {
    width: theme.spacing(70),
    ...getBaseStyles(theme),
  },
});

const simpleModalStyles = (theme) => ({
  modalPaper: {
    ...getBaseStyles(theme),
  },
});

const customModalStyles = (theme, width) => ({
  modalPaper: {
    width,
    ...getBaseStyles(theme),
  },
});

const getStyles = (size, width) => {
  const styles = width
    ? (theme) => customModalStyles(theme, width)
    : {
        [DEFAULT_MODAL_SIZE]: defaultModalStyles,
        [LARGE_MODAL_SIZE]: largeModalStyles,
        [SIMPLE_MODAL_SIZE]: simpleModalStyles,
      }[size];

  return styles || defaultModalStyles;
};

export const DefaultModal = ({
  onClose,
  open,
  size = DEFAULT_MODAL_SIZE,
  children,
  width = null,
  isLimitedHeight,
  styles,
  ...props
}) => {
  const useStyles = makeStyles()(getStyles(size, width));
  const { classes } = useStyles();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
      width={width}
      style={overflowStyle}
      {...props}
    >
      <div
        className={`${classes.modalPaper} ${
          isLimitedHeight ? classes.limitedHeight : ""
        }`}
        style={styles}
      >
        {children}
      </div>
    </Modal>
  );
};
