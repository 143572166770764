import Grid from "@mui/material/Grid";
import { Waypoint } from "react-waypoint";
import {
  useCurrentRefinements,
  useInfiniteHits,
  useStats,
} from "react-instantsearch-hooks-web";
import { MORE_SLIDES_FOUND_SNACKBAR_HIDE_DURATION } from "components/PathologyMap/constants/common";
import { ALGOLIA_MORE_SLIDES_FOUND_SUCCESS_MESSAGE } from "constants/successMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { usePathologyMapSlideCardItemsStyles } from "./styles";
import { getPathologyMapItemsView } from "components/PathologyMap/utilities/constants";

export const PathologyMapSlideItems = ({ view }) => {
  const { hits, isLastPage, showMore } = useInfiniteHits();
  const { classes } = usePathologyMapSlideCardItemsStyles();
  const { showSuccess } = useSnackbar();
  const { items } = useCurrentRefinements();

  const { nbHits } = useStats();

  const onWaypointEnter = () => {
    if (isLastPage) {
      return;
    }
    showSuccess(ALGOLIA_MORE_SLIDES_FOUND_SUCCESS_MESSAGE, {
      action: null,
      autoHideDuration: MORE_SLIDES_FOUND_SNACKBAR_HIDE_DURATION,
      disableWindowBlurListener: true,
      className: classes.snackbar,
    });
    showMore();
  };

  const View = getPathologyMapItemsView(view);

  return (
    <Grid container spacing={3} alignItems="stretch">
      <View hits={hits} nbHits={nbHits} items={items} />
      <Waypoint onEnter={onWaypointEnter} />
    </Grid>
  );
};
