import React, { useCallback, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { agGridDefaultColDefFloatingFilter } from "components/utilities/grid";

import { Box, Typography } from "@mui/material";
import { backendURL } from "services/backendAPI";
import { useBaseConsultationsAgGridStyles } from "./styles";
import { PathologistWorklistColumnDefs } from "components/PathologyConsultations/AgGridColumns/PathologistWorklistColumnDefs";

const titleMargin = 2;

export const GRID_CONTAINER_STYLE = {};

export const BaseConsultationsAgGrid = ({
  consultations,
  title,
  userDetails,
}) => {
  const { classes } = useBaseConsultationsAgGridStyles();
  const { isStaff } = userDetails;
  const [, setGridApi] = useState();

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      params.api.sizeColumnsToFit();
      params.api.suppressNoRowsOverlay = true;
    },
    [setGridApi]
  );

  const getContextMenuItems = (params) => {
    const row = params?.node?.data;

    if (!row || !isStaff) {
      return [];
    }

    const rowID = row?.id;
    const lisaLink = `${backendURL}/lisa/core/pathologyconsultationorder/${row.id}/change/`;

    const result = [
      {
        name: "LIMs URL (Pathology Consultation)",
        action: () => {
          if (rowID) {
            window.open(lisaLink, "_blank");
          }
        },
      },
    ];

    // not all consultations have lab orders attached, so optionally render
    if (row?.order?.id) {
      const orderLink = `${backendURL}/lisa/core/order/${row.order.id}/change/`;
      result.push({
        name: "LIMs URL (Order)",
        action: () => {
          if (rowID) {
            window.open(orderLink, "_blank");
          }
        },
      });
    }

    return result;
  };

  if (!consultations) {
    return null;
  }

  return (
    <div className={classes.worklistStyle}>
      <Box mt={titleMargin}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Box>
      <div className={classes.worklistStyle}>
        <div style={GRID_CONTAINER_STYLE} className="ag-theme-balham">
          <AgGridReact
            domLayout={"autoHeight"}
            defaultColDef={agGridDefaultColDefFloatingFilter}
            rowSelection={"single"}
            rowData={consultations}
            columnDefs={PathologistWorklistColumnDefs}
            onGridReady={onGridReady}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </div>
    </div>
  );
};
