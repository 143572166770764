import {
  H_AND_E_SERVICE,
  IF_SCAN_ONLY_SERVICE,
  IMAGE_ANALYSIS_SERVICE,
  PATHOLOGY_CONSULTATION_SERVICE,
  SCAN_ONLY_SERVICE,
  UNSTAINED_SERVICE,
} from "components/OrderForm/constants";
import {
  H_AND_E_TYPE,
  IF_SERVICE_TYPE,
  IHC_SERVICE_TYPE,
  REQUESTED_H_AND_E_STAIN_COUNT,
  REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT,
  REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT,
  REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT,
  REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT,
  REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT,
  REQUESTED_SLIDE_SCANNING_ONLY_COUNT,
  REQUESTED_UNSTAINED_COUNT,
  SPECIAL_STAIN_TYPE,
  UNSTAINED_TYPE,
} from "components/OrderForm/SlideServicesForm/constants";
import {
  usePostSampleAntibodiesRequestedMutation,
  usePostSamplePanelsRequestedMutation,
  usePostSampleServicesRequestedMutation,
  usePostSampleSpecialStainsRequestedMutation,
} from "store/apis/samplesApi";

export const getRowsFromScanOnly = (data, service, serviceType) => {
  const results = data.map((sampleDetails) => {
    return {
      count: sampleDetails[service],
      serviceType,
      type: serviceType,
      sample_uuid: sampleDetails.uuid,
      name: sampleDetails.name,
      pathology_consultation_count: 0,
      image_analysis_count: 0,
    };
  });

  // Unstained slides shouldn't be visible
  const validResults = results.filter((data) => {
    return data.count > 0;
  });

  return validResults;
};

export const getRowsFromSimpleSamples = (data) => {
  const results = data.map(
    ({
      requested_h_and_e_stain_count,
      uuid,
      name,
      requested_h_and_e_stain_pathology_consultation_count,
      requested_h_and_e_stain_image_analysis_count,
    }) => {
      return {
        count: requested_h_and_e_stain_count,
        serviceType: H_AND_E_SERVICE,
        type: H_AND_E_SERVICE,
        sample_uuid: uuid,
        name,
        pathology_consultation_count:
          requested_h_and_e_stain_pathology_consultation_count,
        image_analysis_count: requested_h_and_e_stain_image_analysis_count,
      };
    }
  );

  return results.filter((data) => {
    return data.count > 0;
  });
};

export const getRowsFromUnstained = (data) => {
  const results = data.map(({ requested_unstained_count, uuid, name }) => {
    return {
      count: requested_unstained_count,
      serviceType: UNSTAINED_SERVICE,
      type: UNSTAINED_SERVICE,
      sample_uuid: uuid,
      name,
      pathology_consultation_count: 0,
      image_analysis_count: 0,
    };
  });

  // Unstained slides shouldn't be visible
  return results.filter((data) => {
    return data.count > 0;
  });
};

export const getRowsFromAntibodies = (data) => {
  return data.map(
    ({
      count,
      antibody: { display_name, uuid: antibody_uuid },
      sample: { uuid: sample_uuid, name },
      pathology_consultation_count,
      image_analysis_count,
    }) => {
      return {
        count,
        serviceType: IHC_SERVICE_TYPE,
        type: display_name,
        sample_uuid,
        antibody_uuid,
        name,
        pathology_consultation_count,
        image_analysis_count,
      };
    }
  );
};

export const getRowsFromSpecialStains = (data) => {
  return data.map(
    ({
      count,
      special_stain: { name: type, uuid: special_stain_uuid },
      sample: { uuid, name },
      pathology_consultation_count,
      image_analysis_count,
    }) => {
      return {
        count,
        serviceType: SPECIAL_STAIN_TYPE,
        type,
        sample_uuid: uuid,
        special_stain_uuid,
        name,
        pathology_consultation_count,
        image_analysis_count,
      };
    }
  );
};

export const getRowsFromSamplePanels = (data) => {
  const result = data.map(
    ({
      count,
      panel: { display_name: type, uuid: panel_uuid },
      sample: { name, uuid: sample_uuid },
      pathology_consultation_count,
      image_analysis_count,
    }) => {
      return {
        count,
        serviceType: IF_SERVICE_TYPE,
        type,
        sample_uuid,
        panel_uuid,
        name,
        pathology_consultation_count,
        image_analysis_count,
      };
    }
  );
  return result;
};

export const getRowsFromIAOnly = (data) => {
  const results = data.map((sampleDetails) => {
    return {
      count: sampleDetails[REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT],
      serviceType: IMAGE_ANALYSIS_SERVICE,
      type: IMAGE_ANALYSIS_SERVICE,
      sample_uuid: sampleDetails.uuid,
      name: sampleDetails.name,
    };
  });

  // Unstained slides shouldn't be visible
  const validResults = results.filter((data) => {
    return data.count > 0;
  });

  return validResults;
};

export const getRowsFromPCOnly = (data) => {
  const results = data.map((sampleDetails) => {
    return {
      count: sampleDetails[REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT],
      serviceType: PATHOLOGY_CONSULTATION_SERVICE,
      type: PATHOLOGY_CONSULTATION_SERVICE,
      sample_uuid: sampleDetails.uuid,
      name: sampleDetails.name,
    };
  });

  // Unstained slides shouldn't be visible
  const validResults = results.filter((data) => {
    return data.count > 0;
  });

  return validResults;
};

export const getIsPathologyConsultationRequestedCount = (data) => {
  return data.filter((e) => e.requested_pathology_consultation).length;
};

export const getIsPathologyConsultationRequestedHandECount = (data) => {
  return data.filter(
    (e) => e.requested_h_and_e_stain_pathology_consultation_count
  ).length;
};

export const getIsImageAnalysisRequestedCount = (data) => {
  return data.filter((e) => e.requested_image_analysis).length;
};

export const getIsImageAnalysisRequestedHandECount = (data) => {
  return data.filter((e) => e.requested_h_and_e_stain_image_analysis_count)
    .length;
};

export const useSubmitRegularSlides = () => {
  const [postSampleServicesRequested] =
    usePostSampleServicesRequestedMutation();

  const submitRegularSlides = (rows, orderUUID) => {
    // this lies at the same end point, so consolidate the updates in one go
    const regularSlidesTypes = [
      H_AND_E_SERVICE,
      UNSTAINED_SERVICE,
      SCAN_ONLY_SERVICE,
      IF_SCAN_ONLY_SERVICE,
      IMAGE_ANALYSIS_SERVICE,
      PATHOLOGY_CONSULTATION_SERVICE,
    ];

    const regularSlides = rows.filter((slide) =>
      regularSlidesTypes.includes(slide.serviceType)
    );

    const postData = {};
    regularSlides.forEach((row) => {
      const details = {
        sample_uuid: row.sample_uuid,
      };

      const rowCountSerialized = parseInt(row.count) || 0;
      const pathologyConsultationCountSerialized =
        parseInt(row.pathology_consultation_count) || 0;
      const imageAnalysisCountSerialized =
        parseInt(row.image_analysis_count) || 0;
      const isScanOnlyService =
        row.type === SCAN_ONLY_SERVICE || row.type === IF_SCAN_ONLY_SERVICE;

      if (row.type === H_AND_E_TYPE) {
        details[REQUESTED_H_AND_E_STAIN_COUNT] = rowCountSerialized;
        details[REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT] =
          pathologyConsultationCountSerialized;
        details[REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT] =
          imageAnalysisCountSerialized;
      } else if (row.type === UNSTAINED_TYPE) {
        details[REQUESTED_UNSTAINED_COUNT] = rowCountSerialized;
      }
      if (isScanOnlyService) {
        const param =
          row.type === SCAN_ONLY_SERVICE
            ? REQUESTED_SLIDE_SCANNING_ONLY_COUNT
            : REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT;
        details[param] = rowCountSerialized;
        details[REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT] =
          pathologyConsultationCountSerialized;
        details[REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT] =
          imageAnalysisCountSerialized;
      }
      if (row.type === IMAGE_ANALYSIS_SERVICE) {
        details[REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT] = rowCountSerialized;
      }
      if (row.type === PATHOLOGY_CONSULTATION_SERVICE) {
        details[REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT] =
          rowCountSerialized;
      }

      postData[row.sample_uuid] = { ...postData[row.sample_uuid], ...details };
    });

    const postValues = Object.values(postData);

    return postSampleServicesRequested({
      orderUUID,
      postParams: postValues,
    });
  };

  return submitRegularSlides;
};

export const useSubmitIHCSlides = () => {
  const [postSampleAntibodiesRequested] =
    usePostSampleAntibodiesRequestedMutation();

  const submitIHCSlides = (rows, orderUUID) => {
    const ihcSlides = rows.filter((response) => {
      return response.serviceType === IHC_SERVICE_TYPE;
    });

    const postParams = ihcSlides.map(
      ({
        count,
        sample_uuid,
        antibody_uuid,
        pathology_consultation_count,
        image_analysis_count,
      }) => {
        return {
          count,
          sample_uuid,
          antibody_uuid,
          pathology_consultation_count,
          image_analysis_count,
        };
      }
    );

    return postSampleAntibodiesRequested({ orderUUID, postParams });
  };

  return submitIHCSlides;
};

export const useSubmitIFSlides = () => {
  const [postSamplePanelsRequested] = usePostSamplePanelsRequestedMutation();

  const submitIFSlides = (rows, orderUUID) => {
    const ifSlides = rows.filter((slide) => {
      return slide.serviceType === IF_SERVICE_TYPE;
    });

    const postParams = ifSlides.map(
      ({
        count,
        sample_uuid,
        panel_uuid,
        pathology_consultation_count,
        image_analysis_count,
      }) => ({
        count,
        sample_uuid,
        panel_uuid,
        pathology_consultation_count,
        image_analysis_count,
      })
    );

    return postSamplePanelsRequested({ orderUUID, postParams });
  };

  return submitIFSlides;
};

export const useSubmitSpecialStains = () => {
  const [postSampleSpecialStainsRequested] =
    usePostSampleSpecialStainsRequestedMutation();

  const submitSpecialStains = (rows, orderUUID) => {
    const specialStainSlides = rows.filter((response) => {
      return response.serviceType === SPECIAL_STAIN_TYPE;
    });

    const postParams = specialStainSlides.map(
      ({
        count,
        sample_uuid,
        special_stain_uuid,
        pathology_consultation_count,
        image_analysis_count,
      }) => ({
        count,
        sample_uuid,
        special_stain_uuid,
        pathology_consultation_count,
        image_analysis_count,
      })
    );

    return postSampleSpecialStainsRequested({ orderUUID, postParams });
  };
  return submitSpecialStains;
};
