/*
 * Shared base configuration for OpenSeadragon components on the slide viewers
 */

export const MAX_ZOOM_LEVEL_FOR_BASE_VIEWER = 40;

export const viewerBaseConfig = {
  degrees: 180,
  visibilityRatio: 0.5,
  zoomPerClick: 3,
  zoomPerScroll: 1.7,
  constrainDuringPan: false,
  minZoomLevel: 0.5,
  showNavigator: true,
  rotationIncrement: 1,
  // Reduce animation time to reduce drag inertia
  animationTime: 0.6,
};
