import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { getDropboxes } from "services/resources/commonResources";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { FETCH_DROPBOXES_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useDropboxesViewStyles } from "./styles";

export const DropboxesView = () => {
  const { classes } = useDropboxesViewStyles();
  const unmountIgnore = useUnmountIgnore();
  const [dropboxes, setDropboxes] = useState([]);
  const { showError } = useSnackbar();

  useEffect(() => {
    getDropboxes()
      .then((data) => {
        if (!unmountIgnore.current) {
          setDropboxes([...data]);
        }
      })
      .catch(() => showError(FETCH_DROPBOXES_ERROR_MESSAGE));
  }, [unmountIgnore]);

  const renderDropbox = (dropbox) => {
    return (
      <Grid item xs={12} md={6} lg={4} key={dropbox.uuid}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="center" gutterBottom>
            {dropbox.name}
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            {dropbox.pickup_time}
          </Typography>
          <div>
            <img src={dropbox.image} width={"100%"} alt={"Dropbox"} />
          </div>
          <br />
          <a href={dropbox.map_url} target="_blank" rel="noopener noreferrer">
            Link to Map
          </a>
        </Paper>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        {dropboxes.map(renderDropbox)}
      </Grid>
    </div>
  );
};
