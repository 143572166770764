import { makeStyles } from "tss-react/mui";

export const useGoToOrderComponentStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  navigateOrderButton: {
    height: "50%",
    marginTop: "16px",
    marginBottom: "auto",
    marginLeft: ".5rem",
  },
  autoComplete: {
    width: "170px",
  },
  autocompleteField: {
    marginTop: "16px",
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      "& .MuiAutocomplete-input": {
        padding: "5.5px 4px 5.5px 6px",
        [theme.breakpoints.down("xxl")]: {
          padding: "5px 4px 5px 6px",
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        [theme.breakpoints.down("xxl")]: {
          top: "calc(50% - .9em)",
        },
      },
    },
    "& .MuiFormLabel-root": {
      top: "-.7rem",
      [theme.breakpoints.down("xxl")]: {
        top: "-0.9rem",
      },
    },
    "& .MuiFormLabel-filled, .Mui-focused": {
      top: "0px",
    },
  },
}));

export const useOrderListViewStyles = makeStyles()((theme) => ({
  appBarStyle: {
    backgroundColor: theme.palette.primary.light,
  },
  tabStyle: {
    marginTop: "1rem",
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
    marginBottom: "2rem",
  },
  tableTitle: { marginLeft: "-.5rem" },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  deleteButton: {
    marginTop: theme.spacing(3),
    float: "right",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  tabContainerStylePadding: {
    padding: "1rem 0px",
  },
}));
