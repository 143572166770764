import React, { useRef, useReducer } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import shallow from "zustand/shallow";

import {
  useImageSettingsStore,
  useViewerStore,
  useChannelsStore,
  useLoader,
} from "components/IFViewer/state";
import {
  range,
  getMultiSelectionStats,
  getBoundingCube,
  canLoadResolution,
  getStatsForResolution,
  formatBytes,
} from "components/IFViewer/utils";
import { useVolumeBtnStyles } from "components/IFViewer/styles";

export const VolumeButton = () => {
  const [selections, setPropertiesForChannel] = useChannelsStore(
    (store) => [store.selections, store.setPropertiesForChannel],
    shallow
  );
  const loader = useLoader();
  const [toggleUse3d, toggleIsVolumeRenderingWarningOn] = useViewerStore(
    (store) => [
      store.use3d,
      store.toggleUse3d,
      store.toggleIsVolumeRenderingWarningOn,
      store.isViewerLoading,
    ],
    shallow
  );

  const [open, toggle] = useReducer((v) => !v, false);
  const anchorRef = useRef(null);
  const { classes } = useVolumeBtnStyles();

  const handleSelectResolution = (resolution, loader) => {
    useViewerStore.setState({
      isChannelLoading: Array(selections.length).fill(true),
    });
    const [xSlice, ySlice, zSlice] = getBoundingCube(loader);
    useImageSettingsStore.setState({
      resolution,
      xSlice,
      ySlice,
      zSlice,
    });
    toggle();
    getMultiSelectionStats({
      loader,
      selections,
      use3d: true,
    }).then(({ domains, contrastLimits }) => {
      range(selections.length).forEach((channel, j) =>
        setPropertiesForChannel(channel, {
          domains: domains[j],
          contrastLimits: contrastLimits[j],
        })
      );
      useImageSettingsStore.setState({
        onViewportLoad: () => {
          useImageSettingsStore.setState({
            onViewportLoad: () => {},
          });
          useViewerStore.setState({
            isChannelLoading: Array(selections.length).fill(false),
          });
        },
      });
      toggleUse3d();
      const isWebGL2Supported = !!document
        .createElement("canvas")
        .getContext("webgl2");
      if (!isWebGL2Supported) {
        toggleIsVolumeRenderingWarningOn();
      }
    });
  };

  const renderMenuItems = (loader) =>
    Array.from({ length: loader.length })
      .fill(0)
      // eslint-disable-next-line no-unused-vars
      .map((_, resolution) => {
        if (loader && canLoadResolution(loader, resolution)) {
          const { height, width, depthDownsampled, totalBytes } =
            getStatsForResolution(loader, resolution);

          return (
            <MenuItem
              dense
              disableGutters
              onClick={() => handleSelectResolution(resolution, loader)}
              key={`(${height}, ${width}, ${depthDownsampled})`}
            >
              {`${resolution}x Downsampled, ~${formatBytes(
                totalBytes
              )} per channel, (${height}, ${width}, ${depthDownsampled})`}
            </MenuItem>
          );
        }
        return null;
      });

  return (
    <>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end">
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={toggle}>
            <MenuList id="resolution-options">
              {renderMenuItems(loader)}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};
