import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

import { useSortPanelStyles } from "./styles";
import { useGetUrlParam } from "./helpers/hooks";
import { PAGE_PARAM, SEARCH_PARAM } from "./helpers/constants";

export const SamplesFilter = () => {
  const { classes } = useSortPanelStyles();

  const search = useGetUrlParam(SEARCH_PARAM);
  const history = useHistory();

  const [text, setText] = useState(search);

  const setFilter = ({ target: { value } }) => {
    const params = new URLSearchParams(history.location.search);

    if (value) {
      params.set(SEARCH_PARAM, value);
    } else {
      params.delete(SEARCH_PARAM);
    }

    params.set(PAGE_PARAM, 1);
    history.replace({ search: params.toString() });
  };

  useEffect(() => {
    setText(search);
  }, [search]);

  return (
    <Box>
      <TextField
        fullWidth
        placeholder="Enter text to filter..."
        value={text}
        onChange={setFilter}
        InputProps={{
          className: classes.filterInput,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
