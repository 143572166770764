import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useBreadcrumbsStyles } from "components/Breadcrumbs/styles";

/**
 *
 * Array: data - array with breadrumbs:
 *  {
 *     string: name - name of the item to display
 *     boolean: hide? - condition to hide item
 *     boolean: bold? - for fontWeight of the item
 *     string: color? - change color of the item
 *     string: link? - link to redirect
 *     boolean: newWindow? - open link in new window
 *  }
 * string: size (small/medium) - for fontSize
 * string: divider (default: /) - icon for the divider
 * string: dividerColor (default: black)
 */
export const Breadcrumbs = ({
  data,
  size = "small",
  divider = "/",
  dividerColor = "black",
}) => {
  const { classes } = useBreadcrumbsStyles({ size });

  return (
    <Typography className={classes.wrapper}>
      {data.map((item, index) => {
        const isLastItem = data.length === index + 1;

        if (item.hide) return null;
        return (
          <span className={classes.item} key={item.name}>
            <Link
              style={{
                cursor: item.link ? "pointer" : "default",
                fontWeight: item.bold ? 700 : 400,
                fontSize: item.fontSize || "inherit",
                color: item.color || "black",
              }}
              to={item.link || "#"}
              target={item.newWindow ? "_blank" : ""}
            >
              {item.name}
            </Link>

            {!isLastItem && (
              <span
                style={{
                  color: dividerColor,
                  fontSize: size === "small" ? "0.75rem" : "1rem",
                }}
              >
                {divider}
              </span>
            )}
          </span>
        );
      })}
    </Typography>
  );
};
