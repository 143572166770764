export const resizeHandles = ["nw", "sw", "se", "ne"];
export const rowSelection = "multiple";
export const rowHeight = 50;
export const agGridStyle = {
  backgroundColor: "transparent",
  height: "80%",
  width: "95%",
};

export const getIHCSlideOptimizationLayout = (heightPixel) => {
  const calculatedHeight = Math.floor(heightPixel / rowHeight);

  // because we have 100vh, sometimes it's too much, so adjust it by 150 pixels
  const calculatedHeightOffset = calculatedHeight - 3;

  const layout = [
    { i: "0", x: 0, y: 0, w: 7, h: calculatedHeightOffset, resizeable: true },
    { i: "1", x: 7, y: 0, w: 5, h: calculatedHeightOffset, resizeable: true },
  ];

  return layout;
};
