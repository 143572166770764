import { Grid } from "@mui/material";
import {
  OrderBillingTable,
  ShippingTable,
} from "components/OrderForm/components/ReviewTables";
import React from "react";

export const BillingAndShippingTab = ({ order, shipments }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <ShippingTable order={order} shipments={shipments} />
      </Grid>
      <Grid item xs>
        <OrderBillingTable order={order} alternative={true} />
      </Grid>
    </Grid>
  );
};
