import React from "react";
import { ConditionalComponent } from "components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent";
import { useSelector } from "react-redux";
import { isStaffSelector } from "store/slices/userDetailsSlice";

export const ShowStaffUserOnly = ({ children }) => {
  const isStaff = useSelector(isStaffSelector);

  return (
    <ConditionalComponent condition={isStaff}>{children}</ConditionalComponent>
  );
};
