import { Typography } from "@mui/material";
import { RefreshPageButton } from "components/Layout/RefreshPageButton";
import { useAppVersionStyles } from "components/Layout/styles";
import { SnackbarActionCloseButton } from "components/Shared/Buttons/SnackbarActionCloseButton";
import React, { useEffect } from "react";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

const appVersionId = process.env.REACT_APP_COMMIT_REF;
const versionCheckInterval = 600 * 1000;

export const AppVersion = () => {
  const { classes } = useAppVersionStyles();
  const { showInfo } = useSnackbar();

  useEffect(() => {
    const interval = setInterval(() => {
      fetch(`${window.location.origin}/version.txt`)
        .then((response) => response.text())
        .then((currentVersion) => {
          if (currentVersion && currentVersion.trim() !== appVersionId) {
            showInfo(
              "A new version of the app is available. Please refresh the page.",
              {
                action: (key) => (
                  <>
                    <RefreshPageButton />
                    <SnackbarActionCloseButton snackbarKey={key} />
                  </>
                ),
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top",
                },
                autoHideDuration: null,
                persist: true,
                preventDuplicate: true,
              }
            );
          }
        })
        .catch(console.log);
    }, versionCheckInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    appVersionId && (
      <Typography className={classes.appVersion}>
        App version: {appVersionId.substring(0, 7)}
      </Typography>
    )
  );
};
