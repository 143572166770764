import { makeStyles } from "tss-react/mui";
import slideViewCursor from "images/icons/ViewSlideIcon.svg";
import {
  HISTOWIZ_PRIMARY_COLOR,
  IDEXX_PRIMARY_COLOR,
  PRIMARY_BLUE,
  LIGHT_GREY,
  SECONDARY_VIEWER,
  WHITE,
  VIEWER_ICONS_HOVER,
  MEDIUM_GREY,
} from "utilities/colors";
import { BLUE_BG_STYLES } from "components/Shared/styles";

export const useAccordionWithCommentsStyles = makeStyles()(() => ({
  accordion: {
    marginBottom: "0.5rem",
    background: `${PRIMARY_BLUE}16`,
  },
  newSlideComment: {
    marginBottom: "0.5rem",

    "& .MuiInputBase-root": {
      padding: "0.5rem",
    },
  },
  commentRedPlaceholder: {
    "& textarea::placeholder": {
      color: IDEXX_PRIMARY_COLOR,
    },
  },
  summary: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  commentCount: {
    position: "absolute",
    top: "0",
    right: "0",
    background: HISTOWIZ_PRIMARY_COLOR,
    color: "white",
    fontSize: "0.4375rem",
    fontWeight: 700,
    padding: "0.09rem 0.25rem",
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
  },
  submit: {
    padding: "0.2rem 0.5rem",
    fontSize: "0.875rem",
    fontWeight: 500,
    marginRight: "0.5rem",
  },
}));

export const useMultiSlidesCommentsStyles = makeStyles()(() => ({
  noCommentsLabel: {
    width: "max-content",
    margin: "auto",
  },
  commentsContainer: {
    paddingTop: "1rem",
  },
  spinnerContainer: {
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useSimpleSlideImageViewStyles = makeStyles()(() => ({
  screenshotWrapper: {
    display: "flex!important",
  },
  downloadButton: {
    background: SECONDARY_VIEWER,
    display: "flex !important",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.5rem 0 0 0.5rem",
    height: "30px",
    marginBottom: "-0.7rem",
    marginLeft: "0.5rem",
    width: "3rem",
    cursor: "pointer",

    "&:hover": {
      background: VIEWER_ICONS_HOVER,
    },

    "&>i": {
      color: WHITE,
      fontSize: "1.25rem",
    },
  },
  dimensionArrow: {
    padding: "0.2rem 0.5rem 0 0.5rem",
    display: "flex !important",
    alignItems: "center",
    borderRadius: " 0 0.5rem 0.5rem 0",
    background: SECONDARY_VIEWER,
    borderLeft: "1px solid #045f75",

    "&:hover": {
      background: VIEWER_ICONS_HOVER,
    },

    "&>i": {
      color: WHITE,
      fontSize: "1.25rem",
    },
  },
  dimensionMenuWrapper: {
    backgroundColor: "white",
    position: "absolute",
    top: "2rem",
    margin: 0,
    listStyleType: "none",
    border: `1px solid ${LIGHT_GREY}`,
    borderRadius: "0.5rem",
    paddingLeft: 0,
    width: "15rem",
  },

  dimensionMenuItem: {
    fontSize: "1rem",
    padding: "0.5rem 1rem 0.5rem 1rem",
    cursor: "pointer",

    "&:hover": {
      background: LIGHT_GREY,
    },

    "&:last-child": {
      paddingBottom: "0.5rem",
      borderTop: `1px solid ${LIGHT_GREY}`,
      cursor: "default",
      "&:hover": {
        background: "unset",
      },
    },
  },
  fullScreenButton: {
    position: "absolute",
    top: "0.2rem",
    right: "0.25rem",

    "&>div": {
      background: SECONDARY_VIEWER,
    },
  },
  icon: {
    position: "absolute",
    width: "1.5rem",
    height: "1.5rem",
  },
  qcButtons: { position: "absolute", bottom: "0.5rem", right: "0.5rem" },
  zoomIndicator: {
    textAlign: "center",
    fontWeight: "500",
    border: "1px solid rgb(220, 222, 220)",
    marginBottom: 4,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    opacity: 0.8,
  },
  osdContainer: {
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    cursor: `url(${slideViewCursor}), auto`,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;",
  },
  crosshairCursor: {
    cursor: "crosshair",
  },
  navigator: {
    "&:hover": {
      "& .hover-visible": {
        background: HISTOWIZ_PRIMARY_COLOR,

        "& svg": {
          display: "block",
        },
      },
    },
  },
  closeBackground: {
    position: "absolute",
    zIndex: 10,
    right: "0.25rem",
    top: "0.25rem",
    opacity: 0.7,
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: MEDIUM_GREY,
    cursor: "pointer",
  },
  closeIcon: {
    width: "0.75rem",
    height: "0.75rem",
    display: "none",
    color: "white",
  },
  collapsedNavigator: {
    position: "absolute",
    zIndex: 1900,
    bottom: "2.25rem",
    left: "0.25rem",
    width: "10rem",
    cursor: "default",
  },
  fullScreenModal: {
    width: "100vw",
    height: "100vh",
    background: "white",
  },
  modalIndex: {
    zIndex: 5000,
  },
}));

export const useSlidesViewerHeaderStyles = makeStyles()((theme) => ({
  toolbarWrapper: {
    marginBottom: "0.5rem",
    paddingTop: "1rem",

    [theme.breakpoints.down("lg")]: {
      paddingTop: "0.25rem",
      marginBottom: 0,
    },
  },
  toolbarItemWrapper: {
    paddingRight: theme.spacing(1),
    flexBasis: "auto",
  },
  headContainer: {
    display: "flex",
  },

  starIcon: {
    height: "2rem",
    width: "2rem",

    [theme.breakpoints.down("lg")]: {
      width: "1.5rem",
      height: "1.5rem",
      marginTop: "-0.25rem",
    },
    [theme.breakpoints.down("md")]: {
      width: "1.25rem",
      height: "1.25rem",
    },
  },
  iconButton: {
    padding: 0,
    margin: "auto 1.25rem auto 0",

    "&.MuiIconButton-root": {
      [theme.breakpoints.down("lg")]: {
        padding: "0.4rem 0.2rem 0.2rem",
        marginRight: "0.5rem",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0.2rem 0.1rem 0.1rem",
        marginRight: "0.25rem",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0.1rem 0.05rem 0.05rem",
        marginRight: "0.125rem",
      },
    },
  },
}));

export const useMainToolbarStyles = makeStyles()(() => ({
  defaultButton: {
    height: "2rem",
    minWidth: "unset",
  },
  toggleButton: {
    "&.Mui-selected": {
      ...BLUE_BG_STYLES,
    },
  },
  lockButton: {
    padding: "0.75rem",
  },
  helpButton: {
    padding: 0,
  },
}));

export const useSlideAnnotationsToolbarStyles = makeStyles()((_theme) => ({
  annotationToolbarButton: {
    height: "2rem",
    minWidth: "unset",

    [_theme.breakpoints.down("lg")]: {
      height: "1.5rem",
      width: "2rem",
      padding: 0,
    },
  },
  annotationToolbarButtonOn: {
    height: "2rem",
    backgroundColor: "primary",
  },
  annotationToolbarButtonOff: {
    height: "2rem",
  },
  helpButton: {
    padding: 0,
  },
  annoCountIcon: {
    color: _theme.palette.common.white,
    backgroundColor: _theme.palette.primary.light,
    borderRadius: "50%",
    width: "1rem",
    height: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.7rem",
    fontWeight: "bold",
    padding: 0,
    "&:hover": {
      backgroundColor: _theme.palette.primary.main,
    },
    left: "-1rem",
  },
  buttonLink: {
    backgroundColor: "transparent",
    border: "none",
    textDecoration: "underline",
    cursor: "pointer",
    color: _theme.palette.common.white,
  },
  buttonText: {
    fontWeight: 400,
    fontSize: "0.875rem",
  },
}));

export const useAttachmentStyles = makeStyles()(() => ({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
  },
  attachedFileName: {
    margin: "0.25rem 0",
  },
  specialInstructionLabel: {
    marginTop: "0.5rem",
  },
}));
