import React from "react";
import compose from "recompose/compose";
import toRenderProps from "recompose/toRenderProps";
import withState from "recompose/withState";
import PermIdentity from "@mui/icons-material/PermIdentity";
import { withRouter } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import {
  CONTACT_URL,
  TEAM_USER_INVITE_URL,
  LOGOUT_URL,
  REFERRALS_URL,
  USER_DETAILS_URL,
  VERSAPAY_AUTH_URL,
} from "constants/urls";
import Lock from "@mui/icons-material/Lock";
import ContactMail from "@mui/icons-material/ContactMail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ReferEmail from "@mui/icons-material/EmailRounded";
import { isMobile } from "react-device-detect";
import { clearCache } from "services/backendAPI";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import { DEMO_USER_EMAIL } from "constants/emails";
import { connect, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { resetStore } from "store/store";

const WithState = toRenderProps(withState("anchorEl", "updateAnchorEl", null));

const styles = (theme) => ({
  extendedIcon: {
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1),
    },
  },
  email: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  menu: {
    zIndex: 4510,
  },
});

const _UserMenu = ({ email = "", classes, history }) => {
  const dispatch = useDispatch();

  return (
    <WithState>
      {({ anchorEl, updateAnchorEl }) => {
        const open = Boolean(anchorEl);
        const handleClose = () => {
          updateAnchorEl(null);
        };
        const logout = () => {
          history.push(LOGOUT_URL);
          clearCache();
          dispatch(resetStore());
        };

        const contactUs = () => {
          history.push(CONTACT_URL);
        };

        const userDetails = () => {
          history.push(USER_DETAILS_URL);
        };

        const referral = () => {
          history.push(REFERRALS_URL);
        };

        const invite = () => {
          history.push(TEAM_USER_INVITE_URL);
        };

        const openPayment = () => {
          window.open(VERSAPAY_AUTH_URL, "_blank", "noopener,noreferrer");
        };

        const isDesktop = !isMobile;
        const isLoggedIn = email !== DEMO_USER_EMAIL;

        return (
          <>
            <Button
              aria-owns={open ? "render-props-menu" : undefined}
              aria-haspopup="true"
              color="inherit"
              onClick={(event) => {
                updateAnchorEl(event.currentTarget);
              }}
              title="Profile"
            >
              <PermIdentity className={classes.extendedIcon} />
              {isDesktop ? (
                <span className={classes.email}>{email}</span>
              ) : null}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className={classes.menu}
            >
              {isLoggedIn ? (
                <MenuItem onClick={userDetails}>
                  <AccountCircle className={classes.extendedIcon} /> Edit User
                  Details
                </MenuItem>
              ) : null}
              <MenuItem onClick={contactUs}>
                <ContactMail className={classes.extendedIcon} /> Contact Account
                Rep
              </MenuItem>
              {isLoggedIn && [
                <MenuItem onClick={referral} key="Refer">
                  <ReferEmail className={classes.extendedIcon} /> Refer A Friend
                </MenuItem>,
                <MenuItem onClick={invite} key="Invite">
                  <AddCircleIcon className={classes.extendedIcon} /> Invite Team
                  Members
                </MenuItem>,
                <MenuItem onClick={openPayment} key="VersaPay">
                  <PaymentIcon className={classes.extendedIcon} /> VersaPay
                </MenuItem>,
              ]}
              <MenuItem onClick={logout}>
                <Lock className={classes.extendedIcon} /> Logout
              </MenuItem>
            </Menu>
          </>
        );
      }}
    </WithState>
  );
};

const mapStateToProps = (state) => ({
  email: state.userDetails.email,
  isStaff: state.userDetails.isStaff,
});

const _UserMenuWithStyles = withStyles(_UserMenu, styles);

export const UserMenu = compose(
  connect(mapStateToProps),
  withRouter
)(_UserMenuWithStyles);
