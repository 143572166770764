import React from "react";
import { useParams } from "react-router";
import { Box, Typography } from "@mui/material";

import { OrderSlidesChart } from "components/OrderSlidesChart/OrderSlidesChart";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { useTitle } from "components/utilities/hooks/useTitle";
import { ORDER_SLIDES_CHART_TITLE } from "components/OrderSlidesChart/helpers/constants";
import { useChartSources } from "components/OrderSlidesChart/helpers/hooks";
import { useSlidesChartStyles } from "components/OrderSlidesChart/styles";
import { Spinner } from "components/Loading/Spinner/Spinner";

export const OrderSlidesChartPage = () => {
  useTitle(ORDER_SLIDES_CHART_TITLE);

  const { classes } = useSlidesChartStyles();
  const { loader } = classes;
  const { orderUUID } = useParams();
  const { sources, isLoading } = useChartSources(orderUUID);

  return (
    <MiniDrawerWithContext header={ORDER_SLIDES_CHART_TITLE}>
      {isLoading ? (
        <Box className={loader}>
          <Spinner size={48} />
        </Box>
      ) : (
        <>
          {sources.length ? (
            <OrderSlidesChart sources={sources} />
          ) : (
            <Box className={loader}>
              <Typography variant="h5">No sources found</Typography>
            </Box>
          )}
        </>
      )}
    </MiniDrawerWithContext>
  );
};
