import { useEffect, useState } from "react";
import { FETCH_TEAMS_ERROR_MESSAGE } from "constants/errorMessages";
import { getTeams } from "services/resources/commonResources";
import { serializeNameAndUUID } from "utilities/api";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useSerializedTeams = () => {
  const unmountIgnore = useUnmountIgnore();
  const { showError } = useSnackbar();
  const [team, setTeam] = useState();
  const [selectableTeams, setSelectableTeams] = useState([]);

  useEffect(() => {
    getTeams()
      .then((response) => {
        const selectableTeams = response.data.map(serializeNameAndUUID);

        // this is used to default to a primary team when someone is making their order (most users will probably only have one team)
        const team = serializeNameAndUUID(response.data[0]);
        if (!unmountIgnore.current) {
          setTeam(team);
          setSelectableTeams(selectableTeams);
        }
      })
      .catch(() => showError(FETCH_TEAMS_ERROR_MESSAGE));
  }, [unmountIgnore]);

  return {
    team,
    selectableTeams,
  };
};
