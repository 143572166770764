import React from "react";
import { MiniDrawerWithContext } from "../Layout/drawer";
import { ContactView } from "./ContactView";
import { useContactPageStyles } from "./styles";
import { Box } from "@mui/material";

export const ContactPage = () => {
  const { classes } = useContactPageStyles();

  return (
    <MiniDrawerWithContext header={"Contact Us"}>
      <Box className={classes.root}>
        <ContactView />
      </Box>
    </MiniDrawerWithContext>
  );
};
