import { FileDownload } from "@mui/icons-material";
import { openMultiSlideParamsNewWindow } from "components/ImageViewer/utilities";
import { useSlideActions } from "components/PathologyMap/Modals/FullSlideInfoModal/hooks";
import { PATHOLOGY_MAP_SEARCH_2 } from "constants/urls";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useClipboard from "react-use-clipboard";
import { AppContext } from "services/context";
import { downloadMultiSlideSource } from "services/resources/slides";
import {
  groupSelector,
  isSharedPageSelector,
  selectedRowsSelector,
  slidesListOrderSelector,
} from "store/slices/slidesListSlice/slidesListSlice";
import { isStaffSelector } from "store/slices/userDetailsSlice";

export const useViewerActions = (currentSlide, isSingleViewer) => {
  const selectedRows = useSelector(selectedRowsSelector);
  const isSharedPage = useSelector(isSharedPageSelector);
  const isStaff = useSelector(isStaffSelector);
  const order = useSelector(slidesListOrderSelector);
  const group = useSelector(groupSelector);

  const hasSlideAndOrder = order && currentSlide;
  const isSharableGroup = (hasSlideAndOrder || !!group) && !group?.custom;
  const showShareButton = !isSharedPage && isSharableGroup;

  const { findSimilarSlides, markSlideValidPosControl } =
    useSlideActions(currentSlide);

  const { handleContextModalChange } = useContext(AppContext);
  /* eslint-disable-next-line no-unused-vars */
  const [createdSlideURL, setCreatedSlideURL] = useState();

  const [, setCreatedSlideLinkCopied] = useClipboard(createdSlideURL);
  useEffect(() => {
    if (createdSlideURL) {
      setCreatedSlideLinkCopied();
    }
  }, [createdSlideURL, setCreatedSlideLinkCopied]);

  const handleCompareSlides = () => {
    openMultiSlideParamsNewWindow({ rows: selectedRows });
  };

  const handleDownloadSlides = () => {
    const slides = isSingleViewer ? [currentSlide] : selectedRows;
    downloadMultiSlideSource({ slides });
  };

  const findSimilarSlidesExternal = () => {
    window.open(PATHOLOGY_MAP_SEARCH_2, "_blank");
  };

  const slideTools = [
    {
      name: "Compare Selected Slides",
      onClick: handleCompareSlides,
      condition: !isSingleViewer,
    },
    {
      name: "Find Similar Slides",
      onClick: findSimilarSlides,
      condition: !!isStaff,
    },
    {
      name: "Find Similar Slides",
      onClick: findSimilarSlidesExternal,
      condition: !isStaff,
    },
    {
      name: "Mark Slide As Valid Pos Control",
      onClick: markSlideValidPosControl,
      condition: !!isStaff,
    },
  ];

  const exportTools = [
    {
      name: isSingleViewer ? "Download Slide" : "Download Slides",
      onClick: handleDownloadSlides,
      Icon: FileDownload,
    },
  ];

  const additionalTools = [
    {
      name: "Request Pathologist Consultation",
      onClick: handleContextModalChange("modalPathologistConsultationOpen"),
      condition: !isSharedPage,
    },
  ];

  return {
    slideTools,
    exportTools,
    additionalTools,
    showShareButton,
    showShareSlideButton: !isSharedPage && selectedRows.length === 1,
    order,
    selectedRows,
    group,
  };
};
