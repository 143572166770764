import { makeStyles } from "tss-react/mui";
import {
  BACKGROUND_GREY,
  MEDIUM_GREY,
  FORM_TEXT_COLOR,
} from "utilities/colors";

export const useSavedInfoRow = makeStyles()(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.2rem 1.6rem",

    "&:nth-of-type(odd)": {
      backgroundColor: BACKGROUND_GREY,
    },
  },
  label: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
    color: MEDIUM_GREY,
  },
  value: {
    color: FORM_TEXT_COLOR,
    fontSize: "0.875rem",
    lineHeight: "16px",
  },
}));
