import {
  NOTIFY_CLIENT_ORDER_COMMENT_RESOURCE_NAME,
  ORDER_COMMENTS_RESOURCE_NAME,
} from "services/resources/apiConstants";
import { baseCreateApi } from "store/apis/baseCreateApi";

export const orderApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: "orders/",
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getOrderByUUID: builder.query({
      query: (orderUUID) => ({
        url: `orders/${orderUUID}/`,
        method: "GET",
      }),
      providesTags: ["getOrderByUUID"],
      keepUnusedDataFor: 0,
    }),
    getSlidesByUUIDs: builder.query({
      query: (slideUUIDs) => ({
        url: "slides_batch/",
        method: "POST",
        body: { slides_uuids: slideUUIDs },
      }),
    }),
    createOrder: builder.mutation({
      query: (postParams) => ({
        url: "orders/",
        method: "POST",
        body: postParams,
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `orders/${orderUUID}/`,
        method: "PUT",
        body: postParams,
      }),
      //@todo: this is a hack to get the order to update after the mutation. The response doesn't contain the complete updated order.
      invalidatesTags: ["getOrderByUUID"],
    }),
    deleteOrder: builder.mutation({
      query: (orderUUID) => ({
        url: `orders/${orderUUID}/`,
        method: "DELETE",
        body: { order_uuid: orderUUID },
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          baseCreateApi.util.updateQueryData(
            "getOrders",
            undefined,
            (draft) => {
              const index = draft.findIndex(
                (order) => order.uuid === queryArgs
              );
              if (index !== -1) draft.splice(index, 1);
            }
          )
        );
      },
    }),
    duplicateOrder: builder.mutation({
      query: (orderUUID) => ({
        url: `orders/${orderUUID}/duplicate_order/`,
        method: "POST",
      }),
    }),
    getOrderComments: builder.query({
      query: ({ orderUUID }) => {
        const url = ORDER_COMMENTS_RESOURCE_NAME.replace(
          ":orderUUID",
          orderUUID
        );
        return {
          url,
          method: "GET",
        };
      },
    }),
    postOrderCommentWithSlides: builder.mutation({
      query: ({ orderUUID, postParams }) => {
        const url = ORDER_COMMENTS_RESOURCE_NAME.replace(
          ":orderUUID",
          orderUUID
        );
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
    }),
    postNotifyClientOrderComment: builder.mutation({
      query: ({ orderUUID, postParams }) => {
        const url = NOTIFY_CLIENT_ORDER_COMMENT_RESOURCE_NAME.replace(
          ":orderUUID",
          orderUUID
        );
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useDuplicateOrderMutation,
  useGetSlidesByUUIDsMutation,
  useLazyGetSlidesByUUIDsQuery,
  useGetOrdersQuery,
  useGetOrderByUUIDQuery,
  useLazyGetOrderByUUIDQuery,
  useLazyGetOrderCommentsQuery,
  usePostOrderCommentWithSlidesMutation,
  usePostNotifyClientOrderCommentMutation,
} = orderApi;
