import { makeStyles } from "tss-react/mui";
import { PATHOLOGY_MAP_TRANSPARENT_BLACK } from "components/PathologyMap/constants/styles";
import { HORIZONTAL_SCROLL_STYLE } from "themes/defaultMuiTheme";
import {
  HIGHLIGHTED_TEXT_BG_COLOR,
  HIGHLIGHTED_TEXT_FONT_WEIGHT,
} from "components/Shared/constants/styles";

export const usePathologyMapCategoriesHeaderStyles = makeStyles()(() => ({
  categoriesPresetButton: {
    width: "2rem",
    minWidth: "2rem",
    height: "2rem",
    marginLeft: "0.5rem",
    "&:hover": {
      backgroundColor: "rgba(66, 148, 213, 0.2)",
    },
  },
  selectedButton: {
    background:
      "linear-gradient(180deg, rgba(66, 148, 213, 0.35), rgba(66, 148, 213, 0.20))",
    color: "black",
  },
}));

export const usePathologyMapListItemStyles = makeStyles()(() => ({
  floatingThumbnailContainer: {
    position: "fixed",
    zIndex: 10000,
    height: "50%",
    right: "1rem",
    top: "1rem",
    border: "1px solid black",
  },
  slideInfoCardHeaderContainer: {
    backgroundColor: "white",
    borderColor: PATHOLOGY_MAP_TRANSPARENT_BLACK,
    borderRadius: "3px",
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_TRANSPARENT_BLACK}`,
    width: "100%",
  },
  cell: {
    width: "12rem",
    display: "flex",
    paddingRight: "1rem",
    padding: "0.5rem",
  },
  highlightedCell: {
    backgroundColor: HIGHLIGHTED_TEXT_BG_COLOR,
  },
  highlightedText: {
    fontWeight: HIGHLIGHTED_TEXT_FONT_WEIGHT,
  },
  lineContainer: {
    height: "3rem",
    minWidth: "100%",
    width: "min-content",
    maxWidth: "min-content !important",
    display: "flex",
    flexDirection: "row",
  },
  headingLine: {
    backgroundColor: "#F2F7F9",
    borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
  },
  slideCardsContainer: {
    position: "absolute",
    overflowX: "auto",
    backgroundColor: "white",
    borderColor: PATHOLOGY_MAP_TRANSPARENT_BLACK,
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_TRANSPARENT_BLACK}`,
    width: "100%",
    ...HORIZONTAL_SCROLL_STYLE,
  },
}));
