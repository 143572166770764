import { makeStyles } from "tss-react/mui";
import { PRIMARY_WHITE } from "utilities/colors";
import leftBgImage from "images/leftBgImage.png";
import rightBgImage from "images/rightBgImage.png";
import { OPENED_DRAWER_WIDTH_PX } from "components/Layout/constants";

export const useMiniDrawerWithContextStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",

    [theme.breakpoints.down("lg")]: {
      "& h6": {
        fontSize: "1rem",
      },
    },
  },
  appBar: {
    zIndex: 1300,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingRight: "unset !important", //necessary to override styles which come from MUI from style attribute
  },
  toolbarMui: {
    "&.MuiToolbar-root": {
      paddingRight: 0,
    },
  },
  navGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navHeader: {
    maxWidth: "40%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "0.25rem",
  },
  appBarShift: {
    marginLeft: OPENED_DRAWER_WIDTH_PX,
    width: `calc(100% - ${OPENED_DRAWER_WIDTH_PX}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    backgroundColor: PRIMARY_WHITE,
    position: "relative",
    minHeight: "100vh",
    width: OPENED_DRAWER_WIDTH_PX,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "56px !important",
  },
  content: {
    backgroundColor: PRIMARY_WHITE,
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundImage: `url(${leftBgImage}), url(${rightBgImage})`,
    backgroundRepeat: "repeat-y, repeat-y",
    backgroundSize: "20%, 20%",
    backgroundPosition: "left top, right top",
    overflowX: "auto",
  },
  clearContent: {
    backgroundColor: PRIMARY_WHITE,
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
  },
  logo: {
    width: OPENED_DRAWER_WIDTH_PX * 0.7,
    marginLeft: "auto",
    paddingLeft: "0rem",
    marginRight: "-.5rem",
    marginTop: "0.5rem",
  },
  emailLabel: {
    float: "right",
    marginRight: "1rem",
  },
  histowizToolbarSection: {
    textDecoration: "none",
  },
  searchWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    maxHeight: 27,
    minWidth: "360px",
    width: "40%",

    [theme.breakpoints.down("md")]: {
      minWidth: "100px",
    },
  },
  noOverflowX: {
    overflowX: "inherit",
  },
}));

export const useAppVersionStyles = makeStyles()({
  appVersion: {
    position: "fixed",
    right: "4rem",
    fontSize: "0.75rem",
    fontStyle: "italic",
    bottom: "0.2rem",
    color: "black",
  },
});

export const useLoginComponentStyles = makeStyles()((theme) => ({
  loginIcon: {
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1),
    },
  },
  email: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));

export const useUserMenuStyles = makeStyles()((theme) => ({
  extendedIcon: {
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1),
    },
  },
  email: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  menu: {
    zIndex: 4510,
  },
}));
