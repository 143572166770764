import React, { Fragment } from "react";
import { Button, Grid } from "@mui/material";
import { SlideCommentComponentV2 } from "components/AllSlidesComments/SlideCommentComponentV2";
import { useAllSlidesCommentsStyles } from "components/AllSlidesComments/styles";
import { ViewSlideModal } from "components/Modals/ViewSlideModal";
import { OrderSlideBreadcrumbsHeader } from "components/OrderSlidesList/OrderSlideBreadcrumbsHeader";
import { FETCH_SLIDE_ERROR_MESSAGE } from "constants/errorMessages";
import { useFilteredComments } from "components/utilities/hooks/useFilteredComments";
import { ORDER_NAME_FOR_SHARE } from "components/Share/helpers/constants";
import { Share } from "components/Share/Share";
import { useLazyGetSlideQuery } from "store/apis/slidesApi";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

// this the entire image thumbnail and then has slide comments on the right side
export const SlideAndSlideCommentsComponent = ({
  slideComments,
  imgHeight = "400px",
  onSlideSelect,
  onDelete,
  onCreate,
}) => {
  const { classes } = useAllSlidesCommentsStyles();
  const [viewSlideModalOpen, setViewSlideModalOpen] = React.useState(false);
  const filteredComments = useFilteredComments(slideComments);

  const [getSlide, { data: fullSlide }] = useLazyGetSlideQuery();

  const { showError } = useSnackbar();

  // this endpoint doesn't give us enough information about the slide
  const slideBasicDetails = filteredComments[0]?.slide;
  const order = slideBasicDetails?.order;

  if (!slideBasicDetails) {
    return null;
  }

  const handleModalOpenClose = (value) => {
    setViewSlideModalOpen(value);
  };

  const handleCloseModal = () => {
    handleModalOpenClose(false);
  };

  const renderViewSlideModal = () => {
    if (!fullSlide || !viewSlideModalOpen) {
      return null;
    }

    return (
      <ViewSlideModal
        showSlideComments
        slide={fullSlide}
        isOpen={viewSlideModalOpen}
        handleCreateSlideComment={onCreate}
        handleDeleteSlideComment={onDelete}
        handleCloseModal={handleCloseModal}
      />
    );
  };

  const handleOpenSlideModal = () => {
    onSlideSelect && onSlideSelect(slideBasicDetails);

    if (slideBasicDetails.uuid) {
      getSlide({ slideUUID: slideBasicDetails.uuid })
        .unwrap()
        .then(() => setViewSlideModalOpen(true))
        .catch(() => {
          showError(FETCH_SLIDE_ERROR_MESSAGE);
        });
    }
  };
  return (
    <Fragment key={slideBasicDetails.uuid}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <div className={classes.cardHeader}>
            <OrderSlideBreadcrumbsHeader slide={slideBasicDetails} />
            {order && <Share item={order} itemName={ORDER_NAME_FOR_SHARE} />}
            <Button
              variant="contained"
              color="primary"
              size={"large"}
              onClick={handleOpenSlideModal}
            >
              View Slide
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            onClick={handleOpenSlideModal}
            className={classes.thumbnailImageContainer}
          >
            <img
              src={slideBasicDetails.medium_thumbnail_url}
              alt={"Macro"}
              height={imgHeight}
              className={classes.thumbnailImage}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <div className={classes.comments}>
            {filteredComments.map((comment) => (
              <Fragment key={comment.uuid}>
                <SlideCommentComponentV2
                  comment={comment}
                  key={comment.uuid}
                  handleDeleteComment={onDelete}
                />
              </Fragment>
            ))}
          </div>
        </Grid>
      </Grid>
      {renderViewSlideModal()}
    </Fragment>
  );
};
