import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLegendStyles } from "components/OrderSlidesList/styles";

export const AnnotationsLegend = ({ annotationsLegend }) => {
  const { classes } = useLegendStyles();

  const getRowBackgroundColor = (item, opacity) => ({
    backgroundColor: item.color.replace(")", `, ${opacity})`),
    "&:hover": {
      backgroundColor: item.color.replace(")", `, ${opacity - 0.05})`),
    },
  });

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell className={classes.headerCell}>Name</TableCell>
            <TableCell className={classes.headerCell} align="right">
              ID
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              Text
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              Area
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              Length
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {annotationsLegend.map((item) =>
            item.regions.map((region, index) => (
              <TableRow
                key={`${item.id}-${region.id}`}
                sx={getRowBackgroundColor(item, 0.15)}
              >
                <TableCell component="th" scope="row">
                  {index === 0 ? item.name : ""}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {region.id}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {region.text}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {region.area}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {region.length}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
