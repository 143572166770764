import { AgGridReact } from "ag-grid-react";
import {
  AntibodyColumnDefs,
  IFPanelColumnDefs,
} from "components/utilities/AgGridCols/AgGridColumns";
import {
  antibodiesDetailGridDefaultColDefs,
  antibodiesTablesDefaultColDef,
} from "components/AntibodiesList/constants";
import { resizeColumnsToFitScreen } from "components/utilities/grid";
import React, { useState } from "react";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: AntibodyColumnDefs,
    defaultColDef: antibodiesDetailGridDefaultColDefs,
    domLayout: "print",
  }, // (notice the comma here!) detailGridOptions is passed onto getDetailRowData
  getDetailRowData: function (params) {
    params.successCallback(params.data.antibodies);
  },
};

export const IFPanelTable = ({
  rowData,
  isLoading,
  quickFilterText,
  isStaff,
  setDisplayRowData,
}) => {
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = ({ api }) => {
    setGridApi(api);
  };

  useAGGridOverlayControl(isLoading, rowData, gridApi);

  const isRowMaster = (panel) => {
    return !!panel.antibodies.length;
  };

  return (
    <AgGridReact
      onGridReady={onGridReady}
      onModelUpdated={setDisplayRowData}
      masterDetail={true}
      isRowMaster={isRowMaster}
      enableSorting={true}
      enableFilter={true}
      quickFilterText={quickFilterText}
      enableColResize={true}
      columnDefs={IFPanelColumnDefs}
      defaultColDef={antibodiesTablesDefaultColDef}
      detailCellRendererParams={detailCellRendererParams}
      detailRowHeight={250}
      rowData={rowData}
      context={{ isStaff }}
      onFirstDataRendered={resizeColumnsToFitScreen}
      overlayNoRowsTemplate={"<span>No data</span>"}
      suppressNoRowsOverlay={isLoading}
    />
  );
};
