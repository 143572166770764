import UploadIcon from "@mui/icons-material/Upload";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import DropzoneBase from "react-dropzone";
import { makeStyles } from "tss-react/mui";
import { AttachedFiles } from "components/utilities/components/Dropzone/AttachedFiles/AttachedFiles";
import { styles } from "components/utilities/components/Dropzone/styles";

const useStyles = makeStyles()(styles);

export const Dropzone = ({
  attachments,
  disabled,
  onDrop,
  title = "Upload Files",
  description,
  adornmentRight,
  children,
  multiple = false,
  className,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs={6}>
        <AttachedFiles
          title={title}
          description={description}
          adornmentRight={adornmentRight}
          attachments={attachments}
          isDisabled={disabled}
        >
          {children}
        </AttachedFiles>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DropzoneBase
            onDrop={onDrop}
            multiple={multiple}
            disabled={disabled}
            accept="image/*,application/pdf"
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={cx("dropzone", {
                    "dropzone--isActive": isDragActive,
                    [classes.disabledCursor]: disabled,
                  })}
                >
                  <input {...getInputProps()} />
                  <Box sx={styles.dropzone}>
                    <Button startIcon={<UploadIcon />} disabled={disabled}>
                      Choose a file(s) to upload
                    </Button>
                  </Box>
                </div>
              );
            }}
          </DropzoneBase>
        </Box>
      </Grid>
    </Grid>
  );
};
