import {
  IMAGE_ANALYSIS_SERVICE,
  PATHOLOGY_CONSULTATION_SERVICE,
} from "components/OrderForm/constants";
import { IHC_SERVICE_TYPE } from "components/OrderForm/SlideServicesForm/constants";
import {
  HISTOWIZ_PRIMARY_COLOR,
  PRIMARY_BLUE,
  PRIMARY_PURPLE,
} from "utilities/colors";

export const gridThemeStyle = {
  height: "500px",
  width: "100%",
  backgroundColor: "transparent",
};

export const STEPPER_COMPLETE_INDEX = 1000;
export const STEPPER_NOT_STARTED_INDEX = -1;

export const requestedServicesForOrder = [
  {
    name: IHC_SERVICE_TYPE,
    fields: ["requested_ihc"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  {
    name: IMAGE_ANALYSIS_SERVICE,
    fields: ["requested_image_analysis"],
    color: PRIMARY_BLUE,
  },
  {
    name: "IF",
    fields: ["requested_immunofluorescence"],
    color: "red",
  },
  {
    name: PATHOLOGY_CONSULTATION_SERVICE,
    fields: ["requested_pathology_consultation"],
    color: PRIMARY_BLUE,
  },
  {
    name: "Return Shipping",
    fields: [
      "ship_back_samples",
      "ship_back_stained_slides",
      "ship_back_unstained_slides",
    ],
    color: PRIMARY_PURPLE,
  },
];

export const requestedServicesForSamples = [
  {
    name: "H&E",
    fields: ["requested_h_and_e_stain_count"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  {
    name: "Special Stain",
    fields: ["requested_special_stains_count"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  {
    name: "Gross Process Embed (Only)",
    fields: ["gross_process_embed_only"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  {
    name: "Scan Only",
    fields: ["requested_slide_scanning_only_count"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  {
    name: "IF Scan Only",
    fields: ["requested_if_slide_scanning_only_count"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  {
    name: "Unstained",
    fields: ["requested_unstained_count"],
    color: HISTOWIZ_PRIMARY_COLOR,
  },
];
