import { createTheme } from "@mui/material/styles";
import { getWhiteLabelThemePalette } from "utilities/whitelabel";
import { PRIMARY_WHITE } from "utilities/colors";
import { grey } from "@mui/material/colors";

const whiteLabelColorPalette = getWhiteLabelThemePalette();

export const MUI_XS_BREAKPOINT_VALUE = 0;
export const MUI_SM_BREAKPOINT_VALUE = 600;
export const MUI_MD_BREAKPOINT_VALUE = 900;
export const MUI_LG_BREAKPOINT_VALUE = 1200;
export const MUI_LAPTOP_BREAKPOINT_VALUE = 1366;
export const MUI_XL_BREAKPOINT_VALUE = 1536;
export const MUI_XXL_BREAKPOINT_VALUE = 1601;

export const histowizTheme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "default" },
          style: {
            color: "black",
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "primary",
        textColor: "inherit",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
  },
  palette: {
    default: {
      main: grey[300],
      dark: grey[400],
    },
    black: {
      main: grey[900],
    },
    background: {
      main: PRIMARY_WHITE,
    },
    primary: {
      // light: will be calculated from palette.primary.main,s
      main: whiteLabelColorPalette["primary"],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: whiteLabelColorPalette["secondary"],
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.75em",
      },
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    useNextVariants: true,
  },
  // Using this property to set snackbar zIndex over OSD navigator and side menu
  zIndex: {
    snackbar: 3200,
  },
  breakpoints: {
    values: {
      xs: MUI_XS_BREAKPOINT_VALUE,
      sm: MUI_SM_BREAKPOINT_VALUE,
      md: MUI_MD_BREAKPOINT_VALUE,
      lg: MUI_LG_BREAKPOINT_VALUE,
      laptop: MUI_LAPTOP_BREAKPOINT_VALUE,
      xl: MUI_XL_BREAKPOINT_VALUE,
      xxl: MUI_XXL_BREAKPOINT_VALUE,
    },
  },
});

const SMOOTH_SCROLL_STYLE = {
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "0.2em",
  },
  scrollBehavior: "smooth",
};

export const VERTICAL_SCROLL_STYLE = {
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  ...SMOOTH_SCROLL_STYLE,
};

export const HORIZONTAL_SCROLL_STYLE = {
  "&::-webkit-scrollbar": {
    height: "0.4em",
  },
  ...SMOOTH_SCROLL_STYLE,
};
