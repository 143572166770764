import { DARK_GREY, LIGHT_GREY, MEDIUM_GREY } from "utilities/colors";
import { PATHOLOGY_MAP_EVEN_SLIDE_DATA_BG_COLOR } from "components/PathologyMap/constants/styles";
import { makeStyles } from "tss-react/mui";

export const useAntibodiesListStyles = makeStyles()(
  (theme, { isFullTable, isDetailsCollapsed }) => ({
    wrapper: {
      display: "flex",
      flexDirection: "row",
    },
    collapseButton: {
      minWidth: "unset",
      padding: 0,
      height: "100%",
    },
    formInput: {
      marginTop: "0.25rem",
      padding: "0.5rem",
      fontSize: "14px",
      width: "100%",
      display: "block",
      borderRadius: "0.125rem",
      border: `1px solid ${MEDIUM_GREY}`,
      boxSizing: "border-box",
      marginBottom: "1rem",
    },
    title: {
      padding: "0.25rem 0 0rem 0.75rem",
      fontSize: "0.75rem",
      color: "grey",
      textTransform: "uppercase",
    },
    subTitle: {
      paddingLeft: "0.75rem",
      textTransform: "uppercase",
      letterSpacing: "1px",
      display: "flex",
      flexDirection: "row",
      maxWidth: "77%",
      [theme.breakpoints.down("xxl")]: {
        maxWidth: "72%",
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: "60%",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "50%",
      },
    },
    truncate: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    gridWrapper: {
      height: "calc(100% - 4rem) !important",
      flexWrap: "nowrap",
    },
    ABSummaryStyle: {
      display: "grid",
      gridTemplateColumns: isFullTable ? "1fr" : "2fr 1fr",
      marginTop: "4rem",
      height: "100%",
      backgroundColor: "white",
      border: `1px solid ${LIGHT_GREY}`,
    },
    cellStyle: {
      "&:nth-of-type(even)": {
        borderRight: `1px solid ${LIGHT_GREY}`,
        textAlign: "right",
      },
      "&:nth-of-type(odd)": {
        borderLeft: `1px solid ${LIGHT_GREY}`,
        width: "8rem",
      },
      padding: "0.5rem",
    },
    rowStyle: {
      "&:nth-of-type(even)": {
        backgroundColor: PATHOLOGY_MAP_EVEN_SLIDE_DATA_BG_COLOR,
      },
    },
    thumbnailStyle: {
      marginTop: "1rem",
      height: "19.5rem",
      maxWidth: "18rem",
    },
    tabs: {
      display: "flex",
      height: "3.5rem",
      background:
        "linear-gradient(180deg, rgba(108, 162, 187, 0.45), rgba(108, 162, 187, 0.10))",
      flexDirection: "column",
      justifyContent: "start",
      "& > .MuiTabs-root": {
        alignItems: "center",
      },
    },
    centered: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      textAlign: "center",
      margin: "auto",
    },
    detailsWrapper: {
      height: "85vh",
      display: isDetailsCollapsed ? "none" : "flex",
      flexDirection: "row",
    },
    detailsCollapsibleContainer: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    agGrid: {
      height: "69vh",
      backgroundColor: "transparent",
    },
    baseTabSelector: {
      "& > span": {
        margin: "0",
      },
      color: `${DARK_GREY} !important`,
      display: "flex",
      flexDirection: "column",
      height: "2.9rem",
      width: "5rem",
    },
    tabSelectorActive: {
      borderRadius: "6px",
      border: `2px solid #8897F3`,
      backgroundColor: "transparent",
      minHeight: "unset",
      boxShadow: "0px 4px 4px 0px #00000040",
      padding: "0.3rem",
      margin: "-2.5rem 0.5rem 0.5rem 0",
    },
    tabSelector: {
      margin: "-2.5rem 1rem 0 0",
    },
    rightFloat: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    viewerContainer: {
      height: "77vh",
      width: "45vw",
    },
  })
);

export const useAntibodiesPageStyles = makeStyles()((theme) => ({
  root: {
    height: "100vh",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  logoWrapper: {
    marginBottom: theme.spacing(3),
  },
  logo: {
    width: "20%",
    marginBottom: "-1.5rem",
  },
  antibodiesListWrapper: {
    height: "85vh",
  },
}));
