import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

export const AdminInstanceLink = ({ url }) => {
  const { classes } = useStyles();

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
    >
      <Tooltip disableInteractive title="LIS Admin" placement="right">
        <ListItem button>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="LIS Admin" />
        </ListItem>
      </Tooltip>
    </a>
  );
};
