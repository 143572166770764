import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Select from "react-select";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import React, { useState } from "react";
import { DefaultSubmitButton } from "components/Shared/Buttons/DefaultSubmitButton";
import { Controller, useForm } from "react-hook-form";
import { createPanel } from "services/resources/commonResources";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, object, string } from "yup";
import { shouldDisplayError } from "components/common";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useCreatePanelModalStyles } from "./styles";

const validationSchema = object().shape({
  name: string().required("Name Is Required"),
  antibodies: array().min(1, "Antibodies Are Required"),
  description: string().notRequired(),
});

const defaultValues = {
  name: "",
  antibodies: [],
};

export const CreatePanelModal = ({
  antibodies,
  open,
  onClose,
  onAfterSuccessCreate,
  teamUUID,
}) => {
  const { classes } = useCreatePanelModalStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { submitCount, errors, touchedFields },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const { showSuccess, showError } = useSnackbar();

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const postParams = {
      name: values.name,
      antibody_uuids: values.antibodies.map((antibody) => antibody.uuid),
      team_uuid: teamUUID,
      description: values.description,
    };

    createPanel(postParams)
      .then((response) => {
        showSuccess("Created Panel is now available in IF Panels list");
        onClose();
        onAfterSuccessCreate(response);
      })
      .catch(() => showError("Panel wasn't created. Please try again"))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <DefaultDialog open={open} onClose={onClose} title="Create New IF Panel">
      <Box height="max-content" width="75vw">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            direction="column"
          >
            <Grid item>
              <TextField
                fullWidth={true}
                label={`IF Panel Name`}
                margin="normal"
                {...register("name")}
              />
              {shouldDisplayError(
                !!errors?.name,
                !!touchedFields?.name,
                submitCount
              ) && (
                <div className={classes.errorFeedback}>
                  {errors.name.message}
                </div>
              )}
            </Grid>

            <Grid item>
              <Controller
                control={control}
                name="antibodies"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <Select
                    options={antibodies}
                    isMulti={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    placeholder={"Select antibodies ... "}
                    getOptionLabel={(data) => data.display_name}
                    getOptionValue={(data) => data.uuid}
                  />
                )}
              />
              {shouldDisplayError(
                !!errors?.antibodies,
                !!touchedFields?.antibodies,
                submitCount
              ) && (
                <div className={classes.errorFeedback}>
                  {errors.antibodies.message}
                </div>
              )}
            </Grid>

            <Grid item>
              <TextField
                multiline
                fullWidth={true}
                label={`IF Panel Description`}
                margin="normal"
                {...register("description")}
              />
            </Grid>
          </Grid>
          <DefaultSubmitButton disabled={isSubmitting}>
            Create Panel
          </DefaultSubmitButton>
        </form>
      </Box>
    </DefaultDialog>
  );
};
