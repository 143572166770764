import { makeStyles } from "tss-react/mui";
import { PRIMARY_BLUE } from "utilities/colors";

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset",
    minWidth: "40rem",

    [theme.breakpoints.down("xl")]: {
      minWidth: "unset",
    },
  },
  tabContent: {
    height: "calc(100% - 77px)",
    padding: "0 2rem",
    overflowY: "auto",
    background: "white",

    [theme.breakpoints.down("xl")]: {
      padding: "0 0.5rem",
      height: "calc(100% - 56px)",
    },
  },
  sortsContainer: {
    paddingTop: "1rem",
  },
  groupsSortLabel: {
    textTransform: "uppercase",
    lineHeight: 2,
    fontSize: "0.75em",
    cursor: "pointer",
    fontWeight: 600,
    whiteSpace: "nowrap",

    [theme.breakpoints.down("xl")]: {
      fontSize: "0.5rem",
    },
  },
  groupsRowContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    padding: "0.3rem 0",
    cursor: "pointer",
    borderBottom: "1px solid #dadce0",

    "&:hover": {
      backgroundColor: `${PRIMARY_BLUE}16`,
    },
  },
  groupsRowsContainer: {
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

export const useScrollStyles = makeStyles()((theme) => ({
  root: {
    overflowY: "auto",

    [theme.breakpoints.up("md")]: {
      listStyle: "none",

      "&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-corner":
        {
          height: "1rem",
          width: "0.4rem",
          background: "transparent",
        },

      "&::-webkit-scrollbar-button": {
        width: 0,
        height: 0,
      },

      "&::-webkit-scrollbar-thumb": {
        border: "6px solid transparent",
        borderRadius: "0.5rem",
        color: "#dadce0",
        background: "#dadce0",
        minHeight: 60,
        minWidth: 60,
      },

      "&::-webkit-scrollbar-thumb:hover": {
        color: "#80868b",
        background: "#80868b",
      },

      "&::-webkit-scrollbar-thumb:active": {
        color: "#5f6368",
        background: "#5f6368",
      },
    },
  },
}));
