import {
  DEFAULT_PACKAGE_HEIGHT as height,
  DEFAULT_PACKAGE_LENGTH as length,
  DEFAULT_PACKAGE_WEIGHT as weight,
  DEFAULT_PACKAGE_WIDTH as width,
  LABEL_CREATED_MESSAGE,
  OvernightShippingValue,
  SHIPPO_ERROR,
  SHIPPO_ERROR_MESSAGE,
} from "components/OrderForm/BillingShipping/utilities/constants";
import { ORDER_FETCHING_ERROR_MESSAGE } from "components/OrderForm/constants";
import {
  FETCH_ORDER_SHIPMENT_ERROR_MESSAGE,
  FETCH_PROJECTS_ERROR_MESSAGE,
  FETCH_PURCHASE_ORDERS_ERROR_MESSAGE,
  POST_ORDER_SHIPMENT_ERROR_MESSAGE,
} from "constants/errorMessages";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { AppContext } from "services/context";
import { getTeams } from "services/resources/commonResources";
import {
  getBlanketPurchaseOrders,
  getOrderShipments,
  postOrderShipment,
} from "services/resources/orders";
import { useGetOrderByUUIDQuery } from "store/apis/orderApi";
import {
  setBlanketPurchaseOrders,
  setOrder,
  setTeamUUID,
  setSubmitting,
  reset,
  billingShippingSelectors,
} from "store/slices/billingShippingSlice/billingShippingSlice";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";

export const useBillingShippingView = () => {
  const { blanketPurchaseOrders, shippingAddressSelected, order } = useSelector(
    billingShippingSelectors.viewPage
  );

  const dispatch = useDispatch();
  const { email } = useContext(AppContext);
  const unmountIgnore = useUnmountIgnore();
  const { showError, showSuccess } = useSnackbar();

  const [shippingLabels, setShippingLabels] = useState(null);
  const [shippingDimensionsModal, setShippingDimensionsModal] = useState(false);
  const [shippingOption, setShippingOption] = useState(OvernightShippingValue);

  const {
    params: { orderUUID },
  } = useRouteMatch();

  const { data: orderData, error } = useGetOrderByUUIDQuery(orderUUID, {
    skip: !orderUUID,
  });
  useQueryError(error, ORDER_FETCHING_ERROR_MESSAGE);

  useEffect(() => {
    getOrderShipments(orderUUID)
      .then((response) => {
        if (response.length && !unmountIgnore.current) {
          setShippingLabels(response);
        }
      })
      .catch(() => showError(FETCH_ORDER_SHIPMENT_ERROR_MESSAGE));
  }, [orderUUID, unmountIgnore]);

  useEffect(() => {
    getTeams()
      .then((response) => {
        if (unmountIgnore.current) return;
        const teamUUID = response.data[0].uuid;
        dispatch(setTeamUUID(teamUUID));
      })
      .catch(() => showError(FETCH_PROJECTS_ERROR_MESSAGE));
  }, [unmountIgnore]);

  useEffect(() => {
    if (!unmountIgnore.current) {
      getBlanketPurchaseOrders()
        .then((res) => {
          dispatch(setBlanketPurchaseOrders(res));
        })
        .catch(() => showError(FETCH_PURCHASE_ORDERS_ERROR_MESSAGE));
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (blanketPurchaseOrders && orderData) {
      dispatch(setOrder(orderData));
    }
  }, [blanketPurchaseOrders, orderData]);

  const handleModalChange = (name) => () => {
    const mapSetterToModalName = {
      shippingDimensionsModal: setShippingDimensionsModal,
    };

    const setter = mapSetterToModalName[name];
    setter((prevValue) => !prevValue);
  };

  const handleShippoError = (error) => {
    const isShippoError = error?.includes(SHIPPO_ERROR);
    const errorMessage = isShippoError
      ? JSON.parse(error.replace(SHIPPO_ERROR, "") || "{}").text ??
        SHIPPO_ERROR_MESSAGE
      : POST_ORDER_SHIPMENT_ERROR_MESSAGE;
    showError(errorMessage);
  };

  const submitFedExComponent = () => {
    dispatch(setSubmitting(true));

    const postParams = {
      address_from_uuid: shippingAddressSelected.uuid,
      width,
      height,
      weight,
      length,
      servicelevel_token: shippingOption,
    };

    const onSuccess = () => showSuccess(LABEL_CREATED_MESSAGE);

    postOrderShipment(orderUUID, postParams)
      .then((response) => {
        dispatch(setSubmitting(false));
        if (!shippingLabels) {
          setShippingLabels([response]);
          onSuccess();
          return;
        }
        // Check if we need to add a new shipping label or update an existing one
        const shippingLabelIndex = shippingLabels.findIndex(
          (label) => label.uuid === response.uuid
        );

        if (shippingLabelIndex !== -1) {
          shippingLabels[shippingLabelIndex] = response;
        } else {
          setShippingLabels([...shippingLabels, response]);
        }

        onSuccess();
      })
      .catch((e) => {
        dispatch(setSubmitting(false));
        const errorDetails = e.response?.data?.detail;
        handleShippoError(errorDetails);
      });
  };

  return {
    order,
    shippingLabels,
    email,
    handleModalChange,
    shippingOption,
    submitFedExComponent,
    setShippingOption,
    shippingDimensionsModal,
  };
};
