import { useSelector } from "react-redux";
import { currentUserPreferencesSelector } from "store/slices/userDetailsSlice";
import {
  ANNOTATION_DRAW_MODE_ENABLED_FIELD,
  ANNOTATION_DRAW_TOOL_FIELD,
  ANNOTATIONS_SWITCH_FIELD,
  NAVIGATOR_SWITCH_FIELD,
} from "components/ImageViewer/constants";
import React from "react";
import Grid from "@mui/material/Grid";
import { MultiSlideViewItem } from "components/ImageViewer/MultiSlideViewItem";

const ViewerWrapper = ({ useMultiSlide, children }) => {
  // for cases when multiple slide selected we're using Grids to display em
  // to display just single slide fullscreen no Grids needed

  return useMultiSlide ? (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {children}
    </Grid>
  ) : (
    <>{children}</>
  );
};

export const DefaultImageViewer = ({
  slides,
  isBookmarkFeatureEnabled = false,
  setAnnotationsCount,
  handleNextSlide,
  handlePreviousSlide,
  isSingleViewer,
  gridApi,
  setAnnotationsLegend,
  currentSlide,
}) => {
  const preferences = useSelector(currentUserPreferencesSelector);

  if (!slides) {
    return;
  }

  const useMultiSlide = slides.length > 1;
  const currentSlides =
    !useMultiSlide && currentSlide ? [currentSlide] : slides;
  const showSlidesNavigation = !isSingleViewer && !useMultiSlide;

  return (
    <ViewerWrapper useMultiSlide={useMultiSlide}>
      {currentSlides.map((row) => (
        <MultiSlideViewItem
          slide={row}
          key={row.uuid}
          showNavigator={preferences[NAVIGATOR_SWITCH_FIELD]}
          annotationsEnabled={preferences[ANNOTATIONS_SWITCH_FIELD]}
          annotationDrawTool={preferences[ANNOTATION_DRAW_TOOL_FIELD]}
          annotationDrawModeEnabled={
            preferences[ANNOTATION_DRAW_MODE_ENABLED_FIELD]
          }
          isBookmarkFeatureEnabled={isBookmarkFeatureEnabled}
          isGT450ScannerFiltersEnabled={false}
          isMulti={useMultiSlide}
          isDistanceMeasureSupported={true}
          setAnnotationsCount={setAnnotationsCount}
          handleNextSlide={handleNextSlide}
          handlePreviousSlide={handlePreviousSlide}
          showSlidesNavigation={showSlidesNavigation}
          gridApi={gridApi}
          setAnnotationsLegend={setAnnotationsLegend}
        />
      ))}
    </ViewerWrapper>
  );
};
