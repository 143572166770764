import { baseCreateApi } from "store/apis/baseCreateApi";
import {
  BLOCK_COMBINE_ACTION_RESOURCE_NAME,
  BLOCK_RESOURCE_NAME,
  ORDER_RESOURCE_NAME,
  SAMPLES_HISTORY_RESOURCE_NAME,
  SAMPLES_PAGINATED_RESOURCE_NAME,
  SAMPLES_RESOURCE_NAME,
  SAMPLES_SCIENCE_INFO,
  SAMPLES_UPDATED_FROM_FILE_RESOURCE_NAME,
  SAMPLE_ANTIBODY_REQUESTED,
  SAMPLE_PANEL_REQUESTED,
  SAMPLE_SERVICES_REQUESTED,
  SAMPLE_SPECIAL_STAIN_REQUESTED,
  SIMPLE_SAMPLE_SERVICES_REQUESTED,
  TEAM_SAMPLES_RESOURCE_NAME,
} from "services/resources/apiConstants";
import { devLogger } from "utilities/devLogger";
import { SUBMITTED_PARAM } from "components/SampleEditor/helpers/constants";

export const samplesApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getSamplesPaginated: builder.query({
      query: ({ page = 1, sortBy = "", search = "", limit = 50 }) => {
        const sort = sortBy ? `&ordering=${sortBy}` : "";

        const pageValue = page ? `&page=${page}` : "";
        const searchValue = search.trim().replaceAll(" ", "-");
        const searchParam = searchValue ? `&search=${searchValue}` : "";
        const allowEditParam = `&${SUBMITTED_PARAM}=true`;

        return {
          url: `/${SAMPLES_PAGINATED_RESOURCE_NAME}?page_size=${limit}${pageValue}${sort}${searchParam}${allowEditParam}`,
          method: "GET",
        };
      },
      providesTags: ["getSamplesPaginated"],
    }),
    postSamples: builder.mutation({
      query: (updateParams) => ({
        url: `${SAMPLES_RESOURCE_NAME}?change_reason=changed_from_sample_editor`,
        method: "POST",
        body: updateParams,
      }),
    }),
    importFileSamples: builder.mutation({
      query: (formData) => ({
        url: SAMPLES_UPDATED_FROM_FILE_RESOURCE_NAME,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["getSamplesPaginated"],
    }),
    getSimpleSamplesInfo: builder.query({
      query: ({ orderUUID }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SIMPLE_SAMPLE_SERVICES_REQUESTED}`,
        method: "GET",
      }),
    }),
    getSamplesServicesRequested: builder.query({
      query: ({ orderUUID }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_SERVICES_REQUESTED}`,
        method: "GET",
      }),
    }),
    postSampleServicesRequested: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_SERVICES_REQUESTED}`,
        method: "POST",
        body: postParams,
      }),
    }),
    getSampleAntibodiesRequested: builder.query({
      query: ({ orderUUID }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_ANTIBODY_REQUESTED}`,
        method: "GET",
      }),
    }),
    postSampleAntibodiesRequested: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_ANTIBODY_REQUESTED}`,
        method: "POST",
        body: postParams,
      }),
    }),
    getSampleSpecialStainsRequested: builder.query({
      query: ({ orderUUID }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_SPECIAL_STAIN_REQUESTED}`,
        method: "GET",
      }),
    }),
    postSampleSpecialStainsRequested: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_SPECIAL_STAIN_REQUESTED}`,
        method: "POST",
        body: postParams,
      }),
    }),
    getSamplePanelsRequested: builder.query({
      query: ({ orderUUID }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_PANEL_REQUESTED}`,
        method: "GET",
      }),
    }),
    postSamplePanelsRequested: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLE_PANEL_REQUESTED}`,
        method: "POST",
        body: postParams,
      }),
    }),
    getSamplesByOrderId: builder.query({
      query: ({ orderUUID }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLES_RESOURCE_NAME}`,
        method: "GET",
      }),
    }),
    getSamplesFromOrders: builder.query({
      query: ({ orderUUIDs }) => ({
        url: `${SAMPLES_RESOURCE_NAME}?order_uuids=${orderUUIDs}`,
        method: "GET",
      }),
    }),
    getTeamSamples: builder.query({
      query: ({ teamUUID, excludeMatchedBlockSamples = false }) => {
        let url = TEAM_SAMPLES_RESOURCE_NAME.replace(":teamUUID", teamUUID);
        if (excludeMatchedBlockSamples) {
          url = `${url}?exclude_matched_block_samples=true`;
        }

        return {
          url,
          method: "GET",
        };
      },
    }),
    postSamplesScienceInfo: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLES_SCIENCE_INFO}`,
        method: "POST",
        body: postParams,
      }),
    }),
    deleteSamples: builder.mutation({
      query: ({ orderUUID, sample_uuids }) => ({
        url: `${ORDER_RESOURCE_NAME}${orderUUID}/samples/`,
        method: "DELETE",
        body: { sample_uuids },
      }),
    }),
    mergeBlocksAction: builder.mutation({
      query: ({ block_uuids }) => {
        const url = `${BLOCK_RESOURCE_NAME}${BLOCK_COMBINE_ACTION_RESOURCE_NAME}`;
        devLogger("mergeBlocksAction", block_uuids);
        const dataParams = { block_uuids: block_uuids };
        return {
          url,
          method: "POST",
          body: dataParams,
        };
      },
    }),
    getChangedSamples: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `${SAMPLES_HISTORY_RESOURCE_NAME}?start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      }),
    }),
    getSampleHistory: builder.query({
      query: ({ sampleId, date }) => ({
        url: `${SAMPLES_HISTORY_RESOURCE_NAME}${sampleId}/?date=${date}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetSamplesPaginatedQuery,
  useLazyGetSamplesPaginatedQuery,
  usePostSamplesMutation,
  useImportFileSamplesMutation,
  useLazyGetSimpleSamplesInfoQuery,
  useLazyGetSamplesServicesRequestedQuery,
  usePostSampleServicesRequestedMutation,
  useLazyGetSampleAntibodiesRequestedQuery,
  usePostSampleAntibodiesRequestedMutation,
  useLazyGetSampleSpecialStainsRequestedQuery,
  usePostSampleSpecialStainsRequestedMutation,
  useLazyGetSamplePanelsRequestedQuery,
  usePostSamplePanelsRequestedMutation,
  useLazyGetSamplesByOrderIdQuery,
  useLazyGetSamplesFromOrdersQuery,
  useLazyGetTeamSamplesQuery,
  usePostSamplesScienceInfoMutation,
  useDeleteSamplesMutation,
  useMergeBlocksActionMutation,
  useLazyGetChangedSamplesQuery,
  useLazyGetSampleHistoryQuery,
} = samplesApi;
