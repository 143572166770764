import classNames from "classnames";
import CheckIcon from "@mui/icons-material/Check";

export const StepIcon = (props) => {
  const { active, completed, classes } = props;

  return (
    <div
      className={classNames(classes.step, {
        [classes.activeStep]: active,
        [classes.completeStep]: completed && !active,
        [classes.inactiveStep]: !active && !completed,
      })}
    >
      {active && <div className={classes.activeStepCenter} />}
      {completed && !active && <CheckIcon className={classes.checkMark} />}
    </div>
  );
};
