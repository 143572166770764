import { Box, Typography, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useSlideMetadataRowStyles } from "components/OrderSlidesList/SlideActionTabs/SlideMetadata/SlideMetadataRow/styles";

export const SlideMetadataRow = ({
  label,
  value,
  keyTooltip,
  valueTooltip,
}) => {
  const { classes } = useSlideMetadataRowStyles();
  const displayValue = value === 0 ? "0" : value || "—";

  const ValueWithTooltip = ({ className, value, tooltip }) => {
    return (
      <Typography className={className}>
        {tooltip ? (
          <Tooltip title={tooltip} classes={{ popper: classes.tooltip }}>
            <span>{value}</span>
          </Tooltip>
        ) : (
          value
        )}
      </Typography>
    );
  };

  return (
    <Box className={classes.root}>
      <ValueWithTooltip
        className={classes.label}
        value={label}
        tooltip={keyTooltip}
      />
      <ValueWithTooltip
        className={classes.value}
        value={displayValue}
        tooltip={valueTooltip}
      />
    </Box>
  );
};

SlideMetadataRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyTooltip: PropTypes.string,
  valueTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
