import React from "react";
import { SubmittingIcon } from "../../icons/LoadingIcon";

import { Button, Typography } from "@mui/material";
import { ShipSpecifications } from "components/OrderForm/BillingShipping/BillingShippingComponents";
import Box from "@mui/material/Box";
import {
  CREATE_FED_EX_LABEL,
  GENERATE_ADDITIONAL_LABEL,
} from "components/OrderForm/BillingShipping/utilities/constants";

const editLabelStyle = { cursor: "pointer", color: "blue" };
const createFedexLabelStyle = { marginTop: "1rem", display: "flex" };

export const FedExComponent = ({
  isValidCountry,
  shippingLabels,
  submitFedExComponent,
  shippingOption,
  submitting,
  handleModal,
  width,
  height,
  length,
  weight,
}) => (
  <>
    <ShipSpecifications />
    <Box mt={2}>
      If your address above is correct, click below to generate a FedEx Label.{" "}
      <Typography variant={"h6"}>
        Package Specifications{" "}
        <span style={editLabelStyle} onClick={handleModal}>
          (Edit)
        </span>
      </Typography>
    </Box>
    <Typography>Width: {width} inches</Typography>
    <Typography>Length: {length} inches</Typography>
    <Typography>Height: {height} inches</Typography>
    <Typography>Weight: {weight} pounds</Typography>
    <Typography>Shipping: {shippingOption} </Typography>
    <Button
      variant="contained"
      color="secondary"
      onClick={submitFedExComponent}
      disabled={submitting || !isValidCountry}
      style={createFedexLabelStyle}
    >
      <SubmittingIcon submitting={submitting} />
      {!!shippingLabels?.length
        ? GENERATE_ADDITIONAL_LABEL
        : CREATE_FED_EX_LABEL}
    </Button>
    <Box mt={1}>
      All generated labels have automatic tracking notifications. FedEx Labels
      are automatically emailed at Order Submission.
    </Box>
  </>
);
