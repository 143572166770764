import { PathologyMapActiveFiltersHeader } from "components/PathologyMap/PathologyMapActiveFilters/PathologyMapActiveFiltersHeader";
import { PathologyMapActiveFiltersItems } from "components/PathologyMap/PathologyMapActiveFilters/PathologyMapActiveFiltersItems";
import { PathologyMapSectionWrapper } from "components/PathologyMap/Shared/PathologyMapSectionWrapper";

export const PathologyMapActiveFiltersSection = () => {
  return (
    <PathologyMapSectionWrapper isVerticallyAdjustable={true}>
      <PathologyMapActiveFiltersHeader />
      <PathologyMapActiveFiltersItems />
    </PathologyMapSectionWrapper>
  );
};
