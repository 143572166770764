import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  useGetGlpOrderQuery,
  useUpdateGlpOrderMutation,
  useGetGlpSearchDataQuery,
} from "store/apis/glpOrderApi";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import {
  FETCH_ORDER_ERROR_MESSAGE,
  UPDATE_ORDER_ERROR_MESSAGE,
} from "constants/errorMessages";
import {
  getGlpFormFields,
  getSubmitGlpFields,
} from "components/GlpForm/helpers/functions";
import { getOrderFormSamplesURL } from "components/OrderForm/Info/utilities";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useGlpOrder = (uuid) => {
  const { error, data, loading } = useGetGlpOrderQuery(uuid, { skip: !uuid });

  useQueryError(error, FETCH_ORDER_ERROR_MESSAGE);

  return { glpOrder: data, loading };
};

export const useGlpForm = (order) => {
  const [fields, setFields] = useState(getGlpFormFields());

  const handleField = (key, value) =>
    setFields((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    setFields(getGlpFormFields(order));
  }, [order]);

  return { fields, handleField };
};

export const useGlpFormSubmit = (orderUUID, fields) => {
  const [mutation, { error, isLoading }] = useUpdateGlpOrderMutation();

  const { push } = useHistory();

  const { showError } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation({ orderUUID, fields: getSubmitGlpFields(fields) })
      .unwrap()
      .then(({ order: { uuid } }) => push(getOrderFormSamplesURL(uuid)))
      .catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));
  };

  useQueryError(error, UPDATE_ORDER_ERROR_MESSAGE);

  return { handleSubmit, isLoading };
};

export const useGlpSearchData = () => {
  const { data } = useGetGlpSearchDataQuery();
  const { test_articles, organizations } = data ?? {};

  return {
    articles: test_articles ?? [],
    organizations: organizations ?? [],
  };
};

export const useGlpProtocol = (initialGlp) => {
  const [protocolFiles, setProtocolFiles] = useState([]);
  const [isGLP, setIsGLP] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleGlp = () => isGLP && !protocolFiles.length && setHasError(true);

  useEffect(() => {
    setIsGLP(!!initialGlp);
  }, [initialGlp]);

  return {
    protocolFiles,
    setProtocolFiles,
    isGLP,
    setIsGLP,
    hasError,
    handleGlp,
  };
};
