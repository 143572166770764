import React, { Fragment, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { useViewerStore } from "components/IFViewer/state";
import { ChannelPreset } from "components/IFViewer/components/Controller/components/ChannelPreset";
import _ from "lodash";
import { updateOrder } from "services/resources/orders";
import { POST_PRESETS_ERROR_MESSAGE } from "constants/errorMessages";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { OPTIONS } from "components/IFViewer/constants";

export const ChannelPresetsTab = React.memo((props) => {
  const { order, classes, setTakenColors } = props;
  const { showError, showSuccess } = useSnackbar();

  const customChannelOptions = useViewerStore(
    (store) => store.customChannelOptions
  );
  const [isPresetsEditMode, setIsPresetsEditMode] = useState(false);
  const [isOrderFetching, setIsOrderFetching] = useState(false);

  const [presets, setPresets] = useState(
    Object.keys(customChannelOptions)
      .filter((option) => option !== OPTIONS)
      .map((originalName) => ({
        originalName,
        customName: customChannelOptions[originalName],
      }))
  );
  const [duplicatedChannelsOriginalNames, setDuplicatedChannelsOriginalNames] =
    useState([]);
  const [originalPresets, setOriginalPresets] = useState(null);

  const updateOriginalPresets = () => {
    setOriginalPresets(_.cloneDeep(presets));
  };

  useEffect(() => {
    if (!presets.length) return;

    const duplicatedCustomNames = presets
      .map((preset) => preset.customName)
      .filter(
        (channelCustomName, index, arr) =>
          arr.indexOf(channelCustomName) !== index
      );

    const updatedDuplicatedChannelNames = presets
      .filter((el) => duplicatedCustomNames.includes(el.customName))
      .map((preset) => preset.originalName);

    if (
      !_.isEqual(duplicatedChannelsOriginalNames, updatedDuplicatedChannelNames)
    ) {
      setDuplicatedChannelsOriginalNames(updatedDuplicatedChannelNames);
    }
  }, [presets]);

  useEffect(() => {
    if (!isPresetsEditMode) return;

    updateOriginalPresets();
  }, [isPresetsEditMode]);

  const changeChannelName = (originalName, newName) => {
    setPresets((prevValue) =>
      prevValue.map((preset) =>
        preset.originalName === originalName
          ? {
              ...preset,
              customName: newName,
            }
          : preset
      )
    );
  };

  const postUpdatedOrder = (presets) => {
    if (!order?.uuid) return;

    setIsOrderFetching(true);

    updateOrder(order.uuid, { preferences: presets })
      .then(() => {
        setIsPresetsEditMode(false);
        const comparedNames = Object.keys(presets).reduce(
          (acc, currentName) => {
            acc[customChannelOptions[currentName]] = presets[currentName];

            return acc;
          },
          {}
        );
        setTakenColors((prev) =>
          prev.map((color) => comparedNames[color] || color)
        );
        useViewerStore.setState({
          customChannelOptions: presets,
        });
        showSuccess("Presets were successfully updated");
        updateOriginalPresets();
      })
      .catch(() => showError(POST_PRESETS_ERROR_MESSAGE))
      .finally(() => setIsOrderFetching(false));
  };

  const onSubmit = () => {
    const parsedPresets = presets.reduce((finalOptions, currentValue) => {
      finalOptions[currentValue.originalName] = currentValue.customName;
      return finalOptions;
    }, {});
    parsedPresets[OPTIONS] = customChannelOptions[OPTIONS];
    postUpdatedOrder(parsedPresets);
  };

  const onCancel = () => {
    setPresets(_.cloneDeep(originalPresets));
    setIsPresetsEditMode(false);
  };

  const onReset = () => {
    const defaultPresets = presets.reduce((finalPresets, currentValue) => {
      finalPresets[currentValue.originalName] = currentValue.originalName;
      return finalPresets;
    }, {});

    const parsedDefaultPresets = Object.keys(defaultPresets).map(
      (defaultPreset) => {
        return {
          originalName: defaultPreset,
          customName: defaultPresets[defaultPreset],
        };
      }
    );

    setPresets([...parsedDefaultPresets]);
    postUpdatedOrder(defaultPresets);
  };
  const isSubmitButtonDisabled = useMemo(
    () =>
      !!presets.find((preset) => !preset.customName.length) ||
      !!duplicatedChannelsOriginalNames.length ||
      _.isEqual(presets, originalPresets) ||
      isOrderFetching,
    [presets, duplicatedChannelsOriginalNames, isOrderFetching, originalPresets]
  );

  return (
    <Fragment>
      <Box className={classes.channelsLabels} display="flex" direction="row">
        <Typography
          className={classes.columnLabel}
          variant={"body1"}
          color={"secondary"}
        >
          Default
        </Typography>
        <KeyboardDoubleArrowRightIcon
          className={classes.arrowIcon}
          fontSize={"medium"}
          sx={{
            pointerEvents: "auto",
          }}
        />
        <Typography
          className={classes.columnLabel}
          variant={"body1"}
          color={"secondary"}
        >
          Custom
        </Typography>
      </Box>
      {presets.map((option) => (
        <ChannelPreset
          key={option.originalName}
          isEditMode={isPresetsEditMode}
          originalName={option.originalName}
          customName={option.customName}
          changePreset={changeChannelName}
          isDuplicated={duplicatedChannelsOriginalNames.includes(
            option.originalName
          )}
        />
      ))}
      <Box display="flex" justifyContent="space-between">
        {isPresetsEditMode ? (
          <Fragment>
            <Button
              variant={"contained"}
              color="secondary"
              onClick={onReset}
              disabled={isOrderFetching}
              id="reset-if-presets"
            >
              Reset to defaults
            </Button>
            <Button
              variant={"contained"}
              color="success"
              onClick={onSubmit}
              disabled={isSubmitButtonDisabled}
              id="submit-if-presets"
            >
              Submit
            </Button>
            <Button
              id="cancel-if-presets"
              variant={"contained"}
              color="error"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Fragment>
        ) : (
          <Button
            id="edit-if-presets"
            className={classes.editPresetsButton}
            variant={"contained"}
            onClick={() => {
              setIsPresetsEditMode(true);
            }}
          >
            Edit presets
          </Button>
        )}
      </Box>
    </Fragment>
  );
});
