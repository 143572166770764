import {
  AlternativeTableCell,
  AlternativeTableCellWithTypography,
  editableConsultationStates,
} from "components/OrdersList/constants";
import {
  ORDER_DETAILS_URL,
  PATHOLOGY_CONSULTATION_INFO_VIEW_URL,
  PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL,
} from "constants/urls";
import { TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import moment from "moment";
import { DATE_REQUEST_FORMAT } from "utilities/dates_and_times";
import React from "react";
import PageViewIcon from "@mui/icons-material/Pageview";
import { textDecorationStyle, useConsultationTableRowStyles } from "./styles";

export const ConsultationTableRow = ({ row }) => {
  const { classes } = useConsultationTableRowStyles();

  let editableState = editableConsultationStates.includes(row.state);

  let consultDetailsURL = "";
  if (editableState) {
    consultDetailsURL =
      PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL.replace(
        ":consultUUID",
        row.uuid
      );
  } else {
    consultDetailsURL = PATHOLOGY_CONSULTATION_INFO_VIEW_URL.replace(
      ":consultUUID",
      row.uuid
    );
  }

  const rowNameSerialized = `${row.name}`;
  const hasHistologyOrder = !!row.order;
  const orderNameSerialized = row.order ? row.order.name : "";

  const renderHistologyOrderTableCell = () => {
    if (!hasHistologyOrder) {
      return;
    }

    const histologyDetailsURL =
      ORDER_DETAILS_URL.replace(":orderUUID", row.order.uuid) + "#summary";
    return (
      <Link
        to={histologyDetailsURL}
        style={textDecorationStyle}
        target={"_blank"}
      >
        {orderNameSerialized}
      </Link>
    );
  };

  const submittedUser = row.submitted_user
    ? `by ${row.submitted_user.first_name} ${row.submitted_user.last_name}`
    : "";

  const ActionItemIcon = editableState ? EditIcon : PageViewIcon;
  const orderNameDisplayComponent = (
    <>
      <Typography
        component={"span"}
        display={"inline"}
        noWrap={true}
        variant={"body1"}
        className={classes.rowCell}
      >
        {rowNameSerialized} <ActionItemIcon />
      </Typography>
    </>
  );

  const actionsText = editableState ? "Edit" : "View";

  const ActionsSection = (
    <Typography component={"span"} wrap={"nowrap"} variant={"body1"}>
      <div style={textDecorationStyle}>
        <Link to={consultDetailsURL}>{actionsText}</Link>
      </div>
    </Typography>
  );

  return (
    <TableRow className={classes.row}>
      <AlternativeTableCell>
        {consultDetailsURL ? (
          <div style={textDecorationStyle}>
            <Link to={consultDetailsURL}>{orderNameDisplayComponent}</Link>
          </div>
        ) : (
          orderNameDisplayComponent
        )}
      </AlternativeTableCell>
      {/*<AlternativeTableCellWithTypography>
      Should be where project will be when we add the linking
      </AlternativeTableCellWithTypography>*/}
      <AlternativeTableCellWithTypography>
        {/*{orderNameSerialized}*/}
        {renderHistologyOrderTableCell()}
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        {row.team.name}
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        {ActionsSection}
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        {row.state_label}
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        {moment(row.created).format(DATE_REQUEST_FORMAT)} {submittedUser}
      </AlternativeTableCellWithTypography>
    </TableRow>
  );
};
