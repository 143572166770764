import { useDispatch } from "react-redux";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { removeSlideAttribute, updateSlide } from "store/slices/slidesSlice";
import {
  removeSlideAttributeFromList,
  updateSelectedRow,
} from "store/slices/slidesListSlice/slidesListSlice";
import {
  AI_MODEL_EVENTS,
  AI_MODELS_STATES,
  QC_AI_MODELS,
} from "components/AITools/constants/common";
import { useLazyGetSlideMLDataQuery } from "store/apis/slidesApi";

export const isSlideTissueMLDataAvailable = (slide, model) => {
  const attribute = `${model}_state`;
  const isAvailable = slide[attribute] === AI_MODELS_STATES.EXISTS;
  return isAvailable;
};

export const isQCModelExists = (slide, modelName) => {
  return !!slide?.additional_data?.[modelName]?.regions;
};

export const isQCModel = (modelName) => {
  return Object.values(QC_AI_MODELS).includes(modelName);
};

export const useSlidesMlData = () => {
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const [getSlideMLData] = useLazyGetSlideMLDataQuery();

  const getSlidesMLData = (slides, modelName) => {
    return Promise.all(
      slides.map((slide) => {
        if (slide[modelName]) {
          return Promise.resolve();
        }

        return getSlideMLData({ slideUUID: slide.uuid, model: modelName })
          .unwrap()
          .then((mlData) => {
            const updatedSlide = { ...slide, ...mlData };
            dispatch(updateSlide(updatedSlide));
            dispatch(updateSelectedRow(updatedSlide));
          })
          .catch(() => {
            showError(
              `Failed to get ML data for slide ${slide.name} ${modelName}`
            );
          });
      })
    );
  };

  const removeSlidesMLData = (slides, modelName) => {
    slides.forEach((currentSlide) => {
      const model = modelName.split("_")[1];
      dispatch(
        removeSlideAttributeFromList({
          uuid: currentSlide.uuid,
          attribute: model,
        })
      );
      dispatch(
        removeSlideAttribute({ uuid: currentSlide.uuid, attribute: model })
      );
    });
  };

  return { getSlidesMLData, removeSlidesMLData };
};

export const raiseOpacityChangeEvent = (tissueOpacity, qcOpacity) => {
  document.dispatchEvent(
    new CustomEvent(AI_MODEL_EVENTS.OPACITY_CHANGE, {
      detail: {
        tissueOpacity,
        qcOpacity,
      },
    })
  );
};
