import { object, string } from "yup";
import { EMAIL_REQUIRED, TEAM_REQUIRED } from "./constants";
import { EMAIL_VALIDATION_REGULAR } from "utilities/auth";
import { EMAIL_VALIDATION_MESSAGE } from "constants/errorMessages";

export const validationSchema = object().shape({
  teamUUID: string().required(TEAM_REQUIRED),
  email: string()
    .required(EMAIL_REQUIRED)
    .matches(EMAIL_VALIDATION_REGULAR, EMAIL_VALIDATION_MESSAGE),
});
