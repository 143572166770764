import React, { Fragment, useMemo } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useController, useFormContext } from "react-hook-form";
import { getFirstErrorMessage } from "utilities/general";
import { CREATE_PROJECT_ACTION } from "components/OrderForm/Info/constants";
import { CheckmarkProvider } from "components/OrderForm/components/CheckmarkProvider";
import {
  projectSelectionStyles,
  createBorderStylesForMUI,
} from "components/OrderForm/styles";

// specific request to have a project selection be a dropdown and have a freeform text, alas this is the result
// ux is pretty difficult to do around this one

const useStyles = makeStyles()(projectSelectionStyles);

export const ProjectSelectionFreeText = ({
  name,
  selectableProjectOptions,
  checkmark,
  openSavedModal,
}) => {
  const { classes } = useStyles();
  const {
    control,
    formState: { submitCount },
  } = useFormContext();

  const {
    field: { ref, onChange, onBlur, value: project },
    fieldState: { isTouched, error },
  } = useController({
    name,
    control,
  });

  const isValidated = isTouched || submitCount !== 0;
  const isDisplayValidation = isValidated && checkmark;

  const autocompleteStyles = useMemo(
    () =>
      isValidated || project.value ? createBorderStylesForMUI(project) : {},
    [project, isValidated]
  );

  if (!selectableProjectOptions) {
    return null;
  }

  const onClickedChange = (event, value) => {
    if (typeof value === "string") {
      // when a user types free hand in the text box, we need to create a record that stores it's freehand

      let valueParams;
      const listOfLabels = selectableProjectOptions.map(
        (result) => result.label
      );

      const stringEnteredAlreadyInProjects = listOfLabels.includes(value);

      if (stringEnteredAlreadyInProjects) {
        valueParams = selectableProjectOptions.filter(
          (result) => result.label === value
        )[0];
      } else {
        valueParams = {
          value: CREATE_PROJECT_ACTION,
          label: event.target.value,
        };
      }

      if (project.label !== value && valueParams.defaults) {
        openSavedModal();
      }

      onChange(valueParams);
    } else {
      // this function is called when a user clicks on a selection
      // this means the projects already exists\

      if (value.defaults) {
        openSavedModal();
      }

      onChange(value);
    }
  };

  const getOptionLabel = (option) => {
    return option.label ?? option;
  };

  const getCurrentValue = (project) => {
    const existingProject = selectableProjectOptions.find(
      (option) => option.value === project.value
    );

    return existingProject ?? project;
  };

  return (
    <Fragment>
      <Typography className={classes.projectSelectionLabel}>
        <label className={classes.formLabel}>Project Name</label>
        <label className={classes.requiredAsterisk}>*</label>
      </Typography>
      <Autocomplete
        sx={autocompleteStyles}
        freeSolo
        id="project-selection-dropdown"
        disableClearable
        autoSelect
        options={selectableProjectOptions}
        getOptionLabel={getOptionLabel}
        value={getCurrentValue(project)}
        onChange={onClickedChange}
        onBlur={onBlur}
        renderInput={(params) => (
          <CheckmarkProvider
            isValid={!error}
            validated={isValidated}
            visible
            className={classes.icon}
          >
            <TextField
              {...params}
              label=""
              margin="normal"
              variant="outlined"
              helperText={
                "Useful to group proper orders under the same project"
              }
              // can't use onChange here, otherwise it disables the dropdown from being limited real-time
              // sort of weird bug
              // onChange={onChangeFromTextInput}
              InputProps={{
                ...params.InputProps,
                inputRef: ref,
                type: "search",
                className: classes.projectTextInput,
              }}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </CheckmarkProvider>
        )}
      />
      {isDisplayValidation && error && (
        <div className={classes.errorFeedback}>
          {getFirstErrorMessage(error)}
        </div>
      )}
    </Fragment>
  );
};
