import {
  allOrdersFinishedStates,
  finishedOrderStates,
  ignoreOrderStates,
  unsubmittedOrderStates,
} from "components/OrdersList/constants";
import { difference } from "lodash";
import { isIAOrPCOrder } from "utilities/orders";

const initialOrders = {
  finishedOrders: [],
  inProgressOrders: [],
  unsubmittedOrders: [],
};

export const splitOrders = (orders) => {
  if (!orders) return { ...initialOrders };

  const unsubmittedOrders = orders.filter((order) => {
    return unsubmittedOrderStates.includes(order.state);
  });

  const finishedOrders = orders.filter(
    (order) =>
      (finishedOrderStates.includes(order.state) && !isIAOrPCOrder(order)) ||
      allOrdersFinishedStates.includes(order.state)
  );

  const notClassifiedOrders = difference(orders, [
    ...unsubmittedOrders,
    ...finishedOrders,
  ]);

  const inProgressOrders = notClassifiedOrders.filter(
    (order) => !ignoreOrderStates.includes(order.state)
  );

  return {
    finishedOrders: [...finishedOrders],
    inProgressOrders: [...inProgressOrders],
    unsubmittedOrders: [...unsubmittedOrders],
  };
};
