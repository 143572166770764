import { useEffect, useState } from "react";

export const useIsVisible = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      { rootMargin, threshold: 0.5 }
    );

    element.current && observer.observe(element.current);

    return () => observer.disconnect();
  }, []);

  return isVisible;
};
