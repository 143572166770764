import { withStyles } from "tss-react/mui";
import { uploadQuoteAttachment } from "services/resources/commonResources";
import { _UserUploadedAttachment } from "../../UserUploads/uploads";
import { UPLOAD_ATTACHMENT_ERROR_MESSAGE } from "constants/errorMessages";
import { withSnackbar } from "notistack";
import { DEFAULT_EXTRA_MESSAGE } from "utilities/hooks/useSnackbar/constants";
import { getSnackbarOptions } from "utilities/hooks/useSnackbar/useSnackbar";

const dropZoneStyles = () => ({
  dropZoneContainer: {
    cursor: "pointer",
  },
  dropZoneLabel: {
    margin: "0.75rem 0.5rem",
  },
  dropZone: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

class _QuoteUploadAttachment extends _UserUploadedAttachment {
  // change the state after it's been updated
  handleChange = (value) => {
    this.props.setFieldValue("order")(value);
  };

  uploadAttachment = (formData) => {
    uploadQuoteAttachment(formData)
      .then((order) => {
        this.handleChange(order);
      })
      .catch(() =>
        this.props.enqueueSnackbar(
          `${UPLOAD_ATTACHMENT_ERROR_MESSAGE} \n ${DEFAULT_EXTRA_MESSAGE}`,
          getSnackbarOptions("error")
        )
      );
  };
}

export const QuoteUploadAttachment = withStyles(
  withSnackbar(_QuoteUploadAttachment),
  dropZoneStyles
);
