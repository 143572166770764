import { useSelector } from "react-redux";
import { DEMO_USER_EMAIL } from "constants/emails";
import { currentUserEmailSelector } from "store/slices/userDetailsSlice";

export const useIsDemoUser = () => {
  const email = useSelector(currentUserEmailSelector);
  const isDemoUser = email === DEMO_USER_EMAIL;

  return isDemoUser;
};
