import { useSlideAnnotationsToolbarStyles } from "components/ImageViewer/styles";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useDispatch } from "react-redux";
import { updatePreferences } from "store/slices/userDetailsSlice";
import { ANNOTATIONS_SWITCH_FIELD } from "components/ImageViewer/constants";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { SnackbarActionCloseButton } from "components/Shared/Buttons/SnackbarActionCloseButton";
import {
  ANNOTATIONS_FOUND_NOTIFICATION_DURATION,
  ANNOTATIONS_FOUND_NOTIFICATION_SUCCESS_MESSAGE,
} from "components/ImageViewer/components/ImageViewerToolbar/constants";

export const formatAnnotationsCount = (annotationsCount, slidesLength) => {
  return `${annotationsCount}${slidesLength > 1 ? "+" : ""}`;
};

export const useAnnotationsCountNotification = (
  annotationsCount,
  currentSlide,
  slidesViewerProps
) => {
  const { classes } = useSlideAnnotationsToolbarStyles();
  const { showSuccess } = useSnackbar();
  const dispatch = useDispatch();

  const showAnnotationsCount =
    (annotationsCount && currentSlide.uuid === annotationsCount.slideUUID) ||
    (annotationsCount && !slidesViewerProps);

  const handleEnableAnnotations = () => {
    dispatch(
      updatePreferences({
        [ANNOTATIONS_SWITCH_FIELD]: true,
      })
    );
  };

  useEffect(() => {
    showAnnotationsCount &&
      showSuccess(ANNOTATIONS_FOUND_NOTIFICATION_SUCCESS_MESSAGE, {
        action: (
          <>
            <button
              className={classes.buttonLink}
              onClick={handleEnableAnnotations}
            >
              <Typography className={classes.buttonText}>
                Activate annotation mode to view
              </Typography>
            </button>
            <SnackbarActionCloseButton />
          </>
        ),
        autoHideDuration: ANNOTATIONS_FOUND_NOTIFICATION_DURATION,
        preventDuplicate: true,
      });
  }, [showAnnotationsCount]);

  return showAnnotationsCount;
};
