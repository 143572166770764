import React, { Fragment, useContext, useState } from "react";
import { AppContext } from "services/context";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import { SubmittingIcon } from "components/icons/LoadingIcon";
import { OrderCommentsNotifyClientModalName } from "components/Modals/constants";
import { RenderSlideThumbnails } from "components/IHCOptimizationViewer/RenderSlideThumbnails";
import Box from "@mui/material/Box";

import { useSelector } from "react-redux";
import { currentUserEmailSelector } from "store/slices/userDetailsSlice";
import { useNotifyOrderCommentsModalStyles } from "./styles";
import { usePostNotifyClientOrderCommentMutation } from "store/apis/orderApi";
import { OptimizationDilutionOrderCommentModule } from "components/Modals/IHCOptimization/OptimizationDilutionOrderCommentModule";
import { dividerStyle } from "components/IHCOptimizationViewer/styles";

const modalContextName = OrderCommentsNotifyClientModalName;

const deadlineTextConstant = "deadlineTextConstant";
const conditionsTextConstant = "conditionsTextConstant";

export const NotifyOrderCommentsModal = ({
  order,
  orderComments,
  setOrderComments,
}) => {
  const { classes } = useNotifyOrderCommentsModalStyles();
  const context = useContext(AppContext);
  const email = useSelector(currentUserEmailSelector);

  const { handleContextModalChange } = context;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [deadlineText, setDeadlineText] = useState("HAPPY");
  const [conditionsText, setConditionsText] = useState("HERE");

  const [postNotifyClientOrderComment] =
    usePostNotifyClientOrderCommentMutation();

  const handleTextChange = (name) => (event) => {
    if (name === deadlineTextConstant) {
      setDeadlineText(event.target.value);
    } else if (name === conditionsTextConstant) {
      setConditionsText(event.target.value);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const order_comment_uuids = orderComments.map((orderComment) => {
      return orderComment.uuid;
    });
    const postParams = {
      order_comment_uuids: order_comment_uuids,
      deadline_text: deadlineText,
      conditions_text: conditionsText,
    };

    const orderUUID = order.uuid;

    postNotifyClientOrderComment({ orderUUID, postParams })
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        setOrderComments([]);
      })
      .catch((error) => {
        // if error, dont turn off isSubmitting
        //setIsSubmitting(false);
        console.log(error);
      });
  };

  const clientEmail = order?.submitted_user?.email;

  const modalActions = () => {
    const submitLabel = `Email Client - ${clientEmail}`;

    return (
      <Typography align={"right"} className={classes.buttonTypography}>
        <span>
          <Button
            color="primary"
            onClick={handleContextModalChange(modalContextName)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            <SubmittingIcon submitting={isSubmitting} size={10} />
            {submitLabel}
          </Button>
        </span>
      </Typography>
    );
  };

  const renderModal = () => {
    const isOpen = context[modalContextName];
    const onClose = handleContextModalChange(modalContextName);

    if (!orderComments || orderComments.length === 0) {
      return null;
    }

    if (!isOpen) {
      return null;
    }

    const clientEmail = order?.submitted_user?.email;
    const dialogTitle = `Email Selected IHC Optimization Slides To Client: ${clientEmail} - NOT LIVE`;

    return (
      <DefaultDialog
        open={isOpen}
        onClose={onClose}
        actions={modalActions}
        title={dialogTitle}
      >
        <Box height={"50vh"} width={"80vw"}>
          <Grid container spacing={3} width={"100%"}>
            <Grid item xs={8} className={classes.grid}>
              <Grid container direction="row" spacing={2}>
                {orderComments.map((comment) => {
                  return (
                    <Fragment key={comment.uuid}>
                      <Grid item xs={3}>
                        <OptimizationDilutionOrderCommentModule
                          orderComment={comment}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <RenderSlideThumbnails
                          key={comment.uuid}
                          slides={comment.slides}
                          width={"100%"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={dividerStyle} />
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography
                color={"inherit"}
                className={classes.reviewTextLabel}
                variant={"h5"}
              >
                IHC Optimization by {email}
              </Typography>

              <Typography
                color={"inherit"}
                className={classes.reviewTextLabel}
                variant={"body1"}
              >
                The below will email the client selected with the following
                attached into the template. You will be cc'ed.
              </Typography>

              <Typography
                color={"inherit"}
                className={classes.reviewTextLabel}
                variant={"body1"}
              >
                <a
                  href={
                    "https://www.dropbox.com/s/10ifpe0wer8f2wi/CleanShot%202022-02-10%20at%2022.37.31.png?dl=0"
                  }
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Sample Template
                </a>
              </Typography>

              <TextField
                id="standard-multiline-flexible"
                label="Conditions Text (Email Template)"
                className={classes.externalTextField}
                placeholder=""
                fullWidth={true}
                multiline
                onChange={handleTextChange(conditionsTextConstant)}
              />

              <TextField
                id="standard-multiline-flexible"
                label="Timeline/Deadline Text (Email Template)"
                className={classes.externalTextField}
                placeholder=""
                fullWidth={true}
                multiline
                onChange={handleTextChange(deadlineTextConstant)}
              />
            </Grid>
          </Grid>
        </Box>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
