import { useGetUserDetailsQuery } from "store/apis/userDetailsApi";
import { useDispatch, useSelector } from "react-redux";
import {
  algoliaUserSlidesSearchApiKeySelector,
  selectForceOpenSlidesSearchTutorial,
  setForceOpenSlidesSearchTutorial,
} from "store/slices/userDetailsSlice";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { PathologyMapMainContent } from "components/PathologyMap/PathologyMapMainContent";
import { SLIDES_SEARCH_V2_NAME } from "components/PathologyMap/constants/common";
import { useTitle } from "components/utilities/hooks/useTitle";
import { useEffect, useState } from "react";
import { UserSlidesSearchTutorialModal } from "components/Modals/TutorialModals/UserSlidesSearchTutorialModal";

export const UserSlidesPathologyMapPage = () => {
  const dispatch = useDispatch();
  const algoliaSearchApiKey = useSelector(
    algoliaUserSlidesSearchApiKeySelector
  );
  useTitle(SLIDES_SEARCH_V2_NAME);

  // need to update user details cause otherwise there is a chance to pull stale teams data
  const { refetch } = useGetUserDetailsQuery();

  const [tutorialModalOpen, setTutorialModalOpen] = useState(false);
  const forceTutorialModalOpen = useSelector(
    selectForceOpenSlidesSearchTutorial
  );

  const handleModalClose = () => {
    setTutorialModalOpen(false);
    dispatch(setForceOpenSlidesSearchTutorial(false));
  };

  useEffect(() => {
    if (!algoliaSearchApiKey) {
      return;
    }

    setTutorialModalOpen(true);
  }, [algoliaSearchApiKey]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <MiniDrawerWithContext
      header={SLIDES_SEARCH_V2_NAME}
      isWithInfiniteScrollPage
    >
      <PathologyMapMainContent algoliaSearchApiKey={algoliaSearchApiKey} />

      <UserSlidesSearchTutorialModal
        open={tutorialModalOpen}
        forceOpen={forceTutorialModalOpen}
        onClose={handleModalClose}
      />
    </MiniDrawerWithContext>
  );
};
