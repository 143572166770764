import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const defaultSpinnerThemeStyle = {
  height: "60vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Spinner = ({ size, color }) => {
  return (
    <div style={{ height: size || 40 }}>
      <CircularProgress size={size || 40} color={color || "primary"} />
    </div>
  );
};
