import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ProgressItemCard } from "components/OrderDetails/Tabs/OrderProgressTab/ProgressItemCard";
import { useOrderHistoryStyles } from "components/OrderDetails/Tabs/OrderProgressTab/styles";

export const OrderHistory = ({ history }) => {
  const { classes } = useOrderHistoryStyles();

  if (!history.length) return null;

  return (
    <ProgressItemCard label={"Order Progress History"}>
      <Stepper
        activeStep={history.length}
        orientation="vertical"
        classes={{
          root: classes.stepper,
        }}
      >
        {history.map((step) => (
          <Step expanded={true} key={step.ids}>
            <StepLabel
              classes={{
                label: classes.stepLabel,
                iconContainer: classes.stepIcon,
              }}
            >
              {step.nameToDisplay}
            </StepLabel>
            <StepContent>
              <Typography className={classes.stepContent}>
                {step.description}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </ProgressItemCard>
  );
};
