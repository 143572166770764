import { Box, Grid } from "@mui/material";
import { DefaultPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography";
import React from "react";
import {
  TEAM_OVERVIEW_HEADER_SUBTITLE_TYPOGRAPHY_PROPS,
  TEAM_OVERVIEW_HEADER_TITLE_TYPOGRAPHY_PROPS,
} from "components/UserProfile/styles";

export const CardHeader = ({ title, subtitle, RightSection = null }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Grid item>
          <DefaultPathologyMapTypography
            {...TEAM_OVERVIEW_HEADER_SUBTITLE_TYPOGRAPHY_PROPS}
          >
            {subtitle}
          </DefaultPathologyMapTypography>
          <DefaultPathologyMapTypography
            component="span"
            {...TEAM_OVERVIEW_HEADER_TITLE_TYPOGRAPHY_PROPS}
          >
            {title}
          </DefaultPathologyMapTypography>
        </Grid>
      </Box>
      <Box>{RightSection}</Box>
    </Box>
  );
};
