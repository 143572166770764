import { Paper, Tab, Tabs } from "@mui/material";
import { useBillingTabs } from "components/OrderForm/BillingShipping/hooks/useBillingTabs";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { AddCreditCardForm } from "components/OrderForm/BillingShipping/BillingTabs/AddCreditCardForm";
import { ExistingContactForm } from "components/OrderForm/BillingShipping/BillingTabs/ExistingContactForm";
import { PurchaseOrderForm } from "components/OrderForm/BillingShipping/BillingTabs/PurchaseOrderForm";
import { TabPanel } from "components/Shared/TabPanel";
import { AddBillingContactForm } from "components/OrderForm/BillingShipping/BillingTabs/AddBillingContactForm";
import {
  BLANKET_PURCHASE_ORDER_TAB_VALUE,
  CREDIT_CARD_TAB_VALUE,
  EXISTING_BILLING_INFO_TAB_VALUE,
  PO_NUMBER_INFO_TAB_VALUE,
} from "components/OrderForm/BillingShipping/utilities/constants";

export const BillingTabs = () => {
  const { classes } = useBillingShippingStyles();
  const {
    billingTabSelected,
    handleBillingTabChange,
    teamUUID,
    handleAddBillingSuccess,
    onBlanketPurchaseOrderSelect,
    handleCreditCardUpdate,
    billingContacts,
  } = useBillingTabs();

  return (
    <Paper className={classes.paper}>
      <Tabs
        value={billingTabSelected}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleBillingTabChange}
      >
        <Tab label="PO Number" />
        <Tab label="Blanket Purchase Orders" />
        <Tab label="Credit Card" />
        <Tab label="Existing Billing Info" />
      </Tabs>
      <TabPanel value={billingTabSelected} index={PO_NUMBER_INFO_TAB_VALUE}>
        {!!teamUUID && (
          <AddBillingContactForm
            onAfterSuccessSubmit={handleAddBillingSuccess}
          />
        )}
      </TabPanel>
      <TabPanel
        value={billingTabSelected}
        index={BLANKET_PURCHASE_ORDER_TAB_VALUE}
      >
        <PurchaseOrderForm onChange={onBlanketPurchaseOrderSelect} />
      </TabPanel>
      <TabPanel value={billingTabSelected} index={CREDIT_CARD_TAB_VALUE}>
        <AddCreditCardForm handleCreditCardUpdate={handleCreditCardUpdate} />
      </TabPanel>
      <TabPanel
        value={billingTabSelected}
        index={EXISTING_BILLING_INFO_TAB_VALUE}
      >
        <ExistingContactForm billingContacts={billingContacts} />
      </TabPanel>
    </Paper>
  );
};

BillingTabs.propTypes = {};
