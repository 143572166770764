import { Close } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { CollapsedBar } from "components/common/CollapsedBar/CollapsedBar";
import { THUMBNAIL_SWITCH_FIELD } from "components/ImageViewer/constants";
import { useFloatingMacroStyles } from "components/OrderSlidesList/styles";
import React, { useState } from "react";

export const FloatingMacro = ({ thumbnail = "", isVisible = true }) => {
  const { classes } = useFloatingMacroStyles();
  const [isThumbnailCollapsed, setIsThumbnailCollapsed] = useState(false);

  if (!(isVisible && thumbnail)) {
    return null;
  }

  return isThumbnailCollapsed ? (
    <CollapsedBar
      onExpand={() => setIsThumbnailCollapsed(false)}
      preferencesField={THUMBNAIL_SWITCH_FIELD}
      title="Thumbnail"
    />
  ) : (
    <Paper className={classes.floatingPaper} elevation={1}>
      <img
        className={`${classes.floatingThumbnail} rounded-border`}
        src={thumbnail}
        alt="Thumbnail"
      />
      <Box
        className={`${classes.closeBackground} hover-visible`}
        onClick={() => setIsThumbnailCollapsed(true)}
      >
        <Close className={classes.closeIcon} />
      </Box>
    </Paper>
  );
};
