import { useEffect, useRef } from "react";

import { MiniDrawerWithContext } from "components/Layout/drawer";
import { useRouteMatch } from "react-router-dom";
import "@recogito/annotorious-openseadragon/dist/annotorious.min.css";
import "css/OpenSeaDragon.css";
import { getSlideViewerPageTitle } from "components/OrderSlidesList/utilities";
import { useTitle } from "components/utilities/hooks/useTitle";
import OpenSeaDragon from "openseadragon";
import { FETCH_SLIDE_ERROR_MESSAGE } from "constants/errorMessages";
import { useGetSlideQuery } from "store/apis/slidesApi";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";

export const SlideImageDebugPage = () => {
  const match = useRouteMatch();
  const { slideUUID } = match.params;

  const { error, data: slide } = useGetSlideQuery({ slideUUID });
  useQueryError(error, FETCH_SLIDE_ERROR_MESSAGE);

  const tileSources = slide?.dzi_access_url;

  const pageTitle = getSlideViewerPageTitle(slide);
  useTitle(pageTitle);

  const viewerRef = useRef(null);

  useEffect(() => {
    viewerRef.current = new OpenSeaDragon({
      id: slideUUID,
      tileSources,
    });

    return () => {
      viewerRef.current?.navigator?.destroy();
      viewerRef.current?.destroy();

      viewerRef.current = null;
    };
  }, [slideUUID, tileSources]);

  return (
    <MiniDrawerWithContext header={"Slide Viewer"}>
      <div className="openseadragon" id={slideUUID} />
    </MiniDrawerWithContext>
  );
};
