import React from "react";
import { Tab, Tabs, Typography, Box } from "@mui/material";
import Bubble from "@mui/icons-material/ChatBubble";

import {
  useSlideInfoTabsStyles,
  useCommentsContainerStyles,
} from "components/PathologyMap/Modals/FullSlideInfoModal/styles";
import { SLIDE_SEARCH_TAB_STYLES } from "components/PathologyMap/constants/styles";

export const CommentsHeader = ({
  isInternal,
  setIsInternal,
  comments,
  isStaff,
}) => {
  const { classes: tabsClasses } = useSlideInfoTabsStyles();
  const { classes } = useCommentsContainerStyles();

  const getCount = (isInternal) =>
    comments.filter(({ is_internal }) => is_internal === isInternal).length;

  const Icon = ({ counter }) => (
    <div className={classes.icon}>
      <Bubble />
      {!!counter && <span>{counter}</span>}
    </div>
  );

  return (
    <Box
      display="flex"
      width="100%"
      padding="0 1rem"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
    >
      <Typography className={classes.commentsLabel}>SLIDE COMMENTS</Typography>
      {isStaff && (
        <Tabs
          value={isInternal}
          onChange={(_, value) => setIsInternal(value)}
          indicatorColor={"primary"}
          classes={{
            root: classes.tabs,
            indicator: tabsClasses.indicator,
          }}
        >
          <Tab
            style={SLIDE_SEARCH_TAB_STYLES}
            classes={{ selected: tabsClasses.selectedTab }}
            value
            icon={<Icon counter={getCount(true)} />}
            label="internal"
          />
          <Tab
            style={SLIDE_SEARCH_TAB_STYLES}
            classes={{ selected: tabsClasses.selectedTab }}
            value={false}
            icon={<Icon counter={getCount(false)} />}
            label="external"
          />
        </Tabs>
      )}
    </Box>
  );
};
