import { CONTACT_US_URL } from "components/Layout/Sidebar/constants";

export const buildContactUsLink = (userDetails) => {
  const params = [
    {
      field: "firstName",
      linkParameter: "firstname",
    },
    {
      field: "lastName",
      linkParameter: "lastname",
    },
    {
      field: "email",
      linkParameter: "email",
    },
    {
      field: "phoneNumber",
      linkParameter: "phone",
    },
  ];

  let finalLink = CONTACT_US_URL;

  params.forEach((param) => {
    const key = param.linkParameter;
    const value = userDetails[param.field];
    finalLink = finalLink + `&${key}=${value}`;
  });

  return finalLink;
};
