import { agGridDefaultColDef } from "components/utilities/grid";

export const antibodiesTablesDefaultColDef = {
  ...agGridDefaultColDef,
  floatingFilter: true,
};

export const antibodiesDetailGridDefaultColDefs = {
  editable: false,
  sortable: false,
  filter: false,
  resizable: true,
};

export const SINGLE_ANTIBODY_TABLE_IDX = 0;
export const MULTIPLEX_ANTIBODY_TABLE_IDX = 1;
export const PANELS_TABLE_IDX = 2;
export const SPECIAL_STAINS_TABLE_IDX = 3;

export const GET_PANELS_ERROR_MESSAGE = "Error occurred during fetching panels";
