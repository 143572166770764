import { baseCreateApi } from "store/apis/baseCreateApi";

export const glpOrderApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getGlpOrder: builder.query({
      query: (orderUUID) => ({
        url: `glp_order/${orderUUID}/`,
        method: "GET",
      }),
    }),
    createGlpOrder: builder.mutation({
      query: (fields) => ({
        url: `glp_order/`,
        method: "POST",
        body: fields,
      }),
    }),
    updateGlpOrder: builder.mutation({
      query: ({ orderUUID, fields }) => ({
        url: `glp_order/${orderUUID}/`,
        method: "PUT",
        body: fields,
      }),
    }),
    getGlpSearchData: builder.query({
      query: () => ({
        url: "/glp_order/search_data/",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUpdateGlpOrderMutation,
  useCreateGlpOrderMutation,
  useGetGlpOrderQuery,
  useGetGlpSearchDataQuery,
} = glpOrderApi;
