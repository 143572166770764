import compose from "recompose/compose";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Grid, Paper, Typography } from "@mui/material";
import { Link, useHistory, withRouter } from "react-router-dom";
import { SubmittingIcon } from "components/icons/LoadingIcon";
import { ServicesRequestedTable } from "components/OrderForm/components/ReviewTables";
import {
  PLACE_ORDER_REVIEW_FEEDBACK_URL,
  PLACE_ORDER_REVIEW_STEP_URL,
} from "constants/urls";
import {
  getOrderByUUID,
  submitOrder,
  updateOrder,
} from "services/resources/orders";
import { withContextAsProps } from "react-context-consumer-hoc";
import { AppContext } from "services/context";
import {
  DEMO_ACCOUNT_MESSAGE,
  LAST_ORDER_FORM_PAGE,
  REVIEW_PAGE_TITLE,
  SUBMIT_ORDER,
} from "components/OrderForm/constants";
import { NewOrderSummaryTable } from "components/OrderForm/components/NewOrderReviewTable";
import { DEMO_USER_EMAIL } from "constants/emails";
import {
  FETCH_ORDER_ERROR_MESSAGE,
  GET_SAMPLES_ERROR,
  SUBMIT_ORDER_ERROR_MESSAGE,
  UPDATE_ORDER_ERROR_MESSAGE,
} from "constants/errorMessages";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import { withStyles } from "tss-react/mui";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useLazyGetSamplesByOrderIdQuery } from "store/apis/samplesApi";

const styles = (theme) => ({
  saveButton: {
    textAlign: "right",
    textTransform: "uppercase",
    marginTop: "2rem",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  confirmPaper: {
    width: "100%",
    marginTop: "2rem",
    padding: theme.spacing(2),
  },
});

const _ReviewViewV2 = ({ classes, email, match }) => {
  const orderUUID = match.params["orderUUID"];
  const { showError } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmMinimumOrderTotal, setConfirmMinimumOrderTotal] =
    useState(false);
  const [confirmHumanSamples, setConfirmHumanSamples] = useState(false);
  const [order, setOrder] = useState(null);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);

  const [getSamplesByOrderId, { data: samples }] =
    useLazyGetSamplesByOrderIdQuery();

  const history = useHistory();

  useEffect(() => {
    document.title = REVIEW_PAGE_TITLE;

    getSamplesByOrderId({ orderUUID }).catch(() =>
      showError(GET_SAMPLES_ERROR)
    );

    getOrderByUUID(orderUUID)
      .then((order) => setOrder(order))
      .catch(() => showError(FETCH_ORDER_ERROR_MESSAGE));
  }, [orderUUID]);

  const handleChange = (handler) => (event) => {
    handler(event.target.checked);
  };

  const makeAPIRequests = async () => {
    return await submitOrder({
      orderUUID,
    }).catch(() => showError(SUBMIT_ORDER_ERROR_MESSAGE));
  };

  const modalActions = () => {
    return (
      <>
        <Button onClick={onModalClose}>Cancel</Button>
        <Button onClick={handleSaveAndContinue} autoFocus>
          <SubmittingIcon submitting={isSubmitting} />
          Submit
        </Button>
      </>
    );
  };

  const onModalClose = () => {
    setConfirmModalVisible(false);
  };

  const handleSubmit = () => {
    if (!samples.length) {
      setConfirmModalVisible(true);
    } else {
      handleSaveAndContinue();
    }
  };

  const handleSaveAndContinue = () => {
    setIsSubmitting(true);
    makeAPIRequests().then(() => {
      const orderID = order.name.replace("Order ", "");
      const url = PLACE_ORDER_REVIEW_FEEDBACK_URL.replace(":orderID", orderID);
      history.push(url);
    });

    updateOrder(orderUUID, {
      last_order_form_page: LAST_ORDER_FORM_PAGE.REVIEW,
    }).catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));
  };

  const renderHumanSamplesConfirmation = () => (
    <Paper className={classes.confirmPaper}>
      <Grid container direction="row" wrap="nowrap" alignItems="baseline">
        <Grid item>
          <Checkbox
            checked={confirmHumanSamples}
            onChange={handleChange(setConfirmHumanSamples)}
            value="confirmHumanSamples"
          />
        </Grid>
        <Grid item>
          <span>
            I understand and agree that the work done by HistoWiz is expected to
            be for research purposes only and will not be used in
            diagnostic/clinical decision making.
          </span>
        </Grid>
      </Grid>
    </Paper>
  );

  const renderMinimumOrderSizeConfirmation = () => (
    <Paper className={classes.confirmPaper}>
      <Grid container direction="row" wrap="nowrap" alignItems="baseline">
        <Grid item>
          <Checkbox
            checked={confirmMinimumOrderTotal}
            onChange={handleChange(setConfirmMinimumOrderTotal)}
            value="confirmMinimumOrderTotal"
          />
        </Grid>
        <Grid item>
          <span>
            I confirm that this order is either a first time free trial OR that
            it will be charged a total of $250 minimum if it does not meet the
            $250 minimum order size (I understand there is a 4 slide per marker
            minimum requirement for IHC and special stain services. I will be
            charged the 4 slides for each marker that does not meet this
            requirement).
          </span>
        </Grid>
      </Grid>
    </Paper>
  );

  if (!order) {
    return null;
  }

  const oldUrl = PLACE_ORDER_REVIEW_STEP_URL.replace(":orderUUID", orderUUID);

  const isDemoUser = email === DEMO_USER_EMAIL;

  return (
    <div>
      <Typography variant={"body2"} align={"center"}>
        Having Issues with our new Review Order Form? Click{" "}
        <Link to={oldUrl}>here</Link> to use the previous order form.
      </Typography>
      <NewOrderSummaryTable order={order} />
      <ServicesRequestedTable order={order} samples={samples} />
      {renderMinimumOrderSizeConfirmation()}
      {renderHumanSamplesConfirmation()}
      <div className={classes.saveButton}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={"primary"}
          disabled={
            isDemoUser ||
            isSubmitting ||
            !(confirmMinimumOrderTotal && confirmHumanSamples)
          }
        >
          <SubmittingIcon submitting={isSubmitting} />
          {isDemoUser ? DEMO_ACCOUNT_MESSAGE : SUBMIT_ORDER}
        </Button>
      </div>
      <DefaultDialog
        open={isConfirmModalVisible}
        onClose={onModalClose}
        actions={modalActions}
      >
        No samples were added. Do you want to submit the order anyway?
      </DefaultDialog>
    </div>
  );
};

const _ReviewViewV2WithStyles = withStyles(_ReviewViewV2, styles);

export const ReviewViewV2 = compose(
  withRouter,
  withContextAsProps(AppContext)
)(_ReviewViewV2WithStyles);
