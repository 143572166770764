import { makeStyles } from "tss-react/mui";

export const useCollapsedBarStyles = makeStyles()({
  collapsedThumbnail: {
    background: "white",
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    gap: "2rem",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 0.5rem 0.25rem 0.75rem",
    fontSize: "0.75rem",

    "& svg": {
      cursor: "pointer",
      width: "1rem",
      height: "1rem",
    },
  },
  collapsedIcons: {
    display: "flex",
    gap: "0.5rem",
  },
  position: {
    position: "fixed",
    right: "0.75rem",
    bottom: 0,
    width: "12.5rem",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: "none",
  },
});
