import { useDispatch, useSelector } from "react-redux";
import {
  addCurrentSlideAIModel,
  removeCurrentSlideAIModel,
  selectCurrentSlideAIModels,
  selectMlDataLoadingMap,
  selectQCModelsOpacity,
  selectTissueModelsOpacity,
  setLastChangedAIModelOptions,
  setMLDataLoadingMap,
  setQCModelsOpacity,
  setTissueModelsOpacity,
} from "store/slices/aiToolsSlice";
import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  AI_PROCESSING_ACTION_MENU_STYLE,
  useContentStyles,
} from "components/AITools/constants/styles";
import {
  AI_MODEL_CHANGE_ACTIONS,
  QC_MODELS_ITEM_MENU_OPTIONS,
  TISSUE_MODELS_ITEM_MENU_OPTIONS,
} from "components/AITools/constants/common";
import { PercentageSlider } from "components/Shared/PercantageSlider";
import {
  isQCModel,
  isQCModelExists,
  isSlideTissueMLDataAvailable,
  raiseOpacityChangeEvent,
} from "components/AITools/utilities/common";
import { RunAIProcessingAction } from "components/ImageViewer/SlideViewerHeader/ActionItems/RunAIProcessingAction";
import { ShowPathologistUserOnly } from "components/ConditionalWrappers/ShowPathologistUserOnly";
import { SlideAIModelSelectableItem } from "components/AITools/components/SlideAIModelSelectableItem";

export const SlideAIToolsContent = ({ slide, slidesTableProps }) => {
  const { classes } = useContentStyles();
  const currentSlideAIModels = useSelector(selectCurrentSlideAIModels);
  const mlDataLoadingMap = useSelector(selectMlDataLoadingMap);
  const dispatch = useDispatch();
  const qcModelsOpacity = useSelector(selectQCModelsOpacity);
  const tissueModelsOpacity = useSelector(selectTissueModelsOpacity);

  const slides = slidesTableProps?.selectedSlides.length
    ? slidesTableProps?.selectedSlides
    : [slide];

  const isSelected = (modelName) => {
    const isSelected = currentSlideAIModels.includes(modelName);
    return isSelected;
  };

  const handleAIModelChange = (modelName) => () => {
    let changeType = AI_MODEL_CHANGE_ACTIONS.ADD;
    const isQC = isQCModel(modelName);

    if (!isSelected(modelName)) {
      dispatch(addCurrentSlideAIModel(modelName));
      if (!isQC) {
        //necessary to avoid blinking of tick before spinner
        dispatch(setMLDataLoadingMap({ modelName, isLoading: true }));
      }
    } else {
      dispatch(removeCurrentSlideAIModel(modelName));
      changeType = "remove";
    }

    dispatch(setLastChangedAIModelOptions({ modelName, changeType }));
  };

  const handleQCModelsOpacityChange = (_, newValue) => {
    raiseOpacityChangeEvent(tissueModelsOpacity, qcModelsOpacity);
    dispatch(setQCModelsOpacity(newValue));
  };

  const handleTissueModelsOpacityChange = (_, newValue) => {
    raiseOpacityChangeEvent(tissueModelsOpacity, qcModelsOpacity);
    dispatch(setTissueModelsOpacity(newValue));
  };

  const isQCModelDisabled = (modelName) => {
    return !slides.some((slide) => isQCModelExists(slide, modelName));
  };

  const isTissueModelDisabled = (modelName) => {
    return !slides.some((slide) =>
      isSlideTissueMLDataAvailable(slide, modelName)
    );
  };

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item xs>
        <Typography className={classes.mlModelsHeader}>
          HISTOWIZ AUTO-QC MODELS
        </Typography>
      </Grid>

      <Grid item xs className={classes.percentageSliderWrapper}>
        <PercentageSlider
          value={qcModelsOpacity}
          handleChange={handleQCModelsOpacityChange}
        />
      </Grid>

      <Grid
        item
        container
        xs
        spacing={1}
        className={classes.aiModelsListWrapper}
      >
        {QC_MODELS_ITEM_MENU_OPTIONS.map((model) => (
          <Grid item key={model.name} xs={6}>
            <SlideAIModelSelectableItem
              key={model.name}
              name={model.name}
              isSelected={isSelected(model.value)}
              handleChange={handleAIModelChange(model.value)}
              isDisabled={isQCModelDisabled(model.value)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid item xs>
        <Typography className={classes.mlModelsHeader}>
          HISTOWIZ TISSUE SEGMENTATION MODELS
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs className={classes.percentageSliderWrapper}>
          <PercentageSlider
            value={tissueModelsOpacity}
            handleChange={handleTissueModelsOpacityChange}
          />
        </Grid>
        <ShowPathologistUserOnly>
          <Grid item>
            <RunAIProcessingAction
              slides={slides}
              menuItemStyle={AI_PROCESSING_ACTION_MENU_STYLE}
            />
          </Grid>
        </ShowPathologistUserOnly>
      </Grid>

      <Grid item xs className={classes.aiModelsListWrapper}>
        {TISSUE_MODELS_ITEM_MENU_OPTIONS.map((model) => (
          <SlideAIModelSelectableItem
            key={model.name}
            name={model.name}
            color={model.color}
            isSelected={isSelected(model.value)}
            handleChange={handleAIModelChange(model.value)}
            isDisabled={isTissueModelDisabled(model.value)}
            isLoading={mlDataLoadingMap[model.value]}
          />
        ))}
      </Grid>
    </Grid>
  );
};
