export const ORDER_NAME = "Order Name";

export const TEAM_MEMBERS = "Team Members";

export const ORGANIZATION = "Organization";

export const PHONE_NUMBER = "Phone Number";

export const PROJECT = "Project";

export const TEAM = "Team";

export const ACCOUNT_REP = "Account Rep";

export const TAT = "Turnaround Time";

export const PATH_CONTRIBUTION = "Order Contributes to Pathology Map";

export const HELP_TOOLTIP_TEXT =
  "Note, this is the duration of time the samples have been in fixative solution. We recommend neutral buffered formalin for 24 hours, but this depends on the sample size and tissue type.";
