import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { withSnackbar } from "notistack";
import { LOGIN_URL } from "constants/urls";
import signupBackground from "images/signup_background_4_optimized.jpg";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { resetPasswordConfirm } from "services/resources/auth";
import { RESET_PASSWORD_CONFIRM_PAGE_TITLE } from "components/ResetPassword/constants";

const styles = (theme) => ({
  paperContainer: {
    backgroundImage: `url(${signupBackground})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
  },
  loginHeader: {
    position: "relative",
    top: "-2rem",
    width: "80%",
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
    paddingTop: "2rem",
    paddingBottom: "2rem",
    color: "white",
  },
  input: {
    marginLeft: "2rem",
    marginTop: "1rem",
    marginRight: "2rem",
    width: "80%",
  },
  signinButton: {
    marginLeft: "2rem",
    marginTop: "2rem",
    marginRight: "2rem",
    marginBottom: "2rem",
    width: "80%",
  },
  signInRightButton: {
    float: "right",
  },
});

class _ResetPasswordConfirmPage extends React.Component {
  state = {
    password1: "",
    password2: "",
  };

  componentDidMount() {
    document.title = RESET_PASSWORD_CONFIRM_PAGE_TITLE;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  submitNewPassword = () => {
    const { uid, token } = this.props.match.params;

    const postParams = {
      uid: uid,
      token: token,
      new_password1: this.state.password1,
      new_password2: this.state.password2,
    };

    resetPasswordConfirm({ postParams })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push(LOGIN_URL);
        }
      })
      .catch(({ response, message }) => {
        let errorMessage;

        const responseData = response?.data;
        if (responseData) {
          const key = Object.keys(responseData);
          errorMessage = `${key}: ${responseData[key]}`;
        } else {
          errorMessage = message;
        }

        this.props.enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      });
  };

  render() {
    const { classes } = this.props;

    // too lazy to throw a yup validation schema here
    let disabled = false;
    if (!(this.state.password1 && this.state.password2)) {
      disabled = true;
    }
    if (this.state.password1 !== this.state.password2) {
      disabled = true;
    }
    if (this.state.password1.length < 8) {
      disabled = true;
    }

    return (
      <Paper className={classes.paperContainer}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
          spacing={0}
        >
          <Grid item xs={12} sm={9} md={6} lg={3}>
            <Paper>
              <Paper className={classes.loginHeader}>
                <Typography align={"center"} variant={"h5"} color={"inherit"}>
                  Reset Password
                </Typography>
              </Paper>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Fragment>
                  <TextField
                    id="password1"
                    label="New Password"
                    type={"password"}
                    className={classes.input}
                    helperText="Min of 8 Characters"
                    value={this.state.password1}
                    onChange={this.handleChange("password1")}
                    margin="normal"
                  />
                  <TextField
                    id="password2"
                    label="Confirm Password"
                    type={"password"}
                    className={classes.input}
                    helperText="Min of 8 Characters"
                    value={this.state.password2}
                    onChange={this.handleChange("password2")}
                    margin="normal"
                  />
                  <div className={classes.signinButton}>
                    <Button
                      className={classes.signInRightButton}
                      variant="contained"
                      color="primary"
                      fullWidth={false}
                      disabled={disabled}
                      onClick={this.submitNewPassword}
                    >
                      Reset Password
                    </Button>
                  </div>
                </Fragment>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export const ResetPasswordConfirmPage = withRouter(
  withStyles(withSnackbar(_ResetPasswordConfirmPage), styles)
);
