import React, { Fragment, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  agGridDefaultColDef,
  exportToExcelAction,
} from "components/utilities/grid";
import { PathologyConsultationWorklistServicesRequested } from "components/PathologyConsultations/AgGridColumns/PathologyConsultationWorklistServicesRequested";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useRenderServicesRequestedStyles } from "./styles";

export const RenderServicesRequested = ({
  servicesRequested,
  consultation,
}) => {
  const { classes } = useRenderServicesRequestedStyles();
  const [rows, setRows] = useState([]);
  const [slideRows, setSlideRows] = useState([]);
  const [sampleGridAPI, setSampleGridAPI] = useState();
  const [slideGridAPI, setSlideGridAPI] = useState();

  useEffect(() => {
    if (!servicesRequested) {
      return;
    }

    const sampleRecords = [];
    const slideRecords = [];
    servicesRequestingConsultations.forEach((sample) => {
      const antibodiesRequested = sample.requested_antibodies.length > 0;
      const specialStainsRequested = sample.requested_special_stains.length > 0;

      if (sample.requested_h_and_e_stain_pathology_consultation_count) {
        const record = {
          name: sample.name,
          count: sample.requested_h_and_e_stain_pathology_consultation_count,
          type: "H&E",
          stainType: "H&E",
        };
        sampleRecords.push(record);
      }

      if (sample.requested_pathology_consultation_only_count) {
        const record = {
          name: sample.name,
          count: sample.requested_pathology_consultation_only_count,
          type: "Consultation Only",
          stainType: "Consultation Only",
        };
        sampleRecords.push(record);
      }

      if (antibodiesRequested) {
        sample.requested_antibodies.forEach((antibody) => {
          const record = {
            name: sample.name,
            count: antibody.pathology_consultation_count,
            type: antibody.name,
            stainType: "IHC",
          };
          sampleRecords.push(record);
        });
      }

      if (specialStainsRequested) {
        sample.requested_special_stains.forEach((specialStain) => {
          const record = {
            name: sample.name,
            count: specialStain.pathology_consultation_count,
            type: specialStain.name,
            stainType: "Special Stain",
          };
          sampleRecords.push(record);
        });
      }

      if (consultation?.slide_group?.slides) {
        consultation.slide_group.slides.forEach((slide) => {
          const record = {
            name: slide.name,
            count: 1,
            type: "Slide",
            stainType: "Slide",
          };
          slideRecords.push(record);
        });
      }

      setRows(sampleRecords);
      setSlideRows(slideRecords);
    });
  }, [servicesRequested]);

  if (!servicesRequested) {
    return null;
  }

  const servicesRequestingConsultations = servicesRequested.filter((sample) => {
    if (!sample.requested_pathology_consultation) {
      return false;
    }
    return true;
  });

  const onSampleGridReady = ({ api }) => {
    setSampleGridAPI(api);
  };

  const onSlideGridReady = ({ api }) => {
    setSlideGridAPI(api);
  };

  return (
    <Fragment>
      <Box mb={3} mt={1}>
        <Box mb={1}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant={"h6"}>Samples</Typography>
            <Button
              variant="contained"
              color="secondary"
              fullWidth={false}
              onClick={() =>
                sampleGridAPI &&
                exportToExcelAction(
                  sampleGridAPI,
                  "HistoWiz-Pathology-Consultation-Samples-Export"
                )
              }
              className={classes.compareButton}
            >
              Download as Excel
            </Button>
          </Grid>
        </Box>

        <div className={`ag-theme-balham ${classes.grid}`}>
          <AgGridReact
            onGridReady={onSampleGridReady}
            rowData={rows}
            columnDefs={PathologyConsultationWorklistServicesRequested}
            rowSelection={"single"}
            enableFillHandle={true}
            fillHandleDirection={"y"}
            enableRangeSelection={true}
            singleClickEdit={false}
            defaultColDef={agGridDefaultColDef}
            suppressCopyRowsToClipboard={true}
            stopEditingWhenGridLosesFocus={true}
          />
        </div>
      </Box>
      <Box mt={3}>
        <Box mb={1}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant={"h6"}>Slides</Typography>
            <Button
              variant="contained"
              color="secondary"
              fullWidth={false}
              onClick={() =>
                slideGridAPI &&
                exportToExcelAction(
                  slideGridAPI,
                  "HistoWiz-Pathology-Consultation-Slides-Export"
                )
              }
              className={classes.compareButton}
            >
              Download as Excel
            </Button>
          </Grid>
        </Box>
        <div className={`ag-theme-balham ${classes.grid}`}>
          <AgGridReact
            onGridReady={onSlideGridReady}
            rowData={slideRows}
            columnDefs={PathologyConsultationWorklistServicesRequested}
            rowSelection={"single"}
            enableFillHandle={true}
            fillHandleDirection={"y"}
            enableRangeSelection={true}
            singleClickEdit={false}
            defaultColDef={agGridDefaultColDef}
            suppressCopyRowsToClipboard={true}
          />
        </div>
      </Box>
    </Fragment>
  );
};
