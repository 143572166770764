import { HighlightOff, OpenInNew } from "@mui/icons-material";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useBlocksActionPanelStyles } from "components/BlocksList/BlocksActionPanel/styles";

export const ProcessingQueueTab = ({
  processingQueue,
  setProcessingQueue,
  selectedSamples,
  setSelectedSamples,
  openBlocksModal,
  handleCreateOrder,
}) => {
  const { classes } = useBlocksActionPanelStyles();

  const handleRemoveSlide = (uuid) => {
    setProcessingQueue(processingQueue.filter((slide) => slide.uuid !== uuid));
  };

  const handleCheck = (event) => {
    const newSelectedSamples = new Set(selectedSamples);

    if (event.target.checked) {
      newSelectedSamples.add(event.target.value);
      setSelectedSamples(newSelectedSamples);

      return;
    }

    newSelectedSamples.delete(event.target.value);
    setSelectedSamples(newSelectedSamples);
  };

  const handleAllCheck = (event) => {
    if (event.target.checked) {
      setSelectedSamples(new Set(processingQueue.map((sample) => sample.uuid)));
      return;
    }

    setSelectedSamples(new Set());
  };

  return (
    <Box className={classes.tabContainer}>
      <Box className={classes.orderButtons}>
        <Button
          endIcon={<OpenInNew />}
          variant="contained"
          color="default"
          className={classes.orderButton}
          onClick={handleCreateOrder}
          disabled={!selectedSamples.size}
        >
          <Typography className={classes.orderButtonText}>
            Add to new order
          </Typography>
        </Button>
        <Button
          endIcon={<OpenInNew />}
          variant="contained"
          color="default"
          onClick={openBlocksModal}
          className={classes.orderButton}
          disabled={!selectedSamples.size}
        >
          <Typography className={classes.orderButtonText}>
            Add to unsubmitted order
          </Typography>
        </Button>
      </Box>
      <Typography className={classes.note}>
        <i>
          Note: You will be redirected to the new order for to complete the
          order
        </i>
      </Typography>
      {processingQueue?.length ? (
        <Box className={classes.queue}>
          <Box className={classes.sampleRow} key="header">
            <Checkbox
              size="small"
              color="default"
              onChange={handleAllCheck}
              checked={selectedSamples.size === processingQueue.length}
            />
            <Typography />
            <Typography className={classes.index}>Select All</Typography>
          </Box>
          {processingQueue?.map((sample, index) => (
            <Box className={classes.sampleRow} key={sample.uuid}>
              <Checkbox
                size="small"
                color="default"
                onChange={handleCheck}
                value={sample.uuid}
                checked={selectedSamples.has(sample.uuid)}
              />
              <Typography className={classes.index}>
                {index + 1} {selectedSamples.has(sample)}
              </Typography>
              <Box>
                <Typography>{sample.name}</Typography>
                <Typography className={classes.sampleInfo}>
                  {sample.projectName} / {sample.orderName}
                </Typography>
              </Box>
              <HighlightOff
                className={classes.remove}
                onClick={() => handleRemoveSlide(sample.uuid)}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography className={classes.noData}>
          No samples added.
          <br />
          Choose a sample and press Add to Queue
        </Typography>
      )}
    </Box>
  );
};
