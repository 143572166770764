import React from "react";
import Box from "@mui/material/Box";
import { OrderActivityView } from "components/OrderComments/OrderActivityView";

export const OrderActivityTab = ({ order }) => {
  if (!order) {
    return null;
  }

  return (
    <Box>
      <OrderActivityView order={order} />
    </Box>
  );
};
