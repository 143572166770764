import { backendAPI } from "services/backendAPI";
import { TEAM_USER_INVITE_RESOURCE_NAME } from "services/resources/apiConstants";

export const getInvites = () => {
  return backendAPI.get(TEAM_USER_INVITE_RESOURCE_NAME).then((response) => {
    return response.data;
  });
};

export const postInvite = ({ email, teamUUID }) => {
  const postParams = {
    email,
    team_uuid: teamUUID,
  };

  return backendAPI.post(TEAM_USER_INVITE_RESOURCE_NAME, postParams);
};
