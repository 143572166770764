import Box from "@mui/material/Box";
import { useCommonCardStyles } from "components/common/Card/styles";

export const Card = ({
  handleBodyClick,
  headerContent,
  bodyContent,
  ...props
}) => {
  const { classes } = useCommonCardStyles();

  return (
    <Box className={classes.slideCard} {...props}>
      <Box className={classes.header}>{headerContent}</Box>
      <Box
        className={handleBodyClick ? classes.bodyWithAction : ""}
        onClick={handleBodyClick}
      >
        {bodyContent}
      </Box>
    </Box>
  );
};
