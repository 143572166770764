import signupBackground from "images/signup_background_4_optimized.jpg";
import { makeStyles } from "tss-react/mui";
import { ERROR_ICON, PRIMARY_WHITE, WHITE } from "utilities/colors";
import leftBgImage from "images/leftBgImage.png";
import rightBgImage from "images/rightBgImage.png";

export const useSignupPageStyles = makeStyles()((theme) => ({
  newInstitutionHint: {
    marginBottom: "1rem",
  },
  paperContainer: {
    backgroundImage: `url(${signupBackground})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
  },
  loginHeader: {
    position: "relative",
    top: "-2rem",
    width: "80%",
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
    paddingTop: "2rem",
    paddingBottom: "2rem",
    color: "white",
  },
  textField: {
    flexBasis: 200,
  },
  errorText: {
    color: "red",
    fontSize: "0.875rem",
    paddingTop: "4px",
  },
  tooltipTitle: {
    color: "lightblue",
  },
  gridWrapper: {
    height: "100% !important",
  },
  referralGridWrapper: {
    paddingTop: "0 !important",
  },
  fieldsRequiredText: {
    marginLeft: "3rem",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  loginLink: {
    textDecoration: "none",
    color: "black",
  },
  errorSelect: {
    "& > div": {
      borderColor: ERROR_ICON,
    },
  },
}));

export const useSuccessSignupPageStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "linear-gradient(#c9efff, #ffffff)",
  },
  icon: {
    width: "15rem",
    height: "15rem",
    marginBottom: "1rem",
    color: "#00cd80",
  },
  title: {
    fontSize: "2.5rem",
    marginBottom: "0.5rem",
  },
  timer: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
}));

export const useSharedSocialSlideStyles = makeStyles()({
  wrapper: {
    height: "100vh",
    paddingTop: "2.5rem",
    backgroundColor: PRIMARY_WHITE,
    flexGrow: 1,
    backgroundImage: `url(${leftBgImage}), url(${rightBgImage})`,
    backgroundRepeat: "repeat-y, repeat-y",
    backgroundSize: "20%, 20%",
    backgroundPosition: "left top, right top",
  },

  viewer: {
    maxWidth: "75vw",
    paddingTop: "1.25rem",
    margin: "0 auto",
    height: "70vh",
    "& > div": {
      backgroundColor: WHITE,
      "& > div": {
        maxHeight: "100%",
        "& > .openseadragon": {
          maxHeight: "100%",
        },
      },
    },
  },
  logoWrapper: {
    maxWidth: "75vw",
    display: "block",
    margin: "0 auto",
  },
  logo: {
    width: "13.5rem",
    backgroundColor: PRIMARY_WHITE,
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "4.375rem",
  },
});

export const useSlideImagePageStyles = makeStyles()((theme) => ({
  viewer: {
    height: "82vh",
    position: "relative",
    display: "flex",
    flexGrow: 1,
    width: "100%",
    marginRight: 0,
    paddingRight: 0,
  },
  slidePaper: {
    backgroundColor: "inherit",
    marginLeft: 0,
    paddingLeft: "1rem",
  },
  shareIcon: {
    display: "inline-block",
    cursor: "pointer",
  },
  textField: {
    marginRight: theme.spacing(1),
  },
  newSlideSubmit: { marginBottom: "1rem", marginTop: "1rem" },
  commentsList: {
    overflowY: "scroll",
    maxHeight: "490px",
  },
  staffOnlyNote: {
    marginLeft: "0.5rem",
    width: "calc(100% - 91px)",
    height: "max-content",
    textAlign: "center",
  },
  slideInfo: {
    overflowY: "auto",
    height: "100%",
  },
}));
