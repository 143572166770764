import { PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR } from "components/PathologyMap/constants/styles";
import { makeStyles } from "tss-react/mui";
import {
  ERROR_ICON,
  FORM_TEXT_COLOR,
  MEDIUM_GREY,
  WHITE,
  LIGHT_GREY,
} from "utilities/colors";

export const useSlideViewerActionsStyles = makeStyles()((theme) => ({
  shareLabel: {
    "&.MuiButtonBase-root": {
      fontSize: "0.75rem",
    },
    "&.Mui-disabled": {
      color: "black",
    },
  },
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "0.8rem 0",
    gap: "0.5rem 1.5rem",
  },
  extAnnotationsActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  actions: {
    "& > *": {
      borderBottom: "1px solid #E0E0E0",
    },

    "& > *:last-child": {
      borderBottom: "none",
    },
  },
  shareButton: {
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("xl")]: {
        fontSize: "0.5rem",
        padding: "0.5rem 1rem",
      },
    },
  },
  shareSlide: {
    gridRow: "-1/3",
    gridColumn: "2/3",
  },
  menuBlock: {
    "& > *": {
      borderBottom: "1px solid #E0E0E0",
    },

    "& > *:last-child": {
      borderBottom: "none",
    },
  },
  menuTitle: {
    color: FORM_TEXT_COLOR,
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "0 0 0.3rem 0.5rem",
  },
  menuLabel: {
    fontSize: "1.325rem",
    lineHeight: "87.5%",
    fontVariant: "all-small-caps",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  menuRow: {
    padding: "0.5rem 0 0.5rem 0.5rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderLeft: "0.2rem solid transparent",
    borderRight: "0.2rem solid transparent",
    position: "relative",

    "&:hover": {
      cursor: "pointer",
      borderLeftColor: PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
      borderRightColor: PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
      transition: "border .3s ease",
    },
  },
  actionButtons: {
    display: "flex",
    gap: "1.5rem",
    flexDirection: "column",
    paddingBottom: "2rem",
  },
  shareSlideButton: {
    height: 100,
  },
  menuIcon: {
    width: "1.25rem",
    height: "1.25rem",
    color: MEDIUM_GREY,
    opacity: 0.7,
  },
  menuRowIcon: {
    width: "0.875rem",
    height: "0.875rem",
    color: MEDIUM_GREY,
    opacity: 0.7,
    marginRight: "1rem",
  },
  menuRowLabel: {
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
    whiteSpace: "nowrap",
  },
  attachment: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.875rem",
    },
  },
  label: {
    fontSize: "1rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.875rem",
    },
  },
  deleteButton: {
    color: ERROR_ICON,
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: "0.5rem",
  },
  visibilityToggle: {
    display: "flex",
    flexDirection: "row",
    gap: "0.4rem",
    "& svg": {
      cursor: "pointer",
      color: ERROR_ICON,
    },
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    border: `2px dashed ${LIGHT_GREY}`,
    borderRadius: "0.25rem",
    cursor: "pointer",
    padding: "1rem",
    marginTop: "1rem",
    color: "#000",
  },
}));

export const useViewerMenuItemStyles = makeStyles()((theme) => ({
  switchWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1.2rem",
    paddingBottom: "1rem",
  },
  switch: {
    width: "2.75rem",
    height: "1.125rem",
    padding: 0,
    display: "flex",
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(1.625rem)",
        color: "#fff",

        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: "0.875rem",
      height: "0.875rem",
      borderRadius: "0.5rem",
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  },
  checkedValue: {
    "& .MuiSwitch-switchBase + .MuiSwitch-track::before": {
      position: "absolute",
      content: '"on"',
      left: 8,
      top: -2,
      color: WHITE,
      fontSize: "0.875rem",
    },
  },
  uncheckedValue: {
    "& .MuiSwitch-switchBase + .MuiSwitch-track::before": {
      position: "absolute",
      content: '"off"',
      right: 8,
      top: -2,
      color: WHITE,
      fontSize: "0.875rem",
    },
    [theme.breakpoints.down("xxl")]: {
      "& .MuiSwitch-switchBase + .MuiSwitch-track::before": {
        right: 5,
        top: -1,
      },
    },
  },
  label: {
    textTransform: "uppercase",
  },
}));
