import { Close } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { CollapsedBar } from "components/common/CollapsedBar/CollapsedBar";
import OpenSeadragon from "openseadragon";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "@recogito/annotorious-openseadragon/dist/annotorious.min.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "css/OpenSeaDragon.css";
import { viewerBaseConfig } from "components/ImageViewer/viewerBaseConfig.js";

import {
  isSharedPageSelector,
  openSlideInFullscreenSelector,
  setOpenSlideInFullscreen,
} from "store/slices/slidesListSlice/slidesListSlice";
import {
  addDefaultViewerEventListeners,
  addOverlaysToViewer,
  buildAnnotationByResponseData,
  createButtonGroupElement,
  createFixedZoomButton,
  createFullScreenButtonElement,
  createNavSlideButtonElement,
  createScreenshotButtonElement,
  createZoomIndicatorElement,
  createColorsLegendElement,
  getAnnotationByInnerID,
  getCreatedComment,
  getEditedComments,
  getQCOverlays,
  getRemovedComments,
  getUpdatedRawAnnotations,
  handleViewerSlideScreenshot,
  DistanceMeasureManager,
  initAnnotorious,
  micronsPerPixelToPixelsPerMeter,
  qcScoreOverlaysExist,
  setRowSelectionVisible,
  updateAnnotationComments,
  wrapViewerZoomButtonsInExceptionHandler,
  handleInitExternalAnnotations,
  createTextToggleElement,
} from "components/ImageViewer/utilities";
import {
  DISTANCE_MEASURE_ENABLED_FIELD,
  EXTERNAL_ANNOTATIONS_SWITCH_FIELD,
  NAVIGATOR_SWITCH_FIELD,
  ZOOM_LIST_LABELS,
} from "components/ImageViewer/constants";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { OpenSeaDragonWithFabric } from "components/ImageViewer/OpenSeaDragonFabric";
import { useViewerFilters } from "components/utilities/hooks/slideViewer";
import { OpenSeaDragonHelper } from "components/ImageViewer/OpenSeaDragonImageHelper";
import { currentUserPreferencesSelector } from "store/slices/userDetailsSlice";
import Box from "@mui/material/Box";
import { BrowserView } from "react-device-detect";
import { useSimpleSlideImageViewStyles } from "components/ImageViewer/styles";
import {
  selectCurrentSlideAIModels,
  selectLastChangedAIModelOptions,
  selectQCModelsOpacity,
  selectTissueModelsOpacity,
} from "store/slices/aiToolsSlice";
import { AIDrawingManager } from "components/AITools/utilities/display";
import {
  useExternalAnnotations,
  useKeyboardShortcuts,
} from "components/ImageViewer/hooks";
import { ExtAnnoDrawingManager } from "components/ImageViewer/extAnnoUtils";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

import {
  useCreateSlideAnnotationCommentMutation,
  useCreateSlideAnnotationMutation,
  useDeleteSlideAnnotationCommentMutation,
  useDeleteSlideAnnotationMutation,
  useLazyGetSlideAnnotationsQuery,
  useUpdateSlideAnnotationCommentMutation,
  useUpdateSlideAnnotationMutation,
} from "store/apis/annotationsApi";
import { CustomMultiSlideToolbar } from "components/ImageViewer/components/CustomMultiSlideToolbar";
import {
  useLazyGetSharedSlideQuery,
  useLazyGetSlideMLDataQuery,
  useLazyGetSlideQuery,
} from "store/apis/slidesApi";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import {
  FAIL_GET_SLIDE_ML_DATA,
  FETCH_SLIDE_ERROR_MESSAGE,
} from "constants/errorMessages";
import { IHC_ANTIBODIES_BASE_URL } from "constants/urls";

export const SimpleSlideView = (props) => {
  const {
    slide: slideProp,
    id,
    showNavigator,
    overlaysNumber,
    annotationsEnabled,
    annotationDrawTool,
    annotationDrawModeEnabled,
    showOverlay,
    isGT450ScannerFiltersEnabled,
    isBookmarkFeatureEnabled,
    scoreToDisplay,
    isDistanceMeasureSupported,
    CustomScreenshotButton = null,
    setAnnotationsCount,
    handleNextSlide,
    handlePreviousSlide,
    showSlidesNavigation,
    gridApi,
    setAnnotationsLegend,
  } = props;

  const preferences = useSelector(currentUserPreferencesSelector);
  const openInFullPage = useSelector(openSlideInFullscreenSelector);
  const dispatch = useDispatch();
  const { showError } = useSnackbar();
  const [isNavigatorCollapsed, setIsNavigatorCollapsed] = useState(false);
  const [tileSources, setTileSources] = useState(slideProp.dzi_access_url);

  const [slide, setSlide] = useState(null);
  const [slideOpened, setSlideOpened] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  /* This is just for passing viewer down to CustomMultiSlideToolbar */
  const [viewerState, setViewerState] = useState(null);
  //keep real zoom that could be from 0 to Infinity
  const [zoom, setZoom] = useState(0);
  //keep ratio of Maximum zoom calculated by OSD to MAX_ZOOM_LEVEL_FOR_BASE_VIEWER variable
  const [actualZoomRatio, setActualZoomRatio] = useState(0);
  const { classes } = useSimpleSlideImageViewStyles();
  const viewerRef = useRef(null);
  const annoRef = useRef(null);
  const aiDrawingRef = useRef(null);
  const extAnnoDrawingRef = useRef(null);
  const viewerMouseTracker = useRef(null);
  const [isAnnotationTextAvailable, setIsAnnotationTextAvailable] =
    useState(false);
  const [showAnnotationText, setShowAnnotationText] = useState(true);
  const [anno, setAnno] = useState();
  const [openDimensions, setOpenDimensions] = useState(false);
  const navigatorId = `navigator-${id}`;
  const showFullNavigator = !isNavigatorCollapsed && showNavigator;
  const showCollapsedNavigator = isNavigatorCollapsed && showNavigator;
  const isExternalAnnotationsEnabled =
    preferences[EXTERNAL_ANNOTATIONS_SWITCH_FIELD];
  const [colorLegend, setColorLegend] = useState([]);
  const [colorLegendFilter, setColorLegendFilter] = useState([]);
  const [getSlideMLData] = useLazyGetSlideMLDataQuery();

  const lastChangedAIModelOptions = useSelector(
    selectLastChangedAIModelOptions
  );
  const qcModelsOpacity = useSelector(selectQCModelsOpacity);
  const tissueModelsOpacity = useSelector(selectTissueModelsOpacity);
  const currentAIModels = useSelector(selectCurrentSlideAIModels);

  const { pathname } = useLocation();

  const isIHC = pathname.includes(IHC_ANTIBODIES_BASE_URL);

  const isSharedPage = useSelector(isSharedPageSelector);

  const [createSlideAnnotation] = useCreateSlideAnnotationMutation();
  const [createSlideAnnotationComment] =
    useCreateSlideAnnotationCommentMutation();
  const [deleteSlideAnnotationComment] =
    useDeleteSlideAnnotationCommentMutation();
  const [updateSlideAnnotationComment] =
    useUpdateSlideAnnotationCommentMutation();
  const [updateSlideAnnotation] = useUpdateSlideAnnotationMutation();
  const [deleteSlideAnnotation] = useDeleteSlideAnnotationMutation();
  const [getSlideAnnotations] = useLazyGetSlideAnnotationsQuery();
  const [getSlideData, { data: slideData, error: getSlideDataError }] =
    useLazyGetSlideQuery();
  const [
    getSharedSlideData,
    { data: sharedSlideData, error: getSharedSlideError },
  ] = useLazyGetSharedSlideQuery();

  useQueryError(
    getSlideDataError || getSharedSlideError,
    FETCH_SLIDE_ERROR_MESSAGE
  );

  useEffect(() => {
    if (isIHC) {
      setSlide(slideProp);
      return;
    }

    const slideGetter = isSharedPage ? getSharedSlideData : getSlideData;
    const fetchParams = isSharedPage
      ? { shareUUID: slideProp?.share_uuid }
      : { slideUUID: slideProp?.uuid };
    setSlide(null);
    slideGetter(fetchParams);
  }, [slideProp.uuid, isIHC]);

  useEffect(() => {
    if (!slideData && !sharedSlideData) return;
    setSlide(slideData || sharedSlideData);
  }, [slideData, sharedSlideData]);

  const modelsOpacitiesRef = useRef({
    qc: qcModelsOpacity,
    tissue: tissueModelsOpacity,
  });

  const unmountIgnore = useUnmountIgnore();

  const isMeasureEnabled =
    isDistanceMeasureSupported && preferences[DISTANCE_MEASURE_ENABLED_FIELD];
  const distanceMeasureManager = useRef(null);

  const isCrosshairCursor = isMeasureEnabled || annotationDrawModeEnabled;

  const annotationAttachment = useExternalAnnotations(
    isExternalAnnotationsEnabled,
    slideProp?.halo_annotation_url,
    showError
  );

  useEffect(() => {
    modelsOpacitiesRef.current = {
      qc: qcModelsOpacity,
      tissue: tissueModelsOpacity,
    };
  }, [qcModelsOpacity, tissueModelsOpacity]);

  useEffect(() => {
    if (distanceMeasureManager.current) {
      distanceMeasureManager.current.isMeasureEnabled = isMeasureEnabled;
    }
  }, [isMeasureEnabled]);

  useViewerFilters(viewerRef, isGT450ScannerFiltersEnabled, slide);

  const handleScreenshot = (_, resizeValues = null) => {
    handleViewerSlideScreenshot(
      slideProp,
      viewerRef.current,
      distanceMeasureManager.current,
      resizeValues,
      extAnnoDrawingRef.current,
      isExternalAnnotationsEnabled
    );
    setOpenDimensions(false);
  };

  const toggleFullScreen = () => {
    setOpenDimensions(false);
    const isFullScreen = viewerState?.isFullPage();
    viewerState.setFullPage(!isFullScreen);

    if (!isFullScreen || !gridApi) return;

    setRowSelectionVisible(gridApi);
  };

  useKeyboardShortcuts(
    viewerState,
    toggleFullScreen,
    showSlidesNavigation,
    handleScreenshot,
    handlePreviousSlide,
    handleNextSlide
  );

  const getSlideAnnotationsHandler = async () => {
    const annotationsResponse = await getSlideAnnotations({
      slideUUID: slide?.uuid,
    });

    if (!annotationsResponse?.data?.length) {
      setAnnotationsCount?.(null);
      return;
    }

    setAnnotationsCount?.({
      slideUUID: slide?.uuid,
      count: annotationsResponse.data.length,
    });
  };

  const handleAnnotationCreate = useCallback(
    async (annotation) => {
      const { uuid: slideUUID } = slide;
      annotation["target"]["source"] = window.location.href;

      const postParams = {
        data: annotation,
      };

      const response = await createSlideAnnotation({ slideUUID, postParams });
      await getSlideAnnotationsHandler();

      // check if component is still mounted
      if (!unmountIgnore.current) {
        setAnnotations((prevAnnotations) => [
          ...prevAnnotations,
          response.data,
        ]);
      }
    },
    [slide, unmountIgnore]
  );

  const handleAnnotationCommentCreate = useCallback(
    async (annotation, comment) => {
      if (comment) {
        const { uuid: annotationUUID } = getAnnotationByInnerID(
          annotations,
          annotation.id
        );

        const postParams = {
          ...comment,
        };

        const response = await createSlideAnnotationComment({
          annotationUUID,
          slideUUID: slideProp?.uuid,
          postParams,
        });

        // Check if component is still mounted
        if (!unmountIgnore.current) {
          setAnnotations((prevAnnotations) => {
            const rawAnnotation = getAnnotationByInnerID(
              prevAnnotations,
              annotation.id
            );
            const filteredAnnotations = prevAnnotations.filter(
              (rawAnnotation) => rawAnnotation.data.id !== annotation.id
            );
            const updatedAnnotation = updateAnnotationComments(
              rawAnnotation,
              response.data
            );

            return [...filteredAnnotations, updatedAnnotation];
          });
        }
      }
    },
    [annotations, unmountIgnore]
  );

  const updateAnnotation = useCallback(
    (annotation) => {
      const { uuid: annotationUUID } = getAnnotationByInnerID(
        annotations,
        annotation.id
      );
      const { uuid: slideUUID } = slide;
      const postParams = {
        data: annotation,
      };

      updateSlideAnnotation({ annotationUUID, slideUUID, postParams })
        .unwrap()
        .then(() => {
          setAnnotations((prevAnnotations) => {
            const updatedAnnotations = annoRef.current.getAnnotations();
            return getUpdatedRawAnnotations(
              prevAnnotations,
              updatedAnnotations,
              annotation
            );
          });
        })
        .catch(() => {
          showError("Failed to update annotation");
        });
    },
    [annotations]
  );

  const handleAnnotationCommentsEdit = useCallback((annotation, comments) => {
    if (comments.length) {
      comments.forEach((comment) => {
        const { uuid, value } = comment;
        updateSlideAnnotationComment({
          commentUUID: uuid,
          data: { value },
        })
          .unwrap()
          .then(() => {
            setAnnotations((prevAnnotations) => {
              const updatedAnnotations = annoRef.current.getAnnotations();
              return getUpdatedRawAnnotations(
                prevAnnotations,
                updatedAnnotations,
                annotation
              );
            });
          })
          .catch(() => {
            showError("Failed to update annotation comment");
          });
      });
    }
  }, []);

  const handleAnnotationCommentsRemove = useCallback((annotation, comments) => {
    if (comments.length) {
      comments.forEach((comment) => {
        deleteSlideAnnotationComment({ commentUUID: comment?.uuid })
          .unwrap()
          .then(() => {
            setAnnotations((prevAnnotations) => {
              const updatedAnnotations = annoRef.current.getAnnotations();
              return getUpdatedRawAnnotations(
                prevAnnotations,
                updatedAnnotations,
                annotation
              );
            });
          })
          .catch(() => {
            showError("Failed to delete annotation comment");
          });
      });
    }
  }, []);

  const handleAnnotationUpdate = useCallback(
    (annotation, prevAnnotation) => {
      const { value: currentPosition } = annotation.target.selector;
      const { value: prevPosition } = prevAnnotation.target.selector;

      if (currentPosition !== prevPosition) {
        updateAnnotation(annotation);
      }

      // there is no option to create more than one comment per one annotation update
      const createdComment = getCreatedComment(annotation, prevAnnotation);
      handleAnnotationCommentCreate(annotation, createdComment);

      const removedComments = getRemovedComments(annotation, prevAnnotation);
      handleAnnotationCommentsRemove(annotation, removedComments);

      const editedComments = getEditedComments(annotation, prevAnnotation);
      handleAnnotationCommentsEdit(annotation, editedComments);
    },
    [
      handleAnnotationCommentCreate,
      handleAnnotationCommentsRemove,
      handleAnnotationCommentsEdit,
      updateAnnotation,
    ]
  );

  const handleAnnotationRemove = useCallback(
    async (annotation) => {
      const { uuid: slideUUID } = slide;
      const { uuid: annotationUUID } = getAnnotationByInnerID(
        annotations,
        annotation.id
      );
      deleteSlideAnnotation({ annotationUUID, slideUUID })
        .unwrap()
        .then(() => {
          setAnnotations((prevAnnotations) => {
            const updatedAnnotations = annoRef.current.getAnnotations();
            return getUpdatedRawAnnotations(
              prevAnnotations,
              updatedAnnotations,
              annotation
            );
          });
        })
        .catch(() => {
          showError("Failed to delete annotation");
        });

      await getSlideAnnotationsHandler();
    },
    [annotations]
  );

  /**
   * Fetch annotations effect. When either annotationsEnabled or slide
   * changes, it fetches annotations and set them to the state
   */
  useEffect(() => {
    if (!slide) {
      return;
    }
    getSlideAnnotations({ slideUUID: slide?.uuid }).then((response) => {
      if (!response) return;
      // Check if component is still mounted
      response.data?.length &&
        setAnnotationsCount?.({
          slideUUID: slide?.uuid,
          count: response.data.length,
        });
      !unmountIgnore.current && setAnnotations(response.data);
    });
  }, [slide?.uuid]);

  const openDimensionsHandler = (isOpen) => {
    setOpenDimensions(isOpen);
  };

  useEffect(() => {
    if (!viewerState) {
      return;
    }

    const zoomLabel = zoom / actualZoomRatio;
    const zoomButtonList = ZOOM_LIST_LABELS.map((zoomButtonValue) =>
      createFixedZoomButton(
        viewerState,
        zoomButtonValue,
        actualZoomRatio,
        Math.abs(zoomButtonValue - zoomLabel) < Number.EPSILON
      )
    );

    const screenshotButton = new OpenSeadragon.Button({
      element: createScreenshotButtonElement(
        handleScreenshot,
        openDimensionsHandler,
        classes,
        openDimensions
      ),
      tooltip: "Screenshot",
    });

    const isFullPage = viewerState.canvas ? viewerState.isFullPage() : false;

    const fullScreenButton = new OpenSeadragon.Button({
      element: createFullScreenButtonElement(
        id,
        toggleFullScreen,
        isFullPage,
        classes
      ),
      tooltip: "Full screen",
    });

    const colorsLegendButton = new OpenSeadragon.Button({
      element: createColorsLegendElement(
        colorLegend,
        colorLegendFilter,
        setColorLegendFilter
      ),
      tooltip: "Colors Legend",
    });

    const annotationsTextToggle = new OpenSeadragon.Button({
      element: createTextToggleElement(
        isAnnotationTextAvailable,
        showAnnotationText,
        setShowAnnotationText
      ),
      tooltip: "Toggle Annotations Text",
    });

    const prevSlideButton = new OpenSeadragon.Button({
      element: createNavSlideButtonElement(
        "prev",
        handlePreviousSlide,
        isFullPage,
        toggleFullScreen
      ),
      tooltip: "Previous slide",
    });

    const nextSlideButton = new OpenSeadragon.Button({
      element: createNavSlideButtonElement(
        "next",
        handleNextSlide,
        isFullPage,
        toggleFullScreen
      ),
      tooltip: "Next slide",
    });

    const buttonGroup = new OpenSeadragon.ButtonGroup({
      buttons: zoomButtonList,
      element: createButtonGroupElement(),
    });

    const zoomIndicator = new OpenSeadragon.Button({
      element: createZoomIndicatorElement(zoom, actualZoomRatio),
      tooltip: "Zoom level",
    });

    const infoGroup = new OpenSeadragon.ButtonGroup({
      buttons: CustomScreenshotButton
        ? [zoomIndicator]
        : [zoomIndicator, screenshotButton],
      element: createButtonGroupElement(),
    });

    const navGroup = new OpenSeadragon.ButtonGroup({
      buttons: [prevSlideButton, nextSlideButton],
      element: createButtonGroupElement(),
    });

    // Remove old zoom controls
    if (viewerState.controls) {
      viewerState.controls.forEach((control) => {
        if (!control.element.id.includes("navigator-")) {
          control.element.remove();
        }
      });
    }

    viewerState.addControl(buttonGroup.element, {
      attachToViewer: true,
      anchor: OpenSeadragon.ControlAnchor.TOP_LEFT,
    });
    viewerState.addControl(infoGroup.element, {
      attachToViewer: true,
      anchor: OpenSeadragon.ControlAnchor.TOP_LEFT,
    });
    if (showSlidesNavigation) {
      viewerState.addControl(navGroup.element, {
        attachToViewer: true,
        anchor: OpenSeadragon.ControlAnchor.BOTTOM_RIGHT,
      });
    }
    viewerState.addControl(fullScreenButton.element, {
      attachToViewer: true,
      anchor: OpenSeadragon.ControlAnchor.TOP_RIGHT,
      autoFade: false,
    });
    isExternalAnnotationsEnabled &&
      colorLegend.length &&
      viewerState.addControl(colorsLegendButton.element, {
        attachToViewer: true,
        anchor: OpenSeadragon.ControlAnchor.BOTTOM_RIGHT,
        autoFade: false,
      });
    isAnnotationTextAvailable &&
      isExternalAnnotationsEnabled &&
      viewerState.addControl(annotationsTextToggle.element, {
        attachToViewer: true,
        anchor: OpenSeadragon.ControlAnchor.BOTTOM_RIGHT,
        autoFade: false,
      });
  }, [
    viewerState,
    zoom,
    actualZoomRatio,
    openDimensions,
    isExternalAnnotationsEnabled,
    colorLegend,
    showAnnotationText,
  ]);
  /**
   * Init OpenSeadragon effect. It is triggered
   * when either of followings change:
   * - id
   * - tileSources
   * - showNavigator
   * - annotationsEnabled
   */

  useEffect(() => {
    const zoomInName = `zoom-in-${id}`;
    const zoomOutName = `zoom-out-${id}`;
    const fullPageName = `full-page-${id}`;
    const toolbarName = `toolbar-${id}`;
    const pixelsPerMeter = micronsPerPixelToPixelsPerMeter(
      slide?.microns_per_pixel
    );

    const viewer = new OpenSeaDragonWithFabric({
      ...viewerBaseConfig,
      id,
      defaultZoomLevel: 1,
      zoomInButton: zoomInName,
      zoomOutButton: zoomOutName,
      fullPageButton: fullPageName,
      tileSources,
      // since the dzi is on a separate server, necessary for downloading screenshots
      crossOriginPolicy: "Anonymous",
      // TODO - This isn't documented very well, but turn it on after you test on mobile
      // immediateRender: true,
      showNavigator: showFullNavigator,
      navigatorId,
      toolbar: toolbarName,
      pixelsPerMeter,
    });

    if (openInFullPage) {
      viewer.setFullPage(true);
      dispatch(setOpenSlideInFullscreen(false));
    }

    if (isBookmarkFeatureEnabled) {
      viewer.bookmarkUrl({ id });
    }

    wrapViewerZoomButtonsInExceptionHandler(
      viewer.zoomInButton,
      viewer.zoomOutButton
    );

    const viewerHelper = new OpenSeaDragonHelper({ viewer });

    viewer.scalebar({
      barThickness: 2,
      backgroundColor: "rgba(255, 255, 255, 0)",
      stayInsideImage: false,
    });

    viewerRef.current = viewer;

    viewerMouseTracker.current = new OpenSeadragon.MouseTracker({
      element: document,
    });

    addDefaultViewerEventListeners(viewerRef.current, {
      setSlideOpened,
      setZoom,
      actualZoomRatio,
      setActualZoomRatio,
      mouseTracker: viewerMouseTracker.current,
      viewerHelper,
    });

    setViewerState(viewer);

    const isDistanceMeasurementAvailable = true;

    if (isDistanceMeasurementAvailable) {
      distanceMeasureManager.current = new DistanceMeasureManager(
        viewerRef.current,
        isMeasureEnabled
      );
      distanceMeasureManager.current.init();
    }

    return () => {
      viewerRef.current?.navigator?.destroy();
      viewerRef.current?.destroy();
      viewerRef.current = null;

      distanceMeasureManager.current?.destroy();
      viewerMouseTracker.current?.destroy();
    };
  }, [
    annotationsEnabled,
    id,
    showFullNavigator,
    tileSources,
    isExternalAnnotationsEnabled,
    slide,
  ]);

  useEffect(() => {
    if (!slideOpened || !slide) return;

    const handleMLDataGetFail = (e) => {
      showError(FAIL_GET_SLIDE_ML_DATA);
    };

    aiDrawingRef.current = new AIDrawingManager(
      viewerRef.current,
      slide,
      modelsOpacitiesRef,
      getSlideMLData,
      handleMLDataGetFail,
      currentAIModels
    );

    extAnnoDrawingRef.current = new ExtAnnoDrawingManager(
      viewerRef.current,
      slide,
      []
    );

    return () => {
      aiDrawingRef.current?.destroy();
      aiDrawingRef.current = null;
      extAnnoDrawingRef.current?.destroy();
      extAnnoDrawingRef.current = null;
    };
  }, [slideOpened, slide]);

  /**
   * Init Annotorious effect. It is triggered
   * when either of followings change:
   * - annotationDrawModeEnabled
   * - annotationDrawTool
   * - annotationsEnabled
   * - slide
   * - annotations
   */
  useEffect(() => {
    const viewer = viewerRef.current;

    if (annotationsEnabled) {
      annoRef.current = initAnnotorious({
        viewer,
        annotationsEnabled,
        annotationDrawTool,
        annotationDrawModeEnabled,
        handleAnnotationUpdate,
        handleAnnotationCreate,
        handleAnnotationRemove,
      });

      setAnno(annoRef.current);
    }

    return () => {
      annoRef.current?.destroy();
      annoRef.current = null;
    };
  }, [
    annotationDrawModeEnabled,
    annotationDrawTool,
    annotationsEnabled,
    handleAnnotationCreate,
    handleAnnotationRemove,
    handleAnnotationUpdate,
  ]);

  /**
   * slideChanged effect. Whenever slide props changes,
   * it updates slide and tileSources states.
   */
  useEffect(() => {
    setTileSources(slideProp.dzi_access_url);
    if (!aiDrawingRef?.current || !extAnnoDrawingRef?.current) {
      return;
    }

    aiDrawingRef.current.updateSlide(slide);
    extAnnoDrawingRef.current.updateSlide(slide);
  }, [slide]);

  /**
   * toggleOverlay and redrawOverlay effects. When showOverlay or slideOpened
   * change, it toggles overlay. Additionally, it redraws overlay if slide or
   * overlaysNumber change
   */
  useEffect(() => {
    const isViewerUpdatable = slideOpened && viewerRef.current;

    if (!isViewerUpdatable || !viewerRef.current?.source) return;

    if (!showOverlay) viewerRef.current?.clearOverlays();
    else {
      viewerRef.current?.clearOverlays();

      if (qcScoreOverlaysExist(slide, scoreToDisplay)) {
        const qcOverlays = getQCOverlays(
          viewerRef.current,
          slide,
          scoreToDisplay,
          overlaysNumber
        );

        addOverlaysToViewer(viewerRef.current, qcOverlays);
      }
    }
  }, [overlaysNumber, showOverlay, slide, slideOpened, scoreToDisplay]);

  useEffect(() => {
    if (!annotations?.length) return;

    if (annoRef.current) {
      const updatedAnnotations = annotations.map(buildAnnotationByResponseData);

      annoRef.current.setAnnotations(updatedAnnotations);
    }
  }, [annotations, anno]);

  useEffect(() => {
    if (!slideOpened) {
      return;
    }

    const { modelName, changeType } = lastChangedAIModelOptions || {};

    modelName && aiDrawingRef.current?.handleModelChange(changeType, modelName);
  }, [lastChangedAIModelOptions, slide, slideOpened]);

  const osdDivClass = "ocd-div";

  useEffect(() => {
    if (!viewerRef?.current?.source) {
      return;
    }

    handleInitExternalAnnotations(
      isExternalAnnotationsEnabled,
      colorLegendFilter,
      extAnnoDrawingRef,
      annotationAttachment,
      setColorLegend,
      setIsAnnotationTextAvailable,
      showAnnotationText,
      setAnnotationsLegend
    );
  }, [
    isExternalAnnotationsEnabled,
    viewerRef?.current?.source,
    colorLegendFilter,
    annotationAttachment,
    showAnnotationText,
    slideProp.halo_annotation_url,
  ]);

  return (
    <Box
      className={`${classes.osdContainer} ${
        isCrosshairCursor && classes.crosshairCursor
      }`}
    >
      <Box className={osdDivClass}>
        <Modal open={openInFullPage} className={classes.modalIndex}>
          <Box className={classes.fullScreenModal}>
            <Box />
          </Box>
        </Modal>
        {showCollapsedNavigator && (
          <CollapsedBar
            onExpand={() => setIsNavigatorCollapsed(false)}
            preferencesField={NAVIGATOR_SWITCH_FIELD}
            title="Navigator"
            positionClass={classes.collapsedNavigator}
          />
        )}
        {showFullNavigator && (
          <div className={`navigator-wrapper c-shadow ${classes.navigator}`}>
            <>
              <Box
                className={`${classes.closeBackground} hover-visible`}
                onClick={() => setIsNavigatorCollapsed(true)}
              >
                <Close className={classes.closeIcon} />
              </Box>
              <div
                id={navigatorId}
                className={`osd-navigator-container rounded-border`}
              />
            </>
          </div>
        )}
        <Box id={`toolbar-${id}`} className={"osd-tb"}>
          <CustomMultiSlideToolbar
            id={id}
            viewer={viewerState}
            isFullScreen={viewerState?.isFullPage()}
          />
        </Box>
        <Box className="openseadragon" id={id} />

        <BrowserView>
          {CustomScreenshotButton && (
            <Box onClick={handleScreenshot}>
              <CustomScreenshotButton />
            </Box>
          )}
        </BrowserView>
      </Box>
    </Box>
  );
};

export const SimpleSlideViewMemo = React.memo(SimpleSlideView);
