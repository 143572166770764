import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState } from "react";
import { postTeamAddress } from "services/resources/commonResources";
import { DefaultTeamAddressField } from "components/Shared/TeamAddress/DefaultTeamAddressField";
import {
  addressesDefFieldsOptions,
  addressesValidationSchema,
  DEFAULT_SUBMIT_BUTTON_TEXT,
  DEFAULT_ADRESS_VALUES,
  SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE,
} from "components/Shared/TeamAddress/constants";
import { DefaultSubmitButton } from "components/Shared/Buttons/DefaultSubmitButton";
import { parseActualAddress } from "utilities/general";
import _ from "lodash";
import { Button, Grid } from "@mui/material";
import LocationOn from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import { getCurrentLocation } from "services/resources/googleMaps";
import { isMobileOrTablet } from "components/IFViewer/utils";
import { SubmittingIcon } from "components/icons/LoadingIcon";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const TeamAddressForm = ({
  address = DEFAULT_ADRESS_VALUES,
  SubmitButton = DefaultSubmitButton,
  submitButtonText = DEFAULT_SUBMIT_BUTTON_TEXT,
  onAfterSuccessSubmit,
  teamUUID,
  addressesFieldsOptions = addressesDefFieldsOptions,
}) => {
  const methods = useForm({
    resolver: yupResolver(addressesValidationSchema),
    mode: "onBlur",
  });

  const { handleSubmit, reset, setValue } = methods;

  const [actualAddress, setActualAddress] = useState({});
  const [isActualAddressLoading, setIsActualAddressLoading] = useState(false);

  const { showWarning } = useSnackbar();

  const { showSuccess, showError } = useSnackbar();

  const getActualAddress = () => {
    setIsActualAddressLoading(true);

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const handleSuccess = (pos) => {
      const crd = pos.coords;
      const lat = crd.latitude.toString();
      const lng = crd.longitude.toString();

      getCurrentLocation(lat, lng)
        .then((response) => {
          const formattedResponse = response.data;
          const actualUserAddress = parseActualAddress(formattedResponse);
          setActualAddress({ ...actualUserAddress });
        })
        .catch((error) =>
          showWarning(`An Error Occurred While Getting Your Location. ${error}`)
        )
        .finally(() => setIsActualAddressLoading(false));
    };

    const handleError = () => {
      setIsActualAddressLoading(false);
      showWarning("An Error Occurred While Getting Your Location.");
    };

    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      options
    );
  };

  useEffect(() => {
    reset({ ...address });
  }, [address]);

  //This useEffect hook fill address form with fetched from google api actual address values
  useEffect(() => {
    if (!_.isEmpty(actualAddress)) {
      actualAddress &&
        Object.keys(actualAddress).forEach((key) => {
          setValue(key, actualAddress[key], { shouldValidate: true });
        });
    }
  }, [actualAddress]);

  const onSubmit = (data) => {
    const postParams = {
      team_uuid: address.team?.uuid || teamUUID,
      ...(!!address.uuid && { uuid: address.uuid }),
      ...data,
    };

    postTeamAddress(postParams)
      .then((response) => {
        showSuccess(SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE);

        if (onAfterSuccessSubmit) {
          onAfterSuccessSubmit(response);
        }
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  const submitSelectedAddress = useCallback(
    (address) => {
      const actualUserAddress = parseActualAddress(address);
      setActualAddress({ ...actualUserAddress });
    },
    [setActualAddress]
  );

  const fillFormWithActualLocation = useCallback(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "prompt") {
        showWarning("Please Allow Permissions to Fill Address");
      } else if (result.state === "denied") {
        showWarning(
          "We were unable to get your location. Please allow permissions to fill your address."
        );
      }

      result.state !== "denied" && getActualAddress();
    });
  }, []);

  const renderAddressField = (addressFieldOption) => {
    // allows to provide your own component to render address field
    // you can define it in addressesDefFieldsOptions arrays
    const AddressFieldComponent =
      addressFieldOption.component ?? DefaultTeamAddressField;

    return (
      <AddressFieldComponent
        {...addressFieldOption}
        submitSelectedAddress={submitSelectedAddress}
        key={addressFieldOption.name}
      />
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {addressesFieldsOptions.map(renderAddressField)}
        <Grid container direction="row" justifyContent="space-between">
          <Box display="flex" justifyContent="flex-start">
            {!isMobileOrTablet() && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={false}
                  onClick={fillFormWithActualLocation}
                  disabled={isActualAddressLoading}
                >
                  <SubmittingIcon submitting={isActualAddressLoading} />
                  <LocationOn />
                  Detect Current Location
                </Button>
              </Box>
            )}
          </Box>
          <SubmitButton children={submitButtonText} />
        </Grid>
      </form>
    </FormProvider>
  );
};
