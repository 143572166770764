import { Fade } from "@mui/material";
import Box from "@mui/material/Box";
import { PathologyMapSlideCardHeader } from "components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardHeader";
import { PathologyMapSlideCardContent } from "components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardContent";
import { usePathologyMapSlideCardItemStyles } from "components/PathologyMap/PathologyMapSlideCards/styles";

export const PathologyMapSlideCardItem = ({
  slide,
  resultsCount,
  index,
  selectedFilters,
}) => {
  const { classes } = usePathologyMapSlideCardItemStyles();

  return (
    <Fade in={true} appear={true} timeout={750}>
      <Box className={classes.slideCardsContainer}>
        <PathologyMapSlideCardHeader
          slide={slide}
          resultsCount={resultsCount}
          index={index}
        />
        <PathologyMapSlideCardContent
          slide={slide}
          selectedFilters={selectedFilters}
        />
      </Box>
    </Fade>
  );
};
