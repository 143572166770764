import { TableHead, TableRow } from "@mui/material";

import React from "react";
import { AlternativeTableCellWithTypography } from "components/OrdersList/constants";

export const InProgressConsultationOrderTableHeader = (
  <TableHead>
    <TableRow>
      <AlternativeTableCellWithTypography>
        Consultation Name
      </AlternativeTableCellWithTypography>
      {/*<AlternativeTableCellWithTypography>*/}
      {/*  Project*/}
      {/*</AlternativeTableCellWithTypography>*/}
      <AlternativeTableCellWithTypography>
        Histology Order
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        Team
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        Actions
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        Status
      </AlternativeTableCellWithTypography>
      <AlternativeTableCellWithTypography>
        Created
      </AlternativeTableCellWithTypography>
    </TableRow>
  </TableHead>
);
