import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useMemo } from "react";
import { AppContext } from "services/context";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { NotUploadedSlideColumnDefs } from "components/utilities/AgGridCols/AgGridColumns";
import { useExpansionStyles } from "components/OrderDetails/styles";

const getContextMenuItems = (context) => (params) => {
  if (!params.node?.data) {
    return [];
  }

  const row = params.node.data;

  return [
    {
      name: "Copy Slide Name",
      action: () => {
        navigator.clipboard.writeText(row.uploaded_name);
        context.handleContextModalChange("snackbarCopied")();
      },
    },
  ];
};

export const NotUploadedSlidesExpansionGrid = ({ notUploadedSlides }) => {
  const { classes } = useExpansionStyles();
  const context = useContext(AppContext);

  let label;
  if (!notUploadedSlides?.length) {
    label = "Slides Not Uploaded";
  } else {
    label = `${"Slides Not Uploaded"} (${notUploadedSlides.length})`;
  }

  const sortedSlides = useMemo(() => {
    return [...notUploadedSlides].sort((a, b) =>
      a.uploaded_name.localeCompare(b.uploaded_name)
    );
  }, [notUploadedSlides]);

  const gridThemeStyle = {
    height: "400px",
    width: "100%",
    backgroundColor: "transparent",
  };

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        className={classes.alternativeHeader}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant={"body1"} color={"inherit"}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.noPaddingAccordion}>
        <div className="ag-theme-balham" style={gridThemeStyle}>
          <AgGridReact
            columnDefs={NotUploadedSlideColumnDefs}
            rowData={sortedSlides}
            enableColResize={true}
            sideBar={false}
            rowGroupPanelShow={"always"}
            getContextMenuItems={getContextMenuItems(context)}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
