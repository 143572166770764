import { useEffect, useState } from "react";
import { Box, CircularProgress, Tabs, Tab } from "@mui/material";
import {
  FETCH_SAMPLE_ERROR_MESSAGE,
  getSlideMeta,
} from "components/OrderSlidesList/SlideActionTabs/constants";
import { SlideMetadataRow } from "components/OrderSlidesList/SlideActionTabs/SlideMetadata/SlideMetadataRow/SlideMetadataRow";
import { useStyles } from "components/OrderSlidesList/SlideActionTabs/SlideMetadata/styles";
import { useSelector } from "react-redux";
import { slidesListOrderSelector } from "store/slices/slidesListSlice/slidesListSlice";
import { TABS_BLUE } from "utilities/colors";
import { getSamples } from "services/resources/samples";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import _ from "lodash";
import { getSampleScienceInfo } from "components/OrderSlidesList/utilities";
import { EditSampleButton } from "components/SampleEditor/components/EditSampleButton";

export const SlideMetadata = ({ slide }) => {
  const { classes } = useStyles();
  const order = useSelector(slidesListOrderSelector);
  const [currentTab, setCurrentTab] = useState(0);
  const metaData = getSlideMeta(slide, order);
  const [sampleData, setSampleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { showError } = useSnackbar();

  useEffect(() => {
    if (!order || !slide.sample || !currentTab || sampleData.length) return;
    setIsLoading(true);
    getSamples(order.uuid)
      .then(({ data }) => {
        const sampleScienceInfo = data.find(
          (sample) => sample.uuid === slide.sample.uuid
        )?.science_info;

        !_.isEmpty(sampleScienceInfo) &&
          setSampleData(getSampleScienceInfo(sampleScienceInfo));
      })
      .catch(() => {
        showError(FETCH_SAMPLE_ERROR_MESSAGE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentTab, slide.sample, sampleData]);

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const MetadataTable = ({ data }) => (
    <Box className={classes.root}>
      {data.map(({ label, value, keyTooltip, valueTooltip }) => (
        <SlideMetadataRow
          key={label}
          label={label}
          value={value}
          keyTooltip={keyTooltip}
          valueTooltip={valueTooltip}
        />
      ))}
    </Box>
  );

  return slide.sample ? (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabsChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: TABS_BLUE,
          },
        }}
        className={classes.tabs}
      >
        <Tab label="Standard Metadata" id="standard-metadata" />
        <Tab label="Science Info" id="science-info" />
        <EditSampleButton sampleId={slide.sample.id} />
      </Tabs>
      {isLoading ? (
        <CircularProgress size={20} className={classes.centered} />
      ) : !currentTab ? (
        <MetadataTable data={metaData} />
      ) : sampleData.length ? (
        <MetadataTable data={sampleData} />
      ) : (
        <Box className={classes.centered}>No science info available</Box>
      )}
    </>
  ) : (
    <MetadataTable data={metaData} />
  );
};
