import { useRef } from "react";

const getClickExecuteAction = (clickAction) => {
  return typeof clickAction === "function"
    ? clickAction
    : clickAction.onMouseDown;
};

const getClickFinalizeAction = (clickAction) => {
  return typeof clickAction === "function"
    ? () => console.log("Finalizer not defined")
    : clickAction.onMouseUp;
};

export const useLongPressClick = (
  onDefaultClick,
  onLongPressClick,
  delay = 300
) => {
  const mouseTimeout = useRef(null);
  const mouseLastTimeClickTime = useRef(null);

  const handleMouseDown = () => {
    mouseLastTimeClickTime.current = Date.now();

    mouseTimeout.current = setTimeout(() => {
      getClickExecuteAction(onLongPressClick)();
    }, delay);
  };

  const handleMouseUp = () => {
    getClickFinalizeAction(onLongPressClick)();

    const clickHoldDelayTime = Date.now() - mouseLastTimeClickTime.current;

    if (clickHoldDelayTime < delay) {
      clearTimeout(mouseTimeout.current);
      onDefaultClick();
    }
  };

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
  };
};
