import React, { Fragment, useContext, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Snackbar,
  IconButton,
  Checkbox,
} from "@mui/material";
import {
  ImageAnalysisAndPathologyConsultationTurnaroundTimeOptions,
  PathologyExpertiseOptions,
} from "components/OrderForm/constants";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import { DEMO_USER_EMAIL } from "constants/emails";
import { AppContext } from "services/context";
import { createPathologyConsultationOrder } from "services/resources/pathologyConsultations";
import { useSelector } from "react-redux";
import {
  currentUserEmailSelector,
  primaryTeamSelector,
} from "store/slices/userDetailsSlice";
import { useRequestPathologistModalLegacyStyles } from "./styles";

export const RequestPathologistModalLegacy = ({ slidesSelected }) => {
  const context = useContext(AppContext);
  const {
    handleContextModalChange,
    snackbarPathologyConsultationOpen,
    modalPathologistConsultationOpen,
  } = context;

  const primaryTeam = useSelector(primaryTeamSelector);
  const email = useSelector(currentUserEmailSelector);

  const { classes } = useRequestPathologistModalLegacyStyles();

  const [turnaroundDaysSelected, setTurnaroundDaysSelected] = useState({
    value: 10,
    label: "Ten Days",
  });
  const [pathologySpecialities, setPathologySpecialities] = useState(null);
  const [description, setDescription] = useState("");
  const [anticipatedFindings, setAnticipatedFindings] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [requestedPathologist, setRequestedPathologist] = useState("");
  const [referencePapers, setReferencePapers] = useState("");
  const [isIntegratedPathologyReport, setIsIntegratedPathologyReport] =
    useState(false);

  const handleSelectChange = (setStateAction) => (selectedOption) => {
    setStateAction(selectedOption);
  };

  const handleChange = (setStateAction) => (event) => {
    setStateAction(event.target.value);
  };

  const handleCheckboxChange = (setStateAction) => (event) => {
    setStateAction(event.target.checked);
  };

  const submitPathologyConsultationRequest = () => {
    const slideUUIDs = slidesSelected.map((slide) => {
      return slide.uuid;
    });

    const specialities = pathologySpecialities.map((speciality) => {
      return speciality.value;
    });

    const postParams = {
      team_uuid: primaryTeam.uuid,
      slide_uuids: slideUUIDs,
      turnaround_days: turnaroundDaysSelected.value,
      requested_pathologist: requestedPathologist,
      special_instructions: specialInstructions,
      description: description,
      anticipated_findings: anticipatedFindings,
      reference_papers: referencePapers,
      specialities: specialities,
      is_integrated_pathology_report: isIntegratedPathologyReport,
    };

    createPathologyConsultationOrder({ postParams }).then(() => {
      handleContextModalChange("snackbarPathologyConsultationOpen")();
      handleContextModalChange("modalPathologistConsultationOpen")();
    });
  };

  const renderPathologyConsultationSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarPathologyConsultationOpen}
        autoHideDuration={6000}
        onClose={handleContextModalChange("snackbarPathologyConsultationOpen")}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            Pathology Consultation Requested Submitted!
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            size="large"
            onClick={handleContextModalChange(
              "snackbarPathologyConsultationOpen"
            )}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  };

  const pathologyConsultationActions = () => {
    const isDemoUser = email === DEMO_USER_EMAIL;
    const RequestLabel = isDemoUser
      ? "Demo Users Cannot Request"
      : "Request Pathology Consultation";

    return (
      <Typography align={"right"} style={{ marginTop: "1rem" }}>
        <span>
          <Button
            color="primary"
            onClick={handleContextModalChange(
              "modalPathologistConsultationOpen"
            )}
            style={{ marginRight: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={submitPathologyConsultationRequest}
            disabled={pathologySpecialities < 1 || isDemoUser}
          >
            {RequestLabel}
          </Button>
        </span>
      </Typography>
    );
  };

  const renderPathologyConsultationForm = () => {
    const isOpen = modalPathologistConsultationOpen;
    const onClose = handleContextModalChange(
      "modalPathologistConsultationOpen"
    );

    const slideNames = slidesSelected.map((slide) => {
      return slide.name;
    });

    if (!isOpen) {
      return null;
    }

    return (
      <DefaultDialog
        open={isOpen}
        onClose={onClose}
        actions={pathologyConsultationActions}
        title={"Request Pathologist Consultation"}
      >
        <Typography
          variant="subtitle1"
          id="simple-modal-description"
          style={{ marginBottom: "1rem" }}
        >
          Access the world’s first online pathologist network and gain instant
          access to top pathologists covering all areas of expertise from human
          and veterinary pathology. Our award winning pathologists will evaluate
          your whole slide images and provide fast results to match your
          required turnaround time.
        </Typography>
        <Typography variant="h6" id="simple-modal-description">
          Slides Selected ({slidesSelected.length})
        </Typography>
        {slideNames.map((name) => {
          return <Typography key={name}>{name}</Typography>;
        })}
        <Typography variant="h6" style={{ marginTop: "1rem" }}>
          Turnaround Time
        </Typography>
        <Select
          value={turnaroundDaysSelected}
          onChange={handleSelectChange(setTurnaroundDaysSelected)}
          options={ImageAnalysisAndPathologyConsultationTurnaroundTimeOptions}
        />
        <Typography variant="h6" style={{ marginTop: "1rem" }}>
          Speciality
        </Typography>
        <Select
          value={pathologySpecialities}
          onChange={handleSelectChange(setPathologySpecialities)}
          isMulti
          options={PathologyExpertiseOptions}
        />
        <TextField
          id="standard-multiline-flexible"
          label="Project Description"
          style={{ marginTop: "1rem" }}
          placeholder="Project Description"
          fullWidth={true}
          multiline
          value={description}
          onChange={handleChange(setDescription)}
          className={classes.textField}
        />
        <TextField
          id="standard-multiline-flexible"
          label="Special Instructions (Analysis, Scoring Methodology)"
          style={{ marginTop: "1rem" }}
          placeholder="Special Instructions (Analysis, Scoring Methodology)"
          fullWidth={true}
          multiline
          value={specialInstructions}
          onChange={handleChange(setSpecialInstructions)}
          className={classes.textField}
        />
        <TextField
          id="standard-multiline-flexible"
          label="Anticipated Findings"
          style={{ marginTop: "1rem" }}
          placeholder="Anticipated Findings"
          fullWidth={true}
          multiline
          value={anticipatedFindings}
          onChange={handleChange(setAnticipatedFindings)}
          className={classes.textField}
        />
        <TextField
          id="standard-multiline-flexible"
          label="Reference Papers"
          style={{ marginTop: "1rem" }}
          placeholder="Reference Papers"
          fullWidth={true}
          multiline
          value={referencePapers}
          onChange={handleChange(setReferencePapers)}
          className={classes.textField}
        />
        <TextField
          id="standard-multiline-flexible"
          label="Requested Pathologist Name (Leave blank if no preference)"
          style={{ marginTop: "1rem" }}
          placeholder="Name of pathologist"
          fullWidth={true}
          multiline
          value={requestedPathologist}
          onChange={handleChange(setRequestedPathologist)}
          className={classes.textField}
        />
        <Checkbox
          checked={isIntegratedPathologyReport}
          onChange={handleCheckboxChange(setIsIntegratedPathologyReport)}
          value="isIntegratedPathologyReport"
          style={{ marginLeft: "-1rem" }}
        />
        <span>Integrated Pathology Report</span>
      </DefaultDialog>
    );
  };

  return (
    <Fragment>
      {renderPathologyConsultationForm()}
      {renderPathologyConsultationSnackbar()}
    </Fragment>
  );
};
