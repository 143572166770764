import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import { ConditionalComponent } from "components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent";
import {
  ACTIONS_TAB_VALUE,
  GROUP_TAB_VALUE,
  SLIDE_METADATA_TAB_VALUE,
} from "components/PathologyMap/constants/common";
import { PathologyMapSlideCardInfo } from "components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardInfo";
import { SlideGroupsVerticalContainer } from "components/SlideGroups/Modals/components/SlideGroupsVerticalContainer";
import { Button } from "@mui/material";
import { Actions } from "components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/Actions";
import { CreateSlideGroupModal } from "components/SlideGroups/Modals/CreateSlideGroupModal";
import { getSlideAttributesFromSlide } from "components/PathologyMap/utilities/common";
import { EditSampleButton } from "components/SampleEditor/components/EditSampleButton";

export const SlideInfoCardContent = ({ classes, currentTabValue, slide }) => {
  const [slideGroupModalOpen, setSlideGroupModalOpen] = useState(false);

  const slideData = getSlideAttributesFromSlide({ slide });

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="calc(100% - 60px)"
        paddingX="1rem"
      >
        <ConditionalComponent
          condition={currentTabValue === SLIDE_METADATA_TAB_VALUE}
        >
          <Box className={classes.slideInfoTabContent}>
            {slideData.map((item) => (
              <PathologyMapSlideCardInfo
                key={`${item.attribute}-${item.value}`}
                title={item.label}
                content={item.value}
                slide={slide}
                attribute={item.attribute}
                selectedFilters={[]}
              />
            ))}
            <EditSampleButton sampleId={slide.sample_id} />
          </Box>
        </ConditionalComponent>
        <ConditionalComponent condition={currentTabValue === GROUP_TAB_VALUE}>
          <SlideGroupsVerticalContainer
            selectedSlides={[slide]}
            customClasses={classes}
          />
          <Button
            variant={"contained"}
            onClick={() => setSlideGroupModalOpen(true)}
            className={classes.createGroupButton}
          >
            Create Slide Group
          </Button>
        </ConditionalComponent>
        <ConditionalComponent condition={currentTabValue === ACTIONS_TAB_VALUE}>
          <Box padding={1}>
            <Actions currentSlide={slide} classes={classes} />
          </Box>
        </ConditionalComponent>
      </Box>
      <CreateSlideGroupModal
        open={slideGroupModalOpen}
        onClose={() => setSlideGroupModalOpen(false)}
      />
    </Fragment>
  );
};
