import {
  useAddSlidesToGroupMutation,
  useCreateSlideGroupMutation,
  useDeleteSlideFromGroupMutation,
  useDeleteSlideGroupMutation,
  useGetSlideGroupsQuery,
  useUpdateSlideGroupMutation,
} from "store/apis/slideGroupsApi";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useEffect, useState } from "react";
import { getTeams } from "services/resources/commonResources";
import { serializeNameAndUUID } from "utilities/api";
import { FETCH_TEAMS_ERROR_MESSAGE } from "constants/errorMessages";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";

export const useCreateSlideGroup = () => {
  const [createSlideGroupMutation, { isLoading }] =
    useCreateSlideGroupMutation();
  const { showSuccess, showError } = useSnackbar();

  const createSlideGroup = (slideGroupName, team) => {
    const slideGroupNameSerialized = slideGroupName.trim();
    const teamUUID = team.value;

    const postParams = {
      name: slideGroupNameSerialized,
      team_uuid: teamUUID,
    };

    return createSlideGroupMutation(postParams)
      .unwrap()
      .then(() =>
        showSuccess(`Slide Group ${slideGroupNameSerialized} created!`)
      )
      .catch((e) => {
        const errorMessage = e.response?.data;
        const errorMessageToDisplay = JSON.stringify(errorMessage);
        showError(errorMessageToDisplay);
      });
  };

  return {
    createSlideGroup,
    isLoading,
  };
};

export const useDeleteSlideGroup = () => {
  const [deleteSlideGroupMutation, { isLoading }] =
    useDeleteSlideGroupMutation();
  const { showSuccess, showError } = useSnackbar();

  const deleteSlideGroup = (slideGroup) => {
    return deleteSlideGroupMutation(slideGroup.uuid)
      .unwrap()
      .then(() => showSuccess("Slides group was successfully removed"))
      .catch(() => showError("Error occurred during removing slide group"));
  };

  return {
    deleteSlideGroup,
    isLoading,
  };
};

export const useDeleteSlidesFromSlideGroup = () => {
  const [deleteSlideFromGroup, { isLoading }] =
    useDeleteSlideFromGroupMutation();
  const { showSuccess, showError } = useSnackbar();

  const deleteSlidesFromSlideGroup = (slideGroup, slides) => {
    const postParams = Array.isArray(slides)
      ? {
          slide_uuids: slides.map((slide) => slide.uuid),
        }
      : {
          slide_uuid: slides.uuid,
        };

    deleteSlideFromGroup({ slideGroupUUID: slideGroup.uuid, postParams })
      .unwrap()
      .then(() =>
        showSuccess(
          `Slides were successfully removed from ${slideGroup.name} slide group`
        )
      )
      .catch(() =>
        showError("Error occurred during removing slides from group")
      );
  };

  return {
    deleteSlidesFromSlideGroup,
    isLoading,
  };
};

export const useAddSlideToGroup = () => {
  const { showSuccess, showError } = useSnackbar();
  const [addSlidesToGroup, { isLoading }] = useAddSlidesToGroupMutation();

  const addSlideToSlideGroup = (slideGroup, slides) => {
    const postParams = {
      slide_uuids: slides.map((slide) => slide.uuid),
    };
    addSlidesToGroup({ slideGroupUUID: slideGroup.uuid, postParams })
      .unwrap()
      .then(() =>
        showSuccess(
          `Slides were successfully added to ${slideGroup.name} slide group`
        )
      )
      .catch(() => showError("Error occurred during adding slides to group"));
  };

  return {
    addSlideToSlideGroup,
    isLoading,
  };
};

export const useRenameSlideGroup = () => {
  const { showSuccess, showError } = useSnackbar();
  const [updateSlideGroup] = useUpdateSlideGroupMutation();
  const { data: allSideGroups } = useGetSlideGroupsQuery();

  const renameSlideGroup = (slideGroup, newName) => {
    const isSlideGroupWithUpdatedNameExist = !!allSideGroups.find(
      (group) => group.name === newName
    );

    if (isSlideGroupWithUpdatedNameExist) {
      showError("Group with such name already exists!");
      return null;
    }

    const postParams = {
      ...slideGroup,
      name: newName,
    };

    return updateSlideGroup({ slideGroupUUID: slideGroup.uuid, postParams })
      .unwrap()
      .then(() => {
        showSuccess(`Group name was changed to ${newName}`);
      })
      .catch(() => showError("Error occurred during renaming slide group"));
  };

  return {
    renameSlideGroup,
  };
};

export const useGetTeams = (setValue) => {
  const unmountIgnore = useUnmountIgnore();

  const { showError } = useSnackbar();

  const [team, setTeam] = useState();
  const [selectableTeams, setSelectableTeams] = useState([]);

  useEffect(() => {
    getTeams()
      .then((response) => {
        const selectableTeams = response.data.map(serializeNameAndUUID);

        // this is used to default to a primary team when someone is making their order (most users will probably only have one team)
        const team = serializeNameAndUUID(response.data[0]);
        if (!unmountIgnore.current) {
          setTeam(team);
          setSelectableTeams(selectableTeams);
          setValue("selectedTeam", team);
        }
      })
      .catch(() => showError(FETCH_TEAMS_ERROR_MESSAGE));
  }, [unmountIgnore]);

  return {
    team,
    selectableTeams,
  };
};
