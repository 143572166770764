import {
  Message,
  BarChart,
  FormatListBulleted,
  Add,
  Grain,
  MoreVert,
  FormatShapes,
} from "@mui/icons-material";

export const META_TAB = "meta";
export const COMMENTS_TAB = "comments";
export const SLIDES_LIST_TAB = "slidesList";
export const GROUPS_TAB = "groups";
export const AI_TOOLS_TAB = "aiTools";
export const ANNOTATIONS_TAB = "extAnnotations";
export const ACTIONS_TAB = "actions";

export const actionTabs = {
  meta: "Metadata",
  comments: "Comments",
  slidesList: "Slides List",
  groups: "Groups",
  aiTools: "AI Tools",
  extAnnotations: "Annotations",
  actions: "Actions",
};

export const TABS_EXCLUDED_FOR_MULTISELECT = [actionTabs[ANNOTATIONS_TAB]];

export const actionTabsIcons = {
  meta: <BarChart />,
  comments: <Message />,
  slidesList: <FormatListBulleted />,
  groups: <Add />,
  aiTools: <Grain />,
  extAnnotations: <FormatShapes />,
  actions: <MoreVert />,
};

export const getSlideMeta = (slide, order) => [
  {
    label: "Name",
    value: slide.name,
    tooltip: slide.name,
  },
  {
    label: "Antibody",
    value: slide.antibody?.name,
  },
  {
    label: "Fixation Time",
    value: slide.sample?.fixation_time_hours,
  },
  {
    label: "Fixative",
    value: order?.fixative?.name,
  },
  {
    label: "Height",
    value: slide.height,
  },
  {
    label: "Order",
    value: slide.order?.name,
  },
  {
    label: "Organ",
    value: slide.sample?.organ?.name,
  },
  {
    label: "Sample",
    value: slide.sample?.name,
  },
  {
    label: "Species",
    value: slide.sample?.species?.name,
  },
  {
    label: "Stain Type",
    value: slide.stain_type,
  },
  /*{  delete or uncomment after Chris's answer
    label: "Submission Type",
    value: slide.submission_type,
  },
  {
    label: "Age (Months)",
    value: slide.age,
  },
  {
    label: "Species Strain / Background Name",
    value: slide.organ,
  },*/
];

export const FETCH_SAMPLE_ERROR_MESSAGE = "Failed to fetch sample data";
