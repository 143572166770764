import * as Yup from "yup";
import { AddressInputWithAutofilling } from "components/Shared/TeamAddress/AddressInputWithAutofilling";

export const DEFAULT_ADRESS_VALUES = {
  name: "",
  street_1: "",
  street_2: "",
  zip_code: "",
  city: "",
  state: "",
  phone_number: "",
  country: "USA",
};

export const addressesDefFieldsOptions = [
  {
    label: "Company Name",
    name: "name",
  },
  {
    label: "Street 1",
    name: "street_1",
    component: AddressInputWithAutofilling,
  },
  {
    label: "Street 2",
    name: "street_2",
  },
  {
    label: "Zip Code",
    name: "zip_code",
  },
  {
    label: "City",
    name: "city",
  },
  {
    label: "State",
    name: "state",
  },
  {
    label: "Phone Number",
    name: "phone_number",
  },
  {
    label: "Country (US Only)",
    name: "country",
  },
];

export const addressesValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  street_1: Yup.string()
    .trim()
    .max(100, "Must be at most 100 characters")
    .required("Required"),
  zip_code: Yup.string()
    .required("Required")
    .matches(
      /^[a-zA-Z0-9]{5,6}$/,
      "Please enter 5 numerical digit for USA or 6 alpha-numerical for CANADA"
    ),
  city: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
});

export const SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE =
  "Address successfully updated!";
export const FAIL_TEAM_ADDRESS_UPDATE_MESSAGE =
  "Address update failed. Please try again.";

export const DEFAULT_SUBMIT_BUTTON_TEXT = "Submit";
