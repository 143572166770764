import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { MultiSlidesComments } from "components/ImageViewer/components/MultiSLidesComments";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  isExpandedSlideListSelector,
  isSharedPageSelector,
  projectUUIDSelector,
  setExpandedSlideList,
} from "store/slices/slidesListSlice/slidesListSlice";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { useHistory, useRouteMatch } from "react-router";

import {
  agGridDefaultColDefFloatingFilter,
  agGridRowSelection,
  getRowId,
  selectFirstRow,
  selectGridRow,
} from "components/utilities/grid";
import { SLIDE_VIEWER_URL } from "constants/urls";
import {
  getMultiContextMenuItems,
  useGetSingleContextMenuItems,
} from "components/OrderSlidesList/AgGridContext";
import {
  getSlideAdminColumnDefs,
  getSlideColumnDefs,
  GroupOrdersColumnDefs,
} from "components/utilities/AgGridCols/AgGridColumns";
import { isMobile } from "react-device-detect";
import {
  AG_GRID_TEXT_COLUMN_FILTER,
  ORDER_SLIDES_AG_GRID_COMPONENTS,
  SLIDES_LIST_CONTAINER_STYLES,
} from "components/OrderSlidesList/constants";
import { AgGridReact } from "ag-grid-react";
import { NoRowsMessage } from "components/SlidesList/constants";
import {
  getExportParams,
  overlayLoadingTemplate,
} from "components/OrderSlidesList/BaseOrderSlidesListView";
import {
  parseSlidesCoordinatesFromUrl,
  withResetFilters,
} from "components/OrderSlidesList/utilities";
import { AddAndRemoveSelectedSlidesFromSlideGroupsModal } from "components/SlideGroups/Modals/AddAndRemoveSelectedSlidesFromSlideGroupsModal";
import { DeleteModal } from "components/Modals/DeleteModal";
import { useDeleteSlidesFromSlideGroup } from "components/SlideGroups/utilities/hooks";
import { modalWindowMode } from "components/SlideGroups/utilities/constants";
import { ExportHaloAnnotationsConfirmationModal } from "components/OrderSlidesList/components/ExportHaloAnnotationsConfirmationModal";
import { useLazyGetSlideAnnotationsQuery } from "store/apis/annotationsApi";

export const SlidesGridOptions = ({
  classes,
  displayedRowCount,
  slides,
  handleExportToExcel,
}) => {
  const isExpanded = useSelector(isExpandedSlideListSelector);
  const dispatch = useDispatch();

  return (
    <Box className={classes.slideListOptions} mt={1}>
      <Box className={classes.searchWrapper} p={1}>
        <Typography align="left" className={classes.slideListHeader}>
          Viewing <span>{displayedRowCount}</span> of{" "}
          <span>{slides.length}</span> slides in list
        </Typography>
        <Box className={classes.buttonsWrapper}>
          <Button
            onClick={handleExportToExcel}
            variant="contained"
            color="primary"
            className={classes.exportButton}
          >
            Export to Excel
          </Button>
          <Button
            onClick={() => dispatch(setExpandedSlideList(!isExpanded))}
            variant="outlined"
            className={classes.expandButton}
          >
            {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const SlidesTable = ({
  classes,
  isSlidesLoading,
  slides,
  onGridReady,
  onRowSelected,
  selectedSlides,
  updateSelectedRows,
  groupOrders,
  gridApi,
}) => {
  const { isStaff, isBetaFeaturesEnabled } = useSelector(userDetailsSelector);
  const { deleteSlidesFromSlideGroup } = useDeleteSlidesFromSlideGroup();
  const isShared = useSelector(isSharedPageSelector);
  const match = useRouteMatch();
  const isExpanded = useSelector(isExpandedSlideListSelector);
  const projectUUID = useSelector(projectUUIDSelector);
  const { slideGroupUUID } = match.params;

  const [columns, setColumns] = useState([]);

  const history = useHistory();
  const [quickFilterText, setQuickFilterText] = useState();
  const [
    isExportHaloAnnotationsModalOpen,
    setIsExportHaloAnnotationsModalOpen,
  ] = useState(false);
  const [
    isAddSlideToGroupModalWindowOpen,
    setIsAddSlideToGroupModalWindowOpen,
  ] = useState(false);
  const [
    isDeleteSlideFromGroupModalWindowOpen,
    setIsDeleteSlideFromGroupModalWindowOpen,
  ] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [chosenSlide, setChosenSlide] = useState(null);

  const getMenuContext = useGetSingleContextMenuItems();

  const handleFirstDataRendered = ({ api }) => {
    const parsedSlidesCoordinates = parseSlidesCoordinatesFromUrl();
    if (parsedSlidesCoordinates.length) {
      parsedSlidesCoordinates.forEach(({ slideId }) => {
        selectGridRow(api, ({ id }) => id === slideId);
      });
    } else {
      selectFirstRow(api, groupOrders);
    }
  };

  const navigateToSlideViewer = (params) => {
    const { data } = params.node;
    // if you select a group, you aren't able to navigate to the slide viewer
    if (data && data.uuid) {
      const url = SLIDE_VIEWER_URL.replace(":slideUUID", data.uuid);
      history.push(url);
    }
  };

  const handleAddSlideToGroupClick = (row) => {
    setIsAddSlideToGroupModalWindowOpen(true);
    setChosenSlide(row);
  };

  const handleRemoveSlideFromGroupClick = (row) => {
    slideGroupUUID
      ? setIsConfirmationModalVisible(true)
      : setIsDeleteSlideFromGroupModalWindowOpen(true);
    setChosenSlide(row);
  };

  const getContextMenuItems = (params) => {
    // if there is no data, dont show any context, otherwise errors
    if (!params.node?.data) {
      return [];
    }

    const row = params.node.data;

    // i dont understand why gridAPI gets reset to null and where that happens
    // i sort of suspect this is because the call is being made from deep ag-grid
    // that something funky is happening w/closures
    // gridApi === is null ...
    const rowsSelected = params.api.getSelectedRows();

    if (rowsSelected.length > 1) {
      return getMultiContextMenuItems({
        rows: rowsSelected,
        isStaff,
        setIsExportHaloAnnotationsModalOpen,
      });
    } else {
      return getMenuContext({
        row,
        isStaff,
        isBetaFeaturesEnabled,
        history,
        handleAddSlideToGroupClick,
        setIsExportHaloAnnotationsModalOpen,
        handleRemoveSlideFromGroupClick,
      });
    }
  };

  useEffect(() => {
    const getGridColumns = isStaff
      ? getSlideAdminColumnDefs
      : getSlideColumnDefs;
    const columnsToSet = getGridColumns();

    if (groupOrders) {
      columnsToSet.unshift(...GroupOrdersColumnDefs);
    }

    setColumns(withResetFilters(columnsToSet, [AG_GRID_TEXT_COLUMN_FILTER]));
  }, [isStaff]);

  const handleRowDataUpdated = () => {
    // once data updated by "inner" update it's needed to sync state
    updateSelectedRows();
  };

  const handleExportToExcel = () => {
    if (!isSlidesLoading) {
      const firstRow = slides[0];
      const exportName = !!projectUUID
        ? firstRow.project.name
        : firstRow.order.name;
      const exportParams = getExportParams(exportName);
      gridApi.exportDataAsExcel(exportParams);
    }
  };

  const removeSlideFromSlideGroup = () => {
    deleteSlidesFromSlideGroup({ uuid: slideGroupUUID }, chosenSlide);
  };

  return (
    <Box className={classes.slideTableWrapper} minHeight={300}>
      <SlidesGridOptions
        classes={classes}
        handleExportToExcel={handleExportToExcel}
        displayedRowCount={selectedSlides?.length || 0}
        slides={slides}
        setQuickFilterText={setQuickFilterText}
      />
      {isMobile ? <br /> : null}
      {!isSlidesLoading && (
        <div className={classes.slideListStyle} height="100%">
          <div
            id="orderSlidesListGrid"
            style={SLIDES_LIST_CONTAINER_STYLES}
            className="ag-theme-balham"
          >
            {columns.length && (
              <AgGridReact
                enableSortingz
                enableFilter
                rowDragManaged
                quickFilterText={quickFilterText}
                enableColResize
                suppressRowClickSelection={groupOrders}
                groupDefaultExpanded={1}
                groupDisplayType="groupRows"
                rowGroupPanelShow={"always"}
                columnDefs={columns}
                rowData={slides}
                getRowId={getRowId}
                defaultColDef={agGridDefaultColDefFloatingFilter}
                rowSelection={agGridRowSelection}
                components={ORDER_SLIDES_AG_GRID_COMPONENTS}
                onGridReady={onGridReady}
                onRowDataUpdated={handleRowDataUpdated}
                onFirstDataRendered={handleFirstDataRendered}
                onCellDoubleClicked={navigateToSlideViewer}
                onRowSelected={onRowSelected}
                getContextMenuItems={getContextMenuItems}
                overlayNoRowsTemplate={NoRowsMessage}
                overlayLoadingTemplate={overlayLoadingTemplate}
              />
            )}
          </div>
        </div>
      )}
      {isExpanded && (
        <MultiSlidesComments slides={selectedSlides} isSharedPage={isShared} />
      )}
      <ExportHaloAnnotationsConfirmationModal
        slides={selectedSlides}
        open={isExportHaloAnnotationsModalOpen}
        onClose={() => {
          setIsExportHaloAnnotationsModalOpen(false);
        }}
      />
      <AddAndRemoveSelectedSlidesFromSlideGroupsModal
        open={isAddSlideToGroupModalWindowOpen}
        onClose={() => {
          setIsAddSlideToGroupModalWindowOpen(false);
          setChosenSlide(null);
        }}
        selectedSlides={[chosenSlide]}
      />
      <AddAndRemoveSelectedSlidesFromSlideGroupsModal
        open={isDeleteSlideFromGroupModalWindowOpen}
        onClose={() => {
          setIsDeleteSlideFromGroupModalWindowOpen(false);
          setChosenSlide(null);
        }}
        selectedSlides={[chosenSlide]}
        mode={modalWindowMode.REMOVE}
      />
      <DeleteModal
        open={isConfirmationModalVisible}
        label={`Are you ure you want to delete ${
          chosenSlide ? chosenSlide.name : "slide"
        } from this group?`}
        onClose={() => setIsConfirmationModalVisible(false)}
        handleDeleteResult={removeSlideFromSlideGroup}
      />
    </Box>
  );
};
