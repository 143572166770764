import React, { Fragment, memo } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OrderHistory } from "components/OrderDetails/Tabs/OrderProgressTab/OrderHistory";
import { FulfillmentTimeExplained } from "components/OrderDetails/Tabs/OrderProgressTab/FulfillmentTimeExplained";
import { OtherOrders } from "components/OrderDetails/Tabs/OrderProgressTab/OtherOrders";
import { OrderDetails } from "components/OrderDetails/Tabs/OrderProgressTab/OrderDetails";
import {
  ORDER_CANCELLED_TRACKER_STATE,
  ORDER_NEW_TRACKER_STATE,
} from "components/OrdersList/constants";
import { OrderCancelled } from "components/OrderDetails/Tabs/OrderProgressTab/OrderCanelled";
import { OrderTracker } from "components/OrderDetails/Tabs/OrderProgressTab/OrderTracker";
import { useOrderHistory } from "components/OrderDetails/Tabs/OrderProgressTab/hooks";
import { useOrderProgressStyles } from "components/OrderDetails/Tabs/OrderProgressTab/styles";
import { CONTACT_US_TEXT } from "components/OrderDetails/Tabs/OrderProgressTab/constants";

export const OrderProgress = memo(({ order, samples }) => {
  const { classes } = useOrderProgressStyles();

  const { orderHistory } = useOrderHistory(order);

  const isOrderCancelled =
    order.tracker_state === ORDER_CANCELLED_TRACKER_STATE;
  const isOrderNew = order.tracker_state === ORDER_NEW_TRACKER_STATE;

  return (
    <Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        flexDirection={"column"}
        position={"relative"}
      >
        {isOrderCancelled && <OrderCancelled />}
        <Box display={"flex"} flexDirection={"row"}>
          {!isOrderNew ? (
            <OrderTracker
              order={order}
              orderHistory={orderHistory}
              samples={samples}
            />
          ) : null}
          <OrderDetails order={order} samples={samples} />
        </Box>
        <OrderHistory history={orderHistory} />
        <FulfillmentTimeExplained />
        <OtherOrders currentOrder={order} />
        <Typography className={classes.contactUs}>{CONTACT_US_TEXT}</Typography>
      </Grid>
    </Fragment>
  );
});
