import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { editedInputStyle } from "components/OrderFormV2/SlideServicesFormDeprecated/SlideServicesViewStyles";
import { KEYS } from "constants/keyboard";

export const CountEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const refInput = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value || 0;
      },
    };
  });
  const onChangeListener = useCallback(
    (event) => setValue(+event.target.value),
    []
  );

  useEffect(() => refInput.current.focus(), []);

  return (
    <input
      onKeyPress={(event) => {
        if (event.key === KEYS.ENTER) {
          refInput.current.focus();
        }
      }}
      style={editedInputStyle}
      value={value}
      onChange={onChangeListener}
      ref={refInput}
      type="number"
      min="0"
    />
  );
});
