import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import _ from "lodash";

import {
  buildSamplesState,
  getScienceInfoColumn,
} from "components/SampleEditor/helpers/functions";
import { getOrgans, getSpecies } from "services/resources/commonResources";
import { useGetSamplesPaginatedQuery } from "store/apis/samplesApi";
import { useDebouncedValue } from "utilities/hooks/useDebouncedValue";
import { DEFAULT_DELAY } from "utilities/hooks/useDebouncedValue/constants";
import {
  ORDERING_PARAM,
  PAGE_PARAM,
  SAMPLE_FIELDS,
  SEARCH_PARAM,
} from "./constants";
import { useGetTeamPreferencesQuery } from "store/apis/teamsApi";

export const useGetUrlParam = (param, defaultValue) => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  return params.get(param) || defaultValue || "";
};

export const useGetSampleColumns = (baseColumns, gridApi, isLoadingTools) => {
  const [fullColumns, setFullColumns] = useState([]);
  const { data, isLoading } = useGetTeamPreferencesQuery();

  useEffect(() => {
    const scienceColumnsInitial =
      data?.preferences?.visible_samples_editor_columns ?? [];

    const sampleScienceColumns = scienceColumnsInitial?.map((column) =>
      getScienceInfoColumn(column, gridApi, setFullColumns)
    );

    setFullColumns([...baseColumns, ...sampleScienceColumns]);
    //isLoadingTools - to have updated baseColumns with data for selects
  }, [data, isLoadingTools]);

  return { fullColumns, setFullColumns, isLoading };
};

export const useSamplesForEditing = () => {
  const history = useHistory();
  const currentPage = useGetUrlParam(PAGE_PARAM, 1);
  const search = useGetUrlParam(SEARCH_PARAM);
  const sortBy = useGetUrlParam(ORDERING_PARAM);

  const debouncedSearch = useDebouncedValue(search, DEFAULT_DELAY);

  const { data, isLoading, isFetching, refetch, error } =
    useGetSamplesPaginatedQuery({
      page: +currentPage,
      sortBy,
      search: debouncedSearch,
    });

  useEffect(() => {
    if (error?.status === 404) {
      let params = new URLSearchParams(history.location.search);
      params.set(PAGE_PARAM, 1);

      history.replace({ search: params.toString() });
    }
  }, [error]);

  const [items, setItems] = useState([]);
  const [initialSamples, setInitialSamples] = useState([]);

  useEffect(() => {
    setItems(buildSamplesState(data?.results ?? []));
    setInitialSamples(buildSamplesState(data?.results ?? []));
  }, [data]);

  const totalPages = Math.ceil(data?.count / 50);

  return {
    items,
    isLoading: isLoading || isFetching,
    setSamples: setItems,
    initialSamples,
    totalPages,
    refetchSamples: refetch,
  };
};

export const useSamplesTools = () => {
  const [species, setSpecies] = useState([]);
  const [organs, setOrgans] = useState([]);

  const [isLoadingTools, setIsLoadingTools] = useState(true);

  useEffect(() => {
    Promise.all([getSpecies(), getOrgans()])
      .then(([species, organs]) => {
        setSpecies(species);
        setOrgans(organs);
      })
      .finally(() => {
        setIsLoadingTools(false);
      });
  }, []);

  return { species, organs, isLoadingTools };
};

//initial samples are necessary to call rebuild for collapsed lists
export const useCollapse = (items, initialSamples) => {
  const [collapsedOrdersList, setCollapsedOrdersList] = useState([]);
  const [collapsedProjectsList, setCollapsedProjectsList] = useState([]);

  const [filteredSamplesList, setFilteredSamplesList] = useState([]);

  const buildCollapseStateHandler = () => {
    const groupedByProjectId = _.groupBy(items, "project_uuid");
    const groupedByOrderId = _.groupBy(items, "order_id");

    const projectsList = items
      .map((item) => {
        return item.isFirstInProject
          ? { id: item.project_uuid, collapse: false }
          : null;
      })

      .filter((item) => {
        return item;
      })
      .filter((project) => {
        //remove projects from toggle list which have only one order
        const groupedOrdersByProject = Object.keys(
          _.groupBy(groupedByProjectId[project?.id], "order_id")
        );
        return groupedOrdersByProject.length > 1;
      });

    setCollapsedProjectsList(projectsList);

    const orderList = items
      .map((item) => {
        return item.isFirstInOrder
          ? { id: item.order_id, collapse: false }
          : null;
      })
      .filter((item) => item)
      .filter((order) => {
        //remove orders from toggle list which have only one order
        const currentOrder = groupedByOrderId[order.id];
        return currentOrder.length > 1;
      });

    setCollapsedOrdersList(orderList);
  };

  useEffect(() => {
    buildCollapseStateHandler();
  }, [initialSamples]);

  useEffect(() => {
    const projectsList = items.filter((sample) => {
      const currentFirstProject = collapsedProjectsList.find(
        (project) => project.id === sample.project_uuid
      );

      return currentFirstProject?.collapse ? sample.isFirstInProject : sample;
    });

    const filteredSamples = projectsList.filter((sample) => {
      const currentFirstOrder = collapsedOrdersList.find(
        (order) => order.id === sample.order_id
      );

      return currentFirstOrder?.collapse ? sample.isFirstInOrder : sample;
    });

    setFilteredSamplesList(filteredSamples);
  }, [collapsedOrdersList, collapsedProjectsList]);

  return {
    filteredSamplesList,
    collapsedOrdersList,
    collapsedProjectsList,
    setCollapsedOrdersList,
    setCollapsedProjectsList,
    setFilteredSamplesList,
    buildCollapseStateHandler,
  };
};

export const useDataForModal = (allSamples, samplesToUpdate) => {
  const formatObject = (obj) => {
    return {
      uuid: obj.uuid,
      [SAMPLE_FIELDS.teamSampleName]: obj.alias,
      fullSampleName: `${obj.project_name}/${obj.order_id}/${obj.display_name}`,
      ...obj.science_info,
      organ: obj.organ?.name || "",
      species: obj.species?.name || "",
    };
  };

  const compareObjects = (left, right) => {
    return Object.entries(left).reduce((acc, [key, value]) => {
      if (value !== right[key]) {
        acc = {
          ...acc,
          uuid: left.uuid,
          fullSampleName: left.fullSampleName,
          [key]: value,
        };
      }
      return acc;
    }, {});
  };

  const dataForChanges = () => {
    const changedInitialSamplesList = allSamples.filter((initialSample) =>
      samplesToUpdate.some(
        (updatedSample) => updatedSample.uuid === initialSample.uuid
      )
    );

    const flatternInitialObjects = changedInitialSamplesList
      .map((item) => formatObject(item))
      .reduce((acc, curr) => {
        acc[curr.uuid] = curr;
        return acc;
      }, {});

    const flatternUpdatedObjects = samplesToUpdate
      .map((item) => formatObject(item))
      .reduce((acc, curr) => {
        acc[curr.uuid] = curr;
        return acc;
      }, {});

    const groupedBySampleUUId = Object.entries(flatternInitialObjects).reduce(
      (acc, [key, value]) => {
        acc[key] = {
          new: compareObjects(flatternUpdatedObjects[key], value),
          old: compareObjects(value, flatternUpdatedObjects[key]),
        };
        return acc;
      },
      {}
    );

    return groupedBySampleUUId;
  };

  return {
    compareData: dataForChanges(),
  };
};
