import {
  ALL_ORDERS_TAB_VALUE,
  COMPLETED_ORDERS_TAB_VALUE,
  IN_PROGRESS_ORDERS_TAB_VALUE,
  UNSUBMITTED_ORDERS_TAB_VALUE,
} from "components/PathologyMap/constants/common";

export const PROJECT_NAME_SORT_FIELD = "name";
export const CREATED_AT_SORT_FIELD = "create";
export const SLIDES_COUNT_SORT_FIELD = "slides_count";
export const IF_SLIDES_COUNT_SORT_FIELD = "if_slides_count";

export const BYTES = "Bytes";
export const KB = "KB";
export const MB = "MB";
export const GB = "GB";
export const TB = "TB";
export const PB = "PB";
export const SIZES = [BYTES, KB, MB, GB, TB, PB];

export const SLIDE_COMMENT_TYPE = "slide_comment";
export const ORDER_COMMENT_TYPE = "order_comment";

export const ORDERS_CARD_TABS_LIST = [
  {
    value: ALL_ORDERS_TAB_VALUE,
    label: "all",
  },
  {
    value: COMPLETED_ORDERS_TAB_VALUE,
    label: "completed",
  },
  {
    value: IN_PROGRESS_ORDERS_TAB_VALUE,
    label: "in-progress",
  },
  {
    value: UNSUBMITTED_ORDERS_TAB_VALUE,
    label: "unsubmitted",
  },
];

export const IF = "IF";
export const IHC = "IHC";
export const SlideTypes = {
  IF,
  IHC,
};
