import { useEffect, useState } from "react";
import { FETCH_SPECIES_ERROR_MESSAGE } from "constants/errorMessages";
import { getSpecies } from "services/resources/commonResources";
import { serializeNameAndUUID } from "utilities/api";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useSerializedSpecies = () => {
  const unmountIgnore = useUnmountIgnore();
  const { showError } = useSnackbar();
  const [selectableSpecies, setSelectableSpecies] = useState([]);

  useEffect(() => {
    getSpecies()
      .then((response) => {
        const results = response.filter((specie) => specie.is_in_order_form);
        const selectableSpecies = results.map(serializeNameAndUUID);

        if (!unmountIgnore.current) {
          setSelectableSpecies(selectableSpecies);
        }
      })
      .catch(() => showError(FETCH_SPECIES_ERROR_MESSAGE));
  }, [unmountIgnore]);

  return {
    selectableSpecies,
  };
};
