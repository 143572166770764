import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  FileUploadRounded,
} from "@mui/icons-material";

import { DeleteModal } from "components/Modals/DeleteModal";
import {
  EXTERNAL_ANNOTATIONS_SWITCH_FIELD,
  GEOJSON_FILE_EXTENSION,
  HALO_ANNOTATIONS_FILE_EXTENSION,
  IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION,
} from "components/ImageViewer/constants";
import { AnnotationsLegend } from "components/OrderSlidesList/ExtAnnotations/AnnotationsLegend";
import {
  handleDeleteAnnotationsAttachment,
  handleUploadAnnotationsAttachment,
} from "components/ImageViewer/SlideViewerHeader/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  currentUserPreferencesSelector,
  updatePreferences,
} from "store/slices/userDetailsSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import Dropzone from "react-dropzone";
import { useSlideViewerActionsStyles } from "components/ImageViewer/SlideViewerHeader/styles";
import { ExportHaloAnnotationsConfirmationModal } from "components/OrderSlidesList/components/ExportHaloAnnotationsConfirmationModal";
import { annotationsSelector } from "store/slices/annotationsSlice";

export const SlideViewerExtAnnotations = ({
  currentSlide,
  annotationsLegend,
}) => {
  const { classes } = useSlideViewerActionsStyles();
  const [
    isExportHaloAnnotationsModalOpen,
    setIsExportHaloAnnotationsModalOpen,
  ] = useState(false);
  const annotations = useSelector(annotationsSelector);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAnnotationsVisible, setIsAnnotationsVisible] = useState(true);
  const preferences = useSelector(currentUserPreferencesSelector);
  const dispatch = useDispatch();
  const { showError, showSuccess } = useSnackbar();
  const isAnnotationAvailable = !!currentSlide?.halo_annotation_url;

  const onUpload = (files) => {
    handleUploadAnnotationsAttachment(
      files[0],
      currentSlide,
      dispatch,
      preferences,
      showError,
      showSuccess
    );
  };

  const handleToggleAnnotations = (value) => {
    const updatedPreferences = {
      ...preferences,
      [EXTERNAL_ANNOTATIONS_SWITCH_FIELD]: value,
    };
    dispatch(updatePreferences(updatedPreferences));
    setIsAnnotationsVisible(value);
  };

  const onDelete = () => {
    handleDeleteAnnotationsAttachment(
      currentSlide,
      preferences,
      dispatch,
      setIsConfirmModalOpen,
      showError,
      showSuccess
    );
  };

  const AnnotationsToggleIcon = isAnnotationsVisible
    ? Visibility
    : VisibilityOff;

  const onDeleteClick = (e) => {
    e.stopPropagation();
    setIsConfirmModalOpen(true);
  };

  const isAnnotationsExist = !!annotations.find(
    (annotation) => annotation.slideUUID === currentSlide.uuid
  )?.data.length;

  return (
    <>
      <Box className={classes.extAnnotationsActions}>
        <Box className={classes.attachment}>
          <Typography fontWeight="bold" className={classes.label}>
            ANNOTATIONS ATTACHMENT
          </Typography>
          {isAnnotationAvailable && (
            <a className={classes.deleteButton} onClick={onDeleteClick}>
              remove
            </a>
          )}
        </Box>
        {isAnnotationAvailable && (
          <Box className={classes.visibilityToggle}>
            <Typography className={classes.label}>ANNOTATIONS</Typography>
            <AnnotationsToggleIcon
              onClick={() => handleToggleAnnotations(!isAnnotationsVisible)}
            />
          </Box>
        )}
      </Box>
      {!isAnnotationAvailable && (
        <Dropzone
          onDrop={onUpload}
          accept={[
            GEOJSON_FILE_EXTENSION,
            HALO_ANNOTATIONS_FILE_EXTENSION,
            IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION,
          ]}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <FileUploadRounded />
              <Typography fontWeight={500}>
                Click to upload or Drag and Drop
              </Typography>
              <Typography>
                Supported Formats: .geojson, .annotations, .xml
              </Typography>
            </div>
          )}
        </Dropzone>
      )}
      <Box marginTop={2}>
        <Button
          onClick={() => setIsExportHaloAnnotationsModalOpen(true)}
          variant="contained"
          color="primary"
          fullWidth={true}
          disabled={!isAnnotationsExist}
        >
          Export annotations
        </Button>
      </Box>
      {isAnnotationAvailable && annotationsLegend && (
        <AnnotationsLegend annotationsLegend={annotationsLegend} />
      )}
      <DeleteModal
        label="Are you sure want to delete this annotations file?"
        open={!!isConfirmModalOpen}
        handleDeleteResult={onDelete}
        onClose={() => setIsConfirmModalOpen(false)}
      />
      <ExportHaloAnnotationsConfirmationModal
        slides={[currentSlide]}
        open={isExportHaloAnnotationsModalOpen}
        onClose={() => {
          setIsExportHaloAnnotationsModalOpen(false);
        }}
      />
    </>
  );
};
