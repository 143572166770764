import { makeStyles } from "tss-react/mui";
import { ACTION_LINK_COLOR, WHITE_SMOKE } from "utilities/colors";

export const useOrderTablesStyles = makeStyles()((theme) => ({
  progressLink: {
    fontSize: "0.7em",
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: WHITE_SMOKE,
    },
  },
  rowCell: {
    fontSize: "14px",
  },
  andLabel: {
    marginInline: "0.25rem",
    marginTop: "0.125rem",
  },
  slidesCount: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  actionsButton: {
    cursor: "pointer",
  },
  actionIcon: {
    height: "1.25rem",
    width: "1.25rem",
    marginRight: "0.5rem",
    color: ACTION_LINK_COLOR,
    cursor: "pointer",
  },
  projectCell: {
    overflow: "hidden",
    fontSize: "inherit",
  },
}));
