import { useState } from "react";
import { FETCH_SLIDE_ERROR_MESSAGE } from "constants/errorMessages";
import Grid from "@mui/material/Grid";
import { getSlideAttributesFromSlide } from "components/PathologyMap/utilities/common";
import { PathologyMapSlideCardInfo } from "components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardInfo";
import { FullSlideInfoModal } from "../Modals/FullSlideInfoModal/FullSlideInfoModal";
import { usePathologyMapSlideCardContentStyles } from "./styles";
import { useLazyGetSlideQuery } from "store/apis/slidesApi";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const PathologyMapSlideCardContent = ({ slide, selectedFilters }) => {
  const slideData = getSlideAttributesFromSlide({ slide });
  const { classes } = usePathologyMapSlideCardContentStyles();

  const [viewSlideModalOpen, setViewSlideModalOpen] = useState(false);

  const [getSlide, { data: fullSlide }] = useLazyGetSlideQuery();
  const { showError } = useSnackbar();

  const handleCloseModal = () => {
    setViewSlideModalOpen(false);
  };

  const handleSlideImageClick = () => {
    if (!slide.uuid) {
      return;
    }

    getSlide({ slideUUID: slide.uuid })
      .unwrap()
      .then(() => {
        setViewSlideModalOpen(true);
      })
      .catch(() => showError(FETCH_SLIDE_ERROR_MESSAGE));
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={0.5}
        className={classes.contentWrapper}
      >
        <Grid
          item
          xs={6}
          className={classes.imageWrapper}
          onClick={handleSlideImageClick}
        >
          <img src={slide.large_thumbnail_url} alt="Slide" width="100%" />
        </Grid>
        <Grid item xs>
          {slideData.map((item) => (
            <PathologyMapSlideCardInfo
              key={`${item.attribute}-${item.value}`}
              title={item.label}
              content={item.value}
              slide={slide}
              attribute={item.attribute}
              selectedFilters={selectedFilters}
            />
          ))}
        </Grid>
      </Grid>
      {fullSlide && (
        <FullSlideInfoModal
          slide={slide}
          fullSlide={fullSlide}
          isOpen={viewSlideModalOpen}
          onClose={handleCloseModal}
          selectedFilters={selectedFilters}
        />
      )}
    </>
  );
};
