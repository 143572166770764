import { useSelector } from "react-redux";
import { currentUserEmailSelector } from "store/slices/userDetailsSlice";
import { useContext, useEffect } from "react";
import { AppContext } from "services/context";
import algoliasearch from "algoliasearch/lite";
import {
  ALGOLIA_PATHOLOGY_MAP_INDEX,
  ALGOLIA_STALLED_SEARCH_DELAY,
  MAX_FILTERS_COUNT,
  RESULTS_PER_PAGE_COUNT,
} from "components/PathologyMap/constants/common";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";

export const PathologyMapConfiguration = ({
  algoliaSearchApiKey,
  children,
}) => {
  const email = useSelector(currentUserEmailSelector);
  const { setAlgoliaClient, algoliaClient, isPathMapFilterEnabled } =
    useContext(AppContext);

  useEffect(() => {
    if (!algoliaSearchApiKey) {
      return;
    }

    const searchClient = algoliasearch("0R1MIPP8UY", algoliaSearchApiKey);
    setAlgoliaClient(searchClient);
  }, [algoliaSearchApiKey, setAlgoliaClient]);

  if (!algoliaClient) {
    return null;
  }

  const configureOptions = {
    userToken: email,
    hitsPerPage: RESULTS_PER_PAGE_COUNT,
    maxValuesPerFacet: MAX_FILTERS_COUNT,
  };

  if (isPathMapFilterEnabled) {
    configureOptions.filters = "access_groups:pathology_map";
  }

  return (
    <InstantSearch
      searchClient={algoliaClient}
      indexName={ALGOLIA_PATHOLOGY_MAP_INDEX}
      stalledSearchDelay={ALGOLIA_STALLED_SEARCH_DELAY}
      routing
    >
      <Configure {...configureOptions} />
      {children}
    </InstantSearch>
  );
};
