import { Box, Grid, Typography } from "@mui/material";
import { BlocksActionHeaderTab } from "components/BlocksList/BlocksActionPanel/BlocksActionHeaderTab";
import { ProcessingQueueTab } from "components/BlocksList/BlocksActionPanel/ProcessingQueueTab";
import {
  blockActions,
  SLIDES_SECTION_TITLE,
} from "components/BlocksList/constants";
import { ImageSection } from "components/BlocksList/ImageSection";
import { useBlocksPageV2Styles } from "components/BlocksList/styles";
import { SlideCard } from "components/SlideNavigator/SlideCard";

export const BlocksActionPanel = ({
  selectedSlides,
  processingQueue,
  setProcessingQueue,
  openBlocksModal,
  handleCreateOrder,
  activeTab,
  setActiveTab,
  selectedSamples,
  setSelectedSamples,
}) => {
  const { classes } = useBlocksPageV2Styles();
  const barcodeSectionTitle = `${selectedSlides[0]?.projectName} / ${selectedSlides[0]?.orderName} / ${selectedSlides[0]?.name}`;
  const tabs = Object.keys(blockActions);

  const getTabCounter = (tab) => {
    switch (tab) {
      case blockActions.imageData:
        return selectedSlides.length;
      case blockActions.processingQueue:
        return processingQueue.length;
      default:
        return 0;
    }
  };

  return (
    <Grid item xs={4} className={classes.imagesWrapper}>
      <Box className={classes.tabs}>
        {tabs.map((tab) => (
          <BlocksActionHeaderTab
            tab={tab}
            key={tab}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            getTabCounter={getTabCounter}
          />
        ))}
      </Box>
      {activeTab === blockActions.imageData && (
        <Box className={classes.tabContainer}>
          {selectedSlides.length ? (
            <>
              <ImageSection
                classes={classes}
                selectedSlides={selectedSlides}
                title={barcodeSectionTitle}
              />
              <ImageSection
                classes={classes}
                Content={SlideCard}
                title={SLIDES_SECTION_TITLE}
                selectedSlides={selectedSlides}
                slidesCount={selectedSlides.length}
              />
            </>
          ) : (
            <Typography className={classes.noData}>
              Click on any sample with slides
            </Typography>
          )}
        </Box>
      )}
      {activeTab === blockActions.processingQueue && (
        <ProcessingQueueTab
          processingQueue={processingQueue}
          setProcessingQueue={setProcessingQueue}
          openBlocksModal={openBlocksModal}
          handleCreateOrder={handleCreateOrder}
          selectedSamples={selectedSamples}
          setSelectedSamples={setSelectedSamples}
        />
      )}
    </Grid>
  );
};
