import React, { Fragment } from "react";
import DraftsIcon from "@mui/icons-material/Drafts";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import { MenuActionItem } from "components/ImageViewer/SlidesViewerHeader";
import { RequestPathologistModalLegacy } from "components/PathologyConsultations/OrderForm/RequestPathologistModalLegacy";
import PageView from "@mui/icons-material/Pageview";
import Box from "@mui/material/Box";
import { useSlideActions } from "components/PathologyMap/Modals/FullSlideInfoModal/hooks";

export const Actions = ({ currentSlide, classes }) => {
  const {
    findSimilarSlides,
    markSlideValidPosControl,
    handleDownloadSlide,
    handleOpenPathologistConsultationModal,
    viewSlide,
  } = useSlideActions(currentSlide);

  return (
    <Fragment>
      <Box className={classes.actionItem}>
        <MenuActionItem
          icon={<DraftsIcon fontSize="small" />}
          onClick={handleOpenPathologistConsultationModal}
          label="Pathologist Consultation"
        />
      </Box>
      <Box className={classes.actionItem}>
        <MenuActionItem
          icon={<DownloadIcon fontSize="small" />}
          onClick={handleDownloadSlide}
          label="Download Slide"
        />
      </Box>
      <ShowStaffUserOnly>
        <Box className={classes.actionItem}>
          <MenuActionItem
            icon={<SearchIcon fontSize="small" />}
            onClick={findSimilarSlides}
            label="Find Similar Slides"
          />
        </Box>
      </ShowStaffUserOnly>
      <ShowStaffUserOnly>
        <Box className={classes.actionItem}>
          <MenuActionItem
            icon={<CheckIcon fontSize="small" />}
            onClick={markSlideValidPosControl}
            label="Mark Slide As Valid Pos Control"
          />
        </Box>
      </ShowStaffUserOnly>
      <Box className={classes.actionItem}>
        <MenuActionItem
          icon={<PageView fontSize="small" />}
          onClick={viewSlide}
          label="View Slide"
        />
      </Box>
      <RequestPathologistModalLegacy slidesSelected={[currentSlide]} />
    </Fragment>
  );
};
