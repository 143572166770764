import React from "react";
import Star from "@mui/icons-material/Star";
import ViewList from "@mui/icons-material/ViewList";
import PageView from "@mui/icons-material/Pageview";
import Polyline from "@mui/icons-material/Polyline";
import LocationCity from "@mui/icons-material/LocationCity";
import CommentIcon from "@mui/icons-material/Comment";
import AutoAwesomeMotion from "@mui/icons-material/AutoAwesomeMotion";
import {
  ORDERS_LIST_URL,
  SLIDES_LIST_URL,
  SLIDE_NAVIGATOR_URL,
  IHC_ANTIBODIES_BASE_URL,
  DROPBOXES_LIST_URL,
  ORDER_SLIDE_COMMENTS_URL,
  PROJECTS_URL,
  SLIDE_GROUPS_LIST_URL,
  SLIDES_LIST_URL_2,
  SAMPLE_EDITOR_URL,
} from "constants/urls";
import { SidebarListItem } from "components/Layout/Sidebar/SidebarListItem";
import { SidebarList } from "components/Layout/Sidebar/SidebarList";
import { SLIDES_SEARCH_V2_NAME } from "components/PathologyMap/constants/common";
import {
  SlideGroupsIcon,
  UserSlidesSearchIcon,
} from "components/PathologyMap/Icons/UserSlidesSearchIcon";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import { SampleMenuIcon } from "components/SampleEditor/icons/SampleMenuIcon";

export const MainList = () => (
  <>
    <SidebarList>
      <SidebarListItem Icon={Star} name="Orders" path={ORDERS_LIST_URL} />
    </SidebarList>
    <SidebarList>
      <SidebarListItem
        Icon={AutoAwesomeMotion}
        name="Projects"
        path={PROJECTS_URL}
      />
      <SidebarListItem
        Icon={UserSlidesSearchIcon}
        name={SLIDES_SEARCH_V2_NAME}
        path={SLIDES_LIST_URL_2}
      />
      <SidebarListItem
        Icon={SlideGroupsIcon}
        name="Slide Groups"
        path={SLIDE_GROUPS_LIST_URL}
      />
      <SidebarListItem Icon={ViewList} name="Slides" path={SLIDES_LIST_URL} />
      <ShowStaffUserOnly>
        <SidebarListItem
          Icon={SampleMenuIcon}
          name="Sample Editor"
          path={SAMPLE_EDITOR_URL}
        />
      </ShowStaffUserOnly>

      <SidebarListItem
        Icon={PageView}
        name="Slide Navigator"
        path={SLIDE_NAVIGATOR_URL}
      />
    </SidebarList>
    <SidebarList>
      <SidebarListItem
        Icon={Polyline}
        name="Antibodies"
        path={IHC_ANTIBODIES_BASE_URL}
      />
      <SidebarListItem
        Icon={LocationCity}
        name="Dropboxes"
        path={DROPBOXES_LIST_URL}
      />
      <SidebarListItem
        Icon={CommentIcon}
        name="Slide Comments"
        path={ORDER_SLIDE_COMMENTS_URL}
      />
    </SidebarList>
  </>
);
