import { useHistory, useLocation } from "react-router";
import {
  ALGOLIA_PATHOLOGY_MAP_INDEX,
  PATHOLOGY_MAP_SEARCH_PAGE_URL_MAP,
} from "components/PathologyMap/constants/common";
import {
  useClearRefinements,
  useCurrentRefinements,
  useInstantSearch,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import { useContext, useMemo } from "react";
import {
  calculateSelectedFiltersSumCount,
  sortFacetsResults,
  toggleArrayElement,
} from "components/PathologyMap/utilities/common";
import { AppContext } from "services/context";
import { useDispatch, useSelector } from "react-redux";
import {
  currentRefinementTypeSelector,
  selectCurrentFacetSearchFilters,
  selectCurrentFiltersSearchQuery,
  setCurrentSearchQuery,
} from "store/slices/pathologyMapSlice";
import {
  FAVORITE_CATEGORIES_PREFERENCES_FIELD,
  SEARCH_PAGE_URL_REGEX_PATTERN,
} from "components/PathologyMap/utilities/constants";
import {
  currentUserPreferencesSelector,
  updatePreferences,
} from "store/slices/userDetailsSlice";
import { updateCurrentUserPreferences } from "services/resources/commonResources";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { UPDATE_USER_DETAILS_MESSAGE } from "constants/errorMessages";

export const useFavoriteCategories = () => {
  const preferences = useSelector(currentUserPreferencesSelector);
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const favoriteCategories =
    preferences?.favoriteCategories?.filter(
      (favCategory) => favCategory instanceof Object
    ) || [];

  const updateCurrentUserDetails = (favoriteCategories) => {
    dispatch(
      updatePreferences({
        [FAVORITE_CATEGORIES_PREFERENCES_FIELD]: favoriteCategories,
      })
    );

    const updatedPreferences = {
      ...preferences,
      [FAVORITE_CATEGORIES_PREFERENCES_FIELD]: favoriteCategories,
    };

    updateCurrentUserPreferences({ preferences: updatedPreferences }).catch(
      () => {
        showError(UPDATE_USER_DETAILS_MESSAGE);
      }
    );
  };

  const markAsFavorite = (refinementType) => {
    const favoriteCategoryEntity = {
      refinementType,
      createdAt: new Date().toISOString(),
    };
    const updatedFavCategories = [
      ...favoriteCategories,
      favoriteCategoryEntity,
    ];

    updateCurrentUserDetails(updatedFavCategories);
  };

  const markAsUnfavorite = (refinementType) => {
    const updatedFavCategories = favoriteCategories.filter(
      (favoriteCategory) => favoriteCategory.refinementType !== refinementType
    );

    updateCurrentUserDetails(updatedFavCategories);
  };

  return {
    favoriteCategories,
    markAsFavorite,
    markAsUnfavorite,
  };
};

export const useCurrentPathologyMapManager = () => {
  const { pathname } = useLocation();

  return PATHOLOGY_MAP_SEARCH_PAGE_URL_MAP[pathname];
};

export const useAlgoliaSourceFacet = (attribute) => {
  const facetsFromSearch = useSelector(selectCurrentFacetSearchFilters);
  const currentRefinement = useSelector(currentRefinementTypeSelector);
  const facetsSearchQuery = useSelector(selectCurrentFiltersSearchQuery);

  const {
    results: { disjunctiveFacets: facets },
  } = useInstantSearch();

  const requestedFacet = useMemo(() => {
    return facets.find(({ name }) => name === attribute)?.data || {};
  }, [facets, attribute]);

  const requestedFacetStats = useMemo(() => {
    const facetsStats = Object.entries(requestedFacet).map(([key, value]) => {
      return {
        value: key,
        count: value,
      };
    });
    return facetsStats;
  }, [requestedFacet, attribute]);

  const isFacetsFromSearch =
    currentRefinement === attribute && facetsSearchQuery;
  const facetsResults = isFacetsFromSearch
    ? facetsFromSearch
    : requestedFacetStats;

  const sortedFacetsResults = sortFacetsResults(facetsResults);

  return sortedFacetsResults;
};

export const useAlgoliaFilterRefine = () => {
  const { setIndexUiState } = useInstantSearch();

  const refine = useMemo(() => {
    return (attribute, value) => {
      setIndexUiState((prevIndexUiState) => {
        const prevAttributeValues =
          prevIndexUiState.refinementList?.[attribute] || [];

        const newState = {
          ...prevIndexUiState,
          refinementList: {
            ...prevIndexUiState.refinementList,
            [attribute]: toggleArrayElement(prevAttributeValues, value),
          },
        };

        return newState;
      });
    };
  }, [setIndexUiState]);

  return refine;
};

export const useCurrentRefinementsSumCount = () => {
  const { items } = useCurrentRefinements();

  const selectedFiltersSumCount = calculateSelectedFiltersSumCount(items);

  return selectedFiltersSumCount;
};

export const useAlgoliaIndex = () => {
  const { algoliaClient } = useContext(AppContext);

  const index = useMemo(
    () => algoliaClient?.initIndex(ALGOLIA_PATHOLOGY_MAP_INDEX),
    [algoliaClient]
  );
  return index;
};

export const useClearAllAlgoliaFilters = () => {
  const { refine } = useClearRefinements();
  const { clear } = useSearchBox();

  const dispatch = useDispatch();

  const clearAllFilters = useMemo(() => {
    return () => {
      dispatch(setCurrentSearchQuery(""));
      clear();
      refine();
    };
  }, [dispatch, clear, refine]);

  return clearAllFilters;
};

export const useIsSearchPage = () => {
  const history = useHistory();

  return SEARCH_PAGE_URL_REGEX_PATTERN.test(history.location.pathname);
};
