import { useEffect, useState } from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import FileCopy from "@mui/icons-material/FileCopyRounded";
import ShareIcon from "@mui/icons-material/Share";
import {
  useGetItemShareRequest,
  useShareClipboard,
  useShareError,
} from "components/Share/helpers/hooks";
import ShareSlideModal from "components/Modals/ShareSlideModal/ShareSlideModal";
import { useStyles } from "components/Share/styles";

export const Share = ({
  item,
  itemName,
  mutation,
  noMargin,
  shareId,
  copyId,
  areLayersLoading,
}) => {
  const { classes } = useStyles();
  const [isOpenShared, setIsOpenShared] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const isSlideSharing = itemName.includes("Slide");

  const handleCopy = useShareClipboard(item, itemName);

  const { share_enabled, uuid } = item;

  const [isAllowed, setIsAllowed] = useState(share_enabled);

  const shareFunction = useGetItemShareRequest(!isAllowed, uuid, itemName);

  const showError = useShareError(itemName);

  const openShareSlideModal = () => {
    if (areLayersLoading) {
      setIsOpenTooltip(true);
      return;
    }
    setIsOpenShared(true);
  };

  const closeShareSlideModal = () => {
    setIsOpenShared(false);
  };

  const handleShare = () => {
    if (areLayersLoading) {
      setIsOpenTooltip(true);
      return;
    }
    if (mutation) {
      mutation();
      return;
    }

    shareFunction()
      .then(() => {
        if (!isAllowed) {
          handleCopy();
        }

        setIsAllowed((prev) => !prev);
      })
      .catch(showError);
  };

  const copyLinkHandler = () => {
    if (areLayersLoading) {
      setIsOpenTooltip(true);
      return;
    }
    handleCopy();
  };

  useEffect(() => {
    setIsAllowed(share_enabled);
  }, [share_enabled]);

  return (
    <>
      <ShareSlideModal
        isOpen={isOpenShared}
        onClose={closeShareSlideModal}
        item={item}
      />

      <Tooltip
        title="You can't share while slide is loading"
        open={isOpenTooltip}
        onClose={() => setIsOpenTooltip(false)}
      >
        <ButtonGroup
          className={!noMargin ? classes.buttonGroup : ""}
          variant="contained"
          color={isAllowed ? "secondary" : "default"}
        >
          {isAllowed && (
            <Button
              id={copyId}
              onClick={copyLinkHandler}
              className={classes.shareButton}
            >
              <FileCopy className={classes.shareIcon} />
            </Button>
          )}
          {/* TODO: move all sharings to RTK mutations */}
          <Button id={shareId} onClick={handleShare}>
            Share {itemName}: {isAllowed ? "On" : "Off"}
          </Button>
          {isSlideSharing && isAllowed && (
            <Button id={copyId} onClick={openShareSlideModal}>
              <ShareIcon className={classes.shareIcon} />
            </Button>
          )}
        </ButtonGroup>
      </Tooltip>
    </>
  );
};
