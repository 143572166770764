import { Typography } from "@mui/material";
import { DEFAULT_TYPOGRAPHY_PROPS } from "components/PathologyMap/constants/styles";

export const DefaultPathologyMapTypography = ({ children, ...props }) => {
  return (
    <Typography {...DEFAULT_TYPOGRAPHY_PROPS} {...props}>
      {children}
    </Typography>
  );
};
