import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    float: "right",
  },
  typographyMarginBottom: { marginBottom: "1rem" },
  selectForm: {
    marginBottom: "0.5rem",
    marginTop: "1rem",
    marginRight: "2rem",
    width: "30vw",
  },
  useHistoWizFedExCheckbox: {
    marginRight: "1rem",
  },
}));
