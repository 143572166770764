import { baseCreateApi } from "store/apis/baseCreateApi";
import { defaultActionAsyncHandler } from "store/utilities";
import {
  setSlideComments,
  createSlideComment,
  deleteSlideComment,
} from "store/slices/slideCommentsSlice";
import { SHARED_SLIDES_COMMENTS_RESOURCE_NAME } from "services/resources/apiConstants";

export const slideCommentsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getSlideComments: builder.query({
      query: ({ slideUUID }) => ({
        url: `slides/${slideUUID}/comments/`,
        method: "GET",
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          setSlideComments
        );
      },
    }),
    createSlideComment: builder.mutation({
      query: ({ slideUUID, text, isInternal }) => ({
        url: `slides/${slideUUID}/comments/`,
        method: "POST",
        body: {
          text,
          is_internal: isInternal,
        },
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          createSlideComment
        );
      },
    }),
    deleteSlideComment: builder.mutation({
      query: ({ slideUUID, commentUUID }) => ({
        url: `slides/${slideUUID}/comments/${commentUUID}/`,
        method: "DELETE",
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled, queryArgs },
          deleteSlideComment
        );
      },
    }),
    addSharedSlideComment: builder.mutation({
      query: ({ shareUUID, text, isInternal }) => {
        const url = SHARED_SLIDES_COMMENTS_RESOURCE_NAME.replace(
          ":shareUUID",
          shareUUID
        );
        const postParams = {
          text: text,
          is_internal: isInternal,
        };
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
    }),
    getSharedSlidesComments: builder.query({
      query: ({ shareUUID }) => {
        const url = SHARED_SLIDES_COMMENTS_RESOURCE_NAME.replace(
          ":shareUUID",
          shareUUID
        );
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetSlideCommentsQuery,
  useLazyGetSlideCommentsQuery,
  useCreateSlideCommentMutation,
  useDeleteSlideCommentMutation,
  useAddSharedSlideCommentMutation,
  useLazyGetSharedSlidesCommentsQuery,
} = slideCommentsApi;
