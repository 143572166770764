import { createSlice } from "@reduxjs/toolkit";

export const initialValues = {
  shippingAddresses: [],
  shippingAddressSelected: null,
  shippingMethodSelected: null,
  selectedBlanketPurchase: null,
  blanketPurchaseOrders: null,
  billingContactSelected: null,
  dropboxSelected: null,
  order: null,
  teamUUID: 0,
  validated: false,
  submitting: false,
};

const billingShippingSlice = createSlice({
  name: "billingShipping",
  initialState: initialValues,
  reducers: {
    setShippingAddresses: (state, { payload }) => {
      state.shippingAddresses = payload;
    },
    setShippingAddressSelected: (state, { payload }) => {
      state.shippingAddressSelected = payload;
    },
    setShippingMethodSelected: (state, { payload }) => {
      state.shippingMethodSelected = payload;
    },
    setSelectedBlanketPurchase: (state, { payload }) => {
      state.selectedBlanketPurchase = payload;
    },
    setBlanketPurchaseOrders: (state, { payload }) => {
      state.blanketPurchaseOrders = payload;
    },
    setOrder: (state, { payload }) => {
      state.order = payload;
    },
    setBillingContactSelected: (state, { payload }) => {
      state.billingContactSelected = payload;
    },
    setDropboxSelected: (state, { payload }) => {
      state.dropboxSelected = payload;
    },
    setTeamUUID: (state, { payload }) => {
      state.teamUUID = payload;
    },
    setValidated: (state, { payload }) => {
      state.validated = payload;
    },
    setSubmitting: (state, { payload }) => {
      state.submitting = payload;
    },
    reset: () => initialValues,
  },
});

export const {
  setShippingAddresses,
  setShippingAddressSelected,
  setShippingMethodSelected,
  setSelectedBlanketPurchase,
  setBlanketPurchaseOrders,
  setOrder,
  setBillingContactSelected,
  setDropboxSelected,
  setTeamUUID,
  setValidated,
  setSubmitting,
  reset,
} = billingShippingSlice.actions;

export const billingShippingReducer = billingShippingSlice.reducer;

export * as billingShippingSelectors from "./selectors";
