import { ORDER_QUESTIONS_URL } from "components/OrdersList/constants";

const REQUIRED_PROPS_FROM_USER_DETAILS = [
  {
    field: "firstName",
    linkParameter: "firstname",
  },
  {
    field: "lastName",
    linkParameter: "lastname",
  },
  {
    field: "email",
    linkParameter: "email",
  },
  {
    field: "phoneNumber",
    linkParameter: "phone",
  },
  {
    extractor: (user) => user?.primaryTeam?.organization?.name || "",
    linkParameter: "companyname",
  },
];

const REQUIRED_PROPS_FROM_ORDER = [
  {
    field: "id",
    linkParameter: "title",
  },
  {
    extractor: (order) => `Sales Order %23${order.id}`,
    linkParameter: "custevent_h5_case_sales_order",
  },
];

const extractPropsFromObject = (props, obj) => {
  return props.reduce((acc, prop) => {
    if (!prop.field && !prop.extractor) {
      return acc;
    }

    const newProp = {
      key: prop.linkParameter,
      value: prop.field ? obj[prop.field] : prop.extractor(obj),
    };

    return [...acc, newProp];
  }, []);
};

export const buildPropsForOrderQuestionsForm = (userDetails, order) => {
  const linkPropsFromOrder = extractPropsFromObject(
    REQUIRED_PROPS_FROM_ORDER,
    order
  );
  const linkPropsFromUserDetails = extractPropsFromObject(
    REQUIRED_PROPS_FROM_USER_DETAILS,
    userDetails
  );

  return [...linkPropsFromOrder, ...linkPropsFromUserDetails];
};

export const buildUrl = (props) =>
  props.reduce(
    (link, { key, value }) => (key && value ? link + `&${key}=${value}` : link),
    ORDER_QUESTIONS_URL
  );
