import { makeStyles } from "tss-react/mui";

export const usePricesReviewViewStyles = makeStyles()((theme) => {
  return {
    paper: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
      marginBottom: "2rem",
    },
    table: {
      minWidth: 700,
    },
    tableTitle: {
      marginLeft: "-.5rem",
      fontSize: "1rem",
    },
    row: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.default,
      },
      "& > td": {
        whiteSpace: "unset",
      },
    },
    selectForm: {
      marginBottom: "1rem",
      marginTop: "1rem",
      marginRight: "2rem",
    },
    dropboxSelectForm: {
      marginBottom: "1rem",
      paddingBottom: "2rem",
      marginTop: "1rem",
      marginRight: "2rem",
    },
    confirmTrial: {
      width: "100%",
      marginTop: "2rem",
      marginBottom: "2rem",
      padding: theme.spacing(2),
    },
    rightAlignButton: {
      float: "right",
      marginTop: "1rem",
      marginBottom: ".25rem",
    },
    saveButton: {
      textAlign: "right",
      textTransform: "uppercase",
    },
    noteMargin: { marginBottom: "1rem" },
  };
});
