import { useHighlightedTextStyles } from "./styles";

export const HighlightedText = ({ text, highlightList }) => {
  const { classes } = useHighlightedTextStyles();

  const highlightedParts = highlightList.map((highlight) => {
    const pattern = new RegExp(`(${highlight})`, "gi");
    return String(text).split(pattern);
  });

  const flattenParts = [].concat(...highlightedParts);

  return (
    <span>
      {flattenParts.map((part, index) => {
        const isHighlighted = highlightList.some(
          (highlight) => highlight.toLowerCase() === part.toLowerCase()
        );
        const className = isHighlighted ? classes.highlightedText : "";

        return (
          <span key={`${part}-${index}`} className={className}>
            {part}
          </span>
        );
      })}
    </span>
  );
};
