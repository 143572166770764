import { SlideGroupShortInfo } from "./SlideGroupShortInfo";
import React, { memo } from "react";
import {
  useAddSlideToGroup,
  useDeleteSlidesFromSlideGroup,
} from "components/SlideGroups/utilities/hooks";
import { checkIfSlideGroupContainSelectedSlides } from "components/SlideGroups/utilities/utilities";

export const SlideGroupItem = memo(
  ({ slideGroup, selectedSlides, classes }) => {
    const { addSlideToSlideGroup, isLoading: isAddSlideToGroupLoading } =
      useAddSlideToGroup();
    const {
      deleteSlidesFromSlideGroup,
      isLoading: isDeleteSlidesFromSlideGroupLoading,
    } = useDeleteSlidesFromSlideGroup();

    const handleSlideGroupClick = () => {
      isSlideGroupContainSlides
        ? deleteSlidesFromSlideGroup(slideGroup, selectedSlides)
        : addSlideToSlideGroup(slideGroup, selectedSlides);
    };

    const isSlideGroupContainSlides = checkIfSlideGroupContainSelectedSlides(
      slideGroup,
      selectedSlides
    );

    return (
      <SlideGroupShortInfo
        key={slideGroup.uuid}
        slideGroup={slideGroup}
        customClasses={classes}
        isHighlighted={isSlideGroupContainSlides}
        isLoading={
          isAddSlideToGroupLoading || isDeleteSlidesFromSlideGroupLoading
        }
        onClick={() => handleSlideGroupClick(slideGroup, selectedSlides)}
      />
    );
  }
);
