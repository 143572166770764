import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import { FormHelperText, FormControl, Input, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  DUPLICATED_NAME_ERROR_MESSAGE,
  EMPTY_NAME_ERROR_MESSAGE,
} from "components/IFViewer/components/Controller/constants";

export const useChannelPresetStyles = makeStyles()(() => ({
  root: {
    marginBlock: "0.5rem",
  },
  channelName: {
    marginTop: "0.25rem",
    fontSize: "1.125rem",
  },
  arrowIcon: {
    marginInline: "1rem",
  },
}));

const compareProps = (prev, next) => {
  return (
    prev.customName === next.customName &&
    prev.isEditMode === next.isEditMode &&
    prev.originalName === next.originalName &&
    prev.isDuplicated === next.isDuplicated
  );
};

export const ChannelPreset = React.memo(
  ({ originalName, isDuplicated, isEditMode, customName, changePreset }) => {
    const { classes } = useChannelPresetStyles(isEditMode);

    const handleChange = (event) => {
      changePreset(originalName, event.target.value);
    };

    const isError = !customName.length || isDuplicated;
    const errorMessage =
      (!customName.length && EMPTY_NAME_ERROR_MESSAGE) ||
      (isDuplicated && DUPLICATED_NAME_ERROR_MESSAGE);

    return (
      <Box className={classes.root} display="flex" direction="row">
        <Typography
          className={classes.channelName}
          variant={"body1"}
          color={"secondary"}
        >
          {originalName}
        </Typography>
        <KeyboardDoubleArrowRightIcon
          className={classes.arrowIcon}
          fontSize={"large"}
          sx={{
            pointerEvents: "auto",
          }}
        />
        {isEditMode ? (
          <FormControl>
            <Input
              error={isError}
              placeholder="Enter new name"
              className={classes.input}
              value={customName}
              onChange={handleChange}
            />
            <FormHelperText error={true}>{errorMessage}</FormHelperText>
          </FormControl>
        ) : (
          <Typography className={classes.channelName} variant={"body1"}>
            {customName}
          </Typography>
        )}
      </Box>
    );
  },
  compareProps
);
