import { Box, Button, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import { BetaIcon } from "components/Shared/Icons/BetaIcon";
import { useToolbarStyles } from "components/SampleEditor/styles";
import { SaveChangesModal } from "components/SampleEditor/SaveChangesModal";
import { ExcelActionButtons } from "components/SampleEditor/components/ExcelActionButtons";
import UpdateIcon from "@mui/icons-material/Update";
import { SAMPLES_CHANGELOG_URL } from "constants/urls";
import { useHistory } from "react-router";

export const Toolbar = ({
  samplesToUpdate,
  updatedSamplesHandler,
  allSamples,
  exportSamplesIds,
  refetchSamples,
  isOpenModal,
  setIsOpenModal,
  nextPage,
  setNextPage,
  onChangePage,
  setExportSamplesIds,
  gridApi,
  fullColumns,
}) => {
  const history = useHistory();
  const { classes } = useToolbarStyles({ activeSave: samplesToUpdate.length });

  const closeModal = () => {
    setIsOpenModal(false);
    setNextPage(null);
  };

  const handleChangelog = () => {
    history.push(SAMPLES_CHANGELOG_URL);
  };

  return (
    <>
      <SaveChangesModal
        closeModal={closeModal}
        updatedSamplesHandler={updatedSamplesHandler}
        isOpenModal={isOpenModal}
        allSamples={allSamples}
        samplesToUpdate={samplesToUpdate}
        refetchSamples={refetchSamples}
        nextPage={nextPage}
        onChangePage={onChangePage}
      />
      <Box className={classes.wrapper}>
        <Button
          startIcon={<SaveIcon />}
          endIcon={<BetaIcon />}
          variant="contained"
          className={classes.button}
          disabled={!samplesToUpdate.length}
          onClick={() => setIsOpenModal(true)}
        >
          Save changes
        </Button>
        <Box className={classes.buttonsWrapper}>
          <ExcelActionButtons
            gridAPI={gridApi}
            isDisabledExport={!exportSamplesIds.length}
            setSelectedRows={setExportSamplesIds}
            fileName="Samples"
            fullColumns={fullColumns}
          />
          <IconButton className={classes.changelog} onClick={handleChangelog}>
            <UpdateIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
