import { makeStyles } from "tss-react/mui";
import { HISTOWIZ_PRIMARY_COLOR, MEDIUM_GREY } from "utilities/colors";

export const resizeableThumbnailStyles = () => ({
  floatingResizeableThumbnail: {
    position: "fixed",
    right: "0.75rem",
    bottom: "0.25rem",
    zIndex: 0,
    objectFit: "cover",
    objectPosition: "0 0",
    cursor: "pointer",
  },
  floatingThumbnail: {
    position: "fixed",
    right: "0.75rem",
    bottom: "0.25rem",
    zIndex: 0,
    objectFit: "cover",
    objectPosition: "0 0",
    width: "12.5rem",
    height: "12.5rem",
    cursor: "pointer",
  },
  floatingPaper: {
    "&:hover": {
      "& .hover-visible": {
        background: HISTOWIZ_PRIMARY_COLOR,

        "& svg": {
          display: "block",
        },
      },
    },
  },
  closeBackground: {
    position: "fixed",
    right: "0.25rem",
    bottom: "12.25rem",
    opacity: 0.7,
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: MEDIUM_GREY,
    cursor: "pointer",
  },
  closeIcon: {
    width: "0.75rem",
    height: "0.75rem",
    display: "none",
    color: "white",
  },
  image: {
    width: "100%",
    objectFit: "cover",
  },
});

export const useResizeableThumbnailStyles = makeStyles()(
  resizeableThumbnailStyles
);
