import measurement_image from "images/newFeatures/measurement_tool.gif";
import { MEASUREMENT_TOOL_MODAL_TUTORIAL_FIELD } from "components/ImageViewer/constants";
import { DefaultTutorialModal } from "components/Modals/TutorialModals/DefaultTutorialModal";
import { HOW_TO_USE_MEASUREMENT_OPTIONS } from "components/Modals/TutorialModals/constants";

export const MeasurementToolTutorialModal = (props) => {
  const headerText = "Measurement Tool Tutorial";
  const tutorialImage = measurement_image;
  const tutorialOptions = HOW_TO_USE_MEASUREMENT_OPTIONS;
  const showTutorialName = MEASUREMENT_TOOL_MODAL_TUTORIAL_FIELD;

  return (
    <DefaultTutorialModal
      {...props}
      headerText={headerText}
      tutorialImage={tutorialImage}
      tutorialOptions={tutorialOptions}
      showTutorialName={showTutorialName}
    />
  );
};
