import { makeStyles } from "tss-react/mui";

export const useGlpFormStyles = makeStyles()(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: "1220px",
    minHeight: "calc(100vh - 100px)",
    padding: "3rem",
    width: "100%",
  },
  section: { display: "flex", flexWrap: "wrap", gap: "2rem" },
  header: {
    paddingBottom: "3rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  links: { display: "flex", gap: "2rem", flexWrap: "wrap" },
  protocol: {
    display: "flex",
    flexWrap: "wrap",
    gap: ".5rem",
    padding: "2rem 0",
  },
  link: { fontWeight: 600, width: "fit-content" },
  input: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    cursor: "pointer",
  },
  label: {
    alignItems: "center",
    border: "1px dashed #666",
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
    padding: "1rem",
    position: "relative",
    width: "100%",
  },
  error: { border: "2px solid red", borderRadius: "0.5rem" },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: ".5rem",
    marginTop: "auto",
    width: "100%",
    paddingTop: "6rem",
    button: { width: "100%" },
  },
  field: { flexGrow: 1, width: "40%" },
  fullWidth: { width: "100%" },
  select: { "& label": { left: "-12px" } },
}));
