import React, { useRef, useEffect } from "react";
import * as typeformEmbed from "@typeform/embed";

export const DropboxTypeform = () => {
  const typeformRef = useRef(null);

  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      "https://form.typeform.com/to/agL57cT5",
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 100,
      }
    );
  }, [typeformRef]);

  return (
    <div ref={typeformRef} style={{ height: "100vh", width: "100%" }}></div>
  );
};
