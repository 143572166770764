import React, { Fragment, useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { getOrderNewsfeed } from "services/resources/orders";
import { useTitle } from "components/utilities/hooks/useTitle";
import { SlideAndSlideCommentsComponent } from "components/AllSlidesComments/SlideAndSlideCommentsComponent";
import { OrderCommentComponent } from "components/AllSlidesComments/OrderCommentComponent";
import { FETCH_NEWSFEED_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useOrderActivityViewStyles } from "./styles";
import { useDeleteSlideCommentMutation } from "store/apis/slideCommentsApi";

const SLIDE_COMMENT_BACKEND_INSTANCE_TYPE = "slide_comment";

const RenderOrderCommentOrSlideComment = ({ comment, handleDeleteComment }) => {
  const { classes } = useOrderActivityViewStyles();
  // this renders either a slide comment or an order comment
  const SlideAndSlideCommentComponentBool =
    comment.instance_type === SLIDE_COMMENT_BACKEND_INSTANCE_TYPE;
  const renderChild = SlideAndSlideCommentComponentBool ? (
    <SlideAndSlideCommentsComponent
      slideComments={[comment]}
      onDelete={handleDeleteComment}
      imgHeight={"300px"}
    />
  ) : (
    <OrderCommentComponent orderComment={comment} />
  );

  return (
    <Box m={2}>
      <Paper key={comment.uuid} className={classes.slideCard} elevation={9}>
        {renderChild}
      </Paper>
    </Box>
  );
};

export const OrderActivityView = ({ order }) => {
  const pageTitle = `HistoWiz - ${order.name} Activity`;
  useTitle(pageTitle);

  const { showError } = useSnackbar();
  const { classes } = useOrderActivityViewStyles();
  const [comments, setComments] = useState([]);

  const [deleteSlideComment] = useDeleteSlideCommentMutation();

  const [loadingText, setLoadingText] = useState("Loading...");

  const handleDeleteComment = (commentForDeleting) => {
    deleteSlideComment({
      slideUUID: commentForDeleting.slide.uuid,
      commentUUID: commentForDeleting.uuid,
    })
      .unwrap()
      .then(() => {
        setComments((actualComments) => {
          const copiedComments = [...actualComments];

          return copiedComments.filter(
            (comment) => comment.uuid !== commentForDeleting.uuid
          );
        });
      })
      .catch(() => showError("Failed to delete comment"));
  };

  useEffect(() => {
    getOrderNewsfeed({ order })
      .then((response) => {
        setComments(response);
        if (response.length === 0) {
          const noCommentsLabel =
            "No slide or order comments were found for this order.";
          setLoadingText(noCommentsLabel);
        }
      })
      .catch(() => showError(FETCH_NEWSFEED_ERROR_MESSAGE));
  }, [order]);

  if (!order) {
    return null;
  }

  if (!comments.length) {
    return (
      <Box mt={1}>
        <Typography className={classes.noCommentsLabel} variant={"h5"}>
          {loadingText}
        </Typography>
      </Box>
    );
  }

  return (
    <Fragment>
      <div className={classes.root}>
        {comments.map((comment) => (
          <RenderOrderCommentOrSlideComment
            key={comment.uuid}
            comment={comment}
            handleDeleteComment={handleDeleteComment}
          />
        ))}
      </div>
    </Fragment>
  );
};
