import React from "react";
import { Link } from "react-router-dom";

import {
  CUSTOM_SLIDE_LIST_ROUTE,
  IF_VIEWER_URL,
  ORDERS_SLIDE_LIST_URL,
  SLIDE_VIEWER_URL,
} from "constants/urls";
import { getNormalizedSlideFileName } from "utilities/slides";
import { Grid, Typography } from "@mui/material";
import {
  gridStyle,
  tabPadding,
  typographySlideNameStyle,
} from "components/OrderDetails/orderDetailsSlidesStyles";
import {
  downloadMultiIFSlideSourceByOMETIFF,
  downloadMultiIFSlideSourceByQPTIFF,
} from "services/resources/slides";
import { backendURL } from "services/backendAPI";
import {
  useLazyDownloadIFSlideSourceOMETiffQuery,
  useLazyDownloadIFSlideSourceQPTiffQuery,
} from "store/apis/downloadSlideApi";
import { isMatchingFile } from "components/ImageViewer/SlideViewerHeader/utils";

const ifSlideStyles = {
  WebkitFilter: "invert(100%)",
  filter: "invert(100%)",
  maxHeight: "500px",
};

export const getIFViewerURLFromSlide = ({ ifSlide }) => {
  const { uuid: slideUUID } = ifSlide;
  const { uuid: orderUUID } = ifSlide.order;

  let url = IF_VIEWER_URL.replace(":orderUUID", orderUUID);
  url = url.replace(":slideUUID", slideUUID);
  return url;
};

export const RenderSlideMacro = ({ slide, quickFilterText }) => {
  const slideViewerURL = SLIDE_VIEWER_URL.replace(":slideUUID", slide.uuid);
  const slideName = slide.name;

  const serializedSlideName = getNormalizedSlideFileName(slide);

  if (
    quickFilterText &&
    !slideName.toUpperCase().includes(quickFilterText.toUpperCase())
  ) {
    return null;
  }

  return (
    <Grid item xl={2} lg={3} md={4} xs={6} key={slide.uuid} style={gridStyle}>
      <Link to={slideViewerURL}>
        <Typography style={typographySlideNameStyle} variant={"h6"}>
          {serializedSlideName}
        </Typography>
        <img
          src={slide.medium_macro_url}
          loading="lazy"
          width="60%"
          alt={"Macro"}
        />
      </Link>
    </Grid>
  );
};

export const RenderIFSlideMacro = ({ ifSlide }) => {
  const serializedSlideName = getNormalizedSlideFileName(ifSlide);
  const viewerURL = getIFViewerURLFromSlide({ ifSlide });

  return (
    <Grid item xl={2} md={3} sm={3} xs={4} key={ifSlide.uuid} style={gridStyle}>
      <Link to={viewerURL}>
        <Typography style={typographySlideNameStyle} variant={"h6"}>
          {serializedSlideName}
        </Typography>
      </Link>
      <img
        src={ifSlide.gcp_macro_medium}
        width="60%"
        alt={"Macro"}
        style={ifSlideStyles}
      />
    </Grid>
  );
};

export const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={tabPadding}>
      {children}
    </Typography>
  );
};

export const DefaultTabWrapper = ({ children }) => {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};

export const useGetSingleContextMenuItemsIFGrid = () => {
  const [downloadIFSlideSourceQPTiff] =
    useLazyDownloadIFSlideSourceQPTiffQuery();
  const [downloadIFSlideSourceOMETiff] =
    useLazyDownloadIFSlideSourceOMETiffQuery();

  const getSingleContextMenuItemsIFGrid = ({ row, isStaff }) => {
    const menuItems = [
      {
        name: "Copy Slide Name",
        action: () => {
          if (row) {
            navigator.clipboard.writeText(row.name);
          }
        },
      },
      {
        name: "Download Slide (qptiff)dd",
        action: () => {
          if (row) {
            downloadIFSlideSourceQPTiff({ IFSlide: row });
          }
        },
      },
      {
        name: "Download Slide (ome.tiff)",
        action: () => {
          if (row) {
            downloadIFSlideSourceOMETiff({ IFSlide: row });
          }
        },
      },
    ];

    if (isStaff) {
      const lisaSlideLink = `${backendURL}/lisa/core/ifslide/${row.id}/change/`;

      const additionalAdmin = [
        {
          name: "View Slide (LISA)",
          action: () => {
            if (row) {
              window.open(lisaSlideLink, "_blank");
            }
          },
        },
      ];

      menuItems.push(...additionalAdmin);
      return menuItems;
    }

    return menuItems;
  };
  return getSingleContextMenuItemsIFGrid;
};

export const getMultiContextMenuItemsIFViewer = ({ rows }) => {
  let menuContext = [
    {
      name: "Download Slides as .qptiff",
      action: () => {
        downloadMultiIFSlideSourceByQPTIFF({ slides: rows });
      },
    },
    {
      name: "Download Slides as .ome.tiff",
      action: () => {
        downloadMultiIFSlideSourceByOMETIFF({ slides: rows });
      },
    },
  ];
  return menuContext;
};

export const viewAllSlides = ({ slides, selectedRows }) => {
  if (!slides?.length) return;

  const pageUrl = `${ORDERS_SLIDE_LIST_URL.replace(
    ":orderUUID",
    slides[0].order?.uuid
  )}#`;

  if (selectedRows?.length) {
    const query = selectedRows.map((row) => `slideId=${row.uuid}`).join(";");

    const url = `${pageUrl}${query}`;
    window.open(url);
    return;
  }

  window.open(pageUrl);
};

export const viewSelectedSlides = ({ selectedRows }) => {
  if (selectedRows?.length) {
    const slidesUUIDs = selectedRows.map((row) => row.uuid);
    localStorage.setItem("customGroupUUIDs", JSON.stringify(slidesUUIDs));
    window.open(CUSTOM_SLIDE_LIST_ROUTE);
  }
};

export const findMatchedSlides = (files, slides) =>
  slides
    .filter(
      (slide) =>
        files.some((file) => isMatchingFile(slide.name, file.name)) &&
        slide.is_public
    )
    .reduce((acc, slide) => {
      const existing = acc.find((s) => s.name === slide.name);
      if (!existing) {
        acc.push(slide);
      } else if (existing.created < slide.created) {
        const existingIndex = acc.indexOf(existing);
        acc[existingIndex] = slide;
      }
      return acc;
    }, []);
