import React, { useState, Fragment } from "react";
import { Button, TextField, Tooltip, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Counter } from "components/SlideGroups/components/Counter";
import { DeleteModal } from "components/Modals/DeleteModal";
import { TextWithLimitedWidth } from "components/SlideGroups/components/TextWithLimitedWidth";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import {
  useDeleteSlideGroup,
  useRenameSlideGroup,
} from "components/SlideGroups/utilities/hooks";
import { useDefaultSlideGroupHeaderStyles } from "components/SlideGroups/utilities/styles";

export const DefaultSlideGroupHeader = ({
  slideGroup,
  redirectAction = null,
}) => {
  const { classes } = useDefaultSlideGroupHeaderStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [slideGroupNameInputValue, setSlideGroupNameInputValue] = useState(
    slideGroup.name
  );
  const { deleteSlideGroup } = useDeleteSlideGroup();
  const { renameSlideGroup } = useRenameSlideGroup();

  const handleSubmitGroupRename = () => {
    renameSlideGroup(slideGroup, slideGroupNameInputValue)?.then(() =>
      setIsEditMode(false)
    );
  };

  const handleDeleteGroup = () => {
    deleteSlideGroup(slideGroup)?.then(() =>
      setIsConfirmationModalVisible(false)
    );
  };

  return (
    <Fragment>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"row"}
      >
        {isEditMode ? (
          <Box
            className={`${classes.headerTextContainer} ${classes.fullWidth}`}
          >
            <TextField
              className={classes.groupNameInput}
              value={slideGroupNameInputValue}
              onChange={(e) => setSlideGroupNameInputValue(e.target.value)}
            />
            <Button
              className={classes.headerButton}
              variant="outlined"
              color={"success"}
              onClick={handleSubmitGroupRename}
            >
              <DoneIcon />
            </Button>
            <Button
              className={classes.headerButton}
              variant="outlined"
              color="error"
              onClick={() => setIsEditMode(false)}
            >
              <CloseIcon />
            </Button>
          </Box>
        ) : (
          <Box className={classes.leftSection}>
            <TextWithLimitedWidth
              value={slideGroup.name}
              textClass={classes.headerText}
              containerClass={classes.headerTextContainer}
            />
            {!!redirectAction && (
              <OpenInNewSharpIcon
                className={classes.redirectIcon}
                fontSize="small"
                onClick={redirectAction}
              />
            )}
          </Box>
        )}
        <Box className={classes.row}>
          <Tooltip
            disableInteractive
            title="Edit group name"
            placement="bottom"
          >
            <EditIcon
              className={classes.actionIcon}
              onClick={() => setIsEditMode(true)}
            />
          </Tooltip>
          <Tooltip disableInteractive title="Delete group" placement="bottom">
            <DeleteIcon
              className={classes.actionIcon}
              onClick={() => setIsConfirmationModalVisible(true)}
            />
          </Tooltip>
          <Typography variant="h6" gutterBottom className={classes.slidesLabel}>
            SLIDES
          </Typography>
          <Counter count={slideGroup.slides.length} />
        </Box>
      </Box>
      <DeleteModal
        open={isConfirmationModalVisible}
        onClose={() => setIsConfirmationModalVisible(false)}
        handleDeleteResult={handleDeleteGroup}
      />
    </Fragment>
  );
};
