import { makeStyles } from "tss-react/mui";

export const useNotifyOrderCommentsModalStyles = makeStyles()((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  cancelButton: {
    marginRight: "1rem",
  },
  externalTextField: {
    marginTop: "1rem",
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  reviewTextLabel: {
    marginBottom: "1rem",
    fontWeight: "bold",
  },
  reviewText: {
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  questionText: {
    display: "inline-block",
  },
  grid: { height: "100%", width: "100%", overflowX: "auto" },
  buttonTypography: { marginTop: "1rem" },
  slideThumbnailImage: {
    verticalAlign: "top",
    marginRight: "1rem",
    marginBottom: "1rem",
  },
}));

export const useSlideSelectionModalStyles = makeStyles()((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  cancelButton: {
    marginRight: "1rem",
  },
  externalTextField: {
    marginTop: "1rem",
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
  },
  reviewTextLabel: {
    marginBottom: "1rem",
  },
  questionText: {
    display: "inline-block",
  },
  grid: { height: "100%", overflowX: "auto" },
  buttonTypography: { marginTop: "1rem" },
}));
