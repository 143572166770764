import React, { useContext } from "react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { SidebarListItem } from "components/Layout/Sidebar/SidebarListItem";
import { SidebarList } from "components/Layout/Sidebar/SidebarList";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { buildContactUsLink } from "components/Layout/Sidebar/utilities";
import { AppContext } from "services/context";
import { CONTACT_US_URL } from "components/Layout/Sidebar/constants";

export const ContactUsList = () => {
  const userDetails = useSelector(userDetailsSelector);
  const { isLoggedIn } = useContext(AppContext);

  const link = isLoggedIn ? buildContactUsLink(userDetails) : CONTACT_US_URL;

  return (
    <SidebarList>
      <SidebarListItem
        Icon={ContactSupportIcon}
        name="Contact Us"
        path={link}
        isExternal={true}
      />
    </SidebarList>
  );
};
