import { useEffect, useMemo, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useHistory, useLocation, useParams } from "react-router";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from "@mui/material";

import { SelectListComponent } from "components/OrderSlidesChart/SelectList";
import { useChartStyles } from "components/OrderSlidesChart/styles";
import {
  getElementColor,
  getSelectedChartValues,
  prepareLabelsAndSlides,
  getInitialValues,
  transformSlide,
} from "components/OrderSlidesChart/helpers/utils";

import { getChartData } from "services/resources/chartData";
import {
  AVAILABLE_PARAMETERS,
  BAR,
  LINE,
  NO_DATA_AVAILABLE,
  PIE,
  SLIDE_DEFINITION_ID,
  URL_PARAM_FILTER_SLIDES_BY,
  URL_PARAM_PARAMETERS,
  URL_PARAM_SELECT_ALL_SLIDES,
  URL_PARAM_SLIDES,
  URL_PARAM_SOURCE,
  URL_PARAM_TYPE,
} from "components/OrderSlidesChart/helpers/constants";
import { ViewSlideModal } from "components/Modals/ViewSlideModal";
import { FETCH_SLIDE_ERROR_MESSAGE } from "constants/errorMessages";
import { PRIMARY_WHITE } from "utilities/colors";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { OrderSlidesChartHeader } from "components/OrderSlidesChart/OrderSlidesChartHeader/OrderSlidesChartHeader";
import { OrderSlidesChartBody } from "components/OrderSlidesChart/OrderSlidesChartBody";
import { useGetSlides } from "./helpers/hooks";
import { OrderSlidesChartDemoModal } from "./OrderSlidesChartDemoModal";

import { selectForceOpenDemoChart } from "store/slices/userDetailsSlice";
import { useLazyGetSlideQuery } from "store/apis/slidesApi";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  PointElement,
  LineElement
);

export const OrderSlidesChart = ({ sources }) => {
  const { classes } = useChartStyles();
  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [slideData, setSlideData] = useState([]);
  const [selectedSlideData, setSelectedSlideData] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [chartType, setChartType] = useState(BAR);
  const [dataType, setDataType] = useState("");
  const [dataTypeSelected, setDataTypeSelected] = useState("");
  const [attachmentUUID, setAttachmentUUID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [viewSlideModalOpen, setViewSlideModalOpen] = useState(false);
  const [allSlidesSelected, setAllSlidesSelected] = useState(false);
  const [filteredSlidesList, setFilteredSlidesList] = useState([]);
  const [showValues, setShowValues] = useState(false);
  const [slideNames, setSlideNames] = useState([]);
  const [slideNameParameter, setSlideNameParameter] = useState("");
  const [filterBy, setFilterBy] = useState(null);
  const [selectedLabelsState, setSelectedLabelsState] = useState({});
  const [selectedSlidesState, setSelectedSlideState] = useState({});
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

  const isDemoEnabled = useSelector(selectForceOpenDemoChart);

  const [getSlide, { data: slide }] = useLazyGetSlideQuery();

  const { showError } = useSnackbar();
  const { orderUUID } = useParams();
  const { path, search } = useLocation();

  // Get order slides and map by names from image analysis data
  const { currentOrder, slides } = useGetSlides(orderUUID);

  const history = useHistory();
  const chartRef = useRef(null);

  const handleDemoModalClose = () => {
    setIsDemoModalOpen(false);
  };

  // Get selected chart data from the URL if specified
  const [
    slidesSelected,
    paramsSelected,
    sourceSelected,
    chartTypeSelected,
    filterBySlideName,
  ] = useMemo(() => {
    let error;

    const result = AVAILABLE_PARAMETERS.map((param) => {
      let savedValue;
      try {
        savedValue = JSON.parse(new URLSearchParams(search).get(param));
      } catch (e) {
        error = e;
      }
      return savedValue || null;
    });

    error && showError(error);

    return result;
  }, [search]);

  useEffect(() => {
    if (sourceSelected) {
      setDataType(sourceSelected);
    }
  }, [sourceSelected]);

  useEffect(() => {
    if (!isDemoEnabled) {
      return;
    }
    setIsDemoModalOpen(true);
  }, [isDemoEnabled]);

  const prepareChartData = (data) => {
    // Define which params to use for the chart values
    const [labelsFromSlide, namesFromSlide] = prepareLabelsAndSlides(data[0]);

    setLabels(labelsFromSlide);
    setSlideNames(namesFromSlide);
    const initialSlideNameParameter = namesFromSlide[0];
    // transform BE data
    if (initialSlideNameParameter) {
      setChartData(data.map(transformSlide));
      setSlideNameParameter(initialSlideNameParameter);
    }

    // Set slide names to display in the list
    const names = data.map(
      (slide) => transformSlide(slide)[initialSlideNameParameter]
    );

    // Set initial checkboxes state
    const [initialLabels, initialSlides] = getInitialValues(labels, names);
    setSelectedSlideState(slidesSelected || initialSlides);
    setSelectedLabelsState(paramsSelected || initialLabels);
    // Set values from url if present
    slidesSelected &&
      setSelectedSlideData(
        Object.keys(slidesSelected).filter((slide) => slidesSelected[slide])
      );

    setChartValues(
      getSelectedChartValues(
        data,
        paramsSelected,
        slidesSelected,
        initialSlideNameParameter,
        chartTypeSelected || chartType
      )
    );
    chartTypeSelected && setChartType(chartTypeSelected);
    // Set slides list to display using filter
    setSlideData(names);
    setFilteredSlidesList(names);
    setFilterBy(filterBySlideName || "");
  };

  const resetChart = () => {
    setSelectedSlideState({});
    setSelectedLabelsState({});
    setChartValues([]);
    setSelectedSlideData([]);
    setAllSlidesSelected(false);
    setChartType(BAR);
    setFilteredSlidesList(slideData);
    setFilterBy("");
    history.replace({ pathname: path, search: "" });
  };

  useEffect(() => {
    if (!dataType || !attachmentUUID) return;

    setIsLoading(true);
    getChartData(attachmentUUID)
      .then(({ data }) =>
        data.length ? prepareChartData(data) : showError(NO_DATA_AVAILABLE)
      )
      .catch((error) => showError(error.message))
      .finally(() => setIsLoading(false));
  }, [attachmentUUID, dataType, dataTypeSelected]);

  // Enable "select all" checkbox if all slides are selected
  useEffect(() => {
    const allSlidesSelected =
      !!Object.values(selectedSlidesState ?? {}).length &&
      Object.values(selectedSlidesState ?? {}).every((v) => v);
    setAllSlidesSelected(allSlidesSelected);
  }, [selectedSlidesState]);

  // Set selected params to url
  const setURLParams = (parameter, state, value, checked) => {
    let params = new URLSearchParams(search);

    const urlSetter = {
      clearParams: () => params.set(parameter, ""),
      [URL_PARAM_TYPE]: () => params.set(parameter, JSON.stringify(value)),
      [URL_PARAM_SOURCE]: () => {
        params = new URLSearchParams();
        params.set(parameter, JSON.stringify(value));
      },
      [URL_PARAM_SELECT_ALL_SLIDES]: () => {
        params.set(URL_PARAM_SLIDES, JSON.stringify(value));
      },
      [URL_PARAM_FILTER_SLIDES_BY]: () =>
        params.set(parameter, JSON.stringify(value)),
    };

    urlSetter[parameter]
      ? urlSetter[parameter]()
      : params.set(parameter, JSON.stringify({ ...state, [value]: checked }));

    history.replace({ pathname: path, search: params.toString() });
  };

  const resetParams = () => {
    const [initialLabels] = getInitialValues(labels, slideData);
    setSelectedLabelsState(initialLabels);
    setChartValues([]);
    setURLParams("clearParams");
  };

  const handleParameterChange = (event) => {
    const { name, checked } = event.target;
    setSelectedLabelsState({ ...selectedLabelsState, [name]: checked });
    setURLParams(URL_PARAM_PARAMETERS, selectedLabelsState, name, checked);

    if (checked) {
      setChartValues([
        ...chartValues,
        {
          label: name,
          data: chartData
            .filter((slide) =>
              selectedSlideData.includes(slide[slideNameParameter])
            )
            .map((item) => item[name]),
          backgroundColor: getElementColor(chartValues),
          borderColor:
            chartType === PIE ? PRIMARY_WHITE : getElementColor(chartValues),
        },
      ]);
    } else {
      setChartValues(chartValues.filter((item) => item.label !== name));
    }
  };

  const handleSlideChange = (event) => {
    const { name, checked } = event.target;
    setSelectedSlideState({ ...selectedSlidesState, [name]: checked });

    setURLParams(URL_PARAM_SLIDES, selectedSlidesState, name, checked);
    const selectedSlide = chartData.find(
      (slide) => slide[slideNameParameter] === name
    );

    if (checked) {
      setChartValues(
        chartValues.map((item) => ({
          ...item,
          data: [...item.data, selectedSlide[item.label]],
        }))
      );
      setSelectedSlideData([...selectedSlideData, name]);
    } else {
      setChartValues(
        chartValues.map((item) => ({
          ...item,
          data: item.data.filter(
            (slide) => slide !== selectedSlide[item.label]
          ),
        }))
      );
      setSelectedSlideData(selectedSlideData.filter((item) => item !== name));
    }
  };

  const handleFilterSlides = (value) => {
    const updatedSlidesList = slideData.filter((slide) =>
      slide.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSlidesList(value ? updatedSlidesList : slideData);
    const selectedAndFilteredSlides = selectedSlideData.filter((slide) =>
      updatedSlidesList.includes(slide)
    );

    selectedSlideData.length && setSelectedSlideData(selectedAndFilteredSlides);
    setChartValues(
      chartValues.map((item) => ({
        ...item,
        data: chartData
          .filter((slide) =>
            updatedSlidesList.includes(slide[slideNameParameter])
          )
          .map((slide) => slide[item.label]),
      }))
    );
    setURLParams(URL_PARAM_FILTER_SLIDES_BY, null, value);
  };

  useEffect(() => {
    filterBy !== null && handleFilterSlides(filterBy);
  }, [filterBy]);

  const handleChartTypeChange = (event) => {
    setURLParams(URL_PARAM_TYPE, {}, event.target.value);
    setChartType(event.target.value);

    setChartValues(
      chartValues.map((item) => ({
        ...item,
        borderColor:
          event.target.value === LINE ? item.backgroundColor : PRIMARY_WHITE,
      }))
    );
  };

  const toggleShowValues = () => {
    setShowValues(!showValues);
  };

  const handleOpenSlideModal = (slideUUID) => {
    if (slideUUID) {
      getSlide({ slideUUID })
        .unwrap()
        .then(() => {
          setViewSlideModalOpen(true);
        })
        .catch(() => showError(FETCH_SLIDE_ERROR_MESSAGE));
    }
  };

  const toggleSlideModal = (value) => {
    setViewSlideModalOpen(value);
  };

  const handleCloseModal = () => {
    toggleSlideModal(false);
  };

  const handleSlideNameParameterChange = ({ value }) => {
    setSlideData(chartData.map((slide) => slide[value]));
    setFilteredSlidesList(chartData.map((slide) => slide[value]));
    handleSelectAllSlides(false);
    setFilterBy("");
    setSlideNameParameter(value);
  };

  const handleSelectAllSlides = (checked) => {
    setAllSlidesSelected(checked);
    const slides = chartData.reduce((acc, slide) => {
      acc[slide[slideNameParameter]] = checked;
      return acc;
    }, {});
    setSelectedSlideState(slides);

    const filteredChartData = checked
      ? chartData.filter((slide) =>
          filteredSlidesList.includes(slide[slideNameParameter])
        )
      : chartData;

    setURLParams(URL_PARAM_SELECT_ALL_SLIDES, {}, slides);

    setSelectedSlideData(
      checked ? filteredChartData.map((slide) => slide[slideNameParameter]) : []
    );

    const updatedChartValues = chartValues.map((item) => ({
      ...item,
      data: checked ? filteredChartData.map((slide) => slide[item.label]) : [],
    }));

    setChartValues(updatedChartValues);
  };

  const handleSource = ({ name, uuid }, reset) => {
    if (reset) {
      resetChart();
      setURLParams(URL_PARAM_SOURCE, {}, name);
    }

    setDataTypeSelected(name);
    setAttachmentUUID(uuid);
  };

  useEffect(() => {
    if (!search.includes(URL_PARAM_SOURCE)) {
      handleSource(sources[0], true);
    } else {
      const urlSource = new URLSearchParams(search)
        .get(URL_PARAM_SOURCE)
        .replaceAll('"', "");
      const foundSource = sources.find(({ name }) => name === urlSource);

      handleSource(foundSource);
    }
  }, [search]);

  return (
    <>
      <OrderSlidesChartHeader
        orderName={currentOrder?.name}
        chart={chartRef}
        chartType={chartType}
        sources={sources}
        handleSource={handleSource}
        selectedSource={{ name: dataTypeSelected, uuid: attachmentUUID }}
      />
      <Box className={classes.container}>
        <Box className={classes.menu}>
          <Box className={classes.menuItem}>
            <Typography>Chart type:</Typography>
            <RadioGroup
              row
              aria-label="type"
              name="type"
              value={chartType}
              onChange={handleChartTypeChange}
            >
              <FormControlLabel
                id="barChartSelector"
                value={BAR}
                control={<Radio />}
                label="Bar"
              />
              <FormControlLabel
                id="lineChartSelector"
                value={LINE}
                control={<Radio />}
                label="Line"
              />
              <FormControlLabel
                id="pieChartSelector"
                value={PIE}
                control={<Radio />}
                label="Pie"
              />
            </RadioGroup>
          </Box>
          <Box className={classes.menuItem}>
            <Typography>Show values</Typography>
            <Switch
              id="showValuesToggle"
              checked={showValues}
              onChange={toggleShowValues}
              name="setType"
            />
            <Button
              id="resetChart"
              variant="outlined"
              onClick={() => {
                resetChart();
                setURLParams(URL_PARAM_SOURCE, {}, sourceSelected);
              }}
            >
              Reset chart
            </Button>
          </Box>
          <Box className={classes.menuItem}>
            <FormControl css={classes.select}>
              <InputLabel id={SLIDE_DEFINITION_ID}>Slide definition</InputLabel>
              <Select
                value={slideNameParameter}
                onChange={({ target }) =>
                  handleSlideNameParameterChange(target)
                }
                labelId={SLIDE_DEFINITION_ID}
              >
                {slideNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.menuListItem}>
            <TextField
              id="filterSlides"
              label="Filter slides"
              onChange={(e) => setFilterBy(e.target.value)}
              value={filterBy || ""}
            />
            <SelectListComponent
              values={filteredSlidesList}
              onChange={handleSlideChange}
              onChecked={selectedSlidesState}
              labels={filteredSlidesList}
              isLoading={isLoading}
              allSelectable
              handleSelectAllSlides={(e) =>
                handleSelectAllSlides(e.target.checked)
              }
              allSlidesSelected={allSlidesSelected}
            />
            <Button
              id={"resetSlidesSelection"}
              size={"small"}
              variant="outlined"
              onClick={() => {
                handleSelectAllSlides(false);
              }}
            >
              Reset slides selection
            </Button>
          </Box>
          <Box className={classes.menuListItem}>
            <SelectListComponent
              values={labels}
              onChange={handleParameterChange}
              onChecked={selectedLabelsState}
              isLoading={isLoading}
            />
            <Button
              id={"resetSlideParameters"}
              size={"small"}
              variant="outlined"
              onClick={resetParams}
            >
              Reset parameters selection
            </Button>
          </Box>
        </Box>
        <Box key={dataTypeSelected} className={classes.bar}>
          <OrderSlidesChartBody
            chartType={chartType}
            labels={selectedSlideData}
            datasets={chartValues}
            chartData={chartData}
            orderName={currentOrder?.name ?? ""}
            handleOpenSlideModal={handleOpenSlideModal}
            showValues={showValues}
            slides={slides}
            params={paramsSelected}
            chartRef={chartRef}
            slideNameParameter={slideNameParameter}
          />
        </Box>
        <ViewSlideModal
          slide={slide}
          isOpen={viewSlideModalOpen}
          handleCloseModal={handleCloseModal}
        />
        <OrderSlidesChartDemoModal
          open={isDemoModalOpen}
          onClose={handleDemoModalClose}
        />
      </Box>
    </>
  );
};
