import { useIHCOptimizationStyles } from "components/IHCOptimizationViewer/IhcOptimizationStyles";
import moment from "moment";
import { DATE_WITH_TIME_TEXT_FORMAT } from "utilities/dates_and_times";
import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import { Checkbox, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { RenderSlideThumbnailInLayoutGrid } from "components/IHCOptimizationViewer/RenderSlideThumbnails";
import { OptimizationDilutionOrderCommentModule } from "components/Modals/IHCOptimization/OptimizationDilutionOrderCommentModule";

export const RenderOrderComment = ({
  orderComment,
  orderCommentsSelected,
  setOrderCommentsSelected,
}) => {
  const { classes } = useIHCOptimizationStyles();
  const dateFormatted = moment(orderComment.created).format(
    DATE_WITH_TIME_TEXT_FORMAT
  );

  const slides = orderComment.slides;

  const commentsSelectedUUIDs = orderCommentsSelected.map(
    (comment) => comment.uuid
  );

  const addCommentToSelected = () => {
    // need to use UUIDs because sometimes javascript makes a copy of the reference
    // and originalComment != comment. by using uuids we ensure that we are comparing
    // the same object
    if (commentsSelectedUUIDs.includes(orderComment.uuid)) {
      setOrderCommentsSelected(
        orderCommentsSelected.filter(
          (comment) => comment.uuid !== orderComment.uuid
        )
      );
    } else {
      setOrderCommentsSelected([...orderCommentsSelected, orderComment]);
    }
  };

  return (
    <Fragment>
      <Box mt={2} mr={3}>
        <Typography variant="h6" align={"right"} gutterBottom>
          {dateFormatted}
        </Typography>
        <Typography variant="subtitle1" align={"right"} gutterBottom>
          <span className={classes.thumbnailSpan}>
            <Checkbox
              onChange={addCommentToSelected}
              value="addCommentToSelected"
              checked={commentsSelectedUUIDs.includes(orderComment.uuid)}
            />
            Attach to Client Email (Batch)
          </span>
        </Typography>
      </Box>
      <OptimizationDilutionOrderCommentModule orderComment={orderComment} />
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {slides.map((slide) => {
          return (
            <RenderSlideThumbnailInLayoutGrid
              key={slide.uuid}
              slide={slide}
              width={150}
            />
          );
        })}
      </Grid>
    </Fragment>
  );
};
