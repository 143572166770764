import { useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import { DefaultModal } from "components/Modals/DefaultModal";
import { ModalSize } from "components/Modals/constants";
import { prepareData } from "components/OrderForm/SamplesFormV2/components/SelectExistingSamplesModal/utils";

import {
  modalStyle,
  useAddPreviousSamplesModalStyles,
} from "components/OrderForm/SamplesFormV2/components/SelectExistingSamplesModal/styles";
import { PARAFFIN_BLOCKS } from "components/OrderForm/constants";
import { getUpdatedArrWithEmptyLines } from "components/OrderForm/SamplesFormV2/utilities";

export const AddPreviousSamplesModal = ({
  ordersAndSamples,
  setAddPreviousSamplesModalOpen,
  addPreviousSamplesModalOpen,
  handleSetAddPreviousSamplesModal,
  checkAllRowsAreValid,
  sampleSubmissions,
  setRows,
}) => {
  const { classes } = useAddPreviousSamplesModalStyles();
  const gridApi = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleAddSlides = () => {
    const samplesToAdd = selectedRows.map((row) => row.data.sample);
    samplesToAdd.forEach((sample) => {
      sample.previous_sample = { uuid: sample.uuid };
      sample.requested_services = [];
      sample.requested_antibodies = [];
      sample.requested_panels = [];
      sample.requested_special_stains = [];
      sample.submission = sampleSubmissions.find(
        (submission) => submission.name === PARAFFIN_BLOCKS
      );
    });

    //samples from prev order should be before empty rows
    setRows((prev) => {
      return getUpdatedArrWithEmptyLines(prev, samplesToAdd);
    });

    setAddPreviousSamplesModalOpen(false);
    checkAllRowsAreValid();
  };

  const {
    rowData,
    columnDefs,
    defaultColDef,
    autoGroupColumnDef,
    getDataPath,
  } = prepareData(ordersAndSamples);

  const onFilterTextBoxChanged = useCallback(
    ({ target }) => {
      gridApi.current.api.setQuickFilter(target.value);
    },
    [gridApi, selectedRows]
  );

  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedNodes());
  };

  return (
    <DefaultModal
      open={addPreviousSamplesModalOpen}
      onClose={handleSetAddPreviousSamplesModal}
      size={ModalSize.SIMPLE}
      styles={modalStyle}
    >
      <Box>
        <div className={classes.previousSamplesContainer}>
          <div className={classes.modalHeader}>
            <div className={classes.title}>
              <Typography variant="h5">Select Samples At HistoWiz</Typography>
              <Typography variant="body1">
                Please select existing samples you would like to add to this
                order.
              </Typography>
            </div>
            <Button
              disabled={!selectedRows.length}
              variant="contained"
              onClick={handleAddSlides}
              color="primary"
            >
              Add
              <div className={classes.samplesCount}>{selectedRows.length}</div>
              Samples
            </Button>
          </div>

          <TextField
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className={classes.filterTextBox}
            type="text"
            id="filter-text-box"
            placeholder="Enter text to filter..."
            onInput={onFilterTextBoxChanged}
          />
          <div className={`${classes.previousSamplesGrid} ag-theme-balham`}>
            <AgGridReact
              ref={gridApi}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              treeData
              animateRows
              groupDefaultExpanded={-1}
              rowSelection="multiple"
              groupSelectsChildren
              getDataPath={getDataPath}
              onSelectionChanged={onSelectionChanged}
              getRowId={({ data }) => data?.uuid}
              suppressRowClickSelection
            />
          </div>
        </div>
      </Box>
    </DefaultModal>
  );
};
