import { makeStyles } from "tss-react/mui";

import { LIGHT_GREY } from "utilities/colors";

export const useSlidesChartStyles = makeStyles()(() => ({
  loader: {
    height: "80vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useChartStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "85vh",
  },
  menu: {
    height: "100%",
    paddingTop: "1rem",
  },
  bar: {
    paddingLeft: "1.5rem",
    width: "64vw",
    height: "80vh",
  },
  listContainer: {
    height: "26vh",
    border: "0.1rem solid #ccc",
    overflow: "auto",
  },
  list: {
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: "flex",
    padding: "0 0 0.5rem",
    width: "100%",
  },
  dataTypeContainer: {
    width: "100%",
    textAlign: "center",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  spinner: {
    marginLeft: "8rem",
    marginTop: "6rem",
  },
  label: {
    margin: 0,
    width: "100%",
    display: "flex",
    padding: "0 0.5rem",
  },
  selectAll: {
    backgroundColor: LIGHT_GREY,
  },
  menuItem: {
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    paddingBottom: "1.5rem",
  },
  select: {
    width: "100%",
    "> label": {
      left: "-13px",
    },
    "> .MuiInputBase-formControl": {
      marginTop: "10px",
    },
  },
  menuListItem: {
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    paddingBottom: "2rem",
  },
  nameSelect: {
    margin: "0.5rem 0 0.5rem 0",
    width: "17rem",
  },
}));
