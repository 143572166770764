import {
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import { rootReducer } from "store/rootReducer";
import { baseCreateApi } from "store/apis/baseCreateApi";
import { RESET_STORE } from "store/constants";
import { resetDataMiddleware } from "store/utilities";

export const resetStore = createAction(RESET_STORE);

export const combinedReducers = combineReducers({
  [baseCreateApi.reducerPath]: baseCreateApi.reducer,
  ...rootReducer,
});

export const extendedRootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return combinedReducers(action.payload, action);
  }

  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: extendedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseCreateApi.middleware)
      .concat(resetDataMiddleware),
});
