import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { Box } from "@mui/material";

import { UserCommentsList } from "components/PathologyMap/Modals/FullSlideInfoModal/SlideCommentsSection/UserCommentsList";
import { NewCard } from "components/PathologyMap/Modals/FullSlideInfoModal/components/NewCard";
import { CommentInput } from "components/PathologyMap/Modals/FullSlideInfoModal/SlideCommentsSection/CommentInput";
import { CommentsHeader } from "./CommentsHeader";
import { useGetSlideComments } from "components/PathologyMap/Modals/FullSlideInfoModal/hooks";

export const CommentsCard = ({ slide }) => {
  const [isInternal, setIsInternal] = useState(false);

  const { isStaff } = useSelector(userDetailsSelector);

  const { comments } = useGetSlideComments(slide);

  return (
    <NewCard
      header={
        <CommentsHeader
          isStaff={isStaff}
          comments={comments}
          isInternal={isInternal}
          setIsInternal={setIsInternal}
        />
      }
      content={
        <Box padding="1rem" height="100%">
          <CommentInput
            slideUUID={slide.uuid}
            isInternal={isInternal}
            isStaff={isStaff}
          />
          <UserCommentsList
            comments={comments}
            isInternal={isInternal}
            isStaff={isStaff}
          />
        </Box>
      }
    />
  );
};
