import {
  HOW_TO_USE_USER_SLIDES_SEARCH_OPTIONS,
  USER_SLIDES_SEARCH_TUTORIAL_VIDEO_URL,
} from "components/Modals/TutorialModals/constants";
import { DefaultTutorialModal } from "components/Modals/TutorialModals/DefaultTutorialModal";
import { USER_SLIDES_SEARCH_MODAL_TUTORIAL_FIELD } from "components/PathologyMap/constants/common";
import { Typography } from "@mui/material";

export const UserSlidesSearchTutorialModal = (props) => {
  const headerText = "User Slides Search Tutorial";
  const showTutorialName = USER_SLIDES_SEARCH_MODAL_TUTORIAL_FIELD;

  return (
    <DefaultTutorialModal
      {...props}
      renderBeforeImage={() => (
        <>
          <Typography variant="body1" gutterBottom textAlign="center">
            {HOW_TO_USE_USER_SLIDES_SEARCH_OPTIONS[0]}
          </Typography>
        </>
      )}
      renderTutorialMedia={() => (
        /*eslint-disable  jsx-a11y/iframe-has-title*/
        <iframe
          src={USER_SLIDES_SEARCH_TUTORIAL_VIDEO_URL}
          width="1024px"
          height="600px"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      headerText={headerText}
      showTutorialName={showTutorialName}
    />
  );
};
