import { FormProvider, Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useEffect, useState } from "react";
import {
  createOrganization,
  getOrganizations,
} from "services/resources/commonResources";
import { serializeNameAndUUID } from "utilities/api";
import {
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import classNames from "classnames";
import create_institution from "images/newFeatures/create_institution.gif";
import { AsyncCreatable } from "react-select";
import { Link } from "react-router-dom";
import { LOGIN_URL } from "constants/urls";
import {
  defaultSignupValues,
  ranges,
  REFERRAL_PROGRAM_VALUE_CONSTANT,
} from "components/SignupForm/constants";
import { useLocation } from "react-router";
import {
  CREATE_ORGANIZATIONS_ERROR_MESSAGE,
  FETCH_ORGANIZATIONS_ERROR_MESSAGE,
} from "constants/errorMessages";
import { defaultShowPreferences } from "components/ImageViewer/constants";
import { AutofillAddressInput } from "components/SignupForm/AutofillInput";
import { StateSelection } from "components/SignupForm/StateSelection";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import {
  signUpValidationSchema,
  teamUserInviteValidationSchema,
} from "components/SignupForm/validation";
import { getPostParamsForRegularSignup } from "components/SignupForm/helpers";

const buttonStyles = {
  registeredButton: {
    marginLeft: "3rem !important",
  },
  signupButton: {
    marginRight: "3rem !important",
    float: "right",
  },
};

const MIN_INSTITUTION_LENGTH = 3;

export const SignupForm = ({ classes, onSubmitAction }) => {
  const defaultSignupValuesParams = { ...defaultSignupValues };

  const [selectedOrganization, setSelectedOrganization] = useState();
  const [organizations, setOrganizations] = useState([]);

  const { showError } = useSnackbar();

  // for team_user_invites, we pass back information in the queryParams, which we need to parse
  // and extract for the frontend to use
  // doing stuff like automatically filling in the email makes the UX experience much better
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const team_user_invite_uuid = searchParams.get("team_user_invite_uuid");
  if (team_user_invite_uuid) {
    defaultSignupValuesParams.email = searchParams.get("email");
  }

  const referralCode = searchParams.get("referral_code");

  if (referralCode) {
    defaultSignupValuesParams.referral = REFERRAL_PROGRAM_VALUE_CONSTANT;
    defaultSignupValuesParams.referralDetails = referralCode;
  }

  // a team invite has a much simpler things we need to verify for
  // because the team (db record) already has many of the records we later need
  const validationSchema = team_user_invite_uuid
    ? teamUserInviteValidationSchema
    : signUpValidationSchema;

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultSignupValuesParams,
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, touchedFields, submitCount, errors, isValid },
  } = methods;

  const referralValue = watch("referral");

  useEffect(() => {
    getOrganizations()
      .then((data) => {
        const serializedOrganizations = data.map(serializeNameAndUUID);

        serializedOrganizations.sort((a, b) => a.label.localeCompare(b.label));
        setOrganizations(serializedOrganizations);
      })
      .catch(() => showError(FETCH_ORGANIZATIONS_ERROR_MESSAGE));
  }, []);

  const getPostParamsForTeamUserInviteSignup = (values) => {
    // because some inputs are disabled for team user invite, we have to do non-conventional
    // fetching to populate some of the values we need
    return {
      email: searchParams.get("email"),
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phoneNumber,
      team_user_invite_uuid: searchParams.get("team_user_invite_uuid"),
    };
  };

  const onSubmit = (values) => {
    const postParams = team_user_invite_uuid
      ? getPostParamsForTeamUserInviteSignup(values)
      : getPostParamsForRegularSignup(values);

    postParams.preferences = defaultShowPreferences;

    return onSubmitAction(postParams);
  };

  const renderInputForm = ({
    placeholder,
    name,
    type,
    disabled,
    defaultValue,
  }) => {
    const isError = shouldDisplayError(name) && errors[name].message;

    return (
      <Fragment>
        <Input
          placeholder={placeholder}
          inputProps={{
            "aria-label": "Description",
          }}
          fullWidth={true}
          type={type}
          {...register(name)}
          disabled={disabled}
          error={!!isError}
          defaultValue={defaultValue}
        />
        <div className={classes.errorText}>{isError}</div>
      </Fragment>
    );
  };

  const loadOptions = (data, callback) => {
    if (data?.length >= MIN_INSTITUTION_LENGTH) {
      // do this to prevent someone from seeing all our customers
      const upperText = data.toUpperCase();
      const filteredResults = organizations.filter((result) => {
        const resultUppercase = result.label.toUpperCase();
        return resultUppercase.includes(upperText);
      });

      return callback(filteredResults);
    } else {
      return callback([]);
    }
  };

  const handleCreateOrganization = (changeHandler) => (value) => {
    if (value.length < MIN_INSTITUTION_LENGTH) return;

    const postParams = {
      name: value,
    };

    createOrganization(postParams)
      .then((response) => {
        const serializedOrganization = serializeNameAndUUID(response);
        setOrganizations((prevOrganizations) => [
          serializedOrganization,
          ...prevOrganizations,
        ]);
        setSelectedOrganization(serializedOrganization);

        changeHandler(serializedOrganization.value);
      })
      .catch(() => showError(CREATE_ORGANIZATIONS_ERROR_MESSAGE));
  };

  const handleOrganizationChange = (changeHandler) => (data) => {
    setSelectedOrganization(data);
    changeHandler(data.value);
  };

  const shouldDisplayError = (fieldName) => {
    return errors[fieldName] && (touchedFields[fieldName] || submitCount);
  };

  const renderOrganizationSelect = () => {
    const name = "organization_uuid";

    return (
      <Grid item xs={5}>
        <Typography display={"inline"}>
          Institution (Creatable, Required){" "}
        </Typography>
        <Tooltip
          disableInteractive
          title={
            <>
              <h5 className={classes.tooltipTitle}>
                If your organization is not listed below, you can create your
                institution when hitting the enter key.
              </h5>
              <img
                src={create_institution}
                width={"100%"}
                alt="Create institution hint"
                className={classes.newInstitutionHint}
              />
            </>
          }
        >
          <IconButton size="medium" aria-label="delete">
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, name } }) => {
            const isError = shouldDisplayError(name) && errors[name].message;
            return (
              <AsyncCreatable
                id={"async-institute-select"}
                placeholder={"Institution ... "}
                loadOptions={loadOptions}
                value={selectedOrganization}
                formatCreateLabel={(data) =>
                  data.length >= MIN_INSTITUTION_LENGTH
                    ? "Create: " + data
                    : `Minimal institution length is ${MIN_INSTITUTION_LENGTH} symbols`
                }
                onChange={handleOrganizationChange(onChange)}
                name={name}
                onBlur={onBlur}
                onCreateOption={handleCreateOrganization(onChange)}
                className={isError && classes.errorSelect}
              />
            );
          }}
        />
        <div className={classes.errorText}>
          {shouldDisplayError(name) && errors[name].message}
        </div>
      </Grid>
    );
  };

  const renderReferralSelection = () => {
    const isError =
      shouldDisplayError("referral") && errors["referral"].message;
    return (
      <Grid item xs={4} className={classes.referralGridWrapper}>
        <Controller
          name="referral"
          control={control}
          render={({ field: { onChange, onBlur, ref } }) => (
            <TextField
              id="heard-from-select"
              defaultValue={referralCode && REFERRAL_PROGRAM_VALUE_CONSTANT}
              fullWidth={true}
              className={classNames(classes.textField)}
              select
              label="Heard From ... "
              onChange={onChange}
              onBlur={onBlur}
              error={!!isError}
              inputRef={ref}
              disabled={!!referralCode}
            >
              {ranges.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <div className={classes.errorText}>{isError}</div>
      </Grid>
    );
  };

  const renderReferralValueSection = () => {
    return (
      <Grid item xs={6} className={classes.referralGridWrapper}>
        <TextField
          id="referral-details-input"
          fullWidth={true}
          label={`Which or Who?`}
          className={classes.textField}
          disabled={!!referralCode}
          margin="none"
          {...register("referralDetails")}
        />
        <div className={classes.errorText}>
          {shouldDisplayError("referralDetails") &&
            errors["referralDetails"].message}
        </div>
      </Grid>
    );
  };

  const registerLabel = team_user_invite_uuid
    ? `Registration for ${searchParams.get("lab_name")} Access`
    : "Register";

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.paperContainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.gridWrapper}
            spacing={0}
          >
            {/*force the signup box to only take half the screen for large resolutions*/}
            <Grid item xs={12} sm={6} xl={5}>
              <Paper className={classes.paperLogin}>
                <Paper className={classes.loginHeader}>
                  <Typography align={"center"} variant={"h5"} color={"inherit"}>
                    {registerLabel}
                  </Typography>
                </Paper>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={5}>
                    {renderInputForm({
                      placeholder: "First Name ... ",
                      name: "firstName",
                    })}
                  </Grid>
                  <Grid item xs={5}>
                    {renderInputForm({
                      placeholder: "Last Name ... ",
                      name: "lastName",
                    })}
                  </Grid>
                  <Grid item xs={10}>
                    {renderInputForm({
                      placeholder: "Email ... ",
                      name: "email",
                      disabled: !!team_user_invite_uuid, // if team_user_invite, email is already selected
                    })}
                  </Grid>
                  <Grid item xs={10}>
                    {renderInputForm({
                      placeholder: "Password ... ",
                      name: "password",
                      type: "password",
                    })}
                  </Grid>
                  <Grid item xs={10}>
                    {renderInputForm({
                      placeholder: "Please Confirm Password ... ",
                      name: "password_2",
                      type: "password",
                    })}
                  </Grid>
                  {!team_user_invite_uuid ? (
                    <>
                      {renderOrganizationSelect()}
                      <Grid item xs={5}>
                        {renderInputForm({
                          placeholder: "Lab Name (e.g. Richard White Lab)... ",
                          name: "labName",
                        })}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        spacing={0}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <AutofillAddressInput
                            placeholder="Zip code  ... "
                            name="zipCode"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {renderInputForm({
                            placeholder: "City  ... ",
                            name: "city",
                          })}
                        </Grid>
                        <StateSelection name="state" classes={classes} />
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={10}>
                    {renderInputForm({
                      placeholder: "Phone Number ... ",
                      name: "phoneNumber",
                    })}
                  </Grid>
                  {!team_user_invite_uuid ? (
                    <>
                      {renderReferralSelection()}
                      {!!referralValue ? (
                        renderReferralValueSection()
                      ) : (
                        <Grid item xs={6} />
                      )}
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fieldsRequiredText}>
                    * All fields are required.
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={4}
                  className={classes.gridWrapper}
                >
                  <Grid item xs={4}>
                    <Link to={LOGIN_URL} className={classes.loginLink}>
                      <Button
                        css={buttonStyles.registeredButton}
                        variant="contained"
                        color="secondary"
                        fullWidth={true}
                      >
                        Already Registered?
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      css={buttonStyles.signupButton}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      disabled={isSubmitting || !isValid}
                    >
                      Signup!
                    </Button>
                  </Grid>
                </Grid>
                <br />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </form>
    </FormProvider>
  );
};
