import { makeStyles } from "tss-react/mui";
import { FORM_GREY } from "utilities/colors";

export const useStyles = makeStyles()({
  sort: {
    display: "flex",
    color: FORM_GREY,
    fontSize: "0.75rem",
    fontWeight: 600,
    cursor: "pointer",
    marginTop: "1rem",
    alignItems: "center",
  },
  filterButton: {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: FORM_GREY,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    opacity: 0.5,
    cursor: "pointer",
  },
  filterCount: {
    padding: "0 0.8rem",
    backgroundColor: FORM_GREY,
    color: "white",
    fontSize: "0.875rem",
    borderRadius: "0.5rem",
    fontWeight: 700,
    marginLeft: "0.5rem",
  },
  filterButtonActive: {
    borderBottom: `2px solid ${FORM_GREY}`,
    fontSize: "1.25rem",
    fontWeight: 700,
    color: FORM_GREY,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    opacity: 0.5,
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
  },
});
