import { OrderNameFromConsultationFormatter } from "components/PathologyConsultations/AgGridColumnsUtilities";

export const PathologyConsultationWorklistServicesRequested = [
  {
    headerName: "Name",
    field: "name",
    enableRowGroup: true,
    filter: "agTextColumnFilter",
    suppressSizeToFit: false,
    checkboxSelection: true,
    width: 200,
  },
  {
    headerName: "Type",
    field: "type",
    enableRowGroup: true,
    filter: "agTextColumnFilter",
    suppressSizeToFit: false,
    width: 150,
  },
  {
    headerName: "Stain",
    field: "stainType",
    valueFormatter: OrderNameFromConsultationFormatter,
    enableRowGroup: true,
    filter: "agTextColumnFilter",
    width: 150,
  },
  {
    headerName: "Requested",
    field: "count",
    //valueFormatter: OrganizationNameFromConsultationFormatter,
    //enableRowGroup: true,
    filter: "agTextColumnFilter",
    width: 150,
  },
];
