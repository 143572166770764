import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SIGNUP_URL } from "constants/urls";
import { Typography } from "@mui/material";
import { DEMO_USER_EMAIL } from "constants/emails";

export const DemoUserWarning = () => {
  return (
    <Fragment>
      <Typography color={"error"} variant={"h6"} gutterBottom>
        This is for demo purposes only. You will not be able to submit the
        order! Please <Link to={SIGNUP_URL}>register</Link> to submit an order.
        <br />
      </Typography>
      <br />
    </Fragment>
  );
};

export const DemoUserWarnIfEmailMatchDemo = ({ email }) => {
  if (email === DEMO_USER_EMAIL) {
    return <DemoUserWarning />;
  } else {
    return null;
  }
};
