import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backendURL } from "services/backendAPI";
import { getLocalToken } from "utilities/auth";

export const baseCreateApi = createApi({
  reducerPath: "apis",
  baseQuery: fetchBaseQuery({
    baseUrl: backendURL,
    prepareHeaders: (headers) => {
      const accessToken = getLocalToken();

      if (accessToken) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Token ${accessToken}`);
      }

      return headers;
    },
  }),
  tagTypes: ["getOrders", "getOrderByUUID"],
  endpoints: () => ({}),
});
