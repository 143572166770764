import { useTitle } from "components/utilities/hooks/useTitle";
import { PATHOLOGY_CONSULTATION_PAGE_TITLE } from "components/IFViewer/constants";
import { useParams } from "react-router";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import {
  getPathologyConsultationInstance,
  getPathologyConsultationTeamFileAttachments,
} from "services/resources/pathologyConsultations";
import React, { useEffect, useState } from "react";
import { PathologyConsultationInfoView } from "components/PathologyConsultations/InfoView";

export const PathologyConsultationInfoPage = () => {
  useTitle(PATHOLOGY_CONSULTATION_PAGE_TITLE);
  const { consultUUID } = useParams();
  const [consultation, setConsultation] = useState(null);
  const [fileAttachments, setFileAttachments] = React.useState([]);

  useEffect(() => {
    if (!consultUUID) {
      return;
    }

    getPathologyConsultationInstance({ consultUUID }).then((consult) => {
      setConsultation(consult);
    });

    getPathologyConsultationTeamFileAttachments({
      consultUUID: consultUUID,
    }).then((response) => {
      setFileAttachments(response);
    });
  }, [consultUUID]);

  return (
    <MiniDrawerWithContext header={PATHOLOGY_CONSULTATION_PAGE_TITLE}>
      <PathologyConsultationInfoView
        consultation={consultation}
        attachments={fileAttachments}
      />
    </MiniDrawerWithContext>
  );
};
