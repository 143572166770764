import React, { useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import ImageOutlined from "@mui/icons-material/ImageOutlined";

import { PROCESSED_IF_SLIDES_TAB_VALUE } from "components/OrderDetails/constants";
import { IF_SLIDE_NAME_FOR_SHARE } from "components/Share/helpers/constants";
import { useIFHeaderStyles } from "components/IFViewer/IFHeader/styles";
import { Share } from "components/Share/Share";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { histowizTheme } from "themes/defaultMuiTheme";
import { ORDER_DETAILS_URL } from "constants/urls";
import { DARK_GREY } from "utilities/colors";

export const IFHeader = ({
  hasTable,
  setHasTable,
  share,
  slide,
  order,
  screenshot,
  areLayersLoading,
  isDownloadLoading,
}) => {
  const [isThumbnailShown, setIsThumbnailShown] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const { classes } = useIFHeaderStyles();
  const { header, buttons, breadcrumb, thumbnail, icon } = classes;
  const { name, share_enabled, gcp_macro_medium } = slide ?? {};

  const BreadcrumbsData = [
    {
      name: `${order.name} Details`,
      color: histowizTheme.palette.primary.light,
      link: `${ORDER_DETAILS_URL.replace(
        ":orderUUID",
        order.uuid
      )}#${PROCESSED_IF_SLIDES_TAB_VALUE}`,
    },
    {
      name: `Slide ${name}`,
      color: histowizTheme.palette.secondary.main,
    },
  ];

  const screenshotHandler = () => {
    if (areLayersLoading) {
      setIsOpenTooltip(true);
      return;
    }
    screenshot?.();
  };

  return (
    <Box className={header}>
      {slide && (
        <Typography variant="h5" color="secondary" className={breadcrumb}>
          <Breadcrumbs
            data={BreadcrumbsData}
            size="medium"
            divider=">"
            dividerColor={DARK_GREY}
          />
          <Box
            className={icon}
            onMouseEnter={() => setIsThumbnailShown(true)}
            onMouseLeave={() => setIsThumbnailShown(false)}
          >
            <ImageOutlined />
            {gcp_macro_medium && isThumbnailShown && (
              <img className={thumbnail} src={gcp_macro_medium} alt={name} />
            )}
          </Box>
        </Typography>
      )}
      <Box className={buttons}>
        {slide && (
          <Share
            noMargin
            item={slide}
            mutation={() => share(slide)}
            itemName={IF_SLIDE_NAME_FOR_SHARE}
            shareId={!share_enabled ? "if-viewer-share-on" : undefined}
            copyId="if-viewer-copy"
            areLayersLoading={areLayersLoading}
          />
        )}
        <Tooltip
          title="You can't make screenshot while slide is loading"
          open={isOpenTooltip}
          onClose={() => setIsOpenTooltip(false)}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={screenshotHandler}
            id="ifViewerScreenshot"
            disabled={isDownloadLoading}
          >
            Screenshot
          </Button>
        </Tooltip>
        <Button
          onClick={() => setHasTable((prev) => !prev)}
          variant="contained"
          color="secondary"
          id="if-viewer-toggle-table"
        >
          {hasTable ? "hide" : "show"} slides list
        </Button>
      </Box>
    </Box>
  );
};
