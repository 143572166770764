import {
  ERROR_MAKING_SLIDE_FAVORITE_MESSAGE,
  YELLOW_MARKED,
} from "components/utilities/constants";
import { IconButton } from "@mui/material";
import StarIcon from "@mui/icons-material/StarRounded";
import StarBorderIcon from "@mui/icons-material/StarBorderRounded";
import React from "react";
import { updateSelectedRow } from "store/slices/slidesListSlice/slidesListSlice";
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import { updateSlide } from "store/slices/slidesSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useMakeSlideStarredMutation } from "store/apis/slidesApi";

const useStyles = makeStyles()(() => ({
  iconButton: {
    padding: 0,
  },
}));

export const FavoriteSlideIconButton = ({
  isStarred,
  slideUUID,
  filledIconProps = {},
  outlineIconProps = {},
  className,
}) => {
  const { showError } = useSnackbar();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [makeSlideStarred] = useMakeSlideStarredMutation();

  const handleClick = (e) => {
    e.stopPropagation();

    if (!slideUUID) {
      return;
    }

    const isStarredUpdated = !isStarred;

    makeSlideStarred({
      slideUUID: slideUUID,
      isStarred: isStarredUpdated,
    })
      .unwrap()
      .then(() => {
        dispatch(
          updateSlide({ uuid: slideUUID, is_starred: isStarredUpdated })
        );
        dispatch(
          updateSelectedRow({ uuid: slideUUID, is_starred: isStarredUpdated })
        );
      })
      .catch((error) =>
        showError(error.response?.data || ERROR_MAKING_SLIDE_FAVORITE_MESSAGE)
      );
  };

  return (
    <IconButton
      size="large"
      onClick={handleClick}
      title={isStarred ? "Unmark as favorite" : "Mark as favorite"}
      className={className || classes.iconButton}
    >
      {isStarred ? (
        <StarIcon htmlColor={YELLOW_MARKED} {...filledIconProps} />
      ) : (
        <StarBorderIcon {...outlineIconProps} />
      )}
    </IconButton>
  );
};
