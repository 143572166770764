import { useDispatch, useSelector } from "react-redux";
import {
  currentRefinementTypeSelector,
  setCurrentFiltersSearchQuery,
  setCurrentRefinementType,
} from "store/slices/pathologyMapSlice";
import { Grid, IconButton } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { useAlgoliaSourceFacet } from "components/PathologyMap/utilities/hooks";
import { getFiltersCountText } from "components/PathologyMap/utilities/common";
import { PathologyMapResultsCounter } from "components/PathologyMap/Shared/PathologyMapResultsCounter";
import { DefaultPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography";
import {
  PATHOLOGY_MAP_BOLD_FONT_WEIGHT,
  PATHOLOGY_MAP_DEFAULT_FONT_WEIGHT,
} from "components/PathologyMap/constants/styles";
import { usePathologyMapCategoryItemStyles } from "./styles";
import StarIcon from "@mui/icons-material/StarRounded";
import { YELLOW_MARKED } from "components/utilities/constants";
import StarBorderIcon from "@mui/icons-material/StarBorderRounded";
import React, { memo } from "react";

export const PathologyMapCategoryItem = memo(
  ({
    isHidden,
    text,
    refinementType,
    isBold,
    isGroupEnd,
    selectedFilters,
    isFavorite = false,
    handleStarClick,
  }) => {
    const { classes } = usePathologyMapCategoryItemStyles();
    const currentRefinementType = useSelector(currentRefinementTypeSelector);
    const dispatch = useDispatch();
    const facet = useAlgoliaSourceFacet(refinementType);
    const filtersCount = getFiltersCountText(facet.length);
    const isSelected = currentRefinementType === refinementType;
    const isFilteredBy = selectedFilters.some(
      (item) => item.attribute === refinementType
    );

    const isDisabled = !isSelected && isFilteredBy;

    const onRowClick = () => {
      dispatch(setCurrentRefinementType(refinementType));
      dispatch(setCurrentFiltersSearchQuery(""));
    };

    const onStarClick = (e) => {
      handleStarClick(isFavorite, refinementType);
      e.stopPropagation();
    };

    const itemFontWeight =
      isBold && filtersCount
        ? PATHOLOGY_MAP_BOLD_FONT_WEIGHT
        : PATHOLOGY_MAP_DEFAULT_FONT_WEIGHT;

    return (
      <Grid
        item
        container
        alignItems="center"
        className={`${classes.categoryItem}
          ${isHidden && classes.hidden}
          ${isSelected && classes.selectedItem}
          ${isGroupEnd && classes.itemGroupEnd}
          ${isDisabled && classes.disabledItem}
        `}
        onClick={onRowClick}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={8}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <DefaultPathologyMapTypography
            fontWeight={itemFontWeight}
            maxWidth="max-content"
          >
            {text}
          </DefaultPathologyMapTypography>
          <IconButton
            size="large"
            onClick={onStarClick}
            className={classes.iconButton}
          >
            {isFavorite ? (
              <StarIcon
                htmlColor={YELLOW_MARKED}
                className={classes.starIcon}
              />
            ) : (
              <StarBorderIcon className={classes.starIcon} opacity={0.6} />
            )}
          </IconButton>
        </Grid>
        <Grid item container justifyContent="center" xs={2}>
          <PathologyMapResultsCounter count={filtersCount} />
        </Grid>
        <Grid item xs className={classes.icon}>
          <div className={classes.arrowWrapper}>
            <NavigateNext />
          </div>
        </Grid>
      </Grid>
    );
  }
);
