import { useEffect, useMemo, useState } from "react";
import {
  getTeamMembers,
  getTeamNewsfeed,
} from "services/resources/commonResources";
import { useGetOrdersQuery } from "store/apis/orderApi";
import { splitOrders } from "components/OrdersList/utilities/helpers";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";
import { FETCH_ORDERS_ERROR_MESSAGE } from "constants/errorMessages";
import {
  filterOrdersByTeam,
  getSelectedOrders,
  ordersSorter,
} from "components/UserProfile/utilities";
import { sortArray } from "utilities/general";

export const useTeamNewsFeed = (selectedTeam) => {
  const [newsfeeds, setNewsfeeds] = useState(new Map());

  useEffect(() => {
    if (!selectedTeam || !!newsfeeds.get(selectedTeam)) return;

    getTeamNewsfeed(selectedTeam.uuid).then((newsfeed) => {
      setNewsfeeds((prevNewsfeeds) => {
        prevNewsfeeds.set(selectedTeam, newsfeed);

        return new Map(prevNewsfeeds);
      });
    });
  }, [selectedTeam]);

  return {
    newsfeeds,
  };
};

export const useTeamMembers = (team) => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!team) return;

    getTeamMembers(team.uuid)
      .then((res) => {
        setMembers(res);
      })
      .catch()
      .finally(() => setIsLoading(false));
  }, [team]);

  return {
    members,
    isLoading,
  };
};

export const usePrepareOrderForSelectedTab = (team, tab, sort) => {
  const {
    data: rawOrders,
    isLoading: isOrdersLoading,
    error,
  } = useGetOrdersQuery();
  useQueryError(error, FETCH_ORDERS_ERROR_MESSAGE);

  const filteredOrders = filterOrdersByTeam(rawOrders, [team]);

  const splittedOrders = useMemo(
    () => splitOrders(filteredOrders),
    [filteredOrders]
  );

  const selectedTabOrders = useMemo(
    () => getSelectedOrders(tab, splittedOrders, filteredOrders),
    [tab, splittedOrders]
  );

  const selectedTabSortedOrders = useMemo(
    () => sortArray(selectedTabOrders, ordersSorter, sort),
    [sort, selectedTabOrders]
  );

  return {
    selectedTabSortedOrders,
    isOrdersLoading,
  };
};
