import React from "react";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { SlideGroupsLayout } from "components/SlideGroups/SlideGroupsLayout";
import { useTitle } from "components/utilities/hooks/useTitle";

export const SlideGroupsListPage = () => {
  useTitle("HistoWiz - Groups");

  return (
    <MiniDrawerWithContext header={"Slide Groups"}>
      <SlideGroupsLayout />
    </MiniDrawerWithContext>
  );
};
