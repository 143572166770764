import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useIsVisible } from "components/utilities/hooks/useIsVisible";
import { ShortInfoBlock } from "components/SlideGroups/components/ShortInfoBlock";
import { useSlideThumbnailWithInfoStyles } from "components/SlideGroups/utilities/styles";

export const SlideThumbnailWithInfo = ({ handleRender, slide }) => {
  const { classes } = useSlideThumbnailWithInfoStyles();
  const containerRef = useRef(null);

  const isVisible = useIsVisible(containerRef, "0px");

  useEffect(() => {
    handleRender(slide.uuid, isVisible);
  }, [isVisible]);

  return (
    <Grid ref={containerRef} item xs={6} height={"75%"} padding={"0.5rem"}>
      <Box className={classes.slideImagePreviewContainer}>
        <Box className={classes.shortInfoAbsoluteContainer}>
          <Box className={classes.shortInfo}>
            <ShortInfoBlock
              title="Order / Name"
              content={`${slide.order.id} / ${slide.name}`}
            />
            <ShortInfoBlock title="Stain Type" content={slide.stain_type} />
            <Box display={"flex"} flexDirection={"row"}>
              <ShortInfoBlock
                title="Antibodies"
                content={slide.antibody?.name}
              />
              <ShortInfoBlock
                title="Xenografts"
                content={slide.sample?.science_info?.xenografts?.[0]}
              />
            </Box>
            <Box display={"flex"} flexDirection={"row"}>
              <ShortInfoBlock title="Organ" content={slide.organ?.name} />
              <ShortInfoBlock title="Species" content={slide.species?.name} />
            </Box>
          </Box>
        </Box>
        <img
          src={slide.medium_thumbnail_url}
          alt={"Macro"}
          className={classes.slideImagePreview}
        />
      </Box>
    </Grid>
  );
};
