import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { Box, Button, Typography } from "@mui/material";
import { RenderFinalReportAttachments } from "components/PathologyConsultations/RenderFinalReportAttachments";
import { ShowStaffUserOnly } from "components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly";
import { usePathologyConsultation } from "components/PathologyConsultations/OrderForm/hooks/usePathologyConsultation";
import { PathologyConsultationFormContent } from "components/PathologyConsultations/OrderForm/PathologyConsultationFormContent";
import { PleaseCallHistoWiz } from "components/Shared/PleaseCallHistoWiz";
import { usePathConsultationFormStyles } from "components/PathologyConsultations/OrderForm/styles";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useHistory } from "react-router";
import { PLACE_ORDER_REVIEW_STEP_URL_REGEX_PATTERN } from "components/PathologyConsultations/OrderForm/utils/constants";

export const PathologyConsultationForm = (props) => {
  const { classes } = usePathConsultationFormStyles();

  const history = useHistory();
  const { showSuccess } = useSnackbar();

  useEffect(() => {
    if (!history?.location?.state?.from) return;

    const wasRedirectedFromReviewPage =
      PLACE_ORDER_REVIEW_STEP_URL_REGEX_PATTERN.test(
        history.location.state.from
      );
    if (wasRedirectedFromReviewPage) {
      showSuccess(
        "Your were redirected to Pathology Consultation form because order contains samples that require Pathology Consultation. Fill this form please"
      );
    }
  }, []);

  const {
    onSubmit,
    consultation,
    methods,
    handleSubmit,
    isGLPConsultation,
    consultUUID,
    selectableTeams,
    team,
    isSubmitting,
    submitText,
    checkKeyDown,
    defaultFormProps,
    setConsultUUID,
    setIsGLPConsultation,
    createConsultationForUploadedFiles,
    showTeamSelectionOptions,
    protocolAttachments,
    setProtocolAttachments,
  } = usePathologyConsultation();

  return (
    <div className={classes.formWrap}>
      {consultation && (
        <Typography variant="h3" align="left">
          Pathology Consultation Questionnaire
          <br />
          {consultation.name}
        </Typography>
      )}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <PathologyConsultationFormContent
            isGLPConsultation={isGLPConsultation}
            setIsGLPConsultation={setIsGLPConsultation}
            consultUUID={consultUUID}
            defaultFormProps={defaultFormProps}
            consultation={consultation}
            createConsultationForUploadedFiles={
              createConsultationForUploadedFiles
            }
            setConsultUUID={setConsultUUID}
            selectableTeams={selectableTeams}
            team={team}
            showTeamSelectionOptions={showTeamSelectionOptions}
            submitCount={methods.formState.submitCount}
            protocolAttachments={protocolAttachments}
            setProtocolAttachments={setProtocolAttachments}
          />
          <Typography variant="body1" align="left" mt={1}>
            Note: This page is editable until a pathologist accepts this order.
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
              type="submit"
              {...props}
            >
              {submitText} Questionnaire
            </Button>
          </Box>
        </form>
      </FormProvider>
      <Box mt={2}>
        <PleaseCallHistoWiz />
      </Box>
      <ShowStaffUserOnly>
        <Box mt={2}>
          <RenderFinalReportAttachments consultation={consultation} />
        </Box>
      </ShowStaffUserOnly>
    </div>
  );
};
