import React from "react";
import { SlidesListView } from "./SlidesListView";
import { withStyles } from "tss-react/mui";
import { MiniDrawerWithContext } from "../Layout/drawer";

const styles = () => ({
  root: {
    height: "80vh",
    position: "relative",
    display: "flex",
    width: "100%",
  },
});

export const _SlidesListGridPage = ({ classes }) => {
  return (
    <MiniDrawerWithContext header={"Slides List"}>
      <div className={classes.root}>
        <SlidesListView />
      </div>
    </MiniDrawerWithContext>
  );
};

export const SlidesListPage = withStyles(_SlidesListGridPage, styles);
