import classNames from "classnames";
import React from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const dropZoneStyles = () => ({
  dropZoneContainer: {
    marginTop: "1rem",
    border: "1px",
    borderStyle: "dashed",
    borderColor: "black",
  },
  dropZoneLabel: {
    margin: "1rem",
  },
  dropZone: {
    width: "100%",
  },
});

export class _UserUploadedAttachment extends React.Component {
  onDrop = (acceptedFiles) => {
    let formData = new FormData();
    const file = acceptedFiles[0];
    const file_name = file.name;

    // we only accept one file so it doesn't really matter
    formData.append("file", acceptedFiles[0]);
    formData.append("file_name", file_name);

    // sometimes we need to pass things like team_uuid or order_uuid
    const contextKeys = Object.keys(this.props.additionalContext);
    contextKeys.forEach((key) => {
      formData.append(key, this.props.additionalContext[key]);
    });

    this.uploadAttachment(formData);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.dropZoneContainer}>
        <Dropzone
          className={classes.dropZone}
          onDrop={this.onDrop}
          multiple={false}
          // TODO - figure out the excel format that windows thinks of xlsx as
          // accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames(classes.dropZone, {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {this.props.icon || null}
                <Typography>
                  <label className={classes.dropZoneLabel}>
                    {this.props.label}
                  </label>
                </Typography>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

_UserUploadedAttachment.propTypes = {
  label: PropTypes.string.isRequired,
  additionalContext: PropTypes.object.isRequired,
};

_UserUploadedAttachment.defaultProps = {
  label: "Please choose your attachment to upload",
};

export const UserUploadedAttachment = withStyles(
  _UserUploadedAttachment,
  dropZoneStyles
);
