import React from "react";
import { DropboxesView } from "./DropboxesView";
import { MiniDrawerWithContext } from "../Layout/drawer";
import { useTitle } from "components/utilities/hooks/useTitle";

export const DropboxPage = () => {
  useTitle("HistoWiz - Dropbox Locations");

  return (
    <MiniDrawerWithContext header={"Dropbox Locations"}>
      <DropboxesView />
    </MiniDrawerWithContext>
  );
};
