import { ML_QC_OVERLAY_PADDING_COEFFICIENT } from "components/ImageViewer/constants";
import {
  getTopQCRegionsByScoreName,
  isQCScoreFailed,
  isQCScorePassed,
} from "components/ImageViewer/utilities";
import {
  AI_MODEL_CHANGE_ACTIONS,
  AI_MODEL_EVENTS,
  QC_SCORE_COLORS,
  SHAPE_DRAW_TYPES,
} from "components/AITools/constants/common";
import { MAP_AI_MODEL_TO_DISPLAY_COLOR } from "components/AITools/constants/styles";
import { isQCModel } from "components/AITools/utilities/common";
import {
  drawPolygonFlat,
  getViewerContainerPositionFromImagePosition,
} from "components/ImageViewer/extAnnoUtils";
import { store } from "store/store";
import {
  removeCurrentSlideAIModel,
  setMLDataLoadingMap,
} from "store/slices/aiToolsSlice";

export const invertPoint = (point, viewer) => {
  return {
    x: viewer.source.width - point.x,
    y: viewer.source.height - point.y,
  };
};

export const getRGBAWithOpacity = (rgbaString, opacityPercent) => {
  const lastCommaIndex = rgbaString.lastIndexOf(",");
  const opacity = opacityPercent / 100;

  return `${rgbaString.substring(0, lastCommaIndex + 1)} ${opacity})`;
};

// Converts QC model contours to a standardized AI contours format.
export const convertQCContoursToAIContoursFormat = (viewer) => (qcPolygon) => {
  const boxSideLength = qcPolygon.patch_bbox[2] - qcPolygon.patch_bbox[0];
  const pad = boxSideLength * ML_QC_OVERLAY_PADDING_COEFFICIENT;
  const px = qcPolygon.patch_bbox[1] + pad;
  const py =
    viewer.source.height - qcPolygon.patch_bbox[0] - boxSideLength - pad;
  const dimension = boxSideLength - pad * 2;

  return {
    ...qcPolygon,
    type: "fill",
    polygon: [
      px,
      py,
      px + dimension,
      py,
      px + dimension,
      py + dimension,
      px,
      py + dimension,
    ],
  };
};

export class AIDrawingManager {
  constructor(
    viewer,
    slide,
    modelsOpacitiesRef,
    mlDataGetter,
    handleGetDataFail,
    initialModels = []
  ) {
    this.viewer = viewer;
    this.slide = slide;
    this.modelsOpacitiesRef = modelsOpacitiesRef;

    this.currentModels = [];

    this.mlDataGetter = mlDataGetter;
    this.handleGetDataFail = handleGetDataFail;

    this.createInitialModels(initialModels);
  }

  async updateSlideMLData(modelName) {
    const model = modelName.split("_")[1];

    if (this.slide[model]) {
      return;
    }

    store.dispatch(setMLDataLoadingMap({ modelName, isLoading: true }));

    try {
      const getterResponse = await this.mlDataGetter({
        slideUUID: this.slide.uuid,
        model: model,
      }).unwrap();

      this.updateSlide({ ...this.slide, ...getterResponse });
    } catch (e) {
      store.dispatch(removeCurrentSlideAIModel(modelName));
      this.handleGetDataFail(e);
    } finally {
      store.dispatch(setMLDataLoadingMap({ modelName, isLoading: false }));
    }
  }

  async modelUpdateHandler(model, modelName) {
    const isTissueModel = !isQCModel(modelName);

    if (isTissueModel) {
      await this.updateSlideMLData(modelName);
      model.draw();
    } else {
      model.draw();
    }
  }

  createInitialModels(initialModels) {
    initialModels.forEach(async (modelName) => {
      const model = this.getOrCreateModel(modelName);
      await this.modelUpdateHandler(model, modelName);
    });
  }

  updateSlide(slide) {
    this.slide = slide;

    this.currentModels.forEach((model) => {
      model.slide = slide;
    });
  }

  // cleaning it up since each model could be up to 1mb
  removeModelDataFromSlide(model) {
    const slide = { ...this.slide };
    delete slide[model];

    this.updateSlide(slide);
  }

  getModelByName(modelName) {
    const model = this.currentModels.find(
      (model) => model.modelName === modelName
    );
    return model;
  }

  createModel(modelName) {
    const isQC = isQCModel(modelName);
    const modelClass = isQC ? AIQualityControlModel : AITissueModel;

    const model = new modelClass(
      modelName,
      this.viewer,
      this.slide,
      this.modelsOpacitiesRef
    );
    this.currentModels.push(model);

    return model;
  }

  getOrCreateModel(modelName) {
    let model = this.getModelByName(modelName);

    if (!model) {
      model = this.createModel(modelName);
    }

    return model;
  }

  async handleModelAdd(model) {
    const modelName = model.modelName;

    await this.modelUpdateHandler(model, modelName);
  }

  handleModelRemove(model) {
    const modelName = model.modelName.split("_")[1];

    this.removeModelDataFromSlide(modelName);

    model.hide();
  }

  handleModelChange(changeType, modelName) {
    const model = this.getOrCreateModel(modelName);

    if (changeType === AI_MODEL_CHANGE_ACTIONS.ADD) {
      this.handleModelAdd(model);
    } else if (changeType === AI_MODEL_CHANGE_ACTIONS.REMOVE) {
      this.handleModelRemove(model);
    }
  }

  destroy() {
    this.currentModels.forEach((model) => {
      model.destroy();
    });
  }
}

class BaseAIModel {
  constructor(modelName, viewer, slide, modelsOpacitiesRef) {
    this.modelName = modelName;
    this.viewer = viewer;
    this.slide = slide;
    this.isShown = false;

    this.overlay = this.viewer.fabricjsOverlay({ scale: 1000 });
    this.canvas = this.overlay.fabricCanvas();
    this.ctx = this.canvas.upperCanvasEl.getContext("2d");

    this.modelsOpacitiesRef = modelsOpacitiesRef;

    this.update = this.update.bind(this);

    this.addEventListeners();
  }

  getFillStyle() {
    const fillStyle = MAP_AI_MODEL_TO_DISPLAY_COLOR[this.modelName];
    return fillStyle;
  }

  applyStylesFilled(polygon) {
    throw new Error(
      `applyStylesFilled not implemented for ${this.modelName} ${polygon}`
    );
  }

  applyStylesNotFilled() {
    this.ctx.save();
    this.ctx.clip();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();
  }

  applyStyles(polygon) {
    if (polygon.type === SHAPE_DRAW_TYPES.FILL) {
      this.applyStylesFilled(polygon);
    } else {
      this.applyStylesNotFilled();
    }
  }

  getOpacity() {
    return this.modelsOpacitiesRef.current;
  }

  getPathPoint(x, y) {
    return { x, y };
  }

  calculateNewPath(region) {
    let newPath = [];
    region.polygon.forEach((value, index) => {
      if (index % 2 !== 0) return;
      const point = this.getPathPoint(value, region.polygon[index + 1]);

      // Pushing individual coordinates instead of the object.
      const position = getViewerContainerPositionFromImagePosition(
        this.viewer,
        point
      );
      newPath.push(position.x, position.y);
    });
    return newPath;
  }

  processContours(contours) {
    return contours.map((region) => ({
      ...region,
      path: this.calculateNewPath(region),
      type: region.type,
    }));
  }

  getTissueContours() {
    throw new Error(`getTissueContours not implemented for ${this.modelName}`);
  }

  draw() {
    if (!this.viewer.source) {
      return;
    }

    const tissueSegmentations = this.getTissueContours();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    tissueSegmentations.forEach((polygon) => {
      drawPolygonFlat(this.ctx, polygon.path);
      this.applyStyles(polygon);
    });

    this.isShown = true;
  }

  update() {
    if (!this.isShown) return;
    this.draw();
  }

  hide() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.isShown = false;
  }

  addEventListeners() {
    this.viewer.addHandler("viewport-change", this.update);
    this.viewer.addHandler("fabricjs-resize-finished", this.update);
    document.addEventListener(AI_MODEL_EVENTS.OPACITY_CHANGE, this.update);
  }

  removeEventListeners() {
    this.viewer.removeHandler("viewport-change", this.update);
    this.viewer.removeHandler("fabricjs-resize-finished", this.update);
    document.removeEventListener(AI_MODEL_EVENTS.OPACITY_CHANGE, this.update);
  }

  destroy() {
    this.hide();
    this.removeEventListeners();
  }
}

export class AITissueModel extends BaseAIModel {
  getPathPoint(x, y) {
    let point = super.getPathPoint(x, y);
    point = invertPoint(point, this.viewer);

    return point;
  }

  applyStylesFilled() {
    const opacity = this.getOpacity();
    const fillStyle = this.getFillStyle();
    this.ctx.fillStyle = getRGBAWithOpacity(fillStyle, opacity);
    this.ctx.fill();
  }

  getTissueContours() {
    const model = this.modelName.split("_")[1];

    let contours = this.slide?.[model]?.coordinates;

    if (typeof contours === "string" || contours === undefined) {
      return [];
    }

    contours = contours.slice(1);
    contours = this.processContours(contours);

    return contours;
  }

  getOpacity() {
    return super.getOpacity().tissue;
  }
}

export class AIQualityControlModel extends BaseAIModel {
  getQCModelStrokeStyleByPolygon(polygon) {
    const fillStyle = this.getFillStyle();
    const opacity = this.getOpacity();
    const score = polygon.patch_score_converted;

    const colorMap = {
      [QC_SCORE_COLORS.FAILED]: fillStyle.FAILED,
      [QC_SCORE_COLORS.PASSED]: fillStyle.PASSED,
      [QC_SCORE_COLORS.DEFAULT]: fillStyle.INCONCLUSIVE,
    };

    let colorKey = QC_SCORE_COLORS.DEFAULT;
    if (isQCScoreFailed(score)) colorKey = QC_SCORE_COLORS.FAILED;
    else if (isQCScorePassed(score)) colorKey = QC_SCORE_COLORS.PASSED;

    return getRGBAWithOpacity(colorMap[colorKey], opacity);
  }

  applyStylesFilled(polygon) {
    this.ctx.fillStyle = "transparent";
    this.ctx.strokeStyle = this.getQCModelStrokeStyleByPolygon(polygon);
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
    this.ctx.fill();
  }

  getTissueContours() {
    if (!this.slide?.additional_data?.[this.modelName]?.regions) return [];

    let contours = getTopQCRegionsByScoreName(
      this.slide,
      this.modelName,
      1000
    ).map(convertQCContoursToAIContoursFormat(this.viewer));

    if (typeof contours === "string" || contours === undefined) {
      return [];
    }

    contours = this.processContours(contours);

    return contours;
  }

  getOpacity() {
    return super.getOpacity().qc;
  }
}
