import { Paper, Tab, Tabs } from "@mui/material";
import { useUpdateAddresses } from "components/OrderForm/BillingShipping/hooks/useUpdateAddresses";
import { AddressSelect } from "components/OrderForm/BillingShipping/ShippingTabs/AddressSelect";
import { serializeTeamAddress } from "components/OrderForm/BillingShipping/utilities/serializers";
import { useBillingShippingStyles } from "components/OrderForm/BillingShipping/styles";
import { TabPanel } from "components/Shared/TabPanel";
import { TeamAddressForm } from "components/Shared/TeamAddress/TeamAddressFormV1";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShippingAddressSelected,
  billingShippingSelectors,
} from "store/slices/billingShippingSlice/billingShippingSlice";

export const ShippingTabs = () => {
  const { classes } = useBillingShippingStyles();
  const [shippingTabSelected, setShippingTabSelected] = useState(0);
  const { shippingAddressSelected, shippingAddresses, teamUUID, validated } =
    useSelector(billingShippingSelectors.shippingTabs);
  const dispatch = useDispatch();
  const updateTeamAddresses = useUpdateAddresses(setShippingTabSelected);

  const handleShippingTabChange = (event, value) => {
    setShippingTabSelected(value);
  };

  const handleAddTeamAddressSuccess = (address) => {
    const shippingAddressSelected = serializeTeamAddress({
      address,
    });

    updateTeamAddresses();
    setShippingTabSelected(0);

    dispatch(setShippingAddressSelected(shippingAddressSelected));
  };

  useEffect(() => {
    updateTeamAddresses();
  }, [updateTeamAddresses]);

  const handleAddressChange = (address) => {
    dispatch(setShippingAddressSelected(address));
  };

  return (
    <Paper className={classes.paper}>
      <Tabs
        value={shippingTabSelected}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleShippingTabChange}
      >
        <Tab label="Add Address" />
        <Tab label="Existing Shipping Address" />
      </Tabs>
      <TabPanel value={shippingTabSelected} index={0}>
        {!!teamUUID && (
          <TeamAddressForm
            teamUUID={teamUUID}
            onAfterSuccessSubmit={handleAddTeamAddressSuccess}
          />
        )}
      </TabPanel>
      <TabPanel value={shippingTabSelected} index={1}>
        <AddressSelect
          value={shippingAddressSelected}
          onChange={handleAddressChange}
          shippingAddresses={shippingAddresses}
          validated={validated}
        />
      </TabPanel>
    </Paper>
  );
};
