import {
  NEEDS_QC_SLIDES_RESOURCE_NAME,
  SLIDES_RESOURCE_NAME,
  PROJECT_SLIDES_URL,
} from "services/resources/apiConstants";
import { baseCreateApi } from "./baseCreateApi";
import {
  BATCH_NEEDS_QC_SLIDES_UPDATE,
  NEEDS_QC_SLIDE_UPDATE,
  ORDERS_NOT_UPLOADED_SLIDE_NAMES,
  ORDERS_SLIDE_LIST_URL,
  ORDERS_SLIDE_NAMES,
  RECENTLY_CHANGED_QC_SLIDES,
  RESET_NEEDS_QC_SLIDES,
  SHARED_ORDER_SLIDE_LIST_URL,
  SHARED_SLIDES_URL,
  SLIDES_MAKE_VALID_POS_CONTROL,
  SLIDE_GROUPS_URL,
} from "constants/urls";

export const slidesApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSlides: builder.query({
      query: () => ({
        url: SLIDES_RESOURCE_NAME,
        method: "GET",
      }),
    }),
    getOnlySlidesSubmittedByUser: builder.query({
      query: () => ({
        url: `${SLIDES_RESOURCE_NAME}?ignore_team_orders=true`,
        method: "GET",
      }),
    }),
    getNeedsQCSlides: builder.query({
      query: () => ({
        url: NEEDS_QC_SLIDES_RESOURCE_NAME,
        method: "GET",
      }),
    }),
    getNeedsQCSlide: builder.query({
      query: ({ uuid }) => ({
        url: `${NEEDS_QC_SLIDES_RESOURCE_NAME}${uuid}/`,
        method: "GET",
      }),
    }),
    getRecentlyChangedSlides: builder.query({
      query: () => ({
        url: RECENTLY_CHANGED_QC_SLIDES,
        method: "GET",
      }),
    }),
    postQCSlide: builder.mutation({
      query: ({ slideUUID, postParams }) => {
        const url = NEEDS_QC_SLIDE_UPDATE.replace(":slideUUID", slideUUID);
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
    }),
    postBulkQCSlide: builder.mutation({
      query: ({ postParams }) => ({
        url: BATCH_NEEDS_QC_SLIDES_UPDATE,
        method: "POST",
        body: postParams,
      }),
    }),
    postResetBulkQCSlides: builder.mutation({
      query: ({ postParams }) => ({
        url: RESET_NEEDS_QC_SLIDES,
        method: "POST",
        body: postParams,
      }),
    }),
    getSlide: builder.query({
      query: ({ slideUUID }) => ({
        url: `${SLIDES_RESOURCE_NAME}${slideUUID}/`,
        method: "GET",
      }),
    }),
    makeSlideShareable: builder.mutation({
      query: ({ slideUUID, isShareable }) => {
        const url = `${SLIDES_RESOURCE_NAME}${slideUUID}/`;
        const postParams = {
          share_enabled: isShareable,
        };
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
    }),
    makeSlideStarred: builder.mutation({
      query: ({ slideUUID, isStarred }) => {
        const url = `${SLIDES_RESOURCE_NAME}${slideUUID}/`;
        const postParams = {
          is_starred: isStarred,
        };
        return {
          url,
          method: "POST",
          body: postParams,
        };
      },
    }),
    getSlidesByOrder: builder.query({
      query: ({ orderUUID }) => {
        const url = ORDERS_SLIDE_LIST_URL.replace(":orderUUID", orderUUID);
        return {
          url,
          method: "GET",
        };
      },
    }),
    getSlidesBySlideGroup: builder.query({
      query: ({ slideGroupUUID }) => ({
        url: `${SLIDE_GROUPS_URL}${slideGroupUUID}/`,
        method: "GET",
      }),
    }),
    getAllSlideNamesByOrder: builder.query({
      query: ({ orderUUID }) => {
        const url = ORDERS_SLIDE_NAMES.replace(":orderUUID", orderUUID);
        return {
          url,
          method: "GET",
        };
      },
    }),
    getNotUploadedSlidesByOrder: builder.query({
      query: ({ orderUUID }) => {
        const url = ORDERS_NOT_UPLOADED_SLIDE_NAMES.replace(
          ":orderUUID",
          orderUUID
        );
        return {
          url,
          method: "GET",
        };
      },
    }),
    getSlidesBySharedOrder: builder.query({
      query: ({ shareUUID }) => {
        const url = SHARED_ORDER_SLIDE_LIST_URL.replace(
          ":shareUUID",
          shareUUID
        );
        return {
          url,
          method: "GET",
        };
      },
    }),
    getSharedSlide: builder.query({
      query: ({ shareUUID }) => {
        const url = SHARED_SLIDES_URL.replace(":shareUUID", shareUUID);
        return {
          url,
          method: "GET",
        };
      },
    }),
    makeSlideValidPosControl: builder.mutation({
      query: ({ slideUUID }) => {
        const url = SLIDES_MAKE_VALID_POS_CONTROL.replace(
          ":slideUUID",
          slideUUID
        );
        return {
          url,
          method: "POST",
        };
      },
    }),
    getSlidesByProject: builder.query({
      query: ({ projectUUID }) => {
        const url = PROJECT_SLIDES_URL.replace(":projectUUID", projectUUID);
        return {
          url,
          method: "GET",
        };
      },
    }),
    getSlideMLData: builder.query({
      query: ({ slideUUID, model }) => {
        const url = `${SLIDES_RESOURCE_NAME}${slideUUID}/slide_ml_data/`;
        return {
          url,
          method: "GET",
          params: { model },
        };
      },
    }),
    runSlideAIProcessing: builder.mutation({
      query: ({ slideUUID }) => ({
        url: `${SLIDES_RESOURCE_NAME}${slideUUID}/run_slide_ml_processing/`,
        method: "POST",
      }),
      transformResponse: (response, meta) => {
        return {
          data: response,
          status: meta.response.status,
        };
      },
    }),
  }),
});

export const {
  useLazyGetAllSlidesQuery,
  useLazyGetOnlySlidesSubmittedByUserQuery,
  useLazyGetNeedsQCSlidesQuery,
  useLazyGetNeedsQCSlideQuery,
  useLazyGetRecentlyChangedSlidesQuery,
  usePostQCSlideMutation,
  usePostBulkQCSlideMutation,
  usePostResetBulkQCSlidesMutation,
  useLazyGetSlideQuery,
  useGetSlideQuery,
  useMakeSlideShareableMutation,
  useMakeSlideStarredMutation,
  useLazyGetSlidesByOrderQuery,
  useGetSlidesBySlideGroupQuery,
  useGetAllSlideNamesByOrderQuery,
  useLazyGetNotUploadedSlidesByOrderQuery,
  useLazyGetSlidesBySharedOrderQuery,
  useLazyGetSharedSlideQuery,
  useMakeSlideValidPosControlMutation,
  useLazyGetSlidesByProjectQuery,
  useLazyGetSlideMLDataQuery,
  useRunSlideAIProcessingMutation,
} = slidesApi;
