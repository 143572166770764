import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = [];

const slidesSlice = createSlice({
  name: "slides",
  initialState,
  reducers: {
    setSlides: (state, { payload }) => payload,
    updateSlide: (state, { payload }) => {
      return state.map((slide) =>
        slide.uuid === payload.uuid ? { ...slide, ...payload } : slide
      );
    },
    removeSlideAttribute: (state, { payload: { uuid, attribute } }) => {
      return state.map((slide) => {
        if (slide.uuid === uuid) {
          const updatedSlide = { ...slide };
          delete updatedSlide[attribute];
          return updatedSlide;
        }
        return slide;
      });
    },
  },
});

export const { setSlides, updateSlide, removeSlideAttribute } =
  slidesSlice.actions;

export const slidesReducer = slidesSlice.reducer;

export const slidesSelector = createSelector(
  (state) => state,
  (state) => state.slides
);
