import { makeStyles } from "tss-react/mui";
import React from "react";
import { Typography } from "@mui/material";

const styles = {
  slideInformation: {
    marginTop: "0rem",
    marginBottom: "-1rem",
  },
};

const useStyles = makeStyles()(styles);

export const SlideCommentHeader = React.memo(({ slideCommentsLength }) => {
  const { classes } = useStyles();

  const countLabel = slideCommentsLength ? `(${slideCommentsLength})` : "";

  return (
    <Typography
      variant={"h6"}
      className={classes.slideInformation}
      color={"primary"}
    >
      Slide Comments {countLabel}
    </Typography>
  );
});
