import { makeStyles } from "tss-react/mui";

import { FORM_GREY, PRIMARY_WHITE, SECONDARY_WHITE } from "utilities/colors";

export const useAllSlidesCommentsStyles = makeStyles()((theme) => ({
  root: {
    height: "calc(100% - 64px)",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "8px",
    flexWrap: "wrap",
    gap: "10px 0",
  },
  images: {
    display: "flex",
    flexDirection: "row",
  },
  thumbnailImageContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  thumbnailImage: {
    width: "100%",
    objectFit: "contain",

    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },
  slideCardsContainer: {
    width: "100%",
    backgroundColor: PRIMARY_WHITE,
    padding: "16px",
  },
  slideCard: {
    width: "100%",
    height: "min-content",
    padding: "16px",
    marginBottom: "16px",
    backgroundColor: SECONDARY_WHITE,
  },
  slideName: {
    fontSize: "20px",
    marginBottom: "8px",
    fontWeight: "500",
  },
  floatingPaper: {
    backgroundColor: "transparent",
  },
  floatingImage: {
    maxHeight: "200px",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  comments: {
    flexDirection: "column",
    width: "100%",
    padding: "16px",
  },
}));

export const useSlideCommentStyles = makeStyles()({
  deleteButton: {
    cursor: "pointer",
    width: "0.75rem",
    height: "0.75rem",
    color: FORM_GREY,
  },
  commentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  wrapper: {
    borderTop: `1px solid ${FORM_GREY}`,
    padding: "0.5rem 0.5rem 1rem",

    "& svg": {
      display: "none",
    },

    "&:hover svg": {
      display: "inline",
    },
  },
  label: {
    color: FORM_GREY,
    fontWeight: 600,
    fontSize: "0.75rem",
  },
  text: {
    color: FORM_GREY,
    fontSize: "0.875rem",
  },
});

export const useSlideCommentComponentStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  slideComment: {
    padding: "1rem",
    margin: "1rem",
  },
  commentTextContainer: {
    width: "calc(100% - 400px)",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  slideImageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
    width: "50%",
  },
  singleImageWrapper: {
    height: "400px",
    width: "400px",
  },
  slideImage: {
    cursor: "pointer",
  },
  commentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  imagesWrapper: {
    cursor: "pointer",
    height: "400px",
    width: "400px",
    backgroundColor: "#fafafa",
    maxWidth: "100%",
  },
  backButton: {
    marginRight: "1rem",
  },
  slideImagePreview: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
}));
