import React, { useCallback, useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { getUserNewsfeedActivity } from "services/resources/commonResources";
import { useAllSlidesCommentsStyles } from "components/AllSlidesComments/styles";
import { serializeSlideComments } from "components/AllSlidesComments/utilities";
import { useTitle } from "components/utilities/hooks/useTitle";
import { SlideAndSlideCommentsComponent } from "components/AllSlidesComments/SlideAndSlideCommentsComponent";
import { FETCH_NEWSFEED_ACTIVITY_ERROR } from "constants/errorMessages";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { pageWithHeaderSpinnerThemeStyle } from "components/Loading/Spinner/SpinnerStyles";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "store/slices/userDetailsSlice";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useDeleteSlideCommentMutation } from "store/apis/slideCommentsApi";

export const AllSlidesCommentsPage = () => {
  const { classes } = useAllSlidesCommentsStyles();
  useTitle("HistoWiz - Slide Comments");
  const { isStaff } = useSelector(userDetailsSelector);
  const { showSuccess, showError } = useSnackbar();
  const [comments, setComments] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(null);

  const [deleteSlideComment] = useDeleteSlideCommentMutation();

  const _fetchComments = () => {
    setIsLoading(true);
    return getUserNewsfeedActivity()
      .then((response) => {
        const slidesComments = response.order_slide_comments;
        const serializedSlideComments = serializeSlideComments(slidesComments);
        setComments(serializedSlideComments);
      })
      .catch(() => showError(FETCH_NEWSFEED_ACTIVITY_ERROR))
      .finally(() => setIsLoading(false));
  };

  const fetchComments = useCallback(() => _fetchComments(), []);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleDelete = useCallback(
    (commentToDelete) => {
      if (!commentToDelete) {
        return null;
      }

      const slideUUID = commentToDelete.slide.uuid;

      deleteSlideComment({
        slideUUID: commentToDelete.slide.uuid,
        commentUUID: commentToDelete.uuid,
      })
        .unwrap()
        .then(() => {
          setComments((comments) => {
            const allComments = { ...comments };
            const slideComments = allComments[slideUUID];

            allComments[slideUUID] = slideComments.filter(
              (slideComment) => commentToDelete.uuid !== slideComment.uuid
            );
            return { ...allComments };
          });

          showSuccess("Comment was deleted");
        })
        .catch(() => showError("Failed to delete comment"));
    },
    [setComments]
  );

  const addSlideComment = useCallback(
    (slideComments) => {
      const allComments = { ...comments };
      allComments[selectedSlide.uuid] = [...slideComments];
      setComments({ ...allComments });
    },
    [comments, setComments, selectedSlide]
  );
  //we prevent displaying internal comments for non-stuff users
  const filteredOrderComments = isStaff
    ? Object.values(comments)
    : Object.values(comments)
        .map((orderComments) =>
          orderComments.filter((comment) => !comment.is_internal)
        )
        .filter((filteredComments) => filteredComments.length);

  const newestSlidesCommentsSortedByDate = filteredOrderComments
    .map((comment, index) => {
      const slideCommentsSortedByDate = comment.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });

      return { index: index, created: slideCommentsSortedByDate[0].created };
    })
    .sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });

  const slidesSortedByTheLastCommentDate = newestSlidesCommentsSortedByDate.map(
    (newestSlideComment) => filteredOrderComments[newestSlideComment.index]
  );

  const renderSlideCommentsComponent = (slideComments) => {
    const slide = slideComments[0]?.slide;

    return (
      <Paper key={slide.uuid} className={classes.slideCard} elevation={9}>
        <SlideAndSlideCommentsComponent
          slideComments={slideComments}
          onSlideSelect={setSelectedSlide}
          onDelete={handleDelete}
          onCreate={addSlideComment}
        />
      </Paper>
    );
  };

  return (
    <MiniDrawerWithContext header={"Recent Slide Comments (All Orders)"}>
      {isLoading ? (
        <div style={pageWithHeaderSpinnerThemeStyle}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.slideCardsContainer}>
            {slidesSortedByTheLastCommentDate.map(renderSlideCommentsComponent)}
          </div>
        </div>
      )}
    </MiniDrawerWithContext>
  );
};
