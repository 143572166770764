import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { SLIDE_VIEWER_URL } from "constants/urls";
import { servicesOptionsMapping } from "components/OrderForm/constants";
import { useSlideNavigatorStyles } from "components/SlideNavigator/styles";

const AttributeLabel = ({ label, value }) => {
  const { classes } = useSlideNavigatorStyles();
  if (!value) {
    return null;
  }

  return (
    <div className={classes.marginBottomStyle}>
      <Typography className={classes.inlineBlockBoldStyle}> {label}</Typography>{" "}
      <Typography className={classes.inlineBlockStyle}>{value}</Typography>
    </div>
  );
};

export const SlideCard = ({ slide, isBrief }) => {
  const { classes } = useSlideNavigatorStyles();
  const { sample } = slide;

  const url = SLIDE_VIEWER_URL.replace(":slideUUID", slide.uuid);
  const organLabel = sample?.organ?.name || "";
  const speciesLabel = sample?.species?.name || "";
  const stainType = servicesOptionsMapping[slide.stain_type];
  const stainName = slide.stain || "";

  return (
    <Grid item>
      <Paper className={`${classes.paper} ${isBrief && classes.isBrief}`}>
        <Grid item className={classes.imageWrapper}>
          <Link to={url}>
            <img
              className={classes.image}
              //@todo: change to slide.large_macro_url after backend merged
              // src={slide.medium_thumbnail_url}
              src={
                process.env.REACT_APP_API_URL +
                "/slides/" +
                slide.uuid +
                "/medium_thumbnail.jpg"
              }
              width="100%"
              alt={"Macro"}
              loading="lazy"
            />
          </Link>
        </Grid>
        {!isBrief && (
          <Grid item className={classes.textWrapper}>
            <AttributeLabel label={"Name"} value={slide.name} />
            <AttributeLabel label={"Organ"} value={organLabel} />
            <AttributeLabel label={"Species"} value={speciesLabel} />
            <AttributeLabel label={"Stain"} value={stainType} />
            <AttributeLabel label={"Stain Name"} value={stainName} />
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
