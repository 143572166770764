import { DefaultTabWrapper } from "components/OrderDetails/Tabs/utilities";
import { SampleDetails } from "components/SamplesList/SampleDetails";
import React from "react";

export const SampleDetailsTab = (props) => {
  const { samples } = props;

  return (
    <DefaultTabWrapper>
      <SampleDetails samples={samples} />
    </DefaultTabWrapper>
  );
};
