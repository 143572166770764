import { useEffect } from "react";
import shallow from "zustand/shallow";
import _ from "lodash";
import { unstable_batchedUpdates as unstableBatchedUpdates } from "react-dom";

import {
  useChannelsStore,
  useImageSettingsStore,
  useLoader,
  useMetadata,
  useViewerStore,
} from "./state";
import {
  buildDefaultSelection,
  getMultiSelectionStats,
  getBoundingCube,
  generateRandomColor,
} from "./utils";
import {
  COLORS_MAP,
  DEFAULT_ERROR_MESSAGE,
  FILL_PIXEL_VALUE,
  IF_VIEWER_COLORS_INTENSITY,
  SAMPLE_AF,
} from "./constants";
import { loadOmeTiff } from "@hms-dbmi/viv";
import { getFromLS } from "components/OrderSlidesList/utilities";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const useImage = (source, slideUUID) => {
  const [use3d, toggleUse3d, brightnessCutOff, customChannelOptions] =
    useViewerStore(
      (store) => [
        store.use3d,
        store.toggleUse3d,
        store.brightnessCutOff,
        store.customChannelOptions,
      ],
      shallow
    );

  const loader = useLoader();
  const metadata = useMetadata();
  const { showError } = useSnackbar();
  useEffect(() => {
    if (!source) return;
    async function changeLoader() {
      useViewerStore.setState({ isChannelLoading: [true] });
      useViewerStore.setState({ isViewerLoading: true });
      if (use3d) toggleUse3d();
      const { url } = source;
      let newLoader;
      try {
        newLoader = await loadOmeTiff(url, {
          images: "all",
        });
      } catch (e) {
        useViewerStore.setState({ loaderError: true });

        return showError(DEFAULT_ERROR_MESSAGE);
      }
      let nextMeta;
      let nextLoader;
      if (Array.isArray(newLoader)) {
        if (newLoader.length > 1) {
          nextMeta = newLoader.map((l) => l.metadata);
          nextLoader = newLoader.map((l) => l.data);
        } else {
          nextMeta = newLoader[0].metadata;
          nextLoader = newLoader[0].data;
        }
      } else {
        nextMeta = newLoader.metadata;
        nextLoader = newLoader.data;
      }
      if (nextLoader) {
        unstableBatchedUpdates(() => {
          useChannelsStore.setState({ loader: nextLoader });
          useViewerStore.setState({
            metadata: nextMeta,
          });
        });
        if (use3d) toggleUse3d();
      }
    }
    changeLoader();
  }, [source]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!metadata) return;

    const changeSettings = async () => {
      // Placeholder
      useViewerStore.setState({ isChannelLoading: [true] });
      useViewerStore.setState({ isViewerLoading: true });
      if (use3d) toggleUse3d();

      const { Channels } = metadata.Pixels;
      const channelOptions = Channels.map((c, i) => c.Name ?? `Channel ${i}`);
      const newSelections = buildDefaultSelection(
        loader[0],
        customChannelOptions,
        channelOptions,
        slideUUID
      );
      let newContrastLimits = [];
      let newDomains = [];

      let newColors = channelOptions.map((c) => {
        if (!COLORS_MAP[c]) {
          COLORS_MAP[c] = generateRandomColor();
        }

        return COLORS_MAP[c];
      });

      const stats = await getMultiSelectionStats({
        loader,
        selections: newSelections,
        use3d: false,
        brightnessCutOff,
      });
      newDomains = stats.domains;
      newContrastLimits = stats.contrastLimits;
      // If there is only one channel, use white.
      useViewerStore.setState({
        useLens: channelOptions.length !== 1,
        useColormap: true,
      });

      const colorsLockedFromLS = getFromLS(IF_VIEWER_COLORS_INTENSITY);

      const savedColorsLocked = Object.values(colorsLockedFromLS ?? {});

      const colorsLocked = newColors.map((color) => {
        const savedColor =
          savedColorsLocked.find((c) => _.isEqual(c.color, color)) ?? {};

        return {
          color,
          colorLocked: !!savedColor.colorLocked || false,
          contrastLimitsLocked: savedColor.contrastLimitsLocked,
        };
      });

      const contrastLimits = newContrastLimits.map((c, i) => {
        const savedColor =
          colorsLocked.find((c) => _.isEqual(c.color, newColors[i])) ?? {};
        return savedColor.contrastLimitsLocked ?? c;
      });
      useChannelsStore.setState({
        ids: newDomains.map(() => String(Math.random())),
        selections: newSelections,
        domains: newDomains,
        contrastLimits,
        colors: newColors,
        colorsLocked,
        channelsVisible: newColors.map(
          (color) => !(COLORS_MAP[SAMPLE_AF] === color)
        ),
      });
      useViewerStore.setState({
        isChannelLoading: newSelections.map((i) => !i),
        isViewerLoading: false,
        pixelValues: new Array(newSelections.length).fill(FILL_PIXEL_VALUE),
        // Set the global selections (needed for the UI). All selections have the same global selection.
        globalSelection: newSelections[0],
        channelOptions,
      });
      const [xSlice, ySlice, zSlice] = getBoundingCube(loader);
      useImageSettingsStore.setState({
        xSlice,
        ySlice,
        zSlice,
      });
    };
    changeSettings();
  }, [loader, metadata]); // eslint-disable-line react-hooks/exhaustive-deps
};
