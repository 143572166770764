import { makeStyles } from "tss-react/mui";
import { getWhiteLabelSignupBackground } from "utilities/whitelabel";

const signupBackground = getWhiteLabelSignupBackground();

export const useLoginPageStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formDiv: {
    width: "100%",
  },
  paperContainer: {
    backgroundImage: `url(${signupBackground})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
  },
  loginContainer: {
    width: "100%",
    marginTop: "1rem",
  },
  loginHeader: {
    position: "relative",
    top: "-2rem",
    width: "80%",
    margin: "auto",
    backgroundColor: `${theme.palette.secondary.main} !important`,
    paddingTop: "2rem",
    paddingBottom: "2rem",
    color: "white !important",
  },
  input: {
    marginLeft: "auto",
    marginTop: "2rem",
    marginRight: "auto",
    width: "80%",
    justifyContent: "center",
  },
  signinButton: {
    marginLeft: "auto",
    marginTop: "2rem",
    marginRight: "auto",
    marginBottom: "2rem",
    width: "80%",
  },
  signInRightButton: {
    marginLeft: ".5rem !important",
  },
  signUpLink: {
    textDecoration: "none",
    color: "black",
    marginRight: "0.5rem",
  },
  resetPasswordLink: {
    textDecoration: "none",
    color: "black",
  },
  fullHeight: {
    height: "100%",
  },
}));
