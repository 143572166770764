import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { getTeams } from "services/resources/commonResources";
import {
  Autocomplete,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { getInvites, postInvite } from "services/resources/invite";
import { CustomTableCell } from "components/OrdersList/constants";
import moment from "moment";
import { DATE_REQUEST_FORMAT } from "utilities/dates_and_times";
import { Controller, useForm } from "react-hook-form";
import { useUnmountIgnore } from "utilities/useUnmountIgnore";
import { yupResolver } from "@hookform/resolvers/yup";
import { EMAIL_ALREADY_SENT_MESSAGE } from "components/TeamUserInvites/constants";
import { FETCH_TEAMS_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { validationSchema } from "components/TeamUserInvites/validation";
import { useTeamComponentStyles } from "components/TeamUserInvites/styles";

export const InvitedTableHeader = (
  <TableHead>
    <TableRow>
      <CustomTableCell>Email</CustomTableCell>
      <CustomTableCell>Team</CustomTableCell>
      <CustomTableCell>Sent On</CustomTableCell>
      <CustomTableCell>Status</CustomTableCell>
    </TableRow>
  </TableHead>
);

const InvitedTableRow = ({ row }) => {
  return (
    <TableRow>
      <CustomTableCell component="th" scope="row">
        {row.email}
      </CustomTableCell>
      <CustomTableCell component="th" scope="row">
        {row.team.name}
      </CustomTableCell>
      <CustomTableCell component="th" scope="row">
        {moment(row.created).format(DATE_REQUEST_FORMAT)}
      </CustomTableCell>
      <CustomTableCell component="th" scope="row">
        Sent
      </CustomTableCell>
    </TableRow>
  );
};

export const InvitedTable = ({ invitedUsers }) => {
  return (
    <Table>
      {InvitedTableHeader}
      <TableBody>
        {invitedUsers.map((row) => {
          return <InvitedTableRow row={row} key={row.uuid} />;
        })}
      </TableBody>
    </Table>
  );
};

export const TeamUserInvitesComponent = () => {
  const { classes } = useTeamComponentStyles();
  const [teams, setTeams] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const unmountIgnore = useUnmountIgnore();
  const { showWarning, showError } = useSnackbar();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "all", resolver: yupResolver(validationSchema) });

  const handleTeamSelection = useCallback(
    (team) => {
      setSelectedTeam(team);
      setValue("teamUUID", team?.uuid);
    },
    [setSelectedTeam, setValue]
  );

  useEffect(() => {
    getInvites().then((response) => {
      if (!unmountIgnore.current) {
        setInvitedUsers(response);
      }
    });
  }, [unmountIgnore]);

  useEffect(() => {
    getTeams()
      .then((response) => {
        const { data } = response;

        if (!unmountIgnore.current) {
          setTeams([...data]);

          data.length === 1 && handleTeamSelection(data[0]);
        }
      })
      .catch(() => showError(FETCH_TEAMS_ERROR_MESSAGE));
  }, [unmountIgnore, handleTeamSelection]);

  const onSubmit = (values) => {
    if (invitedUsers.some(({ email }) => email === values.email)) {
      return showWarning(EMAIL_ALREADY_SENT_MESSAGE);
    }

    postInvite(values)
      .then((response) => {
        setInvitedUsers((invitedUsers) => [response.data, ...invitedUsers]);
      })
      .catch((error) => {
        const { response = {}, message } = error;
        showError(response.data?.email || response.data || message);
      });
  };

  return (
    <>
      <Typography variant={"h4"} className={classes.intro}>
        Invite New User to Team
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.emailInput}>
          <TextField
            className={classes.inputForm}
            error={!!errors.email?.message}
            label="Email ..."
            {...register("email")}
            type="email"
          />
          {errors.email?.message && (
            <span className={classes.errorMessage} role="alert">
              {errors.email.message}
            </span>
          )}
        </div>
        <Controller
          control={control}
          name="teamUUID"
          render={({ field: { onBlur, ref } }) => (
            <Autocomplete
              disableClearable
              options={teams}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={ref}
                  label="Teams"
                  margin="normal"
                />
              )}
              onBlur={onBlur}
              className={classes.inputForm}
              value={selectedTeam}
              onChange={(e, value) => {
                handleTeamSelection(value);
              }}
            />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          size={"large"}
          type="submit"
          disabled={!isValid}
        >
          Invite
        </Button>
      </form>

      <div className={classes.inviteTable}>
        <InvitedTable invitedUsers={invitedUsers} />
      </div>
    </>
  );
};
