import { Fragment, useState } from "react";
import { finishedConsultationStates } from "components/PathologyConsultations/constants";
import { ConsultationActionsModal } from "components/PathologyConsultations/ConsultationActionsModal/ConsultationActionsModal";
import { Button } from "@mui/material";
import { buttonStyle } from "components/PathologyConsultations/ConsultationActionsModal/styles";
import {
  canRenderFinishRequest,
  canRenderPreviousRequest,
  canRenderReviewRequest,
  canRenderStartRequest,
} from "components/PathologyConsultations/utilities";

export const useConsultationActions = ({ data }) => {
  const [isConsultationActionModalOpen, setIsConsultationActionModalOpen] =
    useState(false);
  const consultation = data;
  if (!consultation) {
    return null;
  }

  const renderActionsModal = () => {
    if (!isConsultationActionModalOpen) {
      return null;
    }

    return (
      <ConsultationActionsModal
        consultation={consultation}
        onClose={handleConsultationModalClose}
        open={isConsultationActionModalOpen}
      />
    );
  };

  const onActionRequestClick = () => {
    setIsConsultationActionModalOpen(
      (isConsultationActionModalOpen) => !isConsultationActionModalOpen
    );
  };

  const handleConsultationModalClose = () => {
    setIsConsultationActionModalOpen(false);
  };

  if (finishedConsultationStates.includes(consultation.state)) {
    // if they don't have any real actions they can perform
    // at least let them see the current thing

    return (
      <>
        <Button
          style={buttonStyle}
          size="small"
          variant="contained"
          disableElevation
          color="secondary"
          onClick={onActionRequestClick}
        >
          Review Finished
          {renderActionsModal()}
        </Button>
      </>
    );
  }

  if (canRenderReviewRequest({ consultation })) {
    return (
      <>
        <Button
          style={buttonStyle}
          size="small"
          variant="contained"
          disableElevation
          color="secondary"
          onClick={onActionRequestClick}
        >
          🔬 Review Request
          {renderActionsModal()}
        </Button>
      </>
    );
  }

  if (canRenderStartRequest({ consultation })) {
    return (
      <Fragment>
        <Button
          onClick={onActionRequestClick}
          style={buttonStyle}
          size="small"
          variant="contained"
          disableElevation
          color="primary"
        >
          🟢 Start Consultation
        </Button>
        {renderActionsModal()}
      </Fragment>
    );
  }

  if (canRenderFinishRequest({ consultation })) {
    return (
      <Fragment>
        <Button
          style={buttonStyle}
          size="small"
          variant="contained"
          disableElevation
          color="secondary"
          onClick={onActionRequestClick}
        >
          ✅ View Slides
        </Button>
        {renderActionsModal()}
      </Fragment>
    );
  }

  // it's helpful to at least always be able to see the path consult order
  if (canRenderPreviousRequest({ consultation })) {
    return (
      <Fragment>
        <Button
          style={buttonStyle}
          size="small"
          variant="contained"
          disableElevation
          color="primary"
          onClick={onActionRequestClick}
        >
          Review
        </Button>
        {renderActionsModal()}
      </Fragment>
    );
  }

  return <span />;
};
