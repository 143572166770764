export const columnDefs = [
  {
    field: "submittedIn",
    headerName: "Submission Type",
    filter: true,
  },
  {
    field: "organ",
    headerName: "Organ",
    filter: true,
  },
  {
    field: "id",
    headerName: "ID",
    filter: true,
  },
  {
    field: "controlType",
    headerName: "Control Type",
    filter: true,
  },
  {
    field: "submittedAt",
    headerName: "Submitted At",
    filter: true,
    sortable: true,
    comparator: (date1, date2) => {
      const date1Obj = new Date(date1);
      const date2Obj = new Date(date2);
      if (date1Obj < date2Obj) return -1;

      if (date1Obj > date2Obj) return 1;

      return 0;
    },
  },
];

export const defaultColDef = {
  flex: 1,
  minWidth: 140,
  filter: true,
  sortable: true,
};

export const autoGroupColumnDef = {
  headerName: "Project/Order/Name",
  minWidth: 290,
  cellRendererParams: {
    suppressCount: true,
    checkbox: true,
  },
};
