import { Button, Grid, Box } from "@mui/material";
import { SelectedServiceWarning } from "components/OrderForm/SamplesFormV2/components/SelectedServiceWarning";
import ClearIcon from "@mui/icons-material/Clear";
import CopyIcon from "@mui/icons-material/FileCopy";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { ExcelActionButtons } from "components/SampleEditor/components/ExcelActionButtons";
import { getUpdatedArrWithEmptyLines } from "components/OrderForm/SamplesFormV2/utilities";
import { memo } from "react";
import { useDeleteSamplesMutation } from "store/apis/samplesApi";
import { DELETE_ORDER_ERROR_MESSAGE } from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

const ActionButtonsPanelComponent = ({
  classes,
  showSelectedServiceWarning,
  toggleCreateAntibodyModal,
  disableLeftButtons,
  disableRightButtons,
  toggleCreatePanelModal,
  handleSetAddPreviousSamplesModal,
  materialsAtHistowiz,
  gridAPI,
  setSelectedRows,
  checkAllRowsAreValid,
  orderUUID,
  getSamplesData,
  setRows,
  orderName,
}) => {
  const [deleteSamples] = useDeleteSamplesMutation();
  const { showError } = useSnackbar();

  const tutorialAction = () => {
    window.open(
      "https://app.tango.us/app/workflow/64484b24-39ae-4fec-998a-e91772a9a25b"
    );
  };
  const deselectHandler = () => {
    const selectedRows = gridAPI.getSelectedRows();
    setSelectedRows([]);
    gridAPI.deselectAll();
    gridAPI.redrawRows(selectedRows);
  };

  const deleteSelectedRows = () => {
    if (!gridAPI) return false;

    const selectedRows = gridAPI.getSelectedRows();

    // if it contains a UUID, this record exists on the backend and we should delete
    const deletedRowsAlreadySaved = selectedRows
      .filter((response) => !!response["uuid"])
      .map(({ uuid }) => uuid);

    gridAPI.applyTransaction({ remove: selectedRows });

    deleteSamples({ orderUUID, sample_uuids: deletedRowsAlreadySaved })
      .then(() => checkAllRowsAreValid())
      .catch(() => showError(DELETE_ORDER_ERROR_MESSAGE));
    deselectHandler();

    return true;
  };

  const handleDuplicateRows = () => {
    const rowsToDuplicate = gridAPI.getSelectedRows();

    const duplicateRowsCopy = rowsToDuplicate.map((row) => {
      let newRow = Object.assign({}, row);
      newRow["uuid"] = null;
      newRow["name"] = "";
      return newRow;
    });

    setRows((prev) => {
      return getUpdatedArrWithEmptyLines(prev, duplicateRowsCopy);
    });

    checkAllRowsAreValid();
    deselectHandler();
  };

  return (
    <Grid container justifyContent="space-between" alignItems="flex-end" pt={2}>
      {showSelectedServiceWarning && (
        <SelectedServiceWarning classes={classes} />
      )}
      <Grid item>
        <Button
          onClick={deleteSelectedRows}
          className={classes.button}
          variant="contained"
          color={"secondary"}
          size={"small"}
          disabled={disableLeftButtons}
          startIcon={<ClearIcon />}
        >
          Delete
        </Button>
        <Button
          onClick={handleDuplicateRows}
          className={classes.button}
          variant="contained"
          color={"secondary"}
          size={"small"}
          disabled={disableLeftButtons}
          startIcon={<CopyIcon />}
        >
          Duplicate
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color={"primary"}
          onClick={tutorialAction}
          startIcon={<HelpOutline />}
        >
          Tutorial
        </Button>
      </Grid>
      <Grid item className={classes.rightGridAlign}>
        <Button
          className={classes.button}
          onClick={toggleCreateAntibodyModal}
          variant="contained"
          color={"secondary"}
          disabled={disableRightButtons}
        >
          Add New Antibody
        </Button>
        <Button
          className={classes.button}
          onClick={toggleCreatePanelModal}
          variant="contained"
          color={"secondary"}
          disabled={disableRightButtons}
        >
          Add New Panel
        </Button>
        {materialsAtHistowiz && (
          <Button
            className={classes.button}
            onClick={handleSetAddPreviousSamplesModal}
            variant="contained"
            color={"secondary"}
            disabled={disableRightButtons}
          >
            Add Samples from order
          </Button>
        )}
        <Box>
          <ExcelActionButtons
            disabled={disableRightButtons}
            setSelectedRows={setSelectedRows}
            refetchHandler={getSamplesData}
            gridAPI={gridAPI}
            fileName={`${orderName}-HistoWiz-Order-Form-Samples-Details`}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const ActionButtonsPanel = memo(ActionButtonsPanelComponent);
