import React, { useContext } from "react";

import { useTitle } from "components/utilities/hooks/useTitle";
import { SignupForm } from "components/SignupForm/SignupForm";
import { registerUser } from "services/resources/auth";
import { updateUserDetails } from "store/slices/userDetailsSlice";
import { SUCCESS_SIGNUP_URL } from "constants/urls";
import { ERROR_PROCESSING_SIGNUP_MESSAGE } from "components/SignupForm/constants";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppContext } from "services/context";
import { useSignupPageStyles } from "components/pages/styles";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

export const SignupPage = () => {
  useTitle("HistoWiz - Register");
  const { classes } = useSignupPageStyles();
  const { showError } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(AppContext);

  const onSubmit = (postParams) => {
    return registerUser(postParams)
      .then((response) => {
        context.updateIsLoggedIn();
        dispatch(updateUserDetails(response));
        history.replace(SUCCESS_SIGNUP_URL);
      })
      .catch(function (error) {
        let errorMessage = "",
          data = error.response?.data;
        if (data) {
          const keys = Object.keys(data);
          if (keys.length > 1) {
            keys.forEach((key) => {
              errorMessage += `${key}: ${data[key]}. `;
            });
          } else {
            errorMessage = `${keys}: ${data[keys]}`;
          }
        }
        showError(errorMessage || ERROR_PROCESSING_SIGNUP_MESSAGE);
      });
  };

  return <SignupForm classes={classes} onSubmitAction={onSubmit} />;
};
