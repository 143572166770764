import { SvgIcon } from "@mui/material";
import React from "react";

export const RectangleIcon = () => {
  return (
    <SvgIcon viewBox="0 0 70 50">
      <g>
        <rect x="12" y="10" width="46" height="30" />
        <g>
          <circle cx="12" cy="10" r="5" />
          <circle cx="58" cy="10" r="5" />
          <circle cx="12" cy="40" r="5" />
          <circle cx="58" cy="40" r="5" />
        </g>
      </g>
    </SvgIcon>
  );
};
