import React from "react";
import { BaseOrderDropdownSelections } from "./BaseSelectDropdowns";

const clonalityOptions = [
  { value: "Monoclonal", label: "Monoclonal" },
  { value: "Polyclonal", label: "Polyclonal" },
  { value: "", label: "Unknown" },
];

export const ClonalitySelection = (props) => {
  const { name } = props;

  return (
    <BaseOrderDropdownSelections
      name={name}
      title={"Clonality type"}
      options={clonalityOptions}
      closeMenuOnSelect={true}
    />
  );
};
