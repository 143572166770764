export const RulerIcon = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <rect
        opacity="0.6"
        x="0.774902"
        width="20"
        height="18.0625"
        fill="url(#rulerIcon)"
      />
      <defs>
        <pattern
          id="rulerIcon"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_731_38"
            transform="matrix(0.0301042 0 0 0.0333333 0.0484375 0)"
          />
        </pattern>
        <image
          id="image0_731_38"
          width="30"
          height="30"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWElEQVR4nO2USQoAIRAD8zD9/DS+K568iYjYbpOCnAvSCyAOIQFgJeYtZiMSuxBWV12ImjFePaeClotwhr97mUcuV9hVNTtju8RDY6HEeL1qmyD9RsTifjK4B9uhSMgkDQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};
