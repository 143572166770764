import { Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { ProgressItemCard } from "components/OrderDetails/Tabs/OrderProgressTab/ProgressItemCard";
import logoHighResolutionTile from "images/logos/logoHighResolutionTile.png";
import { ORDER_FULFILLMENT_TIME_EXPLANATIONS } from "components/OrderDetails/Tabs/OrderProgressTab/constants";
import { v4 as uuid } from "uuid";
import { useFulfillmentTimeExplainedStyles } from "components/OrderDetails/Tabs/OrderProgressTab/styles";

export const FulfillmentTimeExplained = () => {
  const { classes } = useFulfillmentTimeExplainedStyles();

  return (
    <ProgressItemCard label={"Our Fulfillment Time Explained"}>
      <Box display={"flex"} flexDirection={"column"}>
        {ORDER_FULFILLMENT_TIME_EXPLANATIONS.map(
          (orderFulfillmentTimeExplanation) => (
            <Box
              key={uuid()}
              display={"flex"}
              flexDirection={"row"}
              className={classes.timeExplainedPoint}
            >
              <img
                className={classes.logo}
                src={logoHighResolutionTile}
                alt="HistoWiz Logo"
              />
              <Typography className={classes.text}>
                {orderFulfillmentTimeExplanation}
              </Typography>
            </Box>
          )
        )}
      </Box>
    </ProgressItemCard>
  );
};
