import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { MiniDrawerWithContext } from "components/Layout/drawer";
import { AgGridReact } from "ag-grid-react";
import { Box, Pagination, Paper, Typography } from "@mui/material";
import _ from "lodash";

import { RESPONSIVE_GRID } from "components/Projects/helpers/constants";
import { PAGE_PARAM } from "components/SampleEditor/helpers/constants";
import {
  useCollapse,
  useSamplesForEditing,
  useGetUrlParam,
  useSamplesTools,
  useGetSampleColumns,
} from "components/SampleEditor/helpers/hooks";
import { convertSampleObjectToUpdate } from "components/SampleEditor/helpers/functions";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";
import { SamplesTableHeader } from "components/SampleEditor/SamplesTableHeader";
import { useSamplesEditorStyles } from "components/SampleEditor/styles";
import { EXPORT_EXCEL_STYLES } from "components/OrderForm/SamplesFormV2/constants";
import { getSamplesColumns } from "components/SampleEditor/helpers/cellRenders";
import { Toolbar } from "components/SampleEditor/Toolbar";
import InfoIcon from "@mui/icons-material/Info";

export const SampleEditor = () => {
  const history = useHistory();
  const currentPage = useGetUrlParam(PAGE_PARAM, 1);

  const { search } = useLocation();
  const { classes } = useSamplesEditorStyles();
  const { species, organs, isLoadingTools } = useSamplesTools();

  const {
    items: allSamples,
    isLoading,
    setSamples,
    initialSamples,
    totalPages,
    refetchSamples,
  } = useSamplesForEditing();

  const [samplesToUpdate, setSamplesToUpdate] = useState([]);
  const [exportSamplesIds, setExportSamplesIds] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  //necessary to redirect from the modal 'go anyway'
  const [nextPage, setNextPage] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  const {
    filteredSamplesList,
    collapsedOrdersList,
    collapsedProjectsList,
    setCollapsedOrdersList,
    setCollapsedProjectsList,
    setFilteredSamplesList,
    buildCollapseStateHandler,
  } = useCollapse(allSamples, initialSamples);

  const checkboxHandler = (node) => {
    if (!gridApi) return;
    const { selected, rowIndex } = node;

    gridApi.getDisplayedRowAtIndex(rowIndex).selectThisNode(!selected);
    gridApi.redrawRows({ rowNodes: [node] });

    setExportSamplesIds(gridApi.getSelectedRows());
  };

  const baseColumns = getSamplesColumns(
    setCollapsedOrdersList,
    setCollapsedProjectsList,
    collapsedOrdersList,
    collapsedProjectsList,
    species,
    organs,
    checkboxHandler
  );

  const {
    fullColumns,
    setFullColumns: setColumnDefs,
    isLoading: isLoadingColumns,
  } = useGetSampleColumns(baseColumns, gridApi, isLoadingTools);

  useAGGridOverlayControl(
    isLoading || isLoadingColumns,
    filteredSamplesList,
    gridApi
  );

  const buildSamplesToUpdateHandler = (updatedSample) => {
    if (!Object.keys(updatedSample).length) {
      setSamplesToUpdate([]);
      setSamples(initialSamples);
      //to get initial values for filtered list
      buildCollapseStateHandler();
      return;
    }
    const initialSample = initialSamples.find(
      (item) => item.uuid === updatedSample.uuid
    );

    //neccessary to have empty fields for the science_info object.
    //To be on the same level for comparisson.
    const formattedInitialSample = convertSampleObjectToUpdate(
      initialSample,
      initialSample
    );

    const isEqualObjects = _.isEqual(formattedInitialSample, updatedSample);

    const sampleToPost = {
      ...updatedSample,
      order_uuid: updatedSample.order_uuid,
      organ_uuid: updatedSample.organ?.uuid,
      species_uuid: updatedSample.species?.uuid,
      submission_uuid: updatedSample.submission?.uuid,
    };

    setSamplesToUpdate((prevSamples) => {
      const existingPrevSample = prevSamples.find((prevSample) => {
        return prevSample.uuid === updatedSample.uuid;
      });

      if (existingPrevSample && isEqualObjects) {
        return prevSamples.filter((item) => item.uuid !== updatedSample.uuid);
      }

      if (existingPrevSample) {
        const updatedSamples = prevSamples.map((prevSample) => {
          return prevSample.uuid === updatedSample.uuid
            ? { ...prevSample, ...sampleToPost }
            : prevSample;
        });

        return updatedSamples;
      }

      if (!existingPrevSample && !isEqualObjects) {
        return [...prevSamples, sampleToPost];
      }

      return prevSamples;
    });
  };

  const updateSamplesState = ({ data, newValue, colDef }) => {
    const field = colDef.field;
    const splittedField = field.split(".");

    const speciesValue = species.find((item) => item.name === newValue);
    const organValue = organs.find((item) => item.name === newValue);

    //find sample which is updated now
    const currentSample = filteredSamplesList.find(
      (item) => item.uuid === data.uuid
    );

    const isScienceInfoField =
      splittedField.length === 2 && splittedField[0] === "science_info";

    const updatedLocalSample = {
      ...currentSample,
      ...(isScienceInfoField && {
        science_info: {
          ...currentSample.science_info,
          [splittedField[1]]: newValue,
        },
      }),
      ...(splittedField.length === 1 && {
        [field]: newValue,
      }),
      ...(field === "organ.name" && {
        organ: organValue,
      }),
      ...(field === "species.name" && {
        species: speciesValue,
      }),
    };

    const updateStateHandler = (prevState) => {
      return prevState.map((sample) => {
        if (sample.uuid === updatedLocalSample.uuid) return updatedLocalSample;
        return sample;
      });
    };

    // both handlers are necessary because of toggling
    setSamples((prev) => {
      return updateStateHandler(prev);
    });

    setFilteredSamplesList((prev) => {
      return updateStateHandler(prev);
    });
    buildSamplesToUpdateHandler(updatedLocalSample);
  };

  //neccessary to have empty fields for the science_info object.
  //To be on the same level for comparisson.
  const formattedAllInitialSamples = initialSamples.map((item) =>
    convertSampleObjectToUpdate(item, item)
  );

  //force - necessary when "go anyways" from modal
  const onChangePage = (_, value, force = false) => {
    if (samplesToUpdate.length && !force) {
      setIsOpenModal(true);
      setNextPage(value);
      return;
    }
    let params = new URLSearchParams(search);
    params.set("page", value);

    history.replace({ search: params.toString() });

    setExportSamplesIds([]);
    setNextPage(null);
    setSamplesToUpdate([]);
  };

  useEffect(() => {
    const currentSearch = history.location.search;
    if (currentSearch) return;
    history.replace({ search: `page=1` });
  }, [history.location]);

  return (
    <MiniDrawerWithContext header={"Samples Editor"}>
      <Toolbar
        samplesToUpdate={samplesToUpdate}
        updatedSamplesHandler={buildSamplesToUpdateHandler}
        allSamples={formattedAllInitialSamples}
        exportSamplesIds={exportSamplesIds}
        refetchSamples={refetchSamples}
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        nextPage={nextPage}
        setNextPage={setNextPage}
        onChangePage={onChangePage}
        setExportSamplesIds={setExportSamplesIds}
        gridApi={gridApi}
        fullColumns={fullColumns}
      />
      <Paper
        id="samples-list"
        className={`ag-theme-balham ${classes.agGridWrapper}`}
      >
        <SamplesTableHeader gridApi={gridApi} setColumns={setColumnDefs} />
        <AgGridReact
          animateRows
          defaultColDef={RESPONSIVE_GRID}
          rowData={filteredSamplesList}
          columnDefs={fullColumns}
          rowHeight={40}
          onCellEditRequest={(e) => updateSamplesState(e)}
          readOnlyEdit
          singleClickEdit
          stopEditingWhenGridLosesFocus
          onGridReady={({ api }) => setGridApi(api)}
          excelStyles={EXPORT_EXCEL_STYLES}
          suppressScrollOnNewData
        />
      </Paper>
      <Box className={classes.bottomWrapper}>
        <Typography className={classes.info}>
          <InfoIcon />
          Hold Shift and use mouse wheel for horizontal scrolling.
        </Typography>
        {!!totalPages && totalPages > 1 && (
          <Pagination
            page={+currentPage}
            className={classes.pagination}
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={onChangePage}
          />
        )}
      </Box>
    </MiniDrawerWithContext>
  );
};
