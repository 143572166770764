import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const OrderCellRenderer = ({
  cellData,
  collapsedOrdersList,
  setCollapsedOrdersList,
  collapsedProjectsList,
}) => {
  const currentFirstOrder = collapsedOrdersList.find(
    (item) => item.id === cellData.order_id
  );

  const isProjectCollapsed = collapsedProjectsList.find(
    (item) => item.collapse && item.id === cellData.project_uuid
  );
  const toggleHandler = () => {
    if (!showToggleIcon) return;

    setCollapsedOrdersList((prev) => {
      return prev.map((item) =>
        item.id === cellData.order_id
          ? { ...item, collapse: !item.collapse }
          : item
      );
    });
  };

  const showToggleIcon =
    currentFirstOrder && !isProjectCollapsed && cellData.isFirstInOrder;
  const paddingStyles = !cellData.isFirstInOrder
    ? "20px"
    : showToggleIcon
    ? 0
    : "20px";

  return (
    <span
      style={{
        opacity: cellData.isFirstInOrder ? 1 : 0.7,
        display: "flex",
        alignItems: "center",
        paddingLeft: paddingStyles,
        fontWeight: cellData.isFirstInOrder ? "bold" : "normal",
      }}
      onClick={toggleHandler}
      id="no-edit"
    >
      {showToggleIcon &&
        (currentFirstOrder.collapse ? (
          <KeyboardArrowRightIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <KeyboardArrowDownIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
          />
        ))}
      {cellData.order_id || "-"}
    </span>
  );
};
