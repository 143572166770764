import { Grid, Typography } from "@mui/material";
import { SlideGroupComponent } from "components/SlideGroups/Views/CardView/SlideGroupComponent";
import Box from "@mui/material/Box";

export const SlideGroupsCardView = ({ slideGroups, classes }) => {
  return (
    <Grid container spacing={2}>
      {slideGroups.length ? (
        slideGroups.map((slideGroup) => (
          <SlideGroupComponent key={slideGroup.uuid} slideGroup={slideGroup} />
        ))
      ) : (
        <Box className={classes.noSlideGroupsLabelContainer}>
          <Typography variant="h5">You don't have slide groups</Typography>
        </Box>
      )}
    </Grid>
  );
};
