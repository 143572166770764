import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_REFINEMENT_TYPE } from "components/PathologyMap/constants/common";

const initialState = {
  currentRefinementType: DEFAULT_REFINEMENT_TYPE,
  currentSearchQuery: "",
  currentFacetSearchFilters: [],
  currentFiltersSearchQuery: "",
};

const pathologyMapSlice = createSlice({
  name: "pathologyMap",
  initialState,
  reducers: {
    setCurrentRefinementType: (state, { payload }) => {
      state.currentRefinementType = payload;
    },
    setCurrentSearchQuery: (state, { payload }) => {
      state.currentSearchQuery = payload;
    },
    setCurrentFacetSearchFilters: (state, { payload }) => {
      state.currentFacetSearchFilters = payload;
    },
    setCurrentFiltersSearchQuery: (state, { payload }) => {
      state.currentFiltersSearchQuery = payload;
    },
  },
});

export const {
  setCurrentRefinementType,
  setCurrentSearchQuery,
  setCurrentFacetSearchFilters,
  setCurrentFiltersSearchQuery,
} = pathologyMapSlice.actions;

export const pathologyMapReducer = pathologyMapSlice.reducer;

export const currentRefinementTypeSelector = (state) =>
  state.pathologyMap.currentRefinementType;
export const currentSearchQuerySelector = (state) =>
  state.pathologyMap.currentSearchQuery;
export const selectCurrentFacetSearchFilters = (state) =>
  state.pathologyMap.currentFacetSearchFilters;
export const selectCurrentFiltersSearchQuery = (state) =>
  state.pathologyMap.currentFiltersSearchQuery;
