import { backendURL } from "services/backendAPI";

export const getLisaSlideLink = ({ slide }) => {
  return `${backendURL}/lisa/core/slide/${slide.id}/change/`;
};

export const getLISAOrderLinkFromSlide = ({ slide }) => {
  if (!slide.order.id) {
    return "";
  }
  return `${backendURL}/lisa/core/order/${slide.order.id}/change/`;
};

export const getLisaIFSlideLink = (ifSlide) => {
  return `${backendURL}/lisa/core/slide/${ifSlide?.id}/change/`;
};
