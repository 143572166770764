const USAVariations = [
  "usa",
  "us",
  "the usa",
  "united states",
  "u.s.a.",
  "u.s.",
  "america",
  "united states of america",
];

export const isUSA = (country) => {
  if (!country) {
    return false;
  }

  const clearCountryName = country.trim();
  const lowerCaseCountryName = clearCountryName.toLowerCase();

  return USAVariations.includes(lowerCaseCountryName);
};
