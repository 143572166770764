import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, ListItem, Tooltip } from "@mui/material";

import { useSidebarStyles } from "components/Layout/Sidebar/styles";

export const SidebarListItem = ({
  Icon,
  name,
  path,
  isExternal = false,
  handler,
  withoutLink = false,
}) => {
  const { pathname } = useLocation();
  const { classes } = useSidebarStyles();
  const { link, linkActive, text, listItem } = classes;

  const isActive = pathname === path;
  const title = !isActive && name;
  const className = isActive ? linkActive : link;
  const Wrapper = withoutLink ? Box : Link;
  const linkProps = withoutLink
    ? {}
    : {
        to: { pathname: path ?? "#" },
        target: isExternal ? "_blank" : "_self",
      };

  return (
    <Tooltip
      disableInteractive
      title={title}
      placement="right"
      PopperProps={{ className: classes.tooltipPopper }}
    >
      <ListItem className={listItem} onClick={handler}>
        <Wrapper className={className} {...linkProps}>
          <Icon />
          <span className={text}>{name}</span>
        </Wrapper>
      </ListItem>
    </Tooltip>
  );
};
