import { DefaultModal } from "components/Modals/DefaultModal";
import { Card } from "components/common/Card/Card";
import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { CardHeader } from "components/UserProfile/components/CardHeader";
import {
  PATHOLOGY_MAP_SLIDE_CARD_CONTENT_TYPOGRAPHY_PROPS,
  TEAM_OVERVIEW_CARD_HEADER_TYPOGRAPHY_PROPS,
  useSlideInfoForPathologyMapStyles,
} from "components/PathologyMap/constants/styles";
import { useOrdersTabContentStyles } from "components/UserProfile/styles";
import { DefaultPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography";
import { useCopyIntoClipboard } from "components/Share/helpers/hooks";
import { buildTeamInfo } from "components/UserProfile/utilities";
import { useTeamMembers } from "components/UserProfile/hooks";
import { Spinner } from "components/Loading/Spinner/Spinner";
import Box from "@mui/material/Box";

export const TeamMembersModal = ({ open, team, onClose }) => {
  const { classes: classesFromPathologyMap } =
    useSlideInfoForPathologyMapStyles();
  const { classes: orderTabStyles } = useOrdersTabContentStyles();
  const classes = { ...classesFromPathologyMap, ...orderTabStyles };

  const { first_name, last_name, email } = team.account_rep || {};
  const salesRepName = team.account_rep ? `${first_name} ${last_name}` : "N/A";

  const { members, isLoading = true } = useTeamMembers(team);

  const handleCopy = useCopyIntoClipboard(email, "Email has copied");

  const teamInfo = useMemo(() => buildTeamInfo(team), [team]);

  const RightSection = () => (
    <Grid className={classes.withPointer} item onClick={() => handleCopy()}>
      <DefaultPathologyMapTypography
        {...TEAM_OVERVIEW_CARD_HEADER_TYPOGRAPHY_PROPS}
      >
        Sales Rep
      </DefaultPathologyMapTypography>
      <DefaultPathologyMapTypography
        {...PATHOLOGY_MAP_SLIDE_CARD_CONTENT_TYPOGRAPHY_PROPS}
      >
        {salesRepName}
      </DefaultPathologyMapTypography>
    </Grid>
  );

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      width="75%"
      styles={{
        maxWidth: "1200px",
        padding: 0,
        borderRadius: "4px",
      }}
    >
      <Card
        headerContent={
          <CardHeader
            subtitle={teamInfo}
            title={team.name}
            RightSection={<RightSection />}
          />
        }
        bodyContent={
          isLoading ? (
            <Box className={classes.spinnerContainer}>
              <Spinner />
            </Box>
          ) : (
            <Box className={classes.teamMembersContainer}>
              {members.map((member) => (
                <Grid
                  key={member.email}
                  container
                  className={`${classes.slideDataContainer} ${classes.slideInfoRow}`}
                  paddingRight={4}
                >
                  <Grid item xs={4}>
                    <Typography>
                      {member.first_name || ""} {member.last_name || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{member.email}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          )
        }
      />
    </DefaultModal>
  );
};
