import { createSlice } from "@reduxjs/toolkit";

export const slideGroupsSlice = createSlice({
  name: "slideGroups",
  initialState: [],
  reducers: {
    setSlideGroups: (state, { payload }) => payload,
    createSlideGroup: (state, { payload }) => {
      state.push(payload);
    },
    updateSlideGroup: (state, { payload }) => {
      const index = state.findIndex(
        (slideGroup) => slideGroup.uuid === payload.uuid
      );
      state[index] = payload;
    },
    deleteSlideFromGroup: (state, { payload }) => {
      return state.filter((slideGroup) => slideGroup.uuid !== payload.uuid);
    },
    deleteSlideGroup: (state, { payload }) => {
      state.splice(
        state.findIndex((slideGroup) => slideGroup.uuid === payload.uuid),
        1
      );
    },
  },
});

export const {
  setSlideGroups,
  createSlideGroup,
  updateSlideGroup,
  deleteSlideGroup,
} = slideGroupsSlice.actions;

export const slideGroupsReducer = slideGroupsSlice.reducer;

export const slideGroupsSelector = (state) => state.slideGroups;
