import { number, object, string } from "yup";
import {
  CuttingInstructionsOptionsMapping,
  OrderIHCOptimizationLabelDisplay,
  serializedTrueFalseOptionsToSelectInput,
  serializedTurnaroundTime,
  LAST_ORDER_FORM_PAGE,
} from "components/OrderForm/constants";
import { CREATE_PROJECT_ACTION } from "components/OrderForm/Info/constants";

export const getOrderInfoValidationSchema = (isGLP) =>
  object().shape({
    project: object({
      label: string().min(1, "Project Is Required!"),
      value: string().min(1, "Project Is Required"),
    })
      .default(undefined)
      .required("Project Is Required"),
    ...(!isGLP
      ? {
          turnaroundTime: object().required("Please select a turnaround time!"),
          // pathologyMapContributorSelection: object().required(
          //   "Please select if this order can contribute to the Pathology Map!"
          // ),
          orderFixative: object().required("Please choose a fixative!"),
          fixationTimeHours: number()
            .typeError("Please specify a fixation time!")
            .required("Please specify a fixation time!")
            .min(0.01, "Must be greater than zero!"),
          sectionsPerSlide: number()
            .typeError("Please select sections per slide!")
            .required("Please select sections per slide!")
            .min(1, "Must be greater than zero!"),
          additionalStepsPerSample: number()
            .typeError("Please select steps!")
            .required("Please select steps!"),
          cutting_instructions: object().required(
            "Please select grossing instructions!"
          ),
          sectionThicknessMicrons: number()
            .typeError("Please select a section thickness!")
            .required("Please select a section thickness!")
            .min(1, "Must be greater than zero!"),
        }
      : {
          purpose_of_study: string().required(
            "Please enter a purpose of study"
          ),
        }),
    species: object().required("Please choose a species!"),
  });

export const buildCreateOrderParams = (values, orderDetails, team, isGLP) => {
  const defaultParams = !isGLP
    ? {
        special_instructions: values.specialInstructions,
        purpose_of_study: values.purpose_of_study,
        cutting_instructions: values.cutting_instructions.value,
        ihc_optimization_type: values.ihc_optimization_type.value,
        materials_at_histowiz: values.materials_at_histowiz.value,
        fixation_time_hours: values.fixationTimeHours,
        additional_steps_per_sample: values.additionalStepsPerSample,
        sections_per_slide: values.sectionsPerSlide,
        section_thickness_microns: values.sectionThicknessMicrons,
        materials_at_histowiz_previous_orders:
          values.materials_at_histowiz_previous_orders,
        expected_staining_pattern: values.expected_staining_pattern,
        experimental_treatment: values.experimental_treatment,
        expected_phenotype: values.expected_phenotype,
        cell_target: values.cell_target,
        materials_at_histowiz_previous_orders_v2_uuids:
          values.materials_at_histowiz_previous_orders_v2_uuids,
        fixative_uuid: values.orderFixative?.value,
        pathology_map_consent: values.pathologyMapContributorSelection?.value,
        turnaround_days: values.turnaroundTime.value,
        save_project_defaults: values.save_project_defaults,
        region_of_interest: values.region_of_interest,
      }
    : {
        purpose_of_study: values.purpose_of_study,
      };

  const projectParams =
    values.project.value === CREATE_PROJECT_ACTION
      ? {
          create_project_name: values.project.label,
          team_uuid: values.team?.value || team.value,
        }
      : { project_uuid: values.project.value };

  const optionalParams = {
    ...(!!orderDetails.name && { name: orderDetails.name }),
    ...(!!values.species && { species_uuid: values.species.value }),
    ...(!!values.specialInstructionsAttachment && {
      special_instructions_attachment_uuid:
        values.specialInstructionsAttachment.value,
    }),
  };

  return {
    last_order_form_page: LAST_ORDER_FORM_PAGE.ORDER_INFO,
    ...defaultParams,
    ...projectParams,
    ...optionalParams,
  };
};

export const buildOrderDetails = ({
  fixative,
  project,
  ihc_optimization_type,
  species,
  special_instructions_attachment,
  cutting_instructions,
  pathology_map_consent,
  turnaround_days,
  materials_at_histowiz,
  fixation_time_hours = "",
  sections_per_slide,
  additional_steps_per_sample,
  special_instructions,
  section_thickness_microns,
  ...others
}) => ({
  ...others,
  pathologyMapContributorSelection:
    serializedTrueFalseOptionsToSelectInput[pathology_map_consent],
  turnaroundTime: serializedTurnaroundTime[turnaround_days],
  materials_at_histowiz:
    serializedTrueFalseOptionsToSelectInput[materials_at_histowiz],
  fixationTimeHours: fixation_time_hours,
  sectionsPerSlide: sections_per_slide,
  additionalStepsPerSample: additional_steps_per_sample,
  specialInstructions: special_instructions,
  sectionThicknessMicrons: section_thickness_microns,
  orderFixative: { value: fixative.uuid, label: fixative.name },
  project: { value: project.uuid, label: project.name },
  ihc_optimization_type: {
    value: ihc_optimization_type,
    label: OrderIHCOptimizationLabelDisplay[ihc_optimization_type],
  },
  ...(!!species && {
    species: { value: species.uuid, label: species.name },
  }),
  ...(!!special_instructions_attachment && {
    specialInstructionsAttachment: {
      value: special_instructions_attachment.uuid,
      label: special_instructions_attachment.name,
      url: special_instructions_attachment.url,
    },
  }),
  ...(!!cutting_instructions && {
    cutting_instructions: {
      value: cutting_instructions,
      label: CuttingInstructionsOptionsMapping[cutting_instructions],
    },
  }),
});

export const getOrderFormSamplesURL = (uuid) => {
  const pathname = window.location.pathname;
  const url = `/forms/order/${uuid}/samples/${
    pathname.includes("v2") ? "v3" : ""
  }`;

  return url;
};
