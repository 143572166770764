import { useViewerMenuItemStyles } from "components/ImageViewer/SlideViewerHeader/styles";
import { DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS } from "components/utilities/FavoriteSlide/constants";
import { FavoriteSlideIconButton } from "components/utilities/FavoriteSlide/FavoriteSlideIconButton";
import { useDispatch, useSelector } from "react-redux";
import { slidesListOrderSelector } from "store/slices/slidesListSlice/slidesListSlice";
import {
  currentUserPreferencesSelector,
  updatePreferences,
} from "store/slices/userDetailsSlice";
import {
  ANNOTATION_DRAW_MODE_ENABLED_FIELD,
  ANNOTATION_DRAW_TOOL_FIELD,
  DISTANCE_MEASURE_ENABLED_FIELD,
  NAVIGATOR_SWITCH_FIELD,
  NAVIGATOR_SWITCH_LABEL,
  SHORTCUTS_PROMPT_SWITCH_FIELD,
  SHORTCUTS_PROMPT_SWITCH_LABEL,
  THUMBNAIL_SWITCH_FIELD,
  THUMBNAIL_SWITCH_LABEL,
} from "components/ImageViewer/constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { forwardRef, useEffect } from "react";
import { ORDERS_SLIDE_LIST_URL, ORDER_DETAILS_URL } from "constants/urls";
import { updateCurrentUserPreferences } from "services/resources/commonResources";
import { UPDATE_USER_DETAILS_MESSAGE } from "constants/errorMessages";
import { MenuItem, Switch, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useUpdateUserPreferencesMutation } from "store/apis/userDetailsApi";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useSlidesViewerHeaderStyles } from "components/ImageViewer/styles";
import { ImageViewerToolbar } from "components/ImageViewer/components/ImageViewerToolbar/ImageViewerToolbar";

export const SlideViewerMenuItem = forwardRef(({ label, fieldName }, ref) => {
  const menuItemInputProps = { "aria-label": "secondary checkbox" };
  const { classes } = useViewerMenuItemStyles();
  const preferences = useSelector(currentUserPreferencesSelector);
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const isChecked = preferences[fieldName];

  const onMenuItemChange = (value) =>
    dispatch(
      updatePreferences({
        [fieldName]: value,
      })
    );

  const updateUserPreferences = (preferences) => {
    const postParams = {
      preferences,
    };

    return updateCurrentUserPreferences(postParams).catch(() =>
      showError(UPDATE_USER_DETAILS_MESSAGE)
    );
  };

  const handleCheckedChange = (e) => {
    const updatedPreferences = {
      ...preferences,
      [fieldName]: e.target.checked,
    };

    updateUserPreferences(updatedPreferences).then(() => {
      onMenuItemChange(!e.target.checked);
    });
  };

  const switchClass = `${classes.switch} ${
    isChecked ? classes.checkedValue : classes.uncheckedValue
  }`;

  return (
    <Box ref={ref} className={classes.switchWrapper}>
      <Switch
        className={switchClass}
        color="secondary"
        checked={isChecked}
        onChange={handleCheckedChange}
        name={fieldName}
        inputProps={menuItemInputProps}
      />
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
});

export const SlideViewerOptions = () => {
  return (
    <Box>
      <SlideViewerMenuItem
        label={THUMBNAIL_SWITCH_LABEL}
        fieldName={THUMBNAIL_SWITCH_FIELD}
      />
      <SlideViewerMenuItem
        label={NAVIGATOR_SWITCH_LABEL}
        fieldName={NAVIGATOR_SWITCH_FIELD}
      />
      <SlideViewerMenuItem
        label={SHORTCUTS_PROMPT_SWITCH_LABEL}
        fieldName={SHORTCUTS_PROMPT_SWITCH_FIELD}
      />
    </Box>
  );
};

export const MenuActionItem = forwardRef(
  ({ icon, onClick, label, ...props }, ref) => {
    return (
      <MenuItem onClick={onClick} ref={ref} {...props}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </MenuItem>
    );
  }
);

export const SlideViewerHeader = ({
  currentSlide,
  showFavoriteIcon = true,
  annotationsCount,
  slidesViewerProps,
  handleNextSlide,
  handlePreviousSlide,
  isSingleViewer,
}) => {
  const preferences = useSelector(currentUserPreferencesSelector);
  const { classes } = useSlidesViewerHeaderStyles();
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  const preventAnnotateMode = () => handleSetDrawModeEnabled(false);

  useEffect(() => {
    window.addEventListener("beforeunload", preventAnnotateMode);

    return () => {
      window.removeEventListener("beforeunload", preventAnnotateMode);
      handleSetDrawModeEnabled(false);
    };
  }, []);

  const handleSetAnnotationDrawTool = (value) => {
    const updatedPreference = {
      [ANNOTATION_DRAW_TOOL_FIELD]: value,
    };
    updateUserPreferences({ postParams: updatedPreference, partial: true });
  };

  const handleSetDrawModeEnabled = (value) => {
    const updatedPreference = {
      [ANNOTATION_DRAW_MODE_ENABLED_FIELD]: value,
      ...(value && { [DISTANCE_MEASURE_ENABLED_FIELD]: false }),
    };
    updateUserPreferences({ postParams: updatedPreference, partial: true });
  };

  const order = useSelector(slidesListOrderSelector);

  const iconButtonProps = {
    className: classes.starIcon,
    ...DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS,
  };

  const showSlidesNavigation =
    !isSingleViewer && slidesViewerProps?.slides?.length === 1;

  const BREADCRUMBS_FOR_DATA = [
    {
      name: currentSlide?.order?.id,
      link: ORDER_DETAILS_URL.replace(
        ":orderUUID",
        order?.uuid || currentSlide?.order?.uuid
      ),
    },
    {
      name: "slides",
      hide: !isSingleViewer,
      link: ORDERS_SLIDE_LIST_URL.replace(
        ":orderUUID",
        currentSlide?.order?.uuid
      ),
    },
    { name: currentSlide?.name, bold: true },
  ];

  return (
    <Box>
      <Box className={classes.headContainer}>
        {showFavoriteIcon && (
          <FavoriteSlideIconButton
            isStarred={currentSlide.is_starred}
            slideUUID={currentSlide.uuid}
            className={classes.iconButton}
            filledIconProps={iconButtonProps}
            outlineIconProps={iconButtonProps}
          />
        )}
        <Box>
          {currentSlide && (
            <Breadcrumbs data={BREADCRUMBS_FOR_DATA} size="medium" />
          )}
        </Box>
      </Box>
      <Grid container className={classes.toolbarWrapper}>
        {!!currentSlide && (
          <ImageViewerToolbar
            selectedDrawTool={preferences[ANNOTATION_DRAW_TOOL_FIELD]}
            setDrawTool={handleSetAnnotationDrawTool}
            drawModeEnabled={preferences[ANNOTATION_DRAW_MODE_ENABLED_FIELD]}
            handleSetDrawModeEnabled={handleSetDrawModeEnabled}
            annotationsCount={annotationsCount}
            slidesViewerProps={slidesViewerProps}
            currentSlide={currentSlide}
            handleNextSlide={handleNextSlide}
            handlePreviousSlide={handlePreviousSlide}
            showSlidesNavigation={showSlidesNavigation}
          />
        )}
      </Grid>
    </Box>
  );
};
