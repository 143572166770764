import { Box, Button, Typography, SvgIcon } from "@mui/material";

import BarChartIcon from "@mui/icons-material/BarChart";
import BiotechIcon from "@mui/icons-material/Biotech";
import { TextWithLimitedWidth } from "components/SlideGroups/components/TextWithLimitedWidth";

export const DetailsHeader = ({
  classes,
  selectedAntibody,
  setIsDetailsView,
  isDetailsView,
  thumbnail,
}) => (
  <Box mb={1} className={classes.tabs}>
    <Typography className={classes.title}>Antibodies Catalog</Typography>
    <Box className={classes.subTitle}>
      <TextWithLimitedWidth
        containerClass={classes.truncate}
        value={selectedAntibody?.display_name}
        tooltipPlacement={"bottom"}
      />
    </Box>
    {thumbnail && (
      <Box className={classes.rightFloat}>
        <Button
          onClick={() => setIsDetailsView(true)}
          className={`${classes.baseTabSelector} ${
            isDetailsView ? classes.tabSelectorActive : classes.tabSelector
          }`}
          startIcon={<SvgIcon component={BarChartIcon} />}
        >
          Details
        </Button>

        <Button
          onClick={() => setIsDetailsView(false)}
          className={`${classes.baseTabSelector} ${
            isDetailsView ? classes.tabSelector : classes.tabSelectorActive
          }`}
          startIcon={<SvgIcon component={BiotechIcon} />}
        >
          Viewer
        </Button>
      </Box>
    )}
  </Box>
);
