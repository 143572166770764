import { useSelector } from "react-redux";
import { currentRefinementTypeSelector } from "store/slices/pathologyMapSlice";
import { useRef, useState } from "react";
import { Grid } from "@mui/material";
import { PathologyMapFilterPropertiesItem } from "components/PathologyMap/PathologyMapFilterProperties/PathologyMapFilterPropertiesItem";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";
import {
  useAlgoliaFilterRefine,
  useAlgoliaSourceFacet,
} from "components/PathologyMap/utilities/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "components/Loading/Spinner/Spinner";
import {
  AVAILABLE_FILTERS_CONTAINER_ID,
  AVAILABLE_FILTERS_PER_PAGE_COUNT,
  INFINITY_FILTERS_SCROLL_THRESHOLD,
} from "components/PathologyMap/constants/common";
import { CenteredComponent } from "components/utilities/CenteredComponent";
import { useDeepEffect } from "components/utilities/hooks/useDeepEffect";
import { useResetScrollOnValueChange } from "components/utilities/hooks/useResetScrollOnValueChange";
import { getNextPageFacetsResults } from "components/PathologyMap/utilities/common";
import { LOADER_WRAPPER_STYLE } from "components/PathologyMap/constants/styles";
import { usePathologyMapFilterPropertiesItemsStyles } from "./styles";

export const PathologyMapFilterPropertiesItems = () => {
  const currentRefinementType = useSelector(currentRefinementTypeSelector);

  const allFilters = useAlgoliaSourceFacet(currentRefinementType);
  const [filtersToRender, setFiltersToRender] = useState([]);

  const { classes } = usePathologyMapFilterPropertiesItemsStyles();
  const { items: selectedFilters } = useCurrentRefinements();
  const refine = useAlgoliaFilterRefine();
  const wrapperRef = useRef(null);

  useResetScrollOnValueChange(wrapperRef, currentRefinementType);

  useDeepEffect(() => {
    setFiltersToRender(allFilters.slice(0, AVAILABLE_FILTERS_PER_PAGE_COUNT));
  }, [allFilters]);

  const handleLoadMoreFilters = () => {
    setFiltersToRender((prevState) => {
      return getNextPageFacetsResults(prevState, allFilters);
    });
  };

  const hasMoreFilters = filtersToRender.length < allFilters.length;

  return (
    <Grid
      container
      item
      xs
      direction="column"
      wrap="nowrap"
      ref={wrapperRef}
      className={classes.itemsWrapper}
      id={AVAILABLE_FILTERS_CONTAINER_ID}
    >
      <InfiniteScroll
        dataLength={filtersToRender.length}
        next={handleLoadMoreFilters}
        hasMore={hasMoreFilters}
        loader={
          <CenteredComponent style={LOADER_WRAPPER_STYLE}>
            <Spinner />
          </CenteredComponent>
        }
        scrollableTarget={AVAILABLE_FILTERS_CONTAINER_ID}
        scrollThreshold={INFINITY_FILTERS_SCROLL_THRESHOLD}
      >
        {filtersToRender.map((item) => {
          return (
            <PathologyMapFilterPropertiesItem
              key={`${currentRefinementType}-${item.value}`}
              value={item.value}
              count={item.count}
              selectedFilters={selectedFilters}
              refine={refine}
            />
          );
        })}
      </InfiniteScroll>
    </Grid>
  );
};
