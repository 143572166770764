import { PathologyMapConfiguration } from "components/PathologyMap/PathologyMapConfiguration";
import { PathologyMapLayout } from "components/PathologyMap/PathologyMapLayout";

export const PathologyMapMainContent = ({ algoliaSearchApiKey }) => {
  return (
    <PathologyMapConfiguration algoliaSearchApiKey={algoliaSearchApiKey}>
      <PathologyMapLayout />
    </PathologyMapConfiguration>
  );
};
