import React, { Fragment, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  connectInfiniteHits,
  connectSearchBox,
  CurrentRefinements,
  InstantSearch,
  RefinementList,
} from "react-instantsearch-dom";
import { Waypoint } from "react-waypoint";

import "components/PathologyMap/PathologyMapDeprecated/algolia.css";

import { makeStyles } from "tss-react/mui";
import Container from "@mui/material/Container";
import { Alert, Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import {
  HistoWizStatsPerSearch,
  PathologyMapHeroContainer,
} from "components/PathologyMap/PathologyMapDeprecated/PathologyMapComponents";
import { SLIDE_VIEWER_URL } from "constants/urls";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Modal from "@mui/material/Modal";
import { appURL } from "services/backendAPI";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Snackbar from "@mui/material/Snackbar";
import {
  HistoWizRefinementTypeList,
  indexName,
} from "components/PathologyMap/PathologyMapDeprecated/constants";
import { postBuyUnstainedSlide } from "services/resources/commonResources";
import { POST_UNSTAINED_SLIDES_ERROR_MESSAGE } from "constants/errorMessages";
import {
  algoliaSearchApiKeySelector,
  currentUserEmailSelector,
} from "store/slices/userDetailsSlice";
import { useSelector } from "react-redux";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { usePathologyMapSearchStyles } from "./styles";

const HistoWizSearchBox = ({ currentRefinement, refine, onFocus }) => {
  const { classes } = usePathologyMapSearchStyles();

  return (
    <Fragment>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search Across 2,000,000 Slides (Mouse, Ki67, Trichrome, etc)"
          inputProps={{ "aria-label": "Search Through 200,000 Files" }}
          value={currentRefinement}
          fullWidth={true}
          onFocus={onFocus}
          //onBlur={onBlur}
          onChange={(event) => refine(event.currentTarget.value)}
        />
      </Paper>
    </Fragment>
  );
};

const CustomSearchBox = connectSearchBox(HistoWizSearchBox);

const HistoWizRefinementListComponent = ({
  title,
  refinementType,
  defaultRefinement,
}) => {
  return (
    <Fragment>
      <Box mb={1}>
        <Typography variant={"h5"} gutterBottom={true}>
          {title}
        </Typography>
        <RefinementList
          defaultRefinement={defaultRefinement}
          attribute={refinementType}
          limit={5}
          showMore
          showMoreLimit={30}
        />
      </Box>
    </Fragment>
  );
};

const PathologyMapSearchHeroComponent = () => {
  // I used to have a dropdown menu, but decided it was clunky
  // it kept on rerendering losing the refinements, so removed.

  // eslint-disable-next-line
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);

  //const closeSearchDropdown = () => {
  //  setShowSearchDropdown(false);
  //};
  //
  //const onSearchBlur = () => {
  //  //setShowSearchDropdown(false)
  //};
  //
  //const renderFloatingSearch = ({ onClose }) => {
  //  if (!showSearchDropdown) {
  //    return null;
  //  }
  //
  //  return (
  //    <Box mt={0.5} className={classes.floatingSearch}>
  //      <FloatingSearchMenu onClose={onClose} />
  //    </Box>
  //  );
  //};

  const onSearchFocus = () => {
    setShowSearchDropdown(true);
  };

  return (
    <Fragment>
      <CustomSearchBox
        onFocus={onSearchFocus}
        //onBlur={onSearchBlur}
      />
      {/*{renderFloatingSearch({ onClose: closeSearchDropdown })}*/}
    </Fragment>
  );
};

const HistoWizRefinements = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { classes } = usePathologyMapSearchStyles();

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justifyContent={"space-around"}
        alignItems="flex-start"
        //wrap={"nowrap"}
        spacing={0}
        className={classes.refinementGrid}
      >
        {HistoWizRefinementTypeList.map((item) => {
          return (
            <Fragment key={item.refinementType}>
              <Grid item>
                <HistoWizRefinementListComponent
                  title={item.title}
                  refinementType={item.refinementType}
                  defaultRefinement={
                    params[item.refinementType]
                      ? [params[item.refinementType]]
                      : null
                  }
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Fragment>
  );
};

const histoWizPathologyHitResultStyles = makeStyles()(() => {
  return {
    gridItem: {
      //height: "30vh",
    },
    imageDiv: {
      height: "30vh",
    },
    image: {
      maxHeight: "100%",
      width: "100%",
      objectFit: "contain",
    },
  };
});

const tableStyles = makeStyles()(() => {
  return {
    table: {
      width: "100%",
      border: "1px solid #000",
      maxWidth: "100%",
    },
    tableCell: {
      overflowWrap: "break-word",
    },
    tableCellAttribute: {
      fontWeight: "bold",
      overflowWrap: "break-word",
      textTransform: "capitalize",
    },
  };
});

const modalStyle = {
  top: `0%`,
  left: `3%`,
  transform: `translate(3%, 3%)`,
};

const usePathMapImageModalStyles = makeStyles()((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageDiv: {
    //maxWidth: "40vw",
    //height: "30vh",
  },
  image: {
    //height: "auto",
    //maxHeight: "90vh",
    //width: "100%",
    height: "100%",
    maxHeight: "85vh",
    width: "auto",
  },
}));

const PathologyMapImageModal = ({ open, handleClose, slide }) => {
  const { classes } = usePathMapImageModalStyles();
  const thumbnailURL = slide.large_thumbnail_url.replace(
    "localhost:8000",
    "api.histowiz.com"
  );

  const nameSerialized = slide.name.replace(".svs", "").trim();
  const slideAttributes = getSlideAttributesFromSlide({ slide });

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography
        noWrap={true}
        align={"center"}
        variant={"h4"}
        color={"secondary"}
      >
        {nameSerialized}
      </Typography>
      <Box display="flex" justifyContent="space-evenly" m={1}>
        <Box className={classes.imageDiv}>
          <img src={thumbnailURL} alt={"Thumbnail"} className={classes.image} />
        </Box>
        <Box pt={0} px={1} mt={0} ml={1}>
          <BasicTable data={slideAttributes} />
          <ViewPurchaseComponent slide={slide} />
        </Box>
      </Box>
    </div>
  );

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(event, reason);
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
};

export const BasicTable = ({ data }) => {
  const { classes } = tableStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size={"small"}>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.label}>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableCellAttribute}
              >
                {row.label}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const getSlideAttributesFromSlide = ({ slide }) => {
  const slideAttributes = [];

  if (slide.name) {
    let modifiedName = slide.name.replace(/\$/g, "_");
    const orderID = slide.order_id;

    modifiedName = modifiedName
      .replace(`${orderID}_`, "")
      .replace(".svs", "")
      .replace(".scn", "");

    slideAttributes.push({
      label: "Name",
      value: modifiedName,
    });
  }
  if (slide.antibody_name) {
    slideAttributes.push({
      label: "Antibody",
      value: slide.antibody_name,
    });
  }
  if (slide.control_type_name) {
    slideAttributes.push({
      label: "Control Type",
      value: slide.control_type_name,
    });
  }
  if (slide.cut_type) {
    slideAttributes.push({ label: "Cut Type", value: slide.cut_type });
  }
  if (slide.fixation_time_hours) {
    slideAttributes.push({
      label: "Fixation Time",
      value: slide.fixation_time_hours,
    });
  }
  if (slide.fixative_name) {
    slideAttributes.push({
      label: "Fixative",
      value: slide.fixative_name,
    });
  }
  if (slide.height) {
    slideAttributes.push({ label: "Height", value: slide.height });
  }

  if (slide.order_name) {
    slideAttributes.push({ label: "Order", value: slide.order_name });
  }
  if (slide.order_external_notes) {
    slideAttributes.push({
      label: "Order Notes",
      value: slide.order_external_notes,
    });
  }
  if (slide.organ_name) {
    slideAttributes.push({ label: "Organ", value: slide.organ_name });
  }
  if (slide.sample_name) {
    slideAttributes.push({ label: "Sample", value: slide.sample_name });
  }
  if (slide.special_stain_name) {
    slideAttributes.push({
      label: "Special Stain Name",
      value: slide.special_stain_name,
    });
  }
  if (slide.species_name) {
    slideAttributes.push({ label: "Species", value: slide.species_name });
  }
  if (slide.stain_type_name) {
    slideAttributes.push({ label: "Stain Type", value: slide.stain_type_name });
  }
  if (slide.submission_type_name) {
    slideAttributes.push({
      label: "Submission Type",
      value: slide.submission_type_name,
    });
  }

  if (slide.science_info) {
    const scienceInfoKeys = Object.keys(slide.science_info);
    scienceInfoKeys.sort();

    scienceInfoKeys.forEach((key) => {
      let serialized = slide.science_info[key];
      if (typeof serialized !== "string") serialized = serialized.toString();
      slideAttributes.push({
        label: key.replace("_", " "),
        value: serialized,
      });
    });
  }

  return slideAttributes;
};

const ViewPurchaseComponent = ({ slide }) => {
  const { showError } = useSnackbar();

  const url = SLIDE_VIEWER_URL.replace(":slideUUID", slide.uuid);
  const urlSerialized = `${appURL}${url}`;

  const onViewSlideClick = () => {
    window.open(urlSerialized, "_blank");
  };

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleBuyUnstainedClick = () => {
    handleClick();
    postBuyUnstainedSlide({ slide }).catch(() =>
      showError(POST_UNSTAINED_SLIDES_ERROR_MESSAGE)
    );
  };

  return (
    <Box display="flex" justifyContent="space-around" mt={0}>
      <Box p={1}>
        <Button variant="contained" color="primary" onClick={onViewSlideClick}>
          View Slide
        </Button>
      </Box>
      <Box p={1}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBuyUnstainedClick}
        >
          Request Pathology Consultation
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <div>
          <PathologyMapAlert onClose={handleClose} severity="info">
            We'll reach out to you shortly about your interest in {slide.name}!
          </PathologyMapAlert>
        </div>
      </Snackbar>
    </Box>
  );
};

const HistoWizHitResult = ({ hit }) => {
  const { classes } = histoWizPathologyHitResultStyles();
  const slide = hit;

  const thumbnailURL = slide.small_thumbnail_url.replace(
    "localhost:8000",
    "api.histowiz.com"
  );

  const [modalOpen, setModalOpen] = useState(false);
  const slideAttributes = getSlideAttributesFromSlide({ slide });

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const renderModal = () => {
    if (!modalOpen) {
      return null;
    }

    return (
      <PathologyMapImageModal
        open={modalOpen}
        handleClose={handleClose}
        slide={hit}
      />
    );
  };

  return (
    <Grid item xs={3} className={classes.gridItem}>
      <Box p={1} m={1}>
        <Box className={classes.imageDiv}>
          <img
            src={thumbnailURL}
            alt={"Thumbnail"}
            className={classes.image}
            onClick={handleOpen}
          />
        </Box>
        <ViewPurchaseComponent slide={slide} />
        <BasicTable data={slideAttributes} />
        {renderModal()}
      </Box>
    </Grid>
  );
};

const PathologyMapAlert = React.forwardRef((props, ref) => {
  return <Alert elevation={6} variant="filled" {...props} ref={ref} />;
});

const PathologyMapInfiniteHits = ({ hits, hasMore, refineNext }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onWaypointEnter = () => {
    if (hasMore) {
      refineNext();
      handleClick();
    }
  };

  return (
    <Fragment>
      {hits.map((hit) => {
        return <HistoWizHitResult hit={hit} key={hit.name} />;
      })}
      {/*If more slides are found, this page will automatically load.*/}
      {/*This is the Infinite Scrolling*/}
      <Waypoint onEnter={onWaypointEnter} />
      <Snackbar open={open} autoHideDuration={500} onClose={handleClose}>
        <PathologyMapAlert onClose={handleClose} severity="success">
          More Matching Slides Found!
        </PathologyMapAlert>
      </Snackbar>
    </Fragment>
  );
};

const PathologyMapInfiniteHitsComponent = connectInfiniteHits(
  PathologyMapInfiniteHits
);

export const PathologyMapSearch = () => {
  const { classes } = usePathologyMapSearchStyles();

  const algoliaSearchApiKey = useSelector(algoliaSearchApiKeySelector);
  const email = useSelector(currentUserEmailSelector);

  if (!(algoliaSearchApiKey || email)) {
    return null;
  }

  // used for during testing
  //const searchClient = algoliasearch("0R1MIPP8UY", AlgoliaPathologyMapKey);

  const searchClient = algoliasearch("0R1MIPP8UY", algoliaSearchApiKey);

  return (
    <Fragment>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        stalledSearchDelay={500}
      >
        <Configure userToken={email} />
        <Paper className={classes.paperContainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={classes.topGridContainer}
          >
            <Grid item xs={12}>
              <PathologyMapHeroContainer />
            </Grid>
            <Grid item xs={10}>
              <Box mb={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <PathologyMapSearchHeroComponent />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Box my={2} />
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12}>
              <HistoWizRefinements />
            </Grid>

            <Grid item xs={12}>
              <CurrentRefinements clearsQuery={true} />
              <HistoWizStatsPerSearch />
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <PathologyMapInfiniteHitsComponent />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </InstantSearch>
    </Fragment>
  );
};
