// always use plural if possible, even if sounds stupid

import { backendURL } from "services/backendAPI";

export const EMPLOYEE_NOMINATION_URL = "/employee_of_the_year/";
export const DROPBOX_REQUEST_URL = "/dropbox_interest/";

// Utilities & Business
export const SIGNUP_URL = "/signup/";
export const SUCCESS_SIGNUP_URL = "/signup-success/";
export const LOGIN_URL = "/login/";
export const DEMO_LOGIN_URL = "/demo/login/";
export const DEMO_LOGIN_URL_2 = "/demo/";
export const LOGOUT_URL = "/logout/";

// RESTful endpoints, maybe this is a bad idea, but these try to match the backend
export const ORDERS_LIST_URL = "/orders/";
export const PATHOLOGY_CONSULTATIONS_LIST_URL = "/pathology_consultations/";
export const SLIDE_GROUPS_LIST_URL = "/slide_groups/";
export const TEAM_OVERVIEW_URL = "/team_overview/";

// this is the same url as both frontend and backend
export const ORDERS_SLIDE_LIST_URL = "/orders/:orderUUID/slides/";
export const CUSTOM_SLIDE_LIST_ROUTE = "/custom_slides_list/";
export const SLIDE_GROUPS_ROUTE = "/slide_groups/:slideGroupUUID/slides/";
export const SLIDE_GROUPS_URL = "/slide_groups/";
export const ORDERS_IHC_OPTIMIZATIONS_URL =
  "/orders/:orderUUID/ihc_optimizations/";

export const ORDERS_IF_SLIDE_LIST_URL = "/orders/:orderUUID/order_if_slides/";

// unused, but not sure why
//export const SHARED_ORDER_URL = "/shared_orders/:shareUUID/";
export const SHARED_ORDER_SLIDE_LIST_URL = "/shared_orders/:shareUUID/slides/";
export const SHARED_GROUP_SLIDE_LIST_URL = "/shared_groups/:shareUUID/slides";
//url for simple viewer
export const SHARED_SOCIAL_ORDER_URL = "/shared_social/viewer/:shareUUID";
export const COPY_PREVIEW_SLIDE_URL = "/social-preview/slide/:shareUUID";
//url for IF viewer
export const SHARED_SOCIAL_IF_ORDER_URL = "/shared_social/if_viewer/:shareUUID";
export const COPY_PREIVEW_IF_SLIDE_URL = "/social-preview/if-slide/:shareUUID";

// endpoint that will show all the names of the order endpoints
export const ORDERS_SLIDE_NAMES = "/orders/:orderUUID/slide_names/";
export const ORDERS_NOT_UPLOADED_SLIDE_NAMES =
  "/orders/:orderUUID/order_not_uploaded_slides/";

export const DROPBOXES_LIST_URL = "/dropboxes/";

export const IHC_ANTIBODIES_BASE_URL = "/ihc-antibodies/";

export const IHC_ANTIBODIES_EXTERNAL_URL = "/ihc-antibodies/:antibodyUUID?";

export const ORDER_DETAILS_URL = "/orders/:orderUUID/";

export const CONSULTATIONS_DETAILS_URL = "/consultations/:consultUUID/";
export const ACCEPT_ASSIGNED_CONSULTATION_URL =
  "/assigned_pathology_consultation_orders/:consultUUID/accept_assigned_consultation/";

export const DECLINE_ASSIGNED_CONSULTATION_URL =
  "/assigned_pathology_consultation_orders/:consultUUID/decline_assigned_consultation/";

export const START_CONSULTATION_URL =
  "/assigned_pathology_consultation_orders/:consultUUID/start_consultation/";

export const FINISH_CONSULTATION_URL =
  "/assigned_pathology_consultation_orders/:consultUUID/finish_consultation/";

export const ORDER_CHART_URL = "/orders/:orderUUID/chart/";

export const PROJECTS_URL = "/projects/";
export const PROJECT_SLIDES_URL = "/project/:projectUUID/slides";
export const SLIDES_LIST_URL = "/slides/";
export const SLIDES_LIST_URL_2 = "/search/";

export const ORDER_SLIDE_COMMENTS_URL = "/slides_comments/";
//export const ORDER_COMMENTS_URL = "/orders/:orderUUID/comments";
export const NEEDS_QC_SLIDE_UPDATE = "/needs_qc_slides/:slideUUID/";
export const SHARED_SLIDES_URL = "/shared_slides/:shareUUID/";
export const SLIDES_MAKE_VALID_POS_CONTROL =
  "/slides/:slideUUID/slides_make_valid_pos_control/";

export const BATCH_NEEDS_QC_SLIDES_UPDATE = "/batch_needs_qc_slides_update/";
export const RESET_NEEDS_QC_SLIDES = "/reset_needs_qc_slides/";
export const RECENTLY_CHANGED_QC_SLIDES = "/recently_changed_qc_slides/";

export const CONTACT_URL = "/contact/";
export const USER_DETAILS_URL = "/user_details/";
export const REFERRALS_URL = "/referrals/";
export const TEAM_USER_INVITE_URL = "/team_invites/";

// Order Form - Placing Orders
export const PLACE_ORDER_INITIAL_URL = "/forms/order/";
export const PLACE_ORDER_INITIAL_URL_V2 = "/forms/order/v2/";

export const PLACE_ORDER_INFO_URL = "/forms/order/:orderUUID/";

export const PLACE_ORDER_SAMPLES_STEP_URL = "/forms/order/:orderUUID/samples/";
export const PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL =
  "/forms/order/:orderUUID/samples_science/";
export const PLACE_ORDER_SLIDES_STEP_URL = "/forms/order/:orderUUID/slides/";
export const PLACE_ORDER_PRICE_REVIEW_STEP_URL =
  "/forms/order/:orderUUID/prices/";
export const PLACE_ORDER_BILLING_STEP_URL = "/forms/order/:orderUUID/billing/";
export const PLACE_ORDER_REVIEW_STEP_URL_V2 =
  "/forms/order/:orderUUID/review/v2/";
export const PLACE_ORDER_REVIEW_STEP_URL = "/forms/order/:orderUUID/review/";
export const PLACE_ORDER_REVIEW_FEEDBACK_URL =
  "/forms/order/:orderID/feedback/";

export const PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM =
  "/forms/pathology_consultation_order/";

export const PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM_INSTANCE_URL =
  "/forms/pathology_consultation_order/:consultUUID/";

export const PATHOLOGY_CONSULTATION_ORDERS = "/pathology_consultation_orders/";

export const PATHOLOGY_CONSULTATION_INFO_VIEW_URL =
  "/pathology_consultation_orders/:consultUUID/";

export const PATHOLOGIST_WORKLIST_URL = "/pathologist_worklist/";

export const COMPLETED_ORDER_REVIEW_FEEDBACK_URL =
  "/completed/order/:orderID/feedback/";

// Slide Viewers
export const SLIDE_VIEWER_URL = "/slide/:slideUUID/viewer/";
export const SLIDE_VIEWER_DEBUG_URL = "/slide/:slideUUID/viewer_debug/";
// a simple basic user of the viewer to debug
export const DEMO_SLIDE_VIEWER_URL = "/demo/slide/viewer/";
export const MULTI_SLIDE_VIEWER_URL = "/multi/slide/viewer/";
export const MRXS_SLIDE_VIEWER_URL = "/mrxs/slide/viewer/";

export const IF_VIEWER_URL = "/if_viewer/:orderUUID/if_slides/:slideUUID/";
export const SHARED_IF_VIEWER_URL = `/shared_if_slides/:shareUUID`;

// the legacy multislide viewer used state, this one will be based on URL params
// to allow people use it as favorites and
// a bit of an awful name tbh.
export const MULTI_SLIDE_VIEWER_PARAMS_URL =
  "/multi/slide/viewer/params/:slideUUIDs/";

export const INTERNAL_QC_VIEWER_URL = "/internal/quality_control/viewer/";

// hardcode this for the time being to appear on the sidebar to use as an order for feedback
export const TESTING_IHC_OPTIMIZATION_URL =
  "/orders/3ebd44f7-c08b-4be4-8110-659c73df53b7/ihc_optimizations/";
export const IHC_OPTIMIZATION_URL = "/orders/:orderUUID/ihc_optimizations/";
export const SHARE_SLIDE_URL = "/shared_slide/:shareSlideUUID/viewer/";
export const SLIDE_NAVIGATOR_URL = "/slide_navigator/";

export const PATHOLOGY_MAP_SEARCH = "/pathology_map/search/";
export const PATHOLOGY_MAP_SEARCH_2 = "/pathology_map/search_2/";
export const BLOCKS_URL = "/blocks_v1/";
export const BLOCKS_V2_URL = "/blocks/";
export const TEAM_BLOCKS_URL = "/teams/:teamUUID/blocks/";

export const ORDER_BARCODES = "/orders/:orderUUID/barcodes/";

// Login As
export const LOGIN_TOKEN = "/loginToken/:token/";
export const RESET_PASSWORD_URL = "/reset_password";
export const RESET_PASSWORD_CONFIRM_URL = "/reset_password_confirm/:uid/:token";

export const LISA_URL = `${backendURL}/lisa/`;

export const PROTOTYPE_URL = "/prototype/";

export const GLP_FORM_URL = "/glp/:orderUUID";
export const VERSAPAY_AUTH_URL =
  "https://secure.versapay.com/auth/saml_sp?partner=Histowiz";

export const SAMPLE_EDITOR_URL = "/sample_editor";
export const SAMPLES_CHANGELOG_URL = "/sample_editor/changelog";
