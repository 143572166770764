// this is a class because react-print only prints classes
import React from "react";
import { Grid } from "@mui/material";
import {
  OrderBillingTable,
  OrderSummaryTable,
  ServicesRequestedTable,
  ShippingTable,
} from "components/OrderForm/components/ReviewTables";
import { SampleList } from "components/OrderDetails/SamplesListComponent";
import {
  AdditionalServicesTable,
  EmbeddingCuttingInstructions,
} from "components/OrderForm/components/OrderDetailsTables";

export class ConsolidatedTabInstructions extends React.Component {
  render() {
    const { order, samples, shipments, classes } = this.props;

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={12}>
          <OrderSummaryTable order={order} alternative={true} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ServicesRequestedTable order={order} samples={samples} />
        </Grid>
        <Grid item xs={12}>
          <SampleList samples={samples} classes={classes} />
        </Grid>
        <Grid item xs={12}>
          <EmbeddingCuttingInstructions order={order} alternative={true} />
        </Grid>
        <Grid item xs={12}>
          <AdditionalServicesTable order={order} alternative={true} />
        </Grid>
        <Grid item xs={12}>
          <ShippingTable order={order} shipments={shipments} />
        </Grid>
        <Grid item xs={12}>
          <OrderBillingTable order={order} alternative={true} />
        </Grid>
      </Grid>
    );
  }
}
