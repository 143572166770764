import { Grid } from "@mui/material";
import { SortLabel } from "components/SlideGroups/Modals/components/SortLabel";
import {
  MODIFIED_DATE_SORT,
  NAME_SORT,
  SLIDES_COUNT_SORT,
} from "components/SlideGroups/utilities/constants";
import React from "react";
import { handleSortClick } from "components/SlideGroups/utilities/utilities";

export const HeaderWithSorts = ({ sort, setSort, classes }) => (
  <Grid container pl={1} className={classes.sortsContainer}>
    <Grid item xs={6} pl={2}>
      <SortLabel
        isActive={sort.field === NAME_SORT}
        label={"Group Name"}
        mode={sort.order}
        onClick={() => handleSortClick(setSort, NAME_SORT)}
        classes={classes}
      />
    </Grid>
    <Grid item xs={2} className={classes.centeredCell}>
      <SortLabel
        isActive={sort.field === SLIDES_COUNT_SORT}
        label={"Slide Count"}
        mode={sort.order}
        onClick={() => handleSortClick(setSort, SLIDES_COUNT_SORT)}
        classes={classes}
      />
    </Grid>
    <Grid item xs={3} className={classes.centeredCell}>
      <SortLabel
        isActive={sort.field === MODIFIED_DATE_SORT}
        label={"Last Modified"}
        mode={sort.order}
        onClick={() => handleSortClick(setSort, MODIFIED_DATE_SORT)}
        classes={classes}
      />
    </Grid>
    <Grid item xs={1} />
  </Grid>
);
