import React, { Fragment, useCallback, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { DefaultDialog } from "components/Modals/DefaultDialog";
import { SimpleSlideViewMemo } from "components/ImageViewer/SimpleSlideImageView";
import { SlideCommentsListAddDetails } from "components/OrderSlidesList/SlideCommentsListAddDetails";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { spinnerThemeStyle } from "components/Loading/Spinner/SpinnerStyles";
import { useFilteredComments } from "components/utilities/hooks/useFilteredComments";
import { useViewSlideModalStyles } from "./styles";
import { useLazyGetSlideCommentsQuery } from "store/apis/slideCommentsApi";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";

export const ViewSlideModal = ({
  showSlideComments = null,
  isOpen,
  slide,
  handleDeleteSlideComment,
  handleCreateSlideComment,
  handleCloseModal,
  additionalActions,
}) => {
  const { classes } = useViewSlideModalStyles();

  const slideUUID = slide?.uuid;

  const [
    getSlideComments,
    { data: slideComments, isLoading: isSlidesLoading, error },
  ] = useLazyGetSlideCommentsQuery();

  useQueryError(error);

  const updateSlideComments = useCallback(() => {
    getSlideComments({ slideUUID });
  }, [slideUUID]);

  useEffect(() => {
    slideUUID && updateSlideComments();
  }, [updateSlideComments, slideUUID]);

  const modalActions = () => {
    return (
      <Typography align={"right"}>
        {additionalActions && additionalActions()}
        <Button color="primary" onClick={handleCloseModal}>
          Close
        </Button>
      </Typography>
    );
  };

  const handleSetSlideComments = () => {
    // if we just had an update event, let's update the data
    updateSlideComments();
  };

  const filteredComments = useFilteredComments(slideComments);

  if (!isOpen) return null;

  const renderModal = () => {
    const dialogTitle = `Slide: ${slide.name}`;

    const slideViewerWidth = showSlideComments ? 8 : 12;

    return (
      <DefaultDialog
        open={isOpen}
        onClose={handleCloseModal}
        actions={modalActions}
        title={dialogTitle}
      >
        <Box height={"70vh"} width={"70vw"}>
          <Grid container spacing={3} className={classes.container}>
            <Grid item md={slideViewerWidth}>
              <SimpleSlideViewMemo
                slide={slide}
                showNavigator={false}
                id={slide.uuid}
                key={slide.uuid}
              />
            </Grid>
            {showSlideComments && (
              <Grid item md={4}>
                {isSlidesLoading ? (
                  <div style={spinnerThemeStyle}>
                    <Spinner />
                  </div>
                ) : (
                  <SlideCommentsListAddDetails
                    handleDeleteComment={handleDeleteSlideComment}
                    handleAddComment={handleCreateSlideComment}
                    slide={slide}
                    slideComments={filteredComments}
                    setSlideComments={handleSetSlideComments}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
