import { Box, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import React from "react";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  slider: {
    marginRight: "0.5rem",
  },
}));

export const PercentageSlider = ({ value, handleChange }) => {
  const { classes } = useStyles();

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Slider
        value={value}
        onChange={handleChange}
        size={"small"}
        className={classes.slider}
      />
      <Typography variant="body2">{value}%</Typography>
    </Box>
  );
};
