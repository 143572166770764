import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { getHashByTabValue } from "utilities/general";

export const useTabsNavigation = (tab) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.hash.replace("#", "") === tab) return;

    const handleRedirect = location.hash ? history.push : history.replace;

    handleRedirect({
      hash: getHashByTabValue(tab),
    });
  }, [tab]);
};
