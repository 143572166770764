import React, { Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles()({
  typographyText: {
    marginBottom: "0rem",
  },
  slideInformation: {
    marginBottom: "0rem",
  },
});

export const SlideNotes = ({ slide }) => {
  const { classes } = useStyles();

  if (!slide.external_notes) {
    return null;
  }

  return (
    <Fragment>
      <Typography
        variant={"h6"}
        className={classes.slideInformation}
        color={"secondary"}
      >
        Notes - {slide.external_notes}
      </Typography>
    </Fragment>
  );
};
