import { useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { Box, Container, Link } from "@mui/material";
import { DefaultImageViewer } from "components/ImageViewer/DefaultSlidesViewer";
import { slideViewerKey } from "components/OrderSlidesList/constants";
import { getWhiteLabelLogo } from "utilities/whitelabel";
import { Spinner } from "components/Loading/Spinner/Spinner";
import { useSharedSocialSlideStyles } from "./styles";
import { IFViewer } from "components/IFViewer/IFViewer";
import { useGetSocialSharedSlide } from "./hooks";

export const SharedSocialSlide = () => {
  const match = useRouteMatch();
  const { classes } = useSharedSocialSlideStyles();

  const logoToUse = getWhiteLabelLogo();
  const isIFViewer = match.path.includes("if_viewer");

  const { loading, slide, isLoggedIn } = useGetSocialSharedSlide();

  const containerRef = useRef();

  const currentViewer =
    isLoggedIn &&
    (isIFViewer ? (
      <IFViewer />
    ) : (
      <DefaultImageViewer
        slides={[slide]}
        isBookmarkFeatureEnabled
        setAnnotationsCount={() => {}}
      />
    ));

  const isLoading = loading || (!slide && !isIFViewer);

  return (
    <>
      <Container
        ref={containerRef}
        maxWidth={false}
        disableGutters
        className={classes.wrapper}
      >
        <Link
          href="https://www.histowiz.com"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.logoWrapper}
        >
          <img src={logoToUse} alt="Logo" className={classes.logo} />
        </Link>
        {isLoading ? (
          <Box className={classes.spinnerContainer}>
            <Spinner />
          </Box>
        ) : (
          <Box
            p={2}
            height={"100%"}
            key={slideViewerKey}
            className={classes.viewer}
            id="viewer-wrapper"
          >
            {currentViewer}
          </Box>
        )}
      </Container>
    </>
  );
};
