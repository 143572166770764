import { downloadMultiSlideSource } from "services/resources/slides";
import { useContext, useEffect, useState } from "react";
import { getLisaSlideLink } from "components/utilities/getLinks";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import useClipboard from "react-use-clipboard";
import { AppContext } from "services/context";
import {
  useCreateSlideCommentMutation,
  useDeleteSlideCommentMutation,
  useGetSlideCommentsQuery,
} from "store/apis/slideCommentsApi";
import { SLIDE_VIEWER_URL } from "constants/urls";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { slideCommentsSelector } from "store/slices/slideCommentsSlice";
import { useMakeSlideValidPosControlMutation } from "store/apis/slidesApi";
import { getPathologyMapSearchParamsURL } from "components/ImageViewer/utilities";
import { SUCCESS_MAKE_VALID_POS_CONTROL_MESSAGE } from "constants/successMessages";
import { FAIL_MAKE_VALID_POS_CONTROL_MESSAGE } from "constants/errorMessages";
import { useQueryError } from "utilities/hooks/useQueryError/useQueryError";

export const useCreateSlideComment = () => {
  const [createSlideCommentMutation, { error }] =
    useCreateSlideCommentMutation();

  useQueryError(error);

  const createSlideComment = (slideUUID, text, isInternal) => {
    const postParams = {
      slideUUID,
      text,
      isInternal,
    };

    return createSlideCommentMutation(postParams);
  };

  return {
    createSlideComment,
  };
};

export const useDeleteSlideComment = () => {
  const [deleteSlideComment] = useDeleteSlideCommentMutation();

  const { showError } = useSnackbar();

  const deleteComment = (comment) => {
    const prop = {
      slideUUID: comment.slide.uuid,
      commentUUID: comment.uuid,
    };
    return deleteSlideComment(prop)
      .unwrap()
      .catch(() => showError("Error deleting comment"));
  };

  return {
    deleteComment,
  };
};

export const useGetSlideComments = (slide) => {
  const { error } = useGetSlideCommentsQuery({ slideUUID: slide?.uuid });

  useQueryError(error);

  const comments = useSelector(slideCommentsSelector(slide.uuid));

  return {
    comments,
  };
};

export const useSlideActions = (currentSlide) => {
  const { showError, showSuccess } = useSnackbar();
  const { handleContextModalChange } = useContext(AppContext);
  const history = useHistory();

  const [createdSlideURL, setCreatedSlideURL] = useState();

  const [, setCreatedSlideLinkCopied] = useClipboard(createdSlideURL);

  const [makeSlideValidPosControl] = useMakeSlideValidPosControlMutation();

  useEffect(() => {
    if (createdSlideURL) {
      setCreatedSlideLinkCopied();
    }
  }, [createdSlideURL, setCreatedSlideLinkCopied]);

  const handleDownloadSlide = () => {
    const slides = [currentSlide];
    downloadMultiSlideSource({ slides });
  };

  const findSimilarSlides = () => {
    const url = getPathologyMapSearchParamsURL({ slide: currentSlide });
    window.open(url, "_blank");
  };

  const markSlideValidPosControl = () => {
    makeSlideValidPosControl({ slideUUID: currentSlide?.uuid })
      .unwrap()
      .then(({ data }) => {
        const slideURL = getLisaSlideLink({ slide: data });
        setCreatedSlideURL(slideURL);
        showSuccess(SUCCESS_MAKE_VALID_POS_CONTROL_MESSAGE);
      })
      .catch(() => showError(FAIL_MAKE_VALID_POS_CONTROL_MESSAGE));
  };

  const handleOpenPathologistConsultationModal = () => {
    handleContextModalChange("modalPathologistConsultationOpen")();
  };

  const viewSlide = () => {
    const url = SLIDE_VIEWER_URL.replace(":slideUUID", currentSlide.uuid);
    history.push(url);
  };

  return {
    findSimilarSlides,
    markSlideValidPosControl,
    handleDownloadSlide,
    handleOpenPathologistConsultationModal,
    viewSlide,
  };
};
