import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  collapseGridRows,
  selectFirstRow,
  selectGridRow,
} from "components/utilities/grid";
import { validatedSlidesColumnDefs } from "components/utilities/AgGridCols/AgGridColumns";
import {
  antibodiesDetailGridDefaultColDefs,
  antibodiesTablesDefaultColDef,
} from "components/AntibodiesList/constants";
import { backendURL } from "services/backendAPI";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";
import { useHistory } from "react-router";
import { IHC_ANTIBODIES_EXTERNAL_URL } from "constants/urls";

export const SingleAntibodyTable = ({
  rowData,
  isLoading,
  quickFilterText,
  columnDefs,
  getContextMenuItems,
  setDisplayRowData,
  isStaff,
  gridApi,
  setSlide,
  setGridApi,
  setDetailGridApi,
  selectedAntibody,
}) => {
  useAGGridOverlayControl(isLoading, rowData, gridApi);

  const history = useHistory();

  const nodesCount = gridApi?.getDisplayedRowCount();

  const getNodeByUUID = (gridApi, uuid) => {
    let matchingRowNode;

    gridApi.forEachNode((node) => {
      if (node.data?.uuid === uuid) {
        matchingRowNode = node;
      }
    });

    return matchingRowNode;
  };

  useEffect(() => {
    if (!selectedAntibody || !gridApi || !nodesCount) {
      return;
    }

    const matchingRowNode = getNodeByUUID(gridApi, selectedAntibody?.uuid);

    if (matchingRowNode) {
      matchingRowNode.setExpanded(true);
      const index = matchingRowNode.rowIndex;
      // Scroll a bit more to view expanded row
      const newIndex = index > nodesCount - 2 ? index : index + 1;
      // Avoid flushSync errors
      queueMicrotask(() => {
        gridApi.ensureIndexVisible(newIndex);
      });
    }
  }, [selectedAntibody, gridApi, nodesCount]);

  const hasSlides = (antibody) => {
    return antibody?.validated_slides?.length;
  };

  const isRowMaster = (data) => {
    return hasSlides(data);
  };

  const onGridReady = ({ api }) => {
    setGridApi(api);
    api.suppressNoRowsOverlay = true;
    api.hideOverlay();
  };

  const handleRowSelectionChanged = ({ node, api }) => {
    if (node.selected) {
      collapseGridRows(api, node);
      node.setExpanded(true);
    } else if (!api.getSelectedRows().length) {
      setSlide(undefined);
    }
  };

  const handleDetailGridReady = ({ api }) => {
    setDetailGridApi(api);
  };

  const handleFirstDetailGridDataRendered = ({ api }) => {
    selectFirstRow(api);
  };

  const handleDetailGridRowSelectionChanged = ({ node }) => {
    if (node.selected) {
      setSlide(node.data);
    }
  };

  const updateURL = (node) => {
    const selectedAntibodyURL = IHC_ANTIBODIES_EXTERNAL_URL.replace(
      ":antibodyUUID?",
      node.data.uuid
    );
    history.push(selectedAntibodyURL);
  };

  const handleRowGroupDisplayChanged = ({ node }) => {
    if (node.expanded) {
      node.setSelected(true);
    }
  };

  const getContextMenuItemSlide = (params) => {
    const row = params?.node?.data;

    if (!row) {
      return;
    }

    if (!isStaff) {
      return;
    }

    return getSingleContextMenuItemSlide({ row });
  };

  const getSingleContextMenuItemSlide = ({ row }) => {
    const slideID = row.id;
    const lisaLink = `${backendURL}/lisa/core/slide/${slideID}/change/`;
    const menuContext = [
      {
        name: "Open Slide in LISA",
        action: () => {
          if (row) {
            window.open(lisaLink, "_blank");
          }
        },
      },
    ];

    return menuContext;
  };

  const detailAntibodyRendererParams = {
    detailGridOptions: {
      columnDefs: validatedSlidesColumnDefs,
      defaultColDef: antibodiesDetailGridDefaultColDefs,
      rowSelection: "single",
      onGridReady: handleDetailGridReady,
      onFirstDataRendered: handleFirstDetailGridDataRendered,
      onRowSelected: handleDetailGridRowSelectionChanged,
      getContextMenuItems: getContextMenuItemSlide,
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.validated_slides);
    },
  };

  const handleFirstDataRendered = ({ api }) => {
    selectGridRow(api, ({ data }) => hasSlides(data));
  };

  const handleRowClicked = ({ data, api }) => {
    if (!hasSlides(data)) {
      collapseGridRows(api);
      api.deselectAll();
    }

    if (!data) return;
    const { uuid } = data;
    const node = getNodeByUUID(gridApi, uuid);
    node.setSelected(true);
    updateURL(node);
  };
  const groupDisplayType = "groupRows";
  return (
    <AgGridReact
      includeHiddenColumnsInQuickFilter
      onModelUpdated={setDisplayRowData}
      masterDetail
      isRowMaster={isRowMaster}
      detailRowHeight={210}
      enableSorting={true}
      enableFilter={true}
      quickFilterText={quickFilterText}
      enableColResize={true}
      rowDragManaged={true}
      onGridReady={onGridReady}
      rowGroupPanelShow="always"
      columnDefs={columnDefs}
      rowData={rowData}
      defaultColDef={antibodiesTablesDefaultColDef}
      context={{ isStaff }}
      getContextMenuItems={getContextMenuItems}
      groupDisplayType={groupDisplayType}
      onRowSelected={handleRowSelectionChanged}
      detailCellRendererParams={detailAntibodyRendererParams}
      onRowGroupOpened={handleRowGroupDisplayChanged}
      isRowSelectable={({ data }) => hasSlides(data)}
      groupRemoveSingleChildren={true}
      animateRows={true}
      rowSelection="single"
      rowClassRules={{
        "antibody-has-control-slides": (data) => !data.data,
        "antibody-has-children": (data) => !data.data,
        "antibody-has-parent": (data) => data.node.parent.rowIndex,
      }}
      onFirstDataRendered={handleFirstDataRendered}
      onRowClicked={handleRowClicked}
      overlayNoRowsTemplate={"<span>No data</span>"}
      suppressNoRowsOverlay={isLoading}
    />
  );
};
