import { backendAPI } from "services/backendAPI";
import { REFERRALS_RESOURCE_NAME } from "services/resources/apiConstants";

export const getReferrals = () => {
  return backendAPI.get(REFERRALS_RESOURCE_NAME).then((response) => {
    return response.data;
  });
};

export const postReferral = (email) =>
  backendAPI.post(REFERRALS_RESOURCE_NAME, email);
