import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";

import { CREATED_SORT, MODIFIED_SORT } from "./helpers/constants";

import { getScienceInfoColumn } from "components/SampleEditor/helpers/functions";
import { useSortPanelStyles } from "components/SampleEditor/styles";
import { SamplesFilter } from "components/SampleEditor/SamplesFilter";
import { SamplesSortButton } from "components/SampleEditor/SamplesSortButton";
import { ColumnModal } from "components/SampleEditor/ColumnModal";

export const SamplesTableHeader = ({ gridApi, setColumns }) => {
  const [isNewColumnOpen, setIsNewColumnOpen] = useState(false);

  const { classes } = useSortPanelStyles();

  return (
    <Box className={classes.wrapper}>
      <SamplesFilter />
      <Box className={classes.rightContainer}>
        <Typography
          className={`${classes.sortTitle} ${classes.sortItemWrapper}`}
        >
          SORT:
        </Typography>
        <SamplesSortButton />
        <SamplesSortButton param={MODIFIED_SORT} />
        <SamplesSortButton param={CREATED_SORT} />
      </Box>
      <Button variant="outlined" onClick={() => setIsNewColumnOpen(true)}>
        create new category
      </Button>
      {isNewColumnOpen && (
        <ColumnModal
          handleClose={() => setIsNewColumnOpen(false)}
          gridApi={gridApi}
          setColumns={setColumns}
          buildColumn={getScienceInfoColumn}
        />
      )}
    </Box>
  );
};
