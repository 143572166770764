import React, { Fragment } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { DROPBOXES_LIST_URL } from "constants/urls";
import { Button, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const useStyles = makeStyles()((theme) => ({
  deleteIconStyle: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    position: "absolute",
    marginLeft: "0.5rem",
  },
  marginTopStyle: { marginTop: "1rem" },
}));

export const ShipSpecifications = () => (
  <Fragment>
    When applicable, please ship all packages per these{" "}
    <a
      href={
        "http://histowiz.com/html/download/HistoWiz_shipping_instructions.pdf"
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      {" "}
      specifications.{" "}
    </a>
  </Fragment>
);

export const DropboxShipComponent = ({
  options,
  classes,
  dropboxSelected,
  handleDropboxChange,
}) => {
  return (
    <div>
      <div className={classes.dropboxSelectForm}>
        <Select
          options={options}
          isMulti={false}
          onChange={handleDropboxChange}
          value={dropboxSelected}
          clearable={false}
        />
      </div>
      <div>
        For more information on Dropboxes, please click{" "}
        <Link to={DROPBOXES_LIST_URL} target="_blank">
          {" "}
          here.
        </Link>
      </div>
    </div>
  );
};
export const BillingContactDisplay = ({ contact }) => {
  if (!contact) {
    return null;
  }

  // When we switch tabs, contact sometimes is then passed as a Proxy
  // object, so check if name exists on it -- if so, it's a valid object to display
  if (!contact["name"]) {
    return null;
  }

  const isCreditCard = !!contact.credit_card;

  return (
    <Fragment>
      <b>Name:</b> {contact.name}
      <br />
      {contact.email.length ? (
        <div>
          <b>Email:</b> {contact.email}
        </div>
      ) : null}
      <br />
      {!isCreditCard ? (
        <Fragment>
          {contact.phone_number.length ? (
            <div>
              <b>Phone Number:</b> {contact.phone_number}{" "}
            </div>
          ) : null}
          {contact.po_number.length ? (
            <div>
              <b>PO Number</b> {contact.po_number}{" "}
            </div>
          ) : null}
        </Fragment>
      ) : null}
      {isCreditCard ? (
        <div>
          <b>Credit Card</b> {contact.credit_card.brand}{" "}
          {contact.credit_card.last4}{" "}
        </div>
      ) : null}
      <br />
    </Fragment>
  );
};

export const ShippingAddressDisplay = ({ address }) => {
  if (!address) {
    return null;
  }

  // When we switch tabs, contact sometimes is then passed as a Proxy
  // object, so check if name exists on it -- if so, it's a valid object to display
  if (!address["name"]) {
    return null;
  }

  return (
    <div>
      <b>Name:</b> {address.name}
      <br />
      <b>Street Line 1:</b> {address.street_1}
      <br />
      <b>Street Line 2:</b> {address.street_2}
      <br />
      <b>Zip Code:</b> {address.zip_code}
      <br />
      <b>City:</b> {address.city}
      <br />
      <b>Country:</b> {address.country}
      <br />
      <b>Phone Number:</b> {address.phone_number}
      <br />
    </div>
  );
};
export const SelfServiceShipComponent = () => {
  return (
    <Fragment>
      <ShipSpecifications />
      <p>
        <br />
        HistoWiz Laboratories, Inc.
        <br />
        30-02 48th Ave, Suite 120 <br />
        Long Island City, NY 11101
        <br />
        <a
          href={
            "https://www.google.com/maps/place/HistoWiz/@40.6561173,-73.9504361,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25b68ad768ee5:0x298cf014db7a66f8!8m2!3d40.6561133!4d-73.9482421"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Map
        </a>
      </p>
    </Fragment>
  );
};

export const ClientFedexRequired = ({ name, setValue }) => {
  const onClick = () => {
    setValue(name, "Add Change to Invoice", {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Fragment>
      <Typography color={"error"}>
        A Client FedEx Number is required when shipping back samples or slides.
        If you would prefer to add the charge to your invoice, please click 'Add
        Charge to Invoice' instead.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size={"large"}
        onClick={onClick}
      >
        Add Charge To Invoice
      </Button>
    </Fragment>
  );
};

export const POAttachment = ({ order, handlePODelete }) => {
  const { classes } = useStyles();

  if (!order.po_attachment) {
    return null;
  }

  const baseFileName = order.po_attachment_name.split("/");
  const fileName = baseFileName.pop();
  const downloadURL = order.po_attachment;

  return (
    <div className={classes.marginTopStyle}>
      PO Attachment Uploaded! -{" "}
      <a href={downloadURL} download target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>
      <Tooltip title="Delete PO Attachment">
        <DeleteOutlineIcon
          className={classes.deleteIconStyle}
          onClick={handlePODelete}
        />
      </Tooltip>
    </div>
  );
};

export const QuoteAttachment = ({ order }) => {
  const { classes } = useStyles();
  const baseFileName = order.quote_attachment_name.split("/");
  const fileName = baseFileName.pop();
  const downloadURL = order.quote_attachment;

  return (
    <div className={classes.marginTopStyle}>
      Quote Attachment Uploaded! -{" "}
      <a href={downloadURL} download target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>
    </div>
  );
};
