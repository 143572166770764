import { Grid } from "@mui/material";
import { usePathologyMapSectionHeaderWrapperStyles } from "./styles";

export const PathologyMapSectionHeaderWrapper = ({
  children,
  className,
  ...props
}) => {
  const { classes } = usePathologyMapSectionHeaderWrapperStyles();

  return (
    <Grid
      container
      item
      className={`${classes.header} ${className}`}
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      {children}
    </Grid>
  );
};
