import React from "react";
import { Button } from "@mui/material";

import { useGlpFormStyles } from "components/GlpForm/styles";

export const GlpFooter = ({ disabled }) => {
  const { classes } = useGlpFormStyles();
  const { footer } = classes;

  return (
    <footer className={footer}>
      <Button
        type="submit"
        disabled={disabled}
        variant="contained"
        color="primary"
      >
        Submit GLP and/or pre-clinical Study to LIMS
      </Button>
      <span>
        Questions? Please feel free to call us at 347-221-1020 or email us
        w/this page link.
      </span>
    </footer>
  );
};
