export const PROJECTS_RESOURCE_NAME = "projects/";

export const PROJECT_SLIDES_URL = "projects/:projectUUID/project_slides/";
export const ORDER_FIXATIVES_RESOURCE_NAME = "order_fixatives/";

export const PURCHASE_ORDERS = "purchase_orders/";

export const ORDER_RESOURCE_NAME = "orders/";
export const BLOCK_RESOURCE_NAME = "blocks/";
export const BLOCK_COMBINE_ACTION_RESOURCE_NAME = "combine/";
export const ORDER_REQUEST_BACK_SHIPPING =
  "orders/:orderUUID/ship_back_slides_and_samples/";
export const ORDER_COMMENTS_RESOURCE_NAME =
  "/orders/:orderUUID/order_comments/";
export const ORDER_SLIDE_COMMENTS_RESOURCE_NAME =
  "/orders/:orderUUID/order_slide_comments/";

export const ORDER_CHART_ATTACHMENTS_RESOURCE_NAME =
  "orders/:orderUUID/order_chart_attachments/";
export const ORDER_CHART_DATA_RESOURCE_NAME =
  "image_analysis/attachments/:attachmentUUID";
export const ORDER_HISTORY_RESOURCE_NAME = "order_history/";

export const NOTIFY_CLIENT_ORDER_COMMENT_RESOURCE_NAME =
  "/orders/:orderUUID/order_comments_notify_customer/";

export const ORDER_REQUEST_FOR_CUSTOM_QUOTE = "order_request_for_custom_quote";
export const STRIPE_CARD_RESOURCE_NAME = "stripe_cards/";
export const PATHOLOGY_CONSULTATION_ORDERS = "pathology_consultation_orders/";
export const PATHOLOGY_CONSULTATION_TEAM_FILE_ATTACHMENTS =
  "pathology_consultation_orders/:consultUUID/team_file_attachments/";
export const PATHOLOGY_CONSULTATION_FINAL_REPORT_TEAM_FILE_ATTACHMENTS =
  "pathology_consultation_orders/:consultUUID/final_report_file_attachments/";

export const PATHOLOGY_CONSULTATION_PROTOCOL_FILE_ATTACHMENTS =
  "pathology_consultation_orders/:consultUUID/protocol_file_attachments/";

export const PATHOLOGIST_WORKLIST_ORDERS =
  "assigned_pathology_consultation_orders/";

export const PATHOLOGY_CONSULTATION_ORDER_INSTANCE_NAME =
  "pathology_consultation_orders/:consultUUID";

export const SHARED_ORDER_RESOURCE_NAME = "shared_orders/";
export const SHARED_SLIDES_GROUP_RESOURCE_NAME = "shared_slide_groups/";
export const ORDERS_SAMPLE_SCIENCE_INFO_ATTACHMENT =
  "/orders/:orderUUID/sample_science_info_attachment/";
export const ALL_ORDERS_IDS_NAME = "all_orders_ids/";

export const ORDERS_QUOTE_ATTACHMENT = "/orders/:orderUUID/quote_attachment/";
export const ORDERS_PO_UPLOAD_ATTACHMENT = "/orders/:orderUUID/po_attachment/";
export const ORDERS_FILE_ATTACHMENTS =
  "/orders/:orderUUID/order_file_attachments/";
export const TEAM_FILE_ATTACHMENTS = "/teams/:teamUUID/team_file_attachments/";

export const ORDER_NEWSFEED_RESOURCE_NAME = "/orders/:orderUUID/newsfeed/";

export const SLIDES_RESOURCE_NAME = "slides/";
export const IF_SLIDES_RESOURCE_NAME = "if_slides/";

export const PATHOLOGY_MAP_SLIDES_RESOURCE_NAME = "pathology_map_slides/";
export const PATHOLOGY_MAP_FILTER_OPTIONS_RESOURCE_NAME =
  "pathology_map_filter_options/";

export const PATHOLOGY_MAP_REQUESTED_SLIDE_INFO_RESOURCE_NAME =
  "pathology_map_requested_slide_info/:slideUUID/";

export const SLIDES_COMMENTS_RESOURCE_NAME = "slides/:slideUUID/comments/";
export const SLIDES_COMMENTS_SINGULAR_RESOURCE_NAME =
  "slides/:slideUUID/comments/:commentUUID/";
export const SHARED_SLIDES_COMMENTS_RESOURCE_NAME =
  "shared_slides/:shareUUID/comments/";

export const NEEDS_QC_SLIDES_RESOURCE_NAME = "/needs_qc_slides/";
export const TEAM_RESOURCE_NAME = "teams/";
export const TEAM_MEMBERS_RESOURCE_NAME = "teams/:teamUUID/members";
export const TEAM_NEWSFEED_RESOURCE_NAME = "teams/:teamUUID/team_newsfeed";
export const ORGANIZATION_RESOURCE_NAME = "/organizations/";
export const ORGAN_RESOURCE_NAME = "organs/";
export const BILLING_RESOURCE_NAME = "billing/";
export const ORDER_SHIPMENTS_RESOURCE_NAME = "shipments/";
export const SUBMIT_ORDER_FORM_RESOURCE_NAME = "submit_order_from_order_form/";
export const SIMPLE_ANTIBODIES_RESOURCE_NAME = "simple_antibodies/";
export const ORDER_ANTIBODIES_RESOURCE_NAME =
  "orders/:orderUUID/order_antibodies/";
export const ANTIBODIES_RESOURCE_NAME = "antibodies/";
export const MULTIPLEX_ANTIBODIES_RESOURCE_NAME = "multiplex_antibodies/";
export const ANTIBODIES_ATTACHMENTS_RESOURCE_NAME = "antibodies_attachments/";

export const SPECIAL_INSTRUCTIONS_ATTACHMENTS_RESOURCE_NAME =
  "special_instructions_attachments/";
export const SPECIES_RESOURCE_NAME = "species/";
export const SAMPLES_RESOURCE_NAME = "samples/";
export const SAMPLES_HISTORY_RESOURCE_NAME = "samples_history/";
export const SAMPLES_PAGINATED_RESOURCE_NAME = "samples_paginated/";
export const SAMPLES_SCIENCE_INFO = "sample_science_info/";
export const SAMPLES_SCIENCE_INFO_RESOURCE_NAME =
  "science_info_download_template/";
export const SAMPLES_UPDATED_FROM_FILE_RESOURCE_NAME =
  "/samples_batch_uploaded/";
export const SPECIAL_STAINS_RESOURCE_NAME = "special_stains/";
export const PANELS_RESOURCE_NAME = "panels/";
export const SIMPLE_PANELS_RESOURCE_NAME = "simple_panels/";

export const SAMPLE_SUBMISSIONS_RESOURCE_NAME = "sample_submissions/";
export const SAMPLE_SERVICES_REQUESTED = "sample_services_requested/";
export const SIMPLE_SAMPLE_SERVICES_REQUESTED =
  "simple_sample_services_requested/";
export const SAMPLE_ANTIBODY_REQUESTED = "sample_antibody_requested/";
export const SAMPLE_CHANNEL_FILTERS_REQUESTED =
  "sample_channel_filter_requested/";
export const SAMPLE_SPECIAL_STAIN_REQUESTED = "sample_special_stain_requested/";
export const SAMPLE_PANEL_REQUESTED = "sample_panel_requested/";
export const BILLING_CONTACTS_RESOURCE_NAME = "billing_contacts/";
export const TEAM_ADDRESSES_RESOURCE_NAME = "team_addresses/";
export const DROPBOXES_RESOURCE_NAME = "dropboxes/";
export const CHANNEL_FILTERS_RESOURCE_NAME = "channel_filters/";
export const REFERRALS_RESOURCE_NAME = "referrals/";

export const TEAM_USER_INVITE_RESOURCE_NAME = "team_invites/";
export const REQUEST_FEATURE_RESOURCE_NAME = "teams/feature_requests/";
export const ADD_USER_TO_TEAM_RESOURCE_NAME =
  "team_invites/:teamInviteUUID/users/";

export const REGISTRATION_RESOURCE_NAME = "rest-auth/registration/";
export const LOGIN_RESOURCE_NAME = "/rest-auth/login/";
export const RESET_PASSWORD_NAME = "/rest-auth/password/reset/";
export const RESET_PASSWORD_CONFIRM_NAME = "/rest-auth/password/reset/confirm/";

export const USER_DETAILS_RESOURCE_NAME = "/users/self/details/";
export const USER_PREFERENCES_RESOURCE_NAME = "/users/self/preferences/";

export const SLIDE_URL_RESOURCE_NAME = "slides/:slideUUID/";
export const SLIDE_ANNOTATIONS_RESOURCE_NAME =
  "slides/:slideUUID/slides_annotations/";
export const SLIDE_ANNOTATION_RESOURCE_NAME =
  "slides_annotations/:annotationUUID/";
export const EXPORT_ANNOTATIONS_IN_HALO_FORMAT =
  "slides_annotations/slides_annotations.zip";

export const SLIDE_ANNOTATION_COMMENTS_RESOURCE_NAME =
  "slides_annotations/:annotationUUID/slides_annotations_comments/";
export const SLIDE_ANNOTATION_COMMENT_RESOURCE_NAME =
  "slides_annotations_comments/:commentUUID/";

export const NEWSFEED_RESOURCE_NAME = "users/newsfeed/";

export const GLOBAL_ORDERS_SEARCH_RESOURCE_NAME = "orders_search/";
export const GLOBAL_SLIDES_SEARCH_RESOURCE_NAME = "slides_search/";
export const GLOBAL_SAMPLES_SEARCH_RESOURCE_NAME = "samples_search/";

export const GOOGLE_MAPS_AUTOCOMPLETE_SEARCH_RESOURCE_NAME =
  "google_maps/autocomplete/:address/";
export const GOOGLE_MAPS_ZIP_CODE_SEARCH_RESOURCE_NAME =
  "google_maps/zip_code/:zipCode/";

export const GOOGLE_MAPS_DETAILS_PLACE_NAME = "google_maps/details/:placeID/";
export const GOOGLE_MAPS_LAT_LONG_DETAILS_NAME =
  "google_maps/lat_lng_details/:lat/:lng/";

export const SLIDE_GROUPS_RESOURCE_NAME = "slide_groups/";
export const SLIDE_GROUP_SINGULAR_RESOURCE_NAME =
  "slide_groups/:slideGroupUUID/add/";
export const SLIDE_GROUP_SINGULAR_ADD_SLIDE_RESOURCE_NAME =
  "slide_groups/:slideGroupUUID/add/";
export const SLIDE_GROUP_SINGULAR_REMOVE_SLIDE_RESOURCE_NAME =
  "slide_groups/:slideGroupUUID/remove/";

export const TEAM_BLOCKS_RESOURCE_NAME = "teams/:teamUUID/blocks/";
export const TEAM_BLOCK_RESOURCE_NAME = `teams/:teamUUID/blocks/:blockUUID`;
export const BLOCK_NEW_ORDER_RESOURCE_NAME = `/blocks/create_order_from_previous_blocks/`;
export const BLOCK_ORDER_RESOURCE_NAME = `/blocks/add_blocks_to_existing_order/`;
export const TEAM_SAMPLES_RESOURCE_NAME = "teams/:teamUUID/samples/";
export const TEAM_UNMERGE_SAMPLES_RESOURCE_NAME = "blocks/unmerge_samples/";
export const TEAM_PREFERENCES = "/team_preferences/";
export const GCP_RESOURCE_NAME = "https://storage.googleapis.com";
export const GCP_FILES_PROXY_PREFIX = "gcp-files";
