import { EMAIL_VALIDATION_MESSAGE } from "constants/errorMessages";
import { EMAIL_VALIDATION_REGULAR } from "utilities/auth";
import { object, ref, string } from "yup";

export const signUpValidationSchema = object().shape({
  firstName: string().required("First name is required!"),
  lastName: string().required("Last name is required!"),
  email: string()
    .required("Please enter a email!")
    .matches(EMAIL_VALIDATION_REGULAR, EMAIL_VALIDATION_MESSAGE),
  city: string().required("Please enter a city!"),
  phoneNumber: string().required("Please enter a phone number!"),
  referral: string().required("Please enter the referral!"),
  referralDetails: string().required("Please enter the referral details!"),
  labName: string().required("Please enter a lab name!"),
  organization_uuid: string().required("Institution name is required!"),
  state: string().required("State is required!"),
  password: string()
    .min(8, "Password has to be greater than eight characters!")
    .required("Password is required!"),
  password_2: string()
    .oneOf([ref("password")], "Passwords do not match")
    .required("Password confirm is required"),
});

export const teamUserInviteValidationSchema = object().shape({
  firstName: string().required("First name is required!"),
  lastName: string().required("Last name is required!"),
  phoneNumber: string().required("Please enter a phone number!"),
  password: string()
    .min(8, "Password has to be greater than eight characters!")
    .required("Password is required!"),
  password_2: string()
    .oneOf([ref("password")], "Passwords do not match")
    .required("Password confirm is required"),
});
