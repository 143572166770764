import { useMemo } from "react";
import { Grid } from "@mui/material";
import { PathologyMapClearActiveFiltersButton } from "components/PathologyMap/PathologyMapActiveFilters/PathologyMapClearActiveFiltersButton";
import {
  useCurrentRefinements,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import { PathologyMapActiveFiltersItem } from "components/PathologyMap/PathologyMapActiveFilters/PathologyMapActiveFiltersItem";
import { setCurrentSearchQuery } from "store/slices/pathologyMapSlice";
import { useDispatch } from "react-redux";
import { usePathologyMapActiveFiltersItemsStyles } from "./styles";

export const PathologyMapActiveFiltersItems = () => {
  const { query, clear } = useSearchBox();
  const dispatch = useDispatch();
  const { items, refine } = useCurrentRefinements();
  const { classes } = usePathologyMapActiveFiltersItemsStyles();

  const activeFilters = useMemo(() => {
    return items.map(({ refinements }) => refinements).flat();
  }, [items]);

  const handleSearchClear = () => {
    clear();
    dispatch(setCurrentSearchQuery(""));
  };

  const isClearButtonEnabled = !!items.length || !!query;

  return (
    <Grid container item xs wrap={"nowrap"} direction="column" spacing={2}>
      <Grid
        direction="row"
        container
        xs
        item
        className={classes.itemsWrapper}
        height="100%"
        alignContent="baseline"
      >
        {query && (
          <PathologyMapActiveFiltersItem
            item={{ attribute: "search", value: query }}
            onClear={handleSearchClear}
          />
        )}
        {activeFilters.map((filter) => {
          return (
            <PathologyMapActiveFiltersItem
              key={`${filter.attribute}-${filter.value}`}
              item={filter}
              onClear={() => refine(filter)}
            />
          );
        })}
      </Grid>

      <Grid container item direction="row-reverse">
        <Grid item className={classes.clearButtonWrapper}>
          <PathologyMapClearActiveFiltersButton
            disabled={!isClearButtonEnabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
