import { backendAPI } from "services/backendAPI";
import FileSaver from "file-saver";
import { EXPORT_ANNOTATIONS_IN_HALO_FORMAT } from "services/resources/apiConstants";

export const downloadResourceByUrl = (url, urlAttribute = "qptiff_url") => {
  return backendAPI.get(url).then((response) => {
    window.location.assign(response.data[urlAttribute]);
  });
};

export const downloadOrderAnnotationsInHaloFormat = (slideUUIDs) => {
  const slideUUIDsUrlSection = slideUUIDs
    .reduce(
      (finalUrl, slideUUID) => finalUrl.concat(`slide_uuids=${slideUUID}&`),
      ""
    )
    .slice(0, -1);

  return backendAPI
    .get(`${EXPORT_ANNOTATIONS_IN_HALO_FORMAT}?${slideUUIDsUrlSection}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const filename = `halo_annotations.zip`;
      FileSaver.saveAs(blob, filename);
    });
};
