import {
  AVAILABLE_FILTERS_PER_PAGE_COUNT,
  MAX_FILTERS_COUNT,
  MAX_FILTERS_SEARCH_COUNT,
  PATHOLOGY_MAP_SLIDE_ATTRIBUTES_OPTIONS,
  SLIDE_NAME_CHARS_LIMIT,
} from "components/PathologyMap/constants/common";
import { getNormalizedSlideFileName } from "utilities/slides";

export const getRefinementLabelByType = (type, categoriesList) => {
  const refinement = categoriesList.find(
    (refinement) => refinement.refinementType === type
  );
  return refinement?.title ?? type;
};

const getScienceInfoSlideAttributes = (slide) => {
  const scienceInfoSlideAttributes = Object.entries(slide.science_info)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => ({
      label: key.replace("_", " "),
      value: typeof value !== "string" ? value?.toString() : value,
      attribute: key,
    }));

  return scienceInfoSlideAttributes;
};

export const getSlideAttributesFromSlide = ({ slide }) => {
  const slideAttributes = PATHOLOGY_MAP_SLIDE_ATTRIBUTES_OPTIONS.reduce(
    (result, { label, attribute, valueGetter }) => {
      const attributeValue = slide[attribute];
      if (attributeValue) {
        result.push({
          label,
          attribute,
          value: valueGetter ? valueGetter(slide) : attributeValue,
        });
      }
      return result;
    },
    []
  );

  if (slide.science_info) {
    const scienceInfoSlideAttributes = getScienceInfoSlideAttributes(slide);
    slideAttributes.push(...scienceInfoSlideAttributes);
  }

  return slideAttributes;
};

export const calculateSelectedFiltersSumCount = (selectedFilters) => {
  return selectedFilters.reduce((sum, selectedFilter) => {
    return sum + selectedFilter.refinements.length;
  }, 0);
};

export const getFiltersCountText = (filtersCount) => {
  const isPlusSignNeeded =
    filtersCount >= MAX_FILTERS_COUNT ||
    filtersCount === MAX_FILTERS_SEARCH_COUNT;
  const filtersCountText = isPlusSignNeeded ? `${filtersCount}+` : filtersCount;
  return filtersCountText;
};

export const toggleArrayElement = (array, element) => {
  if (array.includes(element)) {
    return array.filter((item) => item !== element);
  } else {
    return [...array, element];
  }
};

export const getHighlightedWords = (hit, attribute) => {
  const highlightedResult = hit._highlightResult[attribute];
  const highlightedWords = highlightedResult?.matchedWords || [];
  return highlightedWords;
};

export const getNormalizedSlideFileNameTruncate = (slide) => {
  const normalizedSlideName = getNormalizedSlideFileName(slide);
  const truncatedSlideName = normalizedSlideName.slice(
    0,
    SLIDE_NAME_CHARS_LIMIT
  );

  return truncatedSlideName;
};

export const sortFacetsResults = (facetsResult) => {
  return [...facetsResult].sort((a, b) => {
    if (b.count - a.count === 0) {
      return a.value.localeCompare(b.value, undefined, { sensitivity: "base" });
    }
    return b.count - a.count;
  });
};

export const getClearedFiltersAttributes = (selectedFilters) => {
  const clearedFilters = selectedFilters.map(({ attribute }) =>
    attribute.replace(/science_info\./gi, "")
  );

  return clearedFilters;
};

export const convertRefinementsListToFilters = (
  refinements,
  currentRefinement
) => {
  // ignoring current refinement cause otherwise search will not return expected results
  const filteredRefinements = refinements.filter(
    (refinement) => refinement.attribute !== currentRefinement
  );

  const filterGroups = filteredRefinements.map((refinementObj) => {
    return refinementObj.refinements.map((refinement) => {
      return `${refinement.attribute}:"${refinement.value}"`;
    });
  });

  const filterString = filterGroups
    .map((group) => `(${group.join(" OR ")})`)
    .join(" AND ");

  return filterString;
};

export const getNextPageFacetsResults = (prevResults, allResults) => {
  const newResults = allResults.slice(
    prevResults.length,
    prevResults.length + AVAILABLE_FILTERS_PER_PAGE_COUNT
  );

  return [...prevResults, ...newResults];
};

export const getSlideCardIndexResult = (idx, allResultsCount) => {
  const result = `${idx + 1}/${allResultsCount}`;
  return result;
};

export const hideNonFavoriteCategoriesAndSort = (
  allCategories,
  favoriteCategories
) =>
  allCategories
    .map((category) => {
      const favCategory = findFavoriteCategory(
        category.refinementType,
        favoriteCategories
      );

      return {
        ...category,
        isGroupEnd: true,
        createdAt: favCategory?.createdAt || null,
        isHidden: !favCategory,
      };
    })
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

export const hideInvisibleCategories = (allCategories, presetCategories) => {
  return allCategories.map((category) =>
    presetCategories.find(
      (presetCategory) =>
        presetCategory.refinementType === category.refinementType
    )
      ? category
      : { isHidden: true, ...category }
  );
};

export const findFavoriteCategory = (refinementType, favoriteCategories) =>
  favoriteCategories.find(
    (favCategory) => favCategory.refinementType === refinementType
  );
