import { AgGridReact } from "ag-grid-react";
import {
  AntibodyColumnDefs,
  MultiplexColumnDefs,
} from "components/utilities/AgGridCols/AgGridColumns";
import { resizeColumnsToFitScreen } from "components/utilities/grid";
import React, { useState } from "react";
import {
  antibodiesDetailGridDefaultColDefs,
  antibodiesTablesDefaultColDef,
} from "components/AntibodiesList/constants";
import { useAGGridOverlayControl } from "components/utilities/hooks/grid/useAGGridOverlayControl";

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: AntibodyColumnDefs,
    defaultColDef: antibodiesDetailGridDefaultColDefs,
    domLayout: "autoHeight",
  }, // (notice the comma here!) detailGridOptions is passed onto getDetailRowData
  getDetailRowData: function (params) {
    params.successCallback(params.data.markers);
  },
};

export const MultiplexAntibodyTable = ({
  rowData,
  quickFilterText,
  getContextMenuItems,
  setDisplayRowData,
  isStaff,
  isLoading,
}) => {
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = ({ api }) => {
    setGridApi(api);
  };

  const isRowMaster = (dataItem) => {
    return dataItem ? dataItem.markers.length > 0 : false;
  };

  useAGGridOverlayControl(isLoading, rowData, gridApi);

  return (
    <AgGridReact
      onGridReady={onGridReady}
      onModelUpdated={setDisplayRowData}
      masterDetail={true}
      isRowMaster={isRowMaster}
      enableSorting={true}
      enableFilter={true}
      quickFilterText={quickFilterText}
      enableColResize={true}
      columnDefs={MultiplexColumnDefs}
      defaultColDef={antibodiesTablesDefaultColDef}
      detailCellRendererParams={detailCellRendererParams}
      detailRowHeight={250}
      rowData={rowData}
      context={{ isStaff }}
      getContextMenuItems={getContextMenuItems}
      onFirstDataRendered={resizeColumnsToFitScreen}
      groupDisplayType={"groupRows"}
      groupRemoveSingleChildren={true}
      animateRows={true}
      rowClassRules={{
        "antibody-has-children": (data) => !data.data,
        "antibody-has-parent": (data) => data.node.parent.rowIndex,
      }}
      overlayNoRowsTemplate={"<span>No data</span>"}
      suppressNoRowsOverlay={isLoading}
    />
  );
};
