import {
  DATE_REQUEST_FORMAT,
  formatDatetimeStringToDates,
  MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
  MonthDateFormatString,
} from "utilities/dates_and_times";
import {
  servicesOptionsMapping,
  TumorDetails,
  TumorDetailsInverted,
} from "components/OrderForm/constants";
import { ML_QC_OPTIONS_RECORDS } from "components/QCViewer/constants";
import { isQCScoreFailed } from "components/ImageViewer/utilities";
import { SAMPLES_NAME_COLUMN_MAX_LIMIT } from "components/OrderForm/SamplesFormV2/constants";

// by doing this, we can highlight the entire rows because the custom stylers
// will use this default_row_color
//export const AG_GRID_BACKGROUND_DEFAULT_ROW_COLOR = { background: "white" };
const AG_GRID_BACKGROUND_DEFAULT_ROW_COLOR = { background: "inherit" };

// can't validate, return pink highlight to show faulty
const AG_GRID_BACKGROUND_ERROR_ROW_STYLING = { background: "#FFCCCC" };

export const slideDataSerializer = (slide) => {
  // for h_and_e / unstained use the default
  const slideData = { ...slide };
  slideData["stain"] = servicesOptionsMapping[slideData.stain_type];

  slideData.orderName = slideData.order.name;

  // for ihc and special stains, use the actual stain name
  if (slideData.stain_type === "ihc" && slideData.antibody) {
    slideData["stain"] = slideData.antibody.name;
  } else if (
    slideData.stain_type === "special_stain" &&
    slideData.special_stain
  ) {
    slideData["stain"] = slideData.special_stain.name;
  }

  return slideData;
};

export const PathologyMapSlideSerializer = (row) => {
  // for h_and_e / unstained use the default
  row["stain"] = servicesOptionsMapping[row.stain_type];

  // for ihc and special stains, use the actual stain name
  if (row.antibody) {
    row["stain"] = row.antibody.name;
  } else if (row.special_stain) {
    row["stain"] = row.special_stain.name;
  }

  return row;
};

export const OrderNameFormatter = ({ data }) => {
  if (data && data.order && data.order.name) {
    return data.order.name;
  }
};
export const OrderNameGetter = ({ data }) => {
  if (data && data.order && data.order.name) {
    const orderID = data.order.name.replace("Order ", "");

    try {
      return parseInt(orderID);
    } catch (err) {
      return data.order.name;
    }
  }
};
export const OrderDueDateFormatter = ({ data }) => {
  if (data && data.order) {
    return MonthDateFormatString(data.order.turnaround_due_datetime);
  }
};
export const SlideCreatedDateFormatter = ({ data }) => {
  if (data) {
    return MonthDateFormatString(data.created);
  }
};

export const CreatedYearDateFormatter = ({ data }) => {
  if (data) {
    return formatDatetimeStringToDates(
      data.created,
      MONTH_DAY_YEAR_WITH_COMMA_FORMAT
    );
  }
};

export const SampleNameFormatter = ({ data }) => {
  if (data && data.sample) {
    return data.sample.name;
  }
};

export const ModifiedDateFormatter = ({ data }) => {
  if (data) {
    return formatDatetimeStringToDates(data.modified, DATE_REQUEST_FORMAT);
  }
};

export const CreatedYearMonthDateFormatter = ({ data }) => {
  if (data) {
    return formatDatetimeStringToDates(data.created, DATE_REQUEST_FORMAT);
  }
};

export const StainNameFormatter = ({ data }) => {
  if (data) {
    return servicesOptionsMapping[data.stain_type];
  }
};

export const HiddenOrganNameFormatter = ({ data }) => {
  if (!data) {
    return;
  }
  if (data.validated_slides?.length) {
    return data.validated_slides
      .map((slide) => {
        return slide.sample?.organ?.name;
      })
      .join(", ");
  }
};

export const OrganNameFormatter = ({ data }) => {
  if (!data) {
    return;
  }
  if (data.sample && data.sample.organ) {
    return data.sample.organ.name;
  }
  if (data.organ) {
    return data.organ.name;
  }
};

export const formatGLPStatus = ({ value }) => {
  const isGLPOrder = value?.is_glp_order;
  const glpStatusToRender = isGLPOrder ? "✅ ❗GLP ❗ ✅" : "-";
  return glpStatusToRender;
};

export const SpeciesNameFormatter = ({ data }) => {
  if (!data) {
    return;
  }
  if (data.sample && data.sample.species) {
    return data.sample.species.name;
  }

  if (data.species) {
    return data.species.name;
  }
};
export const ProjectNameFormatter = ({ data }) => {
  return data?.project?.name;
};

export const IsUploadedFormatter = ({ data }) => {
  if (data && data.dzi_access_url) {
    return "Uploaded";
  }
};

export const TrueFalseFormatter =
  (value) =>
  ({ data }) => {
    if (data && data[value]) {
      return "True";
    } else {
      return "False";
    }
  };

export const SingleSpeciesFormatter = ({ data }) => {
  if (data && data.host_species) {
    return data.host_species.name;
  }
};

export const SubmittedInFormatter = ({ data }) => {
  if (data && data.submission) {
    return data.submission.name;
  }
};

export const CloneNumberNATextFormatter = ({ data }) => {
  if (data && data.clone_number) {
    if (data.clone_number === "N/A") {
      return "";
    } else {
      return data.clone_number;
    }
  }
};
export const ValidatedSpeciesFormatter = ({ data }) => {
  if (data && data.histowiz_validated_species) {
    const speciesName = data.histowiz_validated_species.map((data) => {
      return data["name"];
    });
    return speciesName.join(", ");
  }
};
export const ReactiveSpeciesFormatter = ({ data }) => {
  if (data && data.reactive_species) {
    const speciesName = data.reactive_species.map((data) => {
      return data["name"];
    });
    return speciesName.join(", ");
  }
};

export const AntibodyApplicationsFormatter = ({ data }) => {
  if (data && data.applications) {
    const labelName = data.applications.map((data) => {
      return data["name"];
    });
    return labelName.join(", ");
  }
};

export const AntibodyAltNamesFormatter = ({ data }) => {
  return data?.alternate_catalog_names?.length
    ? data.alternate_catalog_names.join(", ")
    : "N/A";
};

export const ResearchAreaFormatter = ({ data }) => {
  return data?.research_area?.length ? data.research_area.join(", ") : "N/A";
};

export const OrganValueFormatter = (params) => {
  const { data } = params;

  if (data && data.organ) {
    return data.organ.name;
  }
};

export const SubmittedInFormatterV2 = (params) => {
  const { data } = params;

  if (data && data.submission) {
    return data.submission.name;
  }
};

export const SimpleValueNameFormatter = (fieldName) => (params) => {
  const { data } = params;

  if (data && data[fieldName]) {
    return data[fieldName].name;
  }
};

export const RequiredCellStyler = (params) => {
  const value = params.value;

  const emptyTypes = [null, "", undefined];
  // if this is in one of the blank types, mark them as red
  if (emptyTypes.includes(value)) {
    return AG_GRID_BACKGROUND_ERROR_ROW_STYLING;
  } else if (Array.isArray(value) && value.length === 0) {
    // check if the default is an array and it's an empty array
    return AG_GRID_BACKGROUND_ERROR_ROW_STYLING;
  } else {
    return AG_GRID_BACKGROUND_DEFAULT_ROW_COLOR;
  }
};

export const SampleNameRequiredCellStyler = (params) => {
  const value = params.value;
  const emptyTypes = [null, "", undefined];
  // if this is in one of the blank types, mark them as red
  if (emptyTypes.includes(value)) {
    return AG_GRID_BACKGROUND_ERROR_ROW_STYLING;
  } else if (value.length > SAMPLES_NAME_COLUMN_MAX_LIMIT) {
    // sample names have a 12 character limit
    return AG_GRID_BACKGROUND_ERROR_ROW_STYLING;
  } else {
    return AG_GRID_BACKGROUND_DEFAULT_ROW_COLOR;
  }
};

export const SimpleValueNameGetterV3 = (params) => {
  const fieldName = params.colDef.field;

  const { data } = params;

  if (data && data[fieldName]) {
    return data[fieldName].name;
  }
};

export const SubmittedInValueSetter = (details) => (params) => {
  const updatedSubmission = details.find(
    (result) => result.name === params.newValue
  );

  params.data.submission = updatedSubmission;
  return true;
};

export const SamplesGridSimpleFormatter = (params) => {
  const { value } = params;
  let result = value;

  // do this to support how fields can be a String (when a user selects)
  // and when also an Array (how we join values together into an Array)
  if (Array.isArray(value)) {
    result = value.join(", ");
  }

  return result;
};

export const OrganValueSetter = (organDetails) => (params) => {
  const updatedOrgan = organDetails.find(
    (organ) => organ.name === params.newValue
  );
  params.data.organ = updatedOrgan;
  return true;
};

export const SpeciesValueSetter = (speciesDetails) => (params) => {
  const updatedSpecies = speciesDetails.find(
    (organ) => organ.name === params.newValue
  );
  params.data.species = updatedSpecies;
  return true;
};

export const GridArrayValueSelector = (params) => {
  // note: these is an issue with ag-grid, on the first time we enter information
  // newValue is frequently empty/null on the first operation
  const fieldName = params.colDef.field;

  let updatedData = [];
  const selectedValue = params.newValue;

  // if someone is dragging down a value, the defaults are frequently arrays
  // so when dragging down, just replace the previous data with the array
  if (Array.isArray(selectedValue)) {
    // we do a lot of slice operations here because otherwise, the reference
    // can cause some weird side effects when it's being dragged
    params.data[fieldName] = selectedValue.slice();
    return true;
  }

  // if the user re-clicked on a value that was already selected, ie. selecting H&E, but the value is [H&E]
  // then remove it from the list
  if (params.oldValue.includes(selectedValue)) {
    updatedData = params.oldValue.filter((item) => {
      return item !== selectedValue;
    });
    params.data[fieldName] = updatedData;
  } else {
    // if this is originally empty, add the selected value to the list
    const correctValues = params.colDef.cellEditorParams.values;
    if (selectedValue && correctValues.includes(selectedValue)) {
      // check for selectedValue - sometimes when copying and pasting, selectedValue is blank for the first
      // data editing cell operation for ag-grid ... seems like a bug ...
      params.oldValue.push(selectedValue);
    }

    params.oldValue.sort();

    // see slice comment above, slice creates a copy, otherwise aggrid passes by reference
    // and data is incorrect when being dragged down
    params.data[fieldName] = params.oldValue.slice();
  }

  return true;
};

export const AntibodiesRequestedGetter = ({ data }) => {
  // in pivot, data can be empty, add a check before
  if (!data) {
    return;
  }

  const { requested_antibodies } = data;
  if (!requested_antibodies || requested_antibodies.length === 0) {
    return;
  }

  const result = requested_antibodies.map((response) => {
    return response.antibody.display_name;
  });

  result.sort();

  const resultSerialized = result.join(", ");
  return resultSerialized;
};

export const IsTumorGetter = (params) => {
  const { data } = params;

  if (!data) {
    return;
  }

  const isTumorValue = data["is_tumor"];

  const result = TumorDetails[isTumorValue];
  return result;
};

export const IsTumorValueSetter = (params) => {
  const updatedValue = TumorDetailsInverted[params.newValue];
  params.data.is_tumor = updatedValue;

  return true;
};

export const IsTumorValueGetterFromOrder = (params) => {
  const sample = params?.data?.sample;

  if (!sample) {
    return;
  }

  const isTumorValue = sample["is_tumor"];
  return TumorDetails[isTumorValue];
};

export const getValueFromLookupCache = (
  fieldName,
  dataCache,
  requestedItems
) => {
  // for UX reasons, we store the values in ag-grid as Plain Strings, ie. "KSM76"
  // this allows the user to drag and drop / copy / export, etc.
  // however, this creates a problem for us when serializing data to the backend
  // we pass a cache to do a quick lookup of field values based on the itemName, "ie. KSM67"
  // and will pass back a list of UUIDs of whatever was requested

  let requestedItemsAsArray = requestedItems;
  if (!Array.isArray(requestedItemsAsArray)) {
    if (requestedItems.includes(",")) {
      requestedItemsAsArray = requestedItems.split(",");
    } else {
      requestedItemsAsArray = [requestedItems];
    }
  }

  return requestedItemsAsArray.reduce((result, requestedItemName) => {
    const item = dataCache?.find(
      (cachedItemDetails) => cachedItemDetails[fieldName] === requestedItemName
    );

    if (item) {
      result.push(item.uuid);
    }

    return result;
  }, []);
};

export const OverallQCScoreGetter = ({ data }) => {
  if (data === undefined) {
    return;
  }

  const qcValues = [
    data.ml_qc_blurry_score,
    data.ml_qc_folds_score,
    data.ml_qc_microvibrations_score,
    data.ml_qc_tissue_cracking_score,
    data.ml_qc_tissue_separation_score,
  ];
  const qcNumericValues = qcValues.filter(
    (value) => !isNaN(value) && value !== null
  );

  if (!qcNumericValues.length) {
    return;
  }

  const maxQCScore = Math.max(...qcNumericValues);

  return maxQCScore;
};

export const QCFailedReasonsCountGetter = ({ data }) => {
  if (data === undefined) {
    return;
  }

  let failedReasonsCount = 0;

  for (const option of ML_QC_OPTIONS_RECORDS) {
    const qcScore = data[option.scoreAttribute];
    const isQCFailed = isQCScoreFailed(qcScore);
    if (isQCFailed) {
      failedReasonsCount++;
    }
  }

  return failedReasonsCount;
};

export const SPECIAL_INSTRUCTIONS = "Special Instructions";
export const IA_PC_SPECIAL_INSTRUCTIONS = "IA/PC Special Instructions";
export const GROSSING_SPECIAL_INSTRUCTIONS = "Grossing Special Instructions";
export const EMBEDDING_SPECIAL_INSTRUCTIONS = "Embedding Special Instructions";
export const CUTTING_SPECIAL_INSTRUCTIONS = "Cutting Special Instructions";
export const IHC_STAINGING_SPECIAL_INSTRUCTIONS =
  "IHC Staining Special Instructions";
export const SCANNING_SPECIAL_INSTRUCTIONS = "Scanning Special Instructions";
export const SPECIAL_INSTRUCTIONS_ATTACHMENT =
  "Special Instructions Attachment";

export const serializeSpecialInstructions = (order) => {
  const specialInstructionsFields = {
    special_instructions: SPECIAL_INSTRUCTIONS,
    special_instructions_ia_pc: IA_PC_SPECIAL_INSTRUCTIONS,
    special_instructions_grossing: GROSSING_SPECIAL_INSTRUCTIONS,
    special_instructions_embedding: EMBEDDING_SPECIAL_INSTRUCTIONS,
    special_instructions_cutting: CUTTING_SPECIAL_INSTRUCTIONS,
    special_instructions_ihc_staining: IHC_STAINGING_SPECIAL_INSTRUCTIONS,
    special_instructions_scanning: SCANNING_SPECIAL_INSTRUCTIONS,
    special_instructions_attachment: SPECIAL_INSTRUCTIONS_ATTACHMENT,
  };

  const specialInstructionsKeys = Object.keys(specialInstructionsFields);

  const resultSpecialInstructions = [];

  Object.keys(order).forEach((key) => {
    if (specialInstructionsKeys.includes(key) && order[key]) {
      const checkbox = {
        field: key,
        label: specialInstructionsFields[key],
        text: order[key],
      };

      resultSpecialInstructions.push(checkbox);
    }
  });

  return resultSpecialInstructions;
};

export const serializeDataForCheckboxes = (order) => {
  return serializeSpecialInstructions(order).map((specialInstruction) => {
    return {
      ...specialInstruction,
      checked: false,
    };
  });
};

export const getCheckBoxesForBulkQCModal = () => {
  return {
    special_instructions_pdf: false,
    special_instructions_text: false,
  };
};
