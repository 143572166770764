import { VERTICAL_SCROLL_STYLE } from "themes/defaultMuiTheme";
import { makeStyles } from "tss-react/mui";
import {
  DARK_GREY,
  FORM_TEXT_COLOR,
  HISTOWIZ_PRIMARY_COLOR,
  LIGHT_GREY,
  TABS_GRADIENT,
  WHITE_SMOKE,
} from "utilities/colors";

export const useBlocksPageV2Styles = makeStyles()((theme) => ({
  newRowClass: {
    backgroundColor: "lightblue",
  },
  container: {
    flexWrap: "nowrap",
  },
  table: {
    width: "100%",
    height: "calc(100vh - 13rem)",
    background: "transparent",
    display: "grid",
    gridTemplateRows: "min-content min-content auto",
  },
  tableWrapper: {
    marginTop: "1.5rem",
    padding: "0 !important",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  imagesWrapper: {
    marginLeft: "1rem",
    marginTop: "1.5rem",
    backgroundColor: "white",
    height: "calc(100vh - 13rem)",
    display: "grid",
    gridTemplateRows: "min-content auto",
  },
  header: {
    display: "flex",
    gap: 20,
    alignItems: "center",
    marginLeft: "auto",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.3rem",
    opacity: 0.7,
    letterSpacing: "0.1rem",
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "0.25rem",
    opacity: 0.4,
  },
  blocksCounter: {
    fontSize: "1.5rem",
    opacity: 0.7,
  },
  imageSectionHeader: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  subHeader: {
    letterSpacing: 0,
    padding: "0.25rem 0 0 0.5rem",
  },
  slideCard: {
    textAlign: "center",
  },
  images: { display: "flex", justifyContent: "left", alignItems: "center" },
  searchInput: {
    padding: "0 1rem",
    width: "30rem",
    fontSize: "1rem",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  processButton: {
    padding: "0.25rem 1rem",
    fontSize: "0.75rem",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  processCounter: {
    fontSize: "0.85rem",
    margin: "0 0.5rem",
    width: "3rem",
    height: "1.5rem",
    color: FORM_TEXT_COLOR,
    borderRadius: "4px",
    background: "white",
    textAlign: "center",
  },
  blocksCount: {
    margin: "1rem",
    padding: "0.25rem",
    width: "15rem",
  },
  blocksCountWrapper: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    width: "35rem",
    height: "3.25rem",
    margin: "0.5rem 0.5rem 0 0",
    borderRadius: "4px",
    backgroundColor: theme.palette.background.main,
  },
  barcodeContainer: {
    overflow: "hidden",
    aspectRatio: "1 / 1",
    cursor: "pointer",
  },
  switch: {
    padding: "0.2rem 1rem",
  },
  counter: {
    width: "3rem",
    height: "1.5rem",
    border: "1px transparent",
    borderRadius: "4px",
    backgroundColor: "#838a8e",
    textAlign: "center",
    marginLeft: "1rem",
    color: WHITE_SMOKE,
  },
  barcodeText: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
  },
  slideSectionText: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.75rem",
    },
    fontWeight: "bold",
  },
  pageHeader: {
    background: TABS_GRADIENT,
    display: "flex",
    alignItems: "center",
    gap: 40,
    padding: "1.25rem 2rem",
    fontWeight: "bold",
    fontSize: "1.3rem",
    letterSpacing: "0.1rem",
    width: "100%",
  },
  teamSelect: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    textTransform: "uppercase",
  },
  tableHeader: {
    background: TABS_GRADIENT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1.5rem 2rem",
  },
  tableTitle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "0.1rem",
    opacity: 0.7,
  },
  tabContainer: {
    ...VERTICAL_SCROLL_STYLE,
    overflowY: "auto",
  },
  tab: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.5rem 0rem",
    cursor: "pointer",
    width: "9rem",
    fontSize: "0.875rem",
    textTransform: "lowercase",
    boxSizing: "border-box",
    position: "relative",

    [theme.breakpoints.down("lg")]: {
      width: "5rem",
    },
  },
  activeTab: {
    borderRadius: "0.375rem",
    border: "4px solid #8897F3",
    opacity: 0.6,
    background: "#FFF",
    padding: "0.5rem 0",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    fontWeight: 700,

    "&>div": {
      backgroundColor: HISTOWIZ_PRIMARY_COLOR,
    },
  },
  disabledTab: {
    cursor: "not-allowed",
  },
  tabs: {
    background: TABS_GRADIENT,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0.25rem 2rem",
  },
  label: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  tabCounter: {
    position: "absolute",
    right: "calc(50% - 1.1rem)",
    top: "0.3rem",
    fontSize: "7px",
    fontWeight: "bold",
    width: "0.8rem",
    height: "0.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: DARK_GREY,
    color: WHITE_SMOKE,
  },
  slideName: {
    fontSize: "0.625rem",
    textAlign: "left",
  },
  orderName: {
    fontSize: "0.625rem",
    opacity: 0.6,
  },
  noData: {
    padding: "1rem",
    margin: "0 auto",
    textAlign: "center",
  },
  formWrapper: {
    ...VERTICAL_SCROLL_STYLE,
    overflowY: "auto",
    height: "70vh",
    width: "75rem",
    maxWidth: "80vw",
  },
}));

export const useBlockToProcessStyles = makeStyles()({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: WHITE_SMOKE,
    height: "78vh",
  },
  tableContainer: {
    width: "100%",
    height: "65vh",
    margin: "1rem 1rem 0 1rem",
    borderBottom: `2px solid ${LIGHT_GREY}`,
  },
  tableHeadCell: {
    fontWeight: "bold",
  },
  actionButton: {
    margin: "0.5rem",
  },
  selectWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  actionSelect: {
    margin: "1rem",
    width: "10rem",
    height: "2rem",
  },
});
