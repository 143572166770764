import { getAddressByZipCode } from "services/resources/googleApi";

const getUSAddress = (addressData) => {
  const index = addressData.results.findIndex(
    (el) =>
      el.address_components[el.address_components.length - 1].long_name ===
      "United States"
  );
  if (index >= 0) return addressData.results[index].address_components;
};

const extractCity = (address) => {
  const cityTypes = [
    "locality",
    "sublocality",
    "administrative_area_level_3",
    "administrative_area_level_2",
  ];

  const city = address.find((element) =>
    cityTypes.some((type) => element.types.includes(type))
  );

  return city.long_name;
};

const extractState = (address) => {
  const state = address.find((element) =>
    element.types.includes("administrative_area_level_1")
  );
  return state.long_name;
};

export const setAddress = async (zip, setInputValue) => {
  if (zip?.length === 4) zip = "0" + zip;

  const { data } = await getAddressByZipCode(zip);
  if (data.status === "OK") {
    const address = getUSAddress(data);
    if (address) {
      setInputValue("city", extractCity(address));
      setInputValue("state", extractState(address));
    }
  }
};

export const getPostParamsForRegularSignup = (values) => {
  return {
    email: values.email,
    password: values.password,
    first_name: values.firstName,
    last_name: values.lastName,
    referrer: values.referral,
    referrer_detail: values.referralDetails,
    lab_name: values.labName,
    organization_uuid: values.organization_uuid,
    city: values.city,
    phone_number: values.phoneNumber,
    zip_code: values.zipCode,
    state: values.state,
  };
};
