import React, { Fragment } from "react";
import "css/OpenSeaDragon.css";
import { useHistory } from "react-router-dom";
import { SimpleSlideView } from "components/ImageViewer/SimpleSlideImageView";
import { Grid } from "@mui/material";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { histowizTheme } from "themes/defaultMuiTheme";
import { ORDER_DETAILS_URL } from "constants/urls";

const slideOSDGridStyle = { marginBottom: "1rem" };

const SlideOSD = (props) => {
  const { slide } = props;
  const BreadcrumbData = [
    {
      name: `${slide.order.name} Details`,
      color: histowizTheme.palette.primary.light,
      link: ORDER_DETAILS_URL.replace(":orderUUID", slide.order.uuid),
    },
    {
      name: slide.name,
      bold: true,
    },
  ];
  return (
    <Grid item key={slide.uuid} xs={6} style={slideOSDGridStyle}>
      <Breadcrumbs data={BreadcrumbData} divider=">" />
      <SimpleSlideView slide={slide} id={slide.uuid} showNavigator={false} />
    </Grid>
  );
};

export const MultiSlideView = () => {
  const history = useHistory();
  const slides = history.location.state?.slides || [];

  return (
    <Fragment>
      <Grid container spacing={8}>
        {slides.map((slide) => (
          <SlideOSD key={slide.uuid} slide={slide} />
        ))}
      </Grid>
    </Fragment>
  );
};
