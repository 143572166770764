import { DEMO_USER_EMAIL, DEMO_USER_PASSWORD } from "constants/emails";
import { useContext, useEffect } from "react";
import { loginAPIUser } from "services/resources/auth";
import { useRouteMatch } from "react-router-dom";
import { AppContext } from "services/context";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useLazyGetSharedSlideQuery } from "store/apis/slidesApi";

export const useGetSocialSharedSlide = () => {
  const match = useRouteMatch();
  const { isLoggedIn, updateIsLoggedIn } = useContext(AppContext);
  const { showError } = useSnackbar();

  const [getSharedSlide, { data: slide, isLoading }] =
    useLazyGetSharedSlideQuery();

  const slideId = match.params.shareUUID;

  const logInHandler = async () => {
    const demoParams = {
      email: DEMO_USER_EMAIL,
      password: DEMO_USER_PASSWORD,
    };

    try {
      await loginAPIUser(demoParams, () => {});
      updateIsLoggedIn();
      await getSlide();
    } catch (error) {
      if (error.response) {
        showError(error.response.data);
      }
    }
  };

  const getSlide = async () => {
    if (!slideId) return;

    getSharedSlide({ shareUUID: slideId });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      logInHandler();
      return;
    }
    getSlide();
  }, []);

  return {
    slide,
    loading: isLoading,
    isLoggedIn,
  };
};
