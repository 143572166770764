export const ANNOTATIONS_FOUND_NOTIFICATION_DURATION = 8000;
export const ANNOTATIONS_FOUND_NOTIFICATION_SUCCESS_MESSAGE =
  "Annotations available for this slide.";

export const DRAWING_TOOLS_TOOLTIPS = {
  RECTANGLE: "Rectangle",
  POLYGON: "Double-click to end polygon selection",
};

export const SLIDE_NAVIGATION_TOOLTIPS = {
  PREVIOUS: "Previous Slide",
  NEXT: "Next Slide",
};

export const SLIDE_ANNOTATIONS_ENABLE_TOOLTIP =
  "Activate annotation mode to view";
export const SLIDE_MEASURE_TOOLTIP =
  "Select two points by clicking on image to measure the distance. Press 'Z' to cancel current selection.";
export const SLIDE_MEASURE_TUTORIAL_TOOLTIP = "Measure Tool Tutorial";
export const SLIDE_ANNOTATION_TUTORIAL_TOOLTIP = "Annotation Mode Tutorial";
