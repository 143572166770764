import { ShoppingCartOutlined } from "@mui/icons-material";
import ImageOutlined from "@mui/icons-material/ImageOutlined";

export const columnDefs = [
  {
    field: "block.orderName",
    headerName: "ORDER ID",
    filter: true,
    sortable: false,
  },
  {
    field: "slidesCount",
    headerName: "SLIDES",
    width: 120,
    filter: true,
    sortable: false,
  },
  {
    field: "atHistowiz",
    headerName: "AT HISTOWIZ",
    valueGetter: ({ data }) => data && (data.atHistowiz ? "Yes" : "No"),
    filter: true,
    sortable: false,
  },
  {
    field: "submission",
    headerName: "SUBMISSION TYPE",
    filter: true,
    sortable: false,
  },
  {
    field: "organ",
    headerName: "ORGAN",
    filter: true,
    sortable: false,
  },
  {
    field: "species",
    headerName: "SPECIES",
    filter: true,
    sortable: false,
  },
  {
    field: "tumorType",
    headerName: "TUMOR TYPE",
    filter: true,
    sortable: false,
  },
  {
    field: "controlType",
    headerName: "CONTROL TYPE",
    filter: true,
    sortable: false,
  },
  {
    field: "created",
    headerName: "SUBMISSION DATE",
    filter: true,
  },
  {
    field: "modified",
    headerName: "LAST MODIFIED",
    filter: true,
    sortable: true,
    sort: "desc",
  },
];

export const defaultColDef = {
  flex: 1,
  minWidth: 110,
  filter: true,
  sort: "asc",
  sortable: true,
};
export const autoGroupColumnDef = {
  headerName: "PROJECT/BLOCK",
  minWidth: 240,
  showDisabledCheckboxes: true,
  cellRendererParams: {
    suppressCount: true,
    checkbox: true,
  },
};

export const SLIDES_SECTION_TITLE = "SLIDES CREATED FROM BLOCK:";

export const blockActions = {
  imageData: "image data",
  processingQueue: "processing queue",
};

export const blockActionsIcons = {
  imageData: <ImageOutlined />,
  processingQueue: <ShoppingCartOutlined />,
};
