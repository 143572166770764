import { useCallback, useEffect, useState } from "react";
import { getGCPData } from "services/resources/slides";
import { DELETE_SLIDE_COMMENT_SUCCESS_MESSAGE } from "constants/successMessages";
import {
  ADD_SLIDE_COMMENT_ERROR_MESSAGE,
  DELETE_SLIDE_COMMENT_ERROR_MESSAGE,
  FETCHING_SLIDES_ERROR_MESSAGE,
} from "constants/errorMessages";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { KEYS, KEYS_CODES } from "constants/keyboard";
import { useSelector } from "react-redux";
import { openSlideInFullscreenSelector } from "store/slices/slidesListSlice/slidesListSlice";
import { ROTATE_DIRECTIONS } from "components/ImageViewer/constants";
import { useClickHold } from "components/utilities/hooks/mouse/useClickHold";
import { useLongPressClick } from "components/utilities/hooks/mouse/useLongPressClick";
import {
  useCreateSlideCommentMutation,
  useDeleteSlideCommentMutation,
  useLazyGetSharedSlidesCommentsQuery,
  useLazyGetSlideCommentsQuery,
} from "store/apis/slideCommentsApi";
import OpenSeadragon from "openseadragon";

export const useMultiSlidesComments = (slides, isSharedPage) => {
  const { showError, showSuccess } = useSnackbar();
  //Here we use Map instead of Object because slide object is used as key
  const [slidesCommentsGroups, setSlidesCommentsGroups] = useState(new Map());
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);

  const [getSlideComments] = useLazyGetSlideCommentsQuery();
  const [getSharedSlidesComments] = useLazyGetSharedSlidesCommentsQuery();
  const [createSlideCommentMutation] = useCreateSlideCommentMutation();
  const [deleteSlideComment] = useDeleteSlideCommentMutation();

  useEffect(() => {
    if (!slides) return;

    const slidesForWhichCommentsShouldBeFetched = slides.filter(
      (slide) => !slidesCommentsGroups.get(slide)
    );

    const slidesCommentsPromises = slidesForWhichCommentsShouldBeFetched.map(
      (slide) =>
        isSharedPage
          ? getSharedSlidesComments({ shareUUID: slide.share_uuid }).unwrap()
          : getSlideComments({ slideUUID: slide.uuid }).unwrap()
    );

    if (slidesCommentsPromises.length) {
      setIsCommentsLoading(true);

      Promise.all(slidesCommentsPromises)
        .then((slideCommentsResponse) => {
          setSlidesCommentsGroups((prevGroups) => {
            slideCommentsResponse.forEach((comments, index) => {
              const slide = slidesForWhichCommentsShouldBeFetched[index];

              prevGroups.set(slide, {
                isOpened: false,
                uuid: slide.uuid,
                comments,
              });
            });

            return new Map(prevGroups);
          });
        })
        .catch(() => {
          showError(FETCHING_SLIDES_ERROR_MESSAGE);
        })
        .finally(() => setIsCommentsLoading(false));
    }
  }, [slides]);

  const handleDeleteComment = useCallback(
    (commentToDelete, slide) => {
      deleteSlideComment({
        slideUUID: commentToDelete.slide.uuid,
        commentUUID: commentToDelete.uuid,
      })
        .unwrap()
        .then(() => {
          setSlidesCommentsGroups((prevGroups) => {
            const group = prevGroups.get(slide);
            const updatedCommentsList = group.comments.filter(
              (comment) => comment.uuid !== commentToDelete.uuid
            );

            prevGroups.set(slide, {
              ...group,
              comments: [...updatedCommentsList],
            });

            return new Map(prevGroups);
          });

          showSuccess(DELETE_SLIDE_COMMENT_SUCCESS_MESSAGE);
        })
        .catch(() => showError(DELETE_SLIDE_COMMENT_ERROR_MESSAGE));
    },
    [setSlidesCommentsGroups]
  );

  const handleCreateComment = useCallback(
    (newSlideCommentText, slide, isInternal) => {
      createSlideCommentMutation({
        slideUUID: slide.uuid,
        text: newSlideCommentText,
        isInternal,
      })
        .unwrap()
        .then((response) => {
          if (response) {
            setSlidesCommentsGroups((prevGroups) => {
              const group = prevGroups.get(slide);

              prevGroups.set(slide, {
                ...group,
                comments: [response, ...group.comments],
              });

              return new Map(prevGroups);
            });
          }
        })
        .catch(() => showError(ADD_SLIDE_COMMENT_ERROR_MESSAGE));
    },
    [setSlidesCommentsGroups]
  );

  return {
    slidesCommentsGroups,
    isCommentsLoading,
    handleCreateComment,
    handleDeleteComment,
  };
};

const useRotateCallback = (
  id,
  direction,
  viewer,
  specificIncrement = undefined
) => {
  return useCallback(() => {
    const { viewport, rotationIncrement } = viewer;
    const increment = specificIncrement || rotationIncrement;

    if (viewport) {
      let rotation = viewport.getRotation();

      const defaultRotation = OpenSeadragon.positiveModulo(
        rotation + increment,
        360
      );

      const reversedRotation = OpenSeadragon.positiveModulo(
        rotation - increment,
        360
      );

      if (!viewport.flipped) {
        rotation =
          direction === ROTATE_DIRECTIONS.LEFT
            ? reversedRotation
            : defaultRotation;
      } else {
        rotation =
          direction === ROTATE_DIRECTIONS.LEFT
            ? defaultRotation
            : reversedRotation;
      }

      viewport.setRotation(rotation);
    }
  }, [direction, viewer, specificIncrement]);
};

export const useSideRotation = (viewerState, direction, id) => {
  const rotateCallbackHold = useRotateCallback(id, direction, viewerState);

  const rotateCallbackClick90 = useRotateCallback(
    id,
    direction,
    viewerState,
    90
  );

  const rotateCallbackHoldClick = useClickHold(rotateCallbackHold);

  return useLongPressClick(rotateCallbackClick90, rotateCallbackHoldClick);
};

export const useKeyboardShortcuts = (
  viewerState,
  toggleFullScreen,
  showSlidesNavigation,
  handleScreenshot,
  handlePreviousSlide,
  handleNextSlide
) => {
  const openInFullPage = useSelector(openSlideInFullscreenSelector);

  const leftRotationActions = useSideRotation(
    viewerState,
    ROTATE_DIRECTIONS.LEFT,
    null
  );
  const rightRotationActions = useSideRotation(
    viewerState,
    ROTATE_DIRECTIONS.RIGHT,
    null
  );

  const getViewerPageKeyDownHandlers = (e, viewer) => {
    return {
      [KEYS_CODES.ARROW_LEFT]: () => {
        if (showSlidesNavigation) {
          const isFullScreen = viewerState?.isFullPage();
          if (isFullScreen) {
            toggleFullScreen();
          }
          handlePreviousSlide(isFullScreen);
        }
        e.preventDefault();
        e.stopPropagation();
      },
      [KEYS_CODES.ARROW_RIGHT]: () => {
        if (showSlidesNavigation) {
          const isFullScreen = viewerState?.isFullPage();

          if (isFullScreen) {
            toggleFullScreen();
          }
          handleNextSlide(isFullScreen);
        }
        e.preventDefault();
        e.stopPropagation();
      },
      [KEYS_CODES.ESC]: () => viewer?.setFullPage(false),
    };
  };

  const getCanvasKeyDownHandlers = (e, viewer) => {
    const handlePan = (x, y) => {
      viewer.viewport.panTo(new OpenSeadragon.Point(x, y), true);
      viewer.viewport.applyConstraints();
      e.preventDefault();
      e.stopPropagation();
    };

    const getCenterX = (bounds) =>
      (bounds.getTopLeft().x + bounds.getTopRight().x) / 2;
    const getCenterY = (bounds) =>
      (bounds.getTopLeft().y + bounds.getBottomLeft().y) / 2;

    return {
      [KEYS_CODES.F]: () => {
        toggleFullScreen();
        e.preventDefault();
        e.stopPropagation();
      },
      [KEYS_CODES.P]: () => {
        const canvas = viewerState?.drawer?.canvas;
        const canvasSizes = {
          width: canvas?.width,
          height: canvas?.height,
        };
        handleScreenshot(null, canvasSizes);
      },
      [KEYS_CODES.Q]: () => {
        leftRotationActions.onMouseDown();
        e.preventDefault();
        e.stopPropagation();
      },
      [KEYS_CODES.E]: () => {
        rightRotationActions.onMouseDown();
        e.preventDefault();
        e.stopPropagation();
      },
      [KEYS.CONTROL + KEYS_CODES.ARROW_UP]: () => {
        const bounds = viewer.viewport.getBounds();
        const newY = Math.max(0, bounds.getTopLeft().y + bounds.height * 1.5);
        handlePan(getCenterX(bounds), newY);
      },
      [KEYS.CONTROL + KEYS_CODES.ARROW_DOWN]: () => {
        const bounds = viewer.viewport.getBounds();
        const newY = bounds.getBottomLeft().y - bounds.height * 1.5;
        handlePan(getCenterX(bounds), newY);
      },
      [KEYS.CONTROL + KEYS_CODES.ARROW_LEFT]: () => {
        const bounds = viewer.viewport.getBounds();
        const newX = Math.max(0, bounds.getTopLeft().x + bounds.width * 1.5);
        handlePan(newX, getCenterY(bounds));
      },
      [KEYS.CONTROL + KEYS_CODES.ARROW_RIGHT]: () => {
        const bounds = viewer.viewport.getBounds();
        const newX = Math.min(1, bounds.getTopRight().x - bounds.width * 1.5);
        handlePan(newX, getCenterY(bounds));
      },
    };
  };

  const getCanvasKeyUpHandlers = (e) => ({
    [KEYS_CODES.Q]: () => {
      leftRotationActions.onMouseUp();
      e.preventDefault();
      e.stopPropagation();
    },
    [KEYS_CODES.E]: () => {
      rightRotationActions.onMouseUp();
      e.preventDefault();
      e.stopPropagation();
    },
  });

  const callHandler = (e, handlersGetter, viewer) => {
    const tagsToExclude = ["INPUT", "TEXTAREA"];
    const keyCode = e.keyCode;
    const isCtrlPressed = e.ctrlKey;

    if (
      KEYS_CODES.hasOwnProperty(keyCode) ||
      openInFullPage ||
      tagsToExclude.includes(e.target.tagName) ||
      e.repeat
    ) {
      return;
    }

    const handlers = handlersGetter(e, viewer);
    if (isCtrlPressed) {
      handlers[KEYS.CONTROL + keyCode]?.(e);
    } else {
      handlers[keyCode]?.(e);
    }
  };

  useEffect(() => {
    if (!viewerState) return;

    const canvasKeyDownListener = (e) =>
      callHandler(e, getCanvasKeyDownHandlers, viewerState);
    const viewerPageKeyDownListener = (e) =>
      callHandler(e, getViewerPageKeyDownHandlers, viewerState);
    const viewerPageKeyUpListener = (e) =>
      callHandler(e, getCanvasKeyUpHandlers, viewerState);

    viewerState.container?.addEventListener("keydown", canvasKeyDownListener);
    window.addEventListener("keydown", viewerPageKeyDownListener);
    window.addEventListener("keyup", viewerPageKeyUpListener);

    return () => {
      viewerState.container?.removeEventListener(
        "keydown",
        canvasKeyDownListener
      );
      window.removeEventListener("keydown", viewerPageKeyDownListener);
      window.removeEventListener("keyup", viewerPageKeyUpListener);
    };
  }, [showSlidesNavigation, viewerState]);
};

export const useExternalAnnotations = (
  isExternalAnnotationsEnabled,
  dataURL,
  showError
) => {
  const [annotationAttachment, setAnnotationAttachment] = useState(null);

  const getDataAttachment = () => {
    getGCPData(dataURL)
      .then((data) => {
        setAnnotationAttachment(data || null);
      })
      .catch(() => {
        showError("Failed to load external annotations");
      });
  };

  useEffect(() => {
    if (!dataURL) {
      return;
    }

    getDataAttachment();
  }, [isExternalAnnotationsEnabled, dataURL]);

  return annotationAttachment;
};
