import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Grid, Tabs, Tab, Divider, Slider } from "@mui/material";
import shallow from "zustand/shallow";
import _ from "lodash";

import { Menu } from "components/IFViewer/components/Controller/components/Menu";
import { GlobalSelectionSlider } from "./components/GlobalSelectionSlider";
import {
  useChannelsStore,
  useImageSettingsStore,
  useLoader,
  useViewerStore,
} from "components/IFViewer/state";
import {
  checkIsChannelNegative,
  getSingleSelectionStats,
  getSingleSelectionStats2D,
  useWindowSize,
} from "components/IFViewer/utils";
import {
  CONTRAST_STEP,
  ERROR_PARSING_URL,
  GLOBAL_SLIDER_DIMENSION_FIELDS,
  IF_VIEWER_COLORS_INTENSITY,
  MAX_CONTRAST,
  MIN_CONTRAST,
  NON_EXISTENT_INDEX,
} from "components/IFViewer/constants";

import { ChannelController } from "components/IFViewer/components/Controller/components/ChannelController";
import { ColormapSelect } from "components/IFViewer/components/Controller/components/ColormapSelect";
import { LensSelect } from "components/IFViewer/components/Controller/components/LensSelect";
import { PanLockToggle } from "components/IFViewer/components/Controller/components/PanLockToggle";
import { SideBySideToggle } from "components/IFViewer/components/Controller/components/SideBySideToggle";
import { VolumeButton } from "components/IFViewer/components/Controller/components/VolumeButton";
import { ZoomLockToggle } from "components/IFViewer/components/Controller/components/ZoomLockToggle";
import { makeStyles } from "tss-react/mui";
import { SlideNavigatorToggle } from "components/IFViewer/components/Controller/components/PictureInPictureToggle";
import { ChannelPresetsTab } from "components/IFViewer/components/Controller/components/ChannelPresetsTab";
import {
  getFromLS,
  parseParamsForIFFromUrl,
  saveToLS,
  updateHash,
} from "components/OrderSlidesList/utilities";
import { OptionsTab } from "components/IFViewer/components/Controller/components/OptionsTab";
import { getAvaiableColorsMessage } from "components/IFViewer/helpers/functions";
import { updateOrder } from "services/resources/orders";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";

const useStyles = makeStyles()((theme) => ({
  grid: { width: "100%" },
  tabs: { height: "2rem", minHeight: "2rem" },
  tab: { fontSize: ".75rem", bottom: 12 },
  divider: { margin: "0.5rem 0" },
  resetIntensityButton: {
    alignSelf: "center",
    width: "100%",
    fontSize: ".75rem",
    padding: "5px",
    marginTop: "0.25rem",
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    borderRadius: "4px",
  },
  editPresetsButton: { margin: "auto" },
  columnLabel: { marginTop: "0.25rem", fontSize: "1.1rem" },
  arrowIcon: {
    fontSize: "2rem",
    marginInline: "1rem",
    color: "#bdbdbd",
  },
  channelsLabels: { marginTop: "0.25rem" },
  brightnessSlider: { width: "95%", margin: "0.5rem" },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const Controller = ({ slideData, isIFSocialSharingPage }) => {
  const { classes } = useStyles();

  const [
    channelsVisible,
    contrastLimits,
    colors,
    domains,
    selections,
    ids,
    setPropertiesForChannel,
    toggleIsOnSetter,
    removeChannel,
    colorsLocked,
    setColorsLocked,
  ] = useChannelsStore(
    (store) => [
      store.channelsVisible,
      store.contrastLimits,
      store.colors,
      store.domains,
      store.selections,
      store.ids,
      store.setPropertiesForChannel,
      store.toggleIsOn,
      store.removeChannel,
      store.colorsLocked,
      store.setColorsLocked,
    ],
    shallow
  );
  const loader = useLoader();

  const colormap = useImageSettingsStore((store) => store.colormap);
  const [
    channelOptions,
    useLinkedView,
    use3d,
    useColormap,
    useLens,
    isChannelLoading,
    setIsChannelLoading,
    removeIsChannelLoading,
    isViewerLoading,
    brightnessCutOff,
    setBrightnessCutOff,
    customChannelOptions,
  ] = useViewerStore(
    (store) => [
      store.computed.channelOptions,
      store.useLinkedView,
      store.use3d,
      store.useColormap,
      store.useLens,
      store.isChannelLoading,
      store.setIsChannelLoading,
      store.removeIsChannelLoading,
      store.isViewerLoading,
      store.brightnessCutOff,
      store.setBrightnessCutOff,
      store.customChannelOptions,
    ],
    shallow
  );
  const viewSize = useWindowSize();
  const { showSuccess, showError } = useSnackbar();
  const [takenColors, setTakenColors] = useState([]);
  // many of the if slides we are uploading it's incorrectly
  // guessing an image is rgb when it isnt, as a result
  // it doesn't show the channel controller
  //const isRgb = metadata && guessRgb(metadata);
  const isRgb = false;

  const resetIntensity = () => {
    ids.forEach(async (id, i) => {
      const selection = {
        ...selections[i],
        c: i,
      };

      const { contrastLimits } = await getSingleSelectionStats2D({
        loader,
        selection,
        brightnessCutOff,
        isNegative: checkIsChannelNegative(
          customChannelOptions,
          channelOptions,
          i,
          slideData?.uuid
        ),
      });
      setPropertiesForChannel(i, {
        contrastLimits,
      });
      setColorsLocked(i, {
        colorLocked: false,
        color: colors[i],
      });
    });

    localStorage.removeItem(IF_VIEWER_COLORS_INTENSITY);
  };

  const resetIntensityForChannel = (i, presets, slideUUID) => {
    const selection = {
      ...selections[i],
      c: i,
    };

    getSingleSelectionStats2D({
      loader,
      selection,
      brightnessCutOff,
      isNegative: presets.options[slideUUID]?.[channelOptions[i]]?.isNegative,
    }).then(({ contrastLimits }) => {
      setPropertiesForChannel(i, {
        contrastLimits,
      });
      setColorsLocked(i, {
        colorLocked: false,
        color: colors[i],
      });
    });

    const colorsLockedFromLS = getFromLS(IF_VIEWER_COLORS_INTENSITY);
    const colorsLocked = Object.values(colorsLockedFromLS ?? {});

    const currentColorFromLSIndex = colorsLocked.findIndex((colorLocked) =>
      _.isEqual(colorLocked.color, colors[i])
    );

    if (currentColorFromLSIndex !== NON_EXISTENT_INDEX) {
      const newColorsLocked = colorsLocked.filter(
        (colorLocked) => !_.isEqual(colorLocked.color, colors[i])
      );

      saveToLS(IF_VIEWER_COLORS_INTENSITY, newColorsLocked);
    }
  };

  const { shape, labels } = loader[0];
  const globalControlLabels = labels.filter((label) =>
    GLOBAL_SLIDER_DIMENSION_FIELDS.includes(label)
  );

  const availableColors = channelOptions.filter(
    (color) => !takenColors.includes(color)
  );

  const updateUrlHandler = () => {
    if (!takenColors.length) {
      setTakenColors(channelOptions.slice(0, ids.length));
      return;
    }

    const mainUrl = window.location.pathname;
    const hash = window.location.hash;

    if (!hash) return;

    const updatedParams = {
      takenColors: JSON.stringify(takenColors),
      channelsVisible: JSON.stringify(channelsVisible),
      colorsValue: JSON.stringify(contrastLimits),
      colorPallette: JSON.stringify(colors),
    };

    const updHash = updateHash(updatedParams);
    const newUrl = `${mainUrl}${updHash}`;

    //use to trigger rerender of Share component and set hash correctly
    window.location.replace(newUrl);
  };

  useEffect(() => {
    updateUrlHandler();
  }, [takenColors, channelsVisible, contrastLimits, colors]);

  const setValuesFromUrl = () => {
    const parsedParams = parseParamsForIFFromUrl();
    if (parsedParams.takenColors) {
      const initialTakenColors = decodeURI(parsedParams.takenColors);
      //current JSON constructions trigger updating of initial values in store
      try {
        JSON.parse(initialTakenColors).forEach((color, i) => {
          if (color !== channelOptions[i]) {
            onSelectionChange(color, i);
          }
        });
      } catch (e) {
        showError(ERROR_PARSING_URL);
      }
    }

    if (parsedParams.channelsVisible) {
      const initialChannelsVisible = decodeURI(parsedParams.channelsVisible);
      try {
        JSON.parse(initialChannelsVisible).forEach((value, i) => {
          if (value !== channelsVisible[i]) {
            toggleIsOnSetter(i);
          }
        });
      } catch (e) {
        showError(ERROR_PARSING_URL);
      }
    }

    if (parsedParams.colorsValue) {
      const initialColorsValue = decodeURI(parsedParams.colorsValue);

      try {
        JSON.parse(initialColorsValue).forEach((value, i) => {
          if (
            value?.[0] !== contrastLimits?.[i]?.[0] ||
            value?.[1] !== contrastLimits?.[i]?.[1]
          ) {
            setPropertiesForChannel(i, {
              contrastLimits: value,
            });
          }
        });
      } catch (e) {
        showError(ERROR_PARSING_URL);
      }
    }

    if (parsedParams.colorPallette) {
      const initialColorPalletteValue = decodeURI(parsedParams.colorPallette);
      console.log(initialColorPalletteValue);
      try {
        JSON.parse(initialColorPalletteValue).forEach((value, i) => {
          if (
            value?.[0] !== colors?.[i]?.[0] ||
            value?.[1] !== colors?.[i]?.[1]
          ) {
            setPropertiesForChannel(i, {
              colors: value,
            });
          }
        });
      } catch (e) {
        showError(ERROR_PARSING_URL);
      }
    }
  };

  useEffect(() => {
    setValuesFromUrl();
  }, []);

  const onSelectionChange = (value, i) => {
    const selection = {
      ...selections[i],
      c: channelOptions.indexOf(value),
    };
    setIsChannelLoading(i, true);

    getSingleSelectionStats({
      loader,
      selection,
      use3d,
      brightnessCutOff,
    }).then(({ domain, contrastLimits: newContrastLimit }) => {
      setPropertiesForChannel(i, {
        contrastLimits: newContrastLimit,
        domains: domain,
      });
      useImageSettingsStore.setState({
        onViewportLoad: () => {
          useImageSettingsStore.setState({
            onViewportLoad: () => {},
          });
          setIsChannelLoading(i, false);
        },
      });
      setPropertiesForChannel(i, { selections: selection });
      setTakenColors((prev) =>
        prev.map((color, index) => (index === i ? value : color))
      );
    });
  };

  const onChannelOptionChange = useCallback(
    (e, name, i) => {
      if (!slideData?.order?.uuid) return;

      const value = e.target.checked;
      const newOptions = { ...customChannelOptions.options };
      newOptions[slideData.uuid] = { ...newOptions[slideData.uuid] };
      newOptions[slideData.uuid][name] = {
        ...newOptions[name],
        isNegative: value,
      };
      const presets = { ...customChannelOptions, options: newOptions };
      updateOrder(slideData.order.uuid, { preferences: presets })
        .then(() => {
          useViewerStore.setState({ customChannelOptions: presets });
          resetIntensityForChannel(i, presets, slideData.uuid);
          showSuccess("Channel options updated");
        })
        .catch(() => {
          showError("Failed to update channel options");
        });
    },
    [customChannelOptions, slideData?.order?.uuid]
  );

  const channelControllers = ids.map((id, i) => {
    const toggleIsOn = () => toggleIsOnSetter(i);
    const handleSliderChange = (e, v) =>
      setPropertiesForChannel(i, { contrastLimits: v });
    const handleRemoveChannel = () => {
      removeChannel(i);
      removeIsChannelLoading(i);
    };

    const handleLockColor = (e) => {
      const colorLocked = e.target.checked;
      const { color } = colorsLocked[i];

      setColorsLocked(i, {
        color,
        colorLocked,
        contrastLimitsLocked: contrastLimits[i],
      });

      const existingColorsLocked = Object.values(
        getFromLS(IF_VIEWER_COLORS_INTENSITY)
      );

      const currentColorFromLSIndex = existingColorsLocked.length
        ? existingColorsLocked.findIndex((colorLocked) =>
            _.isEqual(colorLocked.color, color)
          )
        : null;

      const isCurrentColorAmongSaved =
        currentColorFromLSIndex && currentColorFromLSIndex !== -1;

      const colorsList = isCurrentColorAmongSaved
        ? existingColorsLocked
        : colorsLocked;
      const indexToChange = isCurrentColorAmongSaved
        ? currentColorFromLSIndex
        : i;

      saveToLS(IF_VIEWER_COLORS_INTENSITY, {
        ...colorsList,
        [indexToChange]: {
          color,
          colorLocked,
          contrastLimitsLocked: colorLocked ? contrastLimits[i] : undefined,
        },
      });
    };

    const handleColorSelect = (color) => {
      setPropertiesForChannel(i, { colors: color });
    };

    const name = channelOptions[selections[i].c];

    return (
      <Grid
        key={`channel-controller-${name}-${id}`}
        className={classes.grid}
        item
      >
        <ChannelController
          index={i}
          name={name}
          onSelectionChange={onSelectionChange}
          channelsVisible={channelsVisible[i]}
          toggleIsOn={toggleIsOn}
          handleSliderChange={handleSliderChange}
          domain={domains[i]}
          slider={contrastLimits[i]}
          color={colors[i]}
          handleRemoveChannel={handleRemoveChannel}
          handleColorSelect={handleColorSelect}
          isLoading={isChannelLoading[i]}
          isColorLocked={colorsLocked[i]?.colorLocked}
          handleLockColor={handleLockColor}
          availableColors={availableColors}
          onChannelOptionChange={(e, name) => onChannelOptionChange(e, name, i)}
          customChannelOptions={customChannelOptions}
          slideUUID={slideData?.uuid}
        />
      </Grid>
    );
  });

  const globalControllers = globalControlLabels.map((label) => {
    const size = shape[labels.indexOf(label)];
    // Only return a slider if there is a "stack."
    return size > 1 ? (
      <GlobalSelectionSlider key={label} size={size} label={label} />
    ) : null;
  });
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const handleBrightnessChange = (e, v) => {
    setBrightnessCutOff(v);
    ids.forEach(async (id, i) => {
      const selection = {
        ...selections[i],
        c: i,
      };

      const { contrastLimits } = await getSingleSelectionStats2D({
        loader,
        selection,
        brightnessCutOff: v,
        isNegative: checkIsChannelNegative(
          customChannelOptions,
          channelOptions,
          i,
          slideData?.uuid
        ),
      });
      setPropertiesForChannel(i, {
        contrastLimits,
      });
    });
  };

  const isLensSelectorEnabled =
    useLens && !colormap && !use3d && shape[labels.indexOf("c")] > 1;

  return (
    <Menu maxHeight={viewSize.height}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        aria-label="tabs"
        indicatorColor="primary"
        textColor="inherit"
        className={classes.tabs}
      >
        <Tab label="Channels" className={classes.tab} />
        {/*<Tab label="Volume" className={classes.tab} />*/}
        {!isIFSocialSharingPage && (
          <Tab label="Options" className={classes.tab} />
        )}
        {!isIFSocialSharingPage && (
          <Tab label="Presets" className={classes.tab} />
        )}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {useColormap && <ColormapSelect />}
        {isLensSelectorEnabled && (
          <LensSelect
            channelOptions={selections.map((sel) => channelOptions[sel.c])}
          />
        )}
        {!use3d && globalControllers}
        {!isViewerLoading && !isRgb ? (
          <Grid container paddingY="1rem">
            {channelControllers}
            {getAvaiableColorsMessage(availableColors)}
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            {!isRgb && <CircularProgress />}
          </Grid>
        )}
        Brightness
        <Slider
          value={brightnessCutOff}
          valueLabelDisplay="auto"
          onChange={handleBrightnessChange}
          size="small"
          min={MIN_CONTRAST}
          max={MAX_CONTRAST}
          step={CONTRAST_STEP}
          className={classes.brightnessSlider}
        />
        <Button
          id="resetIntensityButton"
          onClick={resetIntensity}
          className={classes.resetIntensityButton}
        >
          Reset colors intensity
        </Button>
        {/*{!isRgb && <AddChannel />}*/}
      </TabPanel>
      {/*<TabPanel value={tab} index={1}>*/}
      {/*  {<RenderingModeSelect />}*/}
      {/*  {<Slicer />}*/}
      {/*  {<CameraOptions />}*/}
      {/*</TabPanel>*/}
      <TabPanel value={tab} index={1}>
        <OptionsTab ifSlide={slideData} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ChannelPresetsTab
          order={slideData?.order}
          classes={classes}
          setTakenColors={setTakenColors}
        />
      </TabPanel>
      <Divider className={classes.divider} />
      <VolumeButton />
      <SlideNavigatorToggle />
      <SideBySideToggle />
      {useLinkedView && !use3d && (
        <>
          <ZoomLockToggle />
          <PanLockToggle />
        </>
      )}
    </Menu>
  );
};
