import { BetaIcon } from "components/Shared/Icons/BetaIcon";
import { WhiteboardIcon } from "images/icons/WhiteboardIcon";
import React from "react";
import { SlideViewerToolbarToggleButton } from "components/ImageViewer/components/ImageViewerToolbar/Buttons/SlideViewerToolbarToggleButton";
import { useDrawingModeButtonStyles } from "components/ImageViewer/components/ImageViewerToolbar/styles";

export const DrawingModeButton = ({ onClick, drawModeEnabled }) => {
  const drawingModeStatus = drawModeEnabled ? "ON" : "OFF";
  const { classes } = useDrawingModeButtonStyles();

  return (
    <SlideViewerToolbarToggleButton
      endIcon={<BetaIcon />}
      startIcon={<WhiteboardIcon width={"1.2rem"} height={"1.1rem"} />}
      onClick={onClick}
      className={classes.buttonWrapper}
      isSelected={drawModeEnabled}
      fullWidth
    >
      <p>DRAWING MODE: {drawingModeStatus}</p>
    </SlideViewerToolbarToggleButton>
  );
};
