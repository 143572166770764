import Grid from "@mui/material/Grid";
import {
  getNormalizedSlideFileNameTruncate,
  getSlideCardIndexResult,
} from "components/PathologyMap/utilities/common";
import { PathologyMapSectionHeaderWrapper } from "components/PathologyMap/Shared/PathologyMapSectionHeaderWrapper";
import { DefaultPathologyMapTypography } from "components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography";
import {
  PATHOLOGY_MAP_SLIDE_CARD_CONTENT_TYPOGRAPHY_PROPS,
  PATHOLOGY_MAP_SLIDE_CARD_HEADER_TYPOGRAPHY_PROPS,
} from "components/PathologyMap/constants/styles";
import { usePathologyMapSlideCardHeaderStyles } from "./styles";

export const PathologyMapSlideCardHeader = ({ slide, resultsCount, index }) => {
  const { classes } = usePathologyMapSlideCardHeaderStyles();

  const slideName = getNormalizedSlideFileNameTruncate({
    name: slide.name,
    order: { id: slide.order_id },
  });

  const result = getSlideCardIndexResult(index, resultsCount);

  return (
    <PathologyMapSectionHeaderWrapper
      alignItems="flex-start"
      className={classes.header}
    >
      <Grid item container xs={8} marginLeft="2rem" direction="column">
        <Grid item>
          <DefaultPathologyMapTypography
            {...PATHOLOGY_MAP_SLIDE_CARD_HEADER_TYPOGRAPHY_PROPS}
          >
            order / name
          </DefaultPathologyMapTypography>
          <DefaultPathologyMapTypography
            {...PATHOLOGY_MAP_SLIDE_CARD_CONTENT_TYPOGRAPHY_PROPS}
          >
            {slide.order_id} / {slideName}
          </DefaultPathologyMapTypography>
        </Grid>
      </Grid>
      <Grid item container xs direction="column" alignContent="flex-end">
        <Grid item>
          <DefaultPathologyMapTypography
            {...PATHOLOGY_MAP_SLIDE_CARD_HEADER_TYPOGRAPHY_PROPS}
          >
            result
          </DefaultPathologyMapTypography>
        </Grid>
        <Grid item>
          <DefaultPathologyMapTypography
            {...PATHOLOGY_MAP_SLIDE_CARD_CONTENT_TYPOGRAPHY_PROPS}
          >
            {result}
          </DefaultPathologyMapTypography>
        </Grid>
      </Grid>
    </PathologyMapSectionHeaderWrapper>
  );
};
