import { useRef } from "react";

export const useClickHold = (callback, delay = 50) => {
  const mouseInterval = useRef(null);

  const handleActionStart = () => {
    if (!mouseInterval.current) {
      mouseInterval.current = setInterval(callback, delay);
    }
  };

  const handleActionStop = () => {
    if (mouseInterval.current) {
      clearInterval(mouseInterval.current);
      mouseInterval.current = null;
    }
  };

  return {
    onMouseDown: handleActionStart,
    onMouseUp: handleActionStop,
  };
};
