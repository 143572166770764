import { backendAPI } from "services/backendAPI";
import {
  ORDER_CHART_ATTACHMENTS_RESOURCE_NAME,
  ORDER_CHART_DATA_RESOURCE_NAME,
} from "services/resources/apiConstants";

export const getChartData = (attachmentUUID) => {
  const url = ORDER_CHART_DATA_RESOURCE_NAME.replace(
    ":attachmentUUID",
    attachmentUUID
  );

  return backendAPI.get(url);
};

export const getAvailableDataTypes = (orderUUID) => {
  const url = ORDER_CHART_ATTACHMENTS_RESOURCE_NAME.replace(
    ":orderUUID",
    orderUUID
  );

  return backendAPI.get(url);
};
