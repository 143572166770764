import {
  PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
  PATHOLOGY_MAP_SELECTED_ITEM_BG_COLOR,
  PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR,
  PATHOLOGY_MAP_FILTERS_SEARCH_BG_COLOR,
} from "components/PathologyMap/constants/styles";

import { makeStyles } from "tss-react/mui";

export const usePathologyMapFilterPropertiesItemStyles = makeStyles()(() => ({
  filterItem: {
    background: "transparent",
    padding: "0.2rem 0.8rem 0.2rem 2.8rem",
    //setting transparent border to avoid jumping on hover
    borderLeft: "0.2rem solid transparent",
    borderRight: "0.2rem solid transparent",
    transition: "background-color .3s ease",
    "&:hover": {
      cursor: "pointer",
      borderLeftColor: PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
      borderRightColor: PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
      transition: "border .3s ease",
    },
  },
  selectedItem: {
    background: PATHOLOGY_MAP_SELECTED_ITEM_BG_COLOR,
  },
  icon: {
    opacity: 0.2,
    textAlign: "end",
    marginTop: "0.3rem",
  },
}));

export const usePathologyMapFilterPropertiesItemsStyles = makeStyles()(() => ({
  itemsWrapper: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const usePathologyMapFilterPropertiesSearchStyles = makeStyles()(() => ({
  searchInput: {
    padding: "0.25rem 1rem 0.25rem 3rem",
    borderRadius: "3px",
    background: PATHOLOGY_MAP_FILTERS_SEARCH_BG_COLOR,
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR}`,
  },
}));
