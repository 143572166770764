import Box from "@mui/material/Box";
import { SortLabel } from "components/SlideGroups/Modals/components/SortLabel";
import { Comment } from "./Comment";
import { Typography } from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";
import { CREATED_DATE_SORT } from "components/SlideGroups/utilities/constants";
import { DeleteModal } from "components/Modals/DeleteModal";
import { useSnackbar } from "utilities/hooks/useSnackbar/useSnackbar";
import { useSelector } from "react-redux";
import { currentUserEmailSelector } from "store/slices/userDetailsSlice";
import { useUserCommentsListStyles } from "components/PathologyMap/Modals/FullSlideInfoModal/styles";
import { useDeleteSlideComment } from "components/PathologyMap/Modals/FullSlideInfoModal/hooks";
import { sortOrder } from "utilities/constants";
import { sortArray } from "utilities/general";
import { slideGroupsSorter } from "components/SlideGroups/utilities/utilities";

export const UserCommentsList = ({ comments, isInternal, isStaff = false }) => {
  const { classes } = useUserCommentsListStyles();

  const [sortingOrder, setSortingOrder] = useState(sortOrder.DESC);
  const [selectedComment, setSelectedComment] = useState(null);
  const { deleteComment } = useDeleteSlideComment();
  const { showError } = useSnackbar();
  const email = useSelector(currentUserEmailSelector);

  const handleDeleteComment = () => {
    deleteComment(selectedComment)
      .then(() => setSelectedComment(null))
      .catch(() => showError("An error occurred during comment deleting"));
  };

  const handleSortClick = () => {
    const reverseOrder = (order) => {
      return order === sortOrder.DESC ? sortOrder.ASC : sortOrder.DESC;
    };

    setSortingOrder((prevOrder) => reverseOrder(prevOrder));
  };

  const sortedComments = useMemo(
    () =>
      sortArray(comments, slideGroupsSorter, {
        field: CREATED_DATE_SORT,
        order: sortingOrder,
      }),
    [sortingOrder, comments]
  );

  const externalComments = useMemo(
    () => sortedComments.filter((c) => !c.is_internal),
    [sortedComments]
  );
  const internalComments = useMemo(
    () => sortedComments.filter((c) => c.is_internal),
    [sortedComments]
  );

  const activeComments =
    isInternal && isStaff ? internalComments : externalComments;

  return (
    <Fragment>
      <SortLabel
        isActive={true}
        label="Comment Date"
        mode={sortingOrder}
        field="created"
        onClick={handleSortClick}
        classes={classes}
      />
      <Box
        className={
          isStaff
            ? classes.staffUserCommentsList
            : classes.nonStaffUserCommentsList
        }
      >
        {activeComments.length ? (
          activeComments.map((comment) => (
            <Comment
              key={comment.uuid}
              classes={classes}
              comment={comment}
              onDeleteClick={() => setSelectedComment(comment)}
              isDeleteEnabled={email === comment.user?.email}
            />
          ))
        ) : (
          <Typography className={classes.noSlidesText}>No Comments</Typography>
        )}
      </Box>
      <DeleteModal
        label="Are you sure want to delete this comment?"
        open={!!selectedComment}
        handleDeleteResult={handleDeleteComment}
        onClose={() => setSelectedComment(null)}
      />
    </Fragment>
  );
};
