// import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LinkIcon from "@mui/icons-material/Link";
import { DARK_GREY } from "utilities/colors";
import { XIcon } from "./Icons/XIcon";

export const FACEBOOK = "facebook";
export const TWITTER = "twitter";
export const WHATSAPP = "whatsapp";
export const TELEGRAM = "telegram";
export const SIMPLE_LINK = "link";
export const LINKED_IN = "linkedIn";

export const SOCIAL_ICONS = [
  //TODO: uncomment when clients verify histowiz at facebook
  // {
  //   id: FACEBOOK,
  //   icon: () => (
  //     <FacebookIcon
  //       style={{ fill: "#4267B2", width: "100%", height: "100%" }}
  //     />
  //   ),
  // },
  {
    id: LINKED_IN,
    icon: () => (
      <LinkedInIcon
        style={{ fill: "#0a66c2", width: "100%", height: "100%" }}
      />
    ),
  },
  {
    id: TWITTER,
    icon: () => <XIcon />,
  },
  {
    id: WHATSAPP,
    icon: () => (
      <WhatsAppIcon
        style={{ fill: "#25D366", width: "100%", height: "100%" }}
      />
    ),
  },
  {
    id: TELEGRAM,
    icon: () => (
      <TelegramIcon
        style={{ fill: "#2AABEE", width: "100%", height: "100%" }}
      />
    ),
  },
  {
    id: SIMPLE_LINK,
    icon: () => (
      <LinkIcon style={{ fill: DARK_GREY, width: "100%", height: "100%" }} />
    ),
  },
];
