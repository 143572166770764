import { useSlideAnnotationsToolbarStyles } from "components/ImageViewer/styles";
import { IconButton } from "@mui/material";
import React from "react";

export const AnnotationsCountIconButton = ({ onClick, count }) => {
  const { classes } = useSlideAnnotationsToolbarStyles();

  return (
    <IconButton
      title="Annotations count"
      color="primary"
      className={classes.annoCountIcon}
      onClick={onClick}
    >
      {count}
    </IconButton>
  );
};
